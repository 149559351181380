import { MODAL_ACCOUNT_EDIT, MODAL_ACCOUNT_SHARE } from 'constants/modal';
import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import withOverlayAction from 'Overlay/withAction';
import { showModal, hideModal } from 'main/modal';
import Container from './container';
import { MODAL_SHARE_DASHBOARD } from '../../../../constants/modal';

class ModalDashboardShareRuleComponent extends React.Component {
  constructor(props) {
    super(props);
    this.hide = this.hide.bind(this);
    this.onGotItClick = this.onGotItClick.bind(this);
  }

  componentDidMount() {
    this.props.addOverlayAction('modal', this.hide);
  }

  hide() {
    this.props.hideModal();
  }

  onGotItClick() {
    const { dispatch } = this.props;
    dispatch(showModal(MODAL_SHARE_DASHBOARD, this.props.data));
  }

  render() {
    return (
      <Container
      onGotItClick={this.onGotItClick}
      onClose={() => this.props.hideModal()}
      dashboardName={this.props.data.name}
      anyArrowAccount={this.props.anyArrowAccount} />
    );
  }
}

const mapStateToProps = state => {
  let anyArrowAccount = false;
  if (Object.values(state.credentials.data).filter(x => 'usersSharedWith' in x && x.dashboardSpaceId == state.ui.modalData.id && x.usersSharedWith.length > 0).length) anyArrowAccount = true;
  if (Object.values(state.credentials.data).filter(x => 'sharedByUserName' in x && x.dashboardSpaceId == state.ui.modalData.id && x.sharedByUserName != false).length) anyArrowAccount = true;
  return {
    data: state.ui.modalData || {},
    anyArrowAccount,
  };
};
const mapDispatchToProps = dispatch => ({
  dispatch,
  showModal: (name, data) => dispatch(showModal(name, data)),
  hideModal: () => dispatch(hideModal()),
});

export default translate()(connect(
  mapStateToProps,
  mapDispatchToProps,
)(withOverlayAction(ModalDashboardShareRuleComponent)));
