/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { OVERLAY } from 'constants/ids';
import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Styles from './style.css';

class OverlayComponent extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(event) {
    const { callbacks } = this.props;
    const len = callbacks.length;
    if (len) callbacks[len - 1].fn(event);
  }

  render() {
    if (!this.props.render) return null;
    // Because of CSS styles, this wrapper cannot be a <div> tag!
    return (
      <span
        className={classNames({
          [Styles.overlay]: true,
          [Styles.active1]: this.props.statess.ui.modal,
          [Styles.active]: this.props.active,
        })}
        id={OVERLAY}
        onClick={this.handleClick} />
    );
  }
}

const mapStateToProps = state => ({
  active: state.ui.overlay,
  callbacks: state.ui.overlayData,
  render: state.ui.overlayTemplate,
  statess: state,
});

export default connect(
  mapStateToProps,
  null,
)(OverlayComponent);
