import React from 'react';
import activeCancel from '../../assets/images/cancel_icon/active.svg';
import hoverCacnel from '../../assets/images/cancel_icon/hover.svg';
import clickCancel from '../../assets/images/cancel_icon/click.svg';

const CancelIcon = props => {
  let style = {
    float: 'right',
    cursor: 'pointer',
    position: 'absolute',
    right: '10px',
  };
  if ('style' in props) {
    style = {
      ...style,
      ...props.style,
    };
  }
  return (
    <img
      src={activeCancel}
      onMouseOver={e => (e.currentTarget.src = hoverCacnel)}
      onMouseOut={e => (e.currentTarget.src = activeCancel)}
      onMouseUp={e => (e.currentTarget.src = clickCancel)}
      onClick={props.onCancel}
      style={style}
    />
  );
};

export default CancelIcon;
