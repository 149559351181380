import React from 'react';
import Styles from './styles.css';

const Switch = ({
  id = 'id',
  checked = false,
  onData = 'Yes',
  offData = 'No',
  onChange,
}) => (
  <div className={Styles.wrapper}>
    <input id={id} className={Styles.checkbox}
      type="checkbox"
      checked={checked}
      onChange={event => onChange(event.target.checked)}
    />
    <label for={id}>
      <div className={Styles.checkmark} data-checked={onData} data-unchecked={offData} />
    </label>
  </div>
);

export default Switch;
