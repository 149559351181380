/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import {
  RX_UI_GUIDE_BACKUP,
  RX_UI_TOGGLE_VIEW,
  RX_UI_STICKY_LIST,
  RX_EDIT_API_ERROR,
  RX_CHANGE_ACTIVE_DASHBOARD,
  RX_DASHBOARD_REMOVE,
  RX_UPDATE_BACKUP_KEY_INFO,
  RX_UPDATE_DEFAULT_ACCOUNTS,
  RX_USERS_API_CALL_STATUS,
  RX_STOP_SHARING_DASHBOARD,
} from './actionTypes';

/**
 * Sets the status of The Yellow Brick Road. If enabled, we try to guide
 * the user throughout the application to backup their Master Key.
 *
 * @param {boolean} shouldShow Show The Yellow Brick Road?
 */
export const RXUIGuideBackup = shouldShow => ({ // eslint-disable-line import/prefer-default-export
  type: RX_UI_GUIDE_BACKUP,
  shouldShow,
});

export const RXToggleView = shouldShowGrid => ({
  type: RX_UI_TOGGLE_VIEW,
  shouldShowGrid,
});

export const RXUIStickyList = isSticky => ({
  type: RX_UI_STICKY_LIST,
  payload: isSticky,
});

export const RXEditApiError = status => ({
  type: RX_EDIT_API_ERROR,
  status,
});

export const RXChangeActiveDashboard = currentDashboard => ({
  type: RX_CHANGE_ACTIVE_DASHBOARD,
  currentDashboard: currentDashboard === '' ? null : currentDashboard,
});

export const RXDashboardRemove = id => ({
  type: RX_DASHBOARD_REMOVE,
  id,
});

export const RXUpdateBackupKeyInfo = args => ({
  type: RX_UPDATE_BACKUP_KEY_INFO,
  payload: args,
});

export const RXUpdateDefaultAccounts = accounts => ({
  type: RX_UPDATE_DEFAULT_ACCOUNTS,
  accounts,
});

export const RXUsersApiCallStatus = args => ({
  type: RX_USERS_API_CALL_STATUS,
  payload: args,
});

export const RXStopSharingDashboard = args => ({
  type: RX_STOP_SHARING_DASHBOARD,
  payload: args,
});
