/*
 * Copyright (C) 2018-2019 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { SOURCE_EMAIL_SEARCH } from 'constants/events';
import { TUTORIAL_ALLOW_POPUPS, CREDENTIAL_NOTE } from 'constants/ids';
import { PAUSE_SCAN as ID_PAUSE_SCAN, TUTORIAL_PAUSE_SCAN } from 'constants/ids';
import { OWNED_DOMAINS } from 'constants/misc';
import { MODAL_ADD_ACCOUNT } from 'constants/routes';
import { SEARCH2 as ID_SEARCH } from 'constants/ids';
import { MODAL_ACCOUNT_ADD } from 'constants/modal';
import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { List } from 'immutable';
import debounce from 'lodash.debounce';
import Script from 'react-load-script';
import { isProduction } from '@nettoken/env';
import {
  arrayItemSliceOrAppend,
  eventTarget,
  noop,
  findNode,
} from '@nettoken/helpers';
import { Credential } from '@nettoken/models';
import Validator from '@nettoken/validator';
import { GOOGLE, MICROSOFT, prepareImportedAccountsForRender } from 'main/emailScan';
import { hideModal, showModal } from 'main/modal';
import { parseUrlQueryString } from 'main/router';
import { getOrCreateAccounts } from 'main/search';
import { hideTutorial, showTutorial } from 'main/tutorial';
import { addCredentialsEfficiently } from 'main/vault/credentials';
import { history } from 'reducers/store';
import { SlackRequest } from 'utils/request';
import withAsyncState from 'AsyncState';
import $ from 'jquery';
import withOverlayAction from 'Overlay/withAction';
import Container from './container';
import { CREDENTIAL_NOTE_PARENT } from '../../../../constants/ids';

const ignoreErrors = [
  'The network connection was lost',
  'Login Required',
  'A network error occurred, and the request could not be completed.',
];
const steps = ['signin', 'awaitingsignin', 'confirm', 'scan', 'preview'];
const useToHeaderToOverrideDefaultEmail = false;

class ModalEmailScanComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = this.getDefaultState();
    this.providers = {};
    this.setInitialObjects();
    this.hasHint = false;

    this.handleBack = this.handleBack.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeConsent = this.handleChangeConsent.bind(this);
    this.handlePauseOrResume = this.handlePauseOrResume.bind(this);
    this.handleSearch = debounce(this.searchCallback.bind(this), 200);
    this.handleScriptLoaded = this.handleScriptLoaded.bind(this);
    this.handleSelectApp = this.handleSelectApp.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeSearch = this.handleChangeSearch.bind(this);
    this.hide = this.hide.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.hideHint = this.hideHint.bind(this);
    this.onChangeSearch = this.onChangeSearch.bind(this);
    this.showHint = this.showHint.bind(this);
    this.signOutUser = this.signOutUser.bind(this);
    this.signOutMicrosoftUser = this.signOutMicrosoftUser.bind(this);
    this.toggleHint = this.toggleHint.bind(this);
    this.togglePassword = this.togglePassword.bind(this);
    this.showCredentialNoteHint = this.showCredentialNoteHint.bind(this);
    this.hideCredentialNoteHint = this.hideCredentialNoteHint.bind(this);
  }

  componentDidMount() {
    this.props.addOverlayAction('modal', this.hide, false);
    this.checkHashString();
    this.showCredentialNoteHint();
  }

  componentWillUnmount() {
    this.props.hideTutorial(TUTORIAL_ALLOW_POPUPS);
    clearTimeout(this.tutorialTimeout);
    this.props.hideTutorial(TUTORIAL_PAUSE_SCAN);
    this.hideCredentialNoteHint();
  }

  addToCounterTotal(delta = 0) {
    this.props.AsyncSetState(() => {
      const foundAccounts = this.state.foundAccounts + delta;
      this.setState({ foundAccounts });

      if (foundAccounts && this.state.isPauseDisabled) {
        this.setState({ isPauseDisabled: false });
      }
    });
  }

  /*
   * Check if the email is defined as a login data for the given credential.
   * We compare against the username as well because sometimes the email
   * might be found there, e.g. when username is an email, or if the extension
   * incorrectly sets the email to the username field. (bugs happen ¯\_(ツ)_/¯)
   */
  credentialHasCurrentEmailAsLoginData(credential, email) {
    const emailUpper = email.toUpperCase();

    if (credential.email && credential.email.toUpperCase() === emailUpper) {
      return true;
    }

    if (credential.username && credential.username.toUpperCase() === emailUpper) {
      return true;
    }

    return false;
  }

  /*
   * URL might contain OAuth access token if the user logged in through
   * the same window into their email. This would likely occur if they have
   * blocked third-party cookies on their device.
   */
  checkHashString() {
    const { search } = this.props.router.location;
    history.replace({ search: `?${MODAL_ADD_ACCOUNT}=email`, hash: '' });

    const params = parseUrlQueryString(search);
    if (params.from === GOOGLE && params.access_token) {
      const OAuthAccessToken = params.access_token;
      this.handleChangeConsent();
      this.setState({ OAuthAccessToken }, () => this.handleSignInButtonClick(GOOGLE));
    }
  }

  handleChangeSearch(event) {
    const query = event ? eventTarget(event).value : '';
    this.setState({ query }, this.handleSearch(query));
    // debouncedSearch.set('search', () => this.props.search(query));
    // debouncedSearch.debounce();
  }

  clearSearch() {
    this.handleChangeSearch();

    const input = $(`#${ID_SEARCH}`);
    if (input) input.focus();
  }

  async fetchAllPages() {
    if (this.state.isPaused) return;

    const { token } = this.pending.nextPage;
    const notFetchedAllPages = !!(token || token === false);

    if (notFetchedAllPages) {
      const res = await this.fetchSinglePage();

      if (res.err) {
        this.handleScanError(res.err);
      }
      else {
        const order = res.results.map(x => x.id);
        const currentIndex = order.length > 0 ? 0 : -1;
        this.pending.pages[token] = { index: currentIndex, order, token };
        const index = this.pending.nextPage.index + 1;
        this.pending.nextPage = { fetching: false, index, token: res.token };
        this.fetchAllResults(token);
        this.fetchAllPages();
      }
    }
    else {
      this.tryToCompleteProcessing();
    }
  }

  async fetchAllResults(pageId) {
    if (this.state.isPaused) return;

    const page = this.pending.pages[pageId];
    const notFetchedAllResults = page.index !== -1;

    if (notFetchedAllResults) {
      const res = await this.fetchSingleResult(pageId);

      if (res.err) {
        this.handleScanError(res.err);
      }
      else {
        const credentialIds = Object.keys(this.props.credentials);
        const headerNames = res.headers.map(x => x.name.toUpperCase());
        let { email } = this.state;
        if (useToHeaderToOverrideDefaultEmail) {
          const indexTo = headerNames.indexOf('TO');
          if (indexTo !== -1) {
            const recipient = this.parseEmailFromFromHeader(res.headers[indexTo].value);
            if (recipient.email) ({ email } = recipient);
          }
        }

        const indexFrom = headerNames.indexOf('FROM');
        if (indexFrom !== -1) {
          const { domain } = this.parseEmailFromFromHeader(res.headers[indexFrom].value);
          getOrCreateAccounts([domain], true)
            .then(results => {
              const newDomain = results[0] && results[0].domain;
              if (newDomain && this.isUniqueDomain(newDomain, credentialIds, email)) {
                const credential = new Credential({ domain: newDomain, email });
                this.foundCredentials = this.foundCredentials.push(credential);
                this.allUniqueFoundDomains = this.allUniqueFoundDomains.push(newDomain);
                this.normalizeCounterTotal(newDomain);
              }
              page.index = res.nextIndex;
              this.fetchAllResults(pageId);
            })
            .catch(e => {
              page.index = res.nextIndex;
              this.fetchAllResults(pageId);
            });
        }
      }
    }
    else {
      delete this.pending.pages[pageId];
      this.tryToCompleteProcessing();
    }
  }

  async fetchSinglePage() {
    const res = {};
    try {
      const provider = this.getProvider();
      const { token } = this.pending.nextPage;
      this.pending.nextPage.fetching = true;
      const { nextPageToken, results } = await provider.getResultsPage(token);
      this.pending.nextPage.fetching = false;
      res.results = results;
      res.token = nextPageToken;
    }
    catch (e) {
      res.err = e;
    }
    return res;
  }

  async fetchSingleResult(id) {
    const res = {};
    try {
      const provider = this.getProvider();
      const page = this.pending.pages[id];
      const resultId = page.order[page.index];
      const headers = await provider.processResult(resultId);
      res.headers = headers;
      const last = page.order.length - 1 === page.index;
      res.nextIndex = last ? -1 : page.index + 1;
    }
    catch (e) {
      res.err = e;
    }
    return res;
  }

  getDefaultState() {
    return {
      apps: {},
      filteredApps: {},
      email: '',
      error: '',
      externalUrl: null,
      foundAccounts: 0,
      groupIds: [],
      groups: {},
      hasBlockedThirdPartyCookies: false,
      hasConsent: false,
      isEmailProviderButtonWaiting: false,
      isPaused: false,
      isPauseDisabled: true,
      isSubmitDisabled: true,
      OAuthAccessToken: null,
      passwordList: {},
      provider: '',
      query: '',
      selected: new List(),
      step: this.nextStep(),
      submitting: false,
      accountAlreadyExists: false,
      loggedinEmail: '',
    };
  }

  getProvider() {
    return this.providers[this.state.provider] || null;
  }

  goToNextStep() {
    const step = this.nextStep(this.state.step);

    // Ensure we hide the tutorial if it was shown.
    if (step === 'scan') {
      this.props.hideTutorial(TUTORIAL_ALLOW_POPUPS);
      this.setState({ isSubmitDisabled: true, step });
      this.processPendingData();
      clearTimeout(this.tutorialTimeout);
      this.tutorialTimeout = setTimeout(() => {
        if (this.shouldShowHint()) {
          this.showHint();
        }
      }, 6000);
    }
    else if (step === 'preview') {
      clearTimeout(this.tutorialTimeout);
      this.hideHint();
      this.setState({ submitting: true });
      getOrCreateAccounts(this.allUniqueFoundDomains.toArray(), true)
        .then(results => {
          const { apps, groups, groupIds } = prepareImportedAccountsForRender({
            credentials: this.foundCredentials,
            results,
            skipResultsNoMembership: true,
            t: this.props.t,
            unsortedId: this.props.unsortedId,
          });
          this.setState({
            apps,
            filteredApps: apps,
            groupIds,
            groups,
            isSubmitDisabled: false,
            passwordList: Object.keys(apps).reduce((obj, key) => {
              obj[key] = false;
              return obj;
            }, {}),
            selected: new List(Object.keys(apps)
              .filter(key => apps[key].groupId !== this.props.unsortedId)),
          });
          this.setState({ submitting: false, step });
        })
        .catch(e => console.log(e));
    }
    else {
      this.setState({ isSubmitDisabled: true, step });
    }
  }

  goToPreviousStep() {
    const step = this.previousStep(this.state.step);
    this.setState({ isSubmitDisabled: true, step });
  }

  handleBack() {
    this.signOutUser();
    this.setInitialObjects();
    const nextState = {
      ...this.getDefaultState(),
      hasBlockedThirdPartyCookies: this.state.hasBlockedThirdPartyCookies,
    };
    this.setState(nextState);
  }

  handleChangeConsent() {
    const hasConsent = !this.state.hasConsent;
    this.setState({ hasConsent });
  }

  handleChange(event, id) {
    const { name, value } = eventTarget(event);
    this.setState({
      apps: {
        ...this.state.apps,
        [id]: {
          ...this.state.apps[id],
          [name]: value,
        },
      },
      filteredApps: {
        ...this.state.filteredApps,
        [id]: {
          ...this.state.filteredApps[id],
          [name]: value,
        },
      },
    });
  }

  handlePauseOrResume(isPaused) {
    this.props.AsyncSetState(() => {
      if (isPaused === undefined) isPaused = !this.state.isPaused;
      this.setState({ isPaused, isSubmitDisabled: !isPaused }, () => {
        if (!isPaused) this.processPendingData();
      });
    });
  }

  handleScanError(err) {
    this.handlePauseOrResume(true);
    const error = typeof err === 'object' ? err.message : err;
    this.props.AsyncSetState(() => this.setState({ error }));
    this.reportErrorToSlack(error);
  }

  handleScriptLoaded() {
    if (!this.providerHasExternalUrl()) {
      this.checkWhetherAlreadyLogedIn();
      return;
    }

    this.getProvider().externalScriptLoadedCallback()
      .then(() => this.promptUserSignIn())
      .catch(e => {
        if (e === 'blocked_cookies') {
          this.setState({ hasBlockedThirdPartyCookies: true }, () => {
            // We might have been redirected here from the previous attempt.
            this.promptUserSignIn();
          });
        }
        else {
          console.log(e);
        }
      });
  }

  searchCallback(query) {
    const { apps } = this.state;
    if (query === '') {
      this.setState({
        filteredApps: apps,
      });
      return;
    }
    const _filteredApps = Object.keys(apps)
      .filter(key => apps[key].name.toLowerCase().startsWith(query.toLowerCase()))
      .reduce((obj, key) => {
        obj[key] = apps[key];
        return obj;
      }, {});

    this.setState({
      filteredApps: _filteredApps,
    });
  }

  handleSelectApp(id) {
    const selected = arrayItemSliceOrAppend(this.state.selected, id);
    this.setState({ selected });
  }

  async handleSignInButtonClick(selectedEmailProvider) {
    /* try {
      const exported = await import(`main/emailScan/handlers/${selectedEmailProvider}`);
      this.setState({ error: '', provider: selectedEmailProvider }, () => {
        this.onEmailProviderLoaded(exported.default);
        this.goToNextStep();
      });
    }
    catch (e) {
      console.log(e);
    } */
  }

  handleSubmit(event) {
    event.preventDefault();

    if (!this.state.accountAlreadyExists) {
      this.promptUserSignIn();
      this.setState({ accountAlreadyExists: false, loggedinEmail: '' });
    }
    else if (!this.isLastStep()) {
      this.goToNextStep();
    }
    else {
      this.importAccounts();
    }
  }

  hasCompletedProcessing() {
    const notFetching = !this.pending.nextPage.fetching;
    const notProcessing = Object.keys(this.pending.pages).length === 0;
    return notFetching && notProcessing;
  }

  hide() {
    // Remove the query string, otherwise the modal would keep opening.
    history.replace({ search: '' });
    this.props.hideModal();
    this.hideCredentialNoteHint();
    this.props.hideTutorial(TUTORIAL_ALLOW_POPUPS);
    this.signOutUser();
  }

  hideHint() {
    if (!this.hasHint) return;
    this.props.hideTutorial(TUTORIAL_PAUSE_SCAN);
    this.hasHint = false;
    clearTimeout(this.tutorialTimeout);
    this.tutorialTimeout = setTimeout(() => {
      if (this.shouldShowHint()) {
        this.showHint();
      }
    }, 6000);
  }

  async importAccounts() {
    this.props.addCredentialsEfficiently({
      callback: this.hide,
      credentialIds: this.state.selected,
      credentials: this.state.apps,
      eventSource: SOURCE_EMAIL_SEARCH,
      loader: submitting => this.setState({ submitting }),
    });
  }

  isBlacklistedDomain(domain) {
    return OWNED_DOMAINS.includes(domain) || this.blacklistedDomains.includes(domain);
  }

  isLastStep() {
    const nextStep = this.nextStep(this.state.step);
    const firstStep = this.nextStep();
    return nextStep === firstStep;
  }

  /*
   * Answers the question - can I add this domain to the found domains list?
   * We do this by looking at the existing credentials and if there is no match
   * for the given domain with email address equal to the currently scanned
   * address, we say yes. Otherwise, we add this domain to the blacklist so next
   * query will get faster to the answer 'no'.
   */
  isUniqueDomain(domain, credentialIds, email) {
    if (this.isBlacklistedDomain(domain) || this.allUniqueFoundDomains.includes(domain)) {
      return false;
    }

    for (let i = 0; i < credentialIds.length; i += 1) {
      const credential = this.props.credentials[credentialIds[i]];

      if (credential.domain === domain &&
        this.credentialHasCurrentEmailAsLoginData(credential, email)) {
        this.blacklistedDomains = [
          ...this.blacklistedDomains,
          domain,
        ];
        return false;
      }
    }

    return true;
  }

  nextStep(current) {
    let index = steps.indexOf(current);
    index += 1;
    if (index > steps.length - 1) index = 0;
    if (index != 0) this.hideCredentialNoteHint();
    return steps[index];
  }

  /**
   * Add domain to a temporary stack. Once the stack exceeds its limit,
   * push it to the server to check how many domains are real accounts
   * and normalise the counter total by adding result delta.
   *
   * @param {string} domain
   */
  normalizeCounterTotal(domain) {
    const stackMaxSize = 1;
    this.normalizeDomains = this.normalizeDomains.push(domain);
    if (this.normalizeDomains.size === stackMaxSize) {
      const stack = this.normalizeDomains.toArray();
      this.normalizeDomains = new List();
      getOrCreateAccounts(stack, true)
        .then(results => this.addToCounterTotal(results.length))
        .catch(noop);
    }
  }

  onChangeSearch(event) {
    const { value: query } = eventTarget(event);
    this.setState({
      query,
    }, () => this.handleSearch(query));
  }

  onEmailProviderLoaded(Provider) {
    if (this.getProvider()) {
      this.handleScriptLoaded();
      return;
    }

    this.setProvider(Provider);
    if (this.providerHasExternalUrl()) {
      const externalUrl = this.getProvider().getExternalScriptUrl();
      this.setState({ externalUrl, isEmailProviderButtonWaiting: true });
    }
    else {
      this.setState({ isEmailProviderButtonWaiting: true }, this.handleScriptLoaded);
    }
  }

  parseEmailFromFromHeader(value) {
    const row = value.trim();
    const spaceIndex = row.lastIndexOf(' ');
    let email = row.substr(spaceIndex + 1);

    // Remove any angle brackets around the email address.
    if (email.startsWith('<')) email = email.substr(1);
    if (email.endsWith('>')) email = email.substr(0, email.length - 1);

    const atIndex = email.lastIndexOf('@');
    const domainWithSubdomains = email.substr(atIndex + 1);
    const domain = Validator.parseDomain(domainWithSubdomains);
    // If there is no email or multiple emails, we cannot be sure.
    if (atIndex === -1 || value.match(/@/g).length > 1) email = '';

    return { domain, email };
  }

  previousStep(current) {
    let index = steps.indexOf(current);
    if (index === -1) index = 0;
    index -= 1;
    if (index === -1) index = 0;
    return steps[index];
  }

  processPendingData() {
    this.setState({ isPaused: false }, () => {
      // Resume result processing for fetched pages.
      const pageIds = Object.keys(this.pending.pages);
      pageIds.forEach(pageId => {
        const page = this.pending.pages[pageId];
        if (page.index !== -1) {
          this.fetchAllResults(pageId);
        }
        else {
          delete this.pending.pages[pageId];
        }
      });
      // Resume fetching new pages.
      this.fetchAllPages();
    });
  }

  checkWhetherAlreadyLogedIn() {
    return this.getProvider().isUserSignedIn({
      accessToken: this.state.OAuthAccessToken,
      blockedCookies: this.state.hasBlockedThirdPartyCookies,
    })
      .then(token => {
        if (token) {
          this.setState({ isEmailProviderButtonWaiting: false, error: '' });
          this.startScan();
        }
        else {
          this.setState({ accountAlreadyExists: false });
          this.promptUserSignIn('345');
        }
      })
      .catch(e => {
        this.setState({ accountAlreadyExists: false });
        this.promptUserSignIn('123');
      });
  }

  promptUserSignIn = index => {
    this.setState({ error: '', isEmailProviderButtonWaiting: true });

    this.getProvider().promptUserSignIn({
      accessToken: this.state.OAuthAccessToken,
      blockedCookies: this.state.hasBlockedThirdPartyCookies,
      index,
    })
      .then(() => {
        this.setState({ isEmailProviderButtonWaiting: false });
        this.startScan();
      })
      .catch(e => {
        this.setState({ isEmailProviderButtonWaiting: false });

        if (e === 'user_authenticated') {
          this.startScan();
        }
        else if ((typeof e === 'object' && e.error === 'popup_blocked_by_browser') || e.errorCode === 'popup_window_error') {
          this.goToPreviousStep();
          const { t } = this.props;
          const error = t('modal.emailScan.blockedPopups', { provider: this.state.provider });
          this.setState({ error });

          this.props.showTutorial({
            coords: {
              left: '65%',
              top: '0',
            },
            direction: 'y',
            isFixed: true,
            light: true,
            name: TUTORIAL_ALLOW_POPUPS,
            text: TUTORIAL_ALLOW_POPUPS,
            zIndex: 99,
          });
        }
        else if (e.error === 'user_closed_popup') {
          this.goToPreviousStep();
        }
        else {
          // TODO: Sometimes we get the error
          // Token renewal operation failed due to timeout|Token Renewal Failed
          // Seems to happen when signing out of the previously logged in
          // account and signing into a new one
          this.goToPreviousStep();
          this.setState({
            error: e.error,
          });
          console.log(e);
        }
      });
  }

  providerHasExternalUrl() {
    return typeof this.getProvider().getExternalScriptUrl === 'function';
  }

  reportErrorToSlack(error) {
    if (!isProduction || ignoreErrors.includes(error)) return;

    const { t } = this.props;
    const text = t('slack.emailScanError.text');
    const attachments = [{
      title: t('slack.emailScanError.attachmentTitle'),
      text: error,
    }];
    const channel = t('slack.emailScanError.channel');
    new SlackRequest({ attachments, text }).to(channel).silent('post');
  }

  setInitialObjects() {
    this.allUniqueFoundDomains = new List();
    this.blacklistedDomains = [];
    this.foundCredentials = new List();
    this.normalizeDomains = new List();
    this.pending = {
      nextPage: {
        fetching: false,
        index: 0,
        token: false,
      },
      pages: {},
    };
  }

  setProvider(Provider) {
    this.providers[this.state.provider] = new Provider();
  }

  signOutUser() {
    const provider = this.getProvider();
    if (provider && typeof provider.signOutUser === 'function') {
      provider.signOutUser(this.state.hasBlockedThirdPartyCookies);
    }
  }

  signOutMicrosoftUser() {
    const provider = this.getProvider();
    provider.signOutMicrosoftUser();
    window.open('https://login.microsoftonline.com/common/oauth2/v2.0/logout', '_blank');
    const element = document.querySelector('[aria-label="Cancel"]');
    element.click();
  }

  shouldShowHint() {
    if (this.state.isPaused || this.state.isPauseDisabled) {
      return false;
    }

    return true;
  }

  hideCredentialNoteHint(notCompleted = false) {
    if (!this.hasHint) return;
    this.props.hideTutorial(CREDENTIAL_NOTE, notCompleted);
    this.hasHint = false;
  }

  async showCredentialNoteHint() {
    if (this.hasHint) return;

    const id = CREDENTIAL_NOTE_PARENT;
    await findNode(`#${id}`);
    this.props.showTutorial({
      coords: {
        left: '-322px',
        top: '30px',
      },
      direction: 'right',
      placement: 'center',
      type: 'advanced',
      id,
      light: true,
      isFixed: false,
      name: CREDENTIAL_NOTE,
      onConfirm: this.hideCredentialNoteHint,
      text: CREDENTIAL_NOTE,
      zIndex: 999,
    });
    this.hasHint = true;
  }

  async showHint() {
    if (this.hasHint) return;

    const id = ID_PAUSE_SCAN;
    await findNode(`#${id}`);
    this.props.showTutorial({
      coords: {
        left: '-322px',
        top: '-25px',
      },
      direction: 'right',
      placement: 'center',
      type: 'advanced',
      id,
      light: true,
      isFixed: true,
      name: TUTORIAL_PAUSE_SCAN,
      onConfirm: this.toggleHint,
      text: TUTORIAL_PAUSE_SCAN,
      zIndex: 999,
    });
    this.hasHint = true;
  }

  startScan() {
    setTimeout(async () => {
      try {
        const provider = this.getProvider();
        const email = await provider.getUserEmail();
        this.setState({ email, accountAlreadyExists: true }, () => {
          this.goToNextStep();
          // this.processPendingData();
        });
      }
      catch (e) {
        console.log(e);
      }
    }, 1000);
  }

  toggleHint() {
    this.hideHint();
    clearTimeout(this.tutorialTimeout);
    this.tutorialTimeout = setTimeout(() => {
      if (this.shouldShowHint()) {
        this.showHint();
      }
    }, 6000);
  }

  togglePassword(id) {
    this.setState({
      passwordList: {
        ...this.state.passwordList,
        [id]: !this.state.passwordList[id],
      },
    });
  }

  tryToCompleteProcessing() {
    if (this.hasCompletedProcessing()) this.goToNextStep();
  }

  render() {
    return (
      <React.Fragment>
        <Container
          apps={this.state.apps}
          filteredApps={this.state.filteredApps}
          canPause={!this.state.isPauseDisabled}
          canSubmit={!this.state.isSubmitDisabled}
          disabledTabs={this.state.step !== this.nextStep()}
          email={this.state.email}
          error={this.state.error}
          foundAccounts={this.state.foundAccounts}
          groupIds={this.state.groupIds}
          groups={this.state.groups}
          hasConsent={this.state.hasConsent}
          hasNewAccounts={this.foundCredentials.size > 0 && Object.keys(this.state.apps).length > 0}
          isPaused={this.state.isPaused}
          isWaiting={this.state.isEmailProviderButtonWaiting ? this.state.provider : false}
          onBack={this.handleBack}
          onCancel={this.hide}
          onChange={this.handleChange}
          onChangeSearch={this.onChangeSearch}
          onChangeConsent={this.handleChangeConsent}
          onClickClearSearch={this.clearSearch}
          onClickGoogleButton={() => this.handleSignInButtonClick(GOOGLE)}
          onClickMicrosoftButton={() => this.handleSignInButtonClick(MICROSOFT)}
          onClickPasswordToggle={this.togglePassword}
          onPauseOrResume={() => this.handlePauseOrResume()}
          onSelectApp={this.handleSelectApp}
          onSubmit={this.handleSubmit}
          passwordList={this.state.passwordList}
          provider={this.state.provider}
          query={this.state.query}
          selectedApps={this.state.selected}
          step={this.state.step}
          submitting={this.state.submitting}
          t={this.props.t}
          unsortedId={this.props.unsortedId}
          accountAlreadyExists={this.state.accountAlreadyExists}
          signOutUser={this.signOutUser}
          showModal={this.props.showModal}
          signOutMicrosoftUser={this.signOutMicrosoftUser}
          loggedinEmail={this.state.loggedinEmail} />

        {this.state.externalUrl && <Script
          onLoad={this.handleScriptLoaded}
          url={this.state.externalUrl} />}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  credentials: state.credentials.data,
  // Needed to query the URL.
  router: state.router,
  unsortedId: state.groups.unsortedGroupId,
});

const mapDispatchToProps = dispatch => ({
  addCredentialsEfficiently: opts => dispatch(addCredentialsEfficiently(opts)),
  hideModal: () => dispatch(hideModal()),
  showModal: name => dispatch(showModal(name)),
  hideTutorial: name => dispatch(hideTutorial(name)),
  showTutorial: args => dispatch(showTutorial(args)),
});

export default translate()(connect(
  mapStateToProps,
  mapDispatchToProps,
)(withAsyncState(withOverlayAction(ModalEmailScanComponent))));
