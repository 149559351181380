/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
export const RX_OVERLAY_ACTIONS = 'RX_OVERLAY_ACTIONS';
export const RX_OVERLAY_ANIMATION_ENTER = 'RX_OVERLAY_ANIMATION_ENTER';
export const RX_OVERLAY_ANIMATION_LEAVE = 'RX_OVERLAY_ANIMATION_LEAVE';
export const RX_OVERLAY_RENDER_COMPONENT = 'RX_OVERLAY_RENDER_COMPONENT';
export const RX_OVERLAY_RENDER_NULL = 'RX_OVERLAY_RENDER_NULL';
