/*
 * Copyright (C) 2018-2019 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { PRESET_I18N } from 'constants/presets';
import i18n from 'i18next';
import backend from 'i18next-xhr-backend';
import languageDetector from 'i18next-browser-languagedetector';
import { reactI18nextModule } from 'react-i18next';
import { envFromBranch } from '@nettoken/env';
import { getPreset } from 'main/tools/reduxState';
import { store } from 'reducers/store';

/**
 * @returns {boolean} Do we have debugging turned on?
 */
const debug = store ? store.dispatch(getPreset(PRESET_I18N)) : false;

/**
 * @returns {string} Path to the external API holding translation strings.
 */
const getTranslationsPath = () => {
  const base = 'https://firebasestorage.googleapis.com';
  const firebaseApp = 'nettoken-d8c2d.appspot.com';
  const dir = encodeURIComponent(`public/nettoken-web-app/locales/${envFromBranch}/`);
  const file = '{{ lng }}.json';
  const path = dir + file;
  const token = 'bae1c26b-ec10-432d-8940-d798c09a695b';
  const str = `${base}/v0/b/${firebaseApp}/o/${path}?alt=media&token=${token}`;
  return str;
};

/**
 * The standard way to configure i18n.
 *
 * {@link https://react.i18next.com/guides/step-by-step-guide}
 */
i18n
  .use(backend)
  .use(languageDetector)
  .use(reactI18nextModule)
  .init({
    backend: {
      crossDomain: true,
      loadPath: getTranslationsPath(),
    },
    fallbackLng: 'en-GB',
    debug,
    interpolation: {
      // React does this automatically.
      escapeValue: false,
    },
    // Removes the need to use I18NextProvider
    react: {
      bindI18n: 'languageChanged loaded',
      bindStore: 'added removed',
      nsMode: 'default',
      wait: true,
      withRef: false,
    },
    returnObjects: true,
  });

/**
 * @returns {string} Current language.
 */
export const getCurrentLng = () => i18n.language || window.localStorage.i18nextLng || '';

/**
 * We will extend the translate method below, so we want to hold a reference
 * to the original method here.
 */
const t = i18n.t.bind(i18n);

/**
 * We can pass custom options to i18n that are not included in the documentation.
 * We do this so we do not have to modify the official API. This method extracts
 * these custom options into a separate object and removes them from the original
 * argument. We use custom options to modify returned keys.
 *
 * @param {object} options
 *
 * @returns {object}
 * @property {object} custom
 * @property {object} options
 */
const extractCustomOptions = (options = {}) => {
  const custom = {
    array: !!options.array,
  };
  if (custom.array) delete options.array;
  return { custom, options };
};

/**
 * @param {string} key
 * @param {object} [options={}]
 * @param {object} [custom={}]
 *
 * @returns {any} Translated text.
 */
const getKey = (key, options = {}, custom = {}) => {
  let text = t(key, options);
  if (custom.array && !Array.isArray(text)) {
    text = typeof text === 'string' ? [text] : [];
  }
  return text;
};

/**
 * Return raw key if no language definition exists. This should be
 * happening only within tests. Also return keys in debug mode.
 *
 * @param {string} key
 * @param {object} [options={}]
 *
 * @returns {any}
 */
i18n.t = (key, options = {}) => {
  if (debug || !getCurrentLng()) return key;
  const { custom, options: original } = extractCustomOptions(options);
  return getKey(key, original, custom);
};

/**
 * Bind the method so it can be imported from other modules.
 * Otherwise, we would have incorrect context for the callback
 * or we would have to bind this manually in every import.
 */
i18n.t = i18n.t.bind(i18n);

export default i18n;
