/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { SEARCH1 } from 'constants/ids';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from '@nettoken/components';
import { noop } from '@nettoken/helpers';
import Counter from 'Counter';
import AppsPreview from 'AppsPreview';
import Styles from 'Modal/style.css';
import cross from '../../../../assets/images/cross.svg';
import leftsvg from '../../../../assets/images/left.svg';
import LeftArrowIcon from '../../../ActionIcons/LeftArrowIcon';
import CancelIcon from '../../../ActionIcons/CancelIcon';
import ClearIcon from '../../../ActionIcons/ClearIcon';

class ModalAccountRemoveContainer extends React.Component {
  getRowLabel(app) {
    let { name } = app;
    if (app.displayLogin) name += ` (${app.displayLogin})`;
    return name;
  }

  header() {
    const { step, t } = this.props;

    if (step === 'select') {
      return (
      <React.Fragment>
        <div className={`${Styles.searchWrapper} ${Styles.stickySearchHeader2}`}>
          <input
            aria-label={t('placeholder.search')}
            className={Styles.search}
            onChange={this.props.onChangeSearch}
            placeholder={`${t('placeholder.search')}...`}
            role="search"
            type="text"
            id={SEARCH1}
            value={this.props.query} />
                {this.props.query.length > 0 ?
                  <ClearIcon onClickClearSearch={this.props.onClickClearSearch} /> :
                  null
                }
        </div>
        <div className={`${Styles.actions2} ${Styles.stickyActionBar2}`}></div>
      </React.Fragment>
      );
    }

    if (step === 'confirm') {
      return (
        <div className={classNames({
          [Styles.search]: true,
          [Styles.alignCenter]: true,
          [Styles.searchWrapper]: true,
          [Styles.stickySearchHeader2]: true,
        })}>
          Check again, you are about to remove these accounts:
        </div>
      );
    }

    return null;
  }

  render() {
    const { step, t } = this.props;
    return (
      <React.Fragment>
        <h1
          aria-label={t('modal.accountRemove.title')}
          className={`${Styles.title} ${Styles.stickyHeaderList}`}>
          <LeftArrowIcon onClickBackHandler={this.props.onClickBackHandler} />
          Remove accounts
          <CancelIcon onCancel={this.props.onCancel} />
        </h1>


        <form
          action="/"
          className={`${Styles.formEmailScanPreview} ${Styles.stickyMinHeightBackground}`}
          method="post"
          onSubmit={this.props.onSubmit}
          style={{
            paddingTop: '0px',
            maxWidth: '1020px',
          }}
          >
            <div className={`${Styles.stickyfixedForm2}`}>
              {this.header()}
              <div className={`${step === 'confirm' ? Styles.stickyAppList3 : Styles.stickyAppList2}`}>
                <AppsPreview
                  apps={this.props.apps}
                  isEmailEditable={false}
                  isPasswordEditable={false}
                  isGroupEditable={false}
                  isRemoveAccount={true}
                  onClickPasswordToggle={this.props.onClickPasswordToggle}
                  onSelectApp={this.props.onClickApp}
                  selectedApps={this.props.selected}
                />
              </div>
            </div>

          <div className={classNames({
            [Styles.actions]: true,
            [Styles.accountCounter]: true,
            [Styles.actionsFixed]: true,
          })} style={{ maxWidth: '1020px', width: '92vw' }}>

            <span>
              <Counter
                noGreyBar={true}
                colour="#fff"
                count={this.props.selected.size}
                font="sm"
                label={'Accounts'}
                size={32}
              />
            </span>

            <div>
              <Button
                className={classNames({
                  [Styles.button]: true,
                  [Styles.danger]: true,
                })}
                onClick={e => e.target.blur()}
                disabled={!this.props.canSubmit}
                loaderSize={20}
                loading={this.props.isSubmitting}
                type="submit"
                value={t(this.props.step === 'select' ? 'Preview' : 'Remove')} />
            </div>
          </div>
        </form>
      </React.Fragment>
    );
  }
}

ModalAccountRemoveContainer.defaultProps = {
  apps: [],
  canSubmit: false,
  isLoading: true,
  isSubmitting: false,
  onCancel: noop,
  onChangeSearch: noop,
  onClickApp: noop,
  onSubmit: noop,
  query: '',
  selected: 0,
  step: '',
};

ModalAccountRemoveContainer.propTypes = {
  apps: PropTypes.array,
  canSubmit: PropTypes.bool,
  isLoading: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  onCancel: PropTypes.func,
  onChangeSearch: PropTypes.func,
  onClickApp: PropTypes.func,
  onClickClearSearch: PropTypes.func,
  onSubmit: PropTypes.func,
  query: PropTypes.string,
  selected: PropTypes.number,
  step: PropTypes.string,
  /** i18n translate method */
  t: PropTypes.func.isRequired,
};

export default ModalAccountRemoveContainer;
