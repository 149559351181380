/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'Helmet';
import Tooltip from 'Tooltip';
import classNames from 'classnames';
import { Button } from '@nettoken/components';
import Styles from 'Sidebar/style.css';

export const Checkbox = ({
  checked,
  name,
  onChange,
  value,
}) => {
  const input = React.createRef();

  React.useEffect(() => {
    if (checked && input && input.current) {
      input.current.focus();
    }
  }, [input, checked]);

  return (
    <div className={Styles.radioWrapper}>
      <input
        ref={input}
        checked={checked}
        className={Styles.radioInput}
        id={name}
        onChange={onChange}
        name={name}
        autoFocus={true}
        // type="radio" would not be focusable with keyboard
        type="checkbox"
        value={value} />

      <label
        className={Styles.radioLabel}
        htmlFor={name}>
        <div className={Styles.radioBorder} />
      </label>
    </div>
  );
};

const SidebarSessionContainer = ({
  onCancel,
  onChangeCheckbox,
  onSubmit,
  options,
  lostConnectionOptions,
  handlerForConnectionLost,
  connectionLostTimeout,
  t,
  timeout,
}) => (
  <form
    action="/"
    className={Styles.sidebarFullHeight}
    method="post"
    onSubmit={onSubmit}>
    <Helmet title={t('views.session.title')} />

    <div className={`${Styles.content} ${Styles._menu} ${Styles._scrollable}`}>
      <div className={Styles.paddingFixer}>
        <h1 className={Styles.title}>
          {t('views.session.title')}
        </h1>

        <p>Nettoken will automatically log you out after this time
        as an added layer of security.</p>

        <ul>
          <li className={Styles.dashedSection}>

            <div className={Styles.switchWrapper}>
              <h2 className={Styles.sectionTitle}>
                {t('views.session.sectionTitle')}
              </h2>
             {/* ToDo Need to move this text in translate file. */}
              <Tooltip content="When Nettoken detects no activity it will count down before automatically logging you out of the browser session as an added layer of security."
                delay={0}
                fontIncrease={true}
                align={{
                  points: ['tr', 'bc'],
                }}
              >
                <p className={Styles.hint}>?</p>
              </Tooltip>
            </div>

            <ul>
              {options.map((option, index) => <li
                className={Styles.exportSectionItem}
                key={index}>
                <h4 className={Styles.radioTitle11}>
                  {option.label}
                </h4>
                <Checkbox
                  checked={timeout === option.value}
                  name={`option-${index}`}
                  onChange={onChangeCheckbox}
                  value={option.value} />
              </li>)}
            </ul>
          </li>
        </ul>

        <div className={Styles.switchWrapper}>
         {/* ToDo Need to move this text in translate file. */}
          <h2 className={Styles.sectionTitle}>
            Lost Connection
          </h2>
         {/* ToDo Need to move this text in translate file. */}
          <Tooltip content="When the network connection is lost, Nettoken will count down before automatically logging you out of the browser session as an added layer of security."
            delay={0}
            fontIncrease={true}
            align={{
              points: ['tr', 'bc'],
            }}
          >
            <p className={Styles.hint}>?</p>
          </Tooltip>
        </div>

        <ul>
          {lostConnectionOptions.map((option, index) => (
            <li
              className={Styles.exportSectionItem}
              key={index}>
              <h4 className={Styles.radioTitle11}>
                {option.label}
              </h4>
              <Checkbox
                checked={connectionLostTimeout === option.value}
                name={`lostConnectionOptions-${index}`}
                onChange={handlerForConnectionLost}
                value={option.value} />
            </li>
          ))}
        </ul>
      </div>
    </div>

    <div className={classNames({
      [Styles.actions]: true,
      [Styles.sessionActions]: true,
    })}>
      <Button
        className={Styles.button}
        onClick={onCancel}
        type="button"
        value={t('global.back')} />

      <Button
        className={Styles.button}
        type="submit"
        value={t('global.save')} />
    </div>
  </form>
);

SidebarSessionContainer.defaultProps = {
  options: [],
  timeout: 0,
};

SidebarSessionContainer.propTypes = {
  onCancel: PropTypes.func,
  onChangeCheckbox: PropTypes.func,
  onSubmit: PropTypes.func,
  options: PropTypes.array,
  /** i18n translate method */
  t: PropTypes.func.isRequired,
  timeout: PropTypes.number,
};

export default SidebarSessionContainer;
