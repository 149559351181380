/*
 * Copyright (C) 2018-2019 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { RXErrorShow } from 'main/error/reduxActions';
import { hideModal } from 'main/modal';
import { getModalTemplate } from 'main/modal/reduxState';
import CSSVars from 'runtime/variables';
import Modal from './wrapper';

class ModalComponent extends React.Component {
  renderTemplate(template) {
    try {
      /*
       * Request must include complete file name including extension to
       * avoid confusion. DO NOT REMOVE OR CHANGE THIS. Otherwise,
       * Webpack will cause trouble during the build process by looping
       * through all files in directory and include unwanted files
       * such as tests or Storybook chapters.
       */
      const module = require(`./templates/${template}/index.js`); // eslint-disable-line global-require
      const Template = module.default;
      return <Template />;
    }
    catch (e) {
      setTimeout(() => {
        const { t } = this.props;
        const title = t('error.default.title');
        const text = t('error.default.text');
        this.props.hideModal();
        this.props.showError(title, text);
      }, CSSVars.trMove);
      return null;
    }
  }

  render() {
    const template = this.props.getModalTemplate();
    if (!template) return null;
    return (
      <Modal active={this.props.ui.modal}>
        {this.renderTemplate(template)}
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  ui: state.ui,
});

const mapDispatchToProps = dispatch => ({
  getModalTemplate: () => dispatch(getModalTemplate()),
  hideModal: () => dispatch(hideModal()),
  showError: (title, text) => dispatch(RXErrorShow(title, text)),
});

export default translate()(connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalComponent));
