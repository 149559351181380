/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { noop } from '@nettoken/helpers';
import { getPathSidebarTemplate } from 'main/sidebar/reduxState';
import GStyles from 'styles/index.css';

class ScrollToTopComponent extends React.Component {
  componentDidUpdate(prevProps) {
    const { pathname: nextValue } = this.props.router.location;
    const { pathname: prevValue } = prevProps.router.location;

    if (this.shouldScroll(prevValue, nextValue)) {
      this.props.scrollTo(0, 0);
    }
  }

  /*
   * We do not scroll only if the pathname did not change or if we
   * switched between dashboard view and settings or vice versa.
   */
  shouldScroll(prevValue, nextValue) {
    const { dispatch } = this.props;
    const prevSidebar = dispatch(getPathSidebarTemplate(prevValue));
    const nextSidebar = dispatch(getPathSidebarTemplate(nextValue));
    return prevValue !== nextValue && !prevSidebar && !nextSidebar;
  }

  render() {
    return (
      <div className={GStyles.elRelative}>
        {this.props.children}
      </div>
    );
  }
}

ScrollToTopComponent.propTypes = {
  className: PropTypes.string,
  scrollTo: PropTypes.func,
};

ScrollToTopComponent.defaultProps = {
  className: null,
  scrollTo: noop,
};

const mapStateToProps = state => ({
  router: state.router,
});

export default connect(
  mapStateToProps,
  null,
)(ScrollToTopComponent);
