/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { List } from 'immutable';
import { Button } from '@nettoken/components';
import AppsPreview from 'AppsPreview';
import Avatar from 'Avatar';
import { getModalTemplate } from 'main/modal/reduxState';

import {
  addEvent,
  eventTarget,
  removeEvent,
  scrolledToBottom,
} from '@nettoken/helpers';

import Tabs from 'Modal/tabs';
import GStyles from 'styles/index.css';
import Styles from 'Modal/style.css';

class ModalAddAccountNavListContainer extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      t,
      dispatch,
      buttons,
      getIcons,
      templateTarget,
      handleClick,
      disabled,
      getHoverIcons,
    } = this.props;
    return (
      <React.Fragment>
        <h1
          aria-label={t('modal.accountAdd.title')}
          className={classNames({
            [Styles.title]: true,
            [Styles.addaccounts]: true,
            [Styles.stickyHeaderList]: true,
          })}
          style={{
            background: '#ffffff',
          }}>
          {t('modal.accountAdd.title')}
        </h1>
        <div
          style={{
            background: '#DCDBDE',
            padding: '12px',
            width: '408px',
            height: '560px',
          }}
        >
          <center
            style={{
              paddingBottom: '15px',
            }}>
            <span style={{
              fontSize: '14px',
            }}>
              Save the login details (email/ username/ <br />
              password) for accounts you have signed up to.
            </span>
          </center>
          <div className={Styles.AddMethodButtonsGroup}>
            {buttons.map((button, index) => <div
              className={Styles.AddMethodButtons}
              onClick={e => handleClick(e, index)}
              onMouseEnter={() => document.getElementById(`nav-action-button-img-${index}`).src = getHoverIcons(index)}
              onMouseLeave={() => document.getElementById(`nav-action-button-img-${index}`).src = getIcons(index)}
              key={index}>
              <span className={classNames({
                [Styles.buttonIcon]: true,
                // [Styles._active]: dispatch(getModalTemplate()) === this.templateTarget(index),
              })} style={{
                margin: '0 auto',
                marginBottom: '15px',
              }}>
              <img
                id={`nav-action-button-img-${index}`}
                alt={t('alt.search')}
                src={getIcons(index)} />
              </span>
              <span style={{
                width: '90px',
              }}>{button}</span>
            </div>)}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ModalAddAccountNavListContainer;
