/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { RX_BACKUP_MASTER_KEY } from './actionTypes';

/**
 * Set a local flag indicating whether the user has created a backup of their
 * Master Key.
 *
 * @param {boolean} backup Did the user backup their Master Key?
 */
export const RXBackupMasterKey = backup => ({ // eslint-disable-line import/prefer-default-export, max-len
  type: RX_BACKUP_MASTER_KEY,
  backup,
});
