/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import {
  URL_CREDENTIAL,
  URL_CREDENTIALS,
  URL_CREDENTIALS_ORDER,
  URL_GROUP,
  URL_GROUPS,
  URL_GROUPS_ORDER,
  URL_PREFERENCES,
  URL_SETTINGS,
} from 'constants/endpoints';
import { List } from 'immutable';
import { Credential } from '@nettoken/models';

import {
  WEL_CREDENTIALS_CREATE,
  WEL_CREDENTIALS_UPDATE,
  WEL_REQ,
} from '@nettoken/web-extension-link';

import { setUserPreferencesRedux } from 'main/auth';
import { RXPrefsSessionTimeout } from 'main/preferences/reduxActions';
import { getPredefinedAccounts } from 'main/search';
import { constructUserPreferencesObject, decryptUserCredentials } from 'main/user';
import { addCredentials, deleteCredentials, updateCredential } from 'main/vault/credentials';
import { getOneCredential } from 'main/vault/credentials/reduxState';
import { appendGroup, deleteGroup, updateGroupName } from 'main/vault/groups';
import { RXGroupsUpdateOne } from 'main/vault/groups/reduxActions';
import { RXGroupsOrder } from 'main/vault/groupsOrder/reduxActions';
import { subscribeExtension } from 'utils/extension';

/**
 * Extension added a credential. Add it to web app's state without
 * hitting the servers since it's already been added to database.
 *
 * @param {object} data
 * @property {array} credentials
 */
const extCreateCredentials = data => dispatch => {
  try {
    let credentials = new List();
    data.credentials.forEach(state => {
      const credential = new Credential(state);
      credentials = credentials.push(credential);
    });
    dispatch(addCredentials({ credentials, pushToServer: false }));
  }
  catch (e) {
    console.log(e);
  }
};

/**
 * Extension updated a credential. Edit its web app state without hitting
 * the servers since it's already been updated in the database.
 *
 * @param {object} data
 * @property {array} credentials
 */
const extUpdateCredentials = data => dispatch => {
  try {
    const [state] = data.credentials;
    const credential = new Credential(state);
    const id = credential.get('id');
    dispatch(updateCredential({
      credential,
      id,
      pushToServer: false,
      fromExtension: true,
    }));
  }
  catch (e) {
    console.log(e);
  }
};

/**
 * If we have multiple web app instances open within the same browser, they all
 * count as a single session. When instance I1 executes action A, extension
 * relays the request and returns response back to I1. However, all remaining
 * instance states would remain unchanged here. This method runs in these
 * instances and ensures our states get updated properly. We never hit the
 * server, we just process the responses and update our state accordingly.
 *
 * @param {object} req
 * @property {object} head
 * @property {string} method
 * @property {object} params
 * @property {object} queryParams
 * @property {string} url
 * @property {object} urlParams
 * @param {object} res
 */
const onOtherWebAppInstanceHTTPRequest = (req, res) => dispatch => {
  const { method, url } = req;
  switch (url) {
    case URL_CREDENTIAL:
      if (method === 'DELETE') dispatch(webDeleteCredentials(req, res));
      if (method === 'PUT') dispatch(webUpdateCredentials(req, res));
      break;
    case URL_CREDENTIALS:
      if (method === 'POST') dispatch(webCreateCredentials(req, res));
      break;
    case URL_CREDENTIALS_ORDER:
      if (method === 'PUT') dispatch(webUpdateCredentialsOrder(req, res));
      break;
    case URL_GROUP:
      if (method === 'DELETE') dispatch(webDeleteGroups(req, res));
      if (method === 'PUT') dispatch(webUpdateGroups(req, res));
      break;
    case URL_GROUPS_ORDER:
      if (method === 'PUT') dispatch(webUpdateGroupsOrder(req, res));
      break;
    case URL_GROUPS:
      if (method === 'POST') dispatch(webCreateGroups(req, res));
      break;
    case URL_PREFERENCES:
      if (method === 'PUT') dispatch(webUpdatePreferences(req, res));
      break;
    case URL_SETTINGS:
      if (method === 'PUT') dispatch(webUpdateSessionTimeout(req, res));
      break;
    default:
      break;
  }
};

/**
 * @param {object} req
 * @param {object} res
 */
const webCreateCredentials = (req, res) => async dispatch => {
  const { groupId } = req.params;
  let encrypted = new List();
  req.params.credentials.forEach((state, index) => {
    const credential = new Credential(state);
    if (!credential.get('groupId')) credential.set('groupId', groupId);
    if (!credential.get('id')) credential.set('id', res.ids[index]);
    encrypted = encrypted.push(credential);
  });

  let credentials = await decryptUserCredentials(encrypted);
  let ids = new List();
  credentials.forEach(credential => {
    const accountId = credential.get('accountId');
    if (!ids.includes(accountId)) ids = ids.push(accountId);
  });

  const predefined = await getPredefinedAccounts({ ids: ids.toArray() });
  credentials = credentials.map(credential => {
    const accountId = credential.get('accountId');
    for (let i = 0; i < predefined.length; i += 1) {
      const data = predefined[i];
      if (data.id === accountId) {
        credential.attachGlobalData(data);
        break;
      }
    }
    return credential;
  });
  dispatch(addCredentials({ credentials, pushToServer: false }));
};

/**
 * @param {object} req
 * @param {object} res
 */
const webCreateGroups = (req, res) => dispatch => {
  const { id } = res.group;
  const { name } = req.params;
  dispatch(appendGroup(name, id));
};

/**
 * @param {object} req
 */
const webDeleteCredentials = req => dispatch => {
  const ids = new List([req.urlParams.id]);
  dispatch(deleteCredentials({ ids, pushToServer: false }));
};

/**
 * @param {object} req
 */
const webDeleteGroups = req => dispatch => {
  const { id } = req.urlParams;
  dispatch(deleteGroup({ id, pushToServer: false }));
};

/**
 * @param {object} req
 */
const webUpdateCredentials = req => async dispatch => {
  const { id } = req.urlParams;
  const encrypted = new Credential({ ...req.params, id });
  const decryptedList = await decryptUserCredentials(new List([encrypted]));
  const prevState = dispatch(getOneCredential(id));
  const allowedKeys = Object.keys(req.params);
  let nextState = decryptedList.get(0).getState();
  nextState = Object.keys(nextState)
    .filter(key => allowedKeys.includes(key))
    .reduce((obj, key) => {
      obj[key] = nextState[key];
      return obj;
    }, {});
  const credential = new Credential({ ...prevState, ...nextState });
  dispatch(updateCredential({ credential, id, pushToServer: false }));
};

/**
 * @param {object} req
 */
const webUpdateCredentialsOrder = req => (dispatch, getState) => {
  const { credentialsOrder } = req.params;
  const { id: groupId } = req.urlParams;
  const group = getState().groups.data[groupId];
  group.apps = credentialsOrder;
  dispatch(RXGroupsUpdateOne(groupId, group));
};

/**
 * @param {object} req
 */
const webUpdateGroups = req => dispatch => {
  const { id } = req.urlParams;
  const { name } = req.params;
  dispatch(updateGroupName({ id, name, pushToServer: false }));
};

/**
 * @param {object} req
 */
const webUpdateGroupsOrder = req => dispatch => {
  const { groupsOrder } = req.params;
  dispatch(RXGroupsOrder(groupsOrder));
};

/**
 * @param {object} req
 * @param {object} res
 */
const webUpdatePreferences = (req, res) => dispatch => {
  const prefs = constructUserPreferencesObject({ ...res.gdpr, id: res.id } || {});
  dispatch(setUserPreferencesRedux(prefs));
};

/**
 * @param {object} req
 * @param {object} res
 */
const webUpdateSessionTimeout = (req, res) => dispatch => {
};

export const handleExtensionGlobalEvents = () => dispatch => { // eslint-disable-line import/prefer-default-export, max-len
  const id = subscribeExtension(payload => { // eslint-disable-line no-unused-vars
    const { data, event } = payload;
    switch (event) {
      case WEL_CREDENTIALS_CREATE:
        return dispatch(extCreateCredentials(data));
      case WEL_CREDENTIALS_UPDATE:
        return dispatch(extUpdateCredentials(data));
      case WEL_REQ:
        return dispatch(onOtherWebAppInstanceHTTPRequest(data.req, data.res));
      // case WEL_SIGNOUT:
      //   return dispatch(signOutUser({ expired: data.expired, pushToServer: false }));
      default:
        return null;
    }
  });
};
