/* eslint-disable */
/*
 * Copyright (C) 2018-2019 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { SEARCH2 as ID_SEARCH, SEARCH3 as ID_INNER } from 'constants/ids';
import { SOURCE_MANUAL_SELECTION } from 'constants/events';
import { MODAL_ADD_ACCOUNT_NAVLIST, MODAL_ADVANCED_SETTINGS } from 'constants/modal';
import { MODAL_ADD_ACCOUNT } from 'constants/routes';
import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { List } from 'immutable';
import debounce from 'lodash.debounce';
import { arrayItemSliceOrAppend, eventTarget } from '@nettoken/helpers';
import { LABEL_UNSORTED_GROUP } from '@nettoken/models';
import { hideModal, showModal } from 'main/modal';
import { getAllPredefinedAccounts } from 'main/search';
import { addCredentials, addCredentialsEfficiently } from 'main/vault/credentials';
import { getGroupIdFromGroupInputField } from 'main/vault/groups';
import { history } from 'reducers/store';
import withAsyncState from 'AsyncState';
import withOverlayAction from 'Overlay/withAction';
import { Request } from 'utils/request';
import $ from 'jquery';
import {
  CW_DECRYPT_USING_MASTER_KEYPAIR,
  CW_ENCRYPT_CREDENTIALS,
  CW_ENCRYPT_USING_MASTER_KEYPAIR,
  CW_ENCRYPT_WITH_EXTERNAL_KEY,
  CW_GENERATE_EPHEMERAL_KEY_PAIR,
  getWorkerPromise,
} from '@nettoken/crypto-worker';
import KeyPair from '@nettoken/crypto-worker/src/keypair';
import { getSharedCredentialData } from 'main/sharedAccounts';
import Container from './container';
import { RxEditAccountProccessing, RXToasterShow } from '../../../../main/modal/reduxActions';
import { deleteCredentials } from '../../../../main/vault/credentials';
import { getNewCredentials } from '../../../../main/vault/credentials/reduxState';
import { getApiErrorStatus } from '../../../../main/ui/reduxState';
import { RXEditApiError, RXUpdateDefaultAccounts } from '../../../../main/ui/reduxActions';
import { MODAL_OFFLINE_MSG } from '../../../../constants/modal';

import { URL_CREDENTIALS_SHARED } from '../../../../constants/endpoints';
import { RXCredentialsUpdateOne } from '../../../../main/vault/credentials/reduxActions';
import { getOrCreateKeyPairForSharing } from '../../../../utils/misc';

const steps = ['confirm', 'preview'];

class ModalAccountAddComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      accounts: new List(),
      credentials: [],
      selected: new List(),
      limit: 20,
      loading: true,
      passwordList: {},
      query: '',
      reachedBottom: false,
      skip: 0,
      step: this.nextStep(),
      submitting: false,
      apps: {},
      defaultAccounts: this.props.defaultAccounts,
      startedSearch: false,
    };

    this.debounced = null;
    this.hide = this.hide.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSearchInPreview = debounce(this.filterAccounts.bind(this), 200);
    this.handleSelectApp = this.handleSelectApp.bind(this);
    this.onChangeSearch = this.onChangeSearch.bind(this);
    this.onScrollToBottom = this.onScrollToBottom.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.clearSearchInner = this.clearSearchInner.bind(this);
    this.handleChangeSearch = this.handleChangeSearch.bind(this);
    this.handleChangeSearchInner = this.handleChangeSearchInner.bind(this);
    this.toggleSelected = this.toggleSelected.bind(this);
    this.searchInPreview = this.searchInPreview.bind(this);
    this.togglePassword = this.togglePassword.bind(this);
    this.onClickBack = this.onClickBack.bind(this);
    this.callApiRequest = this.callApiRequest.bind(this);
    // this.getOrCreateKeyPairForSharing = this.getOrCreateKeyPairForSharing.bind(this);
    this.prepareCredentials = this.prepareCredentials.bind(this);
  }

  componentDidMount() {
    this.setCorrectUrl(true);
    this.props.addOverlayAction('modal', this.hide, false);
    this.search({ append: true });
  }

  componentWillUnmount() {
    this.setCorrectUrl(false);
  }

  // async getOrCreateKeyPairForSharing(credential) {
  //   const keypair = new KeyPair();
  //   const {
  //     publicKey,
  //     secretKey,
  //   } = credential;
  //   const worker = await getWorkerPromise('crypto');
  //   if (publicKey && secretKey) {
  //     const [pKey, sKey] = await Promise.all([
  //       worker({
  //         event: CW_DECRYPT_USING_MASTER_KEYPAIR,
  //         message: publicKey,
  //       }),
  //       worker({
  //         event: CW_DECRYPT_USING_MASTER_KEYPAIR,
  //         message: secretKey,
  //       }),
  //     ])
  //       .then(values => values.map(v => Object.values(v)
  //         .join('')
  //         .replace('CW_DECRYPT_USING_MASTER_KEYPAIR', '')));

  //     keypair.set(pKey, sKey);
  //   }
  //   else {
  //     const newKeyPair = await worker({
  //       event: CW_GENERATE_EPHEMERAL_KEY_PAIR,
  //       format: 'base64',
  //     });
  //     keypair.set(newKeyPair.publicKey, newKeyPair.secretKey);
  //   }
  //   return keypair;
  // }

  prepareCredentials = ({
    id,
    note,
    groupId,
    accountId,
    name,
    username,
    email,
    password,
    loginUrl,
    phone,
    publicKey,
    secretKey,
    shared,
    sharedByDashboard,
    dashboardSpaceId,
    picture,
  }) => ({
    id,
    note,
    groupId,
    accountId,
    name,
    username,
    email,
    password,
    loginUrl,
    phone,
    publicKey,
    secretKey,
    shared,
    sharedByDashboard,
    dashboardSpaceId,
    picture,
  });

  handleChange(event, id) {
    const {
      name,
      value,
    } = eventTarget(event);
    this.setState({
      apps: {
        ...this.state.apps,
        [id]: {
          ...this.state.apps[id],
          [name]: value,
        },
      },
      credentials: {
        ...this.state.credentials,
        [id]: {
          ...this.state.credentials[id],
          [name]: value,
        },
      },
    });
  }

  handleSelectApp(id) {
    const selected = arrayItemSliceOrAppend(this.state.selected, id);
    this.setState({
      selected,
      canSubmit: selected.size > 0,
    });
  }

  hide() {
    if (!this.state.submitting) {
      this.setCorrectUrl(false);
      this.props.setEditAccountProccessing(false);
      this.props.setApiError(false);
      this.props.hide();
    }
  }

  handleChangeSearch(event) {
    const query = event ? eventTarget(event).value : '';
    this.setState({
      query,
      reachedBottom: false,
      skip: 0,
    }, () => this.search({ query }));
  }

  handleChangeSearchInner(event) {
    const query = event ? eventTarget(event).value : '';
    this.setState({ query }, this.handleSearchInPreview(query));
  }

  clearSearch() {
    this.handleChangeSearch();

    const input = $(`#${ID_SEARCH}`);
    if (input) input.focus();
  }

  clearSearchInner() {
    this.handleChangeSearchInner();

    const input = $(`#${ID_INNER}`);
    if (input) input.focus();
  }

  filterAccounts(query) {
    if (query === '') {
      this.setState({
        apps: { ...this.state.credentials },
      });
    }

    let apps = this.state.credentials;
    apps = Object.keys(apps)
      .filter(key => {
        if (
          apps[key].name.toLowerCase().includes(query.toLowerCase()) ||
          apps[key].email.toLowerCase().includes(query.toLowerCase()) ||
          apps[key].groupId.toLowerCase().includes(query.toLowerCase())
        ) {
          return true;
        }
        return false;
      })
      .reduce((obj, key) => {
        obj[key] = apps[key];
        return obj;
      }, {});
    this.setState({
      apps,
    });
  }

  loading(loading) {
    this.props.AsyncSetState(() => this.setState({ loading }));
  }

  nextStep(current) {
    let index = steps.indexOf(current);
    index += 1;
    if (index > steps.length - 1) index = 0;
    return steps[index];
  }

  onChangeSearch(event) {
    const { value: query } = eventTarget(event);
    this.setState({
      query,
      reachedBottom: false,
      skip: 0,
      startedSearch: true,
    }, () => this.search({ query }));
  }

  onScrollToBottom() {
    if (!this.state.startedSearch) {
      const skip = this.state.skip + this.state.limit;
      this.setState({ skip }, () => this.search({ append: true, scrollUpdate: true }));
    }
  }

  async handleSharingDashboard() {
    const { dashboards, currentDashboard } = this.props;
    if (
      currentDashboard &&
      'usersSharedWith' in dashboards[currentDashboard] &&
      dashboards[currentDashboard].usersSharedWith.length > 0
    ) {
      const { usersSharedWith } = dashboards[currentDashboard];
      const credentials = this.props.getNewCredentials();
      const sharedCredentialDataArr = {};
      let sharedCredentialDataArrCount = 0;
      let credentialsKeys = {};
      // usersSharedWith.forEach(async (user, userIndex) => {
      let userIndex = 0;
      for (let user of usersSharedWith) {
        // const element = array[index];
        // Object.keys(credentials).forEach(async accountId => {
        for (let accountId in credentials) {
          const credential = credentials[accountId];
          // console.log('spec_Check_daatta', credentialsKeys);
          if (accountId in credentialsKeys) {
            credential.publicKey = credentialsKeys[accountId].publicKey;
            credential.secretKey = credentialsKeys[accountId].secretKey;
          }
          const {
            id: sharedWithUserId,
            publicKey: sharedWithUserPublicKey,
            responded: dashboardStatus,
          } = user;
          console.log('spec_Check_daatta', credential.publicKey, credential.secretKey);
          const {
            publicKey,
            secretKey,
          } = await getOrCreateKeyPairForSharing(credential, KeyPair);
          const worker = await getWorkerPromise('crypto');
          const [pKey, sKey] = await Promise.all([
            worker({
              event: CW_ENCRYPT_USING_MASTER_KEYPAIR,
              message: publicKey,
            }),
            worker({
              event: CW_ENCRYPT_USING_MASTER_KEYPAIR,
              message: secretKey,
            }),
          ])
            .then(values => values.map(v => Object.values(v)
              .join('')
              .replace('CW_ENCRYPT_USING_MASTER_KEYPAIR', '')));
          credential.publicKey = pKey;
          credential.secretKey = sKey;
          credentialsKeys = {
            ...credentialsKeys,
            [accountId]: {
              publicKey: pKey,
              secretKey: sKey,
            }
          }
          credential.shared = true;
          credential.sharedByDashboard = true;
          credential.dashboardSpaceId = dashboards[currentDashboard].id;
          const { encrypted } = await worker({
            event: CW_ENCRYPT_CREDENTIALS,
            credentials: [credential],
          });
          if (!encrypted[0]) {
            throw new Error('Encrypted credentials unavailable');
          }
          const [{
            encrypted: encryptedPublicKey,
          }, {
            encrypted: encryptedSecretKey,
          }] = await Promise.all([
            worker({
              event: CW_ENCRYPT_WITH_EXTERNAL_KEY,
              message: publicKey,
              inputFormat: 'plain',
              outputFormat: 'base64',
              publicKey: sharedWithUserPublicKey,
            }),
            worker({
              event: CW_ENCRYPT_WITH_EXTERNAL_KEY,
              message: secretKey,
              inputFormat: 'plain',
              outputFormat: 'base64',
              publicKey: sharedWithUserPublicKey,
            }),
          ]);
          const SharedCredential = {
            credential: this.prepareCredentials(encrypted[0]),
            accountId: credential.accountId,
            secretKey: encryptedSecretKey,
            publicKey: encryptedPublicKey,
            sharedWithUserId,
            hasPendingInvites: !dashboardStatus,
          };
          // this.props.getSharedCredentials(credential.id);
          // this.props.updateCredential(credential.id, credential);
          console.log('spec_cjeck_ddd', sharedCredentialDataArr, SharedCredential);
          if (userIndex in sharedCredentialDataArr) {
            sharedCredentialDataArr[userIndex].push(SharedCredential);
          }
          else {
            sharedCredentialDataArr[userIndex] = [SharedCredential];
          }
          credentials[accountId] = credential;
          console.log('spec_cjeck_ddd2', sharedCredentialDataArr, SharedCredential);
          sharedCredentialDataArrCount += 1;
          if (sharedCredentialDataArrCount >= (
            Object.keys(credentials).length * usersSharedWith.length
          )) {
            // const finalPayload = [...sharedCredentialDataArr];
            // sharedCredentialDataArr = [];
            this.callApiRequest(sharedCredentialDataArr, 0, credentials);
          }
        };
        userIndex += 1;
      };
    }
    else {
      this.setState({ canSubmit: false });
      this.submitting(false);
      this.hide();
    }
  }

  callApiRequest(finalPayload, index, credentialsData) {
    console.log('spec_Check_33', index, finalPayload);
    const req = new Request(URL_CREDENTIALS_SHARED);
    req.authorise()
      .post({ credentials: finalPayload[index] })
      .then(() => {
        console.log('spec_Check_331', index);
        finalPayload[index].map(data => {
          if (!data.credential.externalAccount) {
            // const newCredential = {
            //   ...credentials[data.credential.id],
            //   // data.credential,
            // }
            let credential = credentialsData[data.credential.id];
            // credential.publicKey = pKey;
            // credential.secretKey = sKey;
            // credential.shared = true;
            // credential.sharedByDashboard = true;
            // credential.dashboardSpaceId = dashboards[currentDashboard].id;
            this.props.getSharedCredentials(credential.id);
            this.props.updateCredential(credential.id, credential);
          }
        });
        if (index >= Object.keys(finalPayload).length - 1) {
          this.setState({ canSubmit: false });
          this.submitting(false);
          this.hide();
        }
        else {
          this.callApiRequest(finalPayload, index + 1, credentialsData);
        }
      })
      .catch(e => console.log('shared error', e));
  }

  async onSubmit(event) {
    event.preventDefault();
    const { step } = this.state;
    if (step === 'confirm') {
      const credentials = this.state.credentials.reduce((obj, credential) => {
        obj[credential.accountId] = credential;
        obj[credential.accountId].dashboardSpaceId = this.props.ui.currentDashboard;
        return obj;
      }, {});
      this.setState({
        apps: { ...credentials },
        credentials,
        passwordList: this.state.credentials.reduce((obj, credential) => {
          obj[credential.accountId] = false;
          return obj;
        }, {}),
        query: '',
        step: this.nextStep(this.state.step),
        selected: new List(this.state.credentials.map(credentail => credentail.accountId)),
      });
    }
    else if (step === 'preview') {
      this.props.setEditAccountProccessing(true);
      if (!navigator.onLine) {
        this.props.showToaster({
          status: 'open',
          type: 'error',
          value: 'Oops, could not save! Please try again',
        });
        return;
      }
      // Modal UI can be bypassed by pressing Enter, do
      // a manual check here. If nothing was selected,
      // we do not allow user to submit the modal.
      // const credentials = this.state.credentials.filter(credential => credential.checked);
      // if (!credentials.length) return;

      this.submitting(true);

      const {
        apps,
        selected: credentialIds,
      } = this.state;
      const filterApps = apps;
      Object.keys(filterApps).map(appKey => {
        if (
          this.props.ui.currentDashboard === '' &&
          filterApps[appKey].groupId === ''
        ) {
          filterApps[appKey].groupId =
          this.props.groups.data[this.props.groups.unsortedGroupId].label;
        }
        if (
          filterApps[appKey].groupId === '' &&
          this.props.ui.currentDashboard !== ''
        ) {
          Object.values(this.props.groups.data).map(grp => {
            if (
              grp.label === LABEL_UNSORTED_GROUP &&
              'dashboardSpaceId' in grp &&
              grp.dashboardSpaceId === this.props.ui.currentDashboard
            ) {
              filterApps[appKey].groupId = grp.label;
            }
          });
        }
      });

      await this.props.addCredentialsEfficiently({
        credentials: filterApps,
        credentialIds,
        eventSource: SOURCE_MANUAL_SELECTION,
      })
        .then(() => {
          setTimeout(() => {
            if (this.props.getApiErrorStatus()) {
              this.setState({ canSubmit: false });
              this.submitting(false);
              this.props.deleteCredentials({
                ids: Object.keys(this.props.getNewCredentials()),
                pushToServer: false,
              });
              this.setState({ canSubmit: true });
              this.props.setEditAccountProccessing(false);
            }
            else {
              this.handleSharingDashboard();
            }
          }, 1000);
        })
        .catch(() => this.submitting(false));
    }
  }

  search(args) {
    if (this.state.reachedBottom) return;
    if (this.debounced) this.debounced.cancel();
    this.debounced = debounce(() => this.searchCallback(args), 200);
    this.debounced();
  }

  search11(args) {
    if (this.state.reachedBottom) return;
    if (this.debounced) this.debounced.cancel();
    this.debounced = debounce(() => this.searchCallback(args), 200);
    this.debounced();
  }

  async searchCallback(args) {
    const {
      limit,
      skip,
      query,
    } = this.state;
    try {
      let predefined = this.state.defaultAccounts;
      if (
        (query == '' && this.state.defaultAccounts.length <= 0) ||
        query != ''
      ) {
        predefined = await getAllPredefinedAccounts({
          q: query,
          scroll: args.scrollUpdate,
          skip,
          limit,
        });
        if (query == '' && this.state.defaultAccounts.length <= 0) {
          this.props.addDefaultAccounts(predefined);
          this.setState({
            defaultAccounts: predefined,
          });
        }
      }
      const results = predefined.map(row => {
        const result = {
          id: row.id,
          accountId: row.id,
          checked: false,
          domain: row.domain,
          email: '',
          loginUrl: row.loginUrl || row.domain,
          name: row.name || row.domain,
          password: '',
        };
        if (row.groupnames) {
          result.groupId = row.groupnames;
        }
        if (row.cookieUrl) result.cookieUrl = row.cookieUrl;
        if (row.picture) result.picture = row.picture;
        if (row.privacyUrl) result.privacyUrl = row.privacyUrl;
        return result;
      });
      let accounts = (args.append ? this.state.accounts.concat(results) : new List(results))
        .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
      if (args.scrollUpdate) {
        const uniqueObj = {};
        const newArray = [];
        accounts.map(app => {
          uniqueObj[app.name.toLowerCase()] = app;
        });
        Object.keys(uniqueObj).map(i => {
          newArray.push(uniqueObj[i]);
        });
        accounts = new List(newArray);
      }
      this.props.AsyncSetState(() => this.setState({ accounts, startedSearch: false }));
      if (predefined.length < this.state.limit) {
        this.props.AsyncSetState(() => this.setState({ reachedBottom: true }));
      }
      this.loading(false);
    }
    catch (e) {
      this.loading(false);
    }
  }

  searchInPreview(event) {
    const { value: query } = eventTarget(event);
    this.setState({
      query,
    }, () => this.handleSearchInPreview(query));
  }

  setCorrectUrl(mounted) {
    const search = mounted ? `?${MODAL_ADD_ACCOUNT}=select` : '';
    history.replace({
      hash: '',
      search,
    });
  }

  submitting(submitting) {
    this.setState({ submitting });
  }

  togglePassword(id) {
    this.setState({
      passwordList: {
        ...this.state.passwordList,
        [id]: !this.state.passwordList[id],
      },
    });
  }

  toggleSelected(index) {
    let {
      accounts,
      credentials,
      selected,
    } = this.state;

    const account = this.state.accounts.get(index);
    account.checked = !account.checked;
    accounts = accounts.set(index, account);

    credentials = !selected.includes(account.accountId) ?
      [...credentials, account] :
      credentials.filter(x => x.accountId !== account.accountId);

    selected = arrayItemSliceOrAppend(selected, account.accountId);

    this.setState({
      accounts,
      credentials,
      selected,
      canSubmit: selected.size > 0,
    });
  }

  onClickBack() {
    this.props.onClickBackHandler();
  }

  render() {
    return (
      <Container
        accounts={this.state.accounts}
        apps={this.state.apps}
        canSubmit={this.state.canSubmit}
        loading={this.state.loading}
        onCancel={() => {
          if (!this.state.submitting) {
            return this.hide();
          }
          return false;
        }}
        onChange={this.handleChange}
        onChangeSearch={this.onChangeSearch}
        onClickClearSearch={this.clearSearch}
        onClickClearSearchInner={this.clearSearchInner}
        onChangeSearchInPreview={this.searchInPreview}
        onClickAdvancedSettings={this.props.onClickAdvancedSettings}
        onClickBackHandler={this.onClickBack}
        onClickPasswordToggle={this.togglePassword}
        onClickApp={this.toggleSelected}
        onScrollToBottom={this.onScrollToBottom}
        onSelectApp={this.handleSelectApp}
        onSubmit={this.onSubmit}
        passwordList={this.state.passwordList}
        query={this.state.query}
        selected={this.state.credentials.length}
        selectedApps={this.state.selected}
        step={this.state.step}
        submitting={this.state.submitting}
        editAccountProccessing={this.props.ui.editAccountProccessing}
        t={this.props.t}
      />
    );
  }
}

const mapStateToProps = state => ({
  ui: state.ui,
  groups: state.groups,
  defaultAccounts: state.ui.defaultAccounts,
  dashboards: state.ui.dashboards,
  currentDashboard: state.ui.currentDashboard,
});

const mapDispatchToProps = dispatch => ({
  addCredentials: args => dispatch(addCredentials(args)),
  addCredentialsEfficiently: args => dispatch(addCredentialsEfficiently(args)),
  getGroupIdFromGroupInputField: name => dispatch(getGroupIdFromGroupInputField(null, name)),
  hide: () => dispatch(hideModal()),
  onClickAdvancedSettings: () => dispatch(showModal(MODAL_ADVANCED_SETTINGS)),
  onClickBackHandler: () => dispatch(showModal(MODAL_ADD_ACCOUNT_NAVLIST)),
  setEditAccountProccessing: status => dispatch(RxEditAccountProccessing(status)),
  deleteCredentials: args => dispatch(deleteCredentials(args)),
  getNewCredentials: () => dispatch(getNewCredentials()),
  setApiError: status => dispatch(RXEditApiError(status)),
  getApiErrorStatus: () => dispatch(getApiErrorStatus()),
  showModal: (template, data) => dispatch(showModal(template, data)),
  showToaster: args => dispatch(RXToasterShow(args)),
  addDefaultAccounts: accounts => dispatch(RXUpdateDefaultAccounts(accounts)),
  getSharedCredentials: id => dispatch(getSharedCredentialData(id)),
  updateCredential: (id, data) => dispatch(RXCredentialsUpdateOne(id, data)),
});

export default translate()(connect(
  mapStateToProps,
  mapDispatchToProps,
)(withAsyncState(withOverlayAction(ModalAccountAddComponent))));
