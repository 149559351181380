/*
 * Copyright (C) 2018-2019 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import React from 'react';
import { List } from 'immutable';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { arrayItemSliceOrAppend } from '@nettoken/helpers';
import Routes from 'config/routes';
import { hideModal } from 'main/modal';
import { goTo } from 'main/router';
import { addCredentialsEfficiently } from 'main/vault/credentials';
import CSSVars from 'runtime/variables';
import withAsyncState from 'AsyncState';
import withOverlayAction from 'Overlay/withAction';
import Container from './container';

class ModalImportPreviewComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: new List(Object.keys(props.data.apps)),
      submitting: false,
    };

    this.goBack = this.goBack.bind(this);
    this.handleSelectApp = this.handleSelectApp.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.props.addOverlayAction('modal', this.goBack, false);
  }

  goBack() {
    this.props.hideModal();
    setTimeout(this.props.goToImport, CSSVars.trMove);
  }

  handleSelectApp(id) {
    const selected = arrayItemSliceOrAppend(this.state.selected, id);
    this.setState({ selected });
  }

  handleSubmit(event) {
    event.preventDefault();

    this.props.addCredentialsEfficiently({
      callback: this.props.hideModal,
      credentialIds: this.state.selected,
      credentials: this.props.data.apps,
      groupNames: this.props.data.groupIds,
      groups: this.props.data.groups,
      loader: submitting => this.setState({ submitting }),
    });
  }

  render() {
    return (
      <Container
        apps={this.props.data.apps}
        groupIds={this.props.data.groupIds}
        groups={this.props.data.groups}
        hasNewAccounts={this.props.data.groupIds.length > 0}
        onCancel={this.goBack}
        onSelectApp={this.handleSelectApp}
        onSubmit={this.handleSubmit}
        selectedApps={this.state.selected}
        submitting={this.state.submitting}
        t={this.props.t}
        unsortedId={this.props.unsortedId} />
    );
  }
}

const mapStateToProps = state => {
  const data = state.ui.modalData || {};
  return {
    credentials: state.credentials.data,
    data,
    unsortedId: state.groups.unsortedGroupId,
  };
};

const mapDispatchToProps = dispatch => ({
  addCredentialsEfficiently: opts => dispatch(addCredentialsEfficiently(opts)),
  goToImport: () => dispatch(goTo(Routes.SETTINGS_IMPORT)),
  hideModal: () => dispatch(hideModal()),
});

export default translate()(connect(
  mapStateToProps,
  mapDispatchToProps,
)(withAsyncState(withOverlayAction(ModalImportPreviewComponent))));
