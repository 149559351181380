/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { callingCountries } from 'country-data';
import { findNode, noop } from '@nettoken/helpers';
import Dropdown from 'Dropdown';
import copy from 'assets/icons/copy.svg';
import show from 'assets/icons/show_01.svg';
import hide from 'assets/icons/hide.svg';
import onHoverCopy from 'assets/icons/copy-shadow.svg';
import onHoverShow from 'assets/icons/show-shadow_01.svg';
import onHoverHide from 'assets/icons/hide-shadow.svg';
import Tooltip from 'Tooltip';
import { Button } from '@nettoken/components';
import Styles2 from 'Modal/style.css';
import Styles from './style.css';
import CopyIcon from '../ActionIcons/CopyIcon';
import ShowPasswordIcon from '../ActionIcons/ShowPasswordIcon';
import HidePasswordIcon from '../ActionIcons/HidePasswordIcon';
import { ID_SHOW_SHARED_INFO } from '../../constants/ids';
import DownArrowIcon from '../ActionIcons/DownArrowIcon';
import RightSideArrow from '../ActionIcons/RightSideArrow';
import QuestionMarkIcon from '../ActionIcons/QuestionMarkIcon';
import { RXToasterShow } from '../../main/modal/reduxActions';

const sortedCountries = callingCountries.all.sort((a, b) => {
  const aName = a.name.toUpperCase();
  const bName = b.name.toUpperCase();
  if (aName < bName) return -1;
  if (aName > bName) return 1;
  return 0;
});

class Input extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      countryCode: '',
      filter: false,
      openDropdown: false,
      selectedDropdown: props.value,
      isCopyHover: false,
      isPasswordHover: false,
      tooltipVisibile: true,
    };
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.mouseEnterOnCopy = this.mouseEnterOnCopy.bind(this);
    this.mouseLeaveOnCopy = this.mouseLeaveOnCopy.bind(this);
    this.mouseEnterOnPassword = this.mouseEnterOnPassword.bind(this);
    this.mouseLeaveOnPassword = this.mouseLeaveOnPassword.bind(this);
    this.onDropdownChange = this.onDropdownChange.bind(this);
    this.onDropdownClick = this.onDropdownClick.bind(this);
    this.onDropdownClickItem = this.onDropdownClickItem.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.copyToClipboard = this.copyToClipboard.bind(this);
    this.inputRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.type === 'tel-with-prefix') {
      this.onDropdownClick({ countryCallingCodes: ['+44'] });
    }
  }

  mouseEnterOnCopy() {
    this.setState({ isCopyHover: true });
  }

  mouseLeaveOnCopy() {
    this.setState({ isCopyHover: false });
  }

  mouseEnterOnPassword() {
    this.setState({ isPasswordHover: true });
  }

  mouseLeaveOnPassword() {
    this.setState({ isPasswordHover: false });
  }

  copyToClipboard(e) {
    this.inputRef.current.select();
    document.execCommand('copy');
    if ('dispatch' in this.props) {
      this.props.dispatch(RXToasterShow({
        status: 'open',
        type: 'success',
        value: 'Copied!',
      }));
    }
  }

  handleClickOutside() {
    document.removeEventListener('click', this.handleClickOutside);
    this.setState({ openDropdown: false });
  }

  onBlur() {
    // Leave time for onClick event to trigger if we clicked one of the children items.
    setTimeout(() => {
      this.setState({ openDropdown: false, tooltipVisibile: true });
      if (this.props.onBlur !== undefined) {
        this.props.onBlur();
      }
    }, 200);
  }

  onDropdownChange(event) {
    this.setState({
      openDropdown: true,
      filter: true,
    });
    this.props.onChange(event);
  }

  onDropdownClick(country) {
    const [code] = country.countryCallingCodes;
    const countryCode = code.replace(/ /g, '');
    const event = {
      target: {
        name: 'countryCode',
        value: countryCode,
      },
    };
    this.props.onChange(event);
    this.setState({ countryCode, openDropdown: false });
  }

  onDropdownClickItem(value) {
    const event = {
      target: {
        name: this.props.name,
        value,
      },
    };
    this.props.onChange(event);
    this.setState({ selectedDropdown: value, filter: false });
  }

  onFocus() {
    this.setState({ openDropdown: true, tooltipVisibile: false });
  }

  renderDropdown() {
    return (
      <ul className={Styles.inputDropdown}>
        {sortedCountries.map(x => (
          <li
            className={Styles.inputDropdownItem}
            key={x.name}
            onClick={() => this.onDropdownClick(x)}>
            <span>{x.name}</span>
            <span>{x.countryCallingCodes[0]}</span>
          </li>
        ))}
      </ul>
    );
  }

  renderDropdownButton() {
    return (
      <div className={classNames({
        [Styles.dropdownButton]: 1,
        [Styles.dropdownGrey]: this.props.dropdownGrey,
      })}
        onClick={this.toggleDropdown}>
        {this.state.countryCode}
      </div>
    );
  }

  renderElement() {
    const {
      background,
      t,
      type,
      typeForceText,
      maxHeight,
      modal,
      name,
      readOnly,
      bgColorOnHover,
      value,
      propClassNames,
      fromMoveAccountScreen,
    } = this.props;

    const className = {
      [Styles.input]: true,
      [Styles._textarea]: type === 'textarea',
      [Styles._password]: (type === 'password' && value),
      [Styles._onlyCopy]: (name === 'email' || name === 'username'),
      [Styles.dropDownCursor]: (name === 'editAppMoveAccount' && this.props.dashboards),
      [Styles.maxHeight]: maxHeight === true,
      [Styles.greyBackground]: background === 'grey',
      [Styles.modalHeight]: modal === true,
      [Styles.disabled]: readOnly,
      [Styles.disabledBg]: !bgColorOnHover,
      ...propClassNames,
    };

    const props = {
      autoComplete: this.props.autoComplete,
      className: classNames(className),
      id: !this.props.modal ? this.props.name : this.props.id + this.props.name,
      name: this.props.name,
      onBlur: this.onBlur,
      onChange: this.props.dropdown ? this.onDropdownChange : this.props.onChange,
      placeholder: this.props.placeholder,
      readOnly: this.props.readOnly,
    };

    if (type === 'file') {
      if (this.props.accept) {
        props.accept = this.props.accept;
      }

      return (
        <React.Fragment>
          <input
            {...props}
            className={`${props.className} ${Styles.inputHidden}`}
            type={type} />

          <div className={classNames({
            ...className,
            [Styles._divAsInput]: true,
            [Styles._placeholder]: !this.props.value,
          })}>
            <span className={Styles.divText}>
              {this.props.value || props.placeholder}
            </span>
            <Button
                className={Styles.browseBtn}
                displaySpanTag={true}
                browseIcon={true}
                value={t('global.browse')}>
            </Button>
            <span className={Styles.inputSquare} />
          </div>
        </React.Fragment>
      );
    }

    if (type === 'textarea') {
      return <textarea value={this.props.value} {...props} />;
    }

    let typeAlias = type;
    if (typeForceText) {
      typeAlias = 'text';
    }
    else if (type === 'tel-with-prefix') {
      typeAlias = 'tel';
    }
    if (this.props.name === 'dashboard') {
      return (
        <input
          onBlur={this.onBlur}
          onClick={this.onFocus}
          type={typeAlias}
          value={this.props.value}
          top={this.props.top}
          {...props}
          readOnly
        />
      );
    }
    if (this.props.name === 'editAppMoveAccount') {
      return (
        <input
          onClick={(this.props.label != 'Dashboard' || this.props.dropdown.length > 0) ? this.props.moveApp : null}
          type={typeAlias}
          disabled={!this.props.dashboards || this.props.readOnly}
          value={this.props.value}
          top={this.props.top}
          {...props}
          readOnly
        />
      );
    }
    if ('showTooltip' in this.props && this.props.showTooltip) {
      let toolTprops = {
        content: this.props.value,
        trigger: 'hover',
      };
      if (!this.state.tooltipVisibile) {
        toolTprops = { ...props, visible: false };
      }
      if (this.props.value && this.props.value.length <= 0) {
        toolTprops = { ...props, visible: false };
      }
      return (
        <Tooltip {...toolTprops}>
          <input
            onBlur={this.onBlur}
            onFocus={this.onFocus}
            type={typeAlias}
            value={this.props.value}
            top={this.props.top}
            {...props} />
        </Tooltip>
      );
    }
    return (
      <input
        onBlur={this.onBlur}
        onFocus={this.onFocus}
        type={typeAlias}
        value={this.props.value}
        top={this.props.top}
        {...props} />
    );
  }

  toggleDropdown(event) {
    event.preventDefault();

    const openDropdown = !this.state.openDropdown;
    this.setState({ openDropdown });

    if (openDropdown) {
      document.addEventListener('click', this.handleClickOutside);
    }
  }

  render() {
    const { t, typeForceText } = this.props;
    return (
      <label
        className={classNames({
          [Styles.wrapper]: true,
          [this.props.className]: this.props.className,
        })}
        htmlFor={this.props.modal ? this.props.id + this.props.name : this.props.name}>
        {this.props.label && <div className={Styles.label}>
          {this.props.label}
          {'tooltipContent' in this.props && <QuestionMarkIcon content={this.props.tooltipContent} rightPosition='5px' bottomPosition='52px' position='absolute' placement='right'/>
        }
        {this.props.error && <div className={`${Styles.label} ${Styles.error}`}>
          {this.props.error}
          {'tooltipContent' in this.props && <QuestionMarkIcon content={this.props.tooltipContent} rightPosition='5px' bottomPosition='52px' position='absolute' placement='right'/>
          }
        </div>}
        </div>}

        {this.props.text && <p className={Styles.subLabelText}>
          {this.props.text}
          {'tooltipContent' in this.props && <QuestionMarkIcon content={this.props.tooltipContent} rightPosition='5px' position='absolute' placement='right'/>
          }
        </p>}

        <span className={Styles.inputWrapper}>
          {this.props.type === 'tel-with-prefix' && this.state.openDropdown && this.renderDropdown()}
          {this.props.type === 'tel-with-prefix' && this.renderDropdownButton()}

          {this.renderElement()}

          <span className={Styles.inputSquare} />
          {this.props.name === 'loginUrl' && 'showLoading' in this.props && this.props.showLoading && (
            <span
              className={classNames({
                [Styles.copyText]: true,
                [Styles.onlyCopy]: true,
              })}
              style={{ maxWidth: '24px', top: '25%' }}
            >
              <span className={Styles2.loader} />
            </span>
          )}
          {this.props.name === 'editAppMoveAccount' &&
            this.props.dashboards &&
            (this.props.label != 'Dashboard' || this.props.dropdown.length > 0) &&
            !this.props.readOnly &&
            <RightSideArrow />}
          {this.props.name === 'dashboard' && !this.props.readOnly && <DownArrowIcon toggleShow={this.toggleDropdown} />}
          {(this.props.name === 'email' || this.props.name === 'username' || this.props.name === 'password') && this.props.value && !this.props.fromMoveAccountScreen &&
            <React.Fragment>
              <input
                className={Styles.passwordCopyInput}
                ref={this.inputRef}
                value={this.props.value}
                readOnly={true}
              />
              <Tooltip content={`copy ${this.props.name}`} >
                <span
                  className={classNames({
                    [Styles.copyText]: true,
                    [Styles.onlyCopy]: (this.props.name === 'email' || this.props.name === 'username'),
                  })}
                  style={{ maxWidth: '24px' }}
                  onClick={this.copyToClipboard}
                  onMouseEnter={this.mouseEnterOnCopy}
                  onMouseLeave={this.mouseLeaveOnCopy}
                >
                  <CopyIcon />
                </span>
              </Tooltip>
            </React.Fragment>
          }

          {this.props.type === 'password' && this.props.value &&
            <Tooltip content={typeForceText ? 'hide password' : 'show password'}>
              <span
                className={Styles.showText}
                onClick={this.props.onClickPasswordToggle}
                onMouseEnter={this.mouseEnterOnPassword}
                onMouseLeave={this.mouseLeaveOnPassword}>
                  {
                    typeForceText ? (
                      <HidePasswordIcon />
                    ) : (
                      <ShowPasswordIcon />
                    )
                  }
              </span>
            </Tooltip>
          }

          {this.state.openDropdown && !this.props.readOnly && <Dropdown
            currentValue={this.props.value}
            filter={this.state.filter}
            fullWidth={true}
            items={this.props.dropdown}
            onClickItem={this.onDropdownClickItem}
            selected={this.state.selectedDropdown}
            showCustomEntry={true}
            reducedHeight={this.props.reducedHeight}
            t={t}
            top={this.props.top} />}
        </span>
      </label>
    );
  }
}

Input.defaultProps = {
  className: '',
  onChange: noop,
  type: 'text',
  maxHeight: false,
  dropdownGrey: false,
  top: false,
  reducedHeight: false,
};

Input.propTypes = {
  autoComplete: PropTypes.string,
  className: PropTypes.string,
  error: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onClickPasswordToggle: PropTypes.func,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  /** i18n translate method */
  t: PropTypes.func.isRequired,
  text: PropTypes.string,
  type: PropTypes.string,
  /** Force type attribute to be text */
  typeForceText: PropTypes.bool,
  value: PropTypes.string,
  reducedHeight: PropTypes.bool,
};

export default Input;
