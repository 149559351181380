/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from '@nettoken/components';
import { noop, reloadBrowser } from '@nettoken/helpers';
import Styles from 'Modal/style.css';
import Input from 'Input';
import Tooltip from 'Tooltip';
import LeftArrowIcon from '../../../ActionIcons/LeftArrowIcon';
import CancelIcon from '../../../ActionIcons/CancelIcon';
import customStyles from './style.css';
import EnvelopeIcon from '../../../ActionIcons/Envelope';

const addDashboard = ({
  onCancel,
  t,
  onChange,
  dashboardName,
  loading,
  onCreate,
  error,
  maxLengthError,
  helperText,
  maxLengthErrorText,
  onClickBackHandler,
}) => (
  <React.Fragment>
    <h1 aria-label='Create Dashboard' className={Styles.title1}>
      <LeftArrowIcon onClickBackHandler={onClickBackHandler} />
      Create Dashboard
      <CancelIcon onCancel={onCancel} />
    </h1>
    <div className={`${Styles.whiteBackground2} ${Styles.minHeightBackground}`}>
      <div className={`${Styles.fixedForm} ${Styles.fixedForm2}`}>
        <p className={customStyles.modalDescription}>
            Create a sub dashboard to further organise your accounts,
            such as separating work and personal accounts.
        </p>
        <div className={Styles.form}>
            <span style={{
              color: '#868692',
              fontSize: '14px',
              padding: '16px 12px 16px',
            }}>DASHBOARD NAME</span>
            <Input className={Styles.shareinputbox} onChange={onChange} value={dashboardName} type="text"/>
            <span style={{ color: '#F8581C', fontSize: '12px' }}>{error ? helperText : ''}</span>
            <span style={{ color: '#F8581C', fontSize: '12px' }}>{maxLengthError ? maxLengthErrorText : ''}</span>
        </div>
      </div>
      <div className={`${Styles.actions} ${Styles.actionsFixed} ${Styles._squeezedAltered} ${Styles.fixedButtons}`}>
        <Button
          className={`${Styles.button}`}
          onClick={onClickBackHandler}
          value='Manage Dashboards' />
        <div>
          <Button
            className={classNames({
              [Styles.button]: true,
              [Styles.notDisabled]: dashboardName.length > 0 && !error && !maxLengthError,
              [Styles.cancelMarginLeft]: true,
            })}
            style={{ paddingLeft: '10px', paddingRight: '10px', paddingTop: '5px' }}
            loading={loading}
            onClick={onCreate}
            disabled={!dashboardName || error || maxLengthError}
            type="submit"
            value='Create' />
        </div>
      </div>
    </div>
  </React.Fragment>
);

addDashboard.propTypes = {
  onCancel: PropTypes.func,
  /** i18n translate method */
  t: PropTypes.func.isRequired,
};

export default addDashboard;
