/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from '@nettoken/components';
import { getIconUrl } from '@nettoken/helpers';
import { setDefaultIcon } from 'utils/misc';
import { PropIcon } from 'utils/propTypes';
import defaultIcon from 'icons/default-icon.svg';
import Styles from 'Modal/style.css';
import upwardArrowicon from '../../../../assets/images/info11.png';
import Avatar from '../../../Avatar';
import { getIconColorNewmethod } from '../../../../utils/misc';

const ModalDashboardDeleteContainer = ({
  name,
  picture,
  onCancel,
  onSubmit,
  t,
  dashboardName,
  selectedDashboardCounter,
  deleteProcessing,
  externalDashboard,
  sharedDashboard,
  currentCounter,
}) => (
  <React.Fragment>
    <h1
      aria-label={'Delete Dashboard'}
      className={classNames({
        [Styles.title]: true,
        [Styles.displayBlock]: true,
        [Styles.stickyHeaderList]: true,
      })}>
      {'Delete Dashboard'}
    </h1>

    <form
      action="/"
      className={Styles.whiteBackground}
      method="post"
      onSubmit={onSubmit}>
      <div className={classNames({
        [Styles.iconHeaderWrapper]: true,
        [Styles.noBg]: true,
      })}>
          {externalDashboard ? (
              <img
                src={upwardArrowicon}
                style={{
                  top: '-3px',
                  right: '-7px',
                  height: '28.5px',
                  width: '28.5px',
                  position: 'absolute',
                }}/>
          ) : null}
          {!externalDashboard && sharedDashboard ? (
            <span className={Styles.sharedMe} style={{
              position: 'absolute',
              top: '-7px',
              right: '-7px',
            }}>
              <svg width="28.5" height="28.5" viewBox="0 0 31 31" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M16 26C9.92487 26 5 21.0751 5 15C5 8.92487 9.92487 4 16
                     4C22.0751 4 27 8.92487 27 15C27 21.0751 22.0751 26 16 26Z"
                    fill="white"/>
                  <circle cx="16" cy="15" r="10.75" stroke="#28283F"/>
                  <path d="M16.0902 9.37494L16.4743 9.05485C16.3805 8.94229
                  16.2421 8.87654
                   16.0956 8.87497C15.9491 8.8734 15.8092 8.93616
                    15.713 9.04668L16.0902 9.37494ZM15.5902 20.6249C15.5902
                     20.9011
                    15.8141 21.1249 16.0902 21.1249C16.3663 21.1249 16.5902
                    20.9011 16.5902 20.6249L15.5902 20.6249ZM11.5166 13.8681C11.3353
                    14.0764 11.3572 14.3922 11.5655 14.5735C11.7738 14.7548
                    12.0896 14.7329 12.2709 14.5246L11.5166 13.8681ZM19.7239
                     14.5165C19.9007
                     14.7286 20.216 14.7573 20.4281 14.5805C20.6403
                    14.4037 20.6689 14.0884 20.4922 13.8763L19.7239 14.5165ZM15.5902
                     9.37494L15.5902
                    20.6249L16.5902 20.6249L16.5902 9.37494L15.5902
                    9.37494ZM12.2709 14.5246L16.4673 9.7032L15.713 9.04668L11.5166
                     13.8681L12.2709
                    14.5246ZM20.4922 13.8763L16.4743 9.05485L15.7061
                    9.69503L19.7239 14.5165L20.4922 13.8763Z"
                    fill="#28283F"/>
              </svg>
            </span>
          ) : null}
          <svg width='100%' height='100%' style={{ borderRadius: '50%' }}>
          <rect x='0' y='0' fill={getIconColorNewmethod(dashboardName)} height='100%' width='100%'></rect>
          <text
              dominantBaseline='middle'
              fill='#ffffff'
              fontSize='10'
              textAnchor='middle'
              x='48%'
              y='54%'
          >{dashboardName.slice(0, 2).toUpperCase()}</text>
          </svg>
      </div>
      <div style={{
        width: '100%',
        display: 'grid',
        justifyContent: 'center',
        textAlign: 'center',
      }}>
        <div>{name}</div>
        <div>{currentCounter} accounts</div>
      </div>
      <p className={`${Styles.promptText} ${Styles.promptTextWidthOverride}`}>
        {externalDashboard ? t('modal.dashboardDeleteExternal.text', { name }) : t('modal.dashboardDelete.text', { name })}
      </p>

      <div className={`${Styles.actions} ${Styles._squeezedAltered}`}>
        <Button
          className={Styles.button}
          onClick={onCancel}
          value={t('modal.accountDelete.no')} />

        <Button
          className={`${Styles.button} ${Styles.danger}`}
          type="submit"
          loading={deleteProcessing}
          value={t('modal.accountDelete.yes')} />
      </div>
    </form>
  </React.Fragment>
);

ModalDashboardDeleteContainer.defaultProps = {
  name: '',
  picture: '',
};

ModalDashboardDeleteContainer.propTypes = {
  name: PropTypes.string,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  picture: PropIcon(),
  /** i18n translate method */
  t: PropTypes.func.isRequired,
};

export default ModalDashboardDeleteContainer;
