/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import {
  RX_OVERLAY_ACTIONS,
  RX_OVERLAY_ANIMATION_ENTER,
  RX_OVERLAY_ANIMATION_LEAVE,
  RX_OVERLAY_RENDER_COMPONENT,
  RX_OVERLAY_RENDER_NULL,
} from './actionTypes';

/**
 * @param {array} arr
 */
export const RXOverlayActions = arr => ({
  type: RX_OVERLAY_ACTIONS,
  arr,
});

export const RXOverlayAnimationEnter = () => ({
  type: RX_OVERLAY_ANIMATION_ENTER,
});

export const RXOverlayAnimationLeave = () => ({
  type: RX_OVERLAY_ANIMATION_LEAVE,
});

export const RXOverlayRenderComponent = () => ({
  type: RX_OVERLAY_RENDER_COMPONENT,
});

export const RXOverlayRenderNull = () => ({
  type: RX_OVERLAY_RENDER_NULL,
});
