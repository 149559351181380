/*
 * Copyright (C) 2018-2019 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { hideModal, showModal } from 'main/modal';
import withOverlayAction from 'Overlay/withAction';
import { removeSharedByAccounts } from 'main/sharedAccounts';
import { Request } from 'utils/request';
import Container from './container';
import { DASHBOARD_SPACES } from '../../../../constants/endpoints';
import {
  MODAL_ADD_DASHBOARD,
  MODAL_EDIT_DASHBOARD,
  MODAL_MY_ACCOUNT,
  MODAL_SHARING_DASHBOARD_BY_INVITATION,
} from '../../../../constants/modal';
import { NETTOKEN_HEAD } from '../../../../constants/ids';

class ModalUserDashboards extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dashboards: {},
    };

    this.hide = this.hide.bind(this);
    this.onClickHandler = this.onClickHandler.bind(this);
  }

  componentDidMount() {
    this.props.addOverlayAction('modal', () => this.hide());
  }

  onClickHandler(dashboard) {
    if (dashboard.invitationId != '') {
      this.props.showModal(MODAL_SHARING_DASHBOARD_BY_INVITATION, {
        ...dashboard,
        sharedByUserName: dashboard.sharedByUser,
      });
    }
    else {
      this.props.showModal(MODAL_EDIT_DASHBOARD, {
        id: dashboard.id,
        dashboardName: dashboard.name,
      });
    }
  }

  componentWillMount() {
    this.getDashboards();
  }

  hide() {
    this.props.hide();
  }

  getDashboards() {
    const dashboardList = {};
    Object.keys(this.props.dashboards).map(resKey => {
      // invitations added back in manage dashbaords
      // if (!('invitationId' in this.props.dashboards[resKey])
      // || !this.props.dashboards[resKey].invitationId) {
      const counter = Object.keys(this.props.credentials).filter(itemKey => {
        if ('dashboardSpaceId' in this.props.credentials[itemKey]) {
          const tmpID = this.props.credentials[itemKey].dashboardSpaceId;
          const resID = this.props.dashboards[resKey].id;
          return tmpID === resID;
        }
        return false;
      }).length;
      dashboardList[resKey] = {
        id: this.props.dashboards[resKey].id,
        name: this.props.dashboards[resKey].name,
        encrypted: this.props.dashboards[resKey].encrypted,
        count: counter,
        externalDashboard: 'externalDashboard' in this.props.dashboards[resKey] ? this.props.dashboards[resKey].externalDashboard : false,
        shared: (!('externalDashboard' in this.props.dashboards[resKey])) && ('shared' in this.props.dashboards[resKey] && this.props.dashboards[resKey].shared),
        invitationId: this.props.dashboards[resKey].invitationId ?? '',
        usersSharedWith: 'usersSharedWith' in this.props.dashboards[resKey] ? this.props.dashboards[resKey].usersSharedWith : [],
        sharedByUserName: 'sharedByUserName' in this.props.dashboards[resKey] ? this.props.dashboards[resKey].sharedByUserName : '',
        sharedByUser: 'sharedByUser' in this.props.dashboards[resKey] ? this.props.dashboards[resKey].sharedByUser : '',
      };
      // }
    });
    this.setState({ dashboards: dashboardList });
  }

  render() {
    return (
      <Container
        onCancel={this.hide}
        dashboards={this.state.dashboards}
        username={this.props.name}
        totalAccounts={this.props.totalAccounts}
        addDashboards={() => this.props.showModal(MODAL_ADD_DASHBOARD)}
        editDashboard={this.onClickHandler}
        goToMyAccount={() => this.props.showModal(MODAL_MY_ACCOUNT)}
      />
    );
  }
}

const mapDispatchToState = state => ({
  name: state.user.profile.name,
  totalAccounts: Object.values(state.credentials.data)
    .filter(data => 'dashboardSpaceId' in data && data.dashboardSpaceId === null)
    .length,
  credentials: state.credentials.data,
  dashboards: state.ui.dashboards,
});

const mapDispatchToProps = dispatch => ({
  hide: () => dispatch(hideModal()),
  showModal: (name, data) => dispatch(showModal(name, data)),
});

export default translate()(
  connect(
    mapDispatchToState,
    mapDispatchToProps,
  )(withOverlayAction(ModalUserDashboards)),
);
