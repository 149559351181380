/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */

/**
 * Checks whether the user has created a backup of their Master Key.
 *
 * @returns {boolean}
 */
export const hasMasterKeyBackup = () => (_, getState) => { // eslint-disable-line import/prefer-default-export, max-len
  const { hasMasterKeyBackup: backup } = getState().user;
  return backup;
};
