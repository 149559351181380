/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { VALID_URL as ID_VALID_URL } from 'constants/ids';
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import { Button } from '@nettoken/components';
import { noop } from '@nettoken/helpers';
import { setDefaultIcon } from 'utils/misc';
import Input from 'Input';
import Tabs from 'Modal/tabs';
import defaultIcon from 'icons/default-icon.svg';
import Styles from 'Modal/style.css';
import cross from '../../../../assets/images/cross.svg';
import leftsvg from '../../../../assets/images/left.svg';
import LeftArrowIcon from '../../../ActionIcons/LeftArrowIcon';
import CancelIcon from '../../../ActionIcons/CancelIcon';

// TODO save login checkbox

const ModalAdvancedSettingsContainer = ({
  app,
  email,
  getError,
  group,
  groupDropdown,
  icon,
  isPasswordVisible,
  isSubmitDisabled,
  loginUrl,
  note,
  onCancel,
  onChange,
  onClickPasswordToggle,
  onSubmit,
  password,
  submitting,
  t,
  username,
  onClickBackHandler,
  validatingURL,
}) => {
  const form = [{
    autoComplete: 'account-name',
    label: t('global.name'),
    name: 'name',
    placeholder: t('placeholder.accountName'),
    type: 'text',
    value: app,
  }, {
    autoComplete: 'url',
    label: t('global.url'),
    name: 'loginUrl',
    placeholder: t('placeholder.url'),
    type: 'text',
    value: loginUrl,
  }, {
    autoComplete: 'email',
    label: t('global.email'),
    name: 'email',
    placeholder: t('placeholder.emailUser'),
    type: 'email',
    value: email,
  }, {
    autoComplete: 'username',
    label: t('global.username'),
    name: 'username',
    type: 'text',
    value: username,
  }, {
    autoComplete: 'new-password',
    isVisible: isPasswordVisible,
    label: t('global.password'),
    name: 'password',
    type: 'password',
    value: password,
  }, {
    autoComplete: 'off',
    dropdown: groupDropdown,
    label: t('global.group'),
    name: 'group',
    placeholder: t('placeholder.group'),
    type: 'text',
    value: group,
  }, {
    autoComplete: 'off',
    label: 'Note',
    name: 'note',
    placeholder: 'Add note',
    type: 'text',
    value: note,
  }];

  return (
    <React.Fragment>
    <div>
      <h1
        aria-label={t('modal.advancedSettings.previewTitle')}
          className={classNames({
            [Styles.title]: true,
            [Styles.addAccountsManually]: true,
            [Styles.stickyHeaderList]: true,
          })}>
        <LeftArrowIcon onClickBackHandler={onClickBackHandler} />
        Add manually
        <CancelIcon onCancel={onCancel} />
      </h1>
        </div>

      <form
        action="/"
        className={Styles.whiteBackground}
        method="post"
        onSubmit={onSubmit}>
        <div className={`${Styles.fixedForm}`}
             style={{
               height: '495px',
             }}>
          <div className={Styles.iconHeaderWrapperAdvanced}>
            <img
              alt={t('alt.advancedSettings')}
              className={Styles.icon}
              onError={setDefaultIcon}
              src={icon || defaultIcon} />
          </div>


          <div id={ID_VALID_URL} className={Styles.form}>
            {form.map(row => <Input
              autoComplete={row.autoComplete}
              dropdown={row.dropdown}
              error={getError(row.name)}
              key={row.name}
              label={row.label}
              name={row.name}
              onChange={onChange}
              onClickPasswordToggle={onClickPasswordToggle}
              placeholder={row.placeholder}
              readOnly={row.readOnly}
              t={t}
              type={row.type}
              typeForceText={row.isVisible}
              value={row.value}
              showLoading={validatingURL} />)}
              <p className={Styles.hint}>{`${200 - note.length} characters left`}</p>
          </div>
        </div>
        <div className={`${Styles.actions} ${Styles.actionsFixed2} ${Styles.right}`}>
          <Button
            className={classNames({
              [Styles.button]: true,
              [Styles.lastChild]: true,
              [Styles.notDisabled]: !isSubmitDisabled,

            })}
            disabled={isSubmitDisabled}
            loaderSize={20}
            loading={submitting}
            type="submit"
            value="Add"
          />
        </div>
      </form>
    </React.Fragment>
  );
};

ModalAdvancedSettingsContainer.defaultProps = {
  app: '',
  getError: noop,
  group: '',
  loginUrl: '',
  password: '',
  username: '',
};

ModalAdvancedSettingsContainer.propTypes = {
  app: PropTypes.string,
  getError: PropTypes.func,
  group: PropTypes.string,
  groupDropdown: PropTypes.instanceOf(List),
  icon: PropTypes.string,
  isPasswordVisible: PropTypes.bool,
  isSubmitDisabled: PropTypes.bool,
  loginUrl: PropTypes.string,
  onCancel: PropTypes.func,
  onChange: PropTypes.func,
  onClickPasswordToggle: PropTypes.func,
  onSubmit: PropTypes.func,
  password: PropTypes.string,
  submitting: PropTypes.bool,
  /** i18n translate method */
  t: PropTypes.func.isRequired,
  username: PropTypes.string,
};

export default ModalAdvancedSettingsContainer;
