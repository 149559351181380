/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { noop } from '@nettoken/helpers';
import { getPathSidebarTemplate } from 'main/sidebar/reduxState';
import GStyles from 'styles/index.css';

class GeneralLoader extends React.Component {
  /*
   * We do not scroll only if the pathname did not change or if we
   * switched between dashboard view and settings or vice versa.
   */

  render() {
    return this.props.loading ? (
      <div className={GStyles.generalLoaderOverLay}>
        <div />
      </div>
    ) : <></>;
  }
}


const mapStateToProps = state => ({
  loading: state.ui.overlayLoading,
});

export default connect(
  mapStateToProps,
  null,
)(GeneralLoader);
