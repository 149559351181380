/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
/**
 * @param {string} id
 *
 * @returns {object} False if the error does not exist.
 */
export const getUIError = id => (_, getState) => {
  const { errors } = getState().ui;
  for (let i = 0; i < errors.length; i += 1) {
    if (errors[i].forElement === id) {
      return errors[i];
    }
  }
  return false;
};

/**
 * @param {string} id
 *
 * @returns {string}
 */
export const getError = id => dispatch => {
  const error = dispatch(getUIError(id));
  return error ? error.message : '';
};
