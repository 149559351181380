/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import React from 'react';
import Styles from './style.css';

const VideoComponent = ({ videoUrl }) => (
  <div className={Styles.wrapper}>
    <div
      className="wistia_responsive_padding"
      style={{
        padding: '56.25% 0 0 0',
        position: 'relative',
      }}>
      <div
        className="wistia_responsive_wrapper"
        style={{
          height: '100%',
          left: '0',
          position: 'absolute',
          top: '0',
          width: '100%',
        }}>
        <iframe
          allowFullScreen
          allowtransparency="true"
          className="wistia_embed"
          frameBorder="0"
          height="100%"
          name="wistia_embed"
          scrolling="no"
          src={videoUrl}
          title="Wistia video player"
          width="100%" />
      </div>
    </div>
    <script src="https://fast.wistia.net/assets/external/E-v1.js" async />
  </div>
);

export default VideoComponent;
