/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { RX_APP_INIT } from 'main/state/actionTypes';
import { RX_USER_LOG_OUT } from 'main/user/actionTypes';
import Reducer from 'reducers/factory';
import { RX_PREFS_FREQUENCY, RX_PREFS_SET } from './actionTypes';

const defaultState = () => ({
  cookies: true,
  device: true,
  connectionLostTimeout: 5,
  tracking: null,
});

const reducer = new Reducer('preferences', defaultState());

export default (state = reducer.defaultState(), action) => {
  switch (action.type) {
    case RX_APP_INIT:
      return reducer.merge(state);

    case RX_PREFS_FREQUENCY:
    case RX_PREFS_SET:
      return {
        ...state,
        [action.key]: action.value,
      };

    case RX_USER_LOG_OUT:
      return reducer.isPersisted ? state : reducer.defaultState();

    default:
      return state;
  }
};
