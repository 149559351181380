/*
 * Copyright (C) 2018-2019 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { MODAL_ADD_ACCOUNT } from 'constants/routes';
import queryString from 'query-string';
import { push } from 'connected-react-router';
import { EXTENSION_REMOVED } from '../../constants/routes';

/**
 * Returns a parsed route without subpath, hash, or query string.
 * We also strip the trailing slash which used to cause nasty bugs
 * when doing strict comparisons against route configurations.
 *
 * @param {string} [route] Custom route to parse.
 *
 * @returns {string}
 */
export const getParsedRoute = route => (_, getState) => {
  if (!route) route = getSafePathname(getState().router.location);
  if (route.endsWith('/')) route = route.substr(0, route.length - 1);
  return route;
};

/**
 * Try to find a route in the current URL query string under the `redirect`
 * parameter. If it's not found, we return an empty string. This should be
 * checked by the callee and decide what to do based on the result.
 *
 * @returns {string}
 */
export const getRouteFromRedirectQueryStringParam = () => (_, getState) => {
  const prevRoute = getState().router.location;
  const queryParams = parseUrlQueryString(prevRoute.search);
  return queryParams.redirect || '';
};

/**
 * Guarantees to return a string as a pathname. This might be empty
 * if location is null, used to happen in tests.
 *
 * @param {object} [loc]
 * @property {string} pathname
 *
 * @returns {string}
 */
export const getSafePathname = loc => loc ? loc.pathname : ''; // eslint-disable-line no-confusing-arrow

/**
 * Navigate to the selected route. If the redirect flag is on, we check the
 * query string of the current path and if it contains a redirect parameter,
 * we navigate to that instead. In other words, it overrides the defined route.
 *
 * @param {string} route Destination URL.
 * @param {boolean} [redirect=false] Override `route` argument if the current
 *   path contains a redirect parameter in its query string?
 */
export const goTo = (route, redirect = false, continueRedirect = false) => dispatch => {
  let overrideRoute = route;

  if (redirect) {
    const redirectParam = dispatch(getRouteFromRedirectQueryStringParam());
    if (!continueRedirect && redirectParam) overrideRoute = redirectParam;
    if (continueRedirect && redirectParam) overrideRoute = `${route}?redirect=${redirectParam}`;
  }

  // If overriding route fails, for example because the URL is broken,
  // go to the original destination. The original value is routed by us
  // so it should always work, whereas redirects can be customised by user.
  try {
    dispatch(push(overrideRoute));
  }
  catch (e) {
    dispatch(push(route));
  }
};

/**
 * @returns {boolean}
 */
export const isAddingCredential = () => (_, getState) => {
  const { router } = getState();
  const queryParams = parseUrlQueryString(router.location.search);
  return !!queryParams[MODAL_ADD_ACCOUNT];
};

/**
 *
 * @returns {boolean}
 */
export const isExtensionRemoved = () => (_, getState) => {
  const { router } = getState();
  const queryParams = parseUrlQueryString(router.location.search);
  return !!queryParams[EXTENSION_REMOVED];
};


/**
 * @param {string} str
 *
 * @returns {object}
 */
export const parseUrlQueryString = str => {
  const params = queryString.parse(str);
  return params;
};

/**
 * @param {object} [params={}]
 *
 * @returns {string}
 */
export const stringifyUrlQueryParams = (params = {}) => {
  const str = queryString.stringify(params);
  return str;
};
