import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import withOverlayAction from 'Overlay/withAction';
import { hideModal } from 'main/modal';
import { setTrackingPermission } from 'main/preferences';
import Container from './container';

class ModalPromptTrackingComponent extends Component {
  constructor() {
    super();
    this.handleCancel = this.handleCancel.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
    this.hide = this.hide.bind(this);
  }

  componentDidMount() {
    this.props.addOverlayAction('modal', this.hide, false);
  }

  handleConfirm() {
    this.props.setTrackingPermission(true);
    this.hide();
  }

  handleCancel() {
    this.props.setTrackingPermission(false);
    this.hide();
  }

  hide() {
    this.props.hide();
  }

  render() {
    return (
      <Container
        onConfirm={this.handleConfirm}
        onCancel={this.handleCancel}
        t={this.props.t}
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  hide: () => dispatch(hideModal()),
  setTrackingPermission: permission => dispatch(setTrackingPermission(permission)),
});

export default translate()(connect(
  null,
  mapDispatchToProps,
)(withOverlayAction(ModalPromptTrackingComponent)));
