const getUiModelTemplateState = () => (_, getState) => {
  const { modalTemplate } = getState().ui;
  return modalTemplate;
};

export const getApiErrorStatus = () => (_, getState) => {
  const { hasApiErrors } = getState().ui;
  return hasApiErrors;
};

export const getEditAccountProcessing = () => (_, getState) => {
  const { editAccountProccessing } = getState().ui;
  return editAccountProccessing;
};

export const getCurrentDashboard = () => (_, getState) => {
  const { currentDashboard } = getState().ui;
  return currentDashboard;
};

export const getCurrentModalData = () => (_, getState) => {
  const { modalData } = getState().ui;
  return modalData;
};

export const getUsersApiCallProcessing = () => (_, getState) => {
  const { usersApiCallInProcess } = getState().ui;
  return usersApiCallInProcess;
};

export default getUiModelTemplateState;
