/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
export const canBindKeyboardShortcuts = () => (_, getState) => { // eslint-disable-line import/prefer-default-export, max-len
  const state = getState();
  return state && state.device && state.device.isDesktop;
};
