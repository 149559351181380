/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { noop } from '@nettoken/helpers';
import Helmet from 'Helmet';
import Tooltip from 'Tooltip';
import Switch from 'Switch';
import iconPlus from 'icons/plus.svg';
import iconMinus from 'icons/minus.svg';
import Styles from 'Sidebar/style.css';
import logout from '../../../../assets/images/logout.svg';
import sessiontimeout from '../../../../assets/images/sessiontimeout.svg';
import accountdetails from '../../../../assets/images/account_details.svg';
import managedashboard from '../../../../assets/images/managedashboard.svg';
import doctorclipboard from '../../../../assets/images/doctor_clipboard.svg';
import backupkey from '../../../../assets/images/backup_key.svg';
import notcomplete from '../../../../assets/images/not_complete.svg';
import exportimg from '../../../../assets/images/export.svg';
import { Checkbox } from '../session/container';
import CancelIcon from '../../../ActionIcons/CancelIcon';

const SidebarSettingsContainer = ({
  email,
  flagErrorTest,
  getError,
  highlight,
  isTracking,
  name,
  onCancel,
  onChange,
  onChangeCheckbox,
  onClickButtonBreakApp,
  onClickButtonBasics,
  onClickButtonDownload,
  onClickButtonBackup,
  onClickButtonDeleteNettoken,
  onClickButtonImport,
  onClickButtonMore,
  onClickButtonPasswordCheckup,
  onClickButtonPrivacy,
  onClickButtonSession,
  onClickDashboards,
  onClickButtonSignOut,
  onSubmit,
  isbackupkey,
  options,
  phone,
  setTrackingPermission,
  showMore,
  t,
  timeout,
  unsavedChanges,
}) => (
    <form
      action="/"
      className={Styles.sidebarFullHeight}
      method="post"
      onSubmit={onSubmit}>
      <Helmet title={t('views.settings.title')} />

      <div className={`${Styles.content} ${Styles._menu} ${Styles._scrollable}`}>
        <div>
          <h1
            className={Styles.title}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {t('views.settings.title')}
            <CancelIcon onCancel={onCancel} style={{ position: 'inherit' }}/>
          </h1>
          <p>
            Only you hold the key to your account, keeping all your sensitive data private!
          </p>
          <div className={`${Styles.link} ${Styles._extraSpacing}`} onClick={onClickButtonSignOut}>
          <img src={logout} style={{ marginRight: '10px' }} />
          <span style={{ fontSize: '16px' }}>
            {t('global.logOut')}
          </span>
          </div>
          <div className={`${Styles.link}`} onClick={onClickButtonBasics}>
          <img src={accountdetails} style={{ marginRight: '10px' }} />
          <span style={{ fontSize: '16px' }}>
            My Nettoken
          </span>
          </div>
          <div className={`${Styles.link}`} onClick={onClickDashboards}>
            <img src={managedashboard} style={{ marginRight: '10px' }} />
            <span style={{ fontSize: '16px' }}>
              Manage dashboards
            </span>
          </div>
          <div className={`${Styles.link}`} onClick={onClickButtonPasswordCheckup}>
          <img src={doctorclipboard} style={{ marginRight: '16px', marginLeft: '3px' }} />
          <span style={{ fontSize: '16px' }}>
            Password checkup
          </span>
          </div>
          <div className={classNames({
            [Styles.backupkey]: isbackupkey,
            [Styles.link]: true,
          })}
          onClick={onClickButtonBackup}
          >
          <img src={backupkey} style={{ marginRight: '10px' }} />
          <span style={{ fontSize: '16px' }}>
           {t('views.backup.sidebarButton')}
          </span>
          </div>
          <div style={{ height: '54px' }}
            className={classNames({
              [Styles.link1]: true,
            })} onClick={onClickButtonMore}>
          <span style={{ marginLeft: '40px' }}>
             <span className={Styles.right}>
              <img src={showMore ? iconMinus : iconPlus} />
            </span>
           More
          </span>
          </div>

          {showMore &&
          <Fragment>
            <div style={{ backgroundColor: '#F6F6F6' }} className={`${Styles.link}`} onClick={onClickButtonSession}>
          <img src={sessiontimeout} style={{ marginRight: '10px' }} />
          <span style={{ fontSize: '16px' }}>
            {t('views.session.title')}
          </span>
          </div>
            <div style={{ backgroundColor: '#F6F6F6' }} className={`${Styles.link}`} onClick={onClickButtonDownload}>
          <img src={exportimg} style={{ marginRight: '10px' }} />
          <span style={{ fontSize: '16px' }}>
            Download Your Data
          </span>
          </div>
             <div style={{ backgroundColor: '#F6F6F6' }} className={`${Styles.link}`} onClick={onClickButtonDeleteNettoken}>
          <img src={notcomplete} style={{ marginRight: '10px' }} />
          <span style={{ fontSize: '16px', color: '#F8581C' }}>
             {t('views.export.title')}
          </span>
          </div>
          </Fragment>}

          {flagErrorTest && <button
            className={Styles.link}
            onClick={onClickButtonBreakApp}
            type="button">
            Debug this app <span
              aria-label="emoji"
              role="img">🔨</span>
          </button>}
        </div>
      </div>
    </form>
);

SidebarSettingsContainer.defaultProps = {
  email: '',
  getError: noop,
  name: '',
  phone: '',
};

SidebarSettingsContainer.propTypes = {
  email: PropTypes.string,
  flagErrorTest: PropTypes.bool,
  getError: PropTypes.func,
  highlight: PropTypes.bool,
  name: PropTypes.string,
  onCancel: PropTypes.func,
  onChange: PropTypes.func,
  onClickButtonBreakApp: PropTypes.func,
  onClickButtonMore: PropTypes.func,
  onClickButtonPrivacy: PropTypes.func,
  onClickButtonSignOut: PropTypes.func,
  onSubmit: PropTypes.func,
  phone: PropTypes.string,
  /** i18n translate method */
  t: PropTypes.func.isRequired,
  unsavedChanges: PropTypes.bool,
};

export default SidebarSettingsContainer;
