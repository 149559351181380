import React from 'react';
import Tooltip from 'rc-tooltip';
import classNames from 'classnames';
import Styles from './Styles.css';

const OverlayContainer = ({ content, fontIncrease }) => (
  <span className={classNames({
    [Styles.content]: true,
    [Styles.fontIncrease]: fontIncrease,
  })}>
    {content}
  </span>
);

const overlayInnserStyle = {
  color: '#333333',
  textAlign: 'left',
  textDecoration: 'none',
  backgroundColor: 'white',
  padding: '0 5px 1px',
  lineHeight: 1,
  margin: 0,
  verticalAlign: 'middle',
  minHeight: '10px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: 0,
  borderRadius: '1px',
  height: '100%',
  boxShadow: '0 0.25px 1px 0 rgba(0,0,0,0.5)',
  opacity: 1,
  background: 'white',
};

function withTooltip({
  fontIncrease,
  content,
  placement,
  children,
  delay,
  tooltipRef,
  ...props
}) {
  return (
    <Tooltip
      getTooltipContainer={() => (tooltipRef ? tooltipRef.current : document.body)}
      mouseEnterDelay={delay ? 0 : 1}
      overlay={<OverlayContainer content={content} fontIncrease={fontIncrease}/>}
      overlayClassName={Styles.overlay}
      overlayInnerStyle={overlayInnserStyle}
      placement={(placement && placement != null) ? placement : 'bottom'}
      trigger={'hover'}
      children={children}
      {...props}
    />
  );
}

export default withTooltip;
