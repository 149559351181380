/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { isProduction } from '@nettoken/env';

const REDUX_STORE = 'REDUX_STORE';

const fontSize = '14px';
let getState;

const metaStore = {};

/**
 * @param {string} key
 * @param {object} value
 *
 * @returns {object}
 */
const addMetadata = (key, value) => {
  if (key !== REDUX_STORE) return value;
  const meta = updateMetadata(key);
  const wrapped = { meta, value };
  return wrapped;
};

/**
 * @param {string} key
 *
 * @returns {any}
 */
const get = key => {
  const data = window.localStorage[key];
  const value = data ? JSON.parse(data) : undefined;
  log('get', '📦 Load', key, value);
  return value;
};

/**
 * @param {string} action
 *
 * @returns {string}
 */
const getColour = action => {
  if (action === 'set') return 'blue';
  if (action === 'get') return 'green';
  return '#555';
};

/**
 * @param {string} action
 * @param {string} message
 * @param {string} key
 * @param {any} value
 */
const log = (action, message, key, value) => {
  if (isProduction) return;

  let state;
  if (!getState) {
    if (key !== REDUX_STORE || !value) return;
    state = value.value || value;
  }
  else {
    state = getState();
  }

  // Not using getPreset() here because of failing circular dependency in tests.
  if (!state || !state.tools || !state.tools.presets || !state.tools.presets.storage) return;
  console.log(
    `%c${message.toUpperCase()} %c${key}`,
    `color: ${getColour(action)}; font-size: ${fontSize};`,
    `color: ${getColour()}; font-size: ${fontSize};`,
  );
  console.log(value);
};

/**
 * @param {string} key
 * @param {object} value
 *
 * @returns {string}
 */
const set = (key, value) => {
  log('set', '💿 Save', key, value);
  value = JSON.stringify(value);
  window.localStorage[key] = value;
  return value;
};

/**
 * @param {string} key
 *
 * @returns {object} Meta data.
 */
const updateMetadata = key => {
  if (!metaStore[key]) metaStore[key] = {};
  metaStore[key].lastModified = Date.now();
  return { ...metaStore[key] };
};

/**
 * @returns {object}
 * @property {object} [meta] Null if does not exist.
 * @property {object} [value] Undefined if does not exist.
 */
export const getReduxStore = () => {
  const state = get(REDUX_STORE);
  const res = {
    meta: null,
    value: undefined,
  };

  if (state) {
    if (state.meta) {
      metaStore[REDUX_STORE] = { ...state.meta };
      res.meta = state.meta;
      res.value = state.value;
    }
    else {
      res.value = state;
    }
  }

  return res;
};

/**
 * @param {object} state
 *
 * @returns {string}
 */
export const setReduxStore = state => {
  const value = addMetadata(REDUX_STORE, state);
  set(REDUX_STORE, value);
};

export const passStoreMethods = () => (d, gs) => {
  getState = gs;
};

export default { get, set };
