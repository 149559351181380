/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import Loadable from 'react-loadable';
import Blank from './blank';
import View from './view';
import ViewWhite from './viewWhite';

export const Auth = Loadable({
  loader: () => import('pages/auth'),
  loading: ViewWhite,
});

export const Cookies = Loadable({
  loader: () => import('pages/legal/cookies'),
  loading: View,
});

export const Dashboard = Loadable({
  loader: () => import('pages/dashboard'),
  loading: View,
});

export const DeveloperTools = Loadable({
  loader: () => import('DeveloperTools'),
  loading: Blank,
});

export const ErrorView = Loadable({
  loader: () => import('pages/error'),
  loading: View,
});

export const FAQ = Loadable({
  loader: () => import('pages/faq'),
  loading: View,
});

export const GetStarted = Loadable({
  loader: () => import('pages/gettingstarted'),
  loading: View,
});

export const GetPricing = Loadable({
  loader: () => import('pages/getpricing'),
  loading: View,
});

export const About = Loadable({
  loader: () => import('pages/about'),
  loading: View,
});

export const Homepage = Loadable({
  loader: () => import('pages/homepage'),
  loading: ViewWhite,
});

export const Instructions = Loadable({
  loader: () => import('pages/instructions'),
  loading: ViewWhite,
});

export const NavbarDashboard = Loadable({
  loader: () => import('NavbarDashboard'),
  loading: Blank,
});

export const PrivacyPolicy = Loadable({
  loader: () => import('pages/legal/privacy-policy'),
  loading: View,
});

export const TOS = Loadable({
  loader: () => import('pages/legal/terms-and-conditions'),
  loading: View,
});

export const AuthenticationSuccess = Loadable({
  loader: () => import('pages/authentication-success'),
  loading: View,
});

export const AuthManually = Loadable({
  loader: () => import('pages/auth-manually'),
  loading: ViewWhite,
});
