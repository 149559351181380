import React from 'react';
import classNames from 'classnames';
import Helmet from 'Helmet';
import Tooltip from 'Tooltip';
import Switch from 'Switch';
import Input from 'Input';
import { Button } from '@nettoken/components';
import Styles from 'Sidebar/style.css';

const SidebarSettingsContainer = ({
  email,
  getError,
  name,
  onCancel,
  onChange,
  onSubmit,
  phone,
  isTracking,
  setTrackingPermission,
  t,
  unsavedChanges,
}) => {
  const form = [
    {
      autoComplete: 'name',
      label: 'Name',
      name: 'name',
      placeholder: t('placeholder.name'),
      type: 'text',
      value: name,
      content:
        'Your contacts will recognise you with this name when you share accounts. ',
    },
    {
      autoComplete: 'email',
      hidden: !email,
      label: t('global.email'),
      name: 'email',
      placeholder: t('placeholder.emailUser'),
      readOnly: true,
      type: 'email',
      value: email,
      content: 'this is a phone input tooltip conetent',
    },
    {
      autoComplete: 'tel-national',
      label: t('global.phone'),
      name: 'Phone',
      placeholder: t('placeholder.phone'),
      readOnly: true,
      type: 'tel',
      value: phone,
      content:
        'Your phone number is the only personal information used to verify your unique Nettoken account and is critically used to restore a lost account. To update your phone number go to settings in the mobile phone app.',
    },
  ];

  return (
    <form
      action="/"
      className={Styles.sidebarFullHeight}
      method="post"
      onSubmit={onSubmit}
    >
      <Helmet title="Basics" />
      <div
        className={`${Styles.content} ${Styles._menu} ${Styles._scrollable}`}
      >
        <div className={Styles.paddingFixer}>
          <h1 className={Styles.title}>My Nettoken</h1>

          {form.map(row => {
            if (row.hidden) return null;
            return (
              <div
                className={Styles.switchWrapper}
                style={{ display: 'inline-flex' }}
              >
                <Input
                  autoComplete={row.autoComplete}
                  error={getError(row.name)}
                  key={row.name}
                  label={row.label}
                  name={row.name}
                  onChange={onChange}
                  placeholder={row.placeholder}
                  readOnly={row.readOnly}
                  t={t}
                  type={row.type}
                  value={row.value}
                />
                <Tooltip
                  content={row.content}
                  delay={0}
                  fontIncrease={true}
                  align={{
                    points: ['tr', 'bc'],
                  }}
                >
                  <p
                    style={{ width: '35px', height: '29px', marginLeft: '3px' }}
                    className={Styles.hint}
                  >
                    ?
                  </p>
                </Tooltip>
              </div>
            );
          })}
        </div>
        <div className={Styles.switchWrapper}>
          <p>Track my activity</p>
          <Tooltip
            content={t('views.settings.tracking')}
            delay={0}
            fontIncrease={true}
            align={{
              points: ['tr', 'bc'],
            }}
          >
            <p className={Styles.hint}>?</p>
          </Tooltip>
          <Switch checked={isTracking} onChange={setTrackingPermission} />
        </div>
      </div>

      <div className={Styles.actions}>
        <Button className={Styles.button} onClick={onCancel} value={t('global.cancel')} />

        <Button
          className={classNames({
            [Styles.button]: true,
            [Styles.active]: unsavedChanges,
          })}
          value={t('global.save')} />
      </div>
    </form>
  );
};

export default SidebarSettingsContainer;
