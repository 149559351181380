/*
 * Copyright (C) 2018-2019 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { MODAL_ACCOUNT_EDIT } from 'constants/modal';
import { DASHBOARD_SHARING } from 'constants/endpoints';
import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { hideModal, showModal } from 'main/modal';
import { getSharedCredentialData } from 'main/sharedAccounts';
import { deleteCredentials } from 'main/vault/credentials';
import { Request } from 'utils/request';
import withOverlayAction from 'Overlay/withAction';
import { RXCredentialsUpdateOne } from 'main/vault/credentials/reduxActions';
import {
  CW_DECRYPT_USING_MASTER_KEYPAIR,
  CW_ENCRYPT_CREDENTIALS,
  CW_ENCRYPT_USING_MASTER_KEYPAIR,
  CW_ENCRYPT_WITH_EXTERNAL_KEY,
  CW_GENERATE_EPHEMERAL_KEY_PAIR,
  getWorkerPromise,
} from '@nettoken/crypto-worker';
import KeyPair from '@nettoken/crypto-worker/src/keypair';
import Container from './container';
import { MODAL_ACCOUNT_SHARE, MODAL_SHARE_DASHBOARD } from '../../../../constants/modal';
import { RXDashboardAdd, RxEditAccountProccessing } from '../../../../main/modal/reduxActions';
import { getOrCreateKeyPairForSharing } from '../../../../utils/misc';

class ModalShareDashboardUserContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchUser: [],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handlewindow = this.handlewindow.bind(this);
    // this.getOrCreateKeyPairForSharing = this.getOrCreateKeyPairForSharing.bind(this);
    this.prepareCredentials = this.prepareCredentials.bind(this);
    this.hide = this.hide.bind(this);
    this.back = this.back.bind(this);
  }

  componentDidMount() {
    this.props.addOverlayAction('modal', this.hide, false);
  }

  // async getOrCreateKeyPairForSharing(credential) {
  //   const keypair = new KeyPair();
  //   const {
  //     publicKey,
  //     secretKey,
  //   } = credential;
  //   const worker = await getWorkerPromise('crypto');
  //   if (publicKey && secretKey) {
  //     const [pKey, sKey] = await Promise.all([
  //       worker({
  //         event: CW_DECRYPT_USING_MASTER_KEYPAIR,
  //         message: publicKey,
  //       }),
  //       worker({
  //         event: CW_DECRYPT_USING_MASTER_KEYPAIR,
  //         message: secretKey,
  //       }),
  //     ])
  //       .then(values => values.map(v => Object.values(v)
  //         .join('')
  //         .replace('CW_DECRYPT_USING_MASTER_KEYPAIR', '')));

  //     keypair.set(pKey, sKey);
  //   }
  //   else {
  //     const newKeyPair = await worker({
  //       event: CW_GENERATE_EPHEMERAL_KEY_PAIR,
  //       format: 'base64',
  //     });
  //     keypair.set(newKeyPair.publicKey, newKeyPair.secretKey);
  //   }
  //   return keypair;
  // }

  prepareCredentials = ({
    id,
    note,
    groupId,
    accountId,
    name,
    username,
    email,
    password,
    loginUrl,
    phone,
    publicKey,
    secretKey,
    shared,
    picture,
  }) => ({
    id,
    note,
    groupId,
    accountId,
    name,
    username,
    email,
    password,
    loginUrl,
    phone,
    publicKey,
    secretKey,
    shared,
    picture,
  });

  async handleSubmit(event) {
    event.preventDefault();
    this.props.setEditAccountProccessing(true);
    const credential = this.props.data.d;
    const {
      id: sharedWithUserId,
      publicKey: sharedWithUserPublicKey,
    } = this.props.data.e;
    const worker = await getWorkerPromise('crypto');
    const apiPayload = {
      sharedData: [],
      credentials: [],
      dsh_publicKey: '',
      dashboardSpace: {
        id: this.props.dashboardSpace.id,
        name: this.props.dashboardSpace.name,
        publicKey: this.props.dashboardSpace.publicKey,
        secretKey: this.props.dashboardSpace.secretKey,
        userId: this.props.dashboardSpace.userId,
        shared: true,
      },
      sharedWithUserId,
      dsh_secretKey: '',
    };
    const {
      publicKey: publicKeyCred,
      secretKey: secretKeyCred,
    } = await getOrCreateKeyPairForSharing(this.props.dashboardSpace, KeyPair);
    // Get either an existing or new KeyPair
    // let keyPair = getOrCreateKeyPairDashboardForSharing(for: dashboard)
    // Keep a master copy of the shared keyPair encrypted with the owner's KeyPair
    // dashboard.publicKey = keyPair.publicKey.encrypt(with: masterKeyPair.publicKey)
    // dashboard.secretKey = keyPair.secretKey.encrypt(with: masterKeyPair.publicKey)
    const spkey = await Promise.all([
      worker({
        event: CW_ENCRYPT_USING_MASTER_KEYPAIR,
        message: publicKeyCred,
      }),
      worker({
        event: CW_ENCRYPT_USING_MASTER_KEYPAIR,
        message: secretKeyCred,
      }),
    ])
      .then(values => values.map(v => Object.values(v)
        .join('')
        .replace('CW_ENCRYPT_USING_MASTER_KEYPAIR', '')));

    apiPayload.dashboardSpace = {
      ...apiPayload.dashboardSpace,
      publicKey: spkey[0],
      secretKey: spkey[1],
    };
    // dsh_publicKey, dsh_secretKey:
    // Get either an existing or new KeyPair
    // let publicKey = keyPair.publicKey.encrypt(with: sharedUser.publicKey!)
    // let secretKey = keyPair.secretKey.encrypt(with: sharedUser.publicKey!)
    const [{
      encrypted: encryptedDashboardPublicKey,
    }, {
      encrypted: encryptedDashboardSecretKey,
    }] = await Promise.all([
      worker({
        event: CW_ENCRYPT_WITH_EXTERNAL_KEY,
        message: publicKeyCred,
        inputFormat: 'plain',
        outputFormat: 'base64',
        publicKey: sharedWithUserPublicKey,
      }),
      worker({
        event: CW_ENCRYPT_WITH_EXTERNAL_KEY,
        message: secretKeyCred,
        inputFormat: 'plain',
        outputFormat: 'base64',
        publicKey: sharedWithUserPublicKey,
      }),
    ]);

    apiPayload.dsh_publicKey = encryptedDashboardPublicKey;
    apiPayload.dsh_secretKey = encryptedDashboardSecretKey;


    // Re-encrypt the dashboard with the shared keys
    // dashboard.encrypt(context: context, keyPair: keyPair)
    const { encrypted: encryptedD } = await worker({
      event: CW_ENCRYPT_CREDENTIALS,
      credentials: [apiPayload.dashboardSpace],
    });
    if (!encryptedD[0]) {
      throw new Error('Encrypted credentials unavailable');
    }
    apiPayload.dashboardSpace = { ...encryptedD[0] };

    if (this.props.dashboardCredentials.length > 0) {
      this.props.dashboardCredentials.map(async item => {
        const { accountId } = item;
        const {
          publicKey,
          secretKey,
        } = await getOrCreateKeyPairForSharing(item, KeyPair);

        // Get either an existing or new KeyPair
        // let keyPair = getOrCreateKeyPairCredentialForSharing(for: credential)
        // Keep a master copy of the shared keyPair encrypted with the owner's KeyPair
        // credential.publicKey = keyPair.publicKey.encrypt(with: masterKeyPair.publicKey)
        // credential.secretKey = keyPair.secretKey.encrypt(with: masterKeyPair.publicKey)
        const [pKey, sKey] = await Promise.all([
          worker({
            event: CW_ENCRYPT_USING_MASTER_KEYPAIR,
            message: publicKey,
          }),
          worker({
            event: CW_ENCRYPT_USING_MASTER_KEYPAIR,
            message: secretKey,
          }),
        ])
          .then(values => values.map(v => Object.values(v)
            .join('')
            .replace('CW_ENCRYPT_USING_MASTER_KEYPAIR', '')));
        item.publicKey = pKey;
        item.secretKey = sKey;
        item.shared = true;
        item.hasPendingInvites = true;

        // Re-encrypt the credential with the shared keys
        // credential.encrypt(context: context, keyPair: keyPair)
        const { encrypted } = await worker({
          event: CW_ENCRYPT_CREDENTIALS,
          credentials: [item],
        });
        if (!encrypted[0]) {
          throw new Error('Encrypted credentials unavailable');
        }
        // sharedData object
        // Get either an existing or new KeyPair
        // let keyPair = getOrCreateKeyPairCredentialForSharing(for: $0)
        // Create a shared credential object with keys encrypted with the shared user public key
        // let publicKey = keyPair.publicKey.encrypt(with: sharedUser.publicKey!)
        // let secretKey = keyPair.secretKey.encrypt(with: sharedUser.publicKey!)
        const [{
          encrypted: encryptedPublicKey,
        }, {
          encrypted: encryptedSecretKey,
        }] = await Promise.all([
          worker({
            event: CW_ENCRYPT_WITH_EXTERNAL_KEY,
            message: publicKey,
            inputFormat: 'plain',
            outputFormat: 'base64',
            publicKey: sharedWithUserPublicKey,
          }),
          worker({
            event: CW_ENCRYPT_WITH_EXTERNAL_KEY,
            message: secretKey,
            inputFormat: 'plain',
            outputFormat: 'base64',
            publicKey: sharedWithUserPublicKey,
          }),
        ]);

        apiPayload.sharedData.push({
          credentialId: item.id,
          sh_accountId: accountId,
          sh_secretKey: encryptedSecretKey,
          sh_publicKey: encryptedPublicKey,
          sharedWithUserId,
        });
        apiPayload.credentials.push(encrypted[0]);
        if (apiPayload.credentials.length >= this.props.dashboardCredentials.length) {
          const req = new Request(DASHBOARD_SHARING);
          req.authorise()
            .post(apiPayload)
            .then(res => {
              const data = {
                ...res,
                name: this.props.dashboardSpace.name,
              };
              this.props.addDashboard(data);
              this.hide();
            })
            .catch(e => console.log('Error:', e));
        }
      });
    }
    else {
      const req = new Request(DASHBOARD_SHARING);
      req.authorise()
        .post(apiPayload)
        .then(res => {
          const data = {
            ...res,
            name: this.props.dashboardSpace.name,
          };
          this.props.addDashboard(data);
          this.hide();
        })
        .catch(e => console.log('Error:', e));
    }
  }


  handlewindow(e) {
  }

  hide() {
    this.props.hideModal();
  }

  back() {
    this.props.showModal(MODAL_SHARE_DASHBOARD, this.props.data.d);
  }


  render() {
    let t = this.props.data.d;
    let k = this.props.data.e;
    if (this.props.data.d === undefined) {
      t = this.props.data;
      k = this.props.data;
    }

    return (
      <Container
        name={t.name}
        onSubmit={this.handleSubmit}
        onCancel={this.hide}
        onBack={this.back}
        searchuser={this.state.searchUser}
        userWindow={this.handlewindow}
        sharedCredentialData={k}
        t={this.props.t}
        counter={this.props.counter}
        sharedDashboard={this.props.sharedDashboard}
        loading={this.props.editAccountProccessing}/>
    );
  }
}

const mapStateToProps = state => ({
  data: state.ui.modalData || {},
  sharedAccounts: state.sharedAccounts,
  counter: Object.values(state.credentials.data).filter(x => 'dashboardSpaceId' in x && x.dashboardSpaceId == state.ui.modalData.d.id).length,
  dashboardCredentials: Object.values(state.credentials.data).filter(x => 'dashboardSpaceId' in x && x.dashboardSpaceId == state.ui.modalData.d.id),
  dashboardSpace: state.ui.dashboards[state.ui.modalData.d.id],
  editAccountProccessing: state.ui.editAccountProccessing,
  sharedDashboard: 'shared' in state.ui.dashboards[state.ui.modalData.d.id] && state.ui.dashboards[state.ui.modalData.d.id].shared,
});


const mapDispatchToProps = dispatch => ({
  deleteCredentials: args => dispatch(deleteCredentials(args)),
  getSharedCredentials: id => dispatch(getSharedCredentialData(id)),
  updateCredential: (id, data) => dispatch(RXCredentialsUpdateOne(id, data)),
  showModal: (name, data) => dispatch(showModal(name, data)),
  hideModal: () => {
    dispatch(RxEditAccountProccessing(false));
    return dispatch(hideModal());
  },
  setEditAccountProccessing: status => dispatch(RxEditAccountProccessing(status)),
  addDashboard: dashboardName => dispatch(RXDashboardAdd(dashboardName)),
});

export default translate()(connect(
  mapStateToProps,
  mapDispatchToProps,
)(withOverlayAction(ModalShareDashboardUserContainer)));
