/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from '@nettoken/components';
import Styles from 'Modal/style.css';
import { PropIcon } from 'utils/propTypes';
import Avatar from 'Avatar';
import sharedAccountIcon from 'icons/upwardarrow.svg';
import sharedToMeIcon from 'icons/downwardarrow.svg';
import CancelIcon from '../../../ActionIcons/CancelIcon';

const ModalUnsavedChangesContainer = ({
  onCancel,
  onSubmit,
  t,
  editAccountProccessing,
  domain,
  loginUrl,
  picture,
  name,
  isExternalAccount,
  show,
  sharedDashboard,
  sharedCredentialData,
}) => (
  <form
    action="/"
    className={Styles.whiteBackground}
    method="post"
    onSubmit={onSubmit}>
    <h1
      aria-label={t('modal.accountEdit.title')}
      className={Styles.title1}>
      Warning
      <CancelIcon onCancel={onCancel} />
    </h1>
    <div>
        <div className={classNames({
          [Styles.iconHeaderWrapper]: true,
          [Styles.noBg]: true,
        })}>
          {
            (
              isExternalAccount && show && sharedDashboard
            ) ||
            (
              !isExternalAccount &&
              !sharedDashboard &&
              sharedCredentialData &&
              sharedCredentialData.filter(x => !x.sharedByDashboard).length > 0
            ) ?
              (
                <img
                  src={sharedAccountIcon}
                  alt=''
                  className={Styles.sharedIcon}
                />
              ) : null
          }
          {isExternalAccount && !sharedDashboard &&
            <img
              src={sharedToMeIcon}
              alt=''
              className={Styles.sharedIcon}
            />
          }
          <Avatar
            className={Styles.iconHeaderWrapper}
            domain={domain}
            loginUrl={loginUrl}
            name={name}
            picture={picture}
            source='true'
          />
          <div style={{ textAlign: 'center' }}>{name}</div>
        </div>
    </div>
    <p className={Styles.promptText} style= {{ maxWidth: 'none', paddingBottom: '100px' }}>
      {t('modal.unsavedChanges.text')}
    </p>

    <div className={`${Styles.actions} ${Styles.actionsFixed2}`}>
      <Button
        className={`${Styles.button} ${Styles.danger}`}
        onClick={onCancel}
        value={t('modal.unsavedChanges.no')} />

      <Button
        className={classNames({
          [Styles.button]: true,
          [Styles.notDisabled]: true,
        })}
        loading={editAccountProccessing}
        type="submit"
        value={t('modal.unsavedChanges.yes')} />
    </div>
  </form>
);

ModalUnsavedChangesContainer.defaultProps = {
  name: '',
  picture: '',
};

ModalUnsavedChangesContainer.propTypes = {
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  name: PropTypes.string,
  picture: PropIcon(),
  loginUrl: PropTypes.string,
  /** i18n translate method */
  t: PropTypes.func.isRequired,
};

export default ModalUnsavedChangesContainer;
