/*
 * Copyright (C) 2018-2019 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import {
  MODAL_SHARE_DASHBOARD_RULES, MODAL_SHARE_DASHBOARD_USER, MODAL_STOP_SHARING,
  MODAL_SHARING_BY_INVITATION, MODAL_USER_SHARED, MODAL_ACCOUNT_EDIT, MODAL_SHARE_DASHBOARD,
  MODAL_USER_DETAIL,
} from 'constants/modal';
import { URL_GROUPS, URL_SHARED_USER, URL_CREDENTIALS_SHARED } from 'constants/endpoints';
import React from 'react';
import { List } from 'immutable';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';

import { showModal, hideModal } from 'main/modal';
import { getUserProfile } from 'main/user/reduxState';
import { getSharedCredentialData } from 'main/sharedAccounts';
import { deleteCredentials } from 'main/vault/credentials';
import { Request } from 'utils/request';
import { getCredentialsRootObject, getOneCredential } from 'main/vault/credentials/reduxState';
import withOverlayAction from 'Overlay/withAction';
import Container from './container';
import { MODAL_STOP_SHARING_DASHBOARD } from '../../../../constants/modal';

class ModalShareDashboardComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchUser: [],
      sharingStatus: {},
      deleteProcessing: false,
      disableSharing: {},
    };
    this.onchangeInput = this.onchangeInput.bind(this);
    this.handlewindow = this.handlewindow.bind(this);
    this.hide = this.hide.bind(this);
    this.onMouseEnterSharingStatus = this.onMouseEnterSharingStatus.bind(this);
    this.onMouseLeaveSharingStatus = this.onMouseLeaveSharingStatus.bind(this);
    this.handleSharingAccount = this.handleSharingAccount.bind(this);
    this.onClickHandler = this.onClickHandler.bind(this);
    this.accountClick = this.accountClick.bind(this);
    this.checkAlreadyExistsDashboard = this.checkAlreadyExistsDashboard.bind(this);
  }

  componentDidMount() {
    this.props.addOverlayAction('modal', this.props.hideModal);
  }

  onClickHandler(credential) {
    this.props.showModal(MODAL_SHARING_BY_INVITATION, credential);
  }

  checkAlreadyExistsDashboard(usersList, sharedId) {
    const { dashboards, userSelfId } = this.props;
    if (usersList.some(user => user.id === sharedId)) return true;
    if ('usersSharedWith' in dashboards[this.props.data.id]) {
      let checkAlreadyExists = false;
      dashboards[this.props.data.id].usersSharedWith.map(acc => {
        if (acc.id == sharedId) {
          checkAlreadyExists = true;
        }
      });
      return checkAlreadyExists;
    }
    return false;
  }

  checkValueRegex = (event, value) => value && new RegExp(`(^|\\s)${event.target.value.toUpperCase()}`).test(value) && event.target.value !== '+';

  onchangeInput(event) {
    const phone = (event.target.value && !event.target.value.startsWith('+')) ? `+44${event.target.value.substr(event.target.value.length - 10)}` : event.target.value;
    if (this.props.userPhone == phone) {
      this.setState({
        searchUser: [{
          type: 'myself',
          errorMessage: true,
          name: 'You cannot share with yourself',
        }],
      });
      return;
    }
    if ((!isNaN(+phone)) && (phone.length >= 13)) {
      this.setState({
        searchUser: [],
      });
      const { sharedAccounts } = this.props;
      const pho = { phone };
      const userreq = new Request(URL_SHARED_USER);
      userreq.authorise().post(pho)
        .then(res => {
          const checkAlreadyExists = this.checkAlreadyExistsDashboard([], res.id);
          if (checkAlreadyExists) {
            this.setState({
              searchUser: [{
                type: 'already_shared',
                name: res.name.trim(),
                errorMessage: true,
              }],
            });
            return;
          }

          this.setState({
            searchUser: [{
              ...res,
              name: res.name || 'XXXXXX',
            }],
          });
        })
        .catch(e => {
          this.setState({
            searchUser: [{
              type: 'not_found',
              errorMessage: true,
            }],
          });
          console.log('Error:', e);
        });
    }
    else if ((isNaN(+phone)) && event.target.value && event.target.value.length > 0 && event.target.value != '+') {
      this.setState({
        searchUser: [{}],
      });
      let alreadySharedCount = false;
      let alreadySharedName = '';
      const { sharedAccounts, dashboards } = this.props;
      let usersList = [];
      const credentials = this.props.dispatch(getCredentialsRootObject());
      if (Object.keys(credentials).length > 0) {
        Object.keys(credentials).forEach(cred => {
          if (credentials[cred].sharedByUserPublicKey != '') {
            const value = credentials[cred].sharedByUserName ?
              credentials[cred].sharedByUserName.toUpperCase() :
              null;
            if (this.checkValueRegex(event, value)) {
              const checkAlreadyExists =
              this.checkAlreadyExistsDashboard(usersList, credentials[cred].sharedByUserId);
              if (checkAlreadyExists) {
                alreadySharedCount = true;
                alreadySharedName = credentials[cred].sharedByUserName;
              }
              else {
                usersList.push({
                  id: credentials[cred].sharedByUserId,
                  name: credentials[cred].sharedByUserName,
                  publicKey: credentials[cred].sharedByUserPublicKey,
                  phone: credentials[cred].sharedByUserPhone,
                });
              }
            }
          }
        });
        Object.keys(sharedAccounts).forEach(accId => {
          sharedAccounts[accId].map(sharedAccount => {
            const value = sharedAccount.name ? sharedAccount.name.toUpperCase() : null;
            if (this.checkValueRegex(event, value)) {
              const checkAlreadyExists =
              this.checkAlreadyExistsDashboard(usersList, sharedAccount.id);
              if (checkAlreadyExists) {
                alreadySharedCount = true;
                alreadySharedName = sharedAccount.name;
              }
              else {
                usersList.push(sharedAccount);
              }
            }
          });
        });
      }

      Object.keys(dashboards).map(dashboardId => {
        if ('usersSharedWith' in dashboards[dashboardId]) {
          dashboards[dashboardId].usersSharedWith.map(userData => {
            const value = userData.name.toUpperCase();
            if (this.checkValueRegex(event, value)) {
              const checkAlreadyExists = this.checkAlreadyExistsDashboard(usersList, userData.id);
              if (!checkAlreadyExists) {
                usersList.push({
                  ...userData,
                });
              }
              else {
                alreadySharedCount = true;
                alreadySharedName = userData.name;
              }
            }
          });
        }
        if ('sharedByUserId' in dashboards[dashboardId] && dashboards[dashboardId].sharedByUserId) {
          let value;
          let originalValue;
          if ('invitationId' in dashboards[dashboardId] && dashboards[dashboardId].invitationId) {
            value = dashboards[dashboardId].sharedByUser ?
              dashboards[dashboardId].sharedByUser.toUpperCase() :
              null;
            originalValue = dashboards[dashboardId].sharedByUser;
          }
          else {
            value = dashboards[dashboardId].sharedByUserName ?
              dashboards[dashboardId].sharedByUserName.toUpperCase() :
              null;
            originalValue = dashboards[dashboardId].sharedByUserName;
          }
          if (this.checkValueRegex(event, value)) {
            const checkAlreadyExists =
            this.checkAlreadyExistsDashboard(usersList, dashboards[dashboardId].sharedByUserId);
            if (!checkAlreadyExists) {
              usersList.push({
                ...dashboards[dashboardId],
                name: originalValue,
                publicKey: dashboards[dashboardId].sharedByUserPublicKey,
                phone: dashboards[dashboardId].sharedByUserPhone,
                id: dashboards[dashboardId].sharedByUserId,
              });
            }
            else {
              alreadySharedCount = true;
              alreadySharedName = originalValue;
            }
          }
        }
      });

      if (usersList.length == 1 &&
        usersList.filter(user => user.id == this.props.userSelfId).length > 0) {
        this.setState({
          searchUser: [{
            type: 'myself',
            errorMessage: true,
            name: 'You cannot share with yourself',
          }],
        });
        return;
      }
      usersList = usersList.filter(user => user.id != this.props.userSelfId);
      if (usersList.length > 0) {
        this.setState({
          searchUser: usersList,
        });
      }
      else {
        const tmp = 0;
        if (alreadySharedCount) {
          this.setState({
            searchUser: [{
              type: 'already_shared',
              errorMessage: true,
              name: alreadySharedName,
            }],
          });
        }
        else {
          this.setState({
            searchUser: [{
              type: 'not_found_with_name',
              errorMessage: true,
            }],
          });
        }
      }
    }
    else {
      this.setState({
        searchUser: [],
      });
    }
  }

  handlewindow(e) {
    if (e.errorMessage) {
      this.setState({
        searchUser: [],
      });
    }
    else {
      const d = this.props.data;
      const data = { d, e };
      this.props.showModal(MODAL_SHARE_DASHBOARD_USER, data);
    }
  }

  hide() {
    this.props.showModal(MODAL_SHARE_DASHBOARD_RULES, this.props.data);
  }

  onMouseEnterSharingStatus(user) {
    this.setState({
      sharingStatus: {
        [user]: true,
      },
    });
  }

  onMouseLeaveSharingStatus(user) {
    this.setState({
      sharingStatus: {
        [user]: false,
      },
    });
  }

  async handleSharingAccount(id, phone, name, isAccepted) {
    this.props.showModal(MODAL_STOP_SHARING_DASHBOARD, {
      ...this.props.dashboardData,
      sharedWithName: name,
      accepted: isAccepted,
      phone,
      sharedWith: id,
      ...this.props.data,
      currentPopup: MODAL_SHARE_DASHBOARD,
      currentPopupData: this.props.data,
    });
    // credential.unshareWithUserIds = id;
    // credential.ishidepopup = credential.responded;
    // credential.sharedWithName = name;
    // credential.isSharedWithAccepted = true;
    // this.props.showModal(MODAL_STOP_SHARING, credential);
  }

  accountClick(account) {
    const userAccountDetails = [];
    const sharedAccountDetail = {};
    const sharedByAccounts = [];
    Object.keys(this.props.sharedAccounts).forEach(accId => {
      const tmpAccountDetails = this.props.sharedAccounts[accId].find(acc => acc.id === account.id);
      if (tmpAccountDetails) {
        Object.keys(this.props.credentials.data).forEach(credId => {
          let sharedDashboard = false;
          if (
            'dashboardSpaceId' in this.props.credentials.data[credId] &&
            this.props.credentials.data[credId].dashboardSpaceId &&
            'shared' in this.props.dashboards[this.props.credentials.data[credId].dashboardSpaceId] &&
            this.props.dashboards[this.props.credentials.data[credId].dashboardSpaceId].shared
          ) {
            sharedDashboard = true;
          }
          if (credId === accId && !sharedDashboard) {
            userAccountDetails.push(this.props.credentials.data[credId]);
            sharedAccountDetail[credId] = tmpAccountDetails;
          }
        });
      }
    });
    Object.keys(this.props.credentials.data).forEach(key => {
      if (this.props.credentials.data[key].sharedByUserId === account.id) {
        let sharedDashboard = false;
        if (
          'dashboardSpaceId' in this.props.credentials.data[key] &&
          this.props.credentials.data[key].dashboardSpaceId &&
          'shared' in this.props.dashboards[this.props.credentials.data[key].dashboardSpaceId] &&
          this.props.dashboards[this.props.credentials.data[key].dashboardSpaceId].shared
        ) {
          sharedDashboard = true;
        }
        if (!sharedDashboard) {
          sharedByAccounts.push(this.props.credentials.data[key]);
          if (
            (('phone' in account && account.phone === '') || !('phone' in account)) &&
            'sharedByUserPhone' in this.props.credentials.data[key]
          ) {
            account.phone = this.props.credentials.data[key].sharedByUserPhone;
          }
        }
      }
    });
    this.props.dispatch(showModal(MODAL_USER_DETAIL, {
      userAccountDetails,
      account,
      oldData: this.props.data,
      oldModal: MODAL_SHARE_DASHBOARD,
      sharedAccountDetail,
      sharedByAccounts,
    }));
  }

  render() {
    const { sharedAccounts, data } = this.props;
    const profile = this.props.getUserProfile();
    const arr = [...new Set(this.state.searchUser)];
    return (
      <Container
        name={this.props.data.name}
        onCancel={this.hide}
        onClose={() => this.props.hideModal()}
        onchnageInput={this.onchangeInput}
        searchuser={arr}
        userWindow={this.handlewindow}
        onMouseEnterSharingStatus={this.onMouseEnterSharingStatus}
        onMouseLeaveSharingStatus={this.onMouseLeaveSharingStatus}
        sharingStatus={this.state.sharingStatus}
        disableSharing={this.state.disableSharing}
        handleSharingAccount={this.handleSharingAccount}
        deleteProcessing={this.state.deleteProcessing}
        t={this.props.t}
        userName={profile.name}
        dashbordData={this.props.data}
        counter={this.props.counter}
        alreadySharedDashboard={this.props.alreadySharedDashboard}
        sharedWithMeDashboard={this.props.sharedWithMeDashboard}
        sharedCredentialData={this.props.usersSharedWith}
        openCredentialSharedBy={this.openCredentialSharedBy}
        onClickHandler={this.onClickHandler}
        accountClick={this.accountClick}
        ownerName={this.props.ownerName}
        ownerPhone={this.props.ownerPhone}
        modalDashboard={this.props.modalDashboard}
        />
    );
  }
}

const mapStateToProps = state => ({
  dashboardData: state.ui.dashboards[state.ui.modalData.id],
  data: state.ui.modalData || {},
  sharedAccounts: state.sharedAccounts,
  counter: Object.values(state.credentials.data).filter(x => 'dashboardSpaceId' in x && x.dashboardSpaceId == state.ui.modalData.id).length,
  alreadySharedDashboard: 'shared' in state.ui.dashboards[state.ui.modalData.id] && state.ui.dashboards[state.ui.modalData.id].shared &&
    'usersSharedWith' in state.ui.dashboards[state.ui.modalData.id] && state.ui.dashboards[state.ui.modalData.id].usersSharedWith.length > 0,
  sharedWithMeDashboard: 'externalDashboard' in state.ui.dashboards[state.ui.modalData.id] && state.ui.dashboards[state.ui.modalData.id].externalDashboard,
  dashboards: state.ui.dashboards,
  usersSharedWith: 'usersSharedWith' in state.ui.dashboards[state.ui.modalData.id] ?
    state.ui.dashboards[state.ui.modalData.id].usersSharedWith.filter(x => (
      x.id != state.user.profile.id)) :
    [],
  userPhone: state.user.profile.phone,
  userSelfId: state.user.profile.id,
  credentials: state.credentials,
  ownerName: 'externalDashboard' in state.ui.dashboards[state.ui.modalData.id] && state.ui.dashboards[state.ui.modalData.id].externalDashboard ? state.ui.dashboards[state.ui.modalData.id].sharedByUserName : '',
  ownerPhone: 'externalDashboard' in state.ui.dashboards[state.ui.modalData.id] && state.ui.dashboards[state.ui.modalData.id].externalDashboard ? state.ui.dashboards[state.ui.modalData.id].sharedByUserPhone : '',
  modalDashboard: state.ui.dashboards[state.ui.modalData.id],
});


const mapDispatchToProps = dispatch => ({
  dispatch,
  getUserProfile: () => dispatch(getUserProfile()),
  deleteCredentials: args => dispatch(deleteCredentials(args)),
  showModal: (name, data) => dispatch(showModal(name, data)),
  hideModal: () => dispatch(hideModal()),
});

export default translate()(connect(
  mapStateToProps,
  mapDispatchToProps,
)(withOverlayAction(ModalShareDashboardComponent)));
