/*
 * Copyright (C) 2018-2019 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { findNode } from '@nettoken/helpers';
import { hideModal, showModal } from 'main/modal';
import { hideTutorial, showTutorial } from 'main/tutorial';
import withOverlayAction from 'Overlay/withAction';
import { removeSharedByAccounts } from 'main/sharedAccounts';
import Container from './container';
import {
  MODAL_ACCOUNT_EDIT,
  MODAL_STOP_SHARING,
  MODAL_SHARING_BY_INVITATION,
  MODAL_SHARING_DASHBOARD_BY_INVITATION,
  MODAL_EDIT_DASHBOARD,
  MODAL_USER_DETAIL,
  MODAL_STOP_SHARING_DASHBOARD,
} from '../../../../constants/modal';
import { TEXT_CONTACT_SHARED_WITH } from '../../../../constants/ids';

class ModalUserAccountDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sharingStatus: {},
      deleteProcessing: false,
      disableSharing: {},
    };

    this.hide = this.hide.bind(this);
    this.onBack = this.onBack.bind(this);
    this.onClickHandler = this.onClickHandler.bind(this);
    this.declineSharingInvitation = this.declineSharingInvitation.bind(this);
    this.onMouseEnterSharingStatus = this.onMouseEnterSharingStatus.bind(this);
    this.onMouseLeaveSharingStatus = this.onMouseLeaveSharingStatus.bind(this);
    this.handleSharingAccount = this.handleSharingAccount.bind(this);
    this.openCredential = this.openCredential.bind(this);
    this.openCredentialSharedBy = this.openCredentialSharedBy.bind(this);
    this.showHintBlock = this.showHintBlock.bind(this);
    this.onDashboardClick = this.onDashboardClick.bind(this);
  }

  componentDidMount() {
    this.props.addOverlayAction('modal', this.hide);
  }

  hide() {
    this.props.hide();
  }

  onClickHandler(credential) {
    this.props.showModal(MODAL_SHARING_BY_INVITATION, credential);
  }

  async declineSharingInvitation() {
    this.setState({ deleteProcessing: true });
    const params = {
      data: this.props.data.oldData,
      unshareWithUserIds: [],
    };

    try {
      const res = await this.props.removeSharedByAccounts(params);
      this.setState({ deleteProcessing: false });
      this.props.hide();
    }
    catch (error) {
      console.log(error, 'declineSharingInvitation');
    }
  }

  onMouseEnterSharingStatus(user) {
    this.setState({
      sharingStatus: {
        [user]: true,
      },
    });
  }

  onMouseLeaveSharingStatus(user) {
    this.setState({
      sharingStatus: {
        [user]: false,
      },
    });
  }

  async handleSharingAccount(credential, id, phone, name, isAccepted, type) {
    if (type == 'dashboard') {
      this.props.showModal(MODAL_STOP_SHARING_DASHBOARD, {
        ...credential,
        sharedWithName: name,
        accepted: isAccepted,
        phone,
        sharedWith: id,
        ...this.props.data,
        currentPopup: MODAL_USER_DETAIL,
        currentPopupData: this.props.data,
      });
    }
    else {
      credential.unshareWithUserIds = id;
      credential.ishidepopup = this.props.data.sharedAccountDetail[credential.id].responded;
      credential.sharedWithName = name;
      credential.isSharedWithAccepted = true;
      this.props.showModal(MODAL_STOP_SHARING, credential);
    }
  }

  openCredential(credential) {
    this.props.showModal(MODAL_ACCOUNT_EDIT, credential);
  }

  openCredentialSharedBy(credential) {
    if (credential.accepted) {
      this.props.showModal(MODAL_ACCOUNT_EDIT, credential);
    }
    else {
      this.props.showModal(MODAL_SHARING_BY_INVITATION, credential);
    }
  }

  onBack() {
    this.props.showModal(this.props.data.oldModal, this.props.data.oldData);
  }

  async showHintBlock(tutorialName, id) {
    await findNode(`#${id}`);
    this.props.showTutorial({
      coords: {
        left: tutorialName === TEXT_CONTACT_SHARED_WITH ? '51px' : '51px',
        top: tutorialName === TEXT_CONTACT_SHARED_WITH ? '-31px' : '-31px',
      },
      direction: 'left',
      placement: 'center',
      type: 'tooltip',
      id,
      light: true,
      isFixed: false,
      name: tutorialName,
      onConfirm: null,
      text: tutorialName,
      zIndex: 999,
    });
    this.hasHint = true;
  }

  onDashboardClick(dashboard) {
    if (dashboard.invitationId) {
      this.props.showModal(MODAL_SHARING_DASHBOARD_BY_INVITATION, {
        ...dashboard,
        sharedByUserName: dashboard.sharedByUser,
      });
    }
    else {
      this.props.showModal(MODAL_EDIT_DASHBOARD, {
        id: dashboard.id,
        dashboardName: dashboard.name,
        prevModalData: {
          id: MODAL_USER_DETAIL,
          data: this.props.data,
        },
      });
    }
  }

  render() {
    return (
      <Container
        onCancel={this.hide}
        onBack={this.onBack}
        data={this.props.data}
        onClickHandler={this.onClickHandler}
        onMouseEnterSharingStatus={this.onMouseEnterSharingStatus}
        onMouseLeaveSharingStatus={this.onMouseLeaveSharingStatus}
        sharingStatus={this.state.sharingStatus}
        handleSharingAccount={this.handleSharingAccount}
        deleteProcessing={this.state.deleteProcessing}
        disableSharing={this.state.disableSharing}
        openCredential={this.openCredential}
        openCredentialSharedBy={this.openCredentialSharedBy}
        showHintBlock={this.showHintBlock}
        hideHintBlock={tutorialName => this.props.hideTutorial(tutorialName)}
        t={this.props.t}
        dashboards={this.props.dashboards}
        onDashboardClick={this.onDashboardClick}/>
    );
  }
}

const mapDispatchToState = state => {
  const dashboards = [];
  Object.values(state.ui.dashboards).map(dashboard => {
    const count = Object.values(state.credentials.data).filter(cred => 'dashboardSpaceId' in cred && cred.dashboardSpaceId == dashboard.id).length;
    if (
      'usersSharedWith' in dashboard &&
      dashboard.usersSharedWith.filter(x => x.id == state.ui.modalData.account.id).length > 0
    ) {
      dashboards.push({
        ...dashboard,
        responded: dashboard.usersSharedWith
          .filter(x => x.id == state.ui.modalData.account.id)[0].responded,
        count,
      });
    }
    else if ('invitationId' in dashboard && dashboard.sharedByUserId == state.ui.modalData.account.id) {
      dashboards.push({
        ...dashboard,
        responded: false,
        count,
      });
    }
    else if ('externalDashboard' in dashboard && dashboard.sharedByUserId == state.ui.modalData.account.id) {
      dashboards.push({
        ...dashboard,
        responded: true,
        count,
      });
    }
  });
  return {
    data: state.ui.modalData || {},
    dashboards,
  };
};

const mapDispatchToProps = dispatch => ({
  hide: () => dispatch(hideModal()),
  showModal: (name, data) => dispatch(showModal(name, data)),
  removeSharedByAccounts: params => dispatch(removeSharedByAccounts(params)),
  hideTutorial: name => dispatch(hideTutorial(name)),
  showTutorial: args => dispatch(showTutorial(args)),
});

export default translate()(connect(
  mapDispatchToState,
  mapDispatchToProps,
)(withOverlayAction(ModalUserAccountDetails)));
