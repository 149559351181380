import React from 'react';
import Styles from 'Modal/style.css';
import { getIconColorNewmethod } from '../../utils/misc';
import SharingBtn from '../ActionIcons/SharingBtn';

const AccountSharedWithInLine = ({
  sharedCredentialData,
  onClickUser,
  onMouseEnterSharingStatus,
  onMouseLeaveSharingStatus,
  sharingStatus,
  disableSharing,
  handleSharingAccount,
  externalDashboard,
  credentialData,
  chevronRightStyle,
}) => (
  <>
    {sharedCredentialData.map((account, index) => (
      !account.sharedByDashboard ? <div className={Styles.outerSharedDetails} style={{ marginLeft: '-5px', width: '354px', height: '41px' }}> <div
      className={Styles.sharedDetails}
      style={{ paddingLeft: '6px', paddingTop: '5px' }}
      key={index}
      onClick={() => {
        onClickUser(account);
      }}
      >
      <div className={Styles.sharedCredDetails} style={{
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
      }}>
      <svg width='100%' height='100%' style={{ border: `1px solid ${getIconColorNewmethod(account.name)}` }}>
        <rect x='0' y='0' fill={getIconColorNewmethod(account.name)} height='100%' width='100%'></rect>
        <text
          dominantBaseline='middle'
          fill='#ffffff'
          fontSize='10'
          textAnchor='middle'
          x='48%'
          y='54%'
        >{account.name.slice(0, 2).toUpperCase()}</text>
      </svg>
        <span style={{
          flex: 1,
          minWidth: 0,
          height: '1.7rem',
          lineHeight: '1.5rem',
        }}>{account.name.length > 18 ? account.name.substring(0, 18).concat('...') : account.name}</span>
      </div>
      <div>
        <SharingBtn
          account={account}
          onMouseEnterSharingStatus={onMouseEnterSharingStatus}
          onMouseLeaveSharingStatus={onMouseLeaveSharingStatus}
          sharingStatus={sharingStatus}
          disableSharing={disableSharing}
          handleSharingAccount={handleSharingAccount}
          externalDashboard={externalDashboard}
          credentialData={credentialData}
          chevronRightStyle={chevronRightStyle}
        />
      </div></div></div> : ''
    ))}
  </>
);

export default AccountSharedWithInLine;
