/*
 * Copyright (C) 2018-2019 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { isProduction } from '@nettoken/env';
import { eventTarget, findNode, JWTDecode } from '@nettoken/helpers';
import Validator from '@nettoken/validator';
import Routes from 'config/routes';
import { goTo } from 'main/router';
import { setSessionTimeout, setConnectionLostTimeout } from 'main/preferences';
import withOverlayAction from 'Overlay/withAction';
import { hideModal } from 'main/modal';
import { hideTutorial, showTutorial } from 'main/tutorial';
import { Request } from 'utils/request';
import Container from './container';
import { MODAL_UNSAVED_CHANGES } from '../../../../constants/modal';
import { showModal } from '../../../../main/modal';
import { SESSION_TIMEOUT_INACTIVITY_TEXT } from '../../../../constants/ids';
import { URL_SETTINGS } from '../../../../constants/endpoints';
import { RXPrefsSessionTimeout } from '../../../../main/preferences/reduxActions';
import getConnectionLostTimeout from '../../../../main/preferences/reduxState';


const minToSec = minutes => minutes * 60;
const hrsToSec = hours => hours * minToSec(60);

class SidebarSessionComponent extends React.Component {
  constructor(props) {
    super(props);

    const { t } = props;
    let options = [{
      label: t('views.session.options.thirtyMinutes'),
      value: minToSec(30),
    }, {
      label: t('views.session.options.eightHours'),
      value: hrsToSec(8),
    }, {
      label: t('views.session.options.oneDay'),
      value: hrsToSec(24),
    }];

    const lostConnectionOptions = [{
      label: '5 seconds',
      value: 5,
    }, {
      label: '30 seconds',
      value: 30,
    }, {
      label: 'Never',
      value: 0,
    }];

    if (!isProduction) {
      options = [
        {
          label: '10 seconds (sonic 🦔)',
          value: 10,
        }, {
          label: '30 seconds (fast life ⚡️)',
          value: 30,
        },
        ...options,
      ];
    }

    this.state = {
      initialTimeout: 0,
      options,
      lostConnectionOptions,
      timeout: 0,
      lostConnectionTimeoutInitial: 0,
      lostConnectionTimeoutNew: 0,
    };

    this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleAction = this.handleAction.bind(this);
    this.handleSubmitAction = this.handleSubmitAction.bind(this);
    this.showHintBlock = this.showHintBlock.bind(this);
  }

  componentDidMount() {
    this.props.addOverlayAction('modal', () => this.handleAction(() => this.props.hide()));
    this.setState({ lostConnectionTimeoutInitial: this.props.connectionLostTimeout });
    this.setState({ lostConnectionTimeoutNew: this.props.connectionLostTimeout });
    this.setInitialTimeout();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.session.accessToken !== this.props.session.accessToken) {
      this.setInitialTimeout();
    }
  }

  setSessionTimedOut(timeout) {
    const params = {
      sessionTimeout: timeout,
      connectionLostTimeout: this.props.connectionLostTimeout,
    };
    const req = new Request(URL_SETTINGS);
    return req.authorise().put(params)
      .then(res => this.props.setSessionTimeToken(res.accessToken))
      .then(console.log('Settings API call'))
      .catch(e => {
        console.log(e);
        return Promise.reject(e);
      });
  }

  getCurrentTimeout() {
    if (!this.props.session.accessToken) return -1;
    const jwt = JWTDecode(this.props.session.accessToken) || {};
    const createdAt = (jwt.iat || 0) * 1000;
    const expiryDate = (jwt.exp || 0) * 1000;
    const currentValueInSeconds = (expiryDate - createdAt) / 1000;

    // Check if the current value is in the options list.
    const { options } = this.state;
    for (let i = 0; i < options.length; i += 1) {
      if (options[i].value === currentValueInSeconds) {
        return currentValueInSeconds;
      }
    }
    return -1;
  }

  handleChangeCheckbox(event) {
    const { value } = eventTarget(event);
    const timeout = Validator.strToInt(value);
    this.setState({ timeout });
  }

  handlerForConnectionLost = event => {
    const { value } = eventTarget(event);
    const timeout = Validator.strToInt(value);
    this.setState({ lostConnectionTimeoutNew: timeout });
  }

  handleSubmit(event) {
    event.preventDefault();

    const {
      initialTimeout,
      timeout,
      lostConnectionTimeoutInitial,
      lostConnectionTimeoutNew,
    } = this.state;

    if (lostConnectionTimeoutNew !== lostConnectionTimeoutInitial) {
      this.props.setConnectionLostTimeout(lostConnectionTimeoutNew, timeout <= 30 ? 86400 : timeout)
        .then(() => this.setState({ lostConnectionTimeoutInitial: lostConnectionTimeoutNew }));
    }

    if (initialTimeout !== timeout) {
      this.props.setTimeout(timeout <= 30 ? 86400 : timeout)
        .then(() => this.setState({ initialTimeout: timeout <= 30 ? 86400 : timeout }));
    }
  }

  handleSubmitAction(e, callback) {
    this.handleSubmit(e);
    callback();
  }

  handleAction(callback) {
    const {
      initialTimeout,
      timeout,
      lostConnectionTimeoutInitial,
      lostConnectionTimeoutNew,
    } = this.state;
    if (
      lostConnectionTimeoutNew !== lostConnectionTimeoutInitial ||
      initialTimeout !== timeout
    ) {
      this.props.showModal(MODAL_UNSAVED_CHANGES, {
        onCancel: () => {
          this.setState({
            lostConnectionTimeoutNew: lostConnectionTimeoutInitial,
            timeout: initialTimeout,
          });
          callback();
        },
        onConfirm: e => this.handleSubmitAction(e, callback),
      });
      return;
    }

    callback();
  }

  setInitialTimeout() {
    const tempTimeout = this.getCurrentTimeout();
    const timeout = tempTimeout == -1 ||
      tempTimeout == 0 ||
      tempTimeout == 30 ||
      tempTimeout == 10 ? 86400 : tempTimeout;
    this.setSessionTimedOut(timeout);
    this.setState({ initialTimeout: timeout, timeout });
  }

  async showHintBlock(tutorialName, nodeId) {
    const id = nodeId;
    await findNode(`#${id}`);
    this.props.showTutorial({
      coords: {
        left: '35px',
        top: tutorialName === SESSION_TIMEOUT_INACTIVITY_TEXT ? '-35px' : '43px',
      },
      direction: 'left',
      placement: 'center',
      type: 'tooltip',
      id,
      light: true,
      isFixed: false,
      name: tutorialName,
      onConfirm: null,
      text: tutorialName,
      zIndex: 999,
    });
    this.hasHint = true;
  }

  render() {
    return (
      <Container
        onCancel={this.props.hide}
        onChangeCheckbox={this.handleChangeCheckbox}
        onSubmit={this.handleSubmit}
        options={this.state.options}
        lostConnectionOptions={this.state.lostConnectionOptions}
        handlerForConnectionLost={this.handlerForConnectionLost}
        connectionLostTimeout={this.state.lostConnectionTimeoutNew}
        t={this.props.t}
        initialTimeout={this.state.initialTimeout}
        lostConnectionTimeoutInitial={this.state.lostConnectionTimeoutInitial}
        timeout={this.state.timeout}
        showHintBlock={this.showHintBlock}
        hideHintBlock={tutorialName => this.props.hideTutorial(tutorialName)}
      />
    );
  }
}

const mapStateToProps = state => ({
  session: state.session,
  connectionLostTimeout: state.preferences.connectionLostTimeout,
});

const mapDispatchToProps = dispatch => ({
  goBack: () => dispatch(goTo(Routes.SETTINGS)),
  setTimeout: timeout => dispatch(setSessionTimeout(timeout)),
  setConnectionLostTimeout: (
    lostConnectionTimeoutNew,
    timeout,
  ) => dispatch(
    setConnectionLostTimeout(lostConnectionTimeoutNew, timeout),
  ),
  hide: () => dispatch(hideModal()),
  showModal: (name, data) => dispatch(showModal(name, data)),
  hideTutorial: name => dispatch(hideTutorial(name)),
  showTutorial: args => dispatch(showTutorial(args)),
  setSessionTimeToken: accessToken => dispatch(RXPrefsSessionTimeout(accessToken)),
});

export default translate()(connect(
  mapStateToProps,
  mapDispatchToProps,
)(withOverlayAction(SidebarSessionComponent)));
