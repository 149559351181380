/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { MODAL } from 'constants/ids';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getCurrentScrollPosition } from '@nettoken/helpers';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import Styles from './style.css';

class ModalWrapperComponent extends React.Component {
  componentDidMount() {
    if (this.refs.wrapper) {
      const scrollY = getCurrentScrollPosition();
      this.refs.wrapper.style.top = `${scrollY}px`;
    }
  }

  render() {
    return (
      <div
        className={classNames({
          [Styles.modal]: true,
          [Styles.active]: this.props.active,
        })}
        id={MODAL}
        ref="wrapper"
        role="dialog">
        <div className={`${Styles.wrapper} ${this.props.modalTemplate === '' || this.props.modalTemplate === 'account-remove' || this.props.modalTemplate === 'account-edit' ? Styles.limitedWidth : ''}`}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

ModalWrapperComponent.propTypes = {
  active: PropTypes.bool.isRequired,
};
const mapStateToProps = state => ({
  modalTemplate: state.ui.modalTemplate,
});


export default translate()(connect(
  mapStateToProps,
  null,
)(ModalWrapperComponent));
