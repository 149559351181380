/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from '@nettoken/components';
import { noop, reloadBrowser } from '@nettoken/helpers';
import Styles from 'Modal/style.css';
import Tooltip from 'Tooltip';
import iconPlus from 'icons/plus.svg';
import info1 from '../../../../assets/images/info11.png';
import chevronRight from '../../../../assets/images/chevronRight.png';
import yellowicon from '../../../../assets/images/yellow.png';
import CancelIcon from '../../../ActionIcons/CancelIcon';
import RightArrowIcon from '../../../ActionIcons/RightArrowIcon';
import QuestionMarkIcon from '../../../ActionIcons/QuestionMarkIcon';
import { getIconColorNewmethod } from '../../../../utils/misc';

import {
  ADD_DASHBOARD_LABEL,
  ID_ADD_DASHBOARD,
  ID_SHOW_HOME_TITLE,
  NETTOKEN_HEAD,
} from '../../../../constants/ids';

const userDashboards = ({
  loading,
  onCancel,
  addDashboards,
  dashboards,
  username,
  totalAccounts,
  editDashboard,
  t,
  goToMyAccount,
}) => (
  <React.Fragment>
    <h1 aria-label="Manage Dashboards" className={Styles.title1}>
      Manage Dashboards
      <CancelIcon onCancel={onCancel} />
    </h1>
    <div
      className={`${Styles.whiteBackground2} ${Styles.minHeightBackground}`}
      style={{ overflow: 'unset' }}
    >
      <div className={`${Styles.fixedForm} ${Styles.fixedForm2}`}>
        <div className={Styles.sharedRow}>
          <h2>
            HOME DASHBOARD
            <QuestionMarkIcon content='My Nettoken' rightPosition='-180px' position='relative' placement='right'/>
          </h2>
        </div>
        <div style= {{ padding: '14px 21px' }}>
        <ul
          className={Styles.tabList}
          style={{
            width: '100%',
            padding: '0px',
            display: 'grid',
          }}
        >
          <li
            className={Styles.liAccounts}
            style ={{ paddingRight: '10px !important', paddingLeft: '10px !important' }}
            onClick={goToMyAccount}
          >
              <div
                style={{
                  borderRadius: '50%',
                  height: '50px',
                  width: '50px',
                  textAlign: 'center',
                  verticalAlign: 'middle',
                  lineHeight: '49px',
                  backgroundColor: getIconColorNewmethod(username),
                }}
              >
                <span style={{ color: 'white' }}>{username.slice(0, 2).toUpperCase()}</span>
              </div>
            <div
              style={{ marginLeft: '11px', width: '166px' }}
            >
              <span>{username.length > 24 ? username.substring(0, 24).concat('...') : username}</span>
              <br />
              <span
                style={{
                  display: 'block',
                  width: '50%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  color: '#868692',
                  fontSize: '12px',
                }}
              >
                {`${totalAccounts} accounts`}
              </span>
            </div>
            <div style={
              {
                display: 'flex', color: '#868692', fontSize: '14px', marginLeft: '-110px',
              }
            }>My Nettoken <img style={{ width: '24px', height: '24px', marginTop: '-1px' }} src={chevronRight} /></div>
          </li>
        </ul>
        </div>
        <div className={Styles.sharedRow} style={{ marginTop: '14px', marginBottom: '14px' }}>
          <h2>
            DASHBOARDS
            <QuestionMarkIcon content='Add Dashboards' rightPosition='-217px' position='relative' placement='right'/>
          </h2>
        </div>
        <div className={classNames({
          [Styles.subHeader]: true,
          [Styles.clickArea]: true,
        })}
          style={{
            display: 'flex',
            padding: '12px 32px',
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onClick={() => addDashboards()}
        >
          <div className={Styles.circle}
                style={{
                  borderRadius: '50%',
                  height: '50px',
                  width: '50px',
                  textAlign: 'center',
                  verticalAlign: 'middle',
                  lineHeight: '49px',
                  backgroundColor: 'white',
                }}>
            <img
              alt='addIcon'
              className={Styles.iconPlus}
              src={iconPlus} />
          </div>
          <span className={classNames({
            [Styles.addAContact]: true,
            [Styles.dashboardName]: true,
          })} style={{ marginLeft: '25px' }}>Add dashboard</span>
        </div>
        {Object.keys(dashboards).length > 0 ? (
          <>
            <div style= {{ padding: '14px 21px' }}>
            <ul
              className={Styles.tabList}
              style={{
                width: '100%',
                padding: '0px',
                display: 'grid',
              }}
            >
              {Object.keys(dashboards).map((dashboard, index) => {
                const color = getIconColorNewmethod(dashboards[dashboard].name);
                return (
                  <li
                    className={Styles.liAccounts}
                    style={{
                      gridTemplateColumns: '0.1fr 0.68fr 0.3fr',
                    }}
                    onClick={
                      () => editDashboard(dashboards[dashboard])
                    }
                  >
                    <div
                      style={{
                        borderRadius: '50%',
                        height: '50px',
                        width: '50px',
                        textAlign: 'center',
                        verticalAlign: 'middle',
                        lineHeight: '49px',
                        backgroundColor: color,
                        position: 'relative',
                      }}
                    >
                      <span style={{ color: 'white' }}>{dashboards[dashboard].name.slice(0, 2).toUpperCase()}</span>
                      {'externalDashboard' in dashboards[dashboard] && dashboards[dashboard].externalDashboard ? (
                        <div style={{ position: 'relative' }}>
                          <img
                            src={info1}
                            style={{
                              top: '-68px',
                              right: '-20px',
                              height: '28.5px',
                              width: '28.5px',
                              position: 'relative',
                            }}/>
                        </div>
                      ) : null}
                      { 'invitationId' in dashboards[dashboard] && dashboards[dashboard].invitationId != '' ? (
                        <div style={{ position: 'relative' }}>
                          <img
                            src={yellowicon}
                            style={{
                              top: '-68px',
                              right: '-20px',
                              height: '28.5px',
                              width: '28.5px',
                              position: 'relative',
                            }}/>
                        </div>
                      ) : null}
                      {'shared' in dashboards[dashboard] && dashboards[dashboard].shared &&
                       'usersSharedWith' in dashboards[dashboard] && dashboards[dashboard].usersSharedWith.length > 0 ? (
                        <span className={Styles.sharedMe} style={{
                          position: 'absolute',
                          top: '-18px',
                          right: '-10px',
                        }}>
                          <svg width="28.5" height="28.5" viewBox="0 0 31 31" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M16 26C9.92487 26 5 21.0751 5 15C5 8.92487 9.92487 4 16
                                 4C22.0751 4 27 8.92487 27 15C27 21.0751 22.0751 26 16 26Z"
                                fill="white"/>
                              <circle cx="16" cy="15" r="10.75" stroke="#28283F"/>
                              <path d="M16.0902 9.37494L16.4743 9.05485C16.3805 8.94229
                              16.2421 8.87654
                               16.0956 8.87497C15.9491 8.8734 15.8092 8.93616
                                15.713 9.04668L16.0902 9.37494ZM15.5902 20.6249C15.5902
                                 20.9011
                                15.8141 21.1249 16.0902 21.1249C16.3663 21.1249 16.5902
                                20.9011 16.5902 20.6249L15.5902 20.6249ZM11.5166 13.8681C11.3353
                                14.0764 11.3572 14.3922 11.5655 14.5735C11.7738 14.7548
                                12.0896 14.7329 12.2709 14.5246L11.5166 13.8681ZM19.7239
                                 14.5165C19.9007
                                 14.7286 20.216 14.7573 20.4281 14.5805C20.6403
                                14.4037 20.6689 14.0884 20.4922 13.8763L19.7239 14.5165ZM15.5902
                                 9.37494L15.5902
                                20.6249L16.5902 20.6249L16.5902 9.37494L15.5902
                                9.37494ZM12.2709 14.5246L16.4673 9.7032L15.713 9.04668L11.5166
                                 13.8681L12.2709
                                14.5246ZM20.4922 13.8763L16.4743 9.05485L15.7061
                                9.69503L19.7239 14.5165L20.4922 13.8763Z"
                                fill="#28283F"/>
                          </svg>
                        </span>
                        ) : null}
                    </div>
                  <div
                    style={{ marginLeft: '17px' }}
                  >
                    <span>{dashboards[dashboard].name.length > 24 ? dashboards[dashboard].name.substring(0, 24).concat('...') : dashboards[dashboard].name}</span>
                    <br />
                    <span
                      style={{
                        display: 'block',
                        width: '50%',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        color: '#868692',
                        fontSize: '12px',
                      }}
                    >
                      {dashboards[dashboard].count} {dashboards[dashboard].count > 1 ? 'accounts' : 'account'}
                    </span>
                    <span style={{ color: '#868692', fontSize: '12px' }}>
                      {'invitationId' in dashboards[dashboard] && dashboards[dashboard].invitationId != '' ? `Pending Invitation from ${dashboards[dashboard].sharedByUser}` : null}
                      {'shared' in dashboards[dashboard] && dashboards[dashboard].shared ?
                        dashboards[dashboard].usersSharedWith.length > 0 &&
                          `Shared with ${dashboards[dashboard].usersSharedWith[0].name}` :
                        null}
                      {'externalDashboard' in dashboards[dashboard] && dashboards[dashboard].externalDashboard ? dashboards[dashboard].usersSharedWith.length > 0 &&
                        `Shared by ${dashboards[dashboard].sharedByUserName}` :
                        null}
                      {(dashboards[dashboard].usersSharedWith.length > 1 && 'shared' in dashboards[dashboard] && dashboards[dashboard].shared && !('invitationId' in dashboards[dashboard] && dashboards[dashboard].invitationId)) ? ' and ' : null}
                      {(dashboards[dashboard].usersSharedWith.length > 1 && 'externalDashboard' in dashboards[dashboard] && dashboards[dashboard].externalDashboard && !('invitationId' in dashboards[dashboard] && dashboards[dashboard].invitationId)) ? ' with ' : null}
                      {dashboards[dashboard].usersSharedWith.length > 1 ?
                        dashboards[dashboard].usersSharedWith.length - 1 : null}
                      {dashboards[dashboard].usersSharedWith.length == 2 ? ' Other' : null}
                      {dashboards[dashboard].usersSharedWith.length > 2 ? ' Others' : null}
                    </span>
                  </div>
                  <span style={
                    {
                      display: 'flex', justifyContent: 'right', color: '#868692', fontSize: '14px',
                    }
                  }>{'invitationId' in dashboards[dashboard] && dashboards[dashboard].invitationId ? 'Open' : 'Edit'} <img style={{ width: '24px', height: '24px', marginTop: '-1px' }} src={chevronRight} /></span>
                  </li>
                );
              })}
            </ul>
            </div>
          </>
        ) : null}
      </div>
      <div className={`${Styles.actions} ${Styles.actionsFixed} ${Styles._squeezedAltered} ${Styles.fixedButtons}`}>
        <Button
          className={`${Styles.button}`}
          onClick={onCancel}
          loaderSize={20}
          value='Cancel' />
      </div>
    </div>
  </React.Fragment>
);

userDashboards.propTypes = {
  onCancel: PropTypes.func,
  /** i18n translate method */
  t: PropTypes.func.isRequired,
};

export default userDashboards;
