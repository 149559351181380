/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { noop } from '@nettoken/helpers';
import Helmet from 'Helmet';
import { Button } from '@nettoken/components';
import Styles from 'Sidebar/style.css';

const SidebarSettingsAdvancedContainer = ({
  highlight,
  onCancel,
  onClickButtonBackup,
  onClickButtonDeleteNettoken,
  onClickButtonImport,
  onClickButtonSession,
  t,
}) => (
  <form
    action="/"
    className={Styles.sidebarFullHeight}
    method="post"
    onSubmit={noop}>
    <Helmet title={t('global.more')} />

    <div className={`${Styles.content} ${Styles._menu} ${Styles._scrollable}`}>
      <div className={Styles.paddingFixer}>
        <h1 className={Styles.title}>
          {t('global.more')}
        </h1>

        <Button
          className={Styles.link}
          onClick={onClickButtonSession}
          value={t('views.session.title')} />

        <Button
          className={classNames({
            [Styles.link]: true,
            [Styles._highlight]: highlight,
          })}
          onClick={onClickButtonBackup}
          value={t('views.backup.sidebarButton')} />

        <Button
          className={Styles.link}
          onClick={onClickButtonImport}
          value={t('views.import.title')} />

        <Button
          className={`${Styles.link} ${Styles._danger}`}
          onClick={onClickButtonDeleteNettoken}
          value={t('views.export.title')} />
      </div>
    </div>

    <div className={Styles.actions}>
      <Button
        className={Styles.button}
        onClick={onCancel}
        value={t('global.back')} />
    </div>
  </form>
);

SidebarSettingsAdvancedContainer.propTypes = {
  highlight: PropTypes.bool,
  onCancel: PropTypes.func,
  onClickButtonBackup: PropTypes.func,
  onClickButtonDeleteNettoken: PropTypes.func,
  onClickButtonImport: PropTypes.func,
  onClickButtonSession: PropTypes.func,
  /** i18n translate method */
  t: PropTypes.func.isRequired,
};

export default SidebarSettingsAdvancedContainer;
