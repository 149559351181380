/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@nettoken/components';
import classNames from 'classnames';
import { noop } from '@nettoken/helpers';
import Helmet from 'Helmet';
import Styles from 'Sidebar/style.css';
import Styles2 from 'Modal/style.css';
import CancelIcon from '../../../ActionIcons/CancelIcon';

const Checkbox = ({ checked, name, onChange }) => (
  <div className={Styles.radioWrapper}>
    <input
      checked={checked}
      className={Styles.radioInput}
      id={name}
      onChange={onChange}
      name={name}
      // type="radio" would not be focusable with keyboard
      type="checkbox" />

    <label
      className={Styles.radioLabel}
      htmlFor={name}>
      <div className={Styles.radioBorder} />
    </label>
  </div>
);


let chk;
class SidebarDeleteAccountContainer extends React.Component {
  renderFragments(type) {
    const { t } = this.props;
    const isChecked = this.props.checkedOption === type;
    chk = isChecked;
    return (
      <React.Fragment>
        {isChecked && <p className={`${Styles.radioTextInExport} ${Styles._danger}`}>
          {t('views.export.warning')}
        </p>}

        <Checkbox
          checked={isChecked}
          name={type}
          onChange={this.props.onChangeCheckbox2} />

        <Button
          className={Styles.radioButton}
          disabled={!isChecked}
          icon={true}
          light={true}
          loading={isChecked && this.props.downloading}
          onClick={this.props.onClickDownload}
          value='Download Data'/>
      </React.Fragment>
    );
  }

  renderFragments1(type) {
    const { t } = this.props;
    const isChecked = this.props.checkedOption === type;
    return (
      <React.Fragment>
        <Checkbox
          checked={isChecked}
          name={type}
          onChange={this.props.onChangeCheckbox} />
      </React.Fragment>
    );
  }

  render() {
    const { t } = this.props;
    return (
        <React.Fragment>
            <h1
              aria-label={t('views.export.title')}
              className={`${Styles2.title1} ${Styles._danger}`}
              style={{ color: 'rgb(248, 88, 28)' }}
            >
                {t('views.export.title')}
                <CancelIcon onCancel={this.props.onCancel} />
            </h1>
            <form
                action="/"
                className={`${Styles2.whiteBackground2} ${Styles2.minHeightBackground}`}
                method="post"
                onSubmit={this.props.onSubmit}>
                <div className={`${Styles2.fixedForm} ${Styles2.fixedForm2}`}>
                  <div style={{ padding: '28px 21px 0' }} >
                      <div>
                          <p style={{ color: 'rgb(248, 88, 28)' }}>
                              Deleting your Nettoken is permanent and irreversable
                              as no reference to your account is left behind.
                              </p>
                          <ul>
                          <li>
                              <h4 className={Styles.radioTitle}>
                                  Download your data before deleting
                                  </h4>
                              <ul>
                                  <p className={Styles.radioTextInExport}>
                                  {t('views.export.encryptedTextDelete')}
                                  </p>
                                  <br/>
                              <li className={Styles.exportSectionItem}>
                                  <span>
                                  I agree and understand</span>
                                  {this.renderFragments('encrypted')}
                              </li>
                              <h4 className={Styles.radioTitle}>
                                  Delete with no data download
                                  </h4>
                                  <p className={Styles.radioTextInExport}>
                                  You will loose all your nettoken data.
                                  </p>
                                  <br/>
                              <li className={Styles.exportSectionItem}>
                                  <span for='html'>
                                  I agree and understand</span>
                                  <input type="radio" id="html" name="fav_language" value="HTML"></input>
                                  {this.renderFragments1('decrypted')}
                              </li>
                              </ul>
                          </li>
                          </ul>
                      </div>
                  </div>
                </div>
                <div
                  className={`${Styles2.actions} ${Styles2.actionsFixed} ${Styles2._squeezedAltered} ${Styles2.fixedButtons}`}
                  style={{ justifyContent: 'right' }}
                >
                  <Button
                    className={classNames({
                      [Styles2.button]: true,
                      [Styles2.notDisabled]: !this.props.nextBtnDisabled,
                      [Styles2.cancelMarginLeft]: true,
                    })}
                      disabled={this.props.nextBtnDisabled}
                      type="submit"
                      value="Next" />
                </div>
            </form>
        </React.Fragment>
    );
  }
}

SidebarDeleteAccountContainer.defaultProps = {
  checkedOption: '',
  deleting: false,
  downloading: false,
  onCancel: noop,
  onChangeCheckbox: noop,
  onClickDownload: noop,
  onSubmit: noop,
};

SidebarDeleteAccountContainer.propTypes = {
  checkedOption: PropTypes.string,
  deleting: PropTypes.bool,
  downloading: PropTypes.bool,
  onCancel: PropTypes.func,
  onChangeCheckbox: PropTypes.func,
  onClickDownload: PropTypes.func,
  onSubmit: PropTypes.func,
  /** i18n translate method */
  t: PropTypes.func.isRequired,
};

export default SidebarDeleteAccountContainer;
