/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import PropTypes from 'prop-types';

export const PropIcon = () => PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.shape({
    prefix: PropTypes.string,
    suffix: PropTypes.string,
    height: PropTypes.number,
    width: PropTypes.number,
  }),
]).isRequired;

export const PropTypesLoading = () => ({
  error: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  pastDelay: PropTypes.bool.isRequired,
  timedOut: PropTypes.bool.isRequired,
});
