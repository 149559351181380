/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import uuid from 'uuid/v4';
import { Map } from 'immutable';

import {
  WEL_STATUS,
  logConnectEvent,
  signMessage,
  verifyMessage,
} from '@nettoken/web-extension-link';

import { addEvent } from '@nettoken/helpers';

let state = {
  awaitingResponse: new Map(),
  callbacks: new Map(),
  connected: false,
  externalPublicKey: null,
  init: false,
  _callbackId: 0,
};

const setState = (nextState, callback) => {
  const prevState = Object.assign({}, state);
  state = Object.assign(prevState, nextState);
  if (typeof callback === 'function') callback();
};

/**
 * Process the extension status change event and update `connected`.
 *
 * @param {object} data
 */
const changeStatus = data => {
  const { connected } = data;
  if (state.connected !== connected) logConnectEvent(connected);
  setState({ connected });
};

/**
 * @param {object} message
 *
 * @returns {Map}
 */
const getCallbacks = message => {
  if (message.id) {
    return state.awaitingResponse.filter((v, k) => k === message.id);
  }
  return state.callbacks;
};

/**
 * Handle event stream from the extension.
 *
 * @param {object} event
 */
const onMessage = async event => {
  try {
    const check = message => {
      if (!message.id) return true;
      const ids = state.awaitingResponse.keySeq();
      return !!ids && ids.includes(message.id);
    };
    verifyMessage(event.data, check, (payload, message) => {
      if (payload.event === WEL_STATUS) changeStatus(payload.data);
      getCallbacks(message).valueSeq().forEach(callback => callback(payload));
    });
  }
  catch (e) {
    console.log(e);
  }
};

/**
 * @returns {boolean}
 */
export const isExtensionConnected = () => state.connected === true;

/**
 * @param {object} payload
 * @property {any} data
 * @property {string} event
 */
export const postExtensionMessage = payload => new Promise(async resolve => {
  const id = uuid();
  let message;
  try {
    if (id) {
      message = await signMessage({ id, payload, publicKey: state.externalPublicKey });
    }
    else {
      window.location.reload();
    }
  }
  catch (e) {
    console.log(e);
  }
  const callback = data => {
    const awaitingResponse = state.awaitingResponse.delete(id);
    setState({ awaitingResponse }, () => resolve(data.data));
  };
  const awaitingResponse = state.awaitingResponse.set(id, callback);
  setState({ awaitingResponse }, () => window.postMessage(message, '*'));
});

/**
 * Define external public key from extension. This is received during the
 * handshake and will be used to encrypt any subsequent messages.
 *
 * @param {string} externalPublicKey
 */
export const setExtensionPublicKey = externalPublicKey => setState({ externalPublicKey });

/**
 * Callback to be triggered on message.
 *
 * @param {function} callback
 *
 * @returns {integer} Callback id for unsubscribing.
 */
export const subscribeExtension = callback => {
  const id = state._callbackId + 1;
  const callbacks = state.callbacks.set(id, callback);
  setState({ callbacks, _callbackId: id });
  return id;
};

/**
 * Remove a callback registered via `subscribeExtension()`.
 *
 * @param {integer} id Value returned by `subscribeExtension()`.
 */
export const unsubscribeExtension = id => {
  const callbacks = state.callbacks.delete(id);
  setState({ callbacks });
};

/**
 * Run this on app initialisation.
 */
export const watchExtension = () => {
  if (state.init) return;
  addEvent(window, 'message', onMessage);
  setState({ init: true });
};
