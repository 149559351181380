/*
 * Copyright (C) 2018-2019 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { RX_APP_INIT } from 'main/state/actionTypes';
import Reducer from 'reducers/factory';

import {
  RX_SHARED_ACCOUNTS_CREATE,
  RX_SHARED_ACCOUNTS_DELETE,
  RX_SHARED_ACCOUNTS_REMOVE,
  RX_SHARED_ACCOUNTS_REMOVE_BULK,
} from './actionTypes';

const defaultState = () => ({});

const reducer = new Reducer('sharedAccounts', defaultState());

export default (state = reducer.defaultState(), action) => {
  switch (action.type) {
    case RX_APP_INIT:
      return reducer.merge(state);

    case RX_SHARED_ACCOUNTS_CREATE: {
      const {
        id,
        sharedAccounts,
      } = action;
      return {
        ...state,
        [id]: sharedAccounts,
      };
    }

    case RX_SHARED_ACCOUNTS_DELETE: {
      const {
        credential: { id: credentialId },
        id,
      } = action;
      return Object.keys(state)
        .reduce((acc, key) => {
          if (key === credentialId) {
            const users = state[key].filter(item => (
              item.id !== id[0]
            ));
            if (users.length === 0) {
              return acc;
            }
            acc[key] = users;
            return acc;
          }
          acc[key] = state[key];
          return acc;
        }, {});
    }

    case RX_SHARED_ACCOUNTS_REMOVE: {
      delete state[action.credentialId];
      return state;
    }

    case RX_SHARED_ACCOUNTS_REMOVE_BULK: {
      const sharedAccounts = state;
      action.data.ids.forEach(credId => {
        if (credId in sharedAccounts) {
          sharedAccounts[credId] = sharedAccounts[credId]
            .filter(account => account.id != action.data.userId);
        }
      });
      return sharedAccounts;
    }

    default:
      return state;
  }
};
