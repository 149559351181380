/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
export const RX_TOOLS_DRAG = 'RX_TOOLS_DRAG';
export const RX_TOOLS_HIDE = 'RX_TOOLS_HIDE';
export const RX_TOOLS_LARGE = 'RX_TOOLS_LARGE';
export const RX_TOOLS_SHOW = 'RX_TOOLS_SHOW';
export const RX_TOOLS_SMALL = 'RX_TOOLS_SMALL';
export const RX_TOOLS_PRESETS = 'RX_TOOLS_PRESETS';
export const RX_TOOLS_VIEW = 'RX_TOOLS_VIEW';
