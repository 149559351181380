/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { canExecuteDeveloperCommand } from 'utils/module';

/**
 * @param {string} key
 *
 * @returns {any} Falsy value if we cannot read presets.
 */
export const getPreset = key => (_, getState) => { // eslint-disable-line import/prefer-default-export, max-len
  const state = getState();
  if (!canExecuteDeveloperCommand || !state) return false;
  return state.tools.presets[key];
};
