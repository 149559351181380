/*
 * Copyright (C) 2018-2019 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { hideModal, showModal } from 'main/modal';
import { Request } from 'utils/request';
import withOverlayAction from 'Overlay/withAction';
import { removeSharedByAccounts } from 'main/sharedAccounts';
import { CW_ENCRYPT_USING_MASTER_KEYPAIR, getWorkerPromise } from '@nettoken/crypto-worker';
import Container from './container';
import { MODAL_DASHBOARDS, MODAL_DASHBOARD_CREATED } from '../../../../constants/modal';
import { RXDashboardAdd } from '../../../../main/modal/reduxActions';
import addDashboard from './container';
import { DASHBOARD_SPACES } from '../../../../constants/endpoints';
import { RXChangeActiveDashboard } from '../../../../main/ui/reduxActions';
import { doSearch } from '../../../../main/search';
import { createGroup } from '../../../../main/vault/groups';

class ModalAddDashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dashboardName: '',
      loading: false,
      error: false,
      maxLengthError: false,
      helperText: 'This dashboard name already exists',
      maxLengthErrorText: 'This name exceeds the 30 characters limit',
    };

    this.hide = this.hide.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onCreate = this.onCreate.bind(this);
  }

  componentDidMount() {
    this.props.addOverlayAction('modal', () => this.hide());
  }

  onChange(e) {
    this.setState({
      ...this.state,
      error:
        Object.keys(this.props.dashboards)
          .filter(id => this.props.dashboards[id].name === e.target.value)
          .length > 0,
      maxLengthError: e.target.value.length > 30,
      dashboardName: e.target.value,
    });
  }

  async onCreate() {
    this.setState({ loading: true });
    const req = new Request(DASHBOARD_SPACES);
    const worker = await getWorkerPromise('crypto');
    const encryptedName = await Promise.all([
      worker({
        event: CW_ENCRYPT_USING_MASTER_KEYPAIR,
        message: this.state.dashboardName,
      }),
    ])
      .then(values => values.map(v => Object.values(v)
        .join('')
        .replace('CW_ENCRYPT_USING_MASTER_KEYPAIR', '')));
    req
      .authorise()
      .post({ name: encryptedName[0] })
      .then(res => {
        res = {
          ...res,
          name: this.state.dashboardName,
        };
        this.props.addDashboard(res);
        this.props.search(res.id.toString(), 'dashboardSpaceId');
        return res;
      }).then(res => {
        this.props.createUnsorted('unsorted', true, null, res.id).then(() => {
          this.props.showModal(MODAL_DASHBOARD_CREATED);
        });
      })
      .catch(e => Promise.reject(e));
  }


  hide() {
    this.props.hide();
  }

  render() {
    return (
      <Container
        onCancel={this.hide}
        t={this.props.t}
        onChange={this.onChange}
        dashboardName={this.state.dashboardName}
        loading={this.state.loading}
        onCreate={this.onCreate}
        error={this.state.error}
        maxLengthError={this.state.maxLengthError}
        helperText={this.state.helperText}
        maxLengthErrorText={this.state.maxLengthErrorText}
        onClickBackHandler={() => this.props.showModal(MODAL_DASHBOARDS)}
        />
    );
  }
}

const mapDispatchToState = state => ({
  dashboards: state.ui.dashboards,
});

const mapDispatchToProps = dispatch => ({
  hide: () => dispatch(hideModal()),
  showModal: (name, data) => dispatch(showModal(name, data)),
  addDashboard: dashboardName => dispatch(RXDashboardAdd(dashboardName)),
  search: (query, specialSearch = '') => {
    if (specialSearch === 'dashboardSpaceId') {
      dispatch(RXChangeActiveDashboard(query));
    }
    return dispatch(doSearch(query, specialSearch));
  },
  createUnsorted: args => dispatch(createGroup(args)),
});

export default translate()(connect(
  mapDispatchToState,
  mapDispatchToProps,
)(withOverlayAction(ModalAddDashboard)));
