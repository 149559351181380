/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */

/*
 * Define template sizes for different templates. Object keys
 * match template names (should be the same as template folder
 * name) and value should be either `small` or `large`. The
 * `small` attribute is currently default, so it can be omitted.
 */
export default {
  privacy: 'large',
};
