/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { List } from 'immutable';
import { includesCaseInsensitive } from '@nettoken/helpers';
import { createGroup } from 'main/vault/groups';

/**
 * @param {string} id
 *
 * @returns {integer} Number of credentials in the group.
 */
export const countGroupCredentials = id => (_, getState) => getState().groups.data[id].apps.length;

/**
 * This method returns all groups available in translate page
 * with current label.
 * @param {string} name
 *
 * @returns {List} Dropdown list.
 */
export const getAllGroupDropdown = name => (_, getState) => {
  const { groups, groupsOrder } = getState();
  const { currentDashboard } = getState().ui;
  let dropdown = new List([
    'Books',
    'Business',
    'Education',
    'Entertainment',
    'Finance',
    'Food & drink',
    'Games',
    'Health & Fitness',
    'Lifestyle',
    'Medical',
    'Music',
    'Navigation',
    'News',
    'Photo & Video',
    'Productivity',
    'Shopping',
    'Social',
    'Sports',
    'Travel',
    'Utilities',
    'Weather',
  ]);

  groupsOrder.order.forEach(id => {
    if ((groups.data[id]) && (includesCaseInsensitive(groups.data[id].label, dropdown) === -1)) {
      if (
        groups.data[id].label !== 'Pending Invitations' &&
        groups.data[id].dashboardSpaceId === currentDashboard
      ) {
        dropdown = dropdown.push(groups.data[id].label);
      }
    }
  });

  // if (
  // name && name !== groups.unsortedGroupId && includesCaseInsensitive(name, dropdown) === -1) {
  //   dropdown = dropdown.push(name);
  // }

  return dropdown;
};

/**
 * This method is used to display a dropdown of available group names under
 * a text input field when creating or editing a credential.
 *
 * @param {string} name Current group name.
 *
 * @returns {List} Dropdown list.
 */

export const getGroupDropdown = name => (_, getState) => {
  const { groups, groupsOrder } = getState();
  let dropdown = new List(groupsOrder.order.map(id => {
    let t = '';
    if (groups.data[id].label !== 'Pending Invitations') {
      t = groups.data[id].label;
    }
    return t;
  }));
  if (name && includesCaseInsensitive(name, dropdown) === -1) {
    dropdown = dropdown.push(name);
  }
  return dropdown;
};

/**
 * @param {string} [label='']
 *
 * @returns {object} False if the group does not exist.
 */
export const getGroupFromName = (label = '') => (_, getState) => {
  label = label.toUpperCase();

  const groups = getState().groups.data;
  const groupIds = Object.keys(groups);
  const { currentDashboard } = getState().ui;

  for (let i = 0; i < groupIds.length; i += 1) {
    const groupId = groupIds[i];
    const groupData = groups[groupId];
    if (
      groupData &&
      groupData.label.toUpperCase() === label &&
      groupData.dashboardSpaceId == currentDashboard
    ) {
      return groupData;
    }
  }

  return false;
};

/**
 * @returns {string} Unsorted group id or null if not defined.
 */
export const getUnsortedGroupId = (createNew = false) => (dispatch, getState) => {
  const { currentDashboard } = getState().ui;
  const { data } = getState().groups;
  const unsortedGrp = Object.values(data).filter(item => {
    if (
      item.label === 'unsorted' &&
      ((
        'dashboardSpaceId' in item &&
        item.dashboardSpaceId === currentDashboard
      ) ||
      (
        !('dashboardSpaceId' in item) &&
        (currentDashboard === '' ||
        currentDashboard === null)
      ))
    ) {
      return true;
    }
    return false;
  });
  if (unsortedGrp.length > 0) {
    return unsortedGrp[0].id;
  }
  if (createNew) {
    return dispatch(createGroup('unsorted', true, null, currentDashboard))
      .then(groupId => Promise.resolve(groupId));
  }
  return null;
};

/**
 * @returns {object} Group data or null if not defined.
 */
export const getUnsortedGroup = () => (dispatch, getState) => {
  const id = dispatch(getUnsortedGroupId());
  const unsorted = getState().groups.data[id];
  return unsorted || null;
};

export const getUnsortedGroup1 = () => (dispatch, getState) => {
  const id = dispatch(getUnsortedGroupId());
  const unsorted = getState().groups.data[id];
  return unsorted || null;
};

/**
 * @param {string} id
 *
 * @returns {object} False if the group does not exist.
 */
export const readGroup = id => (_, getState) => {
  const groupData = getState().groups.data[id];
  if (!groupData) return false;
  if (groupData.id == 'd6c6f624109e45f6b495a950aa5c3f98') groupData.label = 'Pending Invitations';
  return { ...groupData };
};
