/*
 * Copyright (C) 2018-2019 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import {
  URL_CREDENTIAL,
  URL_CREDENTIALS_ORDER,
  URL_GROUP,
  URL_GROUPS,
  URL_USERS,
  URL_GROUPS_ORDER,
  DOWNWARD,
} from 'constants/endpoints';
import { List } from 'immutable';
import { focusGroupTitle } from '@nettoken/helpers';
import { Group, LABEL_UNNAMED_GROUP, LABEL_UNSORTED_GROUP } from '@nettoken/models';
import Validator from '@nettoken/validator';


import { refreshSearch } from 'main/search';
import { RXCredentialsUpdate } from 'main/vault/credentials/reduxActions';
import { RXGroupsOrder } from 'main/vault/groupsOrder/reduxActions';
import { getGroupsOrder } from 'main/vault/groupsOrder/reduxState';
import Debouncer from 'utils/debouncer';
import { Request } from 'utils/request';
import { getTrackingStatus, getsharedCRedstatus } from '../../tracking/reduxState';

import {
  RXGroupsCreate,
  RXGroupsDelete,
  RXGroupsSetUnsortedId,
  RXGroupsUpdate,
  RXGroupsUpdateOne,
} from './reduxActions';

import { getGroupFromName, getUnsortedGroup, readGroup } from './reduxState';
import { getCurrentModalData, getUsersApiCallProcessing } from '../../ui/reduxState';
import { RXUsersApiCallStatus } from '../../ui/reduxActions';
import { RXOverLoading, RXToasterShow } from '../../modal/reduxActions';
import { getUserData } from '../../user';
import { authenticateUserData } from '../../auth';

const debouncedUpdateGroupName = new Debouncer(params => {
  const { id, name } = params;
  const req = new Request(URL_GROUP);
  req.setUrlParams({ id }).authorise().put({ name })
    .catch(e => console.log(e));
}, 500);

const debouncedUpdateGroupsOrder = new Debouncer(params => {
  const { prev, next, getState } = params;
  debouncedUpdateGroupsOrder.set('prev', undefined);
  debouncedUpdateGroupsOrder.set('next', undefined);
  debouncedUpdateGroupsOrder.set('getState', undefined);
  setGroupsOrder({ next, prev, getState })
    .catch(e => console.log(e));
}, 800);

/**
 * Appends a new group to the list of groups only internally.
 *
 * @param {string} label Group name.
 * @param {string} id Group id.
 */
const append = (id, label, source, loading = false, dashboardSpaceId) => dispatch => {
  const meta = Validator.ensureDefaults({ source }, {
    open: false,
    source: 'group',
  });
  const group = new Group({
    id,
    label,
    meta,
    dashboardSpaceId,
  });
  if (
    label === LABEL_UNSORTED_GROUP &&
    (
      !('dashboardSpaceId' in group.getState()) ||
      group.getState().dashboardSpaceId === '' ||
      group.getState().dashboardSpaceId === null
    )
  ) dispatch(RXGroupsSetUnsortedId(id));
  dispatch(RXGroupsCreate(id, { ...group.getState(), loading, dashboardSpaceId }));
};

/**
 * @param {array} prev
 * @param {array} next
 *
 * @returns {boolean}
 */
const isGroupOrderEqual = (prev, next) => {
  for (let i = 0; i < prev.length; i += 1) {
    if (prev[i] !== next[i]) return false;
  }
  return true;
};

/**
 * @param {array} arr
 *
 * @returns {array} Shuffled array.
 */
const shuffleArray = arr => {
  for (let i = arr.length - 1; i > 0; i -= 1) {
    const j = Math.floor(Math.random() * (i + 1));
    [arr[i], arr[j]] = [arr[j], arr[i]];
  }
  return arr;
};

/**
 * @param {object} [args]
 * @property {array} [apps=[]]
 * @property {boolean} [createEmptyGroup=false]
 * @property {boolean} [pushToServer=true]
 */
export const addCredentialsToGroup = args => async (dispatch, getState) => {
  const {
    apps,
    createEmptyGroup,
    pushToServer,
    credentialId,
    fromMoveInput,
  } = Validator.ensureDefaults(args, {
    apps: [],
    createEmptyGroup: false,
    pushToServer: true,
    credentialId: 0,
    fromMoveInput: false,
  });
  const groups = getState().groups.data;
  const credentials = [];
  apps.forEach(app => {
    const { id, insertIndex: index } = app;
    const dashboardSpaceId = ('dashboardSpaceId' in app && app.dashboardSpaceId != '') ? app.dashboardSpaceId : null;
    const { groupId } = app;
    credentials.push({ groupId, id, dashboardSpaceId });
    if (groups[groupId]) {
      if (groups[groupId].label === 'unsorted') {
        // if (credentialId != 0) {
        //   groups[groupId].apps.pop();
        // }
        groups[groupId].apps.unshift(id);
      }
      else {
        groups[groupId].apps.splice(index, 0, id);
      }
    }


    // Only inserting at specific index isn't handled by the server.
    if (index !== undefined && pushToServer) {
      if (app.sharedId && app.shared === true && app.externalAccount === true) {
        const { sharedId } = app;
        const req1 = new Request(DOWNWARD);
        req1.authorise().put({
          groupId,
          sharedId,
          setHomeDashboard: false,
        })
          .then(() => syncCredentialsOrder(groupId, order))
          .catch(e => console.log(e));
      }
      // const req2 = new Request(URL_USERS);
      // const users = req2.authorise().get();
      // users.then(res => {
      //   res.profile.groups.forEach(v => {
      //     v.credentials.forEach(a => {
      //       if (a.id === id && a.shared === true && a.externalAccount === true) {
      //         const { sharedId } = a;
      //         const req1 = new Request(DOWNWARD);
      //         req1.authorise().put({
      //           groupId,
      //           sharedId,
      //           setHomeDashboard: false,
      //         })
      //           .then(() => syncCredentialsOrder(groupId, order))
      //           .catch(e => console.log(e));
      //       }
      //     });
      //   });
      // });
      const order = [...groups[groupId].apps];
      const arr = [];
      arr.push(id);
      const issharedby = dispatch(getsharedCRedstatus(arr));
      // API call stopped because URL_CREDENTIAL is called twice when changing groups of an account
      if (fromMoveInput && !app.externalAccount) {
        const req = new Request(URL_CREDENTIAL);
        req.setUrlParams({ id }).authorise().put({ groupId, dashboardSpaceId })
          .then(() => syncCredentialsOrder(groupId, order))
          .catch(e => console.log(e));
      }
    }
  });
  const prev = getState().credentials.data;
  const stateArr = [];
  Object.keys(prev).forEach(key => {
    stateArr.push(prev[key].id);
  });
  const arr = [];
  Object.keys(credentials).forEach(key => {
    arr.push(credentials[key].id);
  });
  if (credentialId != 0) {
    // alert('herbe');
    apps.forEach(app => {
      const { id, insertIndex: index } = app;
      const { groupId } = app;
      if (groupId in groups) {
        if (groups[groupId].label !== 'unsorted') {
          if (pushToServer) {
            groups[groupId].apps.pop();
          }
          groups[groupId].apps.splice(index, 0, credentialId);
        }
        else {
          groups[groupId].apps.shift();
          groups[groupId].apps.unshift(credentialId);
          // groups[groupId].apps.splice(0, 0, credentialId);
        }
      }
    });
  }
  Object.keys(groups).map(itemKey => {
    groups[itemKey].apps = [...new Set(groups[itemKey].apps)];
  });
  dispatch(RXCredentialsUpdate(credentials));
  dispatch(RXGroupsUpdate(groups));
  const modalData = dispatch(getCurrentModalData());
  if (modalData) {
    // const currentDashboardId = modalData?.id;
    const { currentDashboard } = getState().ui;
    const usersApiCallInProcess = dispatch(getUsersApiCallProcessing());
    if (currentDashboard && usersApiCallInProcess) {
      apps.forEach(app => {
        const { id, insertIndex: index } = app;
        const dashboardSpaceId =
        ('dashboardSpaceId' in app && app.dashboardSpaceId != '') ? app.dashboardSpaceId : null;
        if (dashboardSpaceId == currentDashboard) {
          dispatch(RXUsersApiCallStatus({
            usersApiCallInProcess: false,
            usersApiCallFinished: true,
          }));
        }
      });
    }
  }

  // Create an unnamed group if there exists only the unsorted group.
  if (createEmptyGroup && Object.keys(groups).length === 1) {
    dispatch(createGroup());
  }
  dispatch(refreshSearch());
  if (fromMoveInput && pushToServer) {
    dispatch(RXOverLoading(true));
    // const token = getState().session.accessToken;
    // const { encryptedUserCredentials, profile } = await getUserData(token, true, true);

    // const {
    //   groups: updatedGroups, groupsOrder, id, shared,
    // } = encryptedUserCredentials;
    // dispatch(authenticateUserData(
    //   id, token, updatedGroups, groupsOrder,
    //   shared, { override: true, skipPrompt: true }, profile,
    // ));
    setTimeout(() => {
      dispatch(RXOverLoading(false));
      dispatch(RXToasterShow({
        status: 'open',
        type: 'success',
        value: 'Changes saved!',
      }));
    }, 2000);
  }
};

/**
 * Appends a new group to the list of groups. If the group does not
 * exist yet, a request to the server will be sent to create it.
 *
 * @param {string} [name='']
 * @param {string} [id] Group id. If supplied, we don't send request
 *   to the server.
 */
export const appendGroup = (name = '', id, source = 'group', dashboardId = null) => (dispatch, getState) => new Promise((resolve, reject) => {
  const callback = (groupId, label, groupSource, loading, dashboardSpaceId) => {
    dispatch(append(groupId, label, groupSource, loading, dashboardSpaceId));
    return resolve(groupId);
  };

  if (id) return callback(id, name, source, true, dashboardId);

  const req = new Request(URL_GROUPS);
  return req.authorise().post({ name, source, dashboardSpaceId: getState().ui.currentDashboard })
    .then(res => callback(
      res.group.id,
      name,
      res.group.source,
      false,
      getState().ui.currentDashboard,
    ))
    .catch(e => reject(e));
});

/**
 * Loop names and create matching groups if they have no credentials.
 *
 * @param {array} [names]
 * @param {object} [groups]
 */
export const createEmptyGroups = (names, groups) => async dispatch => {
  if (Array.isArray(names)) {
    let promises = new List();
    names.forEach(name => {
      if (groups[name].apps.length) return;
      promises = promises.push(dispatch(createGroup(name)));
    });
    await Promise.all(promises.toArray());
  }
};

/**
 * @param {string} label
 * @param {boolean} focus
 * @param {string} source
 *
 * @returns {string} Created group id.
 */
export const createGroup = (label, focus, source, dashboardId = '') => (
  dispatch => new Promise((resolve, reject) => {
    dispatch(appendGroup(label, null, source, dashboardId))
      .then(id => {
        if (focus) focusGroupTitle(id);
        return resolve(id);
      })
      .catch(e => reject(e));
  }));

/**
 * Delete the first unnamed group if it exists and is empty.
 *
 * TODO: This does not do anything since we changed the label to be only a
 * placeholder, the group never gets identified.
 */
export const deleteFirstGroupIfExistsAndEmpty = () => async dispatch => {
  // const id = await dispatch(getGroupIdFromGroupInputField(null, LABEL_UNNAMED_GROUP, false));
  // const group = dispatch(readGroup());
  // if (group && !group.apps.length) {
  //   await dispatch(deleteGroup({ id }));
  // }
};

/**
 * @param {object} [args]
 * @property {string} id
 * @property {boolean} [pushToServer=true]
 */
export const deleteGroup = args => dispatch => {
  const { id, pushToServer } = Validator.ensureDefaults(args, { pushToServer: true });
  dispatch(RXGroupsDelete(id));
  if (pushToServer) {
    const req = new Request(URL_GROUP);
    req.setUrlParams({ id }).authorise().delete().catch(e => console.log(e));
  }
};

/**
 * Creates a new group if it does not exist.
 *
 * @param {object} [data]
 * @param {string} [label]
 * @param {boolean} [createUndefined=true]
 *
 * @returns {string} Created group id.
 */
export const getGroupIdFromGroupInputField = (data, label, createUndefined = true, source) => (
  (dispatch, getState) => {
    const prevGroup = data ? data.label.toUpperCase() : null;
    const nextGroup = label ? label.toUpperCase() : '';
    const { currentDashboard } = getState().ui;
    if (!data || prevGroup !== nextGroup) {
      if (nextGroup.length > 0) {
        const maybeGroup = dispatch(getGroupFromName(label));
        // Move to this group.
        if (maybeGroup) {
          return Promise.resolve(maybeGroup.id);
        }

        // Create new group.
        if (createUndefined) {
          return dispatch(createGroup(label, false, source, currentDashboard))
            .then(groupId => Promise.resolve(groupId))
            .catch(e => console.log(e));
        }

        return undefined;
      }

      // Move to unsorted.
      if (
        currentDashboard !== '' &&
        nextGroup === '' &&
        currentDashboard !== null
      ) {
        const tmpDashId = currentDashboard;
        const unsortedGrp = Object.values(getState().groups.data)
          .filter(item => {
            if (
              item.label === LABEL_UNSORTED_GROUP &&
              'dashboardSpaceId' in item &&
              item.dashboardSpaceId == tmpDashId
            ) {
              return true;
            }
            return false;
          });
        if (unsortedGrp.length > 0) {
          return Promise.resolve(unsortedGrp[0].id);
        }
        // const unsorted = dispatch(getUnsortedGroup());
        // return Promise.resolve(unsorted.id);
        if (label !== undefined) {
          const newLabel = label === '' || label === null ? LABEL_UNSORTED_GROUP : label;
          return dispatch(createGroup(newLabel, false, source, currentDashboard))
            .then(groupId => Promise.resolve(groupId))
            .catch(e => console.log(e));
        }
      }

      if (currentDashboard === '' || currentDashboard === null) {
        const unsorted = dispatch(getUnsortedGroup());
        return Promise.resolve(unsorted.id);
      }
    }

    if (data && Object.keys(data).length > 0 && prevGroup === nextGroup) {
      return Promise.resolve(data.id);
    }

    return false;
  });

/**
 * Returns whether the passed group name is unique. This is used to
 * display UI errors by tinting the input field.
 *
 * @param {string} id Group ID.
 * @param {string} name Group name.
 * @param {object} groups All user groups.
 *
 * @returns {boolean}
 */
export const isGroupNameUnique = (id, name, groups, currentDashboard = '') => {
  name = name.toUpperCase();

  if (name) {
    const keys = Object.keys(groups);
    for (let i = 0; i < keys.length; i += 1) {
      const groupData = groups[keys[i]];
      if (
        groupData.id !== id &&
        groupData.label.toUpperCase() === name &&
        groupData.dashboardSpaceId === currentDashboard
      ) {
        return false;
      }
    }
  }

  return true;
};

export const moveGroup = (id, insertIndex, pushToServer = false) => (dispatch, getState) => {
  const prev = dispatch(getGroupsOrder());
  const next = [...prev];
  const index = next.indexOf(id);
  if (index === -1) return;
  next.splice(index, 1);
  next.splice(insertIndex, 0, id);
  dispatch(RXGroupsOrder(next));
  if (pushToServer === true) {
    if (!debouncedUpdateGroupsOrder.get('prev')) debouncedUpdateGroupsOrder.set('prev', prev);
    debouncedUpdateGroupsOrder.set('next', next);
    debouncedUpdateGroupsOrder.set('getState', getState);
    debouncedUpdateGroupsOrder.debounce();
  }
};

/**
 * @param {object} [args]
 * @param {array} next Next order.
 * @param {array} [prev] Previous order.
 * @param {string} [token]
 *
 * @returns {Promise}
 */
export const setGroupsOrder = async args => {
  const {
    next, prev, token, getState,
  } = Validator.ensureDefaults(args);
  const { currentDashboard } = getState().ui;
  // Below lines commented because groups order api is not called because of this.
  // const req11 = new Request(URL_USERS);
  // const { profile } = await req11.authorise(token).get();
  // const pendingInvitation = profile.shared.invitations;
  // const del = Object.keys(pendingInvitation).length;
  // if (del === 0) {
  //   next.shift();
  // }
  let pushToServer = true;

  if (!Array.isArray(next)) return Promise.resolve();
  // Compare states to see if we can skip the request.
  if (Array.isArray(prev) && prev.length === next.length) {
    pushToServer = !isGroupOrderEqual(prev, next);
  }

  // if (!pushToServer) return Promise.resolve();
  const req = new Request(URL_GROUPS_ORDER);
  return req.authorise(token).put({ groupsOrder: next, dashboardSpaceId: currentDashboard })
    .catch(e => Promise.reject(e));
};

export const shuffleGroups = () => (dispatch, getState) => {
  const { order: groups } = getState().groupsOrder;
  dispatch(RXGroupsOrder(shuffleArray(groups)));
};

/**
 * @param {string} id Group id.
 * @param {array} credentialsOrder
 *
 * @returns {Promise}
 */
export const syncCredentialsOrder = (id, credentialsOrder) => new Promise((resolve, reject) => {
  const req = new Request(URL_CREDENTIALS_ORDER);
  return req.setUrlParams({ id }).authorise().put({ credentialsOrder, notify: false })
    .then(res => resolve(res, console.log('')))
    .catch(e => reject(e));
});

/**
 * Updates meta data.
 *
 * @param {string} id
 * @param {object} nextState
 */
export const updateGroup = (id, nextState = {}) => (dispatch, getState) => {
  const groupData = getState().groups.data[id] || {};
  const meta = {
    ...(groupData.meta || {}),
    ...nextState,
  };
  dispatch(RXGroupsUpdateOne(id, { meta }));
};

/**
 * @param {object} [args]
 * @property {string} id
 * @property {string} name
 * @property {boolean} [pushToServer=true]
 *
 * @returns {boolean}
 */
export const updateGroupName = args => (dispatch, getState) => {
  const { id, name, pushToServer } = Validator.ensureDefaults(args, { pushToServer: true });
  const isUnique = isGroupNameUnique(
    id,
    name,
    getState().groups.data,
    getState().ui.currentDashboard,
  );
  dispatch(RXGroupsUpdateOne(id, { label: name }));

  // Group names must be unique to save the changes and sync them with server.
  // We want to update user objects even if we do not save them because the
  // NewGroupButton component relies on updates to update its disabled state.
  // Omitting this step in the past caused a bug where the button would not
  // render proper state if the group name was not unique.
  if (isUnique && pushToServer) {
    // The wait needs to be small enough so the user cannot start renaming
    // another group, otherwise all changes to the previous group would be lost!
    debouncedUpdateGroupName.set('id', id);
    debouncedUpdateGroupName.set('name', name);
    debouncedUpdateGroupName.debounce();
  }

  return isUnique;
};
