/*
 * Copyright (C) 2024 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */

/**
 * {@link node_modules/@nettoken/sass-core/src/colours.scss}
 */
/* eslint-disable */
export default {
  cBg: '#dcdbde',
  cBlack: 'black',
  cGreen: '#4dff36',
  cHighlight: '#fff466',
  cPlaceholder: '#dcdbde',
  cPlaceholderLight: '#868692',
  cPrimary: '#28283f',
  cRed: '#f8581c',
  cShadow: '#626262',
  cShadow2: '#c7c7c7',
  cWhite: 'white',
  cDisabledPurple: '#c1c1d8',
  cDisabledWhite: '#ebebeb'
};
/* eslint-enable */
