/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { LABEL_UNSORTED_GROUP as unsorted } from '@nettoken/models';
import { RX_APP_INIT } from 'main/state/actionTypes';
import { RX_USER_LOG_OUT } from 'main/user/actionTypes';
import { RX_GROUPS_CREATE, RX_GROUPS_DELETE } from 'main/vault/groups/actionTypes';
import Reducer from 'reducers/factory';
import { RX_GROUPS_ORDER } from './actionTypes';

const defaultState = () => ({
  order: [],
});

const reducer = new Reducer('groupsOrder', defaultState());

export default (state = reducer.defaultState(), action) => {
  switch (action.type) {
    case RX_APP_INIT:
      return reducer.merge(state);

    case RX_GROUPS_CREATE:
      return action.group.label === unsorted ? state : {
        ...state,
        order: [
          ...new Set([...state.order,
            action.id,
          ])],
      };

    case RX_GROUPS_DELETE: {
      const index = state.order.indexOf(action.id);
      return {
        ...state,
        order: [
          ...state.order.slice(0, index),
          ...state.order.slice(index + 1),
        ],
      };
    }

    case RX_GROUPS_ORDER:
      return {
        ...state,
        order: action.order,
      };

    case RX_USER_LOG_OUT:
      return reducer.isPersisted ? state : reducer.defaultState();

    default:
      return state;
  }
};
