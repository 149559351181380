/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */

/**
 * @returns {string} Currently shown modal template.
 */
export const getModalTemplate = () => (_, getState) => {
  const { modalTemplate } = getState().ui;
  return modalTemplate;
};

/**
 * @returns {boolean} Overlay visibility state.
 */
export const hasOverlay = () => (_, getState) => {
  const { overlayTemplate } = getState().ui;
  return !!overlayTemplate;
};

export const getDashboardList = () => (_, getState) => {
  const { dashboards } = getState().ui;
  return dashboards;
};

export const getDashboardInvites = () => (_, getState) => {
  const { dashboardInvites } = getState().user;
  return dashboardInvites;
};
