/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from '@nettoken/components';
import { getIconUrl } from '@nettoken/helpers';
import { setDefaultIcon } from 'utils/misc';
import { PropIcon } from 'utils/propTypes';
import defaultIcon from 'icons/default-icon.svg';
import sharedToMeIcon from 'icons/downwardarrow.svg';
import Styles from 'Modal/style.css';
import { getIconColorNewmethod } from '../../../../utils/misc';
import Avatar from '../../../Avatar';
import CancelIcon from '../../../ActionIcons/CancelIcon';

const TakeNoteNoDashboardContainer = ({
  onCancel,
  onSubmit,
  name,
  counter,
  t,
}) => (
  <React.Fragment>
    <h1
      aria-label="Take Note"
      className={classNames({
        [Styles.title1]: true,
        [Styles.displayFlex]: true,
      })}>
      Take Note
      <CancelIcon onCancel={onCancel} />
    </h1>
    <div className={`${Styles.whiteBackground}`}>
      <div className={classNames({
        [Styles.iconHeaderWrapper]: true,
        [Styles.noBg]: true,
      })}>
        <svg width='100%' height='100%' style={{ borderRadius: '50%' }}>
          <rect x='0' y='0' fill={getIconColorNewmethod(name)} height='100%' width='100%'></rect>
          <text
              dominantBaseline='middle'
              fill='#ffffff'
              fontSize='10'
              textAnchor='middle'
              x='48%'
              y='54%'
          >{name.slice(0, 2).toUpperCase()}</text>
        </svg>
      </div>
      <p style={{ marginBottom: '0px', textAlign: 'center' }}>{name}</p>
      <p style={{
        marginBottom: '0px', textAlign: 'center', color: '#868692', fontSize: '14px',
      }}>{`${counter} ${counter > 1 ? 'accounts' : 'account'}`}</p>
      <p className={`${Styles.promptText} ${Styles.promptTextTakeNote}`}>
        There are currently no dashboards for you to move accounts from.
      </p>

      <div className={`${Styles.actions} ${Styles.actionsFixed} ${Styles._squeezedAltered}`} style={{ bottom: '0px', position: 'absolute' }}>
        <Button
          className={`${Styles.button} ${Styles.confirmUnsaved}`}
          style={{ visibility: 'hidden' }}
          type="submit"
          value="" />
        <Button
          className={`${Styles.button} ${Styles.notDisabled}`}
          onClick={onCancel}
          value="Okay" />
      </div>
    </div>
  </React.Fragment>
);

TakeNoteNoDashboardContainer.defaultProps = {};

TakeNoteNoDashboardContainer.propTypes = {
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  /** i18n translate method */
  t: PropTypes.func.isRequired,
};

export default TakeNoteNoDashboardContainer;
