/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { RX_APP_INIT } from 'main/state/actionTypes';
import Reducer from 'reducers/factory';

import {
  RX_DEVICE_DESKTOP,
  RX_DEVICE_HOTKEYS_ADD,
  RX_DEVICE_HOTKEYS_REMOVE,
  RX_DEVICE_IPAD,
  RX_DEVICE_MOBILE,
  RX_DEVICE_PHONE,
  RX_DEVICE_TABLET,
  RX_DEVICE_BROWSER,
} from './actionTypes';

const defaultState = () => ({
  availableShortcuts: [],
  browser: null,
  isDesktop: false,
  isMobile: false,
  isPhone: false,
  isTablet: false,
  isPad: false,
});

const reducer = new Reducer('device', defaultState());

export default (state = reducer.defaultState(), action) => {
  switch (action.type) {
    case RX_APP_INIT:
      return reducer.defaultState();

    case RX_DEVICE_BROWSER:
      return {
        ...state,
        browser: action.value,
      };

    case RX_DEVICE_DESKTOP:
      return {
        ...state,
        isDesktop: !!action.value,
      };

    case RX_DEVICE_HOTKEYS_ADD:
      return {
        ...state,
        availableShortcuts: [
          ...state.availableShortcuts,
          ...action.shortcuts.filter(x => !state.availableShortcuts.includes(x)),
        ],
      };

    case RX_DEVICE_HOTKEYS_REMOVE:
      return {
        ...state,
        availableShortcuts: [
          ...state.availableShortcuts.filter(x => !action.shortcuts.includes(x)),
        ],
      };

    case RX_DEVICE_IPAD:
      return {
        ...state,
        isPad: !!action.value,
      };

    case RX_DEVICE_MOBILE:
      return {
        ...state,
        isMobile: !!action.value,
      };

    case RX_DEVICE_PHONE:
      return {
        ...state,
        isPhone: !!action.value,
      };

    case RX_DEVICE_TABLET:
      return {
        ...state,
        isTablet: !!action.value,
      };

    default:
      return state;
  }
};
