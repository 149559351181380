/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import React from 'react';
import { PropTypesLoading } from 'utils/propTypes';
import Background from 'Background';
import GStyles from 'styles/index.css';

const LazyLoadViewComponent = ({ pastDelay }) => (
  <Background
    centerHorizontally
    centerVertically
    paddingTop={0}>
    {pastDelay && <div className={GStyles.loader} />}
  </Background>
);

LazyLoadViewComponent.propTypes = PropTypesLoading;

export default LazyLoadViewComponent;
