/*
 * Copyright (C) 2018-2019 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { i18nRenderLines } from '@nettoken/helpers';
import Group from 'Group';
import Styles from 'Modal/style.css';

const PreviewImport = ({
  apps,
  groupIds,
  groups,
  hasNewAccounts,
  onSelectApp,
  selectedApps,
  t,
  unsortedId,
}) => (
  <div className={Styles.importWrapper}>
    {hasNewAccounts && <div className={Styles.groupsWrapper}>
      {groupIds.map(id => {
        const group = groups[id] || {};
        return (
          <Group
            apps={apps}
            deletable={false}
            disableAppInfo={true}
            disableAppMove={true}
            disableAppOpen={true}
            draggable={false}
            editable={false}
            flexible={false}
            id={group.id}
            invisible={false}
            items={group.apps}
            key={group.id}
            label={group.label}
            onSelectApp={onSelectApp}
            open={group.meta.open}
            placeholder={t('placeholder.groupTitle')}
            selectedApps={selectedApps}
            t={t}
            unsortedId={unsortedId} />
        );
      })}
    </div>}

    {!hasNewAccounts && <p className={Styles.emailScanDisclaimer}>
      {i18nRenderLines(t('modal.importPreview.noNewAccounts'))}
    </p>}
  </div>
);

PreviewImport.defaultProps = {
  apps: {},
  groupIds: [],
  groups: {},
  unsortedId: '',
};

PreviewImport.propTypes = {
  apps: PropTypes.object,
  groupIds: PropTypes.array,
  groups: PropTypes.object,
  hasNewAccounts: PropTypes.bool,
  onSelectApp: PropTypes.func,
  selectedApps: PropTypes.array,
  unsortedId: PropTypes.string,
};

export default translate()(PreviewImport);
