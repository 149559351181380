/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
var localStorage; // eslint-disable-line no-var
var shouldMockLocalStorage = false; // eslint-disable-line no-var
var store; // eslint-disable-line no-var

// Fixes vh units in iOS and other browsers.
require('viewport-units-buggyfill').init();

if (typeof Promise === 'undefined') {
  // Rejection tracking prevents a common issue where React gets into an
  // inconsistent state due to an error, but it gets swallowed by a Promise,
  // and the user has no idea what causes React's erratic future behavior.
  require('promise/lib/rejection-tracking').enable(); // eslint-disable-line global-require
  window.Promise = require('promise/lib/es6-extensions.js'); // eslint-disable-line global-require
}

// Emulates full ES2015+ environment.
require('@babel/polyfill');

// fetch() polyfill for making API calls.
require('whatwg-fetch');

// Object.assign() is commonly used with React.
// It will use the native implementation if it's present and isn't buggy.
Object.assign = require('object-assign');

/*
 * We use `window.localStorage` for browserless testing to keep state in memory, so we
 * polyfill it here. We also want to polyfill it in browsers where it might not be available,
 * e.g. Safari in private mode, so it does not break our functionality. Lastly, we might
 * not have access to `window.localStorage` due to content settings (e.g. Chrome), so we
 * need to polyfill it there too.
 */
try {
  if (!window.localStorage) {
    shouldMockLocalStorage = true;
  }
}
catch (e) {
  shouldMockLocalStorage = true;
}

if (shouldMockLocalStorage) {
  localStorage = (function () { // eslint-disable-line func-names
    store = {};

    return {
      clear: function () { // eslint-disable-line object-shorthand, func-names
        store = {};
      },
      getItem: function (key) { // eslint-disable-line object-shorthand, func-names
        return store[key] || null;
      },
      setItem: function (key, value) { // eslint-disable-line object-shorthand, func-names
        store[key] = value.toString();
      },
    };
  }());

  Object.defineProperty(window, 'localStorage', {
    value: localStorage,
  });
}

if (!window.requestAnimationFrame) {
  window.requestAnimationFrame = function (fn) { // eslint-disable-line func-names
    var callback = function () { // eslint-disable-line func-names, no-var
      fn();
    };
    setTimeout(callback, 0);
  };
}

if (typeof Element.prototype.remove !== 'function') {
  Element.prototype.remove = function () { // eslint-disable-line func-names
    this.parentElement.removeChild(this);
  };
}
