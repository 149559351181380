/*
 * Copyright (C) 2018-2019 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { hideModal } from 'main/modal';
import withOverlayAction from 'Overlay/withAction';
import Container from './container';
import { getApiErrorStatus } from '../../../../main/ui/reduxState';
import { showModal } from '../../../../main/modal';
import { MODAL_ACCOUNT_EDIT } from '../../../../constants/modal';
import { RxEditAccountProccessing } from '../../../../main/modal/reduxActions';

class ModalUnsavedChangesComponent extends React.Component {
  constructor(props) {
    super(props);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.props.addOverlayAction('modal', this.handleCancel, false);
  }

  handleCancel() {
    this.props.hide();
  }

  handleSubmit(event) {
    event.preventDefault();
    this.props.showModal(MODAL_ACCOUNT_EDIT, this.props.data);
  }

  render() {
    const { sharedAccounts, data } = this.props;
    let showsss = true;
    if (
      sharedAccounts[data.id] &&
      sharedAccounts[data.id].filter(x => !x.sharedByDashboard).length == 0
    ) {
      showsss = false;
    }
    return (
      <Container
        onCancel={this.handleCancel}
        onSubmit={this.handleSubmit}
        name={this.props.data.name}
        sharedCredentialData={sharedAccounts[data.id]}
        show={showsss}
        sharedDashboard={this.props.sharedDashboard}
        picture={this.props.data.picture}
        domain={this.props.data.domain}
        loginUrl={this.props.data.loginUrl}
        isShared={this.props.data.shared}
        isExternalAccount={this.props.data.externalAccount}
        editAccountProccessing={this.props.editAccountProccessing}
        t={this.props.t} />
    );
  }
}

const mapStateToProps = state => ({
  data: state.ui.modalData.currentData || {},
  sharedAccounts: state.sharedAccounts,
  editAccountProccessing: state.ui.editAccountProccessing,
});

const mapDispatchToProps = dispatch => ({
  hide: () => dispatch(hideModal()),
  getApiErrorStatus: () => dispatch(getApiErrorStatus()),
  showModal: (name, data) => dispatch(showModal(name, data)),
  setEditAccountProccessing: status => dispatch(RxEditAccountProccessing(status)),
});

export default translate()(connect(
  mapStateToProps,
  mapDispatchToProps,
)(withOverlayAction(ModalUnsavedChangesComponent)));
