/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import React from 'react';
import { Trans } from 'react-i18next';
import PropTypes from 'prop-types';
import { Button } from '@nettoken/components';
import { noop } from '@nettoken/helpers';
import Helmet from 'Helmet';
import Styles from 'Sidebar/style.css';

const SidebarBackupContainer = ({
  loading,
  backup,
  onCancel,
  onClickDownload,
  onClickExplain,
  t,
}) => (
  <form
    action="/"
    className={Styles.sidebarFullHeight}
    method="post"
    onSubmit={noop}>
    <Helmet title={t('views.backup.title')} />

    <div className={`${Styles.content} ${Styles._menu} ${Styles._scrollable}`}>
      <div className={Styles.paddingFixer}>
        <h1 className={Styles.title}>
          {t('views.backup.title')}
        </h1>
        <ul>
          <li className={`${Styles.exportSectionItem}`}>
            <p className={Styles.radioTextInExport}>
            <p>
             In the event that you get locked out of your Nettoken
             (lose your phone or delete the phone app),
             please download and safely store the pdf with your unique backup key.
            </p>
            <br />
            <p> Your backup key together with a new Nettoken
             app registered with your current phone number will log you back in! .</p>
              {t('views.backup.hint')}
            </p>
            <center>
            <Button
              className={Styles.backupButton}
              icon={true}
              loading={loading}
              onClick={onClickDownload}
              value={t('views.backup.button')} />
            </center>
              <center>
               <Button
              className={Styles.explainButton}
              onClick={onClickExplain}
              value='Explain'
            />
              </center>
          </li>
        </ul>
      </div>
    </div>

    <div className={Styles.actions}>
      <Button
        className={Styles.button}
        onClick={onCancel}
        type="button"
        value={t('global.back')} />
    </div>
  </form>
);

SidebarBackupContainer.propTypes = {
  loading: PropTypes.bool,
  onCancel: PropTypes.func,
  onClickDownload: PropTypes.func,
  /** i18n translate method */
  t: PropTypes.func.isRequired,
};

export default SidebarBackupContainer;
