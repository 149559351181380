/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { RX_APP_INIT } from 'main/state/actionTypes';
import { RX_USER_LOG_OUT } from 'main/user/actionTypes';
import Reducer from 'reducers/factory';

import {
  RX_GROUPS_CREATE,
  RX_GROUPS_DELETE,
  RX_GROUPS_SET_UNSORTED_ID,
  RX_GROUPS_UPDATE,
  RX_GROUPS_UPDATE_BATCH,
} from './actionTypes';

const defaultState = () => ({
  data: {},
  unsortedGroupId: null,
});

const reducer = new Reducer('groups', defaultState());

export default (state = reducer.defaultState(), action) => {
  switch (action.type) {
    case RX_APP_INIT:
      return reducer.merge(state);

    case RX_GROUPS_CREATE: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.id]: {
            ...action.group,
            // loading: true,
          },
        },
      };
    }

    case RX_GROUPS_DELETE: {
      const { [action.id]: deleted, ...nextState } = state.data;
      return {
        ...state,
        data: {
          ...nextState,
        },
      };
    }

    case RX_GROUPS_SET_UNSORTED_ID:
      return {
        ...state,
        unsortedGroupId: action.id,
      };

    case RX_GROUPS_UPDATE: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.id]: {
            ...(state.data[action.id] || {}),
            ...action.group,
          },
        },
      };
    }

    case RX_GROUPS_UPDATE_BATCH: {
      const nextState = {};
      Object.keys(action.groups).forEach(id => {
        if (typeof action.groups[id] === 'object') {
          nextState[id] = {
            ...(state.data[id] || {}),
            ...action.groups[id],
            loading: false,
          };
        }
      });
      return {
        ...state,
        data: {
          ...state.data,
          ...nextState,
        },
      };
    }

    case RX_USER_LOG_OUT:
      return reducer.isPersisted ? state : reducer.defaultState();

    default:
      return state;
  }
};
