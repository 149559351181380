/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import debounce from 'lodash.debounce';

class Debouncer {
  constructor(callback, delay = 0) {
    this.state = {};
    this.debounced = debounce(() => callback(this.state), delay);
  }

  debounce() {
    this.debounced();
  }

  get(key) {
    return this.state[key];
  }

  set(key, value) {
    this.setState({ [key]: value });
  }

  setState(nextState = {}) {
    this.state = Object.assign(this.state, nextState);
  }
}

export default Debouncer;
