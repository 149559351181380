/* eslint-disable */
/*
 * Copyright (C) 2018-2019 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { MODAL_ACCOUNT_EDIT, MODAL_CHANGE_PASSWORD } from 'constants/modal';
import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { hideModal, showModal } from 'main/modal';
import { Request } from 'utils/request';
import { history } from 'reducers/store';
import withOverlayAction from 'Overlay/withAction';
import { deleteCredential, updateCredential } from 'main/vault/credentials';
import { getOneCredential } from 'main/vault/credentials/reduxState';
import { encryptCredential } from 'main/user';
import { stopSharingAccount } from 'main/sharedAccounts';
import {
  CW_DECRYPT_USING_MASTER_KEYPAIR,
  CW_ENCRYPT_CREDENTIALS,
  CW_ENCRYPT_USING_MASTER_KEYPAIR,
  CW_ENCRYPT_WITH_EXTERNAL_KEY,
  CW_GENERATE_EPHEMERAL_KEY_PAIR,
  CW_EXTRACT_MASTER_KEYS,
  getWorkerPromise,
} from '@nettoken/crypto-worker';
import KeyPair from '@nettoken/crypto-worker/src/keypair';
import Container from './container';
import { MODAL_EDIT_DASHBOARD } from '../../../../constants/modal';
import { DASHBOARD_SHARING, URL_CREDENTIALS_SHARED } from '../../../../constants/endpoints';
import { RXStopSharingDashboard } from '../../../../main/ui/reduxActions';
import { getOrCreateKeyPairForSharingDashboard } from '../../../../utils/misc';
import { RXSharedAccountsRemoveBulk } from '../../../../main/sharedAccounts/reduxActions';
import { RXUpdateUsersShareWith } from '../../../../main/vault/credentials/reduxActions';


class ModalStopSharingDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      acceptProcessing: false,
      cancelProcessing: false,
    };
    this.onClickAccept = this.onClickAccept.bind(this);
    this.onClickCancel = this.onClickCancel.bind(this);
    // this.getOrCreateKeyPairForSharing = this.getOrCreateKeyPairForSharing.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }

  componentDidMount() {
    this.props.addOverlayAction('modal', this.hideModal);
  }

  hideModal() {
    history.replace({
      hash: '',
      search: '',
    });
    this.props.hide();
  }

  // async getOrCreateKeyPairForSharing(dashboard) {
  //   const keypair = new KeyPair();
  //   const {
  //     publicKey,
  //     secretKey,
  //   } = dashboard;
  //   const worker = await getWorkerPromise('crypto');

  //   if (dashboard.shared === true && publicKey && secretKey) {
  //     const [pKey, sKey] = await Promise.all([
  //       worker({
  //         event: CW_DECRYPT_USING_MASTER_KEYPAIR,
  //         message: publicKey,
  //       }),
  //       worker({
  //         event: CW_DECRYPT_USING_MASTER_KEYPAIR,
  //         message: secretKey,
  //       }),
  //     ])
  //       .then(values => values.map(v => Object.values(v)
  //         .join('')
  //         .replace('CW_DECRYPT_USING_MASTER_KEYPAIR', '')));

  //     keypair.set(pKey, sKey);
  //   }
  //   else {
  //     const { newKeyPairPublicKey, newKeyPairSecretKey } =
  //     await worker({ event: CW_GENERATE_EPHEMERAL_KEY_PAIR });
  //     // Save the data on server.
  //     const event = CW_EXTRACT_MASTER_KEYS;
  //     const { encryptedPrivateKey } =
  //     await worker({ event, newKeyPairPublicKey, newKeyPairSecretKey });
  //     keypair.set(newKeyPairPublicKey, newKeyPairSecretKey);
  //   }
  //   return keypair;
  // }

  // NOT USED IN THIS FILE ANYWHERE  SO COMMENTED THIS
  // async getOrCreateKeyPairForSharingCredential(credential) {
  //   const keypair = new KeyPair();
  //   const {
  //     publicKey,
  //     secretKey,
  //   } = credential;
  //   const worker = await getWorkerPromise('crypto');
  //   if (credential.shared === true && publicKey && secretKey) {
  //     const [pKey, sKey] = await Promise.all([
  //       worker({
  //         event: CW_DECRYPT_USING_MASTER_KEYPAIR,
  //         message: publicKey,
  //       }),
  //       worker({
  //         event: CW_DECRYPT_USING_MASTER_KEYPAIR,
  //         message: secretKey,
  //       }),
  //     ])
  //       .then(values => values.map(v => Object.values(v)
  //         .join('')
  //         .replace('CW_DECRYPT_USING_MASTER_KEYPAIR', '')));

  //     keypair.set(pKey, sKey);
  //   }
  //   else {
  //     // const newKeyPair = await worker({
  //     //   event: CW_GENERATE_EPHEMERAL_KEY_PAIR,
  //     //   format: 'base64',
  //     // });
  //     // // keypair.set(newKeyPair.publicKey, newKeyPair.secretKey);

  //     const { newKeyPairPublicKey, newKeyPairSecretKey } =
  //     await worker({ event: CW_GENERATE_EPHEMERAL_KEY_PAIR, format: 'base64' });
  //     // Save the data on server.
  //     const event = CW_EXTRACT_MASTER_KEYS;
  //     const { encryptedPrivateKey } =
  //     await worker({ event, newKeyPairPublicKey, newKeyPairSecretKey });
  //     keypair.set(newKeyPairPublicKey, newKeyPairSecretKey);
  //   }
  //   return keypair;
  // }

  async onClickAccept() {
    this.setState({
      acceptProcessing: true,
    });
    const unSharedCredentials = [];
    const unSharedCredentialsUID = [];
    const { sharedAccounts, currentCredentials } = this.props;

    const usersSharedWithLength = this.props.dashboardSpace.usersSharedWith.length;
    let sharedDashboard = false;
    if (usersSharedWithLength > 1) {
      sharedDashboard = true;
    }
    else {
      sharedDashboard = false;
    }
    this.props.dashboardSpace.sharedDashboard = sharedDashboard;

    for (const credential of currentCredentials) {
      if (!credential.externalAccount) {
        const objCredential = {
          id: credential.id,
          name: credential.name,
          username: credential.username,
          email: credential.email,
          password: credential.password,
          publicKey: credential.publicKey,
          secretKey: credential.secretKey,          
          shared: sharedDashboard,
          accountId: credential.accountId,
          note: credential.note,
        };
        const {
          publicKey,
          secretKey,
        } = await getOrCreateKeyPairForSharingDashboard(objCredential, KeyPair);
        const worker = await getWorkerPromise('crypto');
        const [pKey, sKey] = await Promise.all([
          worker({
            event: CW_ENCRYPT_USING_MASTER_KEYPAIR,
            message: publicKey,
          }),
          worker({
            event: CW_ENCRYPT_USING_MASTER_KEYPAIR,
            message: secretKey,
          }),
        ])
          .then(values => values.map(v => Object.values(v)
            .join('')
            .replace('CW_ENCRYPT_USING_MASTER_KEYPAIR', '')));
        const { encrypted } = await worker({
          event: CW_ENCRYPT_CREDENTIALS,
          credentials: [objCredential],
        });
        unSharedCredentials.push(encrypted[0]);
      }
    }
    unSharedCredentialsUID.push(this.props.data.sharedWith);

    const promises = currentCredentials.map(async data => {
      const shared = false;
      // shared variable set to false by default until the need is determined
      /* if (sharedAccounts[data.id].length > 1) {
        shared = true;
      }
      else {
        shared = false;
      } */
      const credential = await encryptCredential({
        ...data,
        shared,
      });
      const params = {
        credential: credential.encrypted[0],
        unshareWithUserIds: [this.props.data.sharedWith],
      };
      // await this.props.stopSharingAccount(params);
    });
    let encryptedDashboardName;
    const req = new Request(DASHBOARD_SHARING);
    Promise.all(promises).then(async values => {
      const {
        publicKey: publicKeyDashboard,
        secretKey: secretKeyDashboard,
      } = await getOrCreateKeyPairForSharingDashboard(this.props.dashboardSpace, KeyPair);
      const worker = await getWorkerPromise('crypto');
      if (sharedDashboard) {
        [{
          encrypted: encryptedDashboardName,
        }] = await Promise.all([
          worker({
            event: CW_ENCRYPT_WITH_EXTERNAL_KEY,
            message: this.props.dashboardSpace.name,
            inputFormat: 'plain',
            outputFormat: 'base64',
            publicKey: publicKeyDashboard,
          }),
        ]);
      }
      else {
        [encryptedDashboardName] = await Promise.all([
          worker({
            event: CW_ENCRYPT_USING_MASTER_KEYPAIR,
            message: this.props.dashboardSpace.name,
            // inputFormat: 'plain',
            // outputFormat: 'base64',
            // publicKey: publicKeyDashboard,
          }),
        ]).then(resData => resData.map(v => Object.values(v)
          .join('')
          .replace('CW_ENCRYPT_USING_MASTER_KEYPAIR', '')));
      }
      const dashboardData = {
        id: this.props.dashboardSpace.id,
        name: encryptedDashboardName,
        publicKey: this.props.dashboardSpace.publicKey,
        secretKey: this.props.dashboardSpace.secretKey,
        userId: this.props.dashboardSpace.userId,
        shared: sharedDashboard,
      };
      req
        .authorise()
        .put({
          dashboard: dashboardData,
          unshareWithUserIds: [this.props.data.sharedWith],
          credentials: unSharedCredentials,
        })
        .then(res => {
          this.props.stopSharingDashboard({
            id: this.props.dashboardSpace.id,
            userId: this.props.data.sharedWith,
            shared: sharedDashboard,
          });
          const credentialAccountsIds = currentCredentials.map(cred => cred.id);
          this.props.stopSharingSharedAccounts({ ids: credentialAccountsIds, userId: this.props.data.sharedWith });
          this.props.updateUsersSharedWith({ ids: credentialAccountsIds, userId: this.props.data.sharedWith });
          this.props.hide();
        })
        .catch(e => Promise.reject(e));
    });
  }

  onClickCancel() {
    this.props.showModal(this.props.data.currentPopup, this.props.data.currentPopupData);
  }

  render() {
    return (
      <Container
        name={this.props.data.name}
        sharedWithName={this.props.data.sharedWithName}
        accepted={this.props.data.accepted}
        onClickAccept={this.onClickAccept}
        onClickCancel={this.onClickCancel}
        acceptProcessing={this.state.acceptProcessing}
        cancelProcessing={this.state.cancelProcessing}
        alreadySharedDashboard={this.props.alreadySharedDashboard}
        sharedWithMeDashboard={this.props.sharedWithMeDashboard}        
        t={this.props.t}/>
    );
  }
}

const mapStateToProps = state => ({
  credentials: state.credentials.data,
  data: state.ui.modalData || {},
  dashboardSpace: state.ui.dashboards[state.ui.modalData.id],
  groups: state.groups.data,
  unsortedId: state.groups.unsortedGroupId,
  alreadySharedDashboard: 'shared' in state.ui.dashboards[state.ui.modalData.id] && state.ui.dashboards[state.ui.modalData.id].shared &&
    'usersSharedWith' in state.ui.dashboards[state.ui.modalData.id] && state.ui.dashboards[state.ui.modalData.id].usersSharedWith.length > 0,  
  sharedWithMeDashboard: 'externalDashboard' in state.ui.dashboards[state.ui.modalData.id] && state.ui.dashboards[state.ui.modalData.id].externalDashboard,
  sharedAccounts: state.sharedAccounts,
  currentCredentials: Object.values(state.credentials.data)
    .filter(cred => cred.dashboardSpaceId == state.ui.modalData.id),
});

const mapDispatchToProps = dispatch => ({
  updateCredential: args => dispatch(updateCredential(args)),
  deleteCredential: args => dispatch(deleteCredential(args)),
  getCredential: id => dispatch(getOneCredential(id)),
  hide: () => dispatch(hideModal()),
  showModal: (name, data) => dispatch(showModal(name, data)),
  stopSharingAccount: params => dispatch(stopSharingAccount(params)),
  stopSharingDashboard: params => dispatch(RXStopSharingDashboard(params)),
  stopSharingSharedAccounts: params => dispatch(RXSharedAccountsRemoveBulk(params)),
  updateUsersSharedWith: params => dispatch(RXUpdateUsersShareWith(params)),
});

export default translate()(connect(
  mapStateToProps,
  mapDispatchToProps,
)(withOverlayAction(ModalStopSharingDashboard)));
