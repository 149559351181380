/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
export const f1 = {
  id: 'f1',
  label: 'Break App Button',
};

export const f2 = {
  id: 'f2',
  label: 'Shuffle Groups On Click',
};
