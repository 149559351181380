/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { SIDEBAR } from 'constants/ids';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Styles from './style.css';

const SidebarWrapperComponent = ({ active, children, wide }) => (
  <div
    className={classNames({
      [Styles.sidebar]: true,
      [Styles.active]: active,
      [Styles.wide]: wide,
    })}
    id={SIDEBAR}
    role="dialog">
    {children}
  </div>
);

SidebarWrapperComponent.propTypes = {
  active: PropTypes.bool.isRequired,
  wide: PropTypes.bool,
};

export default SidebarWrapperComponent;
