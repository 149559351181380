/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import env from 'runtime/env';
import Request from './request';

class SlackRequest extends Request {
  constructor(params) {
    super(env.slackWebhook);
    if (params) this.params = params;
    this._loggerMask = 'Slack';
    this._parser = 'text';
  }

  attach(attachments = []) {
    this._addParams({ attachments });
    return this;
  }

  send() {
    return super.send(false);
  }

  to(channel) {
    this._addParams({ channel });
    return this;
  }
}

export default SlackRequest;
