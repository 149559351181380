/*
 * Copyright (C) 2019 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */

/**
 * @returns {string}
 */
export const getQuery = () => (_, getState) => { // eslint-disable-line import/prefer-default-export
  const state = getState();
  const { query } = state.search;
  return query;
};
