/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { WELCOME_VIDEO } from 'constants/videos';
import {
  MODAL_PROMPT_BACKUP, MODAL_RECOVER_MASTER_KEY, MODAL_PROMPT_TRACKING, MODAL_WELCOME_VIDEO,
} from 'constants/modal';
import { URL_GROUPS, URL_USERS, URL_CREDENTIAL } from 'constants/endpoints';
import { List } from 'immutable';
import { isProduction } from '@nettoken/env';
import { LABEL_UNSORTED_GROUP } from '@nettoken/models';
import { CW_DECRYPT_USING_MASTER_KEYPAIR, getWorkerPromise } from '@nettoken/crypto-worker';
import i18n from 'config/i18n';
import { pendingInvitationGroupId } from 'main/vault/credentials';
import { RXCredentialsUpdate } from 'main/vault/credentials/reduxActions';
import { hasMasterKeyBackup } from 'main/backup/reduxState';
import { getCookieBoolean } from 'main/cookies';
import { deleteUserAccount, exportUserData } from 'main/user';
import { CK_HIDE_BACKUP_PROMPT } from 'main/cookies/types';
import { showModal, hideModal } from 'main/modal';
import { RXPrefsSet } from 'main/preferences/reduxActions';
import { createGroup } from 'main/vault/groups';
import { getPredefinedAccounts } from 'main/search';
import { initialiseTutorials } from 'main/tutorial';
import { RXUIGuideBackup } from 'main/ui/reduxActions';
import { RXGroupsOrder } from 'main/vault/groupsOrder/reduxActions';
import { deleteCredentials } from 'main/vault/credentials';

import {
  decryptUserCredentials,
  ephemeralStateChange,
  extendCredentialsWithPredefinedAccounts,
  signInUser,
  getUserData,
} from 'main/user';

import { getSharedCredentialData } from 'main/sharedAccounts';
import { RXUserChangesSave, RXAddDashboardInvites } from 'main/user/reduxActions';
import { getUserProfile, hasEncryptedData } from 'main/user/reduxState';
import { addCredentials, hasAnyCredentials } from 'main/vault/credentials';
import { appendGroup, setGroupsOrder } from 'main/vault/groups';

import { Request, SlackRequest } from 'utils/request';
import { isExtensionConnected } from 'utils/extension';
import { func } from 'prop-types';
import { data } from 'jquery';
import { encryptCredential } from '../user';
import getUiModelTemplateState, { getCurrentModalData } from '../ui/reduxState';
import { hideOverlay } from '../overlay';
import { RXUsersApiCallStatus } from '../ui/reduxActions';
import { RXSRemoveSharedAccounts, RXSharedAccountsCreate } from '../sharedAccounts/reduxActions';
import { RXModalData } from '../modal/reduxActions';

const ERR_WRONG_MASTER_KEY = 'ERR_WRONG_MASTER_KEY';

/**
 * @param {string} id User id.
 * @param {List} encryptedCredentials
 * @param {string} token
 *
 * @returns {List}
 */
export const decryptCredentials = async (id, encryptedCredentials, token, opt = false) => {
  try {
    const decryptedCredentials = await decryptUserCredentials(encryptedCredentials);
    // if (encryptedCredentials.size !== decryptedCredentials.size) {
    //   throw new Error(ERR_WRONG_MASTER_KEY);
    // }

    // Fetch all unique global accounts.
    const ids = decryptedCredentials.reduce((result, credential) => {
      const accountId = credential.get('accountId');
      if (!result.includes(accountId)) result.push(accountId);
      return result;
    }, []);
    // const req = new Request(URL_USERS);
    // const obj = await req.authorise(token).get();
    const predefined = ids.length ? await getPredefinedAccounts({ ids }, token) : [];
    const decrypted = await extendCredentialsWithPredefinedAccounts(id, predefined, decryptedCredentials); // eslint-disable-line max-len
    return decrypted;
  }
  catch (e) {
    throw e;
  }
};

const displayPromptIfNeeded = (skipPrompt = false) => async (dispatch, getState) => {
  const template = dispatch(getUiModelTemplateState());
  if (template === 'connection-lost') {
    window.location.reload();
    // dispatch(hideOverlay());
    // dispatch(hideModal());
  }
  const { browser, isPad } = getState().device;
  const { data: credentials } = getState().credentials;
  // const key = '3274d511e18264c3fb092a276c579fdb';
  const obj = await getState().groups.data;
  let accounts = 0;
  Object.keys(obj).forEach(key => {
    accounts += obj[key].apps.length;
  });
  if (!dispatch(hasAnyCredentials()) && !skipPrompt) {
    if (isExtensionConnected() || isPad) {
      if (accounts < 1 && !skipPrompt) {
        dispatch(showModal(MODAL_WELCOME_VIDEO, {
          video: WELCOME_VIDEO,
          steps: ['video'],
        }));
      }
    }
    else if (browser !== null) {
      if (accounts < 1 && !skipPrompt) {
        dispatch(showModal(MODAL_WELCOME_VIDEO,
          { steps: ['extension', 'video'], video: WELCOME_VIDEO }));
      }
    }
    else {
      console.log('');
      if (accounts < 1 && !skipPrompt) {
        dispatch(showModal(MODAL_WELCOME_VIDEO,
          { steps: ['instruction', 'video'], video: WELCOME_VIDEO }));
      }
    }
  }
  else if (dispatch(hasEncryptedData()) && !dispatch(hasMasterKeyBackup())) {
    if (!getCookieBoolean(CK_HIDE_BACKUP_PROMPT)) {
      if (browser !== null && !isPad && !skipPrompt) {
        dispatch(showModal(MODAL_PROMPT_BACKUP));
      }

      // var1 = false;
    }
    const test1 = !dispatch(hasMasterKeyBackup());
    dispatch(RXUIGuideBackup(true));
  }
  else if (isExtensionConnected() && !skipPrompt) {
    if (accounts < 1) {
      dispatch(showModal(MODAL_WELCOME_VIDEO, { steps: ['video'], video: WELCOME_VIDEO }));
    }
  }
  else if (browser !== null && !isPad && !skipPrompt) {
    if (accounts < 1) {
      dispatch(showModal(MODAL_WELCOME_VIDEO, { steps: ['extension'] }));
    }
  }
  else if (!isPad && !skipPrompt) {
    if (accounts < 1) {
      dispatch(showModal(MODAL_WELCOME_VIDEO, { steps: ['instruction'] }));
    }
  }
  else {
    const result = dispatch(displayMasterKeyBackupPromptIfNeeded());
    if (!result) {
      dispatch(displayTrackingPromptIfNeeded());
    }
  }
  // else if (dispatch(hasEncryptedData()) && !dispatch(hasMasterKeyBackup())) {
  //   if (!getCookieBoolean(CK_HIDE_BACKUP_PROMPT)) {
  //     dispatch(showModal(MODAL_PROMPT_BACKUP));
  //   }
  //   dispatch(RXUIGuideBackup(true));
  // }
};

export const displayMasterKeyBackupPromptIfNeeded = () => (dispatch, getState) => {
  const res = false;
  if (dispatch(hasEncryptedData()) && !dispatch(hasMasterKeyBackup())) {
    dispatch(showModal(MODAL_PROMPT_BACKUP));
    dispatch(RXUIGuideBackup(true));
  }
  return res;
};

// export const totalcount = () => getState => {
//   console.log('totalcount', getState);
// };

export const displayTrackingPromptIfNeeded = () => (dispatch, getState) => {
  const { tracking } = getState().preferences;
  if (tracking === null) {
    dispatch(showModal(MODAL_PROMPT_TRACKING));
    return true;
  }

  return false;
};

/**
 * Returns the unsorted group from the initial state. If it does not exist,
 * we force the server to create it.
 *
 * @param {array} groups
 * @param {string} token
 *
 * @returns {object} Group state.
 */
const est = true;
const getUnsortedGroup = (groups, token) => dispatch => {
  let [unsorted] = groups.filter(x => x.name === LABEL_UNSORTED_GROUP);
  if (unsorted) return Promise.resolve(unsorted);
  const req = new Request(URL_GROUPS);
  return req.authorise(token).post({ name: LABEL_UNSORTED_GROUP })
    .then(res => {
      unsorted = res.group;
      const next = res.groupsOrder.filter(x => x !== unsorted.id);
      return setGroupsOrder({ next, token, est });
    })
    .then(() => Promise.resolve(unsorted))
    .catch(e => Promise.reject(e));
};

const isInivite = true;
const getPendingInviteGrp = (pending, token) => dispatch => {
  if (pending) return Promise.resolve(pending);
  const req = new Request(URL_GROUPS);
  return req.authorise(token).post({ name: 'Pending Invitations' })
    .then(res => {
      pending = res.group;
      const next = res.groupsOrder.filter(x => x !== pending.id);
      return setGroupsOrder({ next, token });
    })
    .then(() => Promise.resolve(pending))
    .catch(e => Promise.reject(e));
};
/**
 * Receive a groups response data from the server and place them into the
 * `objects` tree as well as create an ordered list for user interface display.
 *
 * @param {string} token
 * @param {List} list
 * @param {array} order
 */
const sortGroupsIntoSchema = (token, list, order) => dispatch => {
  const tree = {};
  return dispatch(getUnsortedGroup(list, token))
    .then(unsorted => {
      list.forEach(x => tree[x.id] = x);
      tree[unsorted.id] = unsorted;
      let promises = new List([dispatch(appendGroup(unsorted.name, unsorted.id))]);
      order.forEach(id => {
        const x = tree[id];
        if (x) {
          promises = promises.push(dispatch(
            appendGroup(x.name, x.id, x.source, x.dashboardSpaceId),
          ));
        }
      });
      return Promise.all(promises.toArray());
    });
};
let pendinggroupId = null;
const sortPendingGroupsIntoSchema = (token, list, order) => dispatch => {
  const tree = {};
  return dispatch(getPendingInviteGrp(list, token))
    .then(pending => {
      pendinggroupId = pending.id;
      const promises = new List([dispatch(appendGroup('Pending Invitations', pending.id))]);
      return Promise.all(promises.toArray());
    });
};

/**
 * @param {string} id User id.
 * @param {object} group
 */
const warnCredentialsMismatch = (id, group) => {
  const text = i18n.t('slack.credentialsMismatch.text', {
    credentials: group.credentials.size,
    credentialsOrder: group.credentialsOrder.length,
    groupId: group.id,
    id,
  });
  console.warn(text);

  if (isProduction) {
    const channel = i18n.t('slack.credentialsMismatch.channel');
    new SlackRequest({ text }).to(channel).silent('post');
  }
};

/**
 * @param {string} id User id.
 * @param {string} accessToken User access token.
 * @param {List} list Immutable list of user groups.
 * @param {array} order Array of group ids representing their order in the UI.
 */
export const authenticateUser = (
  id, accessToken, list, order, shared1 = new List(), options = {},
) => async (dispatch, getState) => {
  // dispatch(getUserData(accessToken));
  const req = new Request(URL_USERS);
  const { profile } = await req.authorise(accessToken).get();
  const pendingInvitation = profile.shared;
  const pendings = new List();
  const pendingList = pendings.push(profile.shared);
  const test = list.push(profile.shared);
  const reducer = (acc, value) => acc.push(...value);
  const sharedAccountsIds = [];
  const encryptedCredentials = test
    .map(group => group.credentials || group.credential_invites || group.invitations)
    .reduce(reducer, new List());
  const encryptedPendingCredentials1 = pendingList
    .map(group => group.credential_invites || group.invitations)
    .reduce(reducer, new List());
  // Create internal state schema.
  pendingInvitationGroupId();
  const connectionLostTimeout = 'connectionLostTimeout' in profile.settings ?
    profile.settings.connectionLostTimeout :
    5;
  // console.log('spec_check_infites_dasj', dashboardInvites, profile.shared.dashboard_invites);
  if ('dashboard_invites' in profile.shared && profile.shared.dashboard_invites.length > 0) {
    const dashboardInvites = Object.values(profile.shared.dashboard_invites).map(x => {
      x.id = x.invitationId;
      return x;
    });
    dispatch(RXAddDashboardInvites(dashboardInvites));
  }
  else {
    dispatch(RXAddDashboardInvites([]));
  }
  // console.log('spec_check_infites_dasj', dashboardInvites);


  // setTimeout(() => {
  //   new Request(URL_CREDENTIAL)
  //     .setUrlParams({ id: '9ccffa21a01e39af41f5cda225b41dcd' })
  //     .authorise()
  //     .delete()
  //     .then(console.log)
  //     .catch(console.log);
  // }, 4000);
  const pendingInvitationGrpId = pendingInvitationGroupId();
  const newOrder = [pendingInvitationGrpId];
  order.forEach(grpId => {
    // if (!(test.filter(group => group.id == grpId && group.name == 'unsorted').size > 0)) {
    if (test.filter(group => group.id == grpId && group.name == 'unsorted').size > 0) {
      // newOrder.push(grpId);
      console.log('');
    }
    else {
      newOrder.push(grpId);
    }
  });
  dispatch(RXGroupsOrder(newOrder));
  return dispatch(sortPendingGroupsIntoSchema(accessToken, pendingInvitation, order))
    .then(() => dispatch(sortGroupsIntoSchema(accessToken, test, order)))
    .then(() => decryptCredentials(id, encryptedCredentials, accessToken))
    .then(credentials => {
      const tmpCred = credentials.toArray();
      let dashboardCredsCount = 0;
      let usersApiCallInProcess = false;
      const modalData = dispatch(getCurrentModalData());
      const templateId = dispatch(getUiModelTemplateState());
      if (templateId == 'account-edit' && modalData.id) {
        const credential = tmpCred.filter(x => x.get('id') == modalData.id);
        if (credential && credential[0]) {
          // modalData.email = credential[0].get('email');
          modalData.updated_fields = true;
          modalData.updated_email = credential[0].get('email');
          modalData.updated_username = credential[0].get('username');
          modalData.updated_note = credential[0].get('note');
          modalData.updated_password = credential[0].get('password');
          dispatch(RXModalData(modalData));
        }
      }
      // const currentDashboardId = modalData?.id;
      const { currentDashboard: currentDashboardId } = getState().ui;
      credentials.filter(credential => {
        const shared = credential.get('shared');
        const accountId = credential.get('id');
        const externalAccount = credential.get('externalAccount');
        if (shared) {
          sharedAccountsIds.push(accountId);
          dispatch(RXSharedAccountsCreate(accountId, credential.get('usersSharedWith')));
        }
        else {
          const usersShredWith = credential.get('usersSharedWith');
          if (!usersShredWith || usersShredWith.length == 0) {
            dispatch(RXSRemoveSharedAccounts(accountId));
          }
        }
        if (currentDashboardId && credential.get('dashboardSpaceId') == currentDashboardId) {
          dashboardCredsCount += dashboardCredsCount;
        }
      });
      if (dashboardCredsCount > 0) {
        usersApiCallInProcess = true;
        dispatch(RXUsersApiCallStatus({
          dashboardCredsCount,
          usersApiCallInProcess,
        }));
      }
      else {
        dispatch(RXUsersApiCallStatus({
          usersApiCallInProcess,
          usersApiCallFinished: true,
        }));
      }
      dispatch(addCredentials({
        credentials,
        pushToServer: false,
        pendingId: pendinggroupId,
        override: options.override,
      }));
    })
    // .then(credentials => {
    //   console.log('credentials..', credentials);
    //   // credentials.filter(credential => {
    //   //   console.log('cre', credential.get('externalAccount'));
    //   //   const shared = credential.get('shared');
    //   //   const accountId = credential.get('id');
    //   //   const externalAccount = credential.get('externalAccount');
    //   //   if (!externalAccount) sharedAccountsIds.push(accountId);
    //   // });
    //   dispatch(addCredentials({
    //     credentials, pushToServer: false, override: options.override,
    //   }));
    // })
    // .then(() => decryptCredentials(id, encryptedCredentials, accessToken))
    // Create internal state schema.
    // .then(credentials => {
    //   console.log('credentials..1', credentials);
    //   credentials.filter(credential => {
    //     // const shared = credential.get('shared');
    //     // const accountId = credential.get('id');
    //     // const externalAccount = credential.get('externalAccount');
    //     // if (shared && !externalAccount) sharedAccountsIds.push(accountId);
    //   });
    //   dispatch(addCredentials({
    //     credentials, pushToServer: false, override: options.override,
    //   }));
    // })
    // .then(() => {
    //   // order.unshift('d6c6f624109e45f6b495a950aa5c3f98');
    //   dispatch(getUnsortedGroup1(list, accessToken))
    //     .then(() => {
    //       if (isInivite) {
    //         console.log('isinvite', isInivite);
    //         console.log('orderdispatch', order);
    //         dispatch(sortGroupsIntoSchema1(accessToken, list, order));
    //       }
    //     });
    // })
    // Create internal state schema.
    .then(() => dispatch(initialiseTutorials()))
    // Go to dashboard.
    .then(() => dispatch(signInUser(accessToken)))
    .then(() => dispatch(RXPrefsSet('connectionLostTimeout', connectionLostTimeout)))
    .then(() => dispatch(displayPromptIfNeeded(options.skipPrompt)))
    // .then(() => sharedAccountsIds.map(credentialId => {
    //   console.log('spec_chechec_dec', credentials[credentialId])
    //   dispatch(getSharedCredentialData(credentialId));
    // }))
    .catch(e => {
      if (typeof e === 'object' && e.message === ERR_WRONG_MASTER_KEY) {
        dispatch(showModal(MODAL_RECOVER_MASTER_KEY, { accessToken }));
        return Promise.reject();
      }

      return Promise.reject(e);
    });
};

export const authenticateUserData = (
  id, accessToken, list, order, shared1 = new List(), options = {}, profile2 = null,
) => async (dispatch, getState) => {
  let profile = profile2;
  if (profile2 == null) {
    const req = new Request(URL_USERS);
    const { profile: userProfile } = await req.authorise(accessToken).get();
    profile = userProfile;
  }
  else {
    profile = profile2;
  }
  const pendingInvitation = profile.shared;
  const pendings = new List();
  const pendingList = pendings.push(profile.shared);
  const test = list.push(profile.shared);
  const reducer = (acc, value) => acc.push(...value);
  const sharedAccountsIds = [];
  const encryptedCredentials = test
    .map(group => group.credentials || group.credential_invites || group.invitations)
    .reduce(reducer, new List());
  const encryptedPendingCredentials1 = pendingList
    .map(group => group.credential_invites || group.invitations)
    .reduce(reducer, new List());
  // Create internal state schema.
  pendingInvitationGroupId();
  const connectionLostTimeout = 'connectionLostTimeout' in profile.settings ?
    profile.settings.connectionLostTimeout :
    5;
  if ('dashboard_invites' in profile.shared && profile.shared.dashboard_invites.length > 0) {
    const dashboardInvites = Object.values(profile.shared.dashboard_invites).map(x => {
      x.id = x.invitationId;
      return x;
    });
    dispatch(RXAddDashboardInvites(dashboardInvites));
  }
  else {
    dispatch(RXAddDashboardInvites([]));
  }

  return dispatch(sortPendingGroupsIntoSchema(accessToken, pendingInvitation, order))
    .then(() => dispatch(sortGroupsIntoSchema(accessToken, test, order)))
    .then(() => decryptCredentials(id, encryptedCredentials, accessToken))
    .then(credentials => {
      const tmpCred = credentials.toArray();
      const templateId = dispatch(getUiModelTemplateState());
      const modalData = dispatch(getCurrentModalData());
      if (templateId == 'account-edit' && modalData.id) {
        const credential = tmpCred.filter(x => x.id == modalData.id);
        if (credential && credential[0]) {
          modalData.email = credential[0].email;
          dispatch(RXModalData(modalData));
        }
      }
      const { currentDashboard: currentDashboardId } = getState().ui;
      let dashboardCredsCount = 0;
      let usersApiCallInProcess = false;
      credentials.filter(credential => {
        const shared = credential.get('shared');
        const accountId = credential.get('id');
        const externalAccount = credential.get('externalAccount');
        if (currentDashboardId && credential.get('dashboardSpaceId') == currentDashboardId) {
          dashboardCredsCount += dashboardCredsCount;
        }
        if (shared) {
          sharedAccountsIds.push(accountId);
          dispatch(RXSharedAccountsCreate(accountId, credential.get('usersSharedWith')));
        }
        else {
          const usersShredWith = credential.get('usersSharedWith');
          if (!usersShredWith || usersShredWith.length == 0) {
            dispatch(RXSRemoveSharedAccounts(accountId));
          }
        }
      });
      if (dashboardCredsCount > 0) {
        usersApiCallInProcess = true;
        dispatch(RXUsersApiCallStatus({
          dashboardCredsCount,
          usersApiCallInProcess,
        }));
      }
      else {
        dispatch(RXUsersApiCallStatus({
          usersApiCallInProcess,
          usersApiCallFinished: true,
        }));
      }
      dispatch(addCredentials({
        credentials,
        pushToServer: false,
        pendingId: pendinggroupId,
        override: options.override,
      }));
    })
    // Create internal state schema.
    .then(() => dispatch(initialiseTutorials()))
    // Go to dashboard.
    .then(() => dispatch(signInUser(accessToken, profile2 == null)))
    .then(() => dispatch(RXPrefsSet('connectionLostTimeout', connectionLostTimeout)))
    .then(() => dispatch(displayPromptIfNeeded(options.skipPrompt)))
    .catch(e => {
      if (typeof e === 'object' && e.message === ERR_WRONG_MASTER_KEY) {
        dispatch(showModal(MODAL_RECOVER_MASTER_KEY, { accessToken }));
        return Promise.reject();
      }

      return Promise.reject(e);
    });
};

/**
 * Receive user preferences response data from the server and place it into the
 * `preferences` state reducer.
 *
 * @param {object} prefs
 * @property {integer} [age]
 * @property {boolean} cookies
 * @property {boolean} device
 * @property {string} [email]
 * @property {string} [location]
 * @property {string} [name]
 * @property {string} [phone]
 */
export const setUserPreferencesRedux = prefs => dispatch => {
  dispatch(RXPrefsSet('cookies', prefs.cookies));
  dispatch(RXPrefsSet('device', prefs.device));

  const profile = dispatch(getUserProfile());
  if (prefs.age) profile.age = prefs.age.toString();
  if (prefs.email) profile.email = prefs.email;
  if (prefs.location) profile.location = prefs.location;
  if (prefs.name) profile.name = prefs.name;
  if (prefs.phone) profile.phone = prefs.phone;
  if (prefs.id) profile.id = prefs.id;
  dispatch(ephemeralStateChange({ profile }));
  dispatch(RXUserChangesSave());
};
