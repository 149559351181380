/*
 * Copyright (C) 2018-2019 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { history } from 'reducers/store';

// Force browser refresh on state change in older browsers.
const supportsHistory = 'pushState' in window.history;

/**
 * We use this component to provide Redux store in arbitrary components.
 * This is used for launching the app and testing individual components.
 *
 * @param {object} props
 * @property {any} children
 * @property {object} store
 */
const ProviderComponent = ({ children, store }) => (
  <Provider store={store}>
    <ConnectedRouter
      forceRefresh={!supportsHistory}
      history={history}>
      {children}
    </ConnectedRouter>
  </Provider>
);

ProviderComponent.propTypes = {
  store: PropTypes.object.isRequired,
};

export default ProviderComponent;
