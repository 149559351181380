/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { EMAIL_SUPPORT, MASTER_KEY_BACKUP_EXT } from 'constants/misc';
import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button } from '@nettoken/components';
import Routes from 'config/routes';
import Input from 'Input';
import logo from 'images/logo.svg';
import Styles from 'Modal/style.css';
import classnames from 'classnames';

const ModalRecoverMasterKeyContainer = ({
  accept,
  disabled,
  error,
  keyFile,
  keyInput,
  loading,
  onCancel,
  onChange,
  onSubmit,
  t,
}) => (
  <form
    action="/"
    className={Styles.formMasterKeyRecovery}
    method="post"
    onSubmit={onSubmit}>
    <div className={Styles.formMasterKeyRecoveryContent}>
      <div className={Styles.logoHeader}>
        <img
          alt={t('alt.logo')}
          className={Styles.logoCircle}
          src={logo} />

        <span className={Styles.errorTitle}>
          {t('error.generic')}
        </span>
      </div>

      <p className={Styles.errorText}>
        {error}
      </p>
    </div>

    <div className={`${Styles.actions} ${Styles.right}`}>
      <Button
        className={classnames({
          [Styles.button]: true,
          [Styles.textStyle]: true,
        })}
        loaderSize={20}
        loading={loading}
        type="submit"
        value={t('global.OK')} />
    </div>
  </form>
);

ModalRecoverMasterKeyContainer.defaultProps = {
  accept: '',
  error: '',
  keyFile: '',
  keyInput: '',
};

ModalRecoverMasterKeyContainer.propTypes = {
  accept: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  keyFile: PropTypes.string,
  keyInput: PropTypes.string,
  loading: PropTypes.bool,
  onCancel: PropTypes.func,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  /** i18n translate method */
  t: PropTypes.func.isRequired,
};

export default ModalRecoverMasterKeyContainer;
