/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { isProduction } from '@nettoken/env';

// Ensures getters/setters do not work in production.
// Prevents us from deploying debuggers if we leave them on.
export const canExecuteDeveloperCommand = !isProduction;

/*
 * Function for retrieving Webpack hot modules. This way it can be
 * stubbed during testing.
 */
export const getHotModule = () => module.hot;
