/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@nettoken/components';
import { noop, reloadBrowser } from '@nettoken/helpers';
import Styles from 'Modal/style.css';
import CancelIcon from '../../../ActionIcons/CancelIcon';

const ConnectionLost = ({
  loading,
  onCancel,
  t,
}) => (
    <React.Fragment>
      <h1
        aria-label="Server Connection is Lost"
        className={Styles.title}
        style={{
          position: 'relative',
        }}
      >
        Connection lost
        <span
          style={{
            position: 'absolute',
            right: '13px',
            border: '0.5px solid #28283F',
            transform: 'rotate(45deg)',
            width: '18px',
          }}
          onClick={onCancel}
        ></span>
        <span
          style={{
            width: '18px',
            position: 'absolute',
            right: '13px',
            border: '0.5px solid #28283F',
            transform: 'rotate(-45deg)',
          }}
          onClick={onCancel}
        ></span>
      </h1>

      <form
        action="/"
        style={{
          width: '100%',
          backgroundColor: '#fff',
          maxWidth: '580px',
        }}
        method="post"
        onSubmit={noop}>
        <br /><br />
        <p className={Styles.emailScanDisclaimer}>
          To protect your data, we've logged you out. You can log in as <br/>
          soon as you connect again.
        </p>
        <div className={Styles.connectionLostMsg}>
          <div className={Styles.emailScanDisclaimer}>
            <p>You can change the session timeout in your settings for when:</p>
            <p>1. no activity is detected <br /> 2. wifi connection is lost</p>
          </div>
          <img src="lostconnection.png" />
        </div>
        <div style={{ textAlign: 'center' }}>
          <Button
            className={Styles.backupPromptButton}
            onClick={reloadBrowser}
            value="LOG IN"
          />
        </div>
      </form>
    </React.Fragment>
);

ConnectionLost.propTypes = {
  onCancel: PropTypes.func,
  /** i18n translate method */
  t: PropTypes.func.isRequired,
};

export default ConnectionLost;
