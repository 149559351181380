/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
export const RX_ERROR_CLEAN = 'RX_ERROR_CLEAN';
export const RX_ERROR_SHOW = 'RX_ERROR_SHOW';
export const RX_ERROR_UI = 'RX_ERROR_UI';
