/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { SEARCH } from 'constants/ids';
import * as SH from 'constants/shortcuts';
import MobileDetect from 'mobile-detect';

import {
  $,
  SPECIAL_KEYS,
  addClass,
  addEvent,
  eventTarget,
  getCrossBrowserEvent,
  isCombinationPressed,
  removeEvent,
} from '@nettoken/helpers';

import { RXToolsHide, RXToolsShow } from 'main/tools/reduxActions';
import { canExecuteDeveloperCommand } from 'utils/module';
import { detectBrowser } from 'utils/misc';

import {
  RXDeviceBrowser,
  RXDeviceDesktop,
  RXDeviceHotkeysAdd,
  RXDeviceHotkeysRemove,
  RXDeviceMobile,
  RXDevicePhone,
  RXDeviceTablet,
  RXDeviceIpad,
} from './reduxActions';

let d;

/**
 * @param {function} callback
 * @param  {...string} shortcut Key combination to trigger the callback.
 */
const createHotkey = (callback, ...shortcut) => event => dispatch => {
  if (!isCombinationPressed(event, ...shortcut)) return false;
  dispatch(callback);
  return true;
};

const focusNavbarSearch = () => {
  const node = $(`#${SEARCH}`);
  if (node) node.focus();
};

const toggleDeveloperTools = (dispatch, getState) => {
  if (!canExecuteDeveloperCommand) return;

  const state = getState();
  if (state && state.tools && state.tools.active) {
    dispatch(RXToolsHide());
  }
  else {
    dispatch(RXToolsShow());
  }
};

const commands = {
  [SH.FOCUS_NAVBAR_SEARCH]: createHotkey(focusNavbarSearch, 'shift', 'f'),
  [SH.TOGGLE_DEVELOPER_TOOLS]: createHotkey(toggleDeveloperTools, 'shift', 'd'),
};

// We disable shortcuts when focused on editable nodes to prevent clashes.
// Consider adding contentEditable in the future.
const ignoreShortcutsOnElements = ['input', 'textarea'];

/**
 * @param {string} os
 *
 * @returns {string}
 */
const getOSSpecificClassName = os => {
  switch (os) {
    case 'macintel':
      return 'mac';
    case 'win32':
      return 'win';
    default:
      return '';
  }
};

/**
 * @param {object} event
 */
const onKeyDown = event => {
  event = getCrossBrowserEvent(event);
  d(handleKeyDown(event));
};

/**
 * @param {array|string} [shortcuts=[]] We implicitly wrap string into an array
 *   if we are adding only one shortcut.
 */
export const addKeyboardShortcuts = (shortcuts = []) => dispatch => {
  if (!Array.isArray(shortcuts)) shortcuts = [shortcuts];
  dispatch(RXDeviceHotkeysAdd(shortcuts));
};

export const appendOSSpecificClassName = () => {
  const os = (window.navigator.platform || '').toLowerCase();
  const className = getOSSpecificClassName(os);
  if (className) addClass(document.body, className);
};

export const bindKeyboardShortcuts = () => dispatch => {
  d = dispatch;
  addEvent(document, 'keydown', onKeyDown);
};

/**
 * @param {object} event
 */
export const handleKeyDown = event => (dispatch, getState) => {
  // Special keys on its own should not do anything.
  if (event.key && SPECIAL_KEYS.includes(event.key.toLowerCase())) return;

  const tagName = eventTarget(event).tagName || '';
  if (ignoreShortcutsOnElements.includes(tagName.toLowerCase())) return;

  const { availableShortcuts: hotkeys } = getState().device;

  for (let i = 0; i < hotkeys.length; i += 1) {
    const shortcut = hotkeys[i];
    const hotkey = commands[shortcut];
    if (hotkey && dispatch(hotkey(event))) {
      event.preventDefault();
      break;
    }
  }
};

export const performDeviceChecks = () => dispatch => {
  const md = new MobileDetect(window.navigator.userAgent);

  const isMobile = md.mobile();
  const isPhone = md.phone();
  const isTablet = md.tablet();
  const isPad = isTablet === 'iPad' || (/Macintosh/i.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 1);
  const isDesktop = !isMobile && !isPhone && !isTablet;

  dispatch(RXDeviceDesktop(isDesktop));
  dispatch(RXDeviceMobile(isMobile));
  dispatch(RXDevicePhone(isPhone));
  dispatch(RXDeviceTablet(isTablet));
  dispatch(RXDeviceIpad(isPad));

  detectBrowser()
    .then(browser => dispatch(RXDeviceBrowser(browser)))
    .catch(e => console.log(e));
};

/**
 * @param {array|string} shortcuts We implicitly wrap string into an array
 *   if we are removing only one shortcut.
 */
export const removeKeyboardShortcuts = (shortcuts = []) => dispatch => {
  if (!Array.isArray(shortcuts)) shortcuts = [shortcuts];
  dispatch(RXDeviceHotkeysRemove(shortcuts));
};

/**
 * Attempts to store the data on local device.
 *
 * @param {any} data File contents.
 * @param {string} name Output file name including extension.
 * @param {string} [type='application/json'] Output file type.
 */
export const saveToLocalDevice = (data, name, type = 'application/json') => {
  const link = document.createElement('a');
  const file = new Blob([data], { type });
  link.href = URL.createObjectURL(file);
  link.download = name;
  link.click();
};

export const unbindKeyboardShortcuts = () => dispatch => {
  d = dispatch;
  removeEvent(document, 'keydown', onKeyDown);
};
