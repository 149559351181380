import React from 'react';
import Styles from 'Modal/style.css';
import { getIconColorNewmethod } from '../../utils/misc';
import SharingBtn from '../ActionIcons/SharingBtn';

const AccountSharedWithInBox = ({
  sharedCredentialData,
  accountClick,
  onMouseEnterSharingStatus,
  onMouseLeaveSharingStatus,
  sharingStatus,
  disableSharing,
  handleSharingAccount,
  credentialData,
  externalDashboard,
  chevronRightStyle,
}) => (
  <>
    {sharedCredentialData && sharedCredentialData.map((account, index) => (
      <div
        className={`${Styles.actions} ${Styles._squeezedAltered} ${Styles.liAccounts}`}
        key={account}
        style={{
          alignItems: 'center',
          display: 'flex',
        }}
        onClick={() => accountClick(account)}
      >
        <div className={Styles.sharedCredDetails}>
          <svg
            width='100%'
            height='100%'
            style={{
              borderRadius: '50%',
              height: '32px',
              width: '32px',
              border: `1px solid ${getIconColorNewmethod(account.name)}`,
            }}
          >
            <rect
              x='0'
              y='0'
              fill={getIconColorNewmethod(account.name)}
              height='100%'
              width='100%'
            ></rect>
            <text
              dominantBaseline='middle'
              fill='#ffffff'
              fontSize='10'
              textAnchor='middle'
              x='48%'
              y='54%'
            >
              {account.name.slice(0, 2).toUpperCase()}
            </text>
          </svg>
        </div>
        <div style={{ width: '230px' }}>
          <span
            style={{
              display: 'block',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {account.name}
          </span>
          <span
            style={{
              display: 'block',
              color: '#868692',
              fontSize: '14px',
            }}
          >
            {account.phone}
          </span>
          <span style={{ color: '#868692', fontSize: '14px' }}>
            {account.responded ? 'They can view only' : 'Pending invite'}
          </span>
        </div>
        <SharingBtn
          account={account}
          onMouseEnterSharingStatus={onMouseEnterSharingStatus}
          onMouseLeaveSharingStatus={onMouseLeaveSharingStatus}
          sharingStatus={sharingStatus}
          disableSharing={disableSharing}
          handleSharingAccount={handleSharingAccount}
          credentialData={credentialData}
          externalDashboard={externalDashboard}
          chevronRightStyle={chevronRightStyle}
        />
      </div>
    ))}
  </>
);

export default AccountSharedWithInBox;
