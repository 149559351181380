/*
 * Copyright (C) 2018-2019 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */

/**
 * @returns {object}
 */
export const getCredentialsRootObject = () => (_, getState) => getState().credentials.data;

/**
 * @param {string} id
 *
 * @returns {integer}
 */
export const getCredentialIndexInGroup = id => (dispatch, getState) => {
  const groups = getState().groups.data;
  const groupId = dispatch(getGroupIdFromCredentialId(id));
  if (groupId === false) return -1;
  return groups[groupId].apps.indexOf(id);
};

/**
 * @param {string} id
 */
export const getGroupIdFromCredentialId = id => dispatch => {
  const app = dispatch(getOneCredential(id));
  return app ? app.groupId : false;
};

/**
 * @param {string} id
 *
 * @returns {object} False if the credential does not exist.
 */
export const getOneCredential = id => (_, getState) => {
  const credentials = getState().credentials.data;
  const credential = credentials[id];
  if (!credential) return false;
  return Object.assign({}, credential);
};

export const getNewCredentials = () => (_, getState) => getState().credentials.setNewCredentials;
