/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import Cookies from 'cookies-js';

// Default expiration time for cookies.
const ONE_DAY = 60 * 60 * 24;

/**
 * This method should be run on app initialisation.
 */
export const configureCookies = () => {
  if (!Cookies.enabled) return;
  Cookies.defaults = {
    path: '/',
    secure: true,
  };
};

/**
 * @param {string} type
 * @param {boolean} [defaultValue=false]
 *
 * @returns {boolean}
 */
export const getCookieBoolean = (type, defaultValue = false) => {
  if (!Cookies.enabled) return defaultValue;
  const value = Cookies.get(type);
  return value === 'true';
};

/**
 * @param {string} type
 * @param {boolean} value
 * @param {integer} [expires=ONE_DAY]
 *
 * @returns {boolean}
 */
export const setCookieBoolean = (type, value, expires = ONE_DAY) => {
  if (!Cookies.enabled) return false;
  const newValue = value ? 'true' : 'false';
  Cookies.set(type, newValue, { expires });
  return true;
};
