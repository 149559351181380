import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { displayMasterKeyBackupPromptIfNeeded, displayTrackingPromptIfNeeded } from 'main/auth';
import { showModal, hideModal } from 'main/modal';
import withOverlayAction from 'Overlay/withAction';
import Container from './container';

class ModalWelcomeVideoComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStep: 0,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.hide = this.hide.bind(this);
  }


  handleSubmit() {
    const { currentStep } = this.state;
    if (currentStep === this.props.data.steps.length - 1) {
      this.hide();
      return;
    }
    this.setState(prevState => ({
      currentStep: prevState.currentStep + 1,
    }));
  }

  hide() {
    const isBackupPromptNeeded = this.props.displayMasterKeyBackupPromptIfNeeded();
    if (!isBackupPromptNeeded) {
      const isTrackingPromptNeeded = this.props.displayTrackingPromptIfNeeded();
      if (!isTrackingPromptNeeded) {
        this.props.hide();
      }
    }
  }

  myhide() {
    const isBackupPromptNeeded = this.props.displayMasterKeyBackupPromptIfNeeded();
    if (!isBackupPromptNeeded) {
      const isTrackingPromptNeeded = this.props.displayTrackingPromptIfNeeded();
      if (!isTrackingPromptNeeded) {
        this.props.hide();
      }
    }
  }

  totalaccount() {
    return this.props.appsTotal;
  }


  render() {
    const { currentStep } = this.state;
    return (
        <Container
          total={this.props.appsTotal}
          goToDashboard={this.hide}
          onSubmit={this.handleSubmit}
          step={this.props.data.steps ? this.props.data.steps[currentStep] : ''}
          videoUrl={this.props.data.video}
        />
    );
  }
}

const mapDispatchToState = state => ({
  data: state.ui.modalData || {},
  device: state.device.browser,
  appsTotal: Object.keys(state.credentials.data).length,
});
const mapDispatchToProps = dispatch => ({
  hide: () => dispatch(hideModal()),
  displayMasterKeyBackupPromptIfNeeded: () => dispatch(displayMasterKeyBackupPromptIfNeeded()),
  displayTrackingPromptIfNeeded: () => dispatch(displayTrackingPromptIfNeeded()),
  showModal: (name, data) => dispatch(showModal(name, data)),
});

export default translate()(connect(
  mapDispatchToState,
  mapDispatchToProps,
)(ModalWelcomeVideoComponent));
