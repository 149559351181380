/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@nettoken/components';
import { noop } from '@nettoken/helpers';
import Helmet from 'Helmet';
import Styles from 'Sidebar/style.css';

const Checkbox = ({ checked, name, onChange }) => (
  <div className={Styles.radioWrapper}>
    <input
      checked={checked}
      className={Styles.radioInput}
      id={name}
      onChange={onChange}
      name={name}
      // type="radio" would not be focusable with keyboard
      type="checkbox" />

    <label
      className={Styles.radioLabel}
      htmlFor={name}>
      <div className={Styles.radioBorder} />
    </label>
  </div>
);

class SidebarDownloadContainer extends React.Component {
  renderFragments(type) {
    const { t } = this.props;
    const isChecked = this.props.checkedOption === type;
    return (
      <React.Fragment>
        {isChecked && <p className={`${Styles.radioTextInExport} ${Styles._danger}`}>
          {t('views.export.warning')}
        </p>}

        <Checkbox
          checked={isChecked}
          name={type}
          onChange={this.props.onChangeCheckbox} />

        <Button
          className={Styles.radioButton}
          disabled={!isChecked}
          icon={true}
          light={true}
          loading={isChecked && this.props.downloading}
          onClick={this.props.onClickDownload}
          value='DOWNLOAD DATA' />
      </React.Fragment>
    );
  }

  render() {
    const { t } = this.props;
    return (
      <form
        action="/"
        className={Styles.sidebarFullHeight}
        method="post"
        onSubmit={this.props.onSubmit}>
        <Helmet title={t('views.export.title')} />

        <div className={`${Styles.content} ${Styles._menu} ${Styles._scrollable}`}>
          <div className={Styles.paddingFixer}>
            <h4 style={{ fontSize: '22px', color: '#28283F' }} className={`${Styles.title} ${Styles._danger}`}>
              Download  your data
            </h4>

            <ul>
              <li>
                <p>
                  Download a file with your Nettoken account data.
                </p>

                <ul>
                    <h4 className={Styles.radioTitle}>
                      Encrypted file
                    </h4>
                    <p>
                      {t('views.export.encryptedText')}
                    </p>
                  <li className={Styles.exportSectionItem}>
                    <span>
                    I agree and understand</span>
                    {this.renderFragments('encrypted')}
                  </li>
                  <h4 className={Styles.radioTitle}>
                     Decrypted file
                    </h4>
                    <p >
                      {t('views.export.decryptedText')}
                    </p>
                  <li className={Styles.exportSectionItem}>
                    <span className={Styles.exportSectionItem}>I agree and understand</span>
                    {this.renderFragments('decrypted')}
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>

        <div className={Styles.actions}>
          <Button
            className={Styles.button}
            onClick={this.props.onCancel}
            type="button"
            value={t('global.cancel')} />
        </div>
      </form>
    );
  }
}

SidebarDownloadContainer.defaultProps = {
  checkedOption: '',
  deleting: false,
  downloading: false,
  onCancel: noop,
  onChangeCheckbox: noop,
  onClickDownload: noop,
  onSubmit: noop,
};

SidebarDownloadContainer.propTypes = {
  checkedOption: PropTypes.string,
  deleting: PropTypes.bool,
  downloading: PropTypes.bool,
  onCancel: PropTypes.func,
  onChangeCheckbox: PropTypes.func,
  onClickDownload: PropTypes.func,
  onSubmit: PropTypes.func,
  /** i18n translate method */
  t: PropTypes.func.isRequired,
};

export default SidebarDownloadContainer;
