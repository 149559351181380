/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import iconChrome from 'assets/images/logo--chrome.svg';
import iconDashlane from 'assets/images/logo--dashlane.svg';
import iconLastPass from 'assets/images/logo--lastpass.svg';
import icononePassword from 'assets/images/logo--onePassword.svg';
import iconNettoken from 'assets/images/logo.svg';
import Styles from 'Modal/style.css';

const icons = {
  chrome: iconChrome,
  dashlane: iconDashlane,
  lastpass: iconLastPass,
  onePassword: icononePassword,
  nettoken: iconNettoken,
};

const urls = {
  chrome: 'https://passwords.google.com/options?ep=1',
  dashlane: 'https://support.dashlane.com/hc/en-us/articles/209340229-Exporting-and-importing-passwords#title1',
  lastpass: 'https://support.logmeininc.com/lastpass/help/how-do-i-nbsp-export-stored-data-from-lastpass-using-a-generic-csv-file',
  onePassword: 'https://support.1password.com/export/',
  nettoken: 'https://www.youtube.com/watch?v=R60O45vRw6Y',
};

const SupportedImportPlatformsList = ({ noMargin, t }) => (
  <div className={classNames({
    [Styles.fromFileOptions]: true,
    [Styles.noMargin]: noMargin,
  })}>
    <ul>
      {t('modal.fromFile.supportedList').map((platform, index) => {
        const icon = icons[platform.icon];
        const url = urls[platform.icon];
        return (
          <li className={Styles.fromFileItem} key={index}>
            <div className={classNames({
              [Styles.fromFileIconWrapper]: true,
              [Styles.noBg]: icon,
            })}>
              {icon && <img
                alt={`${platform.label} ${t('global.icon')}`}
                className={Styles.fromFileIcon}
                src={icon} />}
            </div>
            <span className={Styles.fromFileLabel}>
              <a href={url} target="_blank" className={Styles.anchorfromFileLabel}>{platform.label}</a>
            </span>
          </li>
        );
      })}
    </ul>
  </div>
);

SupportedImportPlatformsList.propTypes = {
  noMargin: PropTypes.bool,
  /** i18n translate method */
  t: PropTypes.func.isRequired,
};

export default SupportedImportPlatformsList;
