/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from '@nettoken/components';
import Avatar from 'Avatar';
import { PropIcon } from 'utils/propTypes';
import Input from 'Input';
import Styles from 'Modal/style.css';
import Dropdown from 'Dropdown';
import sharedAccountIcon from 'icons/upwardarrow.svg';
import sharedToMeIcon from 'icons/downwardarrow.svg';
import { getIconColorNewmethod } from '../../../../utils/misc';
import cross from '../../../../assets/images/cross.svg';
import leftsvg from '../../../../assets/images/left.svg';
import CancelIcon from '../../../ActionIcons/CancelIcon';
import LeftArrowIcon from '../../../ActionIcons/LeftArrowIcon';
import SharingBtn from '../../../ActionIcons/SharingBtn';
import EnvelopeIcon from '../../../ActionIcons/Envelope';
import AccountSharedWithInBox from '../../../AccountSharedWithInBox';
import chevronRight from '../../../../assets/images/chevronRight.png';

const ModalDashboardShareContainer = ({
  name,
  onCancel,
  sharedCredentialData,
  onchnageInput,
  searchuser,
  userWindow,
  userName,
  t,
  onMouseEnterSharingStatus,
  onMouseLeaveSharingStatus,
  sharingStatus,
  disableSharing,
  handleSharingAccount,
  credentialData,
  onClose,
  counter,
  sharedWithMeDashboard,
  alreadySharedDashboard,
  onClickHandler,
  accountClick,
  ownerName,
  ownerPhone,
  modalDashboard,
}) => (
  <React.Fragment>
    <h1
      aria-label={t('modal.accountShare.title')}
      className={Styles.title1}>
      {!alreadySharedDashboard && <LeftArrowIcon onClickBackHandler={onCancel} />}
      Dashboard sharing
      <CancelIcon onCancel={onClose} />
    </h1>
    <div className={`${Styles.whiteBackground2} ${Styles.minHeightBackground}`}>
      <div className={`${Styles.fixedForm} ${Styles.fixedForm2}`}>
        <div className={classNames({
          [Styles.iconHeaderWrapper]: true,
          [Styles.noBg]: true,
          [Styles.nameIconHoverEffect]: true,
        })}>
            <svg width='100%' height='100%' style={{ borderRadius: '50%' }}>
            <rect x='0' y='0' fill={getIconColorNewmethod(name)} height='100%' width='100%'></rect>
            <text
                dominantBaseline='middle'
                fill='#ffffff'
                fontSize='10'
                textAnchor='middle'
                x='48%'
                y='54%'
            >{name.slice(0, 2).toUpperCase()}</text>
            </svg>
          {alreadySharedDashboard &&
            <img
              src={sharedWithMeDashboard ? sharedToMeIcon : sharedAccountIcon}
              alt=''
              className={Styles.sharedIcon} style={{ top: '-6px' }}
            />
          }
        </div>
        <p style={{ marginBottom: '0px', textAlign: 'center' }}>{name}</p>
        <p style={{
          marginBottom: '0px', textAlign: 'center', color: '#868692', fontSize: '14px',
        }}>{`${counter} ${counter > 1 ? 'accounts' : 'account'}`}</p>
        {(!sharedWithMeDashboard) &&
        <div className={Styles.form}>
          <span style={{
            color: '#868692',
            fontSize: '14px',
            padding: '16px 12px 16px',
          }}>SELECT CONTACT</span>
          <Input className={Styles.shareinputbox} onChange={onchnageInput} type="text"
                placeholder="Type contact name or number"/>
          {
            searchuser.map(e => {
              let item = e.name;
              let isErrorMessage = false;
              if (e.errorMessage) {
                isErrorMessage = true;
                if (e.type === 'not_found') {
                  item = 'No nettoken contact found. Invite your friends to sign up first before sharing!';
                }
                else if (e.type === 'already_shared') {
                  item = `You are already sharing this dashboard with ${e.name}`;
                }
                else if (e.type === 'not_found_with_name') {
                  item = 'You can only search existing contacts by name. Type a phone number to share with new contacts.';
                }
                else if (e.type === 'myself') {
                  item = 'You cannot share with yourself.';
                }
              }
              return (
                <Dropdown
                  items={[item]}
                  fullWidthShare={true}
                  showCustomEntry={true}
                  isErrorMessage={isErrorMessage}
                  fromShare={true}
                  onClickItem={_ => userWindow(e)}
                  // eslint-disable-next-line no-confusing-arrow
                  itemBuilder={i => {
                    if (isErrorMessage) {
                      return i;
                    }
                    return (
                      <div
                        className={Styles.userSearchDropdownItem}
                        style={{
                          alignItems: 'center',
                        }}
                      >
                        <span
                          className={Styles.initials}
                          style={{ backgroundColor: getIconColorNewmethod(e.name) }}
                        >
                          {e.name.slice(0, 2).toUpperCase()}
                        </span>
                        <div className={Styles.userInfo}>
                          <div className={Styles.name}>{e.name}</div>
                          <div className={Styles.phone}>{e.phone}</div>
                        </div>
                      </div>
                    );
                  }}
                  t={t}
                />
              );
            })
          }
        </div>
      }
        <div style= {{ padding: '14px 21px' }}>
        <div>
        <ul className={Styles.tabList} style={{
          width: '100%',
          padding: '0px',
        }} >
          <li
          className={Styles.youLineStyle}
          style={{ gridTemplateColumns: '0.1fr 1fr 0.3fr 0.1fr', padding: '12px 0px !important' }}>
          <span>
            <svg
              width="100%"
              height="100%"
              style={{
                borderRadius: '50%',
                height: '32px',
                width: '32px',
                border: `1px solid ${getIconColorNewmethod(userName)}`,
              }}>
              <rect x="0" y="0" fill={getIconColorNewmethod(userName)} height="100%" width="100%"></rect>
              <text
                dominantBaseline="middle"
                fill="#ffffff"
                fontSize="10"
                textAnchor="middle"
                x="48%"
                y="54%"
              >{userName.slice(0, 2).toUpperCase()}</text>
            </svg>
          </span>
          <div style={{ marginLeft: '18px', width: '190px' }} >
            <span>{ userName } (you)</span><br />
            <span style={{ color: '#868692', fontSize: '14px' }}> {sharedWithMeDashboard ?
              'You can view only' :
              'You are the owner'}</span>
          </div>
        </li>
        {sharedWithMeDashboard && <div
          className={`${Styles.actions} ${Styles._squeezedAltered} ${Styles.liAccounts}`}
          style={{
            alignItems: 'center',
            display: 'flex',
          }}
          onClick={() => accountClick({
            active: true,
            dashboardSpaceId: modalDashboard.id,
            id: modalDashboard.sharedByUserId,
            name: modalDashboard.sharedByUserName,
            phone: modalDashboard.sharedByUserPhone,
            publicKey: modalDashboard.sharedByUserPublicKey,
            responded: true,
            source: 'iOSApp',
          })}
        >
          <div className={Styles.sharedCredDetails}>
            <svg
              width='100%'
              height='100%'
              style={{
                borderRadius: '50%',
                height: '32px',
                width: '32px',
                border: `1px solid ${getIconColorNewmethod(ownerName)}`,
              }}
            >
              <rect
                x='0'
                y='0'
                fill={getIconColorNewmethod(ownerName)}
                height='100%'
                width='100%'
              ></rect>
              <text
                dominantBaseline='middle'
                fill='#ffffff'
                fontSize='10'
                textAnchor='middle'
                x='48%'
                y='54%'
              >
                {ownerName.slice(0, 2).toUpperCase()}
              </text>
            </svg>
          </div>
          <div style={{ width: '230px' }}>
            <span
              style={{
                display: 'block',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              {ownerName}
            </span>
            <span
              style={{
                display: 'block',
                color: '#868692',
                fontSize: '14px',
              }}
            >
              {ownerPhone}
            </span>
            <span style={{ color: '#868692', fontSize: '14px' }}>
              They are the owner
            </span>
          </div>
          <div className={Styles.rightSide}>
            <img style={{ width: '24px', height: '24px', marginRight: '20px' }} src={chevronRight} />
          </div>
        </div>}
        <AccountSharedWithInBox
          sharedCredentialData={sharedCredentialData}
          accountClick={accountClick}
          onMouseEnterSharingStatus={onMouseEnterSharingStatus}
          onMouseLeaveSharingStatus={onMouseLeaveSharingStatus}
          sharingStatus={sharingStatus}
          disableSharing={disableSharing}
          handleSharingAccount={handleSharingAccount}
          externalDashboard={sharedWithMeDashboard}
          chevronRightStyle={{ marginRight: '42px' }}
        />
        </ul>
        </div>
        </div>
      </div>
      <div className={`${Styles.actions} ${Styles.actionsFixed} ${Styles._squeezedAltered} ${Styles.fixedButtons}`}>
        <Button
          className={`${Styles.button}`}
          onClick={onClose}
          value='Cancel' />
      </div>
    </div>
  </React.Fragment>
);

ModalDashboardShareContainer.defaultProps = {
  name: '',
  picture: '',
  userName: '',
};

ModalDashboardShareContainer.propTypes = {
  name: PropTypes.string,
  loginUrl: PropTypes.string,
  onCancel: PropTypes.func,
  onchnageInput: PropTypes.func,
  userWindow: PropTypes.func,
  picture: PropIcon(),
  userName: PropTypes.string,
  /** i18n translate method */
  t: PropTypes.func.isRequired,
};

export default ModalDashboardShareContainer;
