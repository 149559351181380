/*
 * Copyright (C) 2018-2019 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { backupMasterKey } from 'main/backup';
import { setCookieBoolean } from 'main/cookies';
import { CK_HIDE_BACKUP_PROMPT } from 'main/cookies/types';
import { hideModal, showModal } from 'main/modal';
import { displayTrackingPromptIfNeeded } from 'main/auth';
import withOverlayAction from 'Overlay/withAction';
import Container from './container';
import { MODAL_DOWNLOAD_BACKUP_KEY } from '../../../../constants/modal';

class ModalPromptBackupComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
    };

    this.handleClickDownload = this.handleClickDownload.bind(this);
    this.hide = this.hide.bind(this);
  }

  componentDidMount() {
    this.props.addOverlayAction('modal', this.hide);
  }

  async handleClickDownload() {
    const { t } = this.props;
    this.setState({ isLoading: true });

    try {
      await this.props.backupMasterKey(t);
      this.setState({ isLoading: false });
      this.props.hide();
    }
    catch (e) {
      this.setState({ isLoading: false });
      console.log(e);
    }
  }

  hide() {
    this.props.hide();
  }

  render() {
    return (
      <Container
        loading={this.state.isLoading}
        onCancel={this.hide}
        onClickDownload={this.handleClickDownload}
        t={this.props.t}
        onBack={() => this.props.showModal(MODAL_DOWNLOAD_BACKUP_KEY)}/>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  backupMasterKey: t => dispatch(backupMasterKey(t)),
  displayTrackingPromptIfNeeded: () => dispatch(displayTrackingPromptIfNeeded()),
  hide: () => dispatch(hideModal()),
  showModal: args => dispatch(showModal(args)),
});

export default translate()(connect(
  null,
  mapDispatchToProps,
)(withOverlayAction(ModalPromptBackupComponent)));
