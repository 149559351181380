/*
 * Copyright (C) 2018-2019 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { MODAL_ACCOUNT_EDIT } from 'constants/modal';
import React from 'react';
import { List } from 'immutable';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';

import { showModal } from 'main/modal';
import { hideModal } from 'main/modal';
import withOverlayAction from 'Overlay/withAction';
import Container from './container';
import { getApiErrorStatus } from '../../../../main/ui/reduxState';

class ModalAccountUnsavedDashboardComponent extends React.Component {
  constructor(props) {
    super(props);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.props.addOverlayAction('modal', this.hide, false);
  }

  handleCancel() {
    this.props.data.onCancel();
  }

  handleSubmit(event) {
    event.preventDefault();
    this.props.data.onConfirm(event);
  }

  render() {
    return (
      <Container
        name={this.props.data.currentApp.name}
        onCancel={this.handleCancel}
        onSubmit={this.handleSubmit}
        picture={this.props.data.currentApp.picture}
        domain={this.props.data.currentApp.domain}
        loginUrl={this.props.data.currentApp.loginUrl}
        fromDashboard={this.props.data.fromDashboard}
        toDashboard={this.props.data.toDashboard}
        t={this.props.t}
        />
    );
  }
}

const mapStateToProps = state => {
  const data = state.ui.modalData || {};
  return {
    data,
  };
};

const mapDispatchToProps = dispatch => ({
  hide: () => dispatch(hideModal()),
  getApiErrorStatus: () => dispatch(getApiErrorStatus()),
  showModal: (name, data) => dispatch(showModal(name, data)),
});

export default translate()(connect(
  mapStateToProps,
  mapDispatchToProps,
)(withOverlayAction(ModalAccountUnsavedDashboardComponent)));
