/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
export const RX_MODAL_ANIMATION_ENTER = 'RX_MODAL_ANIMATION_ENTER';
export const RX_MODAL_ANIMATION_LEAVE = 'RX_MODAL_ANIMATION_LEAVE';
export const RX_MODAL_DATA = 'RX_MODAL_DATA';
export const RX_MODAL_FOCUS_POP = 'RX_MODAL_FOCUS_POP';
export const RX_MODAL_FOCUS_PUSH = 'RX_MODAL_FOCUS_PUSH';
export const RX_MODAL_TEMPLATE = 'RX_MODAL_TEMPLATE';
export const RX_TOASTER_SHOW = 'RX_TOASTER_SHOW';
export const RX_DASHBOARD_ADD = 'RX_DASHBOARD_ADD';
export const RX_EDIT_ACCOUNT_PROCCESSING = 'RX_EDIT_ACCOUNT_PROCCESSING';
export const RX_DASHBOARDS_CREATE = 'RX_DASHBOARDS_CREATE';
export const RX_DASHBOARDS_CREATE_BULK = 'RX_DASHBOARDS_CREATE_BULK';
export const RX_DASHBOARD_ACCEPT = 'RX_DASHBOARD_ACCEPT';
export const RX_DASHBOARD_DECLINE = 'RX_DASHBOARD_DECLINE';
export const RX_DASHBOARD_UPDATE = 'RX_DASHBOARD_UPDATE';
export const RX_OVRELAYLOADING = 'RX_OVRELAYLOADING';
