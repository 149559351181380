/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import {
  RX_CREDENTIALS_CREATE,
  RX_CREDENTIALS_DELETE,
  RX_CREDENTIALS_UPDATE,
  RX_CREDENTIALS_REPLACE_ID,
  RX_CREDENTIALS_UPDATE_BATCH,
  RX_CREDENTIALS_UPDATE_FILTERED_LIST,
  RX_SET_NEW_CREDENTIALS,
  RX_SET_UPDATE_USERS_SHARED_WITH,
} from './actionTypes';

/**
 * @param {array} credentials
 */
export const RXCredentialsCreate = (credentials, override) => ({
  type: RX_CREDENTIALS_CREATE,
  credentials,
  override,
});

/**
 * @param {string} id
 */
export const RXCredentialsDelete = id => ({
  type: RX_CREDENTIALS_DELETE,
  id,
});

/**
 * @param {array} credentials
 */
export const RXCredentialsUpdate = credentials => ({
  type: RX_CREDENTIALS_UPDATE_BATCH,
  credentials,
});

/**
 * @param {string} id
 * @param {object} credential
 */
export const RXCredentialsUpdateOne = (id, credential) => ({
  type: RX_CREDENTIALS_UPDATE,
  credential,
  id,
});

/**
 * @param {string} id
 * @param {string} newId
 */
export const RXReplaceCredentialTemporaryId = (id, newId) => ({
  type: RX_CREDENTIALS_REPLACE_ID,
  newId,
  id,
});

export const RXCredentialsUpdateFilteredList = data => ({
  type: RX_CREDENTIALS_UPDATE_FILTERED_LIST,
  data,
});

export const RXSetNewCredentials = data => ({
  type: RX_SET_NEW_CREDENTIALS,
  data,
});

export const RXUpdateUsersShareWith = data => ({
  type: RX_SET_UPDATE_USERS_SHARED_WITH,
  data,
});
