/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from '@nettoken/components';
import Avatar from 'Avatar';
import { PropIcon } from 'utils/propTypes';
import Input from 'Input';
import Styles from 'Modal/style.css';
import Dropdown from 'Dropdown';
import sharedAccountIcon from 'icons/upwardarrow.svg';
import { getIconColorNewmethod } from '../../../../utils/misc';
import cross from '../../../../assets/images/cross.svg';
import leftsvg from '../../../../assets/images/left.svg';
import CancelIcon from '../../../ActionIcons/CancelIcon';
import LeftArrowIcon from '../../../ActionIcons/LeftArrowIcon';
import SharingBtn from '../../../ActionIcons/SharingBtn';
import AccountSharedWithInBox from '../../../AccountSharedWithInBox';
import UsersSharedWith from '../../../UsersSharedWith';

const ModalAccountShareContainer = ({
  name,
  email,
  userName,
  displayName,
  picture,
  onCancel,
  sharedCredentialData,
  onchangeInput,
  searchuser,
  userWindow,
  loginUrl,
  domain,
  t,
  onMouseEnterSharingStatus,
  onMouseLeaveSharingStatus,
  sharingStatus,
  disableSharing,
  handleSharingAccount,
  credentialData,
  onClose,
  accountClick,
  usersSharedWith,
}) => (
  <React.Fragment>
    <h1
      aria-label={t('modal.accountShare.title')}
      className={Styles.title1}>
      <LeftArrowIcon onClickBackHandler={onCancel} />
      Share {name.length > 20 ? `${name.substr(0, 20)}…` : name}
      <CancelIcon onCancel={onClose} />
    </h1>
    <div className={`${Styles.whiteBackground2} ${Styles.minHeightBackground}`}>
      <div className={`${Styles.fixedForm} ${Styles.fixedForm2}`}>
        <div className={classNames({
          [Styles.iconHeaderWrapper]: true,
          [Styles.noBg]: true,
          [Styles.nameIconHoverEffect]: true,
        })}>
          <Avatar
            className={Styles.iconHeaderWrapper}
            domain={domain}
            loginUrl={loginUrl}
            name={name}
            picture={picture}
          />
          {(sharedCredentialData && sharedCredentialData.length > 0) && (
            <img
              src={sharedAccountIcon}
              alt=''
              className={Styles.sharedIcon}
            />)
          }
        </div>
        <div style={{ textAlign: 'center' }}>
          {displayName}
        </div>
        <div className={Styles.form}>
          <span style={{
            color: '#868692',
            fontSize: '14px',
            padding: '16px 12px 16px',
          }}>SELECT CONTACT</span>
          <Input className={Styles.shareinputbox} onChange={onchangeInput} type="text"
                placeholder="Type contact name or number"/>
          {
            searchuser.map(e => {
              let item = e.name;
              let isErrorMessage = false;
              if (e.errorMessage) {
                isErrorMessage = true;
                if (e.type === 'not_found') {
                  item = 'No nettoken contact found. Invite your friends to sign up first before sharing!';
                }
                else if (e.type === 'already_shared') {
                  item = `You are already sharing this account with ${e.name}`;
                }
                else if (e.type === 'not_found_with_name') {
                  item = 'You can only search existing contacts by name. Type a phone number to share with new contacts.';
                }
                else if (e.type === 'myself') {
                  item = 'You cannot share with yourself.';
                }
              }
              return (
                <Dropdown
                  items={[item]}
                  fullWidthShare={true}
                  showCustomEntry={true}
                  isErrorMessage={isErrorMessage}
                  fromShare={true}
                  onClickItem={_ => userWindow(e)}
                  // eslint-disable-next-line no-confusing-arrow
                  itemBuilder={i => {
                    if (isErrorMessage) {
                      return i;
                    }
                    return (
                      <div
                        className={Styles.userSearchDropdownItem}
                        style={{
                          alignItems: 'center',
                        }}
                      >
                        <span
                          className={Styles.initials}
                          style={{ backgroundColor: getIconColorNewmethod(e.name) }}
                        >
                          {e.name.slice(0, 2).toUpperCase()}
                        </span>
                        <div className={Styles.userInfo}>
                          <div className={Styles.name}>{e.name}</div>
                          <div className={Styles.phone}>{e.phone}</div>
                        </div>
                      </div>
                    );
                  }}
                  t={t}
                />
              );
            })
          }
        </div>
        <div style= {{ padding: '14px 21px' }}>
          {(usersSharedWith.length > 0) && (
            <UsersSharedWith
              usersSharedWith={usersSharedWith}
              customStyles={{
                display: 'flex',
                padding: '12px 10px',
              }}
            />
          )}
          <AccountSharedWithInBox
            sharedCredentialData={sharedCredentialData}
            accountClick={accountClick}
            onMouseEnterSharingStatus={onMouseEnterSharingStatus}
            onMouseLeaveSharingStatus={onMouseLeaveSharingStatus}
            sharingStatus={sharingStatus}
            disableSharing={disableSharing}
            handleSharingAccount={handleSharingAccount}
            credentialData={credentialData}
            chevronRightStyle={{ marginRight: '48px' }}
          />
        </div>
      </div>
      <div className={`${Styles.actions} ${Styles.actionsFixed} ${Styles._squeezedAltered} ${Styles.fixedButtons}`}>
        <Button
          className={`${Styles.button}`}
          onClick={onClose}
          value='Cancel' />
      </div>
    </div>
  </React.Fragment>
);

ModalAccountShareContainer.defaultProps = {
  name: '',
  picture: '',
};

ModalAccountShareContainer.propTypes = {
  name: PropTypes.string,
  loginUrl: PropTypes.string,
  onCancel: PropTypes.func,
  onchangeInput: PropTypes.func,
  userWindow: PropTypes.func,
  picture: PropIcon(),
  /** i18n translate method */
  t: PropTypes.func.isRequired,
};

export default ModalAccountShareContainer;
