/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
export const RX_DEVICE_DESKTOP = 'RX_DEVICE_DESKTOP';
export const RX_DEVICE_BROWSER = 'RX_DEVICE_BROWSER';
export const RX_DEVICE_HOTKEYS_ADD = 'RX_DEVICE_HOTKEYS_ADD';
export const RX_DEVICE_HOTKEYS_REMOVE = 'RX_DEVICE_HOTKEYS_REMOVE';
export const RX_DEVICE_MOBILE = 'RX_DEVICE_MOBILE';
export const RX_DEVICE_IPAD = 'RX_DEVICE_IPAD';
export const RX_DEVICE_PHONE = 'RX_DEVICE_PHONE';
export const RX_DEVICE_TABLET = 'RX_DEVICE_TABLET';
