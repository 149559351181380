/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@nettoken/components';
import { i18nRenderLines } from '@nettoken/helpers';
import Helmet from 'Helmet';
import Input from 'Input';
import SupportedImportPlatformsList from 'Modal/templates/account-add-file/platforms';
import Styles from 'Sidebar/style.css';

const SidebarImportContainer = ({
  error,
  filename,
  isSubmitting,
  onCancel,
  onChange,
  onSubmit,
  t,
}) => (
  <form
    action="/"
    className={Styles.sidebarFullHeight}
    method="post"
    onSubmit={onSubmit}>
    <Helmet title={t('views.import.title')} />

    <div className={`${Styles.content} ${Styles._menu} ${Styles._scrollable}`}>
      <div className={Styles.paddingFixer}>
        <h1 className={Styles.title}>
          {t('views.import.title')}
        </h1>

        <ul>
          <li className={`${Styles.dashedSection} ${Styles.exportSectionItem}`}>
            <p className={Styles.radioTextInExport}>
              {i18nRenderLines(t('views.import.text'))}
            </p>

            <SupportedImportPlatformsList noMargin t={t} />

            {error && <p className={`${Styles.radioTextInExport} ${Styles._danger}`}>
              {error}
            </p>}

            <Input
              label={t('views.import.inputLabel')}
              name="file"
              onChange={onChange}
              placeholder={t('views.import.inputFile')}
              t={t}
              type="file"
              value={filename} />
          </li>
        </ul>
      </div>
    </div>

    <div className={Styles.actions}>
      <Button
        className={Styles.button}
        onClick={onCancel}
        type="button"
        value={t('global.back')} />

      <Button
        className={Styles.button}
        disabled={!filename}
        loaderSize={20}
        loading={isSubmitting}
        type="submit"
        value={t('global.submit')} />
    </div>
  </form>
);

SidebarImportContainer.defaultProps = {
  filename: '',
};

SidebarImportContainer.propTypes = {
  error: PropTypes.string,
  filename: PropTypes.string,
  isSubmitting: PropTypes.bool,
  onCancel: PropTypes.func,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  /** i18n translate method */
  t: PropTypes.func.isRequired,
};

export default SidebarImportContainer;
