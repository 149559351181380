/*
 * Copyright (C) 2018-2019 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { MODAL_ACCOUNT_DELETE_CONFIRM, MODAL_ACCOUNT_EDIT } from 'constants/modal';
import React from 'react';
import { List } from 'immutable';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { showModal } from 'main/modal';
import { removeSharedByAccounts } from 'main/sharedAccounts';
import { deleteCredentials } from 'main/vault/credentials';
import withOverlayAction from 'Overlay/withAction';
import Container from './container';
import { RxEditAccountProccessing } from '../../../../main/modal/reduxActions';

class ModalAccountDeleteComponent extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.hide = this.hide.bind(this);
  }

  componentDidMount() {
    this.props.addOverlayAction('modal', this.hide, false);
  }

  handleSubmit(event) {
    event.preventDefault();
    const ids = new List([this.props.data.id]);
    this.props.setEditAccountProccessing(true);
    if (this.props.data.externalAccount && this.props.data.shared) {
      this.removeExternalAccount().then(() => {
        this.props.setEditAccountProccessing(false);
        this.props.showModal(MODAL_ACCOUNT_DELETE_CONFIRM, this.props.data);
      }).catch(e => {
        this.props.setEditAccountProccessing(false);
        this.props.showModal(MODAL_ACCOUNT_EDIT, this.props.data);
        console.log(e);
      });
    }
    else {
      this.props.deleteCredentials({ ids }).then(() => {
        this.props.setEditAccountProccessing(false);
        this.props.showModal(MODAL_ACCOUNT_DELETE_CONFIRM, this.props.data);
      }).catch(e => {
        this.props.setEditAccountProccessing(false);
        this.props.showModal(MODAL_ACCOUNT_EDIT, this.props.data);
        console.log(e);
      });
    }
  }

  hide() {
    this.props.showModal(MODAL_ACCOUNT_EDIT, this.props.data);
  }

  async removeExternalAccount() {
    const params = {
      data: this.props.data,
      unshareWithUserIds: [],
    };

    try {
      const res = await this.props.removeSharedByAccounts(params);
    }
    catch (error) {
      console.log(error, 'removeExternalAccount');
    }
  }

  render() {
    return (
      <Container
        name={this.props.data.name}
        onCancel={this.hide}
        onSubmit={this.handleSubmit}
        picture={this.props.data.picture}
        t={this.props.t}
        domain={this.props.data.domain}
        loginUrl={this.props.data.loginUrl}
        editAccountProccessing={this.props.editAccountProccessing}
        />
    );
  }
}

const mapStateToProps = state => {
  const data = state.ui.modalData || {};
  return {
    data,
    editAccountProccessing: state.ui.editAccountProccessing,
  };
};

const mapDispatchToProps = dispatch => ({
  deleteCredentials: args => dispatch(deleteCredentials(args)),
  showModal: (name, data) => dispatch(showModal(name, data)),
  setEditAccountProccessing: status => dispatch(RxEditAccountProccessing(status)),
  removeSharedByAccounts: params => dispatch(removeSharedByAccounts(params)),
});

export default translate()(connect(
  mapStateToProps,
  mapDispatchToProps,
)(withOverlayAction(ModalAccountDeleteComponent)));
