/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from '@nettoken/components';
import { noop, reloadBrowser } from '@nettoken/helpers';
import Styles from 'Modal/style.css';
import Tooltip from 'Tooltip';
import LeftArrowIcon from '../../../ActionIcons/LeftArrowIcon';
import QuestionMarkIcon from '../../../ActionIcons/QuestionMarkIcon';
import CancelIcon from '../../../ActionIcons/CancelIcon';
import EnvelopeIcon from '../../../ActionIcons/Envelope';
import { getIconColorNewmethod } from '../../../../utils/misc';
import SharingBtn from '../../../ActionIcons/SharingBtn';
import Avatar from '../../../Avatar';
import {
  ID_CONTACT_SHARED_BY,
  ID_CONTACT_SHARED_WITH,
  TEXT_CONTACT_SHARED_BY,
  TEXT_CONTACT_SHARED_WITH,
} from '../../../../constants/ids';
import yellowicon from '../../../../assets/images/yellow.png';
import info1 from '../../../../assets/images/info11.png';
import chevronRight from '../../../../assets/images/chevronRight.png';

const OfflineMsg = ({
  loading,
  onCancel,
  onBack,
  data,
  onClickHandler,
  onMouseEnterSharingStatus,
  onMouseLeaveSharingStatus,
  sharingStatus,
  deleteProcessing,
  disableSharing,
  handleSharingAccount,
  openCredential,
  openCredentialSharedBy,
  t,
  dashboards,
  onDashboardClick,
}) => (
  <React.Fragment>
    <h1 aria-label='Contact' className={Styles.title1}>
      <LeftArrowIcon onClickBackHandler={onBack} />
      Contact
      <CancelIcon onCancel={onCancel} />
    </h1>
    <div className={`${Styles.whiteBackground2} ${Styles.minHeightBackground}`} style={{ overflowY: 'auto' }}>
      <div className={`${Styles.fixedForm2} ${Styles.fixedForm}`}>
          <div className={classNames({
            [Styles.iconHeaderWrapper]: true,
            [Styles.noBg]: true,
          })}>
            <svg width='100%' height='100%' style={{ borderRadius: '50%' }}>
              <rect x='0' y='0' fill={getIconColorNewmethod(data.account.name)} height='100%' width='100%'></rect>
              <text
                dominantBaseline='middle'
                fill='#ffffff'
                fontSize='10'
                textAnchor='middle'
                x='48%'
                y='54%'
              >{data.account.name.slice(0, 2).toUpperCase()}</text>
            </svg>
          </div>
          <div style={{
            display: 'grid',
            justifyContent: 'center',
            textAlign: 'center',
          }}>
            <span>{data.account.name}</span>
            <span>{data.account.phone}</span>
          </div>
          {Object.keys(dashboards).length > 0 ? (
            <>
              <div className={Styles.sharedRow}>
                <h2 style={{ justifyContent: 'space-between' }}><span style={{ marginLeft: '0px', fontSize: '14px' }}>SHARED DASHBOARDS</span>
                <QuestionMarkIcon content='When you share an account with a contact they can view your username, email, and password and use it to log in to your account. Your contact cannot edit the information and can only view it.' position='relative' rightPosition='-13px' placement='right'/>
                </h2>
              </div>
              <div style= {{ padding: '14px 21px' }}>
              <ul
                className={Styles.tabList}
                style={{
                  width: '100%',
                  padding: '0px',
                  display: 'grid',
                }}
              >
                {Object.keys(dashboards).map((dashboard, index) => {
                  const color = getIconColorNewmethod(dashboards[dashboard].name);
                  return (
                    <li
                      className={Styles.liAccounts}
                      style={{
                        gridTemplateColumns: '0.1fr 1fr 0.3fr',
                      }}
                      onClick={() => onDashboardClick(dashboards[dashboard])}
                    >
                      <div
                        style={{
                          borderRadius: '50%',
                          height: '50px',
                          width: '50px',
                          textAlign: 'center',
                          verticalAlign: 'middle',
                          lineHeight: '49px',
                          backgroundColor: color,
                          position: 'relative',
                        }}
                      >
                        {'invitationId' in dashboards[dashboard] && dashboards[dashboard].invitationId != '' ? (
                          <div style={{ position: 'relative' }}>
                            <img
                              src={yellowicon}
                              style={{
                                position: 'absolute',
                                top: '-8px',
                                right: '-7px',
                                height: '28.5px',
                                width: '28.5px',
                              }}/>
                          </div>
                        ) : null}
                        {'externalDashboard' in dashboards[dashboard] && dashboards[dashboard].externalDashboard ? (
                          <div style={{ position: 'relative' }}>
                            <img
                              src={info1}
                              style={{
                                position: 'absolute',
                                top: '-8px',
                                right: '-7px',
                                height: '28.5px',
                                width: '28.5px',
                              }}/>
                          </div>
                        ) : null}
                        {'shared' in dashboards[dashboard] && !('externalDashboard' in dashboards[dashboard]) ? (
                          <span className={Styles.sharedMe} style={{
                            position: 'absolute',
                            top: '-18px',
                            right: '-8px',
                          }}>
                            <svg width="28.5" height="28.5" viewBox="0 0 31 31" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M16 26C9.92487 26 5 21.0751 5 15C5 8.92487 9.92487 4 16 4C22.0751 4 27 8.92487 27 15C27 21.0751 22.0751 26 16 26Z"
                                  fill="white"/>
                                <circle cx="16" cy="15" r="10.75" stroke="#28283F"/>
                                <path d="M16.0902 9.37494L16.4743 9.05485C16.3805 8.94229 16.2421 8.87654 16.0956 8.87497C15.9491 8.8734 15.8092 8.93616
                                  15.713 9.04668L16.0902 9.37494ZM15.5902 20.6249C15.5902 20.9011 15.8141 21.1249 16.0902 21.1249C16.3663 21.1249 16.5902
                                  20.9011 16.5902 20.6249L15.5902 20.6249ZM11.5166 13.8681C11.3353 14.0764 11.3572 14.3922 11.5655 14.5735C11.7738 14.7548
                                  12.0896 14.7329 12.2709 14.5246L11.5166 13.8681ZM19.7239 14.5165C19.9007 14.7286 20.216 14.7573 20.4281 14.5805C20.6403
                                  14.4037 20.6689 14.0884 20.4922 13.8763L19.7239 14.5165ZM15.5902 9.37494L15.5902 20.6249L16.5902 20.6249L16.5902 9.37494L15.5902
                                  9.37494ZM12.2709 14.5246L16.4673 9.7032L15.713 9.04668L11.5166 13.8681L12.2709 14.5246ZM20.4922 13.8763L16.4743 9.05485L15.7061
                                  9.69503L19.7239 14.5165L20.4922 13.8763Z"
                                  fill="#28283F"/>
                            </svg>
                          </span>
                        ) : null}
                        <span style={{ color: 'white' }}>{dashboards[dashboard].name.slice(0, 2).toUpperCase()}</span>
                      </div>
                    <div
                      style={{ marginLeft: '22px' }}
                    >
                      <span>{dashboards[dashboard].name.length > 16 ? dashboards[dashboard].name.substring(0, 16).concat('...') : dashboards[dashboard].name}</span>
                      <br />
                      <span style={
                        {
                          color: '#868692', fontSize: '14px',
                        }
                      }>{'invitationId' in dashboards[dashboard] ? 'Pending invitation' : `${dashboards[dashboard].count} accounts`}</span>
                      <br />
                      <span style={
                        {
                          color: '#868692', fontSize: '14px',
                        }
                      }>{'externalDashboard' in dashboards[dashboard] && dashboards[dashboard].externalDashboard && dashboards[dashboard].sharedByUserName === data.account.name ? 'They are the owner' : ''}</span>
                      <span style={
                        {
                          color: '#868692', fontSize: '14px',
                        }
                      }>{'externalDashboard' in dashboards[dashboard] && dashboards[dashboard].externalDashboard && dashboards[dashboard].sharedByUserName !== data.account.name ? 'They can view only' : ''}</span>
                      <span style={
                        {
                          color: '#868692', fontSize: '14px',
                        }
                      }>{'shared' in dashboards[dashboard] && !('externalDashboard' in dashboards[dashboard]) ? 'They can view only' : ''}</span>
                    </div>
                    <SharingBtn
                      account={{
                        id: data.account.id,
                        phone: data.account.phone,
                        name: data.account.name,
                        responded: dashboards[dashboard].responded,
                      }}
                      onMouseEnterSharingStatus={onMouseEnterSharingStatus}
                      onMouseLeaveSharingStatus={onMouseLeaveSharingStatus}
                      sharingStatus={sharingStatus}
                      disableSharing={disableSharing}
                      handleSharingAccount={(...props) => handleSharingAccount(...props, 'dashboard')}
                      credentialData={dashboards[dashboard]}
                      inlineStyleBtn={{ float: 'right' }}
                      spcl_id={ dashboards[dashboard].id }
                      fromContactPopup={true}
                      externalDashboard={
                        ('externalDashboard' in dashboards[dashboard] && dashboards[dashboard].externalDashboard) ||
                        ('invitationId' in dashboards[dashboard] && dashboards[dashboard].invitationId != '')
                      }
                      chevronRightStyle={{ marginRight: '-4px' }}
                    />
                    </li>
                  );
                })}
              </ul>
              </div>
            </>
          ) : null}
          {(data.userAccountDetails.length > 0) ? (
            <>
              <div className={Styles.sharedRow} style={{ marginTop: '10px' }}>
                <h2 style={{ justifyContent: 'space-between' }}><span style={{ marginLeft: '0px', fontSize: '14px' }}>ACCOUNTS SHARED WITH {data.account.name.length > 18 ? data.account.name.toUpperCase().substring(0, 18).concat('...') : data.account.name.toUpperCase()}</span>
                <QuestionMarkIcon content='When you share an account with a contact they can view your username, email, and password and use it to log in to your account. Your contact cannot edit the information and can only view it.' position='relative' rightPosition='-13px' placement='right'/>
                </h2>
              </div>
              <div style= {{ padding: '14px 21px' }}>
              <ul className={Styles.tabList} style={{
                width: '100%',
                padding: '0px',
              }} >
                {data.userAccountDetails.map((credential, index) => <li
                className={Styles.liAccounts}
                onClick={() => openCredential(credential)}
                key={index} style={{ gridTemplateColumns: '0.1fr 1fr 0.3fr' }}>
                <span>
                  <Avatar
                    className={Styles.sharedImageStyle}
                    domain={credential.domain}
                    loginUrl={''}
                    name={credential.name}
                    picture={credential.picture}
                  />
                </span>
                <div style={{ marginLeft: '26px', width: '195px' }}>
                  <span>{credential.name.length > 16 ? credential.name.substring(0, 16).concat('...') : credential.name}</span><br />
                  <span style={{
                    display: 'block',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    color: '#868692',
                    fontSize: '14px',
                  }}>{credential.email ? credential.email : credential.username }</span>
                  <span style={
                    {
                      color: '#868692', fontSize: '14px',
                    }
                }>{!data.sharedAccountDetail[credential.id].responded ? 'Pending invitation' : 'They can view only'}</span>
                </div>
                <SharingBtn
                  account={{
                    id: data.sharedAccountDetail[credential.id].id,
                    phone: data.sharedAccountDetail[credential.id].phone,
                    name: data.sharedAccountDetail[credential.id].name,
                    responded: data.sharedAccountDetail[credential.id].responded,
                  }}
                  onMouseEnterSharingStatus={onMouseEnterSharingStatus}
                  onMouseLeaveSharingStatus={onMouseLeaveSharingStatus}
                  sharingStatus={sharingStatus}
                  disableSharing={disableSharing}
                  handleSharingAccount={(...props) => handleSharingAccount(...props, 'credential')}
                  credentialData={credential}
                  inlineStyleBtn={{ float: 'right' }}
                  spcl_id={ credential.id }
                  fromContactPopup={true}
                  externalDashboard={false}
                  chevronRightStyle={{ marginRight: '-4px' }}
                />
                </li>)}
              </ul>
              </div>
            </>
          ) : null }
          {(data.sharedByAccounts.length > 0) ? (
            <>
              <div className={Styles.sharedRow} style={{ marginTop: '10px' }}>
                <h2 style={{ justifyContent: 'space-between' }}><span style={{ marginLeft: '0px', fontSize: '14px' }}>ACCOUNTS SHARED BY {data.account.name.length > 18 ? data.account.name.toUpperCase().substring(0, 18).concat('...') : data.account.name.toUpperCase()}</span>
                <QuestionMarkIcon content='When an account is shared by a contact you can view the username, email, and password and use it to log in to the account. You can only view this information and cannot edit it.' position='relative' rightPosition='-13px' placement='right'/>
                </h2>
              </div>
              <div style= {{ padding: '14px 21px' }}>
              <ul className={Styles.tabList} style={{
                width: '100%',
                padding: '0px',
              }} >
              {data.sharedByAccounts.map((credential, index) => <li
                className={Styles.liAccounts}
                key={index} style={{ gridTemplateColumns: '0.1fr 1fr 0.3fr' }}
                onClick={() => openCredentialSharedBy(credential)}>
                <span>
                  <Avatar
                    className={Styles.sharedImageStyle}
                    domain={credential.domain}
                    loginUrl={''}
                    name={credential.name}
                    picture={credential.picture}
                  />
                </span>
                <div style={{ marginLeft: '26px', width: '195px' }}>
                  <span>{credential.name.length > 16 ? credential.name.substring(0, 16).concat('...') : credential.name}</span><br />
                  <span style={{
                    display: 'block',
                    width: '50%',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    color: '#868692',
                    fontSize: '14px',
                  }}>{credential.email ? credential.email : credential.username }</span>
                  <span style={{
                    color: '#868692',
                    fontSize: '14px',
                  }}>{!credential.accepted ? 'Pending invitation' : 'They are the owner'}</span>
                </div>
                <SharingBtn
                  account={{
                    id: credential.sharedId,
                    phone: credential.phone,
                    name: credential.name,
                    responded: credential.accepted,
                  }}
                  onMouseEnterSharingStatus={onMouseEnterSharingStatus}
                  onMouseLeaveSharingStatus={onMouseLeaveSharingStatus}
                  sharingStatus={sharingStatus}
                  disableSharing={disableSharing}
                  handleSharingAccount={(...props) => handleSharingAccount(...props, 'credential')}
                  credentialData={credential}
                  inlineStyleBtn={{ float: 'right' }}
                  spcl_id={ credential.id }
                  fromContactPopup={true}
                  sharedByAccounts={true}
                  chevronRightStyle={{ marginRight: '-4px' }}
                />
              </li>)}
              </ul>
              </div>
            </>
          ) : null}
      </div>
      <div className={`${Styles.actions} ${Styles.actionsFixed} ${Styles._squeezedAltered} ${Styles.fixedButtons}`}>
        <Button
          className={`${Styles.button}`}
          onClick={onCancel}
          value='Cancel' />
      </div>
    </div>
  </React.Fragment>
);

OfflineMsg.propTypes = {
  onCancel: PropTypes.func,
  /** i18n translate method */
  t: PropTypes.func.isRequired,
};

export default OfflineMsg;
