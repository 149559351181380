/*
 * Copyright (C) 2019 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { isStorybookOrTest, isE2ETest } from '@nettoken/env';
import defaultIcon from 'icons/default-icon.svg';
import { sha256 } from 'js-sha256';
import {
  CW_DECRYPT_USING_MASTER_KEYPAIR,
  CW_EXTRACT_MASTER_KEYS,
  CW_GENERATE_EPHEMERAL_KEY_PAIR,
  getWorkerPromise,
} from '@nettoken/crypto-worker';
// import KeyPair from '@nettoken/crypto-worker/src/keypair';

const stringSliceOrAppend = (str, len, item = 'a') => {
  if (str.length > len) {
    return str.slice(str.length - len);
  }
  if (str.length < len) {
    return str.padEnd(len, item);
  }
  return str;
};

const rgbToHex = ({ r, g, b }) => {
  const alpha = '80';
  r = r.toString(16);
  g = g.toString(16);
  b = b.toString(16);

  if (r.length == 1) {
    r = `0${r}`;
  }
  if (g.length == 1) {
    g = `0${g}`;
  }
  if (b.length == 1) {
    b = `0${b}`;
  }

  return `#${r}${g}${b}${alpha}`;
};


/**
 * We use this method to mock modules in Jest tests.
 * However, we do not want to mock anything in e2e tests.
 *
 * @returns {boolean}
 */
export const isTest = () => !isE2ETest && isStorybookOrTest;

/**
 * Meant to be used as `onError={setDefaultIcon}` to handle cases where
 * source attribute returns error 404 for example, so we display the default
 * icon instead.
 *
 * @param {object} event
 */
export const setDefaultIcon = event => { // eslint-disable-line import/prefer-default-export
  event.target.onerror = null;
  event.target.src = defaultIcon;
};

export const getIconColor = domain => {
  const min = 97;
  const max = 122;
  // remove domain
  const name = domain.split('.')[0];
  // remove numbers
  let cleanName = name.replace(/\d+/g, '');
  cleanName = cleanName.replaceAll(/\s/g, '');
  // remove special characters apart from the one in whitelist
  const doublecleanname = cleanName.replace(/[^\w\s]/gi, '');
  const exactString = stringSliceOrAppend(doublecleanname, 3);
  const parseNum = char => {
    const asciiValue = char.charCodeAt();
    return parseInt(((asciiValue - min) / (max - min)) * 255, 10);
  };
  const rgb = {
    r: parseNum(exactString[0]),
    g: parseNum(exactString[1]),
    b: parseNum(exactString[2]),
  };
  // const values = [...exactString].map(char => {
  //   const asciiValue = char.charCodeAt();
  //   return ((asciiValue - min) / (max - min)) * 255;
  // });

  const color = rgbToHex(rgb);
  return color;
};

/**
 * @return {string}
 */
export const detectBrowser = async () => {
  const userAgentString = navigator.userAgent;
  try {
    const isBrave = (navigator.brave && await navigator.brave.isBrave() || false);
    if (isBrave) {
      return 'BRAVE';
    }
  }
  catch (e) {
    return null;
  }

  if (userAgentString.indexOf('Edg') > -1) {
    return 'Edge';
  }

  if (/Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)) {
    return 'CHROME';
  }

  return null;

  // if (userAgentString.indexOf('Edg') > -1) {
  //   return 'Edge';
  // }

  // if (userAgentString.indexOf('Firefox') > -1) {
  //   return 'Firefox';
  // }

  // if (userAgentString.indexOf('Chrome') > -1) {
  //   return 'Chrome';
  // }

  // if (userAgentString.indexOf('Safari') > -1) {
  //   return 'Safari';
  // }

  // if (userAgentString.indexOf('MSIE') > -1 || userAgentString.indexOf('rv:') > -1) {
  //   return 'Internet Explorer';
  // }

  // if (userAgentString.indexOf('Opera') > -1) {
  //   return 'Opera';
  // }

  // return 'unknown';
};

const { createHash } = require('crypto');

const hash = domain => {
  console.log('');
  return createHash('sha256').update(domain).digest('hex');
};

const normalizeTwoBitHex = data => {
  const rMin = 0;
  const rMax = 255;
  const targetRangeMin = 89;
  const targetRangeMax = 230;
  const n = (((data - rMin) / (rMax - rMin)) * (targetRangeMax - targetRangeMin) + targetRangeMin);
  return Math.ceil(n);
};

const rgbToHexNewMethod = ({ r, g, b }) => {
  r = r.toString(16);
  g = g.toString(16);
  b = b.toString(16);
  return `#${r}${g}${b}`;
};

export const getIconColorNewmethod = domain => {
  let color;
  if (domain) {
    const hashedData = hash(domain);

    const hashSubstring = hashedData.substring(0, 16);

    const bigI = BigInt('0x'.concat(hashSubstring)); /* global BigInt */

    const biColorCode = bigI % BigInt('0x'.concat(1000000));

    const nColorCode = Number(biColorCode);

    const red = (nColorCode >> 16); // eslint-disable-line no-bitwise
    const green = ((nColorCode >> 8) & 0xff); // eslint-disable-line no-bitwise
    const blue = (nColorCode & 0xff); // eslint-disable-line no-bitwise

    const rgb = {
      r: normalizeTwoBitHex(red),
      g: normalizeTwoBitHex(green),
      b: normalizeTwoBitHex(blue),
      alpha: 1,
    };

    color = rgbToHexNewMethod(rgb);
  }
  else {
    const rgb = {
      r: 0.5,
      g: 0.5,
      b: 1,
      alpha: 1,
    };
    color = rgbToHexNewMethod(rgb);
  }
  return color;
};


export const getOrCreateKeyPairForSharing = async (credential, KeyPair) => {
  const keypair = new KeyPair();
  const {
    publicKey,
    secretKey,
  } = credential;
  const worker = await getWorkerPromise('crypto');
  if (publicKey && secretKey) {
    const [pKey, sKey] = await Promise.all([
      worker({
        event: CW_DECRYPT_USING_MASTER_KEYPAIR,
        message: publicKey,
      }),
      worker({
        event: CW_DECRYPT_USING_MASTER_KEYPAIR,
        message: secretKey,
      }),
    ])
      .then(values => values.map(v => Object.values(v)
        .join('')
        .replace('CW_DECRYPT_USING_MASTER_KEYPAIR', '')));

    keypair.set(pKey, sKey);
  }
  else {
    const newKeyPair = await worker({
      event: CW_GENERATE_EPHEMERAL_KEY_PAIR,
      format: 'base64',
    });
    keypair.set(newKeyPair.publicKey, newKeyPair.secretKey);
  }
  return keypair;
};

export const getOrCreateKeyPairForSharingDashboard = async (dashboard, KeyPair) => {
  const keypair = new KeyPair();
  const {
    publicKey,
    secretKey,
  } = dashboard;
  const worker = await getWorkerPromise('crypto');
  if (dashboard.shared === true && publicKey && secretKey) {
    const [pKey, sKey] = await Promise.all([
      worker({
        event: CW_DECRYPT_USING_MASTER_KEYPAIR,
        message: publicKey,
      }),
      worker({
        event: CW_DECRYPT_USING_MASTER_KEYPAIR,
        message: secretKey,
      }),
    ])
      .then(values => values.map(v => Object.values(v)
        .join('')
        .replace('CW_DECRYPT_USING_MASTER_KEYPAIR', '')));

    keypair.set(pKey, sKey);
  }
  else {
    // const newKeyPair = await worker({
    //   event: CW_GENERATE_EPHEMERAL_KEY_PAIR,
    //   format: 'base64',
    // });
    // // keypair.set(newKeyPair.publicKey, newKeyPair.secretKey);

    const { newKeyPairPublicKey, newKeyPairSecretKey } =
    await worker({ event: CW_GENERATE_EPHEMERAL_KEY_PAIR, format: 'base64' });
    // Save the data on server.
    const event = CW_EXTRACT_MASTER_KEYS;
    const { encryptedPrivateKey } =
    await worker({ event, newKeyPairPublicKey, newKeyPairSecretKey });
    keypair.set(newKeyPairPublicKey, newKeyPairSecretKey);
  }
  return keypair;
};


export const getOrCreateKeyPairForSharingMultiConditions = async (credential, KeyPair) => {
  const keypair = new KeyPair();
  const {
    publicKey,
    secretKey,
  } = credential;
  const worker = await getWorkerPromise('crypto');
  if (
    credential.shared === true &&
    publicKey &&
    secretKey &&
    publicKey != null &&
    secretKey != null
  ) {
    const [pKey, sKey] = await Promise.all([
      worker({
        event: CW_DECRYPT_USING_MASTER_KEYPAIR,
        message: publicKey,
      }),
      worker({
        event: CW_DECRYPT_USING_MASTER_KEYPAIR,
        message: secretKey,
      }),
    ])
      .then(values => values.map(v => Object.values(v)
        .join('')
        .replace('CW_DECRYPT_USING_MASTER_KEYPAIR', '')));

    keypair.set(pKey, sKey);
  }
  else {
    const newKeyPair = await worker({
      event: CW_GENERATE_EPHEMERAL_KEY_PAIR,
      format: 'base64',
    });
    keypair.set(newKeyPair.publicKey, newKeyPair.secretKey);
  }
  return keypair;
};
