import { URL_TRACKING } from 'constants/endpoints';
import {
  EVENT_CREDENTIAL_CREATED,
  EVENT_EMAIL_UPDATED,
  EVENT_PASSWORD_UPDATED,
  EVENT_USERNAME_UPDATED,
  EVENT_GROUP_CHANGED,
  EVENT_DASHBOARD_CHANGED,
  SOURCE_WEB_APP,
} from 'constants/events';
import { Request } from 'utils/request';
import { getTrackingStatus, getsharedCRedstatus } from './reduxState';

const getUpdateEvents = (prev, next) => {
  const eventMap = {
    email: EVENT_EMAIL_UPDATED,
    password: EVENT_PASSWORD_UPDATED,
    username: EVENT_USERNAME_UPDATED,
    groupId: EVENT_GROUP_CHANGED,
  };
  const events = [];
  Object.keys(eventMap).forEach(key => {
    if (prev[key].toLowerCase() !== next[key].toLowerCase()) {
      if (eventMap[key] === EVENT_GROUP_CHANGED) {
        events.push({ eventName: eventMap[key], fromId: prev[key], toId: next[key] });
        return;
      }
      events.push({ eventName: eventMap[key] });
    }
  });

  return events;
};

export const trackUpdateEvents = args => dispatch => {
  const {
    id,
    prevCredential,
    nextCredential,
    eventSource,
  } = args;
  const events = getUpdateEvents(prevCredential, nextCredential);
  events.forEach(event => postActivityLogs(event, eventSource, [id]));
};

export const trackAddEvents = args => dispatch => {
  const event = { eventName: EVENT_CREDENTIAL_CREATED };
  postActivityLogs(event, args.eventSource, args.credentialIds);
};

export const trackMoveEvents = args => dispatch => {
  const shouldTrackUser = dispatch(getTrackingStatus());

  const event = {
    eventName: EVENT_GROUP_CHANGED,
    fromId: args.fromGroup,
    toId: args.toGroup,
  };
  postActivityLogs(event, SOURCE_WEB_APP, args.credentialIds);
};

export const postActivityLogs = async (event, source, ids) => {
  try {
    await Promise.all(ids.map(async credentialId => {
      const req = new Request(URL_TRACKING);
      const res = await req.authorise().post({ ...event, source, credentialId });
      return res;
    }));
  }
  catch (err) {
    console.log(err);
  }
};
