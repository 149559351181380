/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from '@nettoken/components';
import { noop, reloadBrowser } from '@nettoken/helpers';
import Styles from 'Modal/style.css';
import Tooltip from 'Tooltip';
import iconPlus from 'icons/plus.svg';
import CancelIcon from '../../../ActionIcons/CancelIcon';
import { getIconColorNewmethod } from '../../../../utils/misc';

import {
  ADD_DASHBOARD_LABEL,
  ID_ADD_DASHBOARD,
  ID_SHOW_HOME_TITLE,
  NETTOKEN_HEAD,
} from '../../../../constants/ids';

const sharingDashboards = ({
  t,
  onClickAccepted,
  onClickClose,
  acceptProcessing,
  dashboardName,
  sharedByUserName,
  sharedByUserPhone,
  deleteProcessing,
  onClickDecline,
}) => (
    <React.Fragment>
      <h1
        aria-label='Sharing Invitation'
        className={Styles.title}>
        Sharing Invitation
        <CancelIcon onCancel={onClickClose} />
      </h1>


      <div className={Styles.SharingBywrapper}>
        <div style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          margin: '28px auto 14px',
        }}>
          <div className={Styles.nameIconHoverEffect}>
            <svg width='100%' height='100%'
              style={{
                borderRadius: '50%', width: '48px', height: '48px',
              }}
            >
              <rect x='0' y='0' fill={getIconColorNewmethod(dashboardName)} height='100%' width='100%'></rect>
              <text
                  dominantBaseline='middle'
                  fill='#ffffff'
                  fontSize='10'
                  textAnchor='middle'
                  x='48%'
                  y='54%'
              >{dashboardName.slice(0, 2).toUpperCase()}</text>
            </svg>
          </div>
          <div style={{ marginLeft: '-12px', zIndex: '3' }}>
            <div className={Styles.nameIconHoverEffect}>
                <svg width='100%' height='100%'
                  style={{
                    borderRadius: '50%', width: '48px', height: '48px',
                  }}
                >
                  <rect x='0' y='0' fill={getIconColorNewmethod(sharedByUserName)} height='100%' width='100%'></rect>
                  <text
                      dominantBaseline='middle'
                      fill='#ffffff'
                      fontSize='10'
                      textAnchor='middle'
                      x='48%'
                      y='54%'
                  >{sharedByUserName.slice(0, 2).toUpperCase()}</text>
                </svg>
            </div>
          </div>
        </div>
        <span style={{
          fontSize: '14px',
        }}>
          {dashboardName} shared by {sharedByUserName}
        </span>
        {/* <span style={{
          fontSize: '14px',
        }}>
          {sharedByUserPhone}
        </span> */}
        <br/><br/>
        <p>
          Accept or decline invitation from {sharedByUserName} to view shared dashboard.
        </p>

        <div className={classNames({
          [Styles.actionsFixed]: true,
          [Styles.actions]: true,
        })}>
          <Button
            className={classNames({
              [Styles.button]: true,
              [Styles._danger]: true,
            })}
            loaderSize={20}
            loading={deleteProcessing}
            onClick={onClickDecline}
            value='Decline' />

          <Button
            className={classNames({
              [Styles.button]: true,
              [Styles.notDisabled]: true,
            })}
            loading={acceptProcessing}
            onClick={onClickAccepted}
            loaderSize={20}
            value='Accept' />
        </div>
      </div>

    </React.Fragment>
);

sharingDashboards.propTypes = {
  onCancel: PropTypes.func,
  /** i18n translate method */
  t: PropTypes.func.isRequired,
};

export default sharingDashboards;
