import React from 'react';
import activeCancel from '../../assets/images/leftArrow_icon/active.svg';
import hoverCacnel from '../../assets/images/leftArrow_icon/hover.svg';
import clickCancel from '../../assets/images/leftArrow_icon/click.svg';

const LeftArrowIcon = props => (
  <img
    src={activeCancel}
    onMouseOver={e => (e.currentTarget.src = hoverCacnel)}
    onMouseOut={e => (e.currentTarget.src = activeCancel)}
    onMouseUp={e => (e.currentTarget.src = clickCancel)}
    onClick={e => {
      if ('toggleView' in props) {
        props.toggleView(false);
      }
      else {
        props.onClickBackHandler();
      }
    }}
    style={{
      position: 'absolute',
      cursor: 'pointer',
      left: '9px',
      float: 'left',
    }}
  />
);

export default LeftArrowIcon;
