/*
 * Copyright (C) 2018-2019 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import React from 'react';
import { List } from 'immutable';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';

import { hideModal } from 'main/modal';
import withOverlayAction from 'Overlay/withAction';
import Container from './container';
import { getApiErrorStatus } from '../../../../main/ui/reduxState';

class TakeNotePrivateDashboardAddAccountsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.hide = this.hide.bind(this);
  }

  componentDidMount() {
    this.props.addOverlayAction('modal', this.hide, false);
  }

  hide() {
    this.props.hide();
  }

  render() {
    return (
      <Container
        onCancel={this.hide}
        name={this.props.name}
        counter={this.props.counter}
        />
    );
  }
}

const mapStateToProps = state => ({
  counter: Object.values(state.credentials.data).filter(x => 'dashboardSpaceId' in x && x.dashboardSpaceId == state.ui.currentDashboard).length,
  name: state.ui.dashboards[state.ui.currentDashboard].name,
});

const mapDispatchToProps = dispatch => ({
  hide: () => dispatch(hideModal()),
  getApiErrorStatus: () => dispatch(getApiErrorStatus()),
});

export default translate()(connect(
  mapStateToProps,
  mapDispatchToProps,
)(withOverlayAction(TakeNotePrivateDashboardAddAccountsComponent)));
