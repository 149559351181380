/*
 * Copyright (C) 2018-2019 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { i18nRenderLines, noop } from '@nettoken/helpers';
import ActionBar from 'Modal/ActionBar';
import Input from 'Input';
import AppsPreview from 'AppsPreview';
import Tabs from 'Modal/tabs';
import Styles from 'Modal/style.css';
import { Button } from '@nettoken/components';
import sharedToMeIcon from 'icons/downwardarrow.svg';
import Avatar from 'Avatar';
import { getIconColorNewmethod } from '../../../../utils/misc';
import CancelIcon from '../../../ActionIcons/CancelIcon';

class ModalSharingByInvitationContainer extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <h1
          aria-label={t('modal.accountAdd.title')}
          className={Styles.title}>
          {/* {t('modal.accountAdd.title')} */}
          Sharing invitation
          <CancelIcon onCancel={this.props.onClickClose} />
        </h1>


        <div className={Styles.SharingBywrapper}>
          <div style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            margin: '28px auto 14px',
          }}>
            <div className={Styles.avatarPosition}>
              <Avatar
                domain={this.props.domain}
                loginUrl={this.props.loginUrl}
                name={this.props.name}
                picture={this.props.picture}
                source='true'
              />
            </div>
            <div style={{ marginLeft: '-12px', zIndex: '3' }}>
              <div className={Styles.nameIconHoverEffect}>
                <svg width='100%' height='100%'
                  style={{
                    borderRadius: '50%', width: '48px', height: '48px',
                  }}
                >
                  <rect x='0' y='0' fill={getIconColorNewmethod(this.props.sharedByUserName)} height='100%' width='100%'></rect>
                  <text
                      dominantBaseline='middle'
                      fill='#ffffff'
                      fontSize='10'
                      textAnchor='middle'
                      x='48%'
                      y='54%'
                  >{this.props.sharedByUserName.slice(0, 2).toUpperCase()}</text>
                </svg>
              </div>
            </div>
          </div>
          <span style={{
            fontSize: '14px',
          }}>
            {this.props.name} shared by {this.props.sharedByUserName}
          </span>
          <span style={{
            fontSize: '14px',
          }}>
            {this.props.sharedByUserPhone}
          </span>
          <br/><br/>
          <p>
            {`Accept or decline invitation from ${this.props.sharedByUserName} to view shared login details.`}
          </p>

          <div className={classNames({
            [Styles.actionsFixed]: true,
            [Styles.actions]: true,
          })}>
            <Button
              className={classNames({
                [Styles.button]: true,
                [Styles._danger]: true,
              })}
              loaderSize={20}
              loading={this.props.deleteProcessing}
              onClick={this.props.onClickDecline}
              value='Decline' />

            <Button
              className={classNames({
                [Styles.button]: true,
                [Styles.notDisabled]: true,
              })}
              loaderSize={20}
              loading={this.props.acceptProcessing}
              onClick={this.props.onClickAccepted}
              value='Accept' />
          </div>
        </div>

      </React.Fragment>
    );
  }
}


export default ModalSharingByInvitationContainer;
