/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import {
  RX_USER_LOG_IN,
  RX_USER_LOG_OUT,
  RX_USER_CHANGES_SAVE,
  RX_USER_CHANGES_TEMP,
  RX_USER_CHANGES_UNDO,
  RX_ADD_DASHBOARD_INVITES,
  RX_REMOVE_DASHBOARD_INVITES,
} from './actionTypes';

export const RXUserChangesSave = () => ({
  type: RX_USER_CHANGES_SAVE,
});

/**
 * @param {array} payloads Redux payload objects.
 */
export const RXUserChangesTemp = payloads => ({
  type: RX_USER_CHANGES_TEMP,
  payloads,
});

export const RXUserChangesUndo = () => ({
  type: RX_USER_CHANGES_UNDO,
});

/**
 * @param {string} accessToken
 */
export const RXUserLogIn = accessToken => ({
  type: RX_USER_LOG_IN,
  accessToken,
});

export const RXUserLogOut = () => ({
  type: RX_USER_LOG_OUT,
});

export const RXAddDashboardInvites = dashboardInvites => ({
  type: RX_ADD_DASHBOARD_INVITES,
  dashboardInvites,
});

export const RXRemoveDashboardInvites = id => ({
  type: RX_REMOVE_DASHBOARD_INVITES,
  id,
});
