/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import React from 'react';
import { PropTypesLoading } from 'utils/propTypes';

const LazyLoadBlankComponent = () => (
  <div />
);

LazyLoadBlankComponent.propTypes = PropTypesLoading;

export default LazyLoadBlankComponent;
