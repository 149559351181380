/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@nettoken/components';
import { noop } from '@nettoken/helpers';
import Helmet from 'Helmet';
import Styles from 'Sidebar/style.css';
import Styles2 from 'Modal/style.css';
import CancelIcon from '../../../ActionIcons/CancelIcon';

const Checkbox = ({ checked, name, onChange }) => (
  <div className={Styles.radioWrapper}>
    <input
      checked={checked}
      className={Styles.radioInput}
      id={name}
      onChange={onChange}
      name={name}
      // type="radio" would not be focusable with keyboard
      type="checkbox"
    />

    <label className={Styles.radioLabel} htmlFor={name}>
      <div className={Styles.radioBorder} />
    </label>
  </div>
);

class SidebarDownloadContainer extends React.Component {
  renderFragments(type) {
    const { t } = this.props;
    const isChecked = this.props.checkedOption === type;
    return (
      <React.Fragment>
        {isChecked && (
          <p className={`${Styles.radioTextInExport} ${Styles._danger}`}>
            {t('views.export.warning')}
          </p>
        )}

        <Checkbox
          checked={isChecked}
          name={type}
          onChange={this.props.onChangeCheckbox}
        />

        <Button
          className={Styles.radioButton}
          disabled={!isChecked}
          icon={true}
          light={true}
          loading={isChecked && this.props.downloading}
          onClick={this.props.onClickDownload}
          value="Download Data"
        />
      </React.Fragment>
    );
  }

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <h1 aria-label="Download your data" className={Styles2.title1}>
          Download your data
          <CancelIcon onCancel={this.props.onCancel} />
        </h1>
        <form
          action="/"
          className={`${Styles2.whiteBackground2} ${Styles2.minHeightBackground}`}
          method="post"
          onSubmit={this.props.onSubmit}
        >
          <div
            className={`${Styles.content} ${Styles._scrollable}`}
          >
            <div className={Styles.paddingFixer}>
              <ul>
                <li>
                  <p>Download a file with your Nettoken account data.</p>

                  <ul>
                    <h4 className={Styles.radioTitle}>Encrypted file</h4>
                    <p>{t('views.export.encryptedText')}</p>
                    <li className={Styles.exportSectionItem}>
                      <span>I agree and understand</span>
                      {this.renderFragments('encrypted')}
                    </li>
                    <h4 className={Styles.radioTitle}>Decrypted file</h4>
                    <p>{t('views.export.decryptedText')}</p>
                    <li className={Styles.exportSectionItem}>
                      <span className={Styles.exportSectionItem}>
                        I agree and understand
                      </span>
                      {this.renderFragments('decrypted')}
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </form>
      </React.Fragment>
    );
  }
}

SidebarDownloadContainer.defaultProps = {
  checkedOption: '',
  deleting: false,
  downloading: false,
  onCancel: noop,
  onChangeCheckbox: noop,
  onClickDownload: noop,
  onSubmit: noop,
};

SidebarDownloadContainer.propTypes = {
  checkedOption: PropTypes.string,
  deleting: PropTypes.bool,
  downloading: PropTypes.bool,
  onCancel: PropTypes.func,
  onChangeCheckbox: PropTypes.func,
  onClickDownload: PropTypes.func,
  onSubmit: PropTypes.func,
  /** i18n translate method */
  t: PropTypes.func.isRequired,
};

export default SidebarDownloadContainer;
