/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
export const URL_ACCOUNTS = 'v1/accounts';
export const URL_ACCOUNTS_SEARCH = 'v1/accounts/search';
export const URL_ACCOUNTS_SEARCH_ALL = 'v1/accounts/search/default';
export const URL_APP_DOWNLOAD = 'v1/download';
export const URL_BACKUP_INFO = 'v1/backup/info';
export const URL_BACKUP = 'v1/backup';
export const URL_CLIENTS_APPROVE = 'v1/clients/approve';
export const URL_CLIENTS_REJECT = 'v1/clients/reject';
export const URL_SHARED_USER = 'v1/users/shared';
export const URL_CREDENTIALS_ORDER = 'v1/groups/:id/credentialsorder';
export const URL_GROUP = 'v1/groups/:id';
export const URL_GROUPS = 'v1/groups';
export const URL_GROUPS_ORDER = 'v1/groupsorder';
export const URL_PREFERENCES = 'v1/gdpr';
export const URL_SETTINGS = 'v1/users/settings';
export const URL_USERS = 'v1/users';
export const URL_CLIENTS = 'v1/clients';
export const DASHBOARD_SPACES = 'v1/dashboardspace';
export const DASHBOARD_SPACE_ID = 'v1/dashboardspace/:id';
export const URL_WEBSITE_SEARCH = 'v1/accounts/is_reachable';
// export const URL_DECLINE_INVITATIONS = 'v1/credentials/sharedto';

export const URL_AUTH_REQUEST = 'v2/auth/request';
export const URL_AUTH_VERIFY = 'v2/auth/verify';
export const URL_CREDENTIAL = 'v2/credentials/:id';
export const URL_CREDENTIALS = 'v2/credentials';
export const URL_CREDENTIALS_SHARED = 'v2/shared/credentials';
export const DOWNWARD = 'v2/shared/credential/update';
export const URL_CREDENTIALS_SHARED_INVITATIONS = 'v2/shared/invitations';
export const URL_CREDENTIALS_SHARED_USERS = 'v2/shared/users';
export const URL_TRACKING = 'v2/tracking';
export const URL_EVENTS = 'v2/tracking/:id';
export const DASHBOARD_SHARING = 'v2/shared/dashboard';
export const ACCEPT_DASHBOARD_SHARING = 'v2/shared/dashboard/invitations';
export const BULK_URL_CREDENTIAL = 'v2/credentials';
