/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import { noop } from '@nettoken/helpers';
import Routes from 'config/routes';
import { isTest } from 'utils/misc';
import Helmet from 'Helmet';
import Toggle from 'SidebarToggle';
import Styles from 'Sidebar/style.css';

class SidebarPrivacyContainer extends React.Component {
  getButton(key, index, extraSpacing) {
    let className = Styles.link;
    if (extraSpacing && index === 0) className += ` ${Styles._extraSpacing3}`;
    return (
      <button
        className={className}
        key={key}
        onClick={this.props.onClick}
        type="button"
        value={`#${key}`}>
        {this.renderText(`views.privacy.buttons.${key}`)}
      </button>
    );
  }

  getSectionInner(id) {
    const { t } = this.props;
    switch (id) {
      /*
      case 'email':
        return [
          <Toggle
            key={0}
            name="newsletter"
            onChange={this.props.onChangePermission}
            t={t}
            text={t('views.privacy.text.newsletter')}
            title={t('views.privacy.text.newsletterTitle')}
            value={this.props.permissionNewsletter} />,

          this.props.permissionNewsletter && this.renderRadio(1),
        ];

      case 'misc':
        return [
          <Toggle
            key={3}
            name="info"
            onChange={this.props.onChangePermission}
            t={t}
            text={t('views.privacy.text.info')}
            title={t('views.privacy.text.infoTitle')}
            value={this.props.permissionInfo} />,
        ];
      */

      case 'optional':
        return (
          <React.Fragment>
            <Toggle
              error={this.props.ageError}
              name="age"
              onChange={this.props.onChangeInput}
              placeholder={t('views.privacy.text.ageTitle')}
              t={t}
              text={t('views.privacy.text.age')}
              title={t('views.privacy.text.ageTitle')}
              type="number"
              value={this.props.age} />

            <Toggle
              error={this.props.hometownError}
              name="location"
              onChange={this.props.onChangeInput}
              placeholder={t('views.privacy.text.locationTitle')}
              t={t}
              text={t('views.privacy.text.homeTown')}
              title={t('views.privacy.text.homeTownTitle')}
              type="text"
              value={this.props.hometown} />

            <Toggle
              name="device"
              onChange={this.props.onChangePermission}
              t={t}
              text={t('views.privacy.text.device')}
              title={t('global.device')}
              value={this.props.permissionDevice} />

            <Toggle
              name="cookies"
              onChange={this.props.onChangePermission}
              t={t}
              text={t('views.privacy.text.cookies')}
              title={t('views.privacy.text.cookiesTitle')}
              value={this.props.permissionCookies} />
          </React.Fragment>
        );

      case 'required':
        return (
          <React.Fragment>
            <Toggle
              t={t}
              text={t('views.privacy.text.phone')}
              title={t('global.phone')} />

            <Toggle
              t={t}
              text={t('views.privacy.text.ipAddress')}
              title={t('global.ipAddress')} />
          </React.Fragment>
        );

      default:
        return null;
    }
  }

  getSection(id, key) {
    const { t } = this.props;
    return (
      <section
        className={Styles.section}
        id={id}
        key={key}>
        <h6
          aria-hidden={true}
          className={`${Styles.title} ${Styles._invisibleText}`}>
          {t('views.privacy.title')}
        </h6>

        {this.getSectionInner(id)}
      </section>
    );
  }

  renderButtons(sections, firstExtraSpacing) {
    return sections.map((key, index) => this.getButton(key, index, firstExtraSpacing));
  }

  renderSections(sections) {
    return sections.map((key, index) => this.getSection(key, index));
  }

  renderText(key) {
    // TODO <Trans> not working in Storybook
    if (isTest()) {
      return this.props.t(key);
    }

    return (
      <Trans i18nKey={key}>
        <strong> </strong>
      </Trans>
    );
  }

  render() {
    const { t } = this.props;
    return (
      <div className={Styles.sidebarFullHeight}>
        <Helmet title={t('views.privacy.title')} />

        <div className={`${Styles.content} ${Styles._menu} ${Styles._scrollable}`}>
          <div className={Styles.paddingFixer}>
            <h1 className={Styles.title}>
              {t('views.privacy.title')}
            </h1>

            {this.renderButtons(this.props.sectionsTop, false)}
            {this.renderButtons(this.props.sectionsBottom, true)}

            <NavLink
              className={`${Styles.link} ${Styles._extraSpacing3}`}
              rel="noopener noreferrer"
              target="_blank"
              to={Routes.LEGAL_PRIVACY}>
              {this.renderText('views.privacy.buttons.more')}
            </NavLink>
          </div>
        </div>

        <div className={`${Styles.content} ${Styles._body}`}>
          <div
            className={Styles.sectionWrapper}
            onScroll={this.props.onScrollWrapper}>
            {
              this.props.showMainContent ?
                this.renderSections(this.props.sectionsTop) :
                this.renderSections(this.props.sectionsBottom)
            }
          </div>
        </div>

        <div className={Styles.actions}>
          <button
            className={Styles.button}
            onClick={this.props.onCancel}
            type="button">
            {t('global.back')}
          </button>
        </div>
      </div>
    );
  }
}

SidebarPrivacyContainer.defaultProps = {
  age: '',
  ageError: '',
  hometown: '',
  hometownError: '',
  onCancel: noop,
  onChangeInput: noop,
  onChangeFrequency: noop,
  onChangePermission: noop,
  onClick: noop,
  onScrollWrapper: noop,
  sectionsBottom: [],
  sectionsTop: [],
  showMainContent: true,
};

SidebarPrivacyContainer.propTypes = {
  age: PropTypes.string,
  ageError: PropTypes.string,
  frequencyNewsletter: PropTypes.string,
  hash: PropTypes.string,
  hometown: PropTypes.string,
  hometownError: PropTypes.string,
  onCancel: PropTypes.func,
  onChangeInput: PropTypes.func,
  onChangeFrequency: PropTypes.func,
  onChangePermission: PropTypes.func,
  onClick: PropTypes.func,
  onScrollWrapper: PropTypes.func,
  permissionCookies: PropTypes.bool.isRequired,
  permissionDevice: PropTypes.bool.isRequired,
  sectionsBottom: PropTypes.array,
  sectionsTop: PropTypes.array,
  showMainContent: PropTypes.bool,
  /** i18n translate method */
  t: PropTypes.func.isRequired,
};

export default SidebarPrivacyContainer;
