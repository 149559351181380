/*
 * Copyright (C) 2018-2019 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { hideModal, showModal } from 'main/modal';
import withOverlayAction from 'Overlay/withAction';
import { removeSharedByAccounts } from 'main/sharedAccounts';
import { Request } from 'utils/request';
import { List } from 'immutable';
import { updateCredential, deleteCredential, moveCredentialToGroupId } from 'main/vault/credentials';
import Container from './container';
import { DASHBOARD_SPACES, ACCEPT_DASHBOARD_SHARING } from '../../../../constants/endpoints';
import { MODAL_ADD_DASHBOARD, MODAL_EDIT_DASHBOARD, MODAL_MY_ACCOUNT } from '../../../../constants/modal';
import { NETTOKEN_HEAD } from '../../../../constants/ids';
import { RXDashboardAccept, RXDashboardAdd, RXDashboardDecline } from '../../../../main/modal/reduxActions';
import { RXChangeActiveDashboard } from '../../../../main/ui/reduxActions';
import { doSearch } from '../../../../main/search';
import { RXRemoveDashboardInvites } from '../../../../main/user/reduxActions';
import { authenticateUserData, decryptCredentials } from '../../../../main/auth';
import { RXSharedAccountsCreate } from '../../../../main/sharedAccounts/reduxActions';
import { addCredentials } from '../../../../main/vault/credentials';
import { getUserData } from '../../../../main/user';

class ModalSharingDashboards extends React.Component {
  constructor(props) {
    super(props);
    this.onClickClose = this.onClickClose.bind(this);
    this.acceptSharingInvitation = this.acceptSharingInvitation.bind(this);
    this.declineSharingInvitation = this.declineSharingInvitation.bind(this);
    this.state = {
      acceptProcessing: false,
      deleteProcessing: false,
    };
  }

  componentDidMount() {
    this.props.addOverlayAction('modal', this.props.hide);
  }

  componentWillMount() {
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.usersApiCallFinished != this.props.usersApiCallFinished &&
      this.props.usersApiCallFinished
    ) {
      this.onClickClose();
    }
  }

  onClickClose() {
    this.props.hide();
  }

  refreshUserData = async () => {
    const { token, authenticate } = this.props;
    const { encryptedUserCredentials, profile } = await getUserData(token, true, true);

    const {
      groups, groupsOrder, id, shared,
    } = encryptedUserCredentials;
    return authenticate(
      id, token, groups, groupsOrder, shared, { override: true, skipPrompt: true }, profile,
    );
  };

  async acceptSharingInvitation() {
    const req = new Request(ACCEPT_DASHBOARD_SHARING);
    this.setState({ acceptProcessing: true });
    this.props.deleteDashboard(this.props.data.id);
    const sharedAccountsIds = [];
    const nameDashboard = this.props.data.name;
    return req.authorise()
      .post({
        sharedId: this.props.data.invitationId,
        dashboardName: this.props.data.name,
        isAccepted: true,
      }).then(res => {
        const objData = {
          ...res,
          name: nameDashboard,
          // ...this.props.data,
        };
        // objData.id = res.id;
        // objData.sharedId = res[0].id;
        const { id } = this.props.data;
        delete objData.invitationId;
        objData.externalDashboard = true;
        objData.shared = true;
        this.props.acceptDashboard(id, objData);
        // if (objData?.credentials) {
        //   const reducer = (acc, value) => acc.push(value);
        //   const encryptedCredentials = objData.credentials.reduce(reducer, List());
        //   decryptCredentials(this.props.userId, encryptedCredentials,
        //     this.props.accessToken).then(credentials => {
        //     console.log('spec_Check_dsafafa', credentials);
        //     credentials.filter(credential => {
        //       const shared = credential.get('shared');
        //       const accountId = credential.get('id');
        //       const externalAccount = credential.get('externalAccount');
        //       if (shared) {
        //         sharedAccountsIds.push(accountId);
        //         this.props.sharedAccountsCreate(accountId, credential.get('usersSharedWith'));
        //       }
        //     });
        //     this.props.addCredentialsData({
        //       credentials,
        //       pushToServer: false,
        //       override: true,
        //     });
        //   });
        // }
        this.refreshUserData();
        // this.props.addDashboard(objData);
        this.props.search(res.id.toString(), 'dashboardSpaceId');
        this.onClickClose();
      }).catch(error => {
        this.props.addDashboard(this.props.data);
      });
  }

  async declineSharingInvitation() {
    this.setState({ deleteProcessing: true });
    const req = new Request(ACCEPT_DASHBOARD_SHARING);
    this.props.deleteDashboard(this.props.data.invitationId);
    return req.authorise()
      .post({
        sharedId: this.props.data.invitationId,
        dashboardName: this.props.data.name,
        isAccepted: false,
      }).then(() => {
        // this.props.deleteDashboard(this.props.data.invitationId);
        this.onClickClose();
      });
    const params = {
      sharedId: this.props.data.invitationId,
      credentialName: this.props.data.name,
      isAccepted: false,
      id: this.props.data.id,
    };

    try {
      const { id } = this.props.data;
      const res = await this.props.sharedInvitation(params);
      this.setState({ deleteProcessing: false });
      this.props.deleteCredential({
        id,
        pushToServer: false,
      });
      this.props.hide();
    }
    catch (error) {
      console.log(error, 'declineSharingInvitation');
    }
  }

  render() {
    return (
      <Container
        onCancel={this.hide}
        onClickAccepted={this.acceptSharingInvitation}
        sharedByUserName={'sharedByUserName' in this.props.data ? this.props.data.sharedByUserName : this.props.data.sharedByUser}
        // sharedByUserPhone={this.props.data.sharedByUserPhone}
        accepted={this.props.data.accepted}
        onClickDecline={this.declineSharingInvitation}
        onClickClose={this.onClickClose}
        acceptProcessing={this.state.acceptProcessing}
        deleteProcessing={this.state.deleteProcessing}
        dashboardName={this.props.data.name}
      />
    );
  }
}

const mapDispatchToState = state => ({
  name: state.user.profile.name,
  data: state.ui.modalData || {},
  usersApiCallFinished: state.ui.usersApiCallFinished,
  userId: state.user.profile.id,
  accessToken: state.session.accessToken,
  token: state.session.accessToken,
});

const mapDispatchToProps = dispatch => ({
  hide: () => dispatch(hideModal()),
  showModal: (name, data) => dispatch(showModal(name, data)),
  addDashboard: dashboardName => dispatch(RXDashboardAdd(dashboardName)),
  search: (query, specialSearch = '') => {
    if (specialSearch === 'dashboardSpaceId') {
      dispatch(RXChangeActiveDashboard(query));
    }
    return dispatch(doSearch(query, specialSearch));
  },
  acceptDashboard: (id, dashboard) => {
    // dispatch(RXDashboardDecline(id));
    dispatch(RXRemoveDashboardInvites(id));
    dispatch(RXDashboardAccept(id, dashboard));
  },
  deleteDashboard: id => {
    dispatch(RXRemoveDashboardInvites(id));
    dispatch(RXDashboardDecline(id));
  },
  sharedAccountsCreate: (
    accountId,
    usersSharedWith,
  ) => dispatch(RXSharedAccountsCreate(
    accountId,
    usersSharedWith,
  )),
  addCredentialsData: args => dispatch(addCredentials(args)),
  authenticate: (...args) => dispatch(authenticateUserData(...args)),
});

export default translate()(
  connect(
    mapDispatchToState,
    mapDispatchToProps,
  )(withOverlayAction(ModalSharingDashboards)),
);
