/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
export const RX_GROUPS_CREATE = 'RX_GROUPS_CREATE';
export const RX_GROUPS_DELETE = 'RX_GROUPS_DELETE';
export const RX_GROUPS_SET_UNSORTED_ID = 'RX_GROUPS_SET_UNSORTED_ID';
export const RX_GROUPS_UPDATE = 'RX_GROUPS_UPDATE';
export const RX_GROUPS_UPDATE_BATCH = 'RX_GROUPS_UPDATE_BATCH';
