/*
 * Copyright (C) 2018-2019 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import {
  URL_USERS, URL_CREDENTIAL, URL_EVENTS, URL_TRACKING,
} from 'constants/endpoints';
import { TUTORIAL_ADD_CREDENTIAL, TUTORIAL_SORT_CREDENTIALS } from 'constants/ids';
import {
  EVENT_CREDENTIAL_CREATED,
  EVENT_EMAIL_UPDATED,
  EVENT_GROUP_CHANGED,
  EVENT_PASSWORD_UPDATED,
  EVENT_USERNAME_UPDATED,
} from 'constants/events';
import { isImmutable, List, Map } from 'immutable';
import { noop } from '@nettoken/helpers';

import {
  Credential,
  credentialLoginAttributes,
  credentialsListToMapByGroupId,
  hasCredentialLoginData,
} from '@nettoken/models';

import Validator from '@nettoken/validator';

import { hasMasterKeyBackup } from 'main/backup/reduxState';
import { catchUIError } from 'main/error';
import { trackAddEvents, trackUpdateEvents } from 'main/tracking';
import { getOrCreateAccounts, refreshSearch } from 'main/search';
import { markAsComplete } from 'main/tutorial';
import { RXUIGuideBackup } from 'main/ui/reduxActions';
import { addUserCredentials, editUserCredential } from 'main/user';
import { hasEncryptedData } from 'main/user/reduxState';

import {
  addCredentialsToGroup,
  createEmptyGroups,
  deleteFirstGroupIfExistsAndEmpty,
  getGroupIdFromGroupInputField,
  syncCredentialsOrder,
} from 'main/vault/groups';

import { RXGroupsUpdateOne } from 'main/vault/groups/reduxActions';
import { getUnsortedGroupId } from 'main/vault/groups/reduxState';
import CSSVars from 'runtime/variables';
import { Request } from 'utils/request';
import {
  RXCredentialsCreate,
  RXCredentialsDelete,
  RXCredentialsUpdateOne,
  RXReplaceCredentialTemporaryId,
  RXSetNewCredentials,
} from './reduxActions';

import {
  getCredentialIndexInGroup,
  getCredentialsRootObject,
  getGroupIdFromCredentialId,
  getOneCredential,
} from './reduxState';
import { DOWNWARD } from '../../../constants/endpoints';

/**
 * @param {array} credentials
 * @param {boolean} createEmptyGroup
 */
const createCredentials = (credentials, createEmptyGroup, override) => dispatch => {
  dispatch(RXCredentialsCreate(credentials, override));
  dispatch(addCredentialsToGroup({ apps: credentials, createEmptyGroup }));
  dispatch(refreshSearch());

  if (dispatch(hasCompletedAddCredentialTutorial())) {
    dispatch(markAsComplete(TUTORIAL_ADD_CREDENTIAL));
  }

  if (dispatch(hasEncryptedData()) && !dispatch(hasMasterKeyBackup())) {
    dispatch(RXUIGuideBackup(true));
  }
};

/**
 * @param {object} [args]
 * @property {string} id
 * @property {boolean} [pushToServer=true]
 */

export const deleteCredential = args => async dispatch => {
  const { id, pushToServer, notify } = Validator.ensureDefaults(args, {
    pushToServer: true,
    notify: true,
  });
  if (pushToServer) {
    try {
      // const req = new Request(`${URL_CREDENTIAL}/${id}?notify=${notify}`);
      // await req.authorise().delete();
      let url = URL_CREDENTIAL;
      url = url.replace(':id', id);
      const req = new Request(`${url}?notify=${notify}`);
      await req.authorise().delete();
      dispatch(deleteCredentialFromGroup({ id, pushToServer }));
      dispatch(RXCredentialsDelete(id));
      dispatch(refreshSearch());
    }
    catch (e) {
      throw (e);
    }
  }
  else {
    dispatch(deleteCredentialFromGroup({ id, pushToServer }));
    dispatch(RXCredentialsDelete(id));
    dispatch(refreshSearch());
  }
};

/**
 * @param {object} [args]
 * @property {string} id Credential id.
 * @property {boolean} [pushToServer=true]
 */
const deleteCredentialFromGroup = args => (dispatch, getState) => {
  const { prevGroupId, id, pushToServer } = Validator.ensureDefaults(args, { pushToServer: true });
  const groupId = prevGroupId || dispatch(getGroupIdFromCredentialId(id));
  const group = getState().groups.data[groupId];

  if (group) {
    const index = group.apps.indexOf(id);
    if (index !== -1) {
      group.apps = [
        ...group.apps.slice(0, index),
        ...group.apps.slice(index + 1),
      ];
    }

    if (group.apps.length <= CSSVars.groupItemsPerRow) {
      group.meta.open = false;
    }
    dispatch(RXGroupsUpdateOne(groupId, group));

    if (pushToServer) {
      const order = [...group.apps];
      syncCredentialsOrder(groupId, order).catch(e => console.log(e));
    }
  }
};

const updateDuplicateCredentials = (credentials, eventSource) => async (dispatch, getState) => {
  let promises = Promise.resolve();

  credentials.forEach(async created => {
    const meta = created.get('meta');
    if (meta.mergeWith) {
      const id = meta.mergeWith;
      const state = dispatch(getOneCredential(id));
      const existing = new Credential(state);
      credentialLoginAttributes.forEach(key => existing.set(key, created.get(key)));

      promises = promises.then(async () => { // eslint-disable-line no-loop-func
        await dispatch(updateCredential({
          credential: existing, id, eventSource, tracking: true,
        }));
        credentials = credentials.filter(x => x.get('id') !== created.get('id'));
      });
    }
  });

  await promises;
  return credentials;
};

/**
 * @param {List} credentials
 * @param {string} defaultGroupId
 *
 * @returns {array}
 */
const normalizeCredentials = (credentials, defaultGroupId, defaultDashId = '') => {
  const promises = credentials.map(async credential => {
    if (!credential.get('groupId')) credential.set('groupId', defaultGroupId);
    if (!credential.get('loginUrl')) credential.set('loginUrl', credential.get('domain'));
    if (!credential.get('name')) credential.set('name', credential.get('domain'));
    // This is a new account, get its account id.
    if (!credential.get('accountId')) {
      const [data] = await getOrCreateAccounts([credential.get('loginUrl')]);
      if (data) {
        credential.attachGlobalData(data);
      }
      if (!credential.get('dashboardSpaceId')) credential.set('dashboardSpaceId', defaultDashId);
    }
    let errors = credential.validate();
    if (errors) {
      errors = errors.filter(x => x.forElement != 'loginUrl');
    }
    if (errors && errors.length > 0) throw errors;

    return credential;
  });
  return Promise.all(promises);
};

/**
 * @param {List} credentials
 */
const setCredentialsGroupIdsToRealValues = credentials => async dispatch => {
  const unsortedId = dispatch(getUnsortedGroupId());
  let groupIdsTempRealMap = new Map();
  let promises = Promise.resolve();

  // Collect unique group ids.
  credentials.forEach(credential => {
    const tempId = credential.get('groupId');
    if (tempId !== unsortedId && !groupIdsTempRealMap.get(tempId)) {
      const flag = true;
      groupIdsTempRealMap = groupIdsTempRealMap.set(tempId, flag);

      // Ensure groups exist on the server and get their ids.
      promises = promises.then(async () => {
        const realId = await dispatch(getGroupIdFromGroupInputField(null, tempId));
        groupIdsTempRealMap = groupIdsTempRealMap.set(tempId, realId);
      });
    }
  });

  await promises;

  // Replace fake group ids with the real values.
  credentials = credentials.map(credential => {
    const tempId = credential.get('groupId');
    if (tempId !== unsortedId) {
      const realId = groupIdsTempRealMap.get(tempId);
      credential.set('groupId', realId);
    }
    return credential;
  });

  return credentials;
};

/**
 * Overwrite existing credentials with the passed credentials login data if
 * they are empty.
 *
 * @param {List} credentials
 *
 * @returns {List} Original credentials excluding those that got updated.
 */
const updateEmptyCredentials = (credentials, eventSource) => async (dispatch, getState) => {
  const { byDomain } = getState().credentials;
  let promises = Promise.resolve();

  credentials.forEach(async created => {
    const domain = created.get('domain');
    const ids = byDomain[domain];
    if (ids) {
      for (let index = 0; index < ids.length; index += 1) {
        const id = ids[index];
        const state = dispatch(getOneCredential(id));
        const existing = new Credential(state);
        if (!hasCredentialLoginData(existing)) {
          // Remove this id so it does not get overwritten twice.
          byDomain[domain].splice(index, 1);

          credentialLoginAttributes.forEach(key => existing.set(key, created.get(key)));

          promises = promises.then(async () => { // eslint-disable-line no-loop-func
            await dispatch(updateCredential({
              credential: existing, id, eventSource, tracking: true,
            }));
            credentials = credentials.filter(x => x.get('id') !== created.get('id'));
          });
          break;
        }
      }
    }
  });

  await promises;
  return credentials;
};

/**
 * @param {object} [args={}]
 * @property {boolean} [createEmptyGroup] Force create an empty group after.
 * @property {List|array} credentials Legacy is array, remove it once we convert
 *   all caller functions.
 * @property {string} [group] Default group label.
 * @property {boolean} [pushToServer=true]
 * @property {boolean} [updateEmptyCredentials] Set login information for empty
 * duplicates instead of creating a new record.
 */
export const addCredentials = (args = {}) => async (dispatch, getState) => {
  args = Validator.ensureDefaults(args, {
    pushToServer: true,
    override: false,
    updateDuplicateCredentials: true,
  });

  let credentials = args.credentials || new List();

  try {
    let apps = new List();
    let credentialIds = new List();
    if (credentials.size) {
      let defaultGroup;
      if (args.pendingId) {
        defaultGroup = args.pendingId;
      }
      else {
        defaultGroup = await dispatch(getGroupIdFromGroupInputField(null, args.group));
      }
      credentials = new List(await normalizeCredentials(
        credentials,
        defaultGroup,
        getState().ui.currentDashboard,
      ));
      if (args.updateDuplicateCredentials) {
        credentials = await dispatch(updateDuplicateCredentials(credentials, args.eventSource));
      }
      const credentialsMap = credentialsListToMapByGroupId(credentials);
      if (args.pushToServer) {
        const dashboardSpaceId = getState().ui.currentDashboard === '' ? null : getState().ui.currentDashboard;
        const idsMap = await addUserCredentials(credentialsMap, dashboardSpaceId);
        // Replace credential `id` with the real value.
        idsMap.entrySeq().forEach(([groupId, ids]) => {
          const max = ids.length - 1;
          credentialIds = credentialIds.push(...ids);
          ids.reverse().forEach((id, i) => credentialsMap.get(groupId).get(max - i).set('id', id));
        });
      }
      credentialsMap.valueSeq().forEach(list => {
        apps = apps.push(...list.toArray().reverse().map(x => x.getState()));
      });
      await dispatch(createCredentials(apps.toArray(), args.createEmptyGroup, args.override));
      if (args.eventSource) {
        dispatch(trackAddEvents({ eventSource: args.eventSource, credentialIds }));
      }
    }
    else {
      dispatch(createCredentials(apps.toArray(), false, args.override));
    }

    return apps;
  }
  catch (e) {
    dispatch(catchUIError(e));
    throw e;
  }
};

/**
 * `addCredentials()` wrapper. Adds optional functionality such as smart
 * credential updating to avoid creating new objects, deleting first empty
 * group, creating empty named groups, and more.
 *
 * @param {object} args
 * @property {function} [callback]
 * @property {List} credentialIds
 * @property {object} credentials
 * @property {string} [group]
 * @property {array} [groupNames]
 * @property {object} [groups]
 * @property {function} [loader=noop]
 */
export const addCredentialsEfficiently = args => async (dispatch, getState) => {
  const callback = args.callback || noop;
  const loader = args.loader || noop;
  try {
    loader(true);

    let credentials = args.credentialIds.map(id => {
      const state = args.credentials[id];
      state.dashboardSpaceId = getState().ui.currentDashboard;
      return state._isModel ? state : new Credential(state);
    });
    credentials = await dispatch(setCredentialsGroupIdsToRealValues(credentials));
    await dispatch(addCredentials({
      createEmptyGroup: args.group !== undefined,
      credentials,
      group: args.group,
      eventSource: args.eventSource,
      updateEmptyCredentials: false,
      updateDuplicateCredentials: args.updateDuplicateCredentials,
    }));

    await dispatch(deleteFirstGroupIfExistsAndEmpty());
    await dispatch(createEmptyGroups());
    loader(false);
    callback();
  }
  catch (e) {
    loader(false);
    throw e;
  }
};

/**
 * @param {object} args
 * @property {List} ids
 * @property {boolean} [pushToServer]
 */
export const deleteCredentials = args => dispatch => new Promise((resolve, reject) => {
  const { ids, pushToServer } = args;
  let promises = Promise.resolve();
  ids.forEach((id, i) => {
    promises = promises.then(() => dispatch(deleteCredential({
      id,
      pushToServer,
      notify: i >= ids.size - 1,
    })));
  });
  return promises
    .then(() => resolve())
    .catch(e => reject(e));
});

export const fetchActivityLogs = async id => {
  const req = new Request(URL_EVENTS);
  return req.setUrlParams({ id }).authorise().get();
};

/**
 * @returns {boolean}
 */
export const hasAnyCredentials = () => dispatch => {
  const credentials = dispatch(getCredentialsRootObject());
  return Object.keys(credentials).length > 0;
};

/**
 * @returns {boolean}
 */
export const hasCompletedAddCredentialTutorial = () => (_, getState) => {
  const credentials = getState().credentials.data;
  const totalCredentials = Object.keys(credentials).length;
  const hasAddedEnoughCredentials = totalCredentials > 10;
  return hasAddedEnoughCredentials;
};

export const moveCredentialInGroup = (id, insertIndex) => (dispatch, getState) => {
  const groupId = dispatch(getGroupIdFromCredentialId(id));
  const group = getState().groups.data[groupId];
  const appIndex = dispatch(getCredentialIndexInGroup(id));
  const [app] = group.apps.splice(appIndex, 1);
  group.apps.splice(insertIndex, 0, app);
  dispatch(RXGroupsUpdateOne(groupId, group));

  const order = [...group.apps];
  syncCredentialsOrder(groupId, order).catch(e => console.log(e));
};

/**
 * @param {object} [args]
 * @property {string} credentialId
 * @property {string} groupId
 * @property {integer} [insertIndex]
 * @property {boolean} [pushToServer=true]
 */
export const moveCredentialToGroupId = args => (dispatch, getState) => {
  const {
    credentialId,
    groupId,
    insertIndex,
    pushToServer,
    prevGroupId,
    credential,
    fromMoveInput,
  } = Validator.ensureDefaults(args, { pushToServer: true, fromMoveInput: false });
  const oldGroupId = prevGroupId || dispatch(getGroupIdFromCredentialId(credentialId));
  const deleteCredentialId = credential ? credential.id : credentialId;
  dispatch(deleteCredentialFromGroup({ oldGroupId, id: deleteCredentialId, pushToServer }));
  let credentialObj;
  if (!credential) {
    credentialObj = dispatch(getOneCredential(deleteCredentialId));
  }
  else {
    credentialObj = credential;
  }
  if (fromMoveInput) {
    dispatch(addCredentialsToGroup({
      apps: [{ ...credentialObj, insertIndex }],
      pushToServer,
      fromMoveInput,
    }));
  }
  else {
    dispatch(addCredentialsToGroup({
      apps: [{ ...credentialObj, insertIndex }],
      pushToServer,
      credentialId,
    }));
  }

  if (oldGroupId === dispatch(getUnsortedGroupId())) {
    dispatch(markAsComplete(TUTORIAL_SORT_CREDENTIALS));
  }

  dispatch(refreshSearch());
};

/**
 * Updates the credential model and optionally syncs its state with the server.
 *
 * @param {object} [args]
 * @property {object} credential
 * @property {string} id Updated credential id.
 * @property {boolean} [pushToServer=true]
 */
export const updateCredential = args => (dispatch, getState) => {
  const {
    credential,
    id,
    pushToServer,
    tracking,
    prevGroupId,
    moveCredential,
    fromExtension,
  } = Validator.ensureDefaults(args, {
    pushToServer: true,
    tracking: false,
    moveCredential: true,
    fromExtension: false,
  });
  let { groupId } = Validator.ensureDefaults(args, {
    pushToServer: true,
    tracking: false,
  });

  const shrd = credential.get('externalAccount');
  const state = dispatch(getOneCredential(id));
  if (!groupId) {
    groupId = credential.get('groupId');
  }
  const group = getState().groups.data[groupId];
  if (groupId && moveCredential && !fromExtension) {
    dispatch(moveCredentialToGroupId({
      credentialId: args.credentialId || id,
      groupId,
      pushToServer,
      prevGroupId,
      insertIndex: group ? group.apps.length : 0,
      credential: credential.getState(),
    }));
  }
  else if (groupId === '' && groupId !== prevGroupId) {
    const credentialId = args.credentialId || id;
    const oldGroupId = prevGroupId || dispatch(getGroupIdFromCredentialId(credentialId));
    // const id = credential.id;
    dispatch(deleteCredentialFromGroup({ prevGroupId, id, pushToServer }));
  }
  const updatedCredential = credential.getState();
  if (!('dashboardSpaceId' in updatedCredential) || updatedCredential.dashboardSpaceId === '') {
    updatedCredential.dashboardSpaceId = null;
  }
  dispatch(RXCredentialsUpdateOne(id, updatedCredential));
  if (args.credentialId) {
    dispatch(RXReplaceCredentialTemporaryId(id, args.credentialId));
  }
  dispatch(refreshSearch());
  const objState = getState();
  if (pushToServer) {
    const groupLength = group ? group.apps.length : 0;
    editUserCredential(
      credential,
      dispatch,
      groupLength,
      objState,
      state,
    ).catch(e => console.log(e));
  }
  if (tracking) {
    dispatch(trackUpdateEvents({
      id,
      prevCredential: state,
      nextCredential: credential.getState(),
      eventSource: args.eventSource,
    }));
  }
};

export const pendingInvitationGroupId = () => {
  // const req = new Request(URL_USERS);
  console.log('');
  return 'd6c6f624109e45f6b495a950aa5c3f98';
};
