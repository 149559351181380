import React from 'react';
import Styles from 'Modal/style.css';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Button } from '@nettoken/components';
import cross from '../../../../assets/images/cross.svg';
import leftsvg from '../../../../assets/images/left.svg';
import LeftArrowIcon from '../../../ActionIcons/LeftArrowIcon';
import moveAccountsImage from '../../../../assets/images/moveAccountsImage.svg';
import moveAccountsArrowIcon from '../../../../assets/images/moveAccountsArrowIcon.svg';
import addAccountsPlusIcon from '../../../../assets/images/addAccountsPlusIcon.svg';
import addAccountsImage from '../../../../assets/images/addAccountsImage.svg';
import CancelIcon from '../../../ActionIcons/CancelIcon';
import { getIconColorNewmethod } from '../../../../utils/misc';

const netTokenLogoIcon = require('images/netTokenLogoIcon.png');
const viewOnlyAccessIcon = require('images/viewOnlyAccessIcon.png');
const stopSharingAnytimeIcon = require('images/stopSharingAnytimeIcon.png');
const uniquePasswordsIcon = require('images/uniquePasswordsIcon.png');

const ModalDashboardCreated = ({
  onCancel,
  dashboardName,
  totalAccounts,
  addAccounts,
  moveAccounts,
}) => (
  <React.Fragment>
    <h1 className={Styles.title1}>
      Dashboard created!
      <CancelIcon onCancel={onCancel} />
    </h1>
    <div
      className={`${Styles.whiteBackground2} ${Styles.minHeightBackground}`}
    >
      <div
        style={{ padding: '0px 30px 15% 30px' }}
        className={`${Styles.fixedForm} ${Styles.fixedForm2}`}
      >
        <div className={classNames({
          [Styles.iconHeaderWrapper]: true,
          [Styles.noBg]: true,
          [Styles.marginHeader]: true,
        })}>
          <svg width='100%' height='100%' style={{ borderRadius: '50%' }}>
            <rect x='0' y='0' fill={getIconColorNewmethod(dashboardName)} height='100%' width='100%'></rect>
            <text
            dominantBaseline='middle'
            fill='#ffffff'
            fontSize='10'
            textAnchor='middle'
            x='48%'
            y='54%'
            >{dashboardName.slice(0, 2).toUpperCase()}</text>
          </svg>
        </div>
        <div style={{ margin: '15px auto', textAlign: 'center' }}>
          <p style={{ marginBottom: '-2px' }}><b>{dashboardName}</b></p>
          <span style={{ fontSize: '12px', color: '#868692' }}>{`${totalAccounts} accounts`}</span>
        </div>
        <div>
          <p style={{ textAlign: 'center' }}>
            {' '}
            Let's start by moving existing accounts or <br />
            adding new ones to this dashboard
          </p>{' '}
          <div>
            <div className={`${Styles.gridThreeLayer} ${Styles.circleParent}`} onClick={() => moveAccounts()}>
              <img src={moveAccountsImage} />
              <div className={Styles.circle}>
                <img
                  src={moveAccountsArrowIcon}
                  className={Styles.iconPlus}
                />
              </div>
              <span>Move Accounts</span>
            </div>
            <div className={`${Styles.gridThreeLayer} ${Styles.circleParent}`} onClick={() => addAccounts()}>
              <img src={addAccountsImage} />
              <div
                className={Styles.circle}
              >
                <img
                  src={addAccountsPlusIcon}
                  className={Styles.iconPlus}
                />
              </div>
              <span>Add Accounts</span>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${Styles.actions} ${Styles.actionsFixed} ${Styles._squeezedAltered} ${Styles.fixedButtons}`}
      >
        <Button
          className={classNames({
            [Styles.custombuttom]: true,
            // [Styles.notDisabled]: false,
            [Styles.cancelMarginLeft]: true,
          })}
          type="submit"
          onClick={onCancel}
          value="Skip"
        />
      </div>
    </div>
  </React.Fragment>
);

ModalDashboardCreated.propTypes = {
  hide: PropTypes.func,
  onGotItClick: PropTypes.func,
};

export default ModalDashboardCreated;
