/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import React from 'react';
import { addModalKeyPress, removeModalKeyPress } from 'main/modal';
import { addOverlayAction, removeOverlayAction } from 'main/overlay';
import { store } from 'reducers/store';

const overlayWrapper = WrappedComponent => class OverlayWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      action: null,
      dismissOnClick: true,
    };
    this.registerAction = this.registerAction.bind(this);
  }

  componentWillUnmount() {
    if (this.state.action) {
      removeModalKeyPress();
      if (this.state.dismissOnClick) store.dispatch(removeOverlayAction());
    }
  }

  registerAction(type, action, dismissOnClick = true) {
    this.setState({ action, dismissOnClick }, () => {
      addModalKeyPress(type, action);
      if (dismissOnClick) store.dispatch(addOverlayAction(type, action));
    });
  }

  render() {
    return (
      <WrappedComponent
        addOverlayAction={this.registerAction}
        {...this.props} />
    );
  }
};

export default overlayWrapper;
