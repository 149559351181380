import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Button } from '@nettoken/components';
import Tooltip from 'Tooltip';
import Styles from 'Modal/style.css';
import pendingInviteIcon from '../../assets/images/pendingInviteIcon.png';
import pendingInviteYellowIcon from '../../assets/images/pendingInviteYellowIcon.png';
import stopSharing from '../../assets/images/stopSharing.png';
import accepted from '../../assets/images/accepted.png';
import chevronRight from '../../assets/images/chevronRight.png';
import pendingInviteYellowIconHover from '../../assets/images/pendingInviteYellowIconHover.png';

const SharingBtn = props => {
  const [tooltipMsg, setToolTipMsg] = useState('Accepted');
  const [pendingInviteIconSrc, setPendingInviteIconSrc] = useState(pendingInviteIcon);
  const [allowHover, setAllowHover] = useState(false);
  useEffect(() => {
    let msg = tooltipMsg;
    if (
      props.sharingStatus['spcl_id' in props ? props.spcl_id : props.account.phone] &&
      !props.externalDashboard && !props.sharedByAccounts
    ) {
      msg = 'Stop Sharing';
    }
    else if (props.account.responded) {
      msg = 'Accepted';
    }
    else {
      msg = 'Pending Invitation';
    }
    setToolTipMsg(msg);
    if (props.externalDashboard || props.sharedByAccounts) {
      setPendingInviteIconSrc(pendingInviteYellowIcon);
    }
    else {
      setPendingInviteIconSrc(pendingInviteIcon);
    }
    if (
      (props.externalDashboard && props.account.responded == false) ||
      (props.sharedByAccounts && props.account.responded == false) ||
      (!props.sharedByAccounts && !props.externalDashboard)
    ) {
      setAllowHover(true);
    }
  }, [props]);
  let chevronRightStyle = { width: '24px', height: '24px', marginRight: '-15px' };
  if ('chevronRightStyle' in props && props.chevronRightStyle) {
    chevronRightStyle = {
      ...chevronRightStyle,
      ...props.chevronRightStyle,
    };
  }
  return (
    <div
      className={classNames({
        [Styles.rightSide]: true,
      })}
      style={props.inlineStyle ? props.inlineStyle : {}}
    >
      <div
        onMouseEnter={() => allowHover &&
          props.onMouseEnterSharingStatus(
            'spcl_id' in props ? props.spcl_id : props.account.phone,
          )}
        onMouseLeave={() => allowHover &&
          props.onMouseLeaveSharingStatus(
            'spcl_id' in props ? props.spcl_id : props.account.phone,
          )}
      >
        <button className={`${Styles.button}`}
          style={{ display: 'contents' }}
          disabled={props.disableSharing[props.account.phone] || false}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            if (props.externalDashboard) return;
            if (props.credentialData) {
              props.handleSharingAccount(
                props.credentialData,
                props.account.id,
                props.account.phone,
                props.account.name,
                props.account.responded,
              );
            }
            else {
              props.handleSharingAccount(
                props.account.id,
                props.account.phone,
                props.account.name,
                props.account.responded,
              );
            }
          }
        }>
          {(props.externalDashboard || props.sharedByAccounts) && props.account.responded ?
            null : (
            <Tooltip
              delay={0}
              fontIncrease={true}
              placement={'top'}
              trigger={['hover']}
              mouseEnterDelay={0}
              mouseLeaveDelay={0}
              content={tooltipMsg}
            >
              {
                !props.sharingStatus['spcl_id' in props ? props.spcl_id : props.account.phone] ? (
                  <img style={{ width: '24px', height: '24px' }} src={props.account.responded ? accepted : pendingInviteIconSrc } />
                ) : (
                  <img style={{ width: '24px', height: '24px' }} src={props.externalDashboard || props.sharedByAccounts ? pendingInviteYellowIconHover : stopSharing} />
                )
              }
            </Tooltip>
            )}
        </button>
      </div>
      {('fromContactPopup' in props && !props.credentialData.externalDashboard && !props.credentialData.invitationId) && <span style={
        {
          color: '#868692', fontSize: '14px', marginRight: '0px', marginLeft: '14px',
        }
     }>Edit</span>}
      {('fromContactPopup' in props && (props.credentialData.externalDashboard || props.credentialData.invitationId)) && <span style={
        {
          color: '#868692', fontSize: '14px', marginRight: '0px', marginLeft: '5px',
        }
      }>{'fromContactPopup' in props && (props.credentialData.invitationId ? 'Open' : 'Edit')}</span>}
      <span style={{ marginLeft: '1px' }}> <img style={chevronRightStyle} src={chevronRight} /> </span>
    </div>
  );
};

export default SharingBtn;
