import React from 'react';
import classNames from 'classnames';
import Avatar from 'Avatar';
import Input from 'Input';
import { Button } from '@nettoken/components';
import Styles from 'Modal/style.css';
import CancelIcon from '../../../ActionIcons/CancelIcon';

const ModalMemoAddContainer = ({
  domain,
  isEditMemo,
  note,
  name,
  loginUrl,
  onCancel,
  onChange,
  onSubmit,
  picture,
  t,
  isChanged,
  isloading,
  editAccountProccessing,
  isShared,
  isExternalAccount,
}) => (
  <>
    <h1 className={classNames({
      [Styles.title]: true,
    })} style={{ display: 'flex' }}>
      { isEditMemo ? 'Edit note' : 'Add note'}
      <CancelIcon onCancel={onCancel} />
    </h1>
    <form
      action="/"
      className={`${Styles.whiteBackground}`}
      method="post"
      onSubmit={onSubmit}
    >
      <div className={Styles.form}>
        <div className={classNames({
          [Styles.iconHeaderWrapper]: true,
          [Styles.noBg]: true,
        })}>
          <Avatar
            domain={domain}
            loginUrl={loginUrl}
            name={name}
            picture={picture}
          />
        </div>
        <p className={`${Styles.subTitle} ${Styles._textCenter}`}>
          {`Add a note for this ${name} account`}
        </p>
        <Input
          name='note'
          placeholder='Add note...'
          maxHeight={true}
          onChange={onChange}
          readOnly={isShared && isExternalAccount}
          t={t}
          type='textarea'
          value={note}
        />
        <p className={Styles.hint}>{`${200 - note.length} characters left`}</p>
      </div>
      <div
        className={`${Styles.actions} ${Styles._squeezedAltered} ${Styles.fixedButtons}`}
        style={{ justifyContent: 'right' }}
      >
        <Button
          className={classNames({
            [Styles.button]: true,
            [Styles.notDisabled]: isChanged,
            [Styles.cancelMarginLeft]: true,
          })}
          disabled={!isChanged}
          loading={editAccountProccessing}
          type="submit"
          value={t('global.save')}
        />
      </div>
    </form>
  </>
);

export default ModalMemoAddContainer;
