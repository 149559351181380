/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { TUTORIAL_ADD_CREDENTIAL, TUTORIAL_SORT_CREDENTIALS, tutorials } from 'constants/ids';
import Validator from '@nettoken/validator';
import { hasCompletedAddCredentialTutorial } from 'main/vault/credentials';
import { store } from 'reducers/store';
import percentageRegex from 'percentage-regex';
import { $, getDocumentRelativeCoordinates } from '@nettoken/helpers';
import { DASHBOARD_TUTORIAL_STEPS } from '../../constants/ids';
import { RXStopTour, RXTutorial } from './reduxActions';
import { hasDoneTutorial } from './reduxState';

/**
 * @param {integer} parentHeight
 * @param {integer} parentWidth
 * @param {object} hintCoords
 *
 * @returns {string}
 */
const coordsToTransformString = (parentHeight, parentWidth, hintCoords) => {
  const translate = { ...hintCoords };
  let transform = '';

  Object.keys(translate).forEach(key => {
    let str = translate[key];

    const pctgs = str.match(percentageRegex());
    if (Array.isArray(pctgs)) {
      pctgs.forEach(pct => {
        const regex = new RegExp(pct, 'g');
        const pctInt = Validator.strToInt(pct.substr(0, pct.length - 1));

        let value = 0;
        switch (key) {
          case 'left':
            value = parentWidth * (pctInt / 100);
            break;

          case 'top':
            value = parentHeight * (pctInt / 100);
            break;

          // no default
        }

        value += 'px';

        str = str.replace(regex, value);
      });
    }

    const space = transform.length ? ' ' : '';
    const axis = ['left', 'right'].includes(key) ? 'X' : 'Y';
    transform += `${space}translate${axis}(${str})`;
  });

  return transform;
};

/**
 * @param {string} [id]
 * @param {boolean} [isFixed=false]
 *
 * @returns {object}
 */
const getParentCoords = (id, isFixed) => {
  const node = id ? $(`#${id}`) : document.documentElement;
  if (!node) return {};
  return getDocumentRelativeCoordinates(node, isFixed);
};

/**
 * @param {integer} [parentLeft=0]
 * @param {integer} [parentTop=0]
 * @param {string} [transform]
 * @param {integer} [zIndex]
 * @param {any} [coords]
 *
 * @returns {object}
 */
const getStyle = (parentLeft, parentTop, transform, zIndex) => {
  const styles = {
    left: `${parentLeft || 0}px`,
    top: `${parentTop || 0}px`,
  };

  if (transform) {
    styles.transform = transform;
  }

  if (zIndex !== undefined) {
    styles.zIndex = zIndex;
  }

  return styles;
};

export const forceAnimation = () => dispatch => dispatch(RXTutorial('_forceAnimation', true));

/**
 * @param {object} hint
 *
 * @returns {object}
 */
export const getHintStyle = hint => {
  const parentCoords = getParentCoords(hint.id, hint.isFixed);
  const transform = coordsToTransformString(
    parentCoords.height,
    parentCoords.width,
    hint.coords,
  );
  const style = getStyle(parentCoords.left, parentCoords.top, transform, hint.zIndex);
  return style;
};

/**
 * Hides all tutorials but does not mark them as completed.
 * Used to clear interface when throwing errors.
 */
export const hideAllTutorials = () => dispatch => {
  tutorials.forEach(name => dispatch(hideTutorial(name, true)));
};

/**
 * @param {string} name
 * @param {boolean} [notCompleted] Keep the tutorial marked as incomplete?
 */
export const hideTutorial = (name, notCompleted) => (dispatch, getState) => {
  const state = getState();
  const { activeHints: prevActiveHints } = state.tutorial;
  let { hints } = state.tutorial;
  let index = -1;
  for (let i = 0; i < hints.length; i += 1) {
    if (hints[i].name === name) {
      index = i;
      break;
    }
  }
  if (index !== -1) {
    hints = [
      ...hints.slice(0, index),
      ...hints.slice(index + 1),
    ];
    dispatch(RXTutorial('hints', hints));
  }

  if (!notCompleted) {
    dispatch(markAsComplete(name));
  }

  const nextActiveHints = prevActiveHints.filter(hint => hint !== name);
  if (name === DASHBOARD_TUTORIAL_STEPS) {
    dispatch(RXStopTour());
  }
  dispatch(RXTutorial('activeHints', nextActiveHints));
};

/**
 * Called upon authentication. Checks which tutorials might have been already
 * completed and marks them.
 */
export const initialiseTutorials = () => (dispatch, getState) => {
  const { data: groups, unsortedGroupId } = getState().groups;

  const checkHasSortedAnyCredentials = () => {
    const unsortedGroup = groups[unsortedGroupId];
    if (unsortedGroup) {
      const unsortedCredentials = unsortedGroup.apps.length;
      if (unsortedCredentials === 0) {
        dispatch(markAsComplete(TUTORIAL_SORT_CREDENTIALS));
      }
    }
  };

  if (dispatch(hasCompletedAddCredentialTutorial())) {
    dispatch(markAsComplete(TUTORIAL_ADD_CREDENTIAL));
    checkHasSortedAnyCredentials();
  }
};

/**
 * @param {string} name
 */
export const markAsComplete = name => (dispatch, getState) => {
  if (!dispatch(hasDoneTutorial(name)) && tutorials.includes(name)) {
    const completed = [
      ...getState().tutorial.completed,
      name,
    ];
    dispatch(RXTutorial('completed', completed));
  }
};

/**
 * @param {object} [args={}] See `showTutorial()` for signature.
 */
export const render = (args = {}) => (dispatch, getState) => {
  const { name } = args;
  // Node already exists, update it.
  let { activeHints } = getState().tutorial;
  if (activeHints.includes(name)) {
    const element = $(`#${name}`);
    if (!element) return;
    const style = getHintStyle(args);
    Object.keys(style).forEach(key => {
      element.style[key] = style[key];
    });
  }
  else {
    activeHints = [
      ...activeHints,
      name,
    ];
    dispatch(RXTutorial('activeHints', activeHints));
  }
};

/**
 * @param {object} args
 * @property {object} coords Containing any of the 'left', 'right', 'top', 'bottom' keys.
 * Should have a pair for x and y axis. Values are any valid CSS values for positioning.
 * Defines the relative position of the hint respective to the node.
 * @property {string} direction
 * @property {string} id Id of the node we want to attach this to.
 * @property {boolean} [isFixed] Fix the element so it doesn't move with the scroll?
 * @property {boolean} [light]
 * @property {string} name Unique tutorial name.
 * @property {string} text
 * @property {integer} [zIndex]
 */
export const showTutorial = args => (dispatch, getState) => {
  const { hints } = getState().tutorial;

  let exists = false;
  // Do not create duplicate nodes.
  for (let i = 0; i < hints.length; i += 1) {
    if (hints[i].name === args.name) {
      exists = true;
      break;
    }
  }

  if (!exists) {
    const value = [...hints, args];
    dispatch(RXTutorial('hints', value));
  }
  else if (exists) {
    if (args.name == DASHBOARD_TUTORIAL_STEPS) {
      // const filteredHints = hints.filter(x => x.id != args.id);
      const value = [args];
      dispatch(RXTutorial('hints', value));
    }
    else {
      const filteredHints = hints.filter(x => x.id != args.id);
      const value = [...filteredHints, args];
      dispatch(RXTutorial('hints', value));
    }
  }

  dispatch(render(args));
};

export const stopForcedAnimation = () => dispatch => dispatch(RXTutorial('_forceAnimation', false));

/**
 * Moves all tutorial nodes to follow their parent nodes when we resize the screen.
 */
export const tutorialFollowNodes = () => {
  const { hints } = store.getState().tutorial;
  hints.forEach(hint => store.dispatch(render(hint)));
};
