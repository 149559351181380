/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { i18nRenderLines, noop } from '@nettoken/helpers';
import Input from 'Input';
import Styles from './style.css';

class SidebarToggleContainer extends React.Component {
  renderInput(placeholder) {
    return (
      <Input
        className={Styles.input}
        error={this.props.error}
        name={this.props.name}
        onChange={this.props.onChange}
        placeholder={placeholder}
        t={this.props.t}
        type={this.props.type}
        value={this.props.value} />
    );
  }

  renderToggle() {
    return (
      <React.Fragment>
        <input
          checked={this.props.value}
          className={Styles.checkboxInput}
          id={this.props.name || null}
          onChange={this.props.onChange}
          type="checkbox"
          value={this.props.name} />

        <label
          className={Styles.checkboxWrapper}
          htmlFor={this.props.name || null}>
          <div className={Styles.checkboxToggle}>
            <span
              aria-hidden={true}
              className={Styles.checkboxLabel}>
              {this.props.t(`global.${this.props.value ? 'yes' : 'no'}`)}
            </span>
          </div>
        </label>
      </React.Fragment>
    );
  }

  render() {
    return (
      <div className={Styles.wrapper}>
        <h2 className={Styles.title}>
          {this.props.title}
        </h2>
        <p className={Styles.text}>
          {i18nRenderLines(this.props.text)}
        </p>
        {
          this.props.value !== undefined &&
          this.props.type === 'checkbox' &&
          this.renderToggle()
        }
        {
          this.props.type !== 'checkbox' &&
          this.renderInput(this.props.placeholder)
        }
      </div>
    );
  }
}

SidebarToggleContainer.defaultProps = {
  error: '',
  name: '',
  onChange: noop,
  placeholder: '',
  text: '',
  title: '',
  type: 'checkbox',
};

SidebarToggleContainer.propTypes = {
  error: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  /** i18n translate method */
  t: PropTypes.func.isRequired,
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  type: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
};

export default SidebarToggleContainer;
