import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Tooltip from 'Tooltip';
import classNames from 'classnames';
import Styles from './Styles.css';


class AppsPreviewContainer extends Component {
  render() {
    const {
      apps,
      onMouseEnter,
      onMouseLeave,
      currentField,
      selectedField,
      selectedOrder,
      currentOrder,
      onSortChange,
      columns,
      onTouch,
      showEditModal,
      isRemoveAccount,
      isMoveAccount,
    } = this.props;

    const displayData = () => {
      const dataJsx = this.props.split ?
        this.props.splitAccounts(apps, selectedField, selectedOrder) :
        this.props.mapObject(apps, this.props.renderRow, selectedField, selectedOrder);
      return dataJsx;
    };
    return (
      <div className={Styles.importWrapper} style={{ minHeight: '610px' }} >
        <table className={classNames({
          [Styles.table]: 1,
          [Styles.isUsername]: this.props.showUsername,
          [Styles.removeAccountsTable]: isRemoveAccount || isMoveAccount,
        })}
          cellPadding="5"
        >
          <thead className={Styles.tableHeader}>
            <tr>
              {columns.map((column, id) => {
                if (column.name === 'dashboard' && !isRemoveAccount) return null;
                return (
                  <Tooltip content={column.tooltip}>
                    <th key={id}
                      onClick={column.sortable ? e => onSortChange(column.name) : null}
                      onMouseEnter={column.sortable ? e => onMouseEnter(column.name) : null}
                      onMouseLeave={column.sortable ? e => onMouseLeave(column.name) : null}
                    >
                      { column.label }
                      {column.sortable &&
                        (currentField === column.name || selectedField === column.name) &&
                        <span
                          onClick={e => onSortChange(column.name)}>
                          <i className={classNames({
                            [Styles[`${selectedOrder || currentOrder}Arrow`]]: true,
                          })}>
                          </i>
                        </span>
                      }
                    </th>
                  </Tooltip>
                );
              })}
            </tr>
          </thead>
          <tbody className={classNames({
            [Styles.tableBody]: 1,
            [Styles.removePadding]: isRemoveAccount,
          })} >
            { Object.keys(apps).length > 0 ? displayData() : (
              <tr>
                <td colSpan={5} style={{ textAlign: 'center', height: '145px' }}>No Results</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  }
}

export default AppsPreviewContainer;
