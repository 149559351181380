/*
 * Copyright (C) 2018-2019 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { SOURCE_WEB_APP } from 'constants/events';
import {
  MODAL_ACCOUNT_DELETE,
  MODAL_ACCOUNT_EDIT,
  MODAL_ACCOUNT_SHARE,
  MODAL_STOP_SHARING,
  MODAL_UNSAVED_CHANGES,
  MODAL_SHARE_RULES,
} from 'constants/modal';
import { ID_SHOW_SHARED_INFO } from 'constants/ids';
import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import {
  addEvent,
  eventKey,
  eventTarget,
  findNode,
  JWTDecode,
  REGULAR_KEYS,
  removeEvent,
} from '@nettoken/helpers';
import { isExtensionConnected } from 'utils/extension';
import { processSocketEvent, SM_IS_CONNECTED } from '@nettoken/socket';
import Validator from '@nettoken/validator';
import { Credential, LABEL_UNSORTED_GROUP } from '@nettoken/models';

import { catchUIError, hideUIError, showUIError } from 'main/error';
import { getError } from 'main/error/reduxState';
import { hideModal, showModal } from 'main/modal';
import { setTrackingPermission } from 'main/preferences';
import {
  deleteCredential,
  fetchActivityLogs,
  moveCredentialToGroupId,
  updateCredential,
} from 'main/vault/credentials';
import { Request } from 'utils/request';
import { getOneCredential } from 'main/vault/credentials/reduxState';
import { getGroupIdFromGroupInputField } from 'main/vault/groups';
import { getAllGroupDropdown, getGroupFromName, readGroup } from 'main/vault/groups/reduxState';
import withOverlayAction from 'Overlay/withAction';
import { signOutUser } from 'main/user';
import { getSharedCredentialData, removeSharedByAccounts } from 'main/sharedAccounts';
import { getAllState } from '../../../../main/user/reduxState';
import Container from './container';
import { DASHBOARD_SPACES } from '../../../../constants/endpoints';
import { MODAL_MOVE_ACCOUNT_DASHBOARD, MODAL_OFFLINE_MSG, MODAL_USER_DETAIL } from '../../../../constants/modal';
import { RxEditAccountProccessing, RXModalData, RXToasterShow } from '../../../../main/modal/reduxActions';
import { RXChangeActiveDashboard, RXEditApiError } from '../../../../main/ui/reduxActions';
import { getApiErrorStatus } from '../../../../main/ui/reduxState';
import { doSearch } from '../../../../main/search';
import { TEXT_LOG_INFO } from '../../../../constants/ids';

class ModalAccountEditComponent extends React.Component {
  constructor(props) {
    super(props);
    const tmpGID = 'unsaved' in this.props.data && !('isEditMemo' in this.props.data.unsaved) ? this.props.data.unsaved.groupId : this.props.data.groupId;
    const groupData = this.props.readGroup(tmpGID);
    const isUnsorted = groupData.label === LABEL_UNSORTED_GROUP;
    const tmpModalData = 'unsaved' in this.props.data && !('isEditMemo' in this.props.data.unsaved) ? this.props.data.unsaved : this.props.data;
    let groupState = isUnsorted ? '' : (groupData.label || '');
    if ('currentGroupBackUp' in this.props.data) {
      groupState = this.props.data.currentGroupBackUp;
    }
    this.state = {
      activityLogs: {},
      isChange: 'unsaved' in this.props.data && !('isEditMemo' in this.props.data.unsaved),
      year: '',
      loading: true,
      email: tmpModalData.email,
      group: groupState,
      currentGroup: groupState,
      id: tmpModalData.id,
      name: tmpModalData.name,
      note: tmpModalData.note,
      password: tmpModalData.password,
      phone: tmpModalData.phone,
      showPassword: false,
      showLogs: false,
      username: tmpModalData.username,
      sharingStatus: {},
      disableSharing: {},
      deleteProcessing: false,
      hasChanges: false,
      dashboard: '',
      oldDashboard: '',
      dashboardDropdown: '',
      emailInvalid: false,
      groupId: tmpGID,
      currentGroupBackUp: groupState,
    };

    this.lastFieldType = tmpModalData.phone && tmpModalData.username && tmpModalData.phone.length > 0 && tmpModalData.username.length === 0 ? 'phone' : 'username';

    this.getLabelFromGroupId = this.getLabelFromGroupId.bind(this);
    this.testpopup = this.testpopup.bind(this);
    this.handleAction = this.handleAction.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.hasChanged = this.hasChanged.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggleView = this.toggleView.bind(this);
    this.togglePassword = this.togglePassword.bind(this);
    this.onMouseEnterSharingStatus = this.onMouseEnterSharingStatus.bind(this);
    this.onMouseLeaveSharingStatus = this.onMouseLeaveSharingStatus.bind(this);
    this.handleSharingAccount = this.handleSharingAccount.bind(this);
    this.onClickDeleteHandler = this.onClickDeleteHandler.bind(this);
    this.declineSharingInvitation = this.declineSharingInvitation.bind(this);
    this.getActivityLogs = this.getActivityLogs.bind(this);
    this.onClickUser = this.onClickUser.bind(this);
    this.getUserAccount = this.getUserAccount.bind(this);
    this.getLabelFromDashboardId = this.getLabelFromDashboardId.bind(this);
    this.moveApp = this.moveApp.bind(this);
  }

  mapNumbersToMonth(num) {
    switch (num) {
      case 0:
        return 'Jan';
      case 1:
        return 'Feb';
      case 2:
        return 'Mar';
      case 3:
        return 'Apr';
      case 4:
        return 'May';
      case 5:
        return 'Jun';
      case 6:
        return 'Jul';
      case 7:
        return 'Aug';
      case 8:
        return 'Sep';
      case 9:
        return 'Oct';
      case 10:
        return 'Nov';
      case 11:
        return 'Dec';
      default:
        return '';
    }
  }

  getActivityLogs() {
    const obj = [];
    let y;
    fetchActivityLogs(this.state.id).then(activityLogs => {
      if (activityLogs) {
        Object.keys(activityLogs).forEach(year => {
          const d = new Date(activityLogs[year].createdAt * 1000);
          y = d.getFullYear();
          const k = `${d.getDate()} ${this.mapNumbersToMonth(d.getMonth())} ${y}`;
          if (obj[k] === undefined) {
            obj[k] = [activityLogs[year]];
          }
          else {
            obj[k].push(activityLogs[year]);
          }
        });
        const arrayToString = JSON.stringify(Object.assign({}, obj)); // convert array to string
        const stringToJsonObject = JSON.parse(arrayToString);
        this.setState({
          year: y,
        });
        this.setState({
          activityLogs: stringToJsonObject,
        });
      }
    }).finally(() => {
      this.setState({
        loading: false,
      });
    });
  }

  componentDidMount() {
    this.props.addOverlayAction('modal', () => this.handleAction(() => this.props.hide()));
    addEvent(document, 'keydown', this.handleKeyDown);
    const dashboardValue = 'dashboardSpaceId' in this.props.data ?
      this.props.data.dashboardSpaceId :
      null;
    const dashboardObj = Object.keys(this.props.dashboardList)
      .filter(item => this.props.dashboardList[item].id === dashboardValue);
    let dashboardName = '';
    if (dashboardObj.length > 0) {
      dashboardName = this.props.dashboardList[dashboardObj[0]].name;
    }
    this.setState({
      dashboard: dashboardName === '' ?
        `${this.props.user.profile.name} (home)` :
        dashboardName,
      oldDashboard: dashboardName,
    });
    if (!this.props.data.externalAccount) {
      this.getActivityLogs();
    }
    const Dashboarddropdown = [];
    if (
      this.props.user.profile.name !== dashboardName &&
      dashboardName !== ''
    ) {
      Dashboarddropdown.push(this.props.user.profile.name);
    }
    Object.values(this.props.dashboardList).map(dashboard => {
      if (dashboard.name !== dashboardName) {
        if (
          !this.props.data.externalAccount && !this.props.data.shared &&
          (!('externalDashboard' in dashboard) || dashboard.externalDashboard)
        ) {
          Dashboarddropdown.push(dashboard.name);
        }

        if ((this.props.data.externalAccount || this.props.data.shared) &&
        (!('usersSharedWith' in dashboard) || dashboard.usersSharedWith.length == 0)) {
          Dashboarddropdown.push(dashboard.name);
        }
      }
    });
    this.setState({ dashboardDropdown: Dashboarddropdown });
  }

  componentWillUnmount() {
    this.props.hideUIError();
    removeEvent(document, 'keydown', this.handleKeyDown);
  }

  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.startTime !== this.state.startTime) {
      this.setState({ startTime: nextProps.startTime });
    }
    if (nextProps.data.updated_fields) {
      this.setState({
        email: nextProps.data.updated_email,
        username: nextProps.data.updated_username,
        note: nextProps.data.updated_note,
        password: nextProps.data.updated_password,
      });
      this.props.updateModalData({
        ...nextProps.data,
        email: nextProps.data.updated_email,
        username: nextProps.data.updated_username,
        note: nextProps.data.updated_note,
        password: nextProps.data.updated_password,
        updated_email: '',
        updated_username: '',
        updated_note: '',
        updated_password: '',
        updated_fields: false,
      });
    }
  }

  // eslint-disable-next-line
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.data.accepted !== this.props.data.accepted) {
      this.props.getSharedCredentials(this.props.data.id);
    }
  }

  onMouseEnterSharingStatus(user) {
    this.setState({
      sharingStatus: {
        [user]: true,
      },
    });
  }

  onMouseLeaveSharingStatus(user) {
    this.setState({
      sharingStatus: {
        [user]: false,
      },
    });
  }

  async editApp(groupId, newGroupId, closeModal = false) {
    this.props.setEditAccountProccessing(true);
    if (!navigator.onLine) {
      this.props.showToaster({
        status: 'open',
        type: 'error',
        value: 'Oops, could not save! Please try again',
      });
      return;
    }
    const moveCredential = (groupId != newGroupId);
    const { id } = this.props.data;
    const prevData = this.props.getCredential(id);
    const selectedDashboardObj = Object.keys(this.props.dashboardList)
      .filter(item => this.props.dashboardList[item].name === this.state.dashboard);
    const credential = new Credential({
      ...prevData,
      email: this.state.email,
      groupId: newGroupId,
      name: this.state.name,
      note: this.state.note,
      password: this.state.password,
      phone: this.state.phone,
      username: this.state.username,
      dashboardSpaceId: selectedDashboardObj.length > 0 ?
        this.props.dashboardList[selectedDashboardObj[0]].id :
        null,
    });
    const errors = credential.validate();
    if (errors) {
      this.props.catchUIError(errors);
      return;
    }
    await this.props.updateCredential({
      credential,
      eventSource: SOURCE_WEB_APP,
      id,
      tracking: this.props.isTracking,
      moveCredential,
      prevGroupId: groupId,
      ...(groupId !== newGroupId && { groupId: newGroupId }),
    });

    if (!this.props.data.externalAccount) {
      this.getActivityLogs();
    }

    this.setState({
      isChange: false,
      currentGroup: this.state.group,
    });

    if (this.state.oldDashboard != this.state.dashboard) {
      if (selectedDashboardObj.length) {
        this.props.search(this.props.dashboardList[selectedDashboardObj[0]].id);
      }
      else {
        this.props.search('');
      }
    }

    if (!closeModal) {
      this.props.showModal(MODAL_ACCOUNT_EDIT, {
        ...this.props.data,
        email: this.state.email,
        name: this.state.name,
        note: this.state.note,
        password: this.state.password,
        phone: this.state.phone,
        username: this.state.username,
      });
    }
    setTimeout(async () => {
      this.props.setEditAccountProccessing(false);
      if (this.props.getApiErrorStatus()) {
        const credentialOld = new Credential({
          ...prevData,
        });
        await this.props.updateCredential({
          credential: credentialOld,
          eventSource: SOURCE_WEB_APP,
          id,
          tracking: false,
          moveCredential,
          pushToServer: false,
          groupId,
        });
        this.setState({
          isChange: true,
        });
        this.props.data.username = prevData.username;
        this.props.data.email = prevData.email;
        this.props.data.name = prevData.name;
        this.props.data.password = prevData.password;
        this.props.data.phone = prevData.phone;
        this.props.data.groupId = prevData.groupId;
        this.props.setEditApiError(false);
        this.setState({ hasChanges: true });
      }
    }, 3000);
  }

  async getGroupId() {
    const { dispatch } = this.props;
    const { id } = this.props.data;
    const app = this.props.getCredential(id);
    const group = this.props.readGroup(app.groupId);
    await dispatch(getGroupIdFromGroupInputField(group, this.state.currentGroup, false));
    return group.id;
  }

  async getNewGroupId() {
    const { dispatch } = this.props;
    const group = await dispatch(getGroupFromName(this.state.group));
    const groupId = await dispatch(getGroupIdFromGroupInputField(group, this.state.group, true, 'group'));
    if (groupId && this.state.group !== '') {
      return groupId;
    }
    return this.props.unsortedId;
  }

  getLabelFromGroupId(id) {
    const { label } = this.props.readGroup(id);
    return label;
  }

  getLabelFromDashboardId(id) {
    if (id === null) return 'Home Dashboard';
    if (!(id in this.props.dashboardList)) return 'Deleted Dashboard';
    return `${this.props.dashboardList[id].name} Dashboard`;
  }

  groupLogsByDate(logs) {
    return logs.reduce((acc, log) => {
      const date = new Date(log.createdAt * 1000);
      const year = date.getFullYear();
      if (!acc[year]) {
        acc[year] = [{
          ...log,
          createdAt: date,
        }];
        return acc;
      }
      acc[date.getFullYear()].push({
        ...log,
        createdAt: date,
      });
      return acc;
    }, {});
  }

  async handleAction(callback) {
    const { dispatch } = this.props;
    // const groupId = await Promise.all([this.getNewGroupId()]);
    this.props.data.unsaved = this.state;
    if (this.hasChanged() && !this.state.emailInvalid) {
      dispatch(showModal(MODAL_UNSAVED_CHANGES, {
        onCancel: () => {
          callback();
        },
        onConfirm: e => this.handleSubmit(e, true, callback),
        currentData: {
          ...this.props.data,
          currentGroupBackUp: this.state.currentGroupBackUp,
        },
      }));
      return;
    }

    if (!this.props.editAccountProccessing) {
      callback();
    }
  }

  async handleChange(event) {
    const { value } = eventTarget(event);
    let { name } = eventTarget(event);
    this.setState({ isChange: true });
    if (name === 'username' && Validator.phone(value)) {
      name = 'phone';
    }
    if ((name === 'username' || name === 'phone') && this.lastFieldType !== name) {
      this.setState({
        [this.lastFieldType]: '',
      });
      this.lastFieldType = name;
    }
    if (name == 'email') {
      if (value != '' && value
        .match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
      ) {
        this.props.hideUIError(name);
        this.setState({
          emailInvalid: false,
        });
      }
      else if (value.trim() == '') {
        this.props.hideUIError(name);
        this.setState({
          emailInvalid: false,
        });
      }
      else {
        this.props.showUIError('Must be a valid email', name);
        this.setState({
          emailInvalid: true,
        });
      }
    }
    this.setState({ [name]: value });
    if (name === 'dashboard') {
      const Dashboarddropdown = [];
      if (value !== this.props.user.profile.name) {
        Dashboarddropdown.push(this.props.user.profile.name);
      }
      Object.values(this.props.dashboardList).map(dashboard => {
        if (dashboard.name !== value) {
          Dashboarddropdown.push(dashboard.name);
        }
      });
      this.setState({ dashboardDropdown: Dashboarddropdown });
    }
    if (name === 'group') {
      this.setState({
        currentGroupBackUp: value,
      });
    }
  }

  hasChanged() {
    const {
      group,
      currentGroup,
    } = this.state;
    if (group !== currentGroup) return true;
    const changeKeys = ['name', 'email', 'username', 'phone', 'password', 'note'];
    return changeKeys.some(key => this.props.data[key] !== this.state[key]);
  }

  handleKeyDown(event) {
    const { tagName } = eventTarget(event);
    if (tagName === 'INPUT') return;

    const { dispatch } = this.props;

    switch (eventKey(event)) {
      // case REGULAR_KEYS.BACKSPACE:
      case REGULAR_KEYS.DELETE:
        event.preventDefault();
        dispatch(showModal(MODAL_ACCOUNT_DELETE, this.props.data));
        break;

      // no default
    }
  }

  testpopup() {
    const { dispatch, data, sharedAccounts } = this.props;
    if (data.shared && sharedAccounts[data.id] && !data.externalAccount) {
      dispatch(showModal(MODAL_ACCOUNT_SHARE, this.props.data));
    }
    else {
      dispatch(showModal(MODAL_SHARE_RULES, this.props.data));
    }
  }

  handleSubmit(event, closeModal = false) {
    event.preventDefault();

    this.props.hideUIError();

    Promise.all([this.getGroupId(), this.getNewGroupId()])
      .then(([groupId, newGroupId]) => this.editApp(groupId, newGroupId, closeModal));
  }

  togglePassword() {
    this.setState({ showPassword: !this.state.showPassword });
  }

  async toggleView(value) {
    if (isExtensionConnected()) {
      this.setState({
        showLogs: value,
      });
      return;
    }

    try {
      const isSocketConnected = await processSocketEvent({ event: SM_IS_CONNECTED });
      if (isSocketConnected) {
        this.setState({
          showLogs: value,
        });
      }
    }
    catch (error) {
      this.props.signOutUser({ expired: true });
      console.log(error);
    }
  }

  async handleSharingAccount(id, phone, name, isAccepted) {
    const { data } = this.props;
    data.unshareWithUserIds = id;
    data.ishidepopup = this.props.sharedAccounts[this.props.data.id][0].responded;
    data.sharedWithName = name;
    data.isSharedWithAccepted = true;
    this.props.dispatch(showModal(MODAL_STOP_SHARING, data));
  }

  onClickDeleteHandler() {
    if (!navigator.onLine) {
      this.setState({ deleteProcessing: true });
      this.props.showToaster({
        status: 'open',
        type: 'error',
        value: 'Oops, could not save! Please try again',
      });
      setTimeout(() => {
        this.setState({ deleteProcessing: false });
      }, 2000);
      return;
    }
    const { data } = this.props;
    this.props.showModal(MODAL_ACCOUNT_DELETE, data);
  }

  onClickUser(account) {
    const userAccountDetails = [];
    const sharedAccountDetail = {};
    const sharedByAccounts = [];
    Object.keys(this.props.sharedAccounts).forEach(accId => {
      const tmpAccountDetails = this.props.sharedAccounts[accId].find(acc => acc.id === account.id);
      if (tmpAccountDetails) {
        Object.keys(this.props.credentials.data).forEach(credId => {
          let sharedDashboard = false;
          if (
            'dashboardSpaceId' in this.props.credentials.data[credId] &&
            this.props.credentials.data[credId].dashboardSpaceId &&
            'shared' in this.props.dashboardList[this.props.credentials.data[credId].dashboardSpaceId] &&
            this.props.dashboardList[this.props.credentials.data[credId].dashboardSpaceId].shared &&
            this.props.dashboardList[this.props.credentials.data[credId].dashboardSpaceId]
              .usersSharedWith.filter(x => x.id == account.id)
              .length > 0
          ) {
            sharedDashboard = true;
          }
          if (credId === accId && !sharedDashboard) {
            userAccountDetails.push(this.props.credentials.data[credId]);
            sharedAccountDetail[credId] = tmpAccountDetails;
          }
        });
      }
    });
    Object.keys(this.props.credentials.data).forEach(key => {
      if (this.props.credentials.data[key].sharedByUserId === account.id) {
        let sharedDashboard = false;
        if (
          'dashboardSpaceId' in this.props.credentials.data[key] &&
          this.props.credentials.data[key].dashboardSpaceId &&
          'shared' in this.props.dashboardList[this.props.credentials.data[key].dashboardSpaceId] &&
          this.props.dashboardList[this.props.credentials.data[key].dashboardSpaceId].shared
        ) {
          sharedDashboard = true;
        }
        if (!sharedDashboard) {
          sharedByAccounts.push(this.props.credentials.data[key]);
          if (
            (('phone' in account && account.phone === '') || !('phone' in account)) &&
            'sharedByUserPhone' in this.props.credentials.data[key]
          ) {
            account.phone = this.props.credentials.data[key].sharedByUserPhone;
          }
        }
      }
    });
    this.props.dispatch(showModal(MODAL_USER_DETAIL, {
      userAccountDetails,
      account,
      oldData: this.props.data,
      oldModal: MODAL_ACCOUNT_EDIT,
      sharedAccountDetail,
      sharedByAccounts,
    }));
  }

  getUserAccount() {
    const credentialId = this.props.data.id;
    let userId = '';
    let userName = '';
    Object.keys(this.props.credentials.data).forEach(key => {
      if (key === credentialId) {
        userId = this.props.credentials.data[credentialId].sharedByUserId;
        userName = this.props.credentials.data[credentialId].sharedByUserName;
      }
    });
    let userAccount = [];
    Object.keys(this.props.sharedAccounts).forEach(accId => {
      const tmpAccountDetails = this.props.sharedAccounts[accId].find(acc => acc.id === userId);
      if (tmpAccountDetails) {
        userAccount = tmpAccountDetails;
      }
    });
    if (userAccount.length === 0) {
      userAccount = {
        id: userId,
        name: userName,
        phone: '',
      };
    }
    return userAccount;
  }

  async declineSharingInvitation() {
    this.setState({ deleteProcessing: true });
    const params = {
      data: this.props.data,
      unshareWithUserIds: [],
    };

    try {
      const res = await this.props.removeSharedByAccounts(params);
      this.setState({ deleteProcessing: false });
      this.props.hide();
    }
    catch (error) {
      console.log(error, 'declineSharingInvitation');
    }
  }

  moveApp() {
    this.props.showModal(MODAL_MOVE_ACCOUNT_DASHBOARD, this.props.data);
  }

  render() {
    const {
      dispatch,
      sharedAccounts,
      data,
    } = this.props;
    let grp = this.state.group;
    if (this.state.group === 'Pending Invitations') {
      grp = '';
    }
    let showsss = true;
    if (
      sharedAccounts[data.id] &&
      sharedAccounts[data.id].filter(x => !x.sharedByDashboard).length == 0
    ) {
      showsss = false;
    }

    return (
      <Container
        sharedByUserName={data.sharedByUserName}
        inoutchange={this.state.isChange}
        sharedCredentialData={sharedAccounts[data.id]}
        show={showsss}
        profilename={this.props.user.profile.name.trim()}
        profileId={this.props.user.userId}
        isShared={data.shared}
        isExternalAccount={data.externalAccount}
        accepted={data.accepted}
        year1={this.state.year}
        activityLogs={this.state.activityLogs}
        cookieUrl={data.cookieUrl}
        domain={data.domain}
        onclicktest={this.testpopup}
        email={this.state.email}
        getError={this.props.getError}
        getLabelFromGroupId={this.getLabelFromGroupId}
        getLabelFromDashboardId={this.getLabelFromDashboardId}
        group={grp}
        groupDropdown={this.props.getGroupDropdown()}
        isTracking={this.props.isTracking}
        loginUrl={data.loginUrl}
        loadingLogs={this.state.loading}
        name={this.state.name}
        note={this.state.note}
        onCancel={() => this.handleAction(() => this.props.hide())}
        onChange={this.handleChange}
        onClickButtonDelete={this.onClickDeleteHandler}
        deleteProcessing={this.state.deleteProcessing}
        onClickPasswordToggle={this.togglePassword}
        onSubmit={this.handleSubmit}
        password={this.state.password}
        phone={this.state.phone}
        picture={this.props.data.picture}
        privacyUrl={this.props.data.privacyUrl}
        setTrackingPermission={this.props.setTrackingPermission}
        showPassword={this.state.showPassword}
        showLogs={this.state.showLogs}
        t={this.props.t}
        toggleView={this.toggleView}
        username={this.state.username}
        onMouseEnterSharingStatus={this.onMouseEnterSharingStatus}
        onMouseLeaveSharingStatus={this.onMouseLeaveSharingStatus}
        sharingStatus={this.state.sharingStatus}
        disableSharing={this.state.disableSharing}
        handleSharingAccount={this.handleSharingAccount}
        onClickUser={this.onClickUser}
        getUserAccount={this.getUserAccount}
        editAccountProccessing={this.props.editAccountProccessing}
        dashboard={this.state.dashboard}
        dashboardDropdown={this.state.dashboardDropdown}
        moveApp={this.moveApp}
        dashboards={Object.keys(this.props.dashboardListWithoutInvitations).length > 0}
        sharedDashboard={this.props.sharedDashboard}
        externalDashboard={this.props.externalDashboard}
        usersSharedWith={this.props.usersSharedWith}
        usersSharedWithWithoutLoggedInUser={this.props.usersSharedWithWithoutLoggedInUser}
        emailInvalid={this.state.emailInvalid}
        dispatch={this.props.dispatch}
      />
    );
  }
}

const mapStateToProps = state => {
  const jwt = JWTDecode(state.session.accessToken) || {};
  let sharedDashboard = false;
  let currentUnSortedGroupId = '';
  const currentApp = state.ui.modalData && 'currentApp' in state.ui.modalData ?
    state.credentials.data[state.ui.modalData.currentApp.id] :
    state.ui.modalData;
  if (state.ui.currentDashboard !== '') {
    Object.values(state.groups.data).map(grp => {
      if (
        grp.label === LABEL_UNSORTED_GROUP &&
        'dashboardSpaceId' in grp &&
        grp.dashboardSpaceId === state.ui.currentDashboard
      ) {
        currentUnSortedGroupId = grp.id;
      }
    });
    if (
      state.ui.currentDashboard &&
      'usersSharedWith' in state.ui.dashboards[state.ui.currentDashboard] &&
      state.ui.dashboards[state.ui.currentDashboard].usersSharedWith.length > 0 &&
      (
        !(currentApp.id in state.sharedAccounts) ||
        state.sharedAccounts[currentApp.id].filter(x => !x.sharedByDashboard).length == 0
      )
    ) {
      sharedDashboard = true;
    }
  }
  else {
    currentUnSortedGroupId = state.groups.unsortedGroupId;
  }
  return {
    data: currentApp || {},
    isTracking: state.preferences.tracking,
    // Needed to re-render when UI error changes.
    ui: state.ui,
    toaster: state.ui.toaster,
    user: {
      ...state.user,
      userId: jwt.sub,
    },
    credentials: state.credentials,
    sharedAccounts: state.sharedAccounts,
    statess: state,
    unsortedId: currentUnSortedGroupId,
    editAccountProccessing: state.ui.editAccountProccessing,
    dashboardList: state.ui.dashboards,
    dashboardListWithoutInvitations: Object.values(state.ui.dashboards).filter(x => (
      !('sharedByUserId' in x) && !('sharedByUserName' in x))),
    usersSharedWith:
    state.ui.currentDashboard && 'usersSharedWith' in state.ui.dashboards[state.ui.currentDashboard] ?
      state.ui.dashboards[state.ui.currentDashboard].usersSharedWith : [],
    usersSharedWithWithoutLoggedInUser:
    state.ui.currentDashboard && 'usersSharedWith' in state.ui.dashboards[state.ui.currentDashboard] ?
      state.ui.dashboards[state.ui.currentDashboard].usersSharedWith.filter(x => (
        x.name != state.user.profile.name)) : [],
    sharedDashboard,
    externalDashboard: state.ui.currentDashboard && 'externalDashboard' in state.ui.dashboards[state.ui.currentDashboard] &&
        state.ui.dashboards[state.ui.currentDashboard].externalDashboard,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
  catchUIError: e => dispatch(catchUIError(e)),
  getCredential: id => dispatch(getOneCredential(id)),
  getGroupDropdown: name => dispatch(getAllGroupDropdown(name)),
  getSharedCredentials: id => dispatch(getSharedCredentialData(id)),
  moveCredentialToGroupId: args => dispatch(moveCredentialToGroupId(args)),
  getError: id => dispatch(getError(id)),
  hide: () => {
    dispatch(RxEditAccountProccessing(false));
    return dispatch(hideModal());
  },
  hideUIError: name => dispatch(hideUIError(name)),
  readGroup: id => dispatch(readGroup(id)),
  setTrackingPermission: permission => dispatch(setTrackingPermission(permission)),
  updateCredential: args => dispatch(updateCredential(args)),
  signOutUser: args => dispatch(signOutUser({ pushToServer: false, ...args })),
  showModal: (name, data) => dispatch(showModal(name, data)),
  deleteCredential: args => dispatch(deleteCredential(args)),
  removeSharedByAccounts: params => dispatch(removeSharedByAccounts(params)),
  getAllState: () => dispatch(getAllState()),
  setEditAccountProccessing: status => dispatch(RxEditAccountProccessing(status)),
  setEditApiError: status => dispatch(RXEditApiError(status)),
  getApiErrorStatus: () => dispatch(getApiErrorStatus()),
  showToaster: args => dispatch(RXToasterShow(args)),
  search: query => {
    dispatch(RXChangeActiveDashboard(query));
    return dispatch(doSearch(query, 'dashboardSpaceId'));
  },
  showUIError: (message, forElement) => dispatch(showUIError(message, forElement)),
  updateModalData: args => dispatch(RXModalData(args)),
});

export default translate()(connect(
  mapStateToProps,
  mapDispatchToProps,
)(withOverlayAction(ModalAccountEditComponent)));
