/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { PRESET_THROTTLE_NETWORK, PRESET_AUTO_LOGOUT } from 'constants/presets';
import { RX_APP_INIT } from 'main/state/actionTypes';
import Reducer from 'reducers/factory';

import {
  RX_TOOLS_DRAG,
  RX_TOOLS_HIDE,
  RX_TOOLS_LARGE,
  RX_TOOLS_PRESETS,
  RX_TOOLS_SHOW,
  RX_TOOLS_SMALL,
  RX_TOOLS_VIEW,
} from './actionTypes';

const defaultState = () => ({
  active: false,
  minimised: false,
  position: {
    x: 0,
    y: 0,
  },
  presets: {
    [PRESET_THROTTLE_NETWORK]: 0,
    [PRESET_AUTO_LOGOUT]: true,
  },
  view: 1,
});

const reducer = new Reducer('tools', defaultState());

export default (state = reducer.defaultState(), action) => {
  switch (action.type) {
    case RX_APP_INIT:
      return reducer.merge(state, true);

    case RX_TOOLS_DRAG:
      return {
        ...state,
        position: {
          x: action.x,
          y: action.y,
        },
      };

    case RX_TOOLS_HIDE:
    case RX_TOOLS_SHOW:
      return {
        ...state,
        active: action.type === RX_TOOLS_SHOW,
      };

    case RX_TOOLS_LARGE:
    case RX_TOOLS_SMALL:
      return {
        ...state,
        minimised: action.type === RX_TOOLS_SMALL,
      };

    case RX_TOOLS_PRESETS:
      return {
        ...state,
        presets: action.presets,
      };

    case RX_TOOLS_VIEW:
      return {
        ...state,
        view: action.view,
      };

    default:
      return state;
  }
};
