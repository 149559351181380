/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
export const RX_CREDENTIALS_CREATE = 'RX_CREDENTIALS_CREATE';
export const RX_CREDENTIALS_DELETE = 'RX_CREDENTIALS_DELETE';
export const RX_CREDENTIALS_UPDATE = 'RX_CREDENTIALS_UPDATE';
export const RX_CREDENTIALS_REPLACE_ID = 'RX_CREDENTIALS_REPLACE_ID';
export const RX_CREDENTIALS_UPDATE_BATCH = 'RX_CREDENTIALS_UPDATE_BATCH';
export const RX_CREDENTIALS_UPDATE_FILTERED_LIST = 'RX_CREDENTIALS_UPDATE_FILTERED_LIST';
export const RX_SET_NEW_CREDENTIALS = 'RX_SET_NEW_CREDENTIALS';
export const RX_SET_UPDATE_USERS_SHARED_WITH = 'RX_SET_UPDATE_USERS_SHARED_WITH';
