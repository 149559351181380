/*
 * Copyright (C) 2018-2019 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import {
  DOWNWARD,
  URL_CREDENTIALS_SHARED,
  URL_CREDENTIALS_SHARED_INVITATIONS,
  URL_CREDENTIALS_SHARED_USERS,
} from 'constants/endpoints';
import { Request } from 'utils/request';
import { Credential } from '@nettoken/models';
import { deleteCredential, updateCredential } from 'main/vault/credentials';
import { getOneCredential } from 'main/vault/credentials/reduxState';
import { encryptCredential } from 'main/user';
import { RXSharedAccountsCreate, RXSharedAccountsDelete } from './reduxActions';


export const getSharedCredentialData = (id, token) => async (dispatch, getState) => {
  // const req = new Request(`${URL_CREDENTIALS_SHARED_USERS}?credentialId=${id}`);
  // const getData = await req.authorise(token)
  //   .get();
  let getData = {};
  try {
    getData = getState().credentials.data[id].usersSharedWith;
    if (getData && getData.length > 0) {
      dispatch(RXSharedAccountsCreate(id, getData));
    }
  }
  catch (error) {
    console.log('Error:', error);
  }
  return getData;
};

export const stopSharingAccount = params => async (dispatch, getState) => {
  const req = new Request(URL_CREDENTIALS_SHARED);
  return req.authorise().put(params)
    .then(res => {
      dispatch(RXSharedAccountsDelete(params));
      const state = getState().sharedAccounts;
      const { id } = params.credential;
      let shared = false;
      if (id in state) {
        shared = true;
      }
      const prevData = dispatch(getOneCredential(id));
      const credential = new Credential({
        ...prevData,
        shared,
      });
      dispatch(updateCredential({
        credential,
        id,
        pushToServer: false,
        tracking: false,
        moveCredential: false,
      }));
      return res;
    })
    .catch(e => Promise.reject(e));
};

export const removeSharedByAccounts = params => async (dispatch, getState) => {
  const credential = await encryptCredential(params.data);
  const body = {
    credential: credential.encrypted[0],
    unshareWithUserIds: params.unshareWithUserIds,
  };
  const req = new Request(URL_CREDENTIALS_SHARED);
  return req.authorise()
    .put(body)
    .then(res => {
      dispatch(deleteCredential({
        id: params.data.id,
        pushToServer: false,
      }));
      return res;
    })
    .catch(e => Promise.reject(e));
};


export const sharedInvitation = params => async (dispatch, getState) => {
  const {
    sharedId,
    credentialName,
    isAccepted,
    id,
    groupIdss,
  } = params;

  const req = new Request(URL_CREDENTIALS_SHARED_INVITATIONS);
  return req.authorise()
    .post({
      sharedId,
      credentialName,
      isAccepted,
    })
    .then(res => {
      if (res.accepted) {
        const req1 = new Request(DOWNWARD);
        const groupId = groupIdss;
        req1.authorise()
          .put({
            groupId,
            sharedId,
            setHomeDashboard: false,
            notify: false,
          })
          .then(res1 => console.log(''));
        const prevData = dispatch(getOneCredential(id));
        const credential = new Credential({
          ...prevData,
          accepted: true,
          shared: true,
          externalAccount: true,
        });

        // dispatch(updateCredential({
        //   credential,
        //   id,
        //   pushToServer: false,
        //   groupId,
        // }));
      }
      else {
        // dispatch(deleteCredential({ id, pushToServer: false }));
      }
      return Promise.resolve(res);
    })
    .catch(e => Promise.reject(e));
};
