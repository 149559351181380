/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
export const RX_UI_GUIDE_BACKUP = 'RX_UI_GUIDE_BACKUP'; // eslint-disable-line import/prefer-default-export
export const RX_UI_TOGGLE_VIEW = 'RX_UI_TOGGLE_VIEW';
export const RX_UI_STICKY_LIST = 'RX_UI_STICKY_LIST';
export const RX_EDIT_API_ERROR = 'RX_EDIT_API_ERROR';
export const RX_CHANGE_ACTIVE_DASHBOARD = 'RX_CHANGE_ACTIVE_DASHBOARD';
export const RX_GET_DASHBOARDLIST = 'RX_GET_DASHBOARDLIST';
export const RX_DASHBOARD_REMOVE = 'RX_DASHBOARD_REMOVE';
export const RX_UPDATE_BACKUP_KEY_INFO = 'RX_UPDATE_BACKUP_KEY_INFO';
export const RX_UPDATE_DEFAULT_ACCOUNTS = 'RX_UPDATE_DEFAULT_ACCOUNTS';
export const RX_USERS_API_CALL_STATUS = 'RX_USERS_API_CALL_STATUS';
export const RX_STOP_SHARING_DASHBOARD = 'RX_STOP_SHARING_DASHBOARD';
