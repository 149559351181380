import React from 'react';
import Styles from 'Modal/style.css';
import activeCancel from '../../assets/images/clear_icon/active.svg';
import hoverCacnel from '../../assets/images/clear_icon/hover.svg';
import clickCancel from '../../assets/images/clear_icon/click.svg';

const ClearIcon = props => (
  <img
    src={activeCancel}
    onMouseOver={e => (e.currentTarget.src = hoverCacnel)}
    onMouseOut={e => (e.currentTarget.src = activeCancel)}
    onMouseUp={e => (e.currentTarget.src = clickCancel)}
    onClick={props.onClickClearSearch}
    className={Styles.close}
  />
);

export default ClearIcon;
