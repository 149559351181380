/*
 * Copyright (C) 2018-2019 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { MODAL_ACCOUNT_EDIT, MODAL_TAKE_ACTION } from 'constants/modal';
import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { hideModal, showModal } from 'main/modal';
import withOverlayAction from 'Overlay/withAction';
import { updateCredential, deleteCredential } from 'main/vault/credentials';
import { getOneCredential } from 'main/vault/credentials/reduxState';
import Container from './container';


class ModalChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      acceptProcessing: false,
      deleteProcessing: false,
    };
    this.onClickEdit = this.onClickEdit.bind(this);
    this.onClickDecline = this.onClickDecline.bind(this);
  }

  onClickEdit() {
    this.props.showModal(MODAL_ACCOUNT_EDIT, this.props.data);
  }

  onClickDecline() {
    this.props.hide();
  }

  render() {
    return (
      <Container
        domain={this.props.data.domain}
        loginUrl={this.props.data.loginUrl}
        name={this.props.data.name}
        picture={this.props.data.picture}
        sharedWithName={this.props.data.sharedWithName}
        accepted={this.props.data.accepted}
        onClickEdit={this.onClickEdit}
        onClickDecline={this.onClickDecline}
        acceptProcessing={this.state.acceptProcessing}
        deleteProcessing={this.state.deleteProcessing}
        t={this.props.t}/>
    );
  }
}

const mapStateToProps = state => ({
  credentials: state.credentials.data,
  data: state.ui.modalData || {},
  groups: state.groups.data,
  unsortedId: state.groups.unsortedGroupId,
});

const mapDispatchToProps = dispatch => ({
  updateCredential: args => dispatch(updateCredential(args)),
  deleteCredential: args => dispatch(deleteCredential(args)),
  getCredential: id => dispatch(getOneCredential(id)),
  hide: () => dispatch(hideModal()),
  showModal: (name, data) => dispatch(showModal(name, data)),

});

export default translate()(connect(
  mapStateToProps,
  mapDispatchToProps,
)(withOverlayAction(ModalChangePassword)));
