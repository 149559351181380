/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
export const RX_SIDEBAR_ANIMATION_ENTER = 'RX_SIDEBAR_ANIMATION_ENTER';
export const RX_SIDEBAR_ANIMATION_LEAVE = 'RX_SIDEBAR_ANIMATION_LEAVE';
export const RX_SIDEBAR_DATA = 'RX_SIDEBAR_DATA';
export const RX_SIDEBAR_TEMPLATE = 'RX_SIDEBAR_TEMPLATE';
