/*
 * Copyright (C) 2018-2019 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { MODAL_REMOVE_ACCOUNT } from 'constants/routes';
import { SEARCH1 as ID_SEARCH } from 'constants/ids';
import { MODAL_ADD_ACCOUNT_NAVLIST } from 'constants/modal';
import React from 'react';
import { List } from 'immutable';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import debounce from 'lodash.debounce';
import { eventTarget } from '@nettoken/helpers';
import { LABEL_UNSORTED_GROUP } from '@nettoken/models';
import { arrayItemSliceOrAppend } from '@nettoken/helpers';
import { hideModal, showModal } from 'main/modal';
import { removeSharedByAccounts } from 'main/sharedAccounts';
import { sharedInvitation } from 'main/sharedAccounts';
import { deleteCredentials } from 'main/vault/credentials';
import { readGroup } from 'main/vault/groups/reduxState';
import { history } from 'reducers/store';
import withAsyncState from 'AsyncState';
import withOverlayAction from 'Overlay/withAction';
import Debouncer from 'utils/debouncer';
import $ from 'jquery';
import Container from './container';
import { MODAL_OFFLINE_MSG } from '../../../../constants/modal';
import { RxEditAccountProccessing, RXToasterShow } from '../../../../main/modal/reduxActions';
import { getApiErrorStatus } from '../../../../main/ui/reduxState';


const debouncedSearch = new Debouncer(params => params.search(), 200);
class ModalAccountRemoveComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      apps: {},
      filteredApps: {},
      isLoading: false,
      isSubmitDisabled: true,
      isSubmitting: false,
      query: '',
      selected: new List(),
      selectedApps: new List(),
      step: 'select',
    };

    this.debounce = debounce(() => this.search(this.state.query), 300);
    this.handleSearch = debounce(() => this.searchCallback(this.state.query), 200);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeSearch = this.handleChangeSearch.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.loadAccounts = this.loadAccounts.bind(this);
    this.togglePassword = this.togglePassword.bind(this);
    this.toggleSelected = this.toggleSelected.bind(this);
    this.onClickBack = this.onClickBack.bind(this);
    this.hidePopup = this.hidePopup.bind(this);
  }

  componentDidMount() {
    this.props.addOverlayAction('modal', this.hidePopup);
    this.checkHashString();
    this.loadAccounts();
  }

  hidePopup() {
    if (!this.state.isSubmitting) {
      this.props.hide();
    }
  }

  checkHashString() {
    history.replace({ search: `?${MODAL_REMOVE_ACCOUNT}`, hash: '' });
  }

  handleChange(event) {
    const { value: query } = eventTarget(event);
    this.setState({ query }, this.handleSearch);
  }

  handleChangeSearch(event) {
    const query = '';
    this.setState({ query }, this.handleSearch);
  }

  clearSearch() {
    const input = $(`#${ID_SEARCH}`);
    if (input) input.focus();
    this.handleChangeSearch();
  }

  searchCallback(query) {
    if (!query) {
      this.setState({
        filteredApps: this.state.apps,
      });
      return;
    }

    const { apps } = this.state;
    query = query.toLowerCase();
    const filteredApps = Object.keys(apps)
      .filter(key => {
        if (
          apps[key].name.toLowerCase().includes(query) ||
          apps[key].email.toLowerCase().includes(query) ||
          apps[key].groupId.toLowerCase().includes(query)
        ) {
          return true;
        }
        return false;
      })
      .reduce((obj, key) => {
        obj[key] = apps[key];
        return obj;
      }, {});

    this.setState({
      filteredApps,
    });
  }

  async handleSubmit(event) {
    event.preventDefault();
    if (!navigator.onLine) {
      this.setSubmitting(true);
      this.props.setEditAccountProccessing(true);
      setTimeout(() => {
        this.setSubmitting(false);
        this.props.setEditAccountProccessing(false);
      }, 2000);
      this.props.showToaster({
        status: 'open',
        type: 'error',
        value: 'Oops, could not save! Please try again',
      });
      return;
    }

    // Modal UI can be bypassed by pressing Enter, do
    // a manual check here. If nothing was selected,
    // we do not allow user to submit the modal.
    if (!this.state.selected.size) return;

    if (this.state.step === 'select') {
      const { apps, selected } = this.state;
      const filteredApps = Object.keys(apps)
        .filter(key => selected.includes(apps[key].id))
        .reduce((obj, key) => {
          obj[key] = apps[key];
          return obj;
        }, {});

      this.setState({ apps: filteredApps, filteredApps, step: 'confirm' });
      return;
    }
    this.setSubmitting(true);
    this.props.setEditAccountProccessing(true);
    const ids = this.state.selected;
    const sharedByAccepted = [];
    const sharedByPending = [];
    const restAccounts = [];
    const allAccounts = this.state.apps;
    try {
      ids.map((async id => {
        const { externalAccount, shared, accepted } = allAccounts[id];
        if (externalAccount && shared) {
          const params = {
            data: allAccounts[id],
            unshareWithUserIds: [],
          };
          const res = await this.props.removeSharedByAccounts(params);
          sharedByAccepted.push(allAccounts[id]);
        }
        else if (!accepted) {
          const params = {
            sharedId: allAccounts[id].invitationId,
            credentialName: allAccounts[id].name,
            isAccepted: false,
            id: allAccounts[id].id,
          };
          const res1 = await this.props.sharedInvitation(params);
          sharedByPending.push(allAccounts[id]);
        }
        else {
          restAccounts.push(allAccounts[id].id);
        }
      }));

      const accoutsIds = new List(restAccounts);
      const res1 = await this.props.deleteCredentials({ ids: accoutsIds });
      this.setSubmitting(false);
      this.props.hide();
      setTimeout(() => {
      }, 2000);
    }
    catch (error) {
      setTimeout(() => {
        this.setSubmitting(false);
        this.props.setEditAccountProccessing(false);
      }, 2000);
    }
  }

  loadAccounts() {
    const tmpApps = Object.keys(this.props.credentials).reduce((obj, key) => {
      const { groupId } = this.props.credentials[key];
      let groupName;
      if (this.props.groups[groupId]) {
        groupName = this.props.groups[groupId].label;
      }
      const isUnsorted = groupName === LABEL_UNSORTED_GROUP;
      groupName = isUnsorted ? '' : (groupName || '');
      obj[key] = { ...this.props.credentials[key] };
      obj[key].groupId = groupName;
      obj[key].showPassword = false;
      return obj;
    }, {});

    let apps = {};
    Object.values(tmpApps).map(obj => {
      let validate = true;
      if (obj.invitationId !== '') {
        validate = false;
      }
      else {
        apps = {
          ...apps,
          [obj.id]: obj,
        };
      }
      return validate;
    });

    this.setState({
      apps,
      filteredApps: apps,
    });
  }

  // TODO very slow, needs perf improvements
  search(query) {
    if (!query) query = '';
    query = query.toUpperCase();
    let apps = [];
    Object.entries(this.props.credentials).forEach(([, app]) => {
      const displayLogin = app.email || app.username || app.phone;
      let match = !query;

      if (!match) {
        const matchesName = app.name.toUpperCase().startsWith(query);
        match = matchesName;
      }

      if (!match) {
        const matchesDisplayLogin = displayLogin.toUpperCase().startsWith(query);
        match = matchesDisplayLogin;
      }

      if (match) {
        let isChecked = false;
        if (this.state.selected.length) {
          this.state.selected.forEach(item => {
            if (app.id === item.id) {
              isChecked = true;
            }
          });
        }
        apps = [
          ...apps,
          Object.assign({ checked: isChecked, displayLogin }, app),
        ];
      }
    });

    apps = apps.sort((a, b) => {
      const aName = a.name ? a.name.toUpperCase() : '';
      const bName = b.name ? b.name.toUpperCase() : '';
      if (aName < bName) return -1;
      if (aName > bName) return 1;
      return 0;
    });

    this.setState({ apps });
  }

  setLoader(isLoading) {
    this.props.AsyncSetState(() => this.setState({ isLoading }));
  }

  setSubmitting(isSubmitting) {
    this.setState({ isSubmitting });
  }

  togglePassword(id) {
    this.setState({
      filteredApps: {
        ...this.state.filteredApps,
        [id]: {
          ...this.state.filteredApps[id],
          showPassword: !this.state.filteredApps[id].showPassword,
        },
      },
      apps: {
        ...this.state.apps,
        [id]: {
          ...this.state.apps[id],
          showPassword: !this.state.apps[id].showPassword,
        },
      },
    });
  }

  toggleSelected(id) {
    let { selected } = this.state;
    selected = arrayItemSliceOrAppend(selected, id);
    const isSubmitDisabled = selected.size === 0;
    this.setState({ isSubmitDisabled, selected });
  }

  onClickBack() {
    if (this.state.step == 'confirm') {
      this.setState({
        step: 'select',
        isSubmitDisabled: true,
        selected: new List(),
      });
      this.loadAccounts();
    }
    else {
      this.props.onClickBackHandler();
    }
  }

  render() {
    return (
      <Container
        apps={this.state.filteredApps}
        canSubmit={!this.state.isSubmitDisabled}
        isLoading={this.state.isLoading}
        isSubmitting={this.state.isSubmitting}
        onCancel={this.hidePopup}
        onClickClearSearch={this.clearSearch}
        onChangeSearch={this.handleChange}
        onClickApp={this.toggleSelected}
        onClickPasswordToggle={this.togglePassword}
        onSubmit={this.handleSubmit}
        query={this.state.query}
        selected={this.state.selected}
        step={this.state.step}
        onClickBackHandler={this.onClickBack}
        t={this.props.t} />
    );
  }
}

const mapStateToProps = state => {
  let creds = {};
  Object.keys(state.credentials.data).map(cred => {
    if (
      !('dashboardSpaceId' in state.credentials.data[cred]) ||
      state.credentials.data[cred].dashboardSpaceId == '' ||
      state.credentials.data[cred].dashboardSpaceId == null ||
      (state.ui.dashboards[state.credentials.data[cred].dashboardSpaceId] &&
        !('externalDashboard' in state.ui.dashboards[state.credentials.data[cred].dashboardSpaceId])) ||
      (state.ui.dashboards[state.credentials.data[cred].dashboardSpaceId] &&
        !state.ui.dashboards[state.credentials.data[cred].dashboardSpaceId].externalDashboard)
    ) {
      creds = {
        ...creds,
        [cred]: {
          ...state.credentials.data[cred],
        },
      };
    }
  });
  return {
    credentials: creds,
    groups: state.groups.data,
    data: state.ui.modalData,
    editAccountProccessing: state.ui.editAccountProccessing,
  };
};

const mapDispatchToProps = dispatch => ({
  deleteCredentials: args => dispatch(deleteCredentials(args)),
  removeSharedByAccounts: params => dispatch(removeSharedByAccounts(params)),
  sharedInvitation: params => dispatch(sharedInvitation(params)),
  hide: () => {
    // Remove the query string, otherwise the modal would keep opening.
    history.replace({ search: '' });
    dispatch(hideModal());
  },
  readGroup: id => dispatch(readGroup(id)),
  onClickBackHandler: () => dispatch(showModal(MODAL_ADD_ACCOUNT_NAVLIST)),
  showModal: (template, data) => dispatch(showModal(template, data)),
  showToaster: args => dispatch(RXToasterShow(args)),
  setEditAccountProccessing: status => dispatch(RxEditAccountProccessing(status)),
  getApiErrorStatus: () => dispatch(getApiErrorStatus()),
});

export default translate()(connect(
  mapStateToProps,
  mapDispatchToProps,
)(withAsyncState(withOverlayAction(ModalAccountRemoveComponent))));
