/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { URL_PREFERENCES, URL_SETTINGS, URL_USERS } from 'constants/endpoints';
import Validator from '@nettoken/validator';
import { getUserProfile } from 'main/user/reduxState';
import { Request } from 'utils/request';
import { RXPrefsSessionTimeout, RXPrefsSet } from './reduxActions';
import getConnectionLostTimeout from './reduxState';

/**
 * @param {integer} timeout Timeout in seconds.
 */
export const setSessionTimeout = timeout => dispatch => {
  const connectionLostTimeout = dispatch(getConnectionLostTimeout());
  const params = {
    sessionTimeout: timeout,
    connectionLostTimeout,
  };
  const req = new Request(URL_SETTINGS);
  return req.authorise().put(params)
    .then(res => dispatch(RXPrefsSessionTimeout(res.accessToken)))
    .catch(e => {
      console.log(e);
      return Promise.reject(e);
    });
};

export const setConnectionLostTimeout = (lostConnectionTimeoutNew, timeout) => dispatch => {
  const params = {
    sessionTimeout: timeout,
    connectionLostTimeout: lostConnectionTimeoutNew,
  };
  dispatch(RXPrefsSet('connectionLostTimeout', lostConnectionTimeoutNew));
  const req = new Request(URL_SETTINGS);
  return req.authorise().put(params)
    .catch(e => {
      console.log(e);
      return Promise.reject(e);
    });
};

export const setTrackingPermission = permission => dispatch => {
  const body = { eventTracking: permission };
  const req = new Request(URL_USERS);
  req.authorise().post(body)
    .then(() => dispatch(RXPrefsSet('tracking', permission)));
};

/**
 * @param {string} key
 * @param {any} value
 */
export const syncPreference = (key, value) => dispatch => {
  dispatch(RXPrefsSet(key, value));

  let params;
  switch (key) {
    case 'cookies':
      params = { [key]: value };
      break;

    case 'device':
      params = { deviceType: value };
      break;

    default:
      return Promise.reject(`Invalid key ${key}`);
  }

  const req = new Request(URL_PREFERENCES);
  return req.authorise().put(params)
    .then(() => Promise.resolve())
    .catch(e => Promise.reject(e));
};

/**
 * @returns {Promise}
 */
export const syncPreferenceInputs = () => dispatch => {
  const profile = dispatch(getUserProfile());
  const params = {
    age: profile.age ? Validator.strToInt(profile.age) : null,
    location: profile.location || '',
  };

  const req = new Request(URL_PREFERENCES);
  return req.authorise().put(params)
    .then(() => Promise.resolve())
    .catch(e => Promise.reject(e));
};
