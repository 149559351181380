/*
 * Copyright (C) 2018-2019 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { MODAL_PROMPT_BACKUP_EXPLAINED } from 'constants/modal';
import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import Routes from 'config/routes';
import { backupMasterKey } from 'main/backup';
import { goTo } from 'main/router';
import withOverlayAction from 'Overlay/withAction';
import { showModal, hideModal } from 'main/modal';
import { Request } from 'utils/request';
import Container from './container';
import { URL_BACKUP_INFO } from '../../../../constants/endpoints';

class SidebarBackupComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      lastUpdated: null,
      termsAccepted: false,
      disabled: false,
      formattedDate: '',
      stepNo: 1,
    };

    this.handleClickDownload = this.handleClickDownload.bind(this);
    this.onChangeCheckbox = this.onChangeCheckbox.bind(this);
    this.onBackHandler = this.onBackHandler.bind(this);
  }

  componentDidMount() {
    this.props.addOverlayAction('modal', this.props.hide);
    this.setState({
      lastUpdated: this.props.backupKeyInfo.lastUpdated,
      disabled: this.props.backupKeyInfo.disabled,
      formattedDate: this.props.backupKeyInfo.formattedDate,
    });
  }

  async handleClickDownload() {
    const { t } = this.props;
    this.setState({ isLoading: true, stepNo: 2 });

    try {
      await this.props.backupMasterKey(t);
      const lastUpdated = new Date();
      const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December',
      ];
      const formattedDate = `${lastUpdated.getDate()} ${monthNames[lastUpdated.getMonth()]}  ${lastUpdated.getFullYear()}`;
      this.setState({
        isLoading: false,
        disabled: true,
        formattedDate,
        lastUpdated,
        termsAccepted: false,
      });
    }
    catch (e) {
      this.setState({ isLoading: false });
      console.log(e);
    }
  }

  onChangeCheckbox() {
    this.setState({
      termsAccepted: !this.state.termsAccepted,
      disabled: this.state.termsAccepted,
    });
  }

  onBackHandler() {
    this.setState({ stepNo: 1 });
  }

  render() {
    return (
      <Container
        backup={this.props.states.user.hasMasterKeyBackup}
        loading={this.state.isLoading}
        onCancel={this.props.hide}
        onClickDownload={this.handleClickDownload}
        onClickExplain={() => this.props.showModal(MODAL_PROMPT_BACKUP_EXPLAINED)}
        t={this.props.t}
        lastUpdated={this.state.lastUpdated}
        termsAccepted={this.state.termsAccepted}
        onChangeCheckbox={this.onChangeCheckbox}
        disabled={this.state.disabled}
        formattedDate={this.state.formattedDate}
        onBackHandler={this.onBackHandler}
        stepNo={this.state.stepNo} />
    );
  }
}

const mapStateToProps = state => ({
  states: state,
  backupKeyInfo: state.ui.backupKeyDetails,
});

const mapDispatchToProps = dispatch => ({
  backupMasterKey: t => dispatch(backupMasterKey(t)),
  goBack: () => dispatch(goTo(Routes.SETTINGS)),
  showModal: (name, data) => dispatch(showModal(name, data)),
  hide: () => dispatch(hideModal()),
});

export default translate()(connect(
  mapStateToProps,
  mapDispatchToProps,
)(withOverlayAction(SidebarBackupComponent)));
