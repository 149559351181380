/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import {
  RX_TOOLS_DRAG,
  RX_TOOLS_HIDE,
  RX_TOOLS_LARGE,
  RX_TOOLS_PRESETS,
  RX_TOOLS_SHOW,
  RX_TOOLS_SMALL,
  RX_TOOLS_VIEW,
} from './actionTypes';

/**
 * @param {integer} [x=0]
 * @param {integer} [y=0]
 */
export const RXToolsDrag = (x, y) => ({
  type: RX_TOOLS_DRAG,
  x,
  y,
});

export const RXToolsHide = () => ({
  type: RX_TOOLS_HIDE,
});

export const RXToolsLarge = () => ({
  type: RX_TOOLS_LARGE,
});

/**
 * @param {object} presets
 */
export const RXToolsPresets = presets => ({
  type: RX_TOOLS_PRESETS,
  presets,
});

export const RXToolsShow = () => ({
  type: RX_TOOLS_SHOW,
});

export const RXToolsSmall = () => ({
  type: RX_TOOLS_SMALL,
});

/**
 * @param {integer} view
 */
export const RXToolsView = view => ({
  type: RX_TOOLS_VIEW,
  view,
});
