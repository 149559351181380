/* eslint-disable */
/*
 * Copyright (C) 2018-2019 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { SEARCH2 as ID_SEARCH } from 'constants/ids';
import { MODAL_ACCOUNT_ADD, MODAL_ADD_ACCOUNT_NAVLIST } from 'constants/modal';
import { SOURCE_FILE_UPLOAD } from 'constants/events';
import { MODAL_ADD_ACCOUNT } from 'constants/routes';
import React from 'react';
import { saveAs } from 'file-saver';
import { List } from 'immutable';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import debounce from 'lodash.debounce';
import KeyPair from '@nettoken/crypto-worker/src/keypair';
import { Request } from 'utils/request';
import {
  CW_DECRYPT_USING_MASTER_KEYPAIR,
  CW_ENCRYPT_CREDENTIALS,
  CW_ENCRYPT_USING_MASTER_KEYPAIR,
  CW_ENCRYPT_WITH_EXTERNAL_KEY,
  CW_GENERATE_EPHEMERAL_KEY_PAIR,
  getWorkerPromise,
} from '@nettoken/crypto-worker';
import { arrayItemSliceOrAppend, eventTarget } from '@nettoken/helpers';
import downloadFile from 'assets/file/Nettoken template.xls';

import { processFile } from 'main/import';
import { hideModal, showModal } from 'main/modal';
import { addCredentialsEfficiently } from 'main/vault/credentials';
import { history } from 'reducers/store';
import withOverlayAction from 'Overlay/withAction';
import { getSharedCredentialData } from 'main/sharedAccounts';
import $ from 'jquery';
import Container from './container';
import { getNewCredentials } from '../../../../main/vault/credentials/reduxState';
import { URL_CREDENTIALS_SHARED } from '../../../../constants/endpoints';
import { RXCredentialsUpdateOne } from '../../../../main/vault/credentials/reduxActions';
import { getOrCreateKeyPairForSharing } from '../../../../utils/misc';

class ModalAccountAddFileComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.handleCancel = this.handleCancel.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = debounce(this.searchCallback.bind(this), 200);
    this.handleSelectApp = this.handleSelectApp.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.handleChangeSearch = this.handleChangeSearch.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChangeSearch = this.onChangeSearch.bind(this);
    this.togglePassword = this.togglePassword.bind(this);
    this.handleSharingDashboard = this.handleSharingDashboard.bind(this);
    // this.getOrCreateKeyPairForSharing = this.getOrCreateKeyPairForSharing.bind(this);
    this.prepareCredentials = this.prepareCredentials.bind(this);
    this.handleImport = this.handleImport.bind(this);
  }

  componentDidMount() {
    this.props.addOverlayAction('modal', this.handleCancel, false);
    this.checkHashString();
  }

  checkHashString() {
    history.replace({ search: `?${MODAL_ADD_ACCOUNT}=file`, hash: '' });
  }

  getInitialState() {
    return {
      apps: {},
      error: '',
      file: null,
      filename: '',
      groupIds: [],
      groups: {},
      loading: false,
      query: '',
      selected: new List(),
      step: 'upload',
      canSubmit: true,
    };
  }

  handleCancel() {
    if (this.state.step === 'upload') {
      this.props.hideModal();
    }
    else if (this.state.step === 'preview') {
      this.setState(this.getInitialState());
    }
  }

  handleChangeSearch(event) {
    const query = event ? eventTarget(event).value : '';
    this.setState({ query }, this.handleSearch(query));
  }

  clearSearch() {
    this.handleChangeSearch();

    const input = $(`#${ID_SEARCH}`);
    if (input) input.focus();
  }

  handleChange(event) {
    const [file] = eventTarget(event).files;
    if (file) {
      this.setState({ file, filename: file.name });
    }
  }

  async handleFile() {
    this.setState({ error: '', loading: true });

    const {
      apps,
      error,
      groupIds,
      groups,
    } = await processFile({
      credentials: this.props.credentials,
      file: this.state.file,
      groups: this.props.groups,
      allowMerge: true,
      t: this.props.t,
      unsortedId: this.props.unsortedId,
      dashboardSpaceId: this.props.currentDashboard,
    });

    if (error !== null) {
      this.showError(error);
    }
    else {
      this.setState({
        apps,
        filteredApps: { ...apps },
        groupIds,
        groups,
        loading: false,
        passwordList: Object.keys(apps).reduce((obj, key) => {
          obj[key] = false;
          return obj;
        }, {}),
        selected: new List(Object.keys(apps)),
        step: 'preview',
      });
    }
  }

  // async getOrCreateKeyPairForSharing(credential) {
  //   const keypair = new KeyPair();
  //   const {
  //     publicKey,
  //     secretKey,
  //   } = credential;
  //   const worker = await getWorkerPromise('crypto');
  //   if (publicKey && secretKey) {
  //     const [pKey, sKey] = await Promise.all([
  //       worker({
  //         event: CW_DECRYPT_USING_MASTER_KEYPAIR,
  //         message: publicKey,
  //       }),
  //       worker({
  //         event: CW_DECRYPT_USING_MASTER_KEYPAIR,
  //         message: secretKey,
  //       }),
  //     ])
  //       .then(values => values.map(v => Object.values(v)
  //         .join('')
  //         .replace('CW_DECRYPT_USING_MASTER_KEYPAIR', '')));

  //     keypair.set(pKey, sKey);
  //   }
  //   else {
  //     const newKeyPair = await worker({
  //       event: CW_GENERATE_EPHEMERAL_KEY_PAIR,
  //       format: 'base64',
  //     });
  //     keypair.set(newKeyPair.publicKey, newKeyPair.secretKey);
  //   }
  //   return keypair;
  // }

  prepareCredentials = ({
    id,
    note,
    groupId,
    accountId,
    name,
    username,
    email,
    password,
    loginUrl,
    phone,
    publicKey,
    secretKey,
    shared,
    sharedByDashboard,
    dashboardSpaceId,
    picture,
  }) => ({
    id,
    note,
    groupId,
    accountId,
    name,
    username,
    email,
    password,
    loginUrl,
    phone,
    publicKey,
    secretKey,
    shared,
    sharedByDashboard,
    dashboardSpaceId,
    picture,
  });

  // async handleSharingDashboard() {
  //   const { dashboards, currentDashboard } = this.props.ui;
  //   const credentials = this.props.getNewCredentials();
  //   if (
  //     currentDashboard &&
  //     'usersSharedWith' in dashboards[currentDashboard] &&
  //     dashboards[currentDashboard].usersSharedWith &&
  //     credentials &&
  //     Object.keys(credentials).length > 0
  //   ) {
  //     const { usersSharedWith } = dashboards[currentDashboard];
  //     usersSharedWith.forEach(async user => {
  //       Object.keys(credentials).forEach(async accountId => {
  //         const credential = credentials[accountId];
  //         const {
  //           id: sharedWithUserId,
  //           publicKey: sharedWithUserPublicKey,
  //           responded: dashboardStatus,
  //         } = user;
  //         const {
  //           publicKey,
  //           secretKey,
  //         } = await getOrCreateKeyPairForSharing(credential, KeyPair);
  //         const worker = await getWorkerPromise('crypto');

  //         const [pKey, sKey] = await Promise.all([
  //           worker({
  //             event: CW_ENCRYPT_USING_MASTER_KEYPAIR,
  //             message: publicKey,
  //           }),
  //           worker({
  //             event: CW_ENCRYPT_USING_MASTER_KEYPAIR,
  //             message: secretKey,
  //           }),
  //         ])
  //           .then(values => values.map(v => Object.values(v)
  //             .join('')
  //             .replace('CW_ENCRYPT_USING_MASTER_KEYPAIR', '')));
  //         credential.publicKey = pKey;
  //         credential.secretKey = sKey;
  //         credential.shared = true;
  //         credential.sharedByDashboard = true;
  //         credential.dashboardSpaceId = dashboards[currentDashboard].id;
  //         const { encrypted } = await worker({
  //           event: CW_ENCRYPT_CREDENTIALS,
  //           credentials: [credential],
  //         });
  //         if (!encrypted[0]) {
  //           throw new Error('Encrypted credentials unavailable');
  //         }
  //         const [{
  //           encrypted: encryptedPublicKey,
  //         }, {
  //           encrypted: encryptedSecretKey,
  //         }] = await Promise.all([
  //           worker({
  //             event: CW_ENCRYPT_WITH_EXTERNAL_KEY,
  //             message: publicKey,
  //             inputFormat: 'plain',
  //             outputFormat: 'base64',
  //             publicKey: sharedWithUserPublicKey,
  //           }),
  //           worker({
  //             event: CW_ENCRYPT_WITH_EXTERNAL_KEY,
  //             message: secretKey,
  //             inputFormat: 'plain',
  //             outputFormat: 'base64',
  //             publicKey: sharedWithUserPublicKey,
  //           }),
  //         ]);
  //         const SharedCredential = {
  //           credential: this.prepareCredentials(encrypted[0]),
  //           accountId,
  //           secretKey: encryptedSecretKey,
  //           publicKey: encryptedPublicKey,
  //           sharedWithUserId,
  //           hasPendingInvites: !dashboardStatus,
  //         };
  //         const req = new Request(URL_CREDENTIALS_SHARED);
  //         req.authorise()
  //           .post(SharedCredential)
  //           .then(() => {
  //             if (!credential.externalAccount) {
  //               this.props.getSharedCredentials(credential.id);
  //               this.props.updateCredential(credential.id, credential);
  //             }

  //             this.setState({ loading: false });
  //             this.props.hideModal();
  //           })
  //           .catch(e => console.log('shared error', e));
  //       });
  //     });
  //   }
  //   else {
  //     this.setState({ loading: false });
  //     this.props.hideModal();
  //   }
  // }
  async handleSharingDashboard() {
    const { dashboards, currentDashboard } = this.props;
    if (
      currentDashboard &&
      'usersSharedWith' in dashboards[currentDashboard] &&
      dashboards[currentDashboard].usersSharedWith.length > 0
    ) {
      const { usersSharedWith } = dashboards[currentDashboard];
      const credentials = this.props.getNewCredentials();
      const sharedCredentialDataArr = {};
      let sharedCredentialDataArrCount = 0;
      let credentialsKeys = {};
      // usersSharedWith.forEach(async (user, userIndex) => {
      let userIndex = 0;
      for (let user of usersSharedWith) {
        // const element = array[index];
        // Object.keys(credentials).forEach(async accountId => {
        for (let accountId in credentials) {
          const credential = credentials[accountId];
          // console.log('spec_Check_daatta', credentialsKeys);
          if (accountId in credentialsKeys) {
            credential.publicKey = credentialsKeys[accountId].publicKey;
            credential.secretKey = credentialsKeys[accountId].secretKey;
          }
          const {
            id: sharedWithUserId,
            publicKey: sharedWithUserPublicKey,
            responded: dashboardStatus,
          } = user;
          console.log('spec_Check_daatta', credential.publicKey, credential.secretKey);
          const {
            publicKey,
            secretKey,
          } = await getOrCreateKeyPairForSharing(credential, KeyPair);
          const worker = await getWorkerPromise('crypto');
          const [pKey, sKey] = await Promise.all([
            worker({
              event: CW_ENCRYPT_USING_MASTER_KEYPAIR,
              message: publicKey,
            }),
            worker({
              event: CW_ENCRYPT_USING_MASTER_KEYPAIR,
              message: secretKey,
            }),
          ])
            .then(values => values.map(v => Object.values(v)
              .join('')
              .replace('CW_ENCRYPT_USING_MASTER_KEYPAIR', '')));
          credential.publicKey = pKey;
          credential.secretKey = sKey;
          credentialsKeys = {
            ...credentialsKeys,
            [accountId]: {
              publicKey: pKey,
              secretKey: sKey,
            }
          }
          credential.shared = true;
          credential.sharedByDashboard = true;
          credential.dashboardSpaceId = dashboards[currentDashboard].id;
          const { encrypted } = await worker({
            event: CW_ENCRYPT_CREDENTIALS,
            credentials: [credential],
          });
          if (!encrypted[0]) {
            throw new Error('Encrypted credentials unavailable');
          }
          const [{
            encrypted: encryptedPublicKey,
          }, {
            encrypted: encryptedSecretKey,
          }] = await Promise.all([
            worker({
              event: CW_ENCRYPT_WITH_EXTERNAL_KEY,
              message: publicKey,
              inputFormat: 'plain',
              outputFormat: 'base64',
              publicKey: sharedWithUserPublicKey,
            }),
            worker({
              event: CW_ENCRYPT_WITH_EXTERNAL_KEY,
              message: secretKey,
              inputFormat: 'plain',
              outputFormat: 'base64',
              publicKey: sharedWithUserPublicKey,
            }),
          ]);
          const SharedCredential = {
            credential: this.prepareCredentials(encrypted[0]),
            accountId: credential.accountId,
            secretKey: encryptedSecretKey,
            publicKey: encryptedPublicKey,
            sharedWithUserId,
            hasPendingInvites: !dashboardStatus,
          };
          // this.props.getSharedCredentials(credential.id);
          // this.props.updateCredential(credential.id, credential);
          console.log('spec_cjeck_ddd', sharedCredentialDataArr, SharedCredential);
          if (userIndex in sharedCredentialDataArr) {
            sharedCredentialDataArr[userIndex].push(SharedCredential);
          }
          else {
            sharedCredentialDataArr[userIndex] = [SharedCredential];
          }
          credentials[accountId] = credential;
          console.log('spec_cjeck_ddd2', sharedCredentialDataArr, SharedCredential);
          sharedCredentialDataArrCount += 1;
          if (sharedCredentialDataArrCount >= (
            Object.keys(credentials).length * usersSharedWith.length
          )) {
            // const finalPayload = [...sharedCredentialDataArr];
            // sharedCredentialDataArr = [];
            this.callApiRequest(sharedCredentialDataArr, 0, credentials);
          }
        };
        userIndex += 1;
      };
    }
    else {
      // this.setState({ canSubmit: false });
      // this.submitting(false);
      // this.hide();
      this.setState({ loading: false });
      this.props.hideModal();
    }
  }
  callApiRequest(finalPayload, index, credentialsData) {
    console.log('spec_Check_33', index, finalPayload);
    const req = new Request(URL_CREDENTIALS_SHARED);
    req.authorise()
      .post({ credentials: finalPayload[index] })
      .then(() => {
        console.log('spec_Check_331', index);
        finalPayload[index].map(data => {
          if (!data.credential.externalAccount) {
            // const newCredential = {
            //   ...credentials[data.credential.id],
            //   // data.credential,
            // }
            let credential = credentialsData[data.credential.id];
            // credential.publicKey = pKey;
            // credential.secretKey = sKey;
            // credential.shared = true;
            // credential.sharedByDashboard = true;
            // credential.dashboardSpaceId = dashboards[currentDashboard].id;
            this.props.getSharedCredentials(credential.id);
            this.props.updateCredential(credential.id, credential);
          }
        });
        if (index >= Object.keys(finalPayload).length - 1) {
          this.setState({ loading: false });
          this.props.hideModal();
        }
        else {
          this.callApiRequest(finalPayload, index + 1, credentialsData);
        }
      })
      .catch(e => console.log('shared error', e));
  }

  handleFieldChange(event, id) {
    const { name, value } = eventTarget(event);
    this.setState({
      apps: {
        ...this.state.apps,
        [id]: {
          ...this.state.apps[id],
          [name]: value,
        },
      },
      filteredApps: {
        ...this.state.filteredApps,
        [id]: {
          ...this.state.filteredApps[id],
          [name]: value,
        },
      },
    });
  }

  handleImport() {
    this.props.addCredentialsEfficiently({
      callback: this.handleSharingDashboard,
      credentialIds: this.state.selected,
      credentials: this.state.apps,
      eventSource: SOURCE_FILE_UPLOAD,
      groupNames: this.state.groupIds,
      groups: this.state.groups,
      updateDuplicateCredentials: true,
      loader: loading => this.setState({ loading }),
    });
  }

  handleSelectApp(id) {
    const selected = arrayItemSliceOrAppend(this.state.selected, id);
    this.setState({
      selected,
      canSubmit: selected.size > 0,
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    if (this.state.step === 'upload') {
      this.handleFile();
    }
    else if (this.state.step === 'preview') {
      this.handleImport();
    }
  }

  handleTemplateDownload() {
    saveAs(downloadFile, 'Nettoken template.xls');
  }

  onChangeSearch(event) {
    const { value: query } = eventTarget(event);
    this.setState({
      query,
    }, () => this.handleSearch(query));
  }

  searchCallback(query) {
    const { apps } = this.state;
    if (query === '') {
      this.setState({
        filteredApps: apps,
      });
      return;
    }
    const _filteredApps = Object.keys(apps)
      .filter(key => apps[key].name.toLowerCase().includes(query.toLowerCase()) ||
        apps[key].groupId.toLowerCase().includes(query.toLowerCase()) ||
        apps[key].email.toLowerCase().includes(query.toLowerCase()))
      .reduce((obj, key) => {
        obj[key] = apps[key];
        return obj;
      }, {});

    this.setState({
      filteredApps: _filteredApps,
    });
  }

  showError(err) {
    const error = typeof err === 'object' ? err.message : this.props.t('error.import');
    this.setState({ error, loading: false });
  }

  togglePassword(id) {
    this.setState({
      passwordList: {
        ...this.state.passwordList,
        [id]: !this.state.passwordList[id],
      },
    });
  }

  render() {
    return (
      <Container
        apps={this.state.filteredApps}
        disabledTabs={this.state.step === 'preview'}
        error={this.state.error}
        filename={this.state.filename}
        canSubmit={this.state.canSubmit}
        groups={this.state.groups}
        groupIds={this.state.groupIds}
        hasNewAccounts={this.state.groupIds.length > 0}
        loading={this.state.loading}
        onCancel={this.handleCancel}
        onChange={this.handleChange}
        onClickClearSearch={this.clearSearch}
        onFieldChange={this.handleFieldChange}
        onChangeSearch={this.onChangeSearch}
        onClickPasswordToggle={this.togglePassword}
        onDownload={this.handleTemplateDownload}
        onSelectApp={this.handleSelectApp}
        onSubmit={this.handleSubmit}
        passwordList={this.state.passwordList}
        query={this.state.query}
        selectedApps={this.state.selected}
        step={this.state.step}
        t={this.props.t}
        onClickBackHandler={this.props.onClickBackHandler}
        unsortedId={this.props.unsortedId} />
    );
  }
}

const mapStateToProps = state => ({
  credentials: state.credentials.data,
  groups: state.groups.data,
  unsortedId: state.groups.unsortedGroupId,
  ui: state.ui,
  currentDashboard: state.ui.currentDashboard,
  defaultAccounts: state.ui.defaultAccounts,
  dashboards: state.ui.dashboards,
});

const mapDispatchToProps = dispatch => ({
  addCredentialsEfficiently: opts => dispatch(addCredentialsEfficiently(opts)),
  onClickBackHandler: () => dispatch(showModal(MODAL_ADD_ACCOUNT_NAVLIST)),
  getNewCredentials: () => dispatch(getNewCredentials()),
  hideModal: () => {
    // Remove the query string, otherwise the modal would keep opening.
    history.replace({ search: '' });
    dispatch(hideModal());
  },
  getSharedCredentials: id => dispatch(getSharedCredentialData(id)),
  updateCredential: (id, data) => dispatch(RXCredentialsUpdateOne(id, data)),
});

export default translate()(connect(
  mapStateToProps,
  mapDispatchToProps,
)(withOverlayAction(ModalAccountAddFileComponent)));
