import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { RxEditAccountProccessing, RXToasterShow } from '../../main/modal/reduxActions';

/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
class ToasterComponent extends React.Component {
  render() {
    const handleClose = () => {
      this.props.showToaster({
        status: 'close',
        type: '',
        value: '',
      });
    };
    if (this.props.toaster.status === 'open') {
      setTimeout(() => {
        this.props.setEditAccountProccessing(false);
      }, 2000);
      setTimeout(() => {
        this.props.showToaster({
          status: 'close',
          type: '',
          value: '',
        });
      }, 3500);
    }
    let html = '';
    if (this.props.toaster.status === 'open') {
      const bgColor = this.props.toaster.type === 'error' ? '#FB5D00' : '#FFF466';
      const color = this.props.toaster.type === 'error' ? '#FFFFFF' : '#28283F';
      html = <div style={{
        backgroundColor: bgColor,
        padding: '5px 40px',
        borderRadius: '3px',
        textAlign: 'center',
        color,
        zIndex: '999999',
      }} onClick={handleClose}>
        {this.props.toaster.value}
      </div>;
    }
    else {
      html = <></>;
    }
    return html;
  }
}

const mapStateToProps = state => ({
  toaster: state.ui.toaster,
});

const mapDispatchToProps = dispatch => ({
  showToaster: toasterData => dispatch(RXToasterShow(toasterData)),
  setEditAccountProccessing: status => dispatch(RxEditAccountProccessing(status)),
});

export default translate()(
  connect(mapStateToProps, mapDispatchToProps)(ToasterComponent),
);
