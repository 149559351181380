import React from 'react';
import active from '../../assets/images/copy_icon/active.svg';
import hover from '../../assets/images/copy_icon/hover.svg';
import click from '../../assets/images/copy_icon/click.svg';

const CopyIcon = () => (
  <img
    src={active}
    onMouseOver={e => (e.currentTarget.src = hover)}
    onMouseOut={e => (e.currentTarget.src = active)}
    onMouseUp={e => (e.currentTarget.src = click)}
  />
);

export default CopyIcon;
