/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { List } from 'immutable';
import { Credential } from '@nettoken/models';
import Validator from '@nettoken/validator';
import { CredentialFilter, TYPE_CSV } from 'main/import/common';
import { detectLoginInformationType } from 'main/user';

/**
 * Check if the file contents are a potential Dashlane decrypted archive.
 *
 * @param {object} file See `openExportedArchiveFile()` for signature.
 *
 * @returns {boolean}
 */
const isSupportedFile = file => {
  const { data, type } = file;
  if (type !== TYPE_CSV || !Array.isArray(data)) return false;
  for (let i = 0; i < data.length; i += 1) {
    if (!Array.isArray(data[i])) return false;
  }
  return true;
};

/**
 * @param {array} rows Dashlane exported CSV rows.
 * @param {Map} credentialsDomainMap Existing credentials sorted by domain.
 *
 * @returns {object} See `formatResponse()` for signature.
 */
const processFile = (rows, credentialsDomainMap) => {
  const credentialFilter = CredentialFilter(credentialsDomainMap);
  let credentials = new List();

  rows.forEach(row => {
    const columns = row.length;
    // Every stored credential has at least 4 columns.
    if (columns < 4) return;
    // LoginURLs are stored in 2nd column, but this value might not be a URL,
    // so we test it first.
    const [name, loginUrl] = row;
    if (!Validator.url(loginUrl)) return;

    const domain = Validator.parseDomain(loginUrl);
    // There might be up to 2 logins and 1 password = 3 entries.
    let loginsAndPwd = row.slice(2, Math.min(columns, 6));

    // Password is stored last and it must exist.
    while (loginsAndPwd.length && !loginsAndPwd[loginsAndPwd.length - 1]) {
      loginsAndPwd = loginsAndPwd.slice(0, loginsAndPwd.length - 1);
    }
    if (!loginsAndPwd.length) return;
    const password = loginsAndPwd[loginsAndPwd.length - 1];
    const logins = loginsAndPwd.slice(0, loginsAndPwd.length - 1);

    // We end up with logins, we use primary value. This array might be empty.
    const login = logins[0] || logins[1] || '';
    const loginType = detectLoginInformationType(login);
    const credential = new Credential({
      [loginType]: login,
      domain,
      loginUrl,
      name,
      password,
    });

    credentials = credentialFilter(credential, true);
  });

  return credentials;
};

export default {
  isSupportedFile,
  name: 'Dashlane',
  processFile,
};
