import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ensureProtocol } from '@nettoken/helpers';
import { getIconUrl } from '@nettoken/helpers';
import { CW_ENCRYPT_CREDENTIALS, getWorkerPromise } from '@nettoken/crypto-worker';
import { Request } from '../../utils/request';
import { getIconColorNewmethod } from '../../utils/misc';
import Styles from './styles.css';
import { getOrCreateAccounts } from '../../main/search';
import { URL_CREDENTIAL } from '../../constants/endpoints';

class Avatar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      imageNotFound: false,
    };

    this.handleError = this.handleError.bind(this);
    this.validateLoginUrl = this.validateLoginUrl.bind(this);
  }

  handleError() {
    this.setState({
      imageNotFound: true,
    });
  }

  async validateLoginUrl() {
    window.open(ensureProtocol(this.props.loginUrl));
  }

  wrapLink() {
    return (
      <a
        className={this.props.source ? Styles.nameIconHoverEffect : Styles.linkIcon}
        href={this.props.loginUrl.includes('http') ? this.props.loginUrl : 'https://'.concat(this.props.loginUrl)}
        rel='noopener noreferrer'
        target='_blank'
      >
        {this.renderChildren()}
      </a>
    );
  }

  renderChildren() {
    const { imageNotFound } = this.state;
    const imgSrc = getIconUrl(this.props.picture);
    if (imgSrc && !imageNotFound) {
      return (
        <img
          className={this.props.source ? Styles.nameIconHoverEffectIcon : Styles.icon}
          src={imgSrc}
          onError={this.handleError}
        />
      );
    }

    return this.renderPlaceholder();
  }

  renderPlaceholder() {
    const color = getIconColorNewmethod(this.props.name);
    const text = this.props.name ?
      this.props.name.slice(0, 2).toUpperCase() : this.props.domain.slice(0, 2).toUpperCase();
    return (
      <div style={{
        background: color,
        width: '100%',
        height: '100%',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '3px',
      }}>
        <span style={{
          color: '#ffffff',
          fontWeight: '700',
        }}>{text}</span>
      </div>
    );
  }

  render() {
    if (this.props.loginUrl) {
      return (
        this.wrapLink()
      );
    }

    return (
      <div className={classNames({
        [Styles.iconWrap]: 1,
        [Styles.iconWrap1]: this.props.grp,
        [this.props.classNameIcon]: this.props.classNameIcon,
      })}>
        {this.renderChildren()}
      </div>
    );
  }
}

Avatar.propTypes = {
  classNameIcon: PropTypes.string,
  domain: PropTypes.string,
  picture: PropTypes.string,
  source: PropTypes.string,
  name: PropTypes.string,
};

export default Avatar;
