/*
 * Copyright (C) 2018-2019 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { i18nRenderLines, noop } from '@nettoken/helpers';
import ActionBar from 'Modal/ActionBar';
import Input from 'Input';
import AppsPreview from 'AppsPreview';
import Tabs from 'Modal/tabs';
import Styles from 'Modal/style.css';
import { Button } from '@nettoken/components';
import downloadfile from 'assets/file/Nettoken template.csv';
import sharedToMeIcon from 'icons/downwardarrow.svg';
import Avatar from 'Avatar';


class ModalStopSharingContainer extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <h1
          aria-label={t('modal.accountAdd.title')}
          className={Styles.title}>
          Stop Sharing
        </h1>


        <div className={Styles.SharingBywrapper}>
          <div className={classNames({
            [Styles.iconHeaderWrapper]: true,
            [Styles.noBg]: true,
          })}>

            {!this.props.accepted &&
              <img
                src={sharedToMeIcon}
                alt=''
                className={Styles.sharedIcon}
              />
            }
            <Avatar
              className={Styles.iconHeaderWrapper}
              domain={this.props.domain}
              loginUrl={this.props.loginUrl}
              name={this.props.name}
              picture={this.props.picture}
            />
          </div>

          <span className={Styles.label}>
            {this.props.name}
          </span>
          <p>
            {`Are you sure you want to stop sharing with ${this.props.sharedWithName}?`}
          </p>

          <div className={classNames({
            [Styles.actionsFixed]: true,
            [Styles.actions]: true,
          })}>
            <Button
              className={classNames({
                [Styles.button]: true,
                [Styles._danger]: true,
              })}
              loaderSize={20}
              loading={this.props.acceptProcessing}
              onClick={this.props.onClickAccept}
              value='Yes' />

            <Button
              className={classNames({
                [Styles.button]: true,
              })}
              loaderSize={20}
              loading={this.props.cancelProcessing}
              onClick={this.props.onClickCancel}
              value='Cancel' />
          </div>
        </div>

      </React.Fragment>
    );
  }
}


export default ModalStopSharingContainer;
