/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { RX_FLAGS_SET, RX_FLAGS_UPDATE } from './actionTypes';

/**
 * @param {object} flags
 */
export const RXFlagsSet = flags => ({
  type: RX_FLAGS_SET,
  flags,
});

/**
 * @param {string} key
 * @param {any} value
 */
export const RXFlagsUpdate = (key, value) => ({
  type: RX_FLAGS_UPDATE,
  key,
  value,
});
