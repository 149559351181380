/*
 * Copyright (C) 2018-2019 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { f1 } from 'constants/flags';
import { MODAL_UNSAVED_CHANGES, MODAL_PASSWORD_CHECKUP } from 'constants/modal';
import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import Validator from '@nettoken/validator';
import { eventTarget, JWTDecode } from '@nettoken/helpers';


import Routes from 'config/routes';
import { getError } from 'main/error/reduxState';
import { getFlag } from 'main/flags/reduxState';
import { showModal } from 'main/modal';
import { setConnectionLostTimeout, setTrackingPermission } from 'main/preferences';
import { goTo } from 'main/router';
import { changeUserPersonalDetails, signOutUser, syncUserPersonalDetails } from 'main/user';
import { RXUserChangesSave, RXUserChangesUndo } from 'main/user/reduxActions';
import { getUserProfile, shouldShowYellowBrickRoad } from 'main/user/reduxState';
import withOverlayAction from 'Overlay/withAction';
import Container from './container';
import {
  MODAL_DASHBOARDS,
  MODAL_DELETE_ACCOUNT,
  MODAL_DOWNLOAD_BACKUP_KEY,
  MODAL_DOWNLOAD_DATA,
  MODAL_MY_ACCOUNT,
  MODAL_SESSION_SETTINGS,
} from '../../../../constants/modal';

class SidebarSettingsComponent extends React.Component {
  constructor(props) {
    super(props);
    const options = [{
      label: '5 seconds',
      value: 5,
    }, {
      label: '30 seconds',
      value: 30,
    }, {
      label: 'Never',
      value: 0,
    }];
    this.state = { hasTestError: false, showMore: false, options };
    this.handleBreakApp = this.handleBreakApp.bind(this);
    this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this);
    this.handleMore = this.handleMore.bind(this);
    this.handlePasswordCheckup = this.handlePasswordCheckup.bind(this);
  }

  componentDidMount() {
    this.props.addOverlayAction('sidebar', () => this.handleAction(() => this.hide()));
  }

  handleAction(callback) {
    if (this.hasChanged()) {
      this.props.showModal(MODAL_UNSAVED_CHANGES, {
        onCancel: () => {
          this.props.RXUserChangesUndo();
          callback();
        },
        onConfirm: e => this.save(e, callback),
      });
      return;
    }

    callback();
  }

  handleBreakApp() {
    this.setState({ hasTestError: true });
  }

  handleChangeCheckbox(event) {
    const { value } = eventTarget(event);
    const connectionLostTimeoutNew = Validator.strToInt(value);
    const timeout = this.getCurrentTimeout();
    this.props.setTimeout(connectionLostTimeoutNew, timeout);
  }

  handleMore() {
    this.setState(prevState => ({
      showMore: !prevState.showMore,
    }));
  }

  handlePasswordCheckup() {
    this.props.showModal(MODAL_PASSWORD_CHECKUP);
  }

  hasChanged() {
    const { changed } = this.props.user;
    return !!Object.keys(changed).length;
  }

  hide() {
    this.props.goToDashboard();
  }

  save(event, callback) {
    if (event) event.preventDefault();
    this.props.RXUserChangesSave();
    this.props.pushSettingsToServer().catch(e => console.log(e));
    callback();
  }

  getCurrentTimeout() {
    if (!this.props.session.accessToken) return -1;
    const jwt = JWTDecode(this.props.session.accessToken) || {};
    const createdAt = (jwt.iat || 0) * 1000;
    const expiryDate = (jwt.exp || 0) * 1000;
    const currentValueInSeconds = (expiryDate - createdAt) / 1000;

    // Check if the current value is in the options list.
    const { options } = this.state;
    for (let i = 0; i < options.length; i += 1) {
      if (options[i].value === currentValueInSeconds) {
        return currentValueInSeconds;
      }
    }
    return -1;
  }

  render() {
    let ct = 0;
    let bck = false;
    Object.keys(this.props.stats.credentials.byDomain).forEach(key => {
      ct += this.props.stats.credentials.byDomain[key].length;
    });
    const te = this.props.user.hasMasterKeyBackup;
    if (te === false) {
      if (ct > 9) {
        bck = true;
      }
    }
    if (this.state.hasTestError) {
      // Don't translate this so the test errors get grouped in Sentry.
      throw new Error('You broke my heart!');
    }

    const user = this.props.getUserProfile();

    return (
      <Container
        email={user.email}
        flagErrorTest={this.props.getFlag(f1.id)}
        getError={this.props.getError}
        highlight={this.props.showYellowBrickRoad()}
        isTracking={this.props.isTracking}
        name={user.name}
        onCancel={() => this.handleAction(() => this.hide())}
        onChange={this.props.changeUserPersonalDetails}
        onChangeCheckbox={this.handleChangeCheckbox}
        onClickButtonBreakApp={this.handleBreakApp}
        onClickButtonBasics={() => this.props.showModal(MODAL_MY_ACCOUNT)}
        onClickButtonDownload={() => this.props.showModal(MODAL_DOWNLOAD_DATA)}
        onClickButtonBackup={() => this.props.showModal(MODAL_DOWNLOAD_BACKUP_KEY)}
        onClickButtonDeleteNettoken={() => this.props.showModal(MODAL_DELETE_ACCOUNT)}
        onClickButtonImport={this.props.goToImport}
        onClickButtonMore={this.handleMore}
        onClickButtonPasswordCheckup={this.handlePasswordCheckup}
        onClickButtonPrivacy={this.props.goToPrivacy}
        onClickButtonSession={() => this.props.showModal(MODAL_SESSION_SETTINGS)}
        onClickButtonSignOut={this.props.signOut}
        onSubmit={e => this.save(e, () => this.hide())}
        options={this.state.options}
        phone={user.phone}
        isbackupkey={bck}
        setTrackingPermission={this.props.setTrackingPermission}
        onClickDashboards={() => this.props.showModal(MODAL_DASHBOARDS)}
        showMore={this.state.showMore}
        unsavedChanges={this.hasChanged()}
        t={this.props.t}
        timeout={this.props.timeout} />
    );
  }
}

const mapStateToProps = state => ({
  // We need this to update the state when flags change.
  flags: state.flags,
  timeout: state.preferences.connectionLostTimeout,
  isTracking: state.preferences.tracking,
  // Update errors when UI state or The Yellow Brick Road change.
  ui: state.ui,
  user: state.user,
  stats: state,
  session: state.session,
});

const mapDispatchToProps = dispatch => ({
  changeUserPersonalDetails: e => dispatch(changeUserPersonalDetails(e)),
  getError: id => dispatch(getError(id)),
  getFlag: id => dispatch(getFlag(id)),
  getUserProfile: () => dispatch(getUserProfile()),
  goToBasics: () => dispatch(goTo(Routes.SETTINGS_BASICS)),
  goToDownload: () => dispatch(goTo(Routes.SETTINGS_DOWNLOAD_DATA)),
  goToBackup: () => dispatch(goTo(Routes.SETTINGS_BACKUP)),
  goToDashboard: () => dispatch(goTo(Routes.DASHBOARD)),
  goToDeleteAccount: () => dispatch(goTo(Routes.SETTINGS_DELETE_ACCOUNT)),
  goToImport: () => dispatch(goTo(Routes.SETTINGS_IMPORT)),
  goToMore: () => dispatch(goTo(Routes.SETTINGS_MORE)),
  goToPrivacy: () => dispatch(goTo(Routes.PRIVACY)),
  goToSession: () => dispatch(goTo(Routes.SETTINGS_SESSION)),
  pushSettingsToServer: () => dispatch(syncUserPersonalDetails()),
  RXUserChangesSave: () => dispatch(RXUserChangesSave()),
  RXUserChangesUndo: () => dispatch(RXUserChangesUndo()),
  setTimeout: timeout => dispatch(setConnectionLostTimeout(timeout)),
  setTrackingPermission: permission => dispatch(setTrackingPermission(permission)),
  showModal: (name, data) => dispatch(showModal(name, data)),
  showYellowBrickRoad: () => dispatch(shouldShowYellowBrickRoad()),
  signOut: () => dispatch(signOutUser()),
});

export default translate()(connect(
  mapStateToProps,
  mapDispatchToProps,
)(withOverlayAction(SidebarSettingsComponent)));
