/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
export const RX_USER_CHANGES_SAVE = 'RX_USER_CHANGES_SAVE';
export const RX_USER_CHANGES_TEMP = 'RX_USER_CHANGES_TEMP';
export const RX_USER_CHANGES_UNDO = 'RX_USER_CHANGES_UNDO';
export const RX_USER_LOG_IN = 'RX_USER_LOG_IN';
export const RX_USER_LOG_OUT = 'RX_USER_LOG_OUT';
export const RX_ADD_DASHBOARD_INVITES = 'RX_ADD_DASHBOARD_INVITES';
export const RX_REMOVE_DASHBOARD_INVITES = 'RX_REMOVE_DASHBOARD_INVITES';
