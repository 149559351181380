import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import withOverlayAction from 'Overlay/withAction';
import { Credential } from '@nettoken/models';
import { eventTarget } from '@nettoken/helpers';
import { hideModal } from 'main/modal';
import { updateCredential } from 'main/vault/credentials';
import { getOneCredential } from 'main/vault/credentials/reduxState';
import { catchUIError, hideUIError } from 'main/error';
import { RxEditAccountProccessing, RXToasterShow } from '../../../../main/modal/reduxActions';

import Container from './container';
import { MODAL_MEMO_ADD, MODAL_UNSAVED_CHANGES } from '../../../../constants/modal';
import { showModal } from '../../../../main/modal';

class ModalMemoAddComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      note: this.props.data.note,
      isEditMemo: this.props.data.note.length > 0,
      isChanged: false,
      isloading: false,
    };

    this.editApp = this.editApp.bind(this);
    this.hide = this.hide.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleAction = this.handleAction.bind(this);
  }

  componentDidMount() {
    this.props.addOverlayAction('modal', () => this.handleAction(() => this.hide()));
  }

  hide() {
    this.props.hide();
  }

  handleChange(event) {
    if (this.state.note.length >= 200) {
      return;
    }
    const { name, value } = eventTarget(event);
    this.setState({
      [name]: value,
      isChanged: this.props.data.note !== value,
    });
  }

  handleSubmit(event) {
    this.setState({ isloading: true });
    this.props.setEditAccountProccessing(true);
    event.preventDefault();
    this.props.hideUIError();
    this.editApp();
  }

  editApp() {
    const { id } = this.props.data;
    const prevData = this.props.getCredential(id);

    const credential = new Credential({
      ...prevData,
      note: this.state.note,
    });

    const errors = credential.validate();
    if (errors) {
      this.props.catchUIError(errors);
      return;
    }

    this.props.updateCredential({ credential, id });
    this.setState({ isChanged: false });
  }

  handleAction(callback) {
    const { dispatch } = this.props;
    this.props.data.unsaved = this.state;
    if (this.state.isChanged) {
      dispatch(showModal(MODAL_UNSAVED_CHANGES, {
        onCancel: () => {
          callback();
        },
        onConfirm: e => this.handleSubmit(e, true, callback),
        currentData: this.props.data,
        modalName: MODAL_MEMO_ADD,
      }));
      return;
    }

    if (!this.props.editAccountProccessing) {
      callback();
    }
  }

  render() {
    return (
      <Container
        domain={this.props.data.domain}
        isEditMemo={this.state.isEditMemo}
        loginUrl={this.props.data.loginUrl}
        note={this.state.note}
        name={this.props.data.name}
        onCancel={() => this.handleAction(() => this.hide())}
        onChange={this.handleChange}
        onSubmit={this.handleSubmit}
        picture={this.props.data.picture}
        t={this.props.t}
        isChanged={this.state.isChanged}
        isloading={this.state.isloading}
        editAccountProccessing={this.props.editAccountProccessing}
        isShared={this.props.data.shared}
        isExternalAccount={this.props.data.externalAccount}
      />
    );
  }
}

const mapStateToProps = state => ({
  data: state.ui.modalData || {},
  // Needed to re-render when UI error changes.
  ui: state.ui,
  editAccountProccessing: state.ui.editAccountProccessing,
});

const mapDispatchToProps = dispatch => ({
  dispatch,
  catchUIError: e => dispatch(catchUIError(e)),
  getCredential: id => dispatch(getOneCredential(id)),
  hide: () => {
    dispatch(RxEditAccountProccessing(false));
    return dispatch(hideModal());
  },
  hideUIError: () => dispatch(hideUIError()),
  updateCredential: args => dispatch(updateCredential(args)),
  setEditAccountProccessing: status => dispatch(RxEditAccountProccessing(status)),
});

export default translate()(connect(
  mapStateToProps,
  mapDispatchToProps,
)(withOverlayAction(ModalMemoAddComponent)));
