/*
 * Copyright (C) 2018-2019 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { hideModal } from 'main/modal';
import withOverlayAction from 'Overlay/withAction';
import Container from './container';

class ModalConnectionLost extends React.Component {
  constructor(props) {
    super(props);

    this.hide = this.hide.bind(this);
  }

  componentDidMount() {
    this.props.addOverlayAction('modal', this.hide, false);
  }

  hide() {
    this.props.hide();
  }

  render() {
    return (
      <Container
        onCancel={this.hide}
        t={this.props.t} />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  hide: () => dispatch(hideModal()),
});

export default translate()(connect(
  null,
  mapDispatchToProps,
)(withOverlayAction(ModalConnectionLost)));
