/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { SEARCH1 } from 'constants/ids';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from '@nettoken/components';
import { noop } from '@nettoken/helpers';
import Counter from 'Counter';
import AppsPreview from 'AppsPreview';
import Input from 'Input';
import sharedAccountIcon from 'icons/upwardarrow.svg';
import sharedToMeIcon from 'icons/downwardarrow.svg';
import Styles from 'Modal/style.css';
import GStyles from '../../../../styles/index.css';
import cross from '../../../../assets/images/cross.svg';
import leftsvg from '../../../../assets/images/left.svg';
import union from '../../../../assets/images/singleIcon.svg';
import switchIcon from '../../../../assets/images/switch.svg';
import LeftArrowIcon from '../../../ActionIcons/LeftArrowIcon';
import CancelIcon from '../../../ActionIcons/CancelIcon';
import ClearIcon from '../../../ActionIcons/ClearIcon';
import { getIconColorNewmethod } from '../../../../utils/misc';
import dropdown from '../../../../assets/images/leftArrow_icon/active.svg';

class ModalMoveAccounts extends React.Component {
  getRowLabel(app) {
    let { name } = app;
    if (app.displayLogin) name += ` (${app.displayLogin})`;
    return name;
  }

  render() {
    const {
      step,
      t,
      selectedDashboardName,
      switchDashboards,
      backBtnPresent,
    } = this.props;
    return (
      <React.Fragment>
        <h1
          aria-label={t('modal.accountRemove.title')}
          className={`${Styles.title} ${Styles.stickyHeaderList}`}
          style={{ backgroundColor: '#fff' }}
        >
          {(step === 'select' && backBtnPresent === 'true') ? <LeftArrowIcon onClickBackHandler={this.props.onClickBackHandler} /> : ''}
          Move accounts
          <CancelIcon onCancel={this.props.onCancel} />
        </h1>

        <form
          action="/"
          className={`${Styles.formEmailScanPreview} ${Styles.stickyMinHeightBackground}`}
          method="post"
          onSubmit={this.props.onSubmit}
          style={{
            paddingTop: '0px',
          }}
        >
          <div className={`${Styles.stickyfixedForm2}`}>
            <div
              className={classNames({
                [Styles.stickySearchHeader2]: true,
              })}
              style={{
                padding: '10px',
                textAlign: 'center',
                backgroundColor: '#DCDBDE',
                zIndex: '4',
              }}
            >
              <span
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexWrap: 'wrap',
                  alignItems: 'center',
                  columnGap: '5px',
                }}
              >
                {step === 'select' ? 'Select and move accounts between dashboards:' :
                  `Check again! Move ${Object.keys(this.props.apps).length} account${Object.keys(this.props.apps).length > 1 ? 's' : ''} between dashboards:`}
                <div className={Styles.break}></div>
                <div style={{
                  display: 'flex',
                  justifyContent: 'space-evenly',
                  alignContent: 'center',
                  alignItems: 'center',
                }}>

                  {step === 'select' && this.props.dashboards.length >= 1 && (
                    <div>
                      <div
                        style={{
                          height: '48px',
                          minWidth: '200px',
                          display: 'grid',
                          background: 'white',
                          justifyContent: 'space-around',
                          alignItems: 'center',
                          cursor: 'pointer',
                          borderRadius: '22px',
                          position: 'relative',
                          gridTemplateColumns: '0.32fr 0.6fr 0.2fr',
                          columnGap: '13px',
                        }}
                        onClick={this.props.openDropdown}
                      >
                          {this.props.selectedDashboardId &&
                            !this.props.dashboardList[this.props.selectedDashboardId]
                              .externalDashboard &&
                            'usersSharedWith' in this.props.dashboardList[this.props.selectedDashboardId] &&
                            this.props.dashboardList[this.props.selectedDashboardId]
                              .usersSharedWith &&
                            this.props.dashboardList[this.props.selectedDashboardId]
                              .usersSharedWith.length > 0 ?
                            (
                            <img
                              src={sharedAccountIcon}
                              alt=''
                              className={Styles.sharedIcon}
                              style={{ position: 'absolute', left: '26px', top: '-4px' }}
                            />
                            ) : null}
                        <div style={{ float: 'left' }}>
                          <svg width='37px' height='37px' className={`${GStyles.sectionItemActive}`}>
                            <rect x='0' y='0' fill={getIconColorNewmethod(this.props.selectedDashboard)} height='100%' width='100%'></rect>
                            <text
                              dominantBaseline='middle'
                              fill='white'
                              fontSize='10'
                              textAnchor='middle'
                              x='48%'
                              y='54%'
                            >{this.props.selectedDashboard.substring(0, 2).toUpperCase()}</text>
                          </svg>
                        </div>
                        <div style={{ float: 'left', textAlign: 'left' }}>
                          <div style={{ fontWeight: 'bold' }}>{this.props.selectedDashboard.substring(0, 10)}</div>
                          <div className={`${GStyles.dashboardNameSidePane}`} style={{ marginTop: '0px' }}>{`${this.props.selectedDashboardCounter} accounts`}</div>
                        </div>
                        <img src={dropdown} style={{
                          transform: 'rotate(270deg)',
                        }} />
                      </div>
                      <ul
                        style={{
                          display: `${this.props.showDropdown ? 'block' : 'none'}`,
                          position: 'absolute',
                          overflow: 'auto',
                          height: '25em',
                        }}
                        className={Styles.dashboardDropdownMoveAccounts}
                      >
                        {this.props.dashboards.map(dash => (
                          <li
                            onClick={
                              () => this.props.onChangeDashboard(
                                dash.value,
                                dash.name,
                                dash.counter,
                              )
                            }
                            style={{
                              borderLeft: '1px solid #dcdbde',
                              borderRight: '1px solid #dcdbde',
                              background: 'white',
                              height: '48px',
                              minWidth: '200px',
                              display: 'grid',
                              justifyContent: 'space-around',
                              alignItems: 'center',
                              cursor: 'pointer',
                              position: 'relative',
                              gridTemplateColumns: '0.25fr 0.7fr',
                              columnGap: '13px',
                            }}
                          >
                            {!dash.externalDashboard &&
                              'usersSharedWith' in dash &&
                              dash.usersSharedWith &&
                              dash.usersSharedWith.length > 0 ?
                              (
                                <img
                                  src={sharedAccountIcon}
                                  alt=''
                                  className={Styles.sharedIcon} style={{ position: 'absolute', left: '26px', top: '-4px' }}
                                />
                              ) : null
                            }
                            <div style={{ float: 'left' }}>
                              <svg width='37px' height='37px' className={`${GStyles.sectionItemActive}`}>
                                <rect x='0' y='0' fill={getIconColorNewmethod(dash.name)} height='100%' width='100%'></rect>
                                <text
                                  dominantBaseline='middle'
                                  fill='white'
                                  fontSize='10'
                                  textAnchor='middle'
                                  x='48%'
                                  y='54%'
                                >{dash.name.substring(0, 2).toUpperCase()}</text>
                              </svg>
                            </div>
                            <div style={{ float: 'left', textAlign: 'left' }}>
                              <div style={{ fontWeight: 'bold' }}>{dash.name.substring(0, 10)}</div>
                              <div className={`${GStyles.dashboardNameSidePane}`} style={{ marginTop: '0px' }}>{`${dash.counter} accounts`}</div>
                            </div>
                            {/* <img src={dropdown} style={{ visibility: 'hidden' }} /> */}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                  {step === 'select' && this.props.dashboards.length === 0 && (
                    <>
                      <div style={
                        {
                          background: 'white',
                          height: '48px',
                          minWidth: '200px',
                          display: 'grid',
                          justifyContent: 'space-around',
                          alignContent: 'center',
                          alignItems: 'center',
                          borderRadius: '22px',
                          position: 'relative',
                          gridTemplateColumns: '0.25fr 0.7fr',
                          columnGap: '13px',
                        }
                      }>
                        {this.props.selectedDashboardId &&
                          !this.props.dashboardList[this.props.selectedDashboardId]
                            .externalDashboard &&
                          'usersSharedWith' in this.props.dashboardList[this.props.selectedDashboardId] &&
                          this.props.dashboardList[this.props.selectedDashboardId]
                            .usersSharedWith &&
                          this.props.dashboardList[this.props.selectedDashboardId]
                            .usersSharedWith.length > 0 ?
                          (
                          <img
                            src={sharedAccountIcon}
                            alt=''
                            className={Styles.sharedIcon}
                            style={{ position: 'absolute', left: '26px', top: '-4px' }}
                          />
                          ) : null}
                        <div style={{ float: 'left' }}>
                          <svg width='37px' height='37px' className={`${GStyles.sectionItemActive}`}>
                            <rect x='0' y='0' fill={getIconColorNewmethod(this.props.selectedDashboard)} height='100%' width='100%'></rect>
                            <text
                              dominantBaseline='middle'
                              fill='white'
                              fontSize='10'
                              textAnchor='middle'
                              x='48%'
                              y='54%'
                            >{this.props.selectedDashboard.substring(0, 2).toUpperCase()}</text>
                          </svg>
                        </div>
                        <div style={{ float: 'left', textAlign: 'left' }}>
                          <div style={{ fontWeight: 'bold' }}>{this.props.selectedDashboard.substring(0, 10)}</div>
                          <div className={`${GStyles.dashboardNameSidePane}`} style={{ marginTop: '0px' }}>{`${this.props.selectedDashboardCounter} accounts`}</div>
                        </div>
                        {/* <img src={dropdown} style={{ visibility: 'hidden' }} /> */}
                      </div>
                    </>
                  )}
                  {step !== 'select' && Object.values(selectedDashboardName).map(dash => (
                    <div style={
                      {
                        background: 'white',
                        height: '48px',
                        minWidth: '200px',
                        display: 'grid',
                        justifyContent: 'space-around',
                        alignContent: 'center',
                        alignItems: 'center',
                        borderRadius: '22px',
                        position: 'relative',
                        gridTemplateColumns: '0.25fr 0.7fr',
                        columnGap: '13px',
                      }
                    }>
                      {!dash.externalDashboard &&
                      'usersSharedWith' in dash &&
                      dash.usersSharedWith &&
                      dash.usersSharedWith.length > 0 ?
                        (
                          <img
                            src={sharedAccountIcon}
                            alt=''
                            className={Styles.sharedIcon} style={{ position: 'absolute', left: '26px', top: '-4px' }}
                          />
                        ) : null
                      }
                      <div style={{ float: 'left' }}>
                        <svg width='37px' height='37px' className={`${GStyles.sectionItemActive}`}>
                          <rect x='0' y='0' fill={getIconColorNewmethod(dash.name.toString())} height='100%' width='100%'></rect>
                          <text
                            dominantBaseline='middle'
                            fill='white'
                            fontSize='10'
                            textAnchor='middle'
                            x='48%'
                            y='54%'
                          >{dash.name.toString().substring(0, 2).toUpperCase()}</text>
                        </svg>
                      </div>
                      <div style={{ float: 'left', textAlign: 'left' }}>
                        <div style={{ fontWeight: 'bold' }}>{dash.name.toString().substring(0, 10)}
                        </div>
                        <div className={`${GStyles.dashboardNameSidePane}`} style={{ marginTop: '0px' }}>{`${dash.counter} accounts`}</div>
                      </div>
                      {/* <img src={dropdown} style={{ visibility: 'hidden' }} /> */}
                    </div>
                  ))}
                  {step === 'select' && this.props.selected.size <= 0 && <img
                    src={union}
                    style={{ paddingLeft: '10px', paddingRight: '10px', cursor: 'pointer' }}
                    onMouseEnter={e => e.target.src = switchIcon}
                    onMouseLeave={e => e.target.src = union}
                    onClick={switchDashboards}
                  />}
                  {(step !== 'select' || this.props.selected.size > 0) && <img
                    src={union}
                    style={{ paddingLeft: '10px', paddingRight: '10px' }}
                  />}
                  <div style={
                    {
                      background: 'white',
                      height: '48px',
                      minWidth: '200px',
                      display: 'grid',
                      justifyContent: 'space-around',
                      alignContent: 'center',
                      alignItems: 'center',
                      borderRadius: '22px',
                      position: 'relative',
                      gridTemplateColumns: '0.25fr 0.7fr',
                      columnGap: '13px',
                    }
                  }>
                    {this.props.currentDashboard &&
                      !this.props.dashboardList[this.props.currentDashboard]
                        .externalDashboard &&
                      'usersSharedWith' in this.props.dashboardList[this.props.currentDashboard] &&
                      this.props.dashboardList[this.props.currentDashboard]
                        .usersSharedWith &&
                      this.props.dashboardList[this.props.currentDashboard]
                        .usersSharedWith.length > 0 ? (
                        <img
                          src={sharedAccountIcon}
                          alt=''
                          className={Styles.sharedIcon}
                          style={{ position: 'absolute', left: '26px', top: '-4px' }}
                        />
                      ) : null
                      }
                    <div style={{ float: 'left' }}>
                      <svg width='37px' height='37px' className={`${GStyles.sectionItemActive}`}>
                        <rect x='0' y='0' fill={getIconColorNewmethod(this.props.dashboardName.toString())} height='100%' width='100%'></rect>
                        <text
                          dominantBaseline='middle'
                          fill='white'
                          fontSize='10'
                          textAnchor='middle'
                          x='48%'
                          y='54%'
                        >{this.props.dashboardName.substring(0, 2).toUpperCase()}</text>
                      </svg>
                    </div>
                    <div style={{ float: 'left', textAlign: 'left' }}>
                      <div style={{ fontWeight: 'bold' }}>{this.props.dashboardName.substring(0, 10)}</div>
                      <div className={`${GStyles.dashboardNameSidePane}`} style={{ marginTop: '0px' }}>{`${this.props.dashboardCounter} accounts`}</div>
                    </div>
                    {/* <img src={dropdown} style={{ visibility: 'hidden' }} /> */}
                  </div>
                </div>
              </span>
            </div>
            <div className={`${Styles.searchWrapper} ${Styles.stickySearchHeader3}`}>
              <input
                aria-label={t('placeholder.search')}
                className={Styles.search}
                onChange={this.props.onChangeSearch}
                placeholder={`${t('placeholder.search')}...`}
                role="search"
                type="text"
                id={SEARCH1}
                value={this.props.query} />
                {this.props.query.length > 0 ?
                  <ClearIcon onClickClearSearch={this.props.onClickClearSearch} /> :
                  null
                }
            </div>
            <div className={`${Styles.actions2} ${Styles.stickyActionBar3}`}></div>
            <div className={`${Styles.stickyAppList4} ${Styles.stickyAppListExtra}`}>
              <AppsPreview
                apps={this.props.apps}
                isEmailEditable={false}
                isPasswordEditable={false}
                isGroupEditable={false}
                isRemoveAccount={false}
                onClickPasswordToggle={this.props.onClickPasswordToggle}
                onSelectApp={this.props.onClickApp}
                selectedApps={this.props.selected}
                isMoveAccount={true}
              />
            </div>
          </div>

          <div className={classNames({
            [Styles.actions]: true,
            [Styles.accountCounter]: true,
            [Styles.actionsFixed]: true,
          })} style={{ maxWidth: '780px', width: '92vw' }}>

            <span>
              <Counter
                noGreyBar={true}
                colour="#fff"
                count={this.props.selected.size}
                font="sm"
                label={'Accounts selected'}
                size={32}
              />
            </span>

            <div>
              <Button
                className={classNames({
                  [Styles.button]: true,
                  [Styles.notDisabled]: this.props.canSubmit,
                })}
                onClick={e => e.target.blur()}
                disabled={!this.props.canSubmit}
                loaderSize={20}
                loading={this.props.isSubmitting}
                type="submit"
                value={step === 'confirm' ? 'Move' : 'Preview'} />
            </div>
          </div>
        </form>
      </React.Fragment>
    );
  }
}

ModalMoveAccounts.defaultProps = {
  apps: [],
  canSubmit: false,
  isLoading: true,
  isSubmitting: false,
  onCancel: noop,
  onChangeSearch: noop,
  onClickApp: noop,
  onSubmit: noop,
  query: '',
  selected: 0,
  step: '',
  selectedDashboardName: [],
};

ModalMoveAccounts.propTypes = {
  apps: PropTypes.array,
  canSubmit: PropTypes.bool,
  isLoading: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  onCancel: PropTypes.func,
  onChangeSearch: PropTypes.func,
  onClickClearSearch: PropTypes.func,
  onSubmit: PropTypes.func,
  query: PropTypes.string,
  selected: PropTypes.number,
  step: PropTypes.string,
  selectedDashboardName: PropTypes.array,
  /** i18n translate method */
  t: PropTypes.func.isRequired,
};

export default ModalMoveAccounts;
