import React from 'react';
import activeCancel from '../../assets/images/leftArrow_icon/active.svg';
import hoverCacnel from '../../assets/images/leftArrow_icon/hover.svg';
import clickCancel from '../../assets/images/leftArrow_icon/click.svg';

const DownArrowIcon = props => (
  <img
    onClick={props.toggleShow}
    src={activeCancel}
    style={{
      cursor: 'pointer',
      transform: 'rotate(270deg)',
      position: 'absolute',
      right: '10px',
      top: '25%',
    }}
  />
);

export default DownArrowIcon;
