/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import React from 'react';
import PropTypes from 'prop-types';
import Container from './container';

const CounterComponent = ({
  background,
  className,
  colour,
  count,
  font,
  label,
  size,
  rotation,
  noGreyBar,
  isfocus,
  undocked,
}) => (
  <Container
    noGreyBar={noGreyBar}
    background={background}
    className={className}
    colour={colour}
    count={count}
    font={font}
    label={label}
    size={size}
    rotation={rotation}
    isfocus={isfocus}
    undocked={undocked}
  />
);

CounterComponent.propTypes = {
  background: PropTypes.string,
  className: PropTypes.string,
  colour: PropTypes.string,
  count: PropTypes.number,
  label: PropTypes.string,
  size: PropTypes.number,
  rotation: PropTypes.bool,
};

export default CounterComponent;
