/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { MODAL_ACCOUNT_EDIT, MODAL_SHARING_BY_INVITATION } from 'constants/modal';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  addClass,
  addEvent,
  ensureProtocol,
  getDocumentRelativeCoordinates,
  getIconUrl,
  pointBelongsToRectangle,
  removeClass,
  removeEvent,
} from '@nettoken/helpers';

import { Credential } from '@nettoken/models';
import { getWorkerPromise, CW_ENCRYPT_CREDENTIALS } from '@nettoken/crypto-worker';
import { Request } from 'utils/request';
import { showModal } from 'main/modal';
import { hasOverlay } from 'main/modal/reduxState';
import { getGroupIdFromCredentialId } from 'main/vault/credentials/reduxState';
import { countGroupCredentials } from 'main/vault/groups/reduxState';
import { pendingInvitationGroupId } from 'main/vault/credentials';
import { MOVE_ATTRIBUTE, MOVE_CLASS_ICON } from 'utils/move';
import { PropIcon } from 'utils/propTypes';
import sharedAccountIcon from 'icons/upwardarrow1.svg';
import sharedByIcon from 'icons/downwardarrow1.svg';
import { getSharedCredentialData } from 'main/sharedAccounts';
import Container from './container';
import Styles from './style.css';
import { getOrCreateAccounts, getPredefinedAccounts } from '../../main/search';
import { URL_CREDENTIAL } from '../../constants/endpoints';

class IconComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ishover: false,
    };

    this.className = this.getWrapperClassName();
    this.copyWrapper = null;
    this.timerInfoHide = null;
    this.touched = false;

    this.handleClick = this.handleClick.bind(this);
    this.handleInfoMouseEnter = this.handleInfoMouseEnter.bind(this);
    this.handleInfoMouseLeave = this.handleInfoMouseLeave.bind(this);
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.handleTouchStart = this.handleTouchStart.bind(this);
    this.openCredentialInfo = this.openCredentialInfo.bind(this);
  }


  componentDidUpdate(prevProps) {
    // Update copied info wrapper node.
    const { selected } = this.props;
    if (prevProps.selected !== selected && this.copyWrapper) {
      const className = Styles._selected;
      const node = this.copyWrapper.firstChild;
      if (selected) {
        addClass(node, className);
      }
      else {
        removeClass(node, className);
      }
    }
  }

  componentWillUnmount() {
    // Trigger this event as we might be hovering over the info box
    // which creates a node copy and this would not get removed on its own.
    this.handleMouseLeave();
  }

  getInfoClickHandler() {
    return this.props.disableAppInfo ? this.handleClick : this.openCredentialInfo;
  }

  getWrapperClassName() {
    const { id, loginUrl } = this.props;
    const className = {};

    if (this.props.isDragged) {
      const groupId = this.props.getGroupIdFromCredentialId(id);
      if (groupId || this.props.countGroupCredentials(groupId) !== null) {
        className[Styles.hidden] = true;
      }
    }
    const groupId = this.props.getGroupIdFromCredentialId(id);
    if (!loginUrl || !this.props.draggable) {
      className[Styles.nonclickable] = true;
    }
    if (groupId === pendingInvitationGroupId() || this.props.externalDashboard) {
      className[MOVE_CLASS_ICON] = false;
    }
    else {
      className[MOVE_CLASS_ICON] = true;
    }

    return className;
  }

  async handleClick(event) {
    /*
     * Prevent click callback from triggering if we are interaction with
     * the elements. (This bug was discovered in Firefox.)
     */
    if (this.props.isDragged || this.props.disableAppOpen) {
      event.preventDefault();

      if (this.props.onSelect) {
        this.props.onSelect(this.props.id);
      }
      return;
    }

    const { loginUrl } = this.props;
    if (loginUrl && !this.touched) window.open(ensureProtocol(loginUrl));
  }

  handleInfoMouseEnter() {
    clearTimeout(this.timerInfoHide);
    this.setState({ ishover: true });
  }

  handleInfoMouseLeave(event) {
    const { container } = this.refs;
    // This might occur if the user manages to trigger this
    // method while we are removing the component.
    if (!container) return;

    const { icon } = container.refs;
    const iconBox = getDocumentRelativeCoordinates(icon);
    const cursor = { x: event.pageX, y: event.pageY };

    if (!pointBelongsToRectangle(cursor, iconBox)) {
      this.handleMouseLeave();
    }
  }

  handleMouseEnter() {
    clearTimeout(this.timerInfoHide);
    const {
      isShared,
      isExternalAccount,
      sharedAccounts,
      id,
      sharedDashboard,
    } = this.props;

    if (this.copyWrapper) return;

    const { infoWrapper } = this.refs.container.refs;
    if (!infoWrapper) return;

    const infoWrapperBox = getDocumentRelativeCoordinates(infoWrapper, false);
    this.copyWrapper = infoWrapper.cloneNode(true);
    this.copyWrapper.style.left = `${infoWrapperBox.left - 7}px`;
    this.copyWrapper.style.top = `${infoWrapperBox.top - 18}px`;

    if (this.props.hasOverlay()) {
      this.copyWrapper.style.zIndex = '999';
    }

    addEvent(this.copyWrapper, 'mouseenter', this.handleInfoMouseEnter);
    addEvent(this.copyWrapper, 'mouseleave', this.handleInfoMouseLeave);
    addEvent(this.copyWrapper, 'click', this.getInfoClickHandler());


    const username = this.props.email || this.props.username;
    const span = document.createElement('span');

    if (username) {
      addClass(span, Styles.username);
      span.innerText = username;
    }

    if (isShared && !isExternalAccount && sharedAccounts !== undefined) {
      if (sharedAccounts[id] && sharedAccounts[id].length !== 0) {
        const updatedSharedAccounts = sharedAccounts[id].filter(x => !x.sharedByDashboard);
        const counts = updatedSharedAccounts.length;
        if (counts > 0) {
          addClass(span, Styles.usernameWith);
          const img = document.createElement('img');
          img.src = sharedAccountIcon;
          const span2 = document.createElement('span');
          addClass(img, Styles.sharedIcon);
          addClass(span2, Styles.sharedText);
          span2.innerText = counts > 1 ? `Shared with ${updatedSharedAccounts[0].name}
          and ${counts - 1} ${counts - 1 > 1 ? 'others' : 'other'}` :
            `Shared with ${updatedSharedAccounts[0].name}`;
          span.append(span2);
          span.append(img);
          addClass(this.copyWrapper, Styles.hoverTrue);
        }
      }
    }
    if (!this.props.externalDashboard && isShared && isExternalAccount &&
      this.props.sharedByUserName) {
      addClass(span, Styles.username);
      const img = document.createElement('img');
      img.src = sharedByIcon;
      const span2 = document.createElement('span');
      addClass(img, Styles.sharedIcon);
      addClass(span2, Styles.sharedText);
      span2.innerText = `Shared by ${this.props.sharedByUserName}`;
      span.append(span2);
      span.append(img);
      addClass(this.copyWrapper, Styles.hoverTrue);
    }

    if (!this.props.externalDashboard && !this.props.accepted && this.props.sharedByUserName) {
      addClass(span, Styles.username);
      const img = document.createElement('img');
      img.src = sharedByIcon;
      const span2 = document.createElement('span');
      addClass(img, Styles.sharedIcon);
      addClass(span2, Styles.sharedText);
      span2.innerText = `Shared by ${this.props.sharedByUserName}`;
      span.append(span2);
      span.append(img);
      addClass(this.copyWrapper, Styles.hoverTrue);
    }

    addClass(this.copyWrapper, Styles.hideI);
    this.copyWrapper.appendChild(span);
    document.body.appendChild(this.copyWrapper);
    this.setState({ ishover: true });

    if (span) addClass(span, Styles.usernameVisible);
    if (this.copyWrapper && this.copyWrapper.firstChild) {
      addClass(this.copyWrapper.firstChild, Styles.usernameVisible);
    }
  }

  handleMouseLeave() {
    this.timerInfoHide = setTimeout(() => {
      if (!this.copyWrapper ||
        (this.copyWrapper && (!this.copyWrapper.firstChild || !this.copyWrapper.lastChild))) return;

      removeClass(this.copyWrapper.firstChild, Styles.usernameVisible);
      removeClass(this.copyWrapper.lastChild, Styles.usernameVisible);
      this._copyWrapper = this.copyWrapper;
      this.copyWrapper = null;
      this.setState({ ishover: false });

      setTimeout(() => {
        if (!this._copyWrapper) return;
        removeEvent(this._copyWrapper, 'mouseenter', this.handleInfoMouseEnter);
        removeEvent(this._copyWrapper, 'mouseleave', this.handleInfoMouseLeave);
        removeEvent(this._copyWrapper, 'click', this.getInfoClickHandler());
        this._copyWrapper.remove();
        this.touched = false;
      }, 100);
    }, 100);
  }

  /**
   * For mobile devices
   */
  handleTouchStart() {
    this.touched = !this.touched;
  }

  async openCredentialInfo(event) {
    if (event) event.stopPropagation();
    if (this.props.disableAppInfo) return;
    this.handleMouseLeave();
    const credential = new Credential(this.props);

    if (!this.props.accepted) {
      this.props.showModal(MODAL_SHARING_BY_INVITATION, credential.getState());
    }
    else {
      this.props.showModal(MODAL_ACCOUNT_EDIT, credential.getState());
    }
  }

  render() {
    const { id, sharedAccounts } = this.props;
    const props = {};
    const picture = this.props.picture && getIconUrl(this.props.picture, 48);
    if (!this.props.disableAppMove) props[MOVE_ATTRIBUTE] = id;
    let showsss;
    if (sharedAccounts[id] === undefined) {
      showsss = undefined;
    }
    else if (sharedAccounts[id] instanceof Array) {
      if (sharedAccounts[id].filter(x => !x.sharedByDashboard).length === 0) {
        showsss = false;
      }
      else {
        showsss = true;
      }
    }
    else {
      showsss = true;
    }

    return (
      <Container
        ishover={this.state.ishover}
        classNameIcon={this.props.picture ? Styles.noBg : ''}
        classNameWrapper={this.className}
        disableAppInfo={this.props.isDragged || this.props.disableAppInfo}
        domain={this.props.domain}
        id={id}
        sharedAccounts={showsss}
        name={this.props.name}
        onClick={this.openCredentialInfo}
        onDoubleClick={this.handleClick}
        onClickInfo={this.openCredentialInfo}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        onTouchStart={this.handleTouchStart}
        picture={picture}
        ref="container"
        groupId={this.props.groupId}
        selectable={!!this.props.onSelect}
        selected={this.props.selected}
        spreadProps={props}
        isShared={this.props.isShared}
        isExternalAccount={this.props.isExternalAccount}
        accepted={this.props.accepted}
        invitationId={this.props.invitationId}
        t={this.props.t}
        isUnsorted={'isUnsorted' in this.props ? this.props.isUnsorted : false}
        sharedDashboard={this.props.sharedDashboard}
        externalDashboard={this.props.externalDashboard}
        />
    );
  }
}

IconComponent.defaultProps = {
  draggable: true,
};

IconComponent.propTypes = {
  cookieUrl: PropTypes.string,
  disableAppInfo: PropTypes.bool,
  disableAppMove: PropTypes.bool,
  disableAppOpen: PropTypes.bool,
  draggable: PropTypes.bool,
  email: PropTypes.string.isRequired,
  groupId: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  loginUrl: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onSelect: PropTypes.func,
  password: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  picture: PropIcon(),
  privacyUrl: PropTypes.string,
  selected: PropTypes.bool,
  /** i18n translate method */
  t: PropTypes.func.isRequired,
  username: PropTypes.string.isRequired,
};

const mapStateToProps = (state, props) => {
  let sharedDashboard = false;
  if (
    'dashboardSpaceId' in state.credentials.data[props.id] &&
    state.credentials.data[props.id].dashboardSpaceId &&
    (
      (
        'externalDashboard' in state.ui.dashboards[state.credentials.data[props.id].dashboardSpaceId] &&
        state.ui.dashboards[state.credentials.data[props.id].dashboardSpaceId].externalDashboard
      ) ||
      (
        'shared' in state.ui.dashboards[state.credentials.data[props.id].dashboardSpaceId] &&
        state.ui.dashboards[state.credentials.data[props.id].dashboardSpaceId].shared
      )
    ) &&
    (
      !(props.id in state.sharedAccounts) ||
      state.sharedAccounts[props.id].filter(x => !x.sharedByDashboard).length == 0
    )
  ) {
    sharedDashboard = true;
  }
  return {
    isDragged: state.ui.moveId === props.id,
    isShared: state.credentials.data[props.id].shared,
    isExternalAccount: state.credentials.data[props.id].externalAccount,
    sharedAccounts: state.sharedAccounts,
    sharedDashboard,
    externalDashboard: state.ui.currentDashboard &&
      ('externalDashboard' in state.ui.dashboards[state.ui.currentDashboard] && state.ui.dashboards[state.ui.currentDashboard].externalDashboard),

  };
};

const mapDispatchToProps = dispatch => ({
  countGroupCredentials: id => dispatch(countGroupCredentials(id)),
  getGroupIdFromCredentialId: id => dispatch(getGroupIdFromCredentialId(id)),
  hasOverlay: () => dispatch(hasOverlay()),
  showModal: (name, data) => dispatch(showModal(name, data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(IconComponent);
