/*
 * Copyright (C) 2018-2019 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { isProduction } from '@nettoken/env';
import { eventTarget, JWTDecode } from '@nettoken/helpers';
import Validator from '@nettoken/validator';
import Routes from 'config/routes';
import { goTo } from 'main/router';
import { setSessionTimeout, setConnectionLostTimeout } from 'main/preferences';
import withOverlayAction from 'Overlay/withAction';
import Container from './container';

const minToSec = minutes => minutes * 60;
const hrsToSec = hours => hours * minToSec(60);

class SidebarSessionComponent extends React.Component {
  constructor(props) {
    super(props);

    const { t } = props;
    let options = [{
      label: t('views.session.options.thirtyMinutes'),
      value: minToSec(30),
    }, {
      label: t('views.session.options.eightHours'),
      value: hrsToSec(8),
    }, {
      label: t('views.session.options.oneDay'),
      value: hrsToSec(24),
    }];

    const lostConnectionOptions = [{
      label: '5 seconds',
      value: 5,
    }, {
      label: '30 seconds',
      value: 30,
    }, {
      label: 'Never',
      value: 0,
    }];

    if (!isProduction) {
      options = [
        {
          label: '10 seconds (sonic 🦔)',
          value: 10,
        }, {
          label: '30 seconds (fast life ⚡️)',
          value: 30,
        },
        ...options,
      ];
    }

    this.state = {
      initialTimeout: 0,
      options,
      lostConnectionOptions,
      timeout: 0,
      lostConnectionTimeoutInitial: 0,
      lostConnectionTimeoutNew: 0,
    };

    this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.props.addOverlayAction('sidebar', this.props.goBack);
    this.setInitialTimeout();
    this.setState({ lostConnectionTimeoutInitial: this.props.connectionLostTimeout });
    this.setState({ lostConnectionTimeoutNew: this.props.connectionLostTimeout });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.session.accessToken !== this.props.session.accessToken) {
      this.setInitialTimeout();
    }
  }

  getCurrentTimeout() {
    if (!this.props.session.accessToken) return -1;
    const jwt = JWTDecode(this.props.session.accessToken) || {};
    const createdAt = (jwt.iat || 0) * 1000;
    const expiryDate = (jwt.exp || 0) * 1000;
    const currentValueInSeconds = (expiryDate - createdAt) / 1000;

    // Check if the current value is in the options list.
    const { options } = this.state;
    for (let i = 0; i < options.length; i += 1) {
      if (options[i].value === currentValueInSeconds) {
        return currentValueInSeconds;
      }
    }
    return -1;
  }

  handleChangeCheckbox(event) {
    const { value } = eventTarget(event);
    const timeout = Validator.strToInt(value);
    this.setState({ timeout });
  }

  handlerForConnectionLost = event => {
    const { value } = eventTarget(event);
    const timeout = Validator.strToInt(value);
    this.setState({ lostConnectionTimeoutNew: timeout });
  }

  handleSubmit(event) {
    event.preventDefault();

    const {
      initialTimeout,
      timeout,
      lostConnectionTimeoutInitial,
      lostConnectionTimeoutNew,
    } = this.state;

    if (lostConnectionTimeoutNew !== lostConnectionTimeoutInitial) {
      this.props.setConnectionLostTimeout(lostConnectionTimeoutNew, timeout);
    }

    if (initialTimeout !== timeout) {
      this.props.setTimeout(timeout)
        .then(() => this.props.goBack())
        .catch(() => this.props.goBack());
    }
    else {
      this.props.goBack();
    }
  }

  setInitialTimeout() {
    const timeout = this.getCurrentTimeout();
    if (timeout !== -1) {
      this.setState({ initialTimeout: timeout, timeout });
    }
  }

  render() {
    return (
      <Container
        onCancel={this.props.goBack}
        onChangeCheckbox={this.handleChangeCheckbox}
        onSubmit={this.handleSubmit}
        options={this.state.options}
        lostConnectionOptions={this.state.lostConnectionOptions}
        handlerForConnectionLost={this.handlerForConnectionLost}
        connectionLostTimeout={this.state.lostConnectionTimeoutNew}
        t={this.props.t}
        timeout={this.state.timeout} />
    );
  }
}

const mapStateToProps = state => ({
  session: state.session,
  connectionLostTimeout: state.preferences.connectionLostTimeout,
});

const mapDispatchToProps = dispatch => ({
  goBack: () => dispatch(goTo(Routes.SETTINGS)),
  setTimeout: timeout => dispatch(setSessionTimeout(timeout)),
  setConnectionLostTimeout: (
    lostConnectionTimeoutNew,
    timeout,
  ) => dispatch(
    setConnectionLostTimeout(lostConnectionTimeoutNew, timeout),
  ),
});

export default translate()(connect(
  mapStateToProps,
  mapDispatchToProps,
)(withOverlayAction(SidebarSessionComponent)));
