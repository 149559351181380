/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ActionBar from 'Modal/ActionBar';
import PreviewImport from 'PreviewImport';
import Styles from 'Modal/style.css';
import cross from '../../../../assets/images/cross.svg';
import leftsvg from '../../../../assets/images/left.svg';


const ModalImportPreviewContainer = ({
  apps,
  groupIds,
  groups,
  hasNewAccounts,
  onCancel,
  onSelectApp,
  onSubmit,
  selectedApps,
  submitting,
  t,
  unsortedId,
}) => (
  <React.Fragment>
  <img src={leftsvg} style={{ position: 'absolute', cursor: 'pointer' }} width='32' height='32' />
    <h1
      aria-label={t('modal.importPreview.title')}
      className={Styles.title}>
      {t('modal.importPreview.title')}
      <img src={cross} style={{ float: 'right', paddingBottom: '8px', cursor: 'pointer' }} width='32' height='32' />
    </h1>

    <form
      action="/"
      className={classNames({
        [Styles.formEmailScanPreview]: hasNewAccounts,
        [Styles.formEmailScan]: !hasNewAccounts,
        [Styles._fixedActions]: true,
      })}
      method="post"
      onSubmit={onSubmit}>
      <PreviewImport
        apps={apps}
        groupIds={groupIds}
        groups={groups}
        hasNewAccounts={hasNewAccounts}
        onSelectApp={onSelectApp}
        selectedApps={selectedApps}
        unsortedId={unsortedId} />

      <ActionBar
        cancelLabel={t(hasNewAccounts ? 'global.cancel' : 'global.back')}
        disableSubmit={false}
        onCancel={onCancel}
        selected={selectedApps.size}
        showSelected={hasNewAccounts}
        submitLabel={t(hasNewAccounts ? 'global.add' : 'global.done')}
        submitting={submitting}
        t={t} />
    </form>
  </React.Fragment>
);

ModalImportPreviewContainer.defaultProps = {
  apps: {},
  groupIds: [],
  groups: {},
  unsortedId: null,
};

ModalImportPreviewContainer.propTypes = {
  apps: PropTypes.object,
  groupIds: PropTypes.array,
  groups: PropTypes.object,
  hasNewAccounts: PropTypes.bool,
  onCancel: PropTypes.func,
  onSelectApp: PropTypes.func,
  onSubmit: PropTypes.func,
  selectedApps: PropTypes.array,
  submitting: PropTypes.bool,
  /** i18n translate method */
  t: PropTypes.func.isRequired,
  unsortedId(props, propName, componentName) {
    const propValue = props[propName];
    if (propValue === null) return;
    if (typeof propValue === 'string') return;
    throw new Error(`${componentName} only accepts null or string.`);
  },
};

export default ModalImportPreviewContainer;
