/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import {
  EVENT_ACCOUNT_ACCESSED,
  EVENT_CREDENTIAL_CREATED,
  EVENT_UPWARDARROWACCOUNT_SHARED,
  EVENT_UPWARDARROWACCOUNT_STOPSHARED,
  EVENT_UPWARDARROWACCOUNT_INVITEACCEPTED,
  EVENT_UPWARDARROWACCOUNT_INVITEREJECTED,
  EVENT_EMAIL_UPDATED,
  EVENT_GROUP_CHANGED,
  EVENT_DASHBOARD_CHANGED,
  EVENT_DASHBOARD_SHARED,
  EVENT_PASSWORD_UPDATED,
  EVENT_USERNAME_UPDATED,
  EVENT_CREDENTIAL_UPDATED,
  SOURCE_ADVANCED_SETTINGS,
  SOURCE_EMAIL_SEARCH,
  SOURCE_FILE_UPLOAD,
  SOURCE_MANUAL_SELECTION,
  SOURCE_WEB_APP,
  SOURCE_CHROME,
  SOURCE_BRAVE,
  SOURCE_EDGE,
  SOURCE_IOS_APP,
} from 'constants/events';
import {
  ID_SHOW_SHARED_INFO,
  TEXT_SHOW_SHARED_INFO,
  TEXT_LOG_INFO,
} from 'constants/ids';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { List } from 'immutable';
import { Button } from '@nettoken/components';
import { ensureProtocol, noop } from '@nettoken/helpers';
import { PropIcon } from 'utils/propTypes';
import Avatar from 'Avatar';
import Input from 'Input';
import Switch from 'Switch';
import Tooltip from 'Tooltip';
import iconPlus from 'icons/plus.svg';

import sharedAccountIcon from 'icons/upwardarrow.svg';
import sharedToMeIcon from 'icons/downwardarrow.svg';
import addAccountIcon from 'icons/add-account-icon.png';
import Styles from 'Modal/style.css';

import { getIconColorNewmethod } from '../../../../utils/misc';
import cross from '../../../../assets/images/cross.svg';
import leftsvg from '../../../../assets/images/left.svg';
import chevronRight from '../../../../assets/images/chevronRight.png';
import HistoryIcon from '../../../ActionIcons/HistoryIcon';
import CancelIcon from '../../../ActionIcons/CancelIcon';
import QuestionMarkIcon from '../../../ActionIcons/QuestionMarkIcon';
import LeftArrowIcon from '../../../ActionIcons/LeftArrowIcon';
import SharingBtn from '../../../ActionIcons/SharingBtn';
import AccountSharedWithInLine from '../../../AccountSharedWithInLine';
import UsersSharedWith from '../../../UsersSharedWith';

const ModalAccountEditContainer = ({
  sharedByUserName,
  sharedCredentialData,
  isShared,
  profilename,
  profileId,
  isExternalAccount,
  accepted,
  activityLogs,
  year1,
  cookieUrl,
  domain,
  email,
  getError,
  inoutchange,
  getLabelFromGroupId,
  getLabelFromDashboardId,
  group,
  groupDropdown,
  isTracking,
  loginUrl,
  loadingLogs,
  name,
  note,
  onCancel,
  onChange,
  onClickButtonDelete,
  onClickPasswordToggle,
  onSubmit,
  onclicktest,
  password,
  phone,
  picture,
  privacyUrl,
  setTrackingPermission,
  showLogs,
  showPassword,
  t,
  toggleView,
  username,
  onMouseEnterSharingStatus,
  onMouseLeaveSharingStatus,
  sharingStatus,
  handleSharingAccount,
  disableSharing,
  hoverHandler,
  onHoverField,
  deleteProcessing,
  onClickUser,
  getUserAccount,
  editAccountProccessing,
  dashboard,
  dashboardDropdown,
  moveApp,
  dashboards,
  appData,
  sharedDashboard,
  externalDashboard,
  usersSharedWith,
  usersSharedWithWithoutLoggedInUser,
  show,
  emailInvalid,
  dispatch,
}) => {
  const form = [{
    autoComplete: 'email',
    label: t('global.email'),
    name: 'email',
    placeholder: (!isExternalAccount ? t('placeholder.emailUser') : ''),
    type: 'email',
    value: email,
    readOnly: (isShared && isExternalAccount) || !accepted,
  }, {
    autoComplete: 'username',
    label: t('global.username'),
    name: 'username',
    placeholder: (!isExternalAccount ? t('placeholder.phone') : ''),
    type: 'text',
    value: username || phone,
    readOnly: (isShared && isExternalAccount) || !accepted,
  }, {
    isVisible: showPassword,
    label: t('global.password'),
    name: 'password',
    type: 'password',
    value: password,
    readOnly: (isShared && isExternalAccount) || !accepted,
  }, {
    autoComplete: 'off',
    dropdown: groupDropdown,
    label: t('global.group'),
    name: 'group',
    placeholder: t('placeholder.group'),
    type: 'text',
    value: group,
    readOnly: (isShared && isExternalAccount && sharedDashboard),
  }, {
    autoComplete: 'off',
    dropdown: dashboardDropdown,
    label: 'Dashboard',
    name: isShared && isExternalAccount && sharedDashboard ? '' : 'editAppMoveAccount',
    placeholder: 'Select Dashboard',
    type: 'text',
    value: dashboard,
    moveApp,
    readOnly: (isShared && isExternalAccount && sharedDashboard),
  }, {
    autoComplete: 'off',
    label: 'Note',
    name: 'note',
    placeholder: (!isExternalAccount ? 'Add note' : ''),
    type: 'textarea',
    value: note,
    readOnly: (isShared && isExternalAccount) || !accepted,
  }];
  const renderForm = () => (
    <form
      action="/"
      className={`${Styles.whiteBackground2} ${Styles.minHeightBackground}`}
      method="post"
      onSubmit={onSubmit}
    >
      <div className={`${Styles.fixedForm} ${Styles.fixedForm2}`}>
        <div className={classNames({
          [Styles.iconHeaderWrapper]: true,
          [Styles.noBg]: true,
        })}>
          {
            (
              isExternalAccount && show && sharedDashboard
            ) ||
            (
              !isExternalAccount &&
              !sharedDashboard &&
              sharedCredentialData &&
              sharedCredentialData.filter(x => !x.sharedByDashboard).length > 0
            ) ?
              (
                <img
                  src={sharedAccountIcon}
                  alt=''
                  className={Styles.sharedIcon}
                />
              ) : null
          }
          {isExternalAccount && !sharedDashboard &&
            <img
              src={sharedToMeIcon}
              alt=''
              className={Styles.sharedIcon}
            />
          }
          <Avatar
            className={Styles.iconHeaderWrapper}
            domain={domain}
            loginUrl={loginUrl}
            name={name}
            picture={picture}
            source='true'
          />
        </div>
        <Input
          className={classNames(Styles.appEditfield)}
          autoComplete='app-name'
          id={'name'}
          modal={true}
          name='name'
          onChange={onChange}
          placeholder={t('placeholder.appName')}
          readOnly={(isShared && isExternalAccount) || !accepted}
          bgColorOnHover={false}
          t={t}
          value={name}
          dispatch={dispatch}
        />
          {(!isExternalAccount) ?
          <div className={Styles.sharedRow} >
          {((!isExternalAccount && (isShared && usersSharedWith.length > 0)) || sharedDashboard) ? (
            <h2>SHARED CONTACTS
               <QuestionMarkIcon content='When you share an account with a contact they can view your username, email, and password and use it to log in to your account. Your contact cannot edit the information and can only view it.' position='relative' rightPosition='-184px' placement='right'/>
            </h2>
          ) : null}
          {(!isExternalAccount && !(sharedCredentialData && sharedCredentialData.length > 0) &&
          !sharedDashboard) &&
            <div className={classNames({
              [Styles.subHeader]: true,
              [Styles.clickArea]: true,
            })} style={{ width: '100%' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <div style={{ display: 'flex', alignItems: 'center', columnGap: '10px' }}>
                  <div className={Styles.circle} onClick={onclicktest}>
                    <img
                      alt={t('alt.addCredential')}
                      className={Styles.iconPlus}
                      src={iconPlus} />
                  </div>
                  <span
                    className={Styles.addAContact}
                    onClick={onclicktest}
                  >Share with a contact</span>
                  <QuestionMarkIcon content='When you share an account with a contact they can view your username, email, and password and use it to log in to your account. Your contact cannot edit the information and can only view it.' position='relative' rightPosition='-184px' placement='right'/>
                </div>
              </div>
            </div>
          }
          {(!sharedDashboard) && (sharedCredentialData && sharedCredentialData.length > 0) &&
            <div className={classNames({
              [Styles.subHeader]: true,
              [Styles.clickArea]: true,
            })}>
              <div className={Styles.circle} onClick={onclicktest}>
                <img
                  alt={t('alt.addCredential')}
                  className={Styles.iconPlus}
                  src={iconPlus} />
              </div>
              <span className={Styles.addAContact} onClick={onclicktest}>Share account</span>
            </div>
          }
            {(usersSharedWith.length > 0) && (
              <UsersSharedWith
                usersSharedWith={usersSharedWith}
              />
            )}
          </div> : null}
        {isShared && isExternalAccount && accepted &&
        ((usersSharedWithWithoutLoggedInUser.length > 0) ||
          (!sharedDashboard && sharedByUserName != null)) &&
          <div className={Styles.sharedRow}>
            <h2>
              SHARED CONTACTS
              <QuestionMarkIcon content='When an account is shared by a contact you can view the username, email, and password and use it to log in to the account. You can only view this information and cannot edit it.' position='relative' rightPosition='-184px' placement='right'/>
            </h2>
          </div>
        }
        {isShared && isExternalAccount && accepted && !sharedDashboard && !externalDashboard &&
           <div className={Styles.sharedRow} style={{ marginTop: '0px' }}>
             <div className={Styles.outerSharedDetails} style={
              {
                marginLeft: '-5px', width: '354px', height: '41px',
              }
            }>
              <div className={Styles.sharedDetails}
                  style={{ paddingLeft: '6px', paddingTop: '5px' }}
                  onClick={() => {
                    const userAccount = getUserAccount();
                    onClickUser(userAccount);
                  }}>
                <div className={`${Styles.leftSide1} ${Styles.sharedCredDetails}`}
                  style={{ cursor: 'pointer' }}
                >
                <svg width='100%' height='100%' style={{ border: `1px solid ${getIconColorNewmethod(sharedByUserName)}` }}>
                  <rect x='0' y='0' fill={getIconColorNewmethod(sharedByUserName)} height='100%' width='100%'></rect>
                  <text
                    dominantBaseline='middle'
                    fill='#ffffff'
                    fontSize='10'
                    textAnchor='middle'
                    x='48%'
                    y='54%'
                  >{sharedByUserName.slice(0, 2).toUpperCase()}</text>
                </svg>
                  <span>{sharedByUserName.length > 18 ? sharedByUserName.substring(0, 18).concat('...') : sharedByUserName}</span>
                </div>

                <div className={Styles.rightSide}>
                  Owner <img style={{ width: '24px', height: '24px', marginRight: '5px' }} src={chevronRight} />
                </div>
              </div>
              </div>
            </div>
          }
        {isShared && isExternalAccount && accepted &&
          <div className={Styles.sharedRow} style={{ marginTop: '0px' }}>
            {(usersSharedWithWithoutLoggedInUser.length > 0) && (
              <UsersSharedWith
                usersSharedWith={usersSharedWithWithoutLoggedInUser}
              />
            )}
          </div>
        }
        {sharedCredentialData &&
          <div className={Styles.sharedRow} style={{ marginTop: '0px' }}>
           <React.Fragment>
              <AccountSharedWithInLine
                sharedCredentialData={sharedCredentialData}
                onClickUser={onClickUser}
                onMouseEnterSharingStatus={onMouseEnterSharingStatus}
                onMouseLeaveSharingStatus={onMouseLeaveSharingStatus}
                sharingStatus={sharingStatus}
                disableSharing={disableSharing}
                handleSharingAccount={handleSharingAccount}
                chevronRightStyle={{ marginRight: '5px' }}
                externalDashboard={isExternalAccount}
              />
            </React.Fragment>
          </div>
        }
        {!sharedDashboard && (
          <>
            {!accepted &&
              <div className={Styles.sharedRow}>
                <h2>Shared by
                    <QuestionMarkIcon content='When an account is shared by a contact you can view the username, email, and password and use it to log in to the account. You can only view this information and cannot edit it.' position='relative' rightPosition='-184px' placement='right'/>
                </h2>
                <div className={Styles.sharedDetails}>
                  <div className={Styles.leftSide1}>
                  <svg width='100%' height='100%'>
                    <rect x='0' y='0' height='100%' width='100%'></rect>
                    <text
                      dominantBaseline='middle'
                      fill='#ffffff'
                      fontSize='10'
                      textAnchor='middle'
                      x='48%'
                      y='54%'
                    >{sharedByUserName.slice(0, 2)}</text>
                  </svg>
                    <span style={{ marginRight: '84px' }}>{sharedByUserName.length > 18 ? sharedByUserName.substring(0, 18).concat('...') : sharedByUserName}</span>
                  </div>

                  <div className={Styles.rightSide}>
                    pending invite
                  </div>
                </div>
              </div>
            }
          </>
        )}

        <div className={`${Styles.form} ${(isExternalAccount) ? Styles.externalAcc : ''}`}>
          {form.map(row => <Input
              autoComplete={row.autoComplete}
              dropdown={row.dropdown}
              error={getError(row.name)}
              key={row.name}
              label={row.label}
              name={row.name}
              onChange={onChange}
              onClickPasswordToggle={onClickPasswordToggle}
              placeholder={row.placeholder}
              readOnly={row.readOnly}
              bgColorOnHover={true}
              t={t}
              type={row.type}
              typeForceText={row.isVisible}
              value={row.value}
              moveApp={moveApp}
              dashboards={dashboards}
              dispatch={dispatch} />)}
            <p className={Styles.hint}>{`${200 - note.length} characters left`}</p>
        </div>

        {(cookieUrl || privacyUrl) && <ul className={Styles.linksList}>
          {privacyUrl && <li className={Styles.linksItem}>
            <a
              className={Styles.linksLink}
              href={ensureProtocol(privacyUrl)}
              rel="noopener noreferrer"
              target="_blank">
              {t('global.privacySettings')}
            </a>
          </li>}
          {cookieUrl && <li className={Styles.linksItem}>
            <a
              className={Styles.linksLink}
              href={ensureProtocol(cookieUrl)}
              rel="noopener noreferrer"
              target="_blank">
              {t('global.cookies')}
            </a>
          </li>}
        </ul>}
      </div>
      <div className={`${Styles.actions} ${Styles.actionsFixed} ${Styles._squeezedAltered} ${Styles.fixedButtons}`}
      >
        {externalDashboard ? <Button
          className={`${Styles.button} ${Styles.danger}`}
          onClick={onCancel}
          loaderSize={20}
          loading={deleteProcessing}
          value='Cancel' /> : <Button
          className={`${Styles.button} ${Styles.danger}`}
          onClick={onClickButtonDelete}
          loaderSize={20}
          loading={deleteProcessing}
          value={t('modal.accountEdit.remove')} />}

        <div>
          {externalDashboard ? null : <Button
          className={classNames({
            [Styles.button]: true,
            [Styles.notDisabled]: inoutchange && !emailInvalid,
            [Styles.cancelMarginLeft]: true,
          })}
            disabled={emailInvalid || !inoutchange}
            type="submit"
            loading={editAccountProccessing && !deleteProcessing}
            value={t('global.save')} />}
        </div>
      </div>
    </form>
  );

  const mapEvents = event => {
    switch (event.eventName) {
      case EVENT_ACCOUNT_ACCESSED:
        return 'Account accessed';
      case EVENT_CREDENTIAL_CREATED:
        return 'Added';
      case EVENT_CREDENTIAL_UPDATED:
        return 'Credential edited';
      case EVENT_EMAIL_UPDATED:
        return 'Email edited';
      case EVENT_PASSWORD_UPDATED:
        return 'Password edited';
      case EVENT_USERNAME_UPDATED:
        return 'Username edited';
      case EVENT_UPWARDARROWACCOUNT_SHARED:
        return 'You shared access';
      case EVENT_DASHBOARD_SHARED:
        return 'You shared';
      case EVENT_UPWARDARROWACCOUNT_STOPSHARED:
        return 'You stopped sharing account';
      case EVENT_UPWARDARROWACCOUNT_INVITEACCEPTED:
        return 'accepted the invitation';
      case EVENT_UPWARDARROWACCOUNT_INVITEREJECTED:
        return 'rejected the invitation';
      case EVENT_DASHBOARD_CHANGED:
        return `Moved from ${getLabelFromDashboardId(event.fromId) || 'Home Dashboard'} to ${getLabelFromDashboardId(event.toId) || 'Home Dashboard'}`;
      case EVENT_GROUP_CHANGED:
        return 'Group changed';
      default:
        return null;
    }
  };

  const mapNumbersToMonth = num => {
    switch (num) {
      case 0: return 'Jan';
      case 1: return 'Feb';
      case 2: return 'Mar';
      case 3: return 'Apr';
      case 4: return 'May';
      case 5: return 'Jun';
      case 6: return 'Jul';
      case 7: return 'Aug';
      case 8: return 'Sep';
      case 9: return 'Oct';
      case 10: return 'Nov';
      case 11: return 'Dec';
      default: return '';
    }
  };

  const mapSource = source => {
    switch (source) {
      case SOURCE_WEB_APP:
      case SOURCE_MANUAL_SELECTION:
      case SOURCE_ADVANCED_SETTINGS:
        return 'web app';
      case SOURCE_EMAIL_SEARCH:
        return 'email search';
      case SOURCE_FILE_UPLOAD:
        return 'file upload';
      case SOURCE_CHROME:
        return 'chrome extension';
      case SOURCE_BRAVE:
        return 'brave extension';
      case SOURCE_EDGE:
        return 'edge extension';
      case SOURCE_IOS_APP:
        return 'phone app';
      default:
        return null;
    }
  };

  const mapRoute = source => {
    switch (source) {
      case SOURCE_MANUAL_SELECTION:
        return 'from list selection';
      case SOURCE_ADVANCED_SETTINGS:
        return 'with unique URL';
      case SOURCE_FILE_UPLOAD:
        return 'from file upload';
      default:
        return '';
    }
  };

  const mapGroupDashboardChange = event => {
    switch (event.eventName) {
      case EVENT_GROUP_CHANGED:
        return `from ${getLabelFromGroupId(event.fromId) || 'a deleted group'} to ${getLabelFromGroupId(event.toId) || 'a deleted group'}`;
      default:
        return '';
    }
  };

  const mapLogs = () => {
    if (loadingLogs) {
      return (
        <div className={Styles.loading}></div>
      );
    }
    if (Object.keys(activityLogs).length === 0 && !isTracking) {
      return (
        <p style={{ color: '#868692' }}>No events have been tracked. Turn tracking on.</p>
      );
    }

    if (Object.keys(activityLogs).length === 0 && isTracking) {
      return (
        <p style={{ color: '#868692' }}>No events have been tracked.</p>
      );
    }
    let Pname;
    return (
      Object.keys(activityLogs).map(year => (
        <>
          <p>{year}</p>
          {activityLogs[year].map(log => (
            <div className={Styles.log}>
              <p className={Styles.logDate}>
                {`${new Date(log.createdAt * 1000).getHours()}:${(new Date(log.createdAt * 1000).getMinutes() < 10 ? '0' : '') + new Date(log.createdAt * 1000).getMinutes()}`}
              </p>
              <p>
                {log.userId === profileId ? `${mapEvents(log)} by you ${mapGroupDashboardChange(log)} ${mapRoute(log.source)} via ${mapSource(log.source)}` : [
                  (
                    mapEvents(log)[0] == 'G' || mapEvents(log) === 'Group changed' || mapEvents(log) === 'Shared account accepted' || mapEvents(log) === 'Account accessed' || mapEvents(log) === 'Email edited' || mapEvents(log) === 'Added' || mapEvents(log) === 'Username edited' || mapEvents(log) === 'Password edited' || mapEvents(log) === 'Credential edited' ? `${mapEvents(log)} by ${log.userId === profileId ? 'you' : log.associatedUserName} ${mapGroupDashboardChange(log)} ${mapRoute(log.source)} via ${mapSource(log.source)}` : `${(log.eventName === EVENT_UPWARDARROWACCOUNT_INVITEACCEPTED || log.eventName === EVENT_UPWARDARROWACCOUNT_INVITEREJECTED) ? log.associatedUserName : ''} ${mapEvents(log)} ${(log.eventName === EVENT_UPWARDARROWACCOUNT_INVITEACCEPTED || log.eventName === EVENT_UPWARDARROWACCOUNT_INVITEREJECTED) ? 'from' : 'with'} ${(log.userId === profileId || log.eventName === EVENT_UPWARDARROWACCOUNT_INVITEACCEPTED || log.eventName === EVENT_UPWARDARROWACCOUNT_INVITEREJECTED) ? 'you' : log.associatedUserName} via ${mapSource(log.source)}`
                  ),
                ] }
              </p>
            </div>
          ))}
        </>
      ))
    );
  };

  const renderLogs = () => (
    <div className={`${Styles.whiteBackground2} ${Styles.minHeightBackground}`}>
      <div className={`${Styles.fixedForm} ${Styles.fixedForm2}`}>
        {renderAvatar()}
        <div style={{ textAlign: 'center' }}>{name}</div>
        <div className={Styles.logContainer}>
          {mapLogs()}
        </div>
      </div>
      <div className={`${Styles.form} ${Styles.actionsFixed} ${Styles.editSwitchWrapper} ${Styles.actions} ${Styles.bottomHeightActivityHistory}`}>
        <p>Record activity of all accounts</p>
        <QuestionMarkIcon content={t('views.settings.tracking')} rightPosition='100px' placement='top'/>
        <Switch checked={isTracking} onChange={setTrackingPermission} />
      </div>
    </div>
  );

  const renderAvatar = () => (
    <div className={classNames({
      [Styles.iconHeaderWrapper]: true,
      [Styles.noBg]: true,
    })}>
      { (
        isExternalAccount && show && sharedDashboard
      ) ||
        (
          !isExternalAccount &&
          !sharedDashboard &&
          sharedCredentialData &&
          sharedCredentialData.filter(x => !x.sharedByDashboard).length > 0
        ) &&
        <img
          src={sharedAccountIcon}
          alt=''
          className={Styles.sharedIcon}
        />
      }
      {isExternalAccount && !sharedDashboard &&
        <img
          src={sharedToMeIcon}
          alt=''
          className={Styles.sharedIcon}
        />
      }
      <Avatar
        className={Styles.iconHeaderWrapper}
        domain={domain}
        loginUrl={loginUrl}
        name={name}
        picture={picture}
        appData={appData}
        source='true'
      />
    </div>
  );

  return (
    <React.Fragment>
      <h1
        aria-label={t('modal.accountEdit.title')}
        className={Styles.title1}>
        {showLogs ? <LeftArrowIcon toggleView={toggleView} /> : null}
        {showLogs ? 'Activity history' : t('modal.accountEdit.title')}
          <CancelIcon onCancel={onCancel} />
        {showLogs || (isShared && isExternalAccount && accepted) ? null :
          <HistoryIcon toggleView={toggleView} /> }
      </h1>
      {showLogs ? renderLogs() : renderForm()}
    </React.Fragment>
  );
};

ModalAccountEditContainer.defaultProps = {
  getError: noop,
};


ModalAccountEditContainer.propTypes = {
  cookieUrl: PropTypes.string,
  email: PropTypes.string,
  getError: PropTypes.func,
  group: PropTypes.string,
  groupDropdown: PropTypes.instanceOf(List),
  loginUrl: PropTypes.string,
  name: PropTypes.string,
  onCancel: PropTypes.func,
  onChange: PropTypes.func,
  onClickButtonDelete: PropTypes.func,
  onclicktest: PropTypes.func,
  onClickPasswordToggle: PropTypes.func,
  onSubmit: PropTypes.func,
  password: PropTypes.string,
  phone: PropTypes.string,
  picture: PropIcon(),
  privacyUrl: PropTypes.string,
  showPassword: PropTypes.bool,
  /** i18n translate method */
  t: PropTypes.func.isRequired,
  username: PropTypes.string,
  dashboardDropdown: PropTypes.instanceOf(List),
};

export default ModalAccountEditContainer;
