/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addKeyboardShortcuts, removeKeyboardShortcuts } from 'main/device';

class ShortcutComponent extends React.Component {
  componentDidMount() {
    this.props.addKeyboardShortcuts(this.props.name);
  }

  componentWillUnmount() {
    this.props.removeKeyboardShortcuts(this.props.name);
  }

  render() {
    return this.props.children || null;
  }
}

ShortcutComponent.propTypes = {
  name: PropTypes.string.isRequired,
};

const mapDispatchToProps = dispatch => ({
  addKeyboardShortcuts: name => dispatch(addKeyboardShortcuts(name)),
  removeKeyboardShortcuts: name => dispatch(removeKeyboardShortcuts(name)),
});

export default connect(
  null,
  mapDispatchToProps,
)(ShortcutComponent);
