/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
export const PRESET_A11Y = 'a11y';
export const PRESET_EXT_LOG = 'ext-log';
export const PRESET_EXT_RAW = 'ext-raw';
export const PRESET_I18N = 'i18n';
export const PRESET_LOG_NETWORK = 'log-network';
export const PRESET_THROTTLE_NETWORK = 'throttle-network';
export const PRESET_MOCK_PHONE = 'mock-phone';
export const PRESET_MOCK_SERVER = 'mock-server';
export const PRESET_PERSIST_SESSION = 'persist-session';
export const PRESET_REDUX = 'redux';
export const PRESET_SENTRY = 'sentry';
export const PRESET_STORAGE = 'storage';
export const PRESET_AUTO_LOGOUT = 'auto-logout';
