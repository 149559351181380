/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { List } from 'immutable';

import {
  REGULAR_KEYS,
  addEvent,
  eventKey,
  includesCaseInsensitive,
  noop,
  removeEvent,
} from '@nettoken/helpers';

import Validator from '@nettoken/validator';
import Styles from './style.css';

class DropdownComponent extends React.Component {
  constructor(props) {
    super(props);
    this.filterDropdown = this.filterDropdown.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  componentDidMount() {
    addEvent(document.body, 'keydown', this.handleKeyPress);
  }

  componentWillUnmount() {
    removeEvent(document.body, 'keydown', this.handleKeyPress);
  }

  filterDropdown() {
    return this.props.items
      .filter(item => {
        const value = item.toUpperCase() || null;
        return new RegExp(`(^|\\s)${this.props.currentValue.toUpperCase()}`).test(value);
      });
  }

  handleKeyPress(event) {
    const key = eventKey(event);

    if ([REGULAR_KEYS.ESCAPE, REGULAR_KEYS.ENTER].includes(key)) {
      if (event.preventDefault) event.preventDefault();

      const focusable = document.querySelector('[tabIndex]');
      if (focusable) focusable.focus();

      this.props.onClickItem(this.props.currentValue, true);
    }
    else if ([REGULAR_KEYS.ARROW_DOWN, REGULAR_KEYS.ARROW_UP].includes(key)) {
      if (event.preventDefault) event.preventDefault();

      const { items } = this.props;
      const isArrowDown = REGULAR_KEYS.ARROW_DOWN === key;
      const matchedIndex = includesCaseInsensitive(this.props.selected, items);
      let selected = '';

      if ('get' in items) {
        if (matchedIndex === -1) {
          if (isArrowDown) {
            selected = items.get(0);
          }
          else {
            selected = items.get(items.size - 1);
          }
        }
        else if (matchedIndex === 0 && !isArrowDown) {
          selected = items.get(items.size - 1);
        }
        else if (matchedIndex === items.size - 1 && isArrowDown) {
          selected = items.get(0);
        }
        else if (isArrowDown) {
          selected = items.get(matchedIndex + 1);
        }
        else {
          selected = items.get(matchedIndex - 1);
        }
        this.props.onClickItem(selected, false);
        this.scrollToSelectedItem();
      }
    }
  }

  isSelected(value) {
    return value.toUpperCase() === this.props.currentValue.toUpperCase();
  }

  onClickItem(selected) {
    this.props.onClickItem(selected, true);
  }

  scrollToSelectedItem() {
    const { list, selected } = this.refs;

    if (this.props.selected && list && selected) {
      const { paddingBottom: lPadBottom, paddingTop: lPadTop } = getComputedStyle(list);
      const { paddingBottom: sPadBottom, paddingTop: sPadTop } = getComputedStyle(selected);
      const paddingVertical =
        Validator.strToInt(lPadBottom) - Validator.strToInt(sPadBottom) +
        Validator.strToInt(lPadTop) - Validator.strToInt(sPadTop);
      const listHeight = list.offsetHeight - paddingVertical;

      const sOffset = selected.offsetTop;
      // Selected item is outside the initial window (below).
      if (sOffset > listHeight) {
        // Selected item is outside the scrolled window (below).
        if (sOffset > list.scrollTop + listHeight) {
          list.scrollTop = sOffset - listHeight + paddingVertical;
        }
        // Selected item is outside the scrolled window (above).
        else if (sOffset < list.scrollTop) {
          list.scrollTop = sOffset;
        }
        // Selected item is within the scrolled window.
        else {
          // Do nothing.
        }
      }
      // Selected item is within the initial window.
      else {
        list.scrollTop = 0;
      }
    }
  }

  render() {
    const { t, itemBuilder } = this.props;
    const limit = this.props.items.size;
    const list = this.props.filter ? this.filterDropdown() : this.props.items;
    return limit === 0 ? null : (
      <ul
        className={classNames({
          [Styles.wrapper]: true,
          [Styles.wrapperShare]: this.props.fromShare === true ? true : null,
          [Styles._fullWidth]: this.props.fullWidth,
          [Styles._reducedHeight]: this.props.reducedHeight,
          [Styles.top]: this.props.top,
        })}
        ref="list">
        {list.map((item, index) => {
          const selected = this.isSelected(item);
          const last = index + 1 === limit;
          const refs = {};
          if (selected) refs.ref = 'selected';
          return (
            <li
              aria-label={t('a11y.groupDropdownItem', { name: item })}
              className={classNames({
                [Styles.item]: true,
                [Styles._customEntry]: this.props.showCustomEntry && last,
                [Styles._errorMessage]: this.props.isErrorMessage,
              })}
              key={index}
              onClick={() => this.onClickItem(item)}
              {...refs}>
              {itemBuilder ? itemBuilder(item) : item}
            </li>
          );
        })}
      </ul>
    );
  }
}

DropdownComponent.defaultProps = {
  filter: false,
  items: new List(),
  onClickItem: noop,
  selected: '',
  currentValue: '',
  showCustomEntry: true,
  isErrorMessage: false,
  itemBuilder: undefined,
};

DropdownComponent.propTypes = {
  filter: PropTypes.bool,
  fullWidth: PropTypes.bool,
  fromShare: PropTypes.bool,
  items: PropTypes.instanceOf(List),
  onClickItem: PropTypes.func,
  selected: PropTypes.string,
  showCustomEntry: PropTypes.bool,
  isErrorMessage: PropTypes.bool,
  /** i18n translate method */
  t: PropTypes.func.isRequired,
  itemBuilder: PropTypes.func,
};

export default DropdownComponent;
