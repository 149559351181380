/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Counter from 'Counter';
import { Button } from '@nettoken/components';
import Styles from 'Modal/style.css';

const ActionBar = ({
  cancelLabel,
  disableSubmit,
  email,
  hideBackButton = false,
  isFixed = true,
  isFromFile,
  isImportStyle = false,
  onCancel,
  selected,
  showSelected,
  submitLabel,
  submitting,
  onClickBackHandler,
  disableInPreview,
  t,
  showDoneBtn,
  isFixedActionBar = false,
  styles = {},
}) => (
  <div className={classNames({
    [Styles.actions]: true,
    [Styles._fixed]: isFixed,
    [Styles.isImportStyle]: isImportStyle,
    [Styles.right]: !showSelected,
    [Styles.accountCounter]: true,
    [Styles.accountCounter1]: submitLabel === 'Preview' ? true : null,
    [Styles.actionsFixed]: isFixedActionBar,
  })}
  style={{ ...styles }}
  >

    {showSelected &&
      <span>
        <Counter
          noGreyBar={true}
          background="#fff"
          colour="#fff"
          count={selected}
          font="sm"
          label={'Accounts'}
          size={32}
        />
      </span>
    }

    <div>
      <Button
        className={classNames({
          [Styles.button]: true,
          [Styles.notDisabled]: !disableSubmit,
        })}
        disabled={disableSubmit}
        loaderSize={20}
        loading={submitting}
        type="submit"
        value={showDoneBtn ? 'Done' : submitLabel} />
    </div>
  </div>
);

ActionBar.propTypes = {
  cancelLabel: PropTypes.string.isRequired,
  disableSubmit: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  selected: PropTypes.number.isRequired,
  showSelected: PropTypes.bool.isRequired,
  submitLabel: PropTypes.string.isRequired,
  submitting: PropTypes.bool.isRequired,
  /** i18n translate method */
  t: PropTypes.func.isRequired,
};

export default ActionBar;
