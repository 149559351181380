/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import deepmerge from 'deepmerge';
import { getPersistedReducers } from './persisted';

class Reducer {
  /**
   * @param {string} name Needs to match the key name in root reducer. For example,
   *   we import a reducer for `credentials`, that would be the value in that case.
   * @param {any} [defaultState]
   */
  constructor(name, defaultState) {
    this.state = {
      defaultState,
      name,
    };

    this.isPersisted = getPersistedReducers().includes(name);
  }

  /**
   * @returns {any} Returns copy of objects so we cannot mutate them.
   */
  defaultState() {
    const { defaultState } = this.state;

    if (Array.isArray(defaultState)) {
      return [...defaultState];
    }

    if (defaultState && typeof defaultState === 'object') {
      return { ...defaultState };
    }

    return defaultState;
  }

  /**
   * @param {object} state
   * @param {boolean} [deep=false]
   *
   * @returns {object}
   */
  merge(state, deep = false) {
    const nextState = deep ?
      deepmerge(this.defaultState(), state) :
      { ...this.defaultState(), ...state };
    return nextState;
  }
}

export default Reducer;
