/*
 * Copyright (C) 2018-2019 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
export const MODAL_ACCOUNT_ADD = 'account-add';
export const MODAL_ADD_ACCOUNT_NAVLIST = 'add-account-navlist';
export const MODAL_ACCOUNT_ADD_FILE = 'account-add-file';
export const MODAL_ACCOUNT_DELETE = 'account-delete';
export const MODAL_ACCOUNT_DELETE_CONFIRM = 'account-delete-confirm';
export const MODAL_ACCOUNT_EDIT = 'account-edit';
export const MODAL_ACCOUNT_SHARE = 'account-share';
export const MODAL_ACCOUNT_ONBOARD = 'account-onboard';
export const MODAL_ACCOUNT_REMOVE = 'account-remove';
export const MODAL_ADVANCED_SETTINGS = 'advanced-settings';
export const MODAL_EMAIL_SCAN = 'email-scan';
export const MODAL_IMPORT_PREVIEW = 'import-preview';
export const MODAL_MEMO_ADD = 'memo-add';
export const MODAL_PASSWORD_CHECKUP = 'password-checkup';
export const MODAL_PROMPT_BACKUP = 'prompt-backup';
export const MODAL_PROMPT_BACKUP_EXPLAINED = 'prompt-backup-explained';
export const MODAL_PROMPT_TRACKING = 'prompt-tracking';
export const MODAL_RECOVER_MASTER_KEY = 'recover-master-key';
export const MODAL_UNSAVED_CHANGES = 'unsaved-changes';
export const MODAL_UNSAVED_DASHBOARD = 'unsaved-dashboard';
export const MODAL_WELCOME_VIDEO = 'welcome-video';
export const MODAL_TEMPLATE_TRANSFER = 'template-transfer';
export const MODAL_SHARING_BY_INVITATION = 'sharing-by-invitation';
export const MODAL_SHARING_DASHBOARD_BY_INVITATION = 'sharing-dashboard-by-invitation';
export const MODAL_STOP_SHARING = 'stop-sharing';
export const MODAL_CHANGE_PASSWORD = 'change-password';
export const MODAL_TAKE_ACTION = 'take-action';
export const MODAL_USER_SHARED = 'account-share-user';
export const MODAL_SHARE_RULES = 'account-share-rule';
export const MODAL_OFFLINE_MSG = 'offline-msg';
export const MODAL_USER_DETAIL = 'user-account-details';
export const MODAL_ADD_DASHBOARD = 'add-dashboard';
export const MODAL_MY_ACCOUNT = 'my-account';
export const MODAL_TAKE_NOTE_PRIVATE_DASHBOARD_ADD_ACCOUNTS = 'take-note-private-dashboard-add-accounts';
export const MODAL_TAKE_NOTE_PRIVATE_DASHBOARD_MOVE_ACCOUNTS = 'take-note-private-dashboard-move-accounts';
export const MODAL_TAKE_NOTE_NO_DASHBOARD = 'take-note-no-dashboard';
export const MODAL_DOWNLOAD_BACKUP_KEY = 'download-backup-key';
export const MODAL_SESSION_SETTINGS = 'session-settings';
export const MODAL_DOWNLOAD_DATA = 'download-data';
export const MODAL_DELETE_ACCOUNT = 'delete-account';
export const MODAL_DELETE_ACCOUNT_CONFIRMATION = 'delete-account-confirmation';
export const MODAL_DASHBOARDS = 'modal-dashboards';
export const MODAL_EDIT_DASHBOARD = 'edit-dashboard';
export const MODAL_DELETE_DASHBOARD = 'delete-dashboard';
export const MODAL_DASHBOARD_CREATED = 'dashboard-created';
export const MODAL_MOVE_ACCOUNTS = 'move-accounts';
export const MODAL_MOVE_ACCOUNT_DASHBOARD = 'move-account-dashboard';
export const MODAL_SHARE_DASHBOARD = 'share-dashboard';
export const MODAL_SHARE_DASHBOARD_RULES = 'share-dashboard-rules';
export const MODAL_SHARE_DASHBOARD_USER = 'share-dashboard-user';
export const MODAL_STOP_SHARING_DASHBOARD = 'stop-sharing-dashboard';
export const MODAL_DROP_ACCOUNT_CONFIRMATION = 'drop-account-confirmation';
