/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { RX_ERROR_CLEAN, RX_ERROR_SHOW, RX_ERROR_UI } from './actionTypes';

/**
 * @param {string} [title='']
 * @param {string} [text='']
 */
export const RXErrorShow = (title = '', text = '') => ({
  type: RX_ERROR_SHOW,
  text,
  title,
});

export const RXErrorClean = () => ({
  type: RX_ERROR_CLEAN,
});

/**
 * @param {array} arr
 */
export const RXErrorUI = arr => ({
  type: RX_ERROR_UI,
  arr,
});
