import React from 'react';
import Styles from 'Modal/style.css';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Button } from '@nettoken/components';
import sharingRulesIcon1 from '../../../../assets/images/sharingRulesIcon1.png';
import sharingRulesIcon2 from '../../../../assets/images/sharingRulesIcon2.png';
import sharingRulesIcon3 from '../../../../assets/images/sharingRulesIcon3.png';
import sharingRulesIcon4 from '../../../../assets/images/sharingRulesIcon4.png';
import sharingSingleAccountIcon from '../../../../assets/images/sharingSingleAccount.png';
import cross from '../../../../assets/images/cross.svg';
import leftsvg from '../../../../assets/images/left.svg';
import LeftArrowIcon from '../../../ActionIcons/LeftArrowIcon';
import CancelIcon from '../../../ActionIcons/CancelIcon';


const ModalAccountShareRuleContainer = ({ hide, onGotItClick, onClose }) => (
  <React.Fragment>
    <h1 className={Styles.title1}>
      <LeftArrowIcon onClickBackHandler={hide} />
      Sharing rules
      <CancelIcon onCancel={onClose} />
    </h1>
    <form
      action='/'
      method='post'
      onSubmit={onGotItClick}
      className={`${Styles.whiteBackground2} ${Styles.minHeightBackground}`}
    >
      <div style={{ padding: '0px 30px' }}>
        <div className={`${Styles.fixedForm} ${Styles.fixedForm2}`}>
          <div style={{ textAlign: 'center', margin: '20px' }}>
            Share account login details with <br /> end-to-end encryption
          </div>
        <div style={{ textAlign: 'center' }}>
          <img src={sharingSingleAccountIcon} style={{ width: '190px', height: '127px' }}/>
        </div>
        <div>
          <div className={Styles.sharingRulesItems}>
            <img
              style={{
                maxWidth: '40px',
                height: '40px',
                marginRight: '20px',
                marginTop: '5px',
              }}
              src={sharingRulesIcon1}
            />
            <div>
              <b> Share with Nettoken users </b>
              <p className={Styles.sharingRulesSubItems}>
                  Invite your friends first before sharing
              </p>
            </div>
          </div>
          <div className={Styles.sharingRulesItems}>
              <img
                style={{
                  maxWidth: '40px',
                  height: '40px',
                  marginRight: '20px',
                  marginTop: '5px',
                }}
                src={sharingRulesIcon2}
              />
              <div>
                <b> View only access </b>
                <p className={Styles.sharingRulesSubItems}>
                  All data is shared but cannot be edited
                </p>
              </div>
            </div>
            <div className={Styles.sharingRulesItems}>
              <img
                style={{
                  maxWidth: '40px',
                  height: '40px',
                  marginRight: '20px',
                  marginTop: '5px',
                }}
                src={sharingRulesIcon3}
              />
              <div>
                <b> Stop sharing anytime </b>
                <p className={Styles.sharingRulesSubItems}>
                  In one click you can stop sharing
                </p>
              </div>
            </div>
            <div className={Styles.sharingRulesItems}>
              <img
                style={{
                  maxWidth: '40px',
                  height: '40px',
                  marginRight: '20px',
                  marginTop: '5px',
                }}
                src={sharingRulesIcon4}
              />
              <div>
                <b> Unique passwords </b>
                <p className={Styles.sharingRulesSubItems}>
                  Never share reused passwords!
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`${Styles.actions} ${Styles.actionsFixed} ${Styles._squeezedAltered} ${Styles.fixedButtons}`}
          style={{
            padding: '14px',
            margin: '0 -30px',
            justifyContent: 'right',
          }}
        >
          <div>
            <Button
              className={classNames({
                [Styles.button]: true,
                [Styles.notDisabled]: true,
                [Styles.cancelMarginLeft]: true,
              })}
              type="submit"
              value="Got it!"
            />
          </div>
        </div>
      </div>
    </form>
  </React.Fragment>
);

ModalAccountShareRuleContainer.propTypes = {
  hide: PropTypes.func,
  onGotItClick: PropTypes.func,
};

export default ModalAccountShareRuleContainer;
