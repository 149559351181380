/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import {
  RX_GROUPS_CREATE,
  RX_GROUPS_DELETE,
  RX_GROUPS_SET_UNSORTED_ID,
  RX_GROUPS_UPDATE,
  RX_GROUPS_UPDATE_BATCH,
} from './actionTypes';

/**
 * @param {string} id
 * @param {object} [nextState={}]
 */
export const RXGroupsCreate = (id, nextState = {}) => ({
  type: RX_GROUPS_CREATE,
  id,
  group: nextState,
});

/**
 * @param {string} id
 */
export const RXGroupsDelete = id => ({
  type: RX_GROUPS_DELETE,
  id,
});

/**
 * @param {string} id
 */
export const RXGroupsSetUnsortedId = id => ({
  type: RX_GROUPS_SET_UNSORTED_ID,
  id,
});

/**
 * @param {object} groups
 * @property {object} id Group state indexed by its id.
 */
export const RXGroupsUpdate = (groups = {}) => ({
  type: RX_GROUPS_UPDATE_BATCH,
  groups,
});

/**
 * @param {strinf} id
 * @param {object} nextState
 */
export const RXGroupsUpdateOne = (id, nextState = {}) => ({
  type: RX_GROUPS_UPDATE,
  id,
  group: nextState,
});
