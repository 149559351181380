/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { RX_BACKUP_MASTER_KEY } from 'main/backup/actionTypes';
import { RX_APP_INIT } from 'main/state/actionTypes';
import Reducer from 'reducers/factory';

import {
  RX_USER_CHANGES_SAVE,
  RX_USER_CHANGES_TEMP,
  RX_USER_CHANGES_UNDO,
  RX_USER_LOG_OUT,
  RX_ADD_DASHBOARD_INVITES,
  RX_REMOVE_DASHBOARD_INVITES,
} from './actionTypes';

const defaultState = () => ({
  changed: {},
  hasMasterKeyBackup: false,
  welcomepopup: false,
  profile: {
    age: '',
    email: '',
    location: '',
    name: '',
    phone: '',
    id: '',
  },
  dashboardInvites: [],
});

const reducer = new Reducer('user', defaultState());

export default (state = reducer.defaultState(), action) => {
  switch (action.type) {
    // Restore old values if we had an unsaved state. This is an equivalent
    // to losing form state on refresh, but we store the data and discard it
    // manually. This behaviour could be changed in the future to prevent users
    // from losing any changes.
    case RX_APP_INIT:
    case RX_USER_CHANGES_SAVE:
    case RX_USER_CHANGES_UNDO: {
      const nextState = {};

      if (action.type !== RX_USER_CHANGES_SAVE) {
        const changed = { ...state.changed };
        Object.keys(changed).forEach(key => {
          nextState[key] = changed[key][0].oldValue;
        });
      }

      return {
        ...reducer.merge(state),
        ...nextState,
        changed: {},
      };
    }

    case RX_BACKUP_MASTER_KEY:
      return {
        ...state,
        hasMasterKeyBackup: action.backup,
      };

    case RX_USER_CHANGES_TEMP: {
      const changed = { ...state.changed };
      const nextState = {};

      action.payloads.forEach(payload => Object.keys(payload).forEach(name => {
        if (!Array.isArray(changed[name])) changed[name] = [];

        const changes = changed[name].length;
        const oldValue = changes ? changed[name][changes - 1].newValue : state[name];
        const newValue = payload[name];

        /*
         * Check if the latest change matches the very first state.
         * If yes, we can clear all of the changes made between then
         * and now.
         */
        if (changes) {
          const firstValue = changed[name][0].oldValue;
          if (typeof firstValue === 'object' && typeof newValue === 'object') {
            const fKeys = Object.keys(firstValue);
            const nKeys = Object.keys(newValue);

            if (fKeys.length === nKeys.length) {
              for (let i = 0; i < fKeys.length; i += 1) {
                const key = fKeys[i];
                if (firstValue[key] !== newValue[key]) break;
                if (i + 1 === fKeys.length) delete changed[name];
              }
            }
          }
        }

        if (Array.isArray(changed[name])) {
          changed[name] = [
            ...changed[name],
            {
              oldValue,
              newValue,
            },
          ];
        }

        nextState[name] = newValue;
      }));

      return {
        ...state,
        ...nextState,
        changed,
      };
    }

    case RX_ADD_DASHBOARD_INVITES:
      return {
        ...state,
        dashboardInvites: action.dashboardInvites,
      };

    case RX_REMOVE_DASHBOARD_INVITES: {
      const newInvites = state.dashboardInvites.filter(dashboard => dashboard.id !== action.id);
      return {
        ...state,
        dashboardInvites: newInvites,
      };
    }

    case RX_USER_LOG_OUT:
      return reducer.isPersisted ? state : reducer.defaultState();

    default:
      return state;
  }
};
