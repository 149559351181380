/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@nettoken/components';
import { noop, reloadBrowser } from '@nettoken/helpers';
import Styles from 'Modal/style.css';

const OfflineMsg = ({
  loading,
  onCancel,
  t,
}) => (
    <React.Fragment>
      <h1
        aria-label="Server Connection is Lost"
        className={Styles.title}>
        {'server connection is lost'.toUpperCase()}
      </h1>

      <form
        action="/"
        className={Styles.formEmailScan}
        method="post"
        onSubmit={noop}>
        <p className={Styles.emailScanDisclaimer}>
          {'Changes cannot be saved with no wifi connection. Please try again later.'}
        </p>
        <div style={{ textAlign: 'center' }}>
          <Button
            className={Styles.backupPromptButton}
            onClick={reloadBrowser}
            value="Refresh"
          />
        </div>
      </form>
    </React.Fragment>
);

OfflineMsg.propTypes = {
  onCancel: PropTypes.func,
  /** i18n translate method */
  t: PropTypes.func.isRequired,
};

export default OfflineMsg;
