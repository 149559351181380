import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import withOverlayAction from 'Overlay/withAction';
import { hideModal } from 'main/modal';
import Container from './container';
import { MODAL_ACCOUNT_EDIT } from '../../../../constants/modal';
import { showModal } from '../../../../main/modal';

class ModalPasswordCheckupComponent extends React.Component {
  constructor(props) {
    super(props);

    const accountsGroup = this.groupAccountsByPassword(this.props.credentials);
    const groups = this.filterGroups(accountsGroup);

    this.state = {
      hasDuplicatePasswords: Object.keys(groups).length > 0,
      duplicatePasswordCount: 0,
      groups,
      selectedAppId: null,
      step: 'preview',
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.goPrev = this.goPrev.bind(this);
  }

  componentDidMount() {
    this.props.addOverlayAction('modal', () => this.props.hideModal());
  }

  filterGroups(groups) {
    return Object.keys(groups)
      .filter(groupKey => groups[groupKey].length > 1)
      .reduce((acc, password) => {
        acc[password] = groups[password];
        return acc;
      }, {});
  }

  goNext() {
    this.setState({
      step: 'summary',
    });
  }

  goPrev() {
    this.setState({
      step: 'preview',
    });
  }

  groupAccountsByPassword(credentials) {
    return Object.values(credentials).reduce((acc, credential) => {
      if (credential.password.length === 0) return acc;
      acc[credential.password] = (acc[credential.password] || []).concat(credential);
      return acc;
    }, {});
  }

  handleSubmit(event, id) {
    event.preventDefault();
    const { groups } = this.state;
    const appPassword = this.props.credentials[id].password;
    const duplicatePasswordCount = groups[appPassword] !== undefined ?
      groups[appPassword].length : 0;

    this.setState({
      selectedAppId: id,
      duplicatePasswordCount,
    });

    this.goNext();
  }

  render() {
    const { step, groups, selectedAppId } = this.state;
    const selectedApp = selectedAppId !== null ? this.props.credentials[selectedAppId] : null;
    return (
      <Container
        credentialGroups={this.props.credentialGroups}
        duplicatePasswordCount={this.state.duplicatePasswordCount}
        hasDuplicatePasswords={this.state.hasDuplicatePasswords}
        selectedApp={selectedApp}
        step={step}
        onCancel={() => this.props.hideModal()}
        onSubmit={this.handleSubmit}
        groups={groups}
        goBack={this.goPrev}
        unsortedId={this.props.unsortedId}
        t={this.props.t}
        goToEditAccount={() => this.props.showModal(
          MODAL_ACCOUNT_EDIT,
          selectedApp,
        )}
      />
    );
  }
}

const mapStateToProps = state => ({
  credentials: state.credentials.data,
  credentialGroups: state.groups.data,
  unsortedId: state.groups.unsortedGroupId,
});

const mapDispatchToProps = dispatch => ({
  showModal: (name, data) => dispatch(showModal(name, data)),
  hideModal: () => dispatch(hideModal()),
});

export default translate()(connect(
  mapStateToProps,
  mapDispatchToProps,
)(withOverlayAction(ModalPasswordCheckupComponent)));
