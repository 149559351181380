/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { List, Map } from 'immutable';
import { Credential, compareCredentials } from '@nettoken/models';

export const TYPE_CSV = 'csv';
export const TYPE_JSON = 'json';

/**
 * If the found credential already exists, do not suggest it.
 *
 * @param {object} credential A credential model.
 * @param {Map} credentialsDomainMap Existing credentials sorted by domain.
 *
 * @returns {boolean}
 */
export const isCredentialDuplicate = (credential, credentialsDomainMap, options = {}) => {
  const currentCredentials = credentialsDomainMap.get(credential.get('domain'));
  const limit = currentCredentials ? currentCredentials.size : 0;
  for (let i = 0; i < limit; i += 1) {
    const current = currentCredentials.get(i);
    let dashboardsMatch = false;
    if (current.get('dashboardSpaceId') === credential.get('dashboardSpaceId')) dashboardsMatch = true;
    if (compareCredentials(credential, current, options).match && dashboardsMatch) {
      return true;
    }
  }
  return false;
};

/**
 * Transform an object of credentials into a map of Credential models
 * grouped by their domains.
 *
 * @param {object} credentials Object obtained from Redux state.
 * @param {Map} [domainMap=new Map()] Initial state for the response.
 *
 * @returns {Map} Credentials grouped by domain.
 */
export const credentialsIdTreeToDomainTree = (credentials = {}, domainMap = new Map()) => {
  Object.keys(credentials).forEach(id => {
    let credential = credentials[id];
    if (!credential._isModel) credential = new Credential(credential);
    const domain = credential.get('domain');
    if (!domainMap.get(domain)) domainMap = domainMap.set(domain, new List());
    const domainArray = domainMap.get(domain).push(credential);
    domainMap = domainMap.set(domain, domainArray);
  });
  return domainMap;
};

/**
 * @param {Map} credentialsDomainMap
 *
 * @returns {function}
 */
export const CredentialFilter = credentialsDomainMap => {
  let resultDomainMap = new Map();
  let credentials = new List();
  /**
   * @param {object} credential
   *
   * @returns {List}
   */
  return (credential, isImported = false) => {
    // Credential cannot already exist in our dashboard or the import.
    // if (!isCredentialDuplicate(credential,
    //   credentialsDomainMap, { comparePassword: true, lowerBounds: 0.80 }) &&
    //   !isCredentialDuplicate(credential, resultDomainMap))
    const password = credential.get('password');
    if (!isCredentialDuplicate(credential, resultDomainMap, {
      allowExtraInfo: false,
      comparePassword: isImported && password && password !== '',
    }) &&
      !isCredentialDuplicate(credential, credentialsDomainMap, {
        comparePassword: isImported && password && password !== '',
        lowerBounds: 0.80,
        allowExtraInfo: false,
      })
    ) {
      const id = credential.get('id');
      resultDomainMap = credentialsIdTreeToDomainTree({ [id]: credential }, resultDomainMap);

      // credential = shouldUpadteCredential(credential, credentialsDomainMap);
      credentials = credentials.push(credential);
    }
    return credentials;
  };
};

export const extractLoginUrl = url => (
  url.split('/', 3).join('/')
);

/**
 * @param {array} groupNames
 *
 * @returns {function}
 */
export const GroupFilter = groupNames => {
  groupNames = groupNames.map(name => name.toUpperCase());
  let emptyGroups = new List();
  /**
   * @param {string} name
   * @param {List} credentials
   *
   * @returns {List}
   */
  return (name, credentials) => {
    // Group must be empty and not included yet.
    if (credentials.size === 0 && !groupNames.includes(name.toUpperCase())) {
      emptyGroups = emptyGroups.push(name);
    }
    return emptyGroups;
  };
};

export const shouldUpadteCredential = (credential, credentialsDomainMap) => {
  const currentCredentials = credentialsDomainMap.get(credential.get('domain'));
  const limit = currentCredentials ? currentCredentials.size : 0;
  for (let i = 0; i < limit; i += 1) {
    const current = currentCredentials.get(i);
    let dashboardsMatch = false;
    if (current.get('dashboardSpaceId') === credential.get('dashboardSpaceId')) dashboardsMatch = true;
    if (compareCredentials(current, credential).match && dashboardsMatch) {
      const meta = {
        mergeWith: current.get('id'),
      };
      credential.set('meta', meta);
      return credential;
    }
  }
  return credential;
};
