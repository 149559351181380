/* eslint-disable */
/*
 * Copyright (C) 2018-2019 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { SOURCE_WEB_APP } from 'constants/events';
import React from 'react';
import { eventTarget } from '@nettoken/helpers';
import { Credential } from '@nettoken/models';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import withOverlayAction from 'Overlay/withAction';
import { updateCredential } from 'main/vault/credentials';
import {
  getWorkerPromise,
  CW_ENCRYPT_CREDENTIALS,
  CW_ENCRYPT_USING_MASTER_KEYPAIR,
  CW_ENCRYPT_WITH_EXTERNAL_KEY,
  CW_DECRYPT_USING_MASTER_KEYPAIR,
  CW_GENERATE_EPHEMERAL_KEY_PAIR,
  CW_EXTRACT_MASTER_KEYS,
} from '@nettoken/crypto-worker';
import {
  BULK_URL_CREDENTIAL, DOWNWARD, URL_CREDENTIAL, URL_CREDENTIALS_SHARED, URL_USERS,
} from '../../../../constants/endpoints';
import Container from './container';
import { RxEditAccountProccessing } from '../../../../main/modal/reduxActions';
import { hideModal, showModal } from '../../../../main/modal';
import { MODAL_ACCOUNT_EDIT, MODAL_MOVE_ACCOUNT_DASHBOARD, MODAL_UNSAVED_DASHBOARD } from '../../../../constants/modal';
import { doSearch } from '../../../../main/search';
import { RXChangeActiveDashboard } from '../../../../main/ui/reduxActions';
import KeyPair from '@nettoken/crypto-worker/src/keypair';
import { Request } from 'utils/request';
import { getUserData } from '../../../../main/user';
import { authenticateUserData } from '../../../../main/auth';
import { getOrCreateKeyPairForSharing, getOrCreateKeyPairForSharingMultiConditions } from '../../../../utils/misc';

class ModalMoveAccountDashboardComponent extends React.Component {
  constructor(props) {
    super(props);
    this.handleAction = this.handleAction.bind(this);
    this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this);
    this.submit = this.submit.bind(this);
    this.state = {
      selectedValue: this.props.currentDashboard,
      loading: false,
    };
  }


  componentDidMount() {
    this.props.addOverlayAction('modal', () => this.handleAction(() => this.props.hide()));
  }

  componentDidUpdate() {
    // console.log()
    // if (!this.props.inoutchange && this.props.editAccountProccessing) {
    //   this.props.setEditAccountProccessing(false);
    // }
  }

  handleAction(callback) {
    if (
      (this.props.currentDashboard !== null &&
        (this.state.selectedValue != this.props.currentDashboard)) ||
      (this.props.currentDashboard === null && this.state.selectedValue !== 'HomeDashboard')) {
      const fromDashboard = this.props.dashboardOptions
        .filter(item => item.value === this.props.currentDashboard)[0].label;
      const toDashboard = this.props.dashboardOptions
        .filter(item => item.value === this.state.selectedValue)[0].label;
      this.props.dispatch(showModal(MODAL_UNSAVED_DASHBOARD, {
        currentApp: this.props.currentApp,
        fromDashboard,
        toDashboard,
        onCancel: () => {
          callback();
        },
        onConfirm: e => this.submit(e, true),
      }));
      return;
    }
    if (
      !this.props.editAccountProccessing
    ) {
      callback();
    }
  }

  handleChangeCheckbox(value) {
    this.setState({ selectedValue: value });
  }

  // async getOrCreateKeyPairForSharing(credential) {
  //   const keypair = new KeyPair();
  //   const {
  //     publicKey,
  //     secretKey,
  //   } = credential;
  //   const worker = await getWorkerPromise('crypto');
  //   if (credential.shared === true && publicKey && secretKey && publicKey != null && secretKey != null) {
  //     const [pKey, sKey] = await Promise.all([
  //       worker({
  //         event: CW_DECRYPT_USING_MASTER_KEYPAIR,
  //         message: publicKey,
  //       }),
  //       worker({
  //         event: CW_DECRYPT_USING_MASTER_KEYPAIR,
  //         message: secretKey,
  //       }),
  //     ])
  //       .then(values => values.map(v => Object.values(v)
  //         .join('')
  //         .replace('CW_DECRYPT_USING_MASTER_KEYPAIR', '')));

  //     keypair.set(pKey, sKey);
  //   }
  //   else {
  //     const newKeyPair = await worker({
  //       event: CW_GENERATE_EPHEMERAL_KEY_PAIR,
  //       format: 'base64',
  //     });
  //     keypair.set(newKeyPair.publicKey, newKeyPair.secretKey);
  //   }
  //   return keypair;
  // }

  prepareCredentials = ({
    id,
    note,
    groupId,
    accountId,
    name,
    username,
    email,
    password,
    loginUrl,
    phone,
    publicKey,
    secretKey,
    shared,
    sharedByDashboard,
    dashboardSpaceId,
    picture,
  }) => ({
    id,
    note,
    groupId,
    accountId,
    name,
    username,
    email,
    password,
    loginUrl,
    phone,
    publicKey,
    secretKey,
    shared,
    sharedByDashboard,
    dashboardSpaceId,
    picture,
  });

  async handleSharingDashboard(currentDashboard, credential, back) {
    const { dashboards } = this.props;
    if (
      currentDashboard &&
      'usersSharedWith' in dashboards[currentDashboard] &&
      dashboards[currentDashboard].usersSharedWith.length > 0
    ) {
      const { usersSharedWith } = dashboards[currentDashboard];
      const credentials = {[credential.accountId]: credential};
      const sharedCredentialDataArr = {};
      let sharedCredentialDataArrCount = 0;
      let credentialsKeys = {};
      // usersSharedWith.forEach(async (user, userIndex) => {
      let userIndex = 0;
      for (let user of usersSharedWith) {
        // const element = array[index];
        // Object.keys(credentials).forEach(async accountId => {
        for (let accountId in credentials) {
          const credential = credentials[accountId];
          // console.log('spec_Check_daatta', credentialsKeys);
          if (accountId in credentialsKeys) {
            credential.publicKey = credentialsKeys[accountId].publicKey;
            credential.secretKey = credentialsKeys[accountId].secretKey;
          }
          const {
            id: sharedWithUserId,
            publicKey: sharedWithUserPublicKey,
            responded: dashboardStatus,
          } = user;
          console.log('spec_Check_daatta', credential.publicKey, credential.secretKey);
          const {
            publicKey,
            secretKey,
          } = await getOrCreateKeyPairForSharing(credential, KeyPair);
          const worker = await getWorkerPromise('crypto');
          const [pKey, sKey] = await Promise.all([
            worker({
              event: CW_ENCRYPT_USING_MASTER_KEYPAIR,
              message: publicKey,
            }),
            worker({
              event: CW_ENCRYPT_USING_MASTER_KEYPAIR,
              message: secretKey,
            }),
          ])
            .then(values => values.map(v => Object.values(v)
              .join('')
              .replace('CW_ENCRYPT_USING_MASTER_KEYPAIR', '')));
          credential.publicKey = pKey;
          credential.secretKey = sKey;
          credentialsKeys = {
            ...credentialsKeys,
            [accountId]: {
              publicKey: pKey,
              secretKey: sKey,
            }
          }
          credential.shared = true;
          credential.sharedByDashboard = true;
          credential.dashboardSpaceId = dashboards[currentDashboard].id;
          const { encrypted } = await worker({
            event: CW_ENCRYPT_CREDENTIALS,
            credentials: [credential],
          });
          if (!encrypted[0]) {
            throw new Error('Encrypted credentials unavailable');
          }
          const [{
            encrypted: encryptedPublicKey,
          }, {
            encrypted: encryptedSecretKey,
          }] = await Promise.all([
            worker({
              event: CW_ENCRYPT_WITH_EXTERNAL_KEY,
              message: publicKey,
              inputFormat: 'plain',
              outputFormat: 'base64',
              publicKey: sharedWithUserPublicKey,
            }),
            worker({
              event: CW_ENCRYPT_WITH_EXTERNAL_KEY,
              message: secretKey,
              inputFormat: 'plain',
              outputFormat: 'base64',
              publicKey: sharedWithUserPublicKey,
            }),
          ]);
          const SharedCredential = {
            credential: this.prepareCredentials(encrypted[0]),
            accountId: credential.accountId,
            secretKey: encryptedSecretKey,
            publicKey: encryptedPublicKey,
            sharedWithUserId,
            hasPendingInvites: !dashboardStatus,
          };
          // this.props.getSharedCredentials(credential.id);
          // this.props.updateCredential(credential.id, credential);
          console.log('spec_cjeck_ddd', sharedCredentialDataArr, SharedCredential);
          if (userIndex in sharedCredentialDataArr) {
            sharedCredentialDataArr[userIndex].push(SharedCredential);
          }
          else {
            sharedCredentialDataArr[userIndex] = [SharedCredential];
          }
          credentials[accountId] = credential;
          console.log('spec_cjeck_ddd2', sharedCredentialDataArr, SharedCredential);
          sharedCredentialDataArrCount += 1;
          if (sharedCredentialDataArrCount >= (
            Object.keys(credentials).length * usersSharedWith.length
          )) {
            // const finalPayload = [...sharedCredentialDataArr];
            // sharedCredentialDataArr = [];
            this.callApiRequest(sharedCredentialDataArr, 0, credentials, back);
          }
        };
        userIndex += 1;
      };
    }
    else {
      this.setState({ canSubmit: false, isSubmitting: false });
      this.props.search(this.state.selectedValue === 'HomeDashboard' ?
      '' :
      this.state.selectedValue.toString(),
        'dashboardSpaceId');
      if (!back) {
        this.props.showModal(MODAL_MOVE_ACCOUNT_DASHBOARD, {
          currentApp: this.props.currentApp,
        });
      }
      else {
        this.props.showModal(MODAL_ACCOUNT_EDIT, {
          currentApp: this.props.currentApp,
        });
      }

      // api call ends here
      this.setState({
        loading: false
      });
    }
  }
  callApiRequest(finalPayload, index, credentialsData, back) {
    console.log('spec_Check_33', index, finalPayload);
    const req = new Request(URL_CREDENTIALS_SHARED);
    req.authorise()
      .post({ credentials: finalPayload[index] })
      .then(() => {
        console.log('spec_Check_331', index);
        finalPayload[index].map(data => {
          if (!data.credential.externalAccount) {
            // const newCredential = {
            //   ...credentials[data.credential.id],
            //   // data.credential,
            // }
            let credential = credentialsData[data.credential.id];
            // credential.publicKey = pKey;
            // credential.secretKey = sKey;
            // credential.shared = true;
            // credential.sharedByDashboard = true;
            // credential.dashboardSpaceId = dashboards[currentDashboard].id;
            // this.props.getSharedCredentials(credential.id);
            // this.props.updateCredential(credential.id, credential);
          }
        });
        if (index >= Object.keys(finalPayload).length - 1) {
          this.setState({ canSubmit: false, isSubmitting: false });
          this.props.search(this.state.selectedValue === 'HomeDashboard' ?
          '' :
          this.state.selectedValue.toString(),
            'dashboardSpaceId');
          if (!back) {
            this.props.showModal(MODAL_MOVE_ACCOUNT_DASHBOARD, {
              currentApp: this.props.currentApp,
            });
          }
          else {
            this.props.showModal(MODAL_ACCOUNT_EDIT, {
              currentApp: this.props.currentApp,
            });
          }

          // api call ends here
          this.setState({
            loading: false
          });
        }
        else {
          this.callApiRequest(finalPayload, index + 1, credentialsData, back);
        }
      })
      .catch(e => console.log('shared error', e));
  }

  async submit(e, back) {
    this.setState({
      loading: true
    });
    this.props.setEditAccountProccessing(true);
    e.preventDefault();
    if(this.props.data.externalAccount) {
      const credential = new Credential({
       ...this.props.data,
       dashboardSpaceId: this.state.selectedValue === 'HomeDashboard' ? null : this.state.selectedValue,
      });

      await this.props.updateCredential({
        credential,
        eventSource: SOURCE_WEB_APP,
        id: this.props.data.id,
        tracking: this.props.isTracking,
        moveCredential: false,
        prevGroupId: this.props.data.groupId,
      });
      setTimeout(() => {
        this.props.setEditAccountProccessing(false);
        this.props.search(this.state.selectedValue === 'HomeDashboard' ?
          '' :
          this.state.selectedValue.toString(),
            'dashboardSpaceId');
        this.setState({
          loading: false
        });
      }, 4000);
    }
    else {
      // api call starts here
      const SharedCredentials = [];
      const unSharedCredentials = [];
      const unSharedCredentialsUID = [];

      const { dashboards } = this.props.state.ui;

      const { selectedDashboardName } = this.state;
      const selectedNewDashboard = this.state.selectedValue === 'HomeDashboard' ? '' : this.state.selectedValue;
      const fromDashboardsId = this.props.data.dashboardSpaceId;
      if (fromDashboardsId &&
        fromDashboardsId != 'home' &&
        fromDashboardsId != 'HomeDashboard' &&
        dashboards[fromDashboardsId].shared &&
        dashboards[fromDashboardsId].usersSharedWith.length > 0
      ) {
        if (!this.props.data.externalAccount) {
          const credential = {
            accountId: this.props.data.accountId,
            id: this.props.data.id,
            name: this.props.data.name,
            username: this.props.data.username,
            email: this.props.data.email,
            password: this.props.data.password,
            note: this.props.data.note,
            groupId: this.props.data.groupId,
            dashboardSpaceId: this.props.data.dashboardSpaceId,
            shared: false,
          };
          const {
            publicKey,
            secretKey,
          } = await getOrCreateKeyPairForSharingMultiConditions(credential, KeyPair);
          const worker = await getWorkerPromise('crypto');
          const [pKey, sKey] = await Promise.all([
            worker({
              event: CW_ENCRYPT_USING_MASTER_KEYPAIR,
              message: publicKey,
            }),
            worker({
              event: CW_ENCRYPT_USING_MASTER_KEYPAIR,
              message: secretKey,
            }),
          ])
            .then(values => values.map(v => Object.values(v)
              .join('')
              .replace('CW_ENCRYPT_USING_MASTER_KEYPAIR', '')));
          credential.dashboardSpaceId = this.props.data.dashboardSpaceId;
          const { encrypted } = await worker({
            event: CW_ENCRYPT_CREDENTIALS,
            credentials: [credential],
          });
          unSharedCredentials.push(encrypted[0]);
        }
        if (unSharedCredentials.length > 0) {
          for (const user of dashboards[fromDashboardsId].usersSharedWith) {
            unSharedCredentialsUID.push(user.id);
          }
        }
      }
      // console.log('spec_checkkk_dd', unSharedCredentialsUID);
      let unSharedUpdatedAccounts = [];
      if (unSharedCredentials.length > 0) {
        try {
          const reqBulkUnShare = new Request(URL_CREDENTIALS_SHARED);
          await reqBulkUnShare.authorise().put({
            credential: unSharedCredentials,
            unshareWithUserIds: unSharedCredentialsUID,
          }).then(res => {
            unSharedUpdatedAccounts = res;
          });
        }
        catch (error) {
          console.log('Error:', error);
        }
      }
      let bulkApiData = [];
     
      if (!this.props.data.externalAccount) {
        const credential = new Credential({
          ...this.props.data,
          dashboardSpaceId: selectedNewDashboard == '' ? null : selectedNewDashboard,
        });
        let shared = credential.get('shared');
        let publicKey = credential.get('publicKey');
        let secretKey = credential.get('secretKey');
        if (unSharedUpdatedAccounts.length > 0) {
          let cred = unSharedUpdatedAccounts.filter(x => x.id == this.props.data.id);
          if (cred.length > 0 && !cred[0].shared) {
            shared = false;
            publicKey = null;
            secretKey = null;
          }
        }
        const worker = getWorkerPromise('crypto');
        const account = {
          accountId: credential.get('accountId'),
          loginUrl: credential.get('loginUrl'),
          email: credential.get('email'),
          groupId: credential.get('groupId'),
          note: credential.get('note'),
          name: credential.get('name'),
          password: credential.get('password'),
          phone: credential.get('phone'),
          username: credential.get('username'),
          shared,
          publicKey,
          secretKey,
          dashboardSpaceId: credential.get('dashboardSpaceId'),
          id: this.props.data.id,
        };
        const data = await worker({ event: CW_ENCRYPT_CREDENTIALS, credentials: [account] });
        const [encrypted] = data.encrypted;
        encrypted.dashboardSpaceId = account.dashboardSpaceId === '' ? null : account.dashboardSpaceId;
        encrypted.id = this.props.data.id;
        bulkApiData = [encrypted];
      }

      let updateCredential = [];
      let oldCredential = this.props.data;
      try {
        const reqBulk = new Request(BULK_URL_CREDENTIAL);
        await reqBulk.authorise()
          .put({ credentials_data: bulkApiData })
          .then((res) => {
            updateCredential = res.updated_credentials;
            oldCredential.groupId = updateCredential[0].groupId;
          });
      } catch (error) {
        console.log('Error:', error);
      }
      // console.log('spepcepcec3', dashboards[selectedNewDashboard], selectedNewDashboard);
      if (selectedNewDashboard &&
        dashboards[selectedNewDashboard].shared &&
        dashboards[selectedNewDashboard].usersSharedWith
      ) {
        if (!this.props.data.externalAccount) {
          this.handleSharingDashboard(selectedNewDashboard,  oldCredential, back);
        }
        // for (const user of dashboards[selectedNewDashboard].usersSharedWith) {
        //   if (!this.props.data.externalAccount) {
        //     const credential = {
        //       ...this.props.data,
        //       groupId: this.props.data.groupId,
        //       dashboardSpaceId: selectedNewDashboard == '' ? null : selectedNewDashboard,
        //     };
        //     const {
        //       id: sharedWithUserId,
        //       publicKey: sharedWithUserPublicKey,
        //       responded: dashboardStatus,
        //     } = user;
        //     const {
        //       publicKey,
        //       secretKey,
        //     } = await getOrCreateKeyPairForSharingMultiConditions(credential,KeyPair);
        //     const worker = await getWorkerPromise('crypto');
        //     const [pKey, sKey] = await Promise.all([
        //       worker({
        //         event: CW_ENCRYPT_USING_MASTER_KEYPAIR,
        //         message: publicKey,
        //       }),
        //       worker({
        //         event: CW_ENCRYPT_USING_MASTER_KEYPAIR,
        //         message: secretKey,
        //       }),
        //     ])
        //       .then(values => values.map(v => Object.values(v)
        //         .join('')
        //         .replace('CW_ENCRYPT_USING_MASTER_KEYPAIR', '')));
        //     credential.publicKey = pKey;
        //     credential.secretKey = sKey;
        //     credential.shared = true;
        //     credential.sharedByDashboard = true;
        //     credential.dashboardSpaceId = selectedNewDashboard == '' ? null : selectedNewDashboard;
        //     credential.groupId = updateCredential.filter(xc => xc.id == this.props.data.id)[0].groupId;
        //     const { encrypted } = await worker({
        //       event: CW_ENCRYPT_CREDENTIALS,
        //       credentials: [credential],
        //     });
        //     if (!encrypted[0]) {
        //       throw new Error('Encrypted credentials unavailable');
        //     }
        //     const [{
        //       encrypted: encryptedPublicKey,
        //     }, {
        //       encrypted: encryptedSecretKey,
        //     }] = await Promise.all([
        //       worker({
        //         event: CW_ENCRYPT_WITH_EXTERNAL_KEY,
        //         message: publicKey,
        //         inputFormat: 'plain',
        //         outputFormat: 'base64',
        //         publicKey: sharedWithUserPublicKey,
        //       }),
        //       worker({
        //         event: CW_ENCRYPT_WITH_EXTERNAL_KEY,
        //         message: secretKey,
        //         inputFormat: 'plain',
        //         outputFormat: 'base64',
        //         publicKey: sharedWithUserPublicKey,
        //       }),
        //     ]);
        //     const SharedCredential = {
        //       credential: this.prepareCredentials(encrypted[0]),
        //       accountId: this.props.data.id,
        //       secretKey: encryptedSecretKey,
        //       publicKey: encryptedPublicKey,
        //       sharedWithUserId,
        //       hasPendingInvites: !dashboardStatus,
        //     };
        //     SharedCredentials.push(SharedCredential);
        //   }
        // }
      }

      // if (SharedCredentials.length > 0) {
      //   try {
      //     const reqBulkShare = new Request(URL_CREDENTIALS_SHARED);
      //     await reqBulkShare.authorise().post({ credentials: SharedCredentials });
      //   }
      //   catch (error) {
      //     console.log('Error:', error);
      //   }
      // }

      const { token, authenticate } = this.props;
      const { encryptedUserCredentials, profile } = await getUserData(token, true, true);

      const {
        groups, groupsOrder, id, shared,
      } = encryptedUserCredentials;
      await authenticate(
        id, token, groups, groupsOrder, shared, { override: true, skipPrompt: true }, profile,
      );
      this.setState({ canSubmit: false, isSubmitting: false });
      this.props.search(this.state.selectedValue === 'HomeDashboard' ?
      '' :
      this.state.selectedValue.toString(),
        'dashboardSpaceId');
      if (!back) {
        this.props.showModal(MODAL_MOVE_ACCOUNT_DASHBOARD, {
          currentApp: this.props.currentApp,
        });
      }
      else {
        this.props.showModal(MODAL_ACCOUNT_EDIT, {
          currentApp: this.props.currentApp,
        });
      }

      // api call ends here
      this.setState({
        loading: false
      });
    }
  }

  render() {
    const {
      data,
    } = this.props;
    const currentDashboardT = !('dashboardSpaceId' in data) ||
    data.dashboardSpaceId === '' ||
    data.dashboardSpaceId === null ?
      'HomeDashboard' : data.dashboardSpaceId
    return <Container
      options={this.props.dashboardOptions}
      currentDashboard={this.state.selectedValue}
      sharedByUserName={data.sharedByUserName}
      isShared={this.props.isShared}
      name={data.name}
      onSubmit={this.submit}
      isExternalAccount={data.externalAccount}
      accepted={data.accepted}
      cookieUrl={data.cookieUrl}
      domain={data.domain}
      loginUrl={data.loginUrl}
      onCancel={() => this.handleAction(() => this.props.hide())}
      onChangeCheckbox={this.handleChangeCheckbox}
      inoutchange={currentDashboardT != this.state.selectedValue}
      picture={this.props.data.picture}
      toggleView={() => this.handleAction(() => this.props.showModal(
        MODAL_ACCOUNT_EDIT,
        this.props.currentApp,
      ))}
      editAccountProccessing={this.state.loading}
    />;
  }
}

const mapStateToProps = state => {
  const currentApp = 'currentApp' in state.ui.modalData ?
    state.credentials.data[state.ui.modalData.currentApp.id] :
    state.credentials.data[state.ui.modalData.id];
  let dashboardOptions = [{
    label: `${state.user.profile.name}`,
    value: 'HomeDashboard',
  }];
  const isShared = currentApp.shared &&
    currentApp.usersSharedWith &&
    currentApp.usersSharedWith.filter(x => !x.sharedByDashboard).length > 0;
  Object.values(state.ui.dashboards).map(item => {
    if (item.externalDashboard || item.invitationId ||
      (item.shared &&
        currentApp.shared &&
        currentApp.usersSharedWith &&
        currentApp.usersSharedWith.filter(x => !x.sharedByDashboard).length > 0)) return;
    if (currentApp.externalAccount && !item.externalDashboard && item.shared) return;
    dashboardOptions = [
      ...dashboardOptions,
      {
        label: item.name,
        value: item.id,
        usersSharedWith: 'usersSharedWith' in item ? item.usersSharedWith : [],
      },
    ];
  });
  return {
    data: currentApp || {},
    dashboardOptions,
    currentDashboard: !('dashboardSpaceId' in currentApp) ||
    currentApp.dashboardSpaceId === '' ||
    currentApp.dashboardSpaceId === null ?
      'HomeDashboard' : currentApp.dashboardSpaceId,
    editAccountProccessing: state.ui.editAccountProccessing,
    isTracking: state.preferences.tracking,
    currentApp,
    isShared,
    token: state.session.accessToken,
    state,
    dashboards: state.ui.dashboards,  
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
  hide: () => {
    dispatch(RxEditAccountProccessing(false));
    return dispatch(hideModal());
  },
  updateCredential: args => dispatch(updateCredential(args)),
  showModal: (name, data) => dispatch(showModal(name, data)),
  search: (query, specialSearch = '') => {
    if (specialSearch === 'dashboardSpaceId') {
      dispatch(RXChangeActiveDashboard(query));
    }
    return dispatch(doSearch(query, specialSearch));
  },
  setEditAccountProccessing: status => dispatch(RxEditAccountProccessing(status)),
  authenticate: (...args) => dispatch(authenticateUserData(...args)),
});

export default translate()(connect(
  mapStateToProps,
  mapDispatchToProps,
)(withOverlayAction(ModalMoveAccountDashboardComponent)));
