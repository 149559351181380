/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { RX_APP_INIT } from 'main/state/actionTypes';
import Reducer from 'reducers/factory';
import { RX_ERROR_CLEAN, RX_ERROR_SHOW } from './actionTypes';

const defaultState = () => ({
  exists: false,
  text: '',
  title: '',
});

const reducer = new Reducer('error', defaultState());

export default (state = reducer.defaultState(), action) => {
  switch (action.type) {
    case RX_APP_INIT:
      return reducer.merge(state);

    case RX_ERROR_CLEAN:
      return reducer.defaultState();

    case RX_ERROR_SHOW:
      return {
        ...state,
        exists: true,
        text: action.text,
        title: action.title,
      };

    default:
      return state;
  }
};
