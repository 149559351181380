/*
 * Copyright (C) 2018-2019 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { hideModal } from 'main/modal';
import withOverlayAction from 'Overlay/withAction';
import Container from './container';

class ModalAccountDeleteConfirmComponent extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.props.addOverlayAction('modal', this.props.hide);
  }

  handleSubmit(event) {
    event.preventDefault();
    this.props.hide();
  }

  render() {
    return (
      <Container
        name={this.props.data.name}
        onSubmit={this.handleSubmit}
        picture={this.props.data.picture}
        t={this.props.t}
        domain={this.props.data.domain}
        loginUrl={this.props.data.loginUrl}
      />
    );
  }
}

const mapStateToProps = state => ({
  data: state.ui.modalData || {},
});

const mapDispatchToProps = dispatch => ({
  hide: () => dispatch(hideModal()),
});

export default translate()(connect(
  mapStateToProps,
  mapDispatchToProps,
)(withOverlayAction(ModalAccountDeleteConfirmComponent)));
