/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */

/**
 * Construct an object with user's personal details.
 *
 * @returns {object} User personal details.
 */
export const getUserProfile = () => (_, getState) => {
  const { profile } = getState().user;
  return {
    age: profile.age,
    email: profile.email,
    location: profile.location,
    name: profile.name,
    phone: profile.phone,
    id: profile.id || '',
  };
};

/**
 * Check if the session looks like it could be the user's first sign in.
 * We determine this by looking at the number of completed tutorial steps.
 * If the user completed any steps, they were previously logged in. Otherwise,
 * we make a guess that this is their first time.
 *
 * @returns {boolean}
 */
export const hasEncryptedData = () => (_, getState) => {
  const { credentials } = getState();
  const credentialsCount = Object.keys(credentials.data).length;
  return credentialsCount > 10;
};

/**
 * @returns {boolean}
 */
export const isUserAuthenticated = () => (_, getState) => {
  const { session } = getState();
  return !!session.createdAt && !!session.accessToken;
};

/**
 * @returns {boolean}
 */
export const shouldShowYellowBrickRoad = () => (_, getState) => {
  const { ui } = getState();
  return !!ui.showYellowBrickRoad;
};

export const getRouterState = () => (_, getState) => {
  const { router } = getState();
  return router;
};

export const getAllState = () => (_, getState) => {
  const { sharedAccounts, credentials } = getState();
  return { sharedAccounts, credentials };
};
