/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { handleAriaKeyPress, noop } from '@nettoken/helpers';
import Avatar from 'Avatar';
import { MOVE_STOP_WORD } from 'utils/move';
import { PropIcon } from 'utils/propTypes';
import { pendingInvitationGroupId } from 'main/vault/credentials';
import yell from '../../assets/images/yellow.png';
import info1 from '../../assets/images/info11.png';
import Styles from './style.css';

let timer;

class DoubleClick extends React.Component {
  onClickHandler = event => {
    clearTimeout(timer);
    if (event.detail === 1) {
      timer = setTimeout(this.props.onClick, 300);
    }
    else if (event.detail === 2) {
      this.props.onDoubleClick();
    }
  };

  render() {
    return (
      <div onClick={this.onClickHandler}>
        {this.props.children}
      </div>
    );
  }
}

class IconContainer extends React.Component {
  getInfoProps() {
    if (this.props.disableAppInfo) {
      return {};
    }

    return {
      onClick: this.props.onClickInfo,
      onKeyDown: e => handleAriaKeyPress(e) && this.props.onClickInfo(e),
      role: 'button',
      tabIndex: '0',
    };
  }

  render() {
    const isUnsorted = 'isUnsorted' in this.props ? this.props.isUnsorted : false;
    return (
      <DoubleClick onDoubleClick={this.props.onDoubleClick}
                   onClick={this.props.onClick}>
        <div
          className={classNames({
            [Styles.wrapper]: true,
            [Styles.wrapper1]: this.props.groupId === pendingInvitationGroupId(),
            ...this.props.classNameWrapper,
          })}
          style= {{ cursor: (this.props.groupId === pendingInvitationGroupId() || this.props.externalDashboard) ? 'default' : 'grab' }}
          id={this.props.id}
          onMouseEnter={this.props.onMouseEnter}
          onMouseLeave={this.props.onMouseLeave}
          onTouchStart={this.props.onTouchStart}
          ref="icon"
          role="button"
          tabIndex="0"
          {...this.props.spreadProps}>
          <div
            className={classNames({
              [Styles.detailsWrapper]: true,
            })}
            ref="infoWrapper"
            {...this.getInfoProps()}>
            {!this.props.disableAppInfo && <div className={classNames({
              [Styles.infoWrapper]: true,
              [MOVE_STOP_WORD]: true,
            })}>
              <svg width="26" height="26" viewBox="0 0 30 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="13" cy="6" r="1.75" transform="rotate(90 13 6)" fill="#28283F"/>
              <circle cx="13" cy="13" r="1.75" transform="rotate(90 13 13)" fill="#28283F"/>
              <circle cx="13" cy="20" r="1.75" transform="rotate(90 13 20)" fill="#28283F"/>
              </svg>
            </div>}
            {this.props.selectable && <div className={classNames({
              [Styles.selectableWrapper]: true,
              [Styles._selected]: this.props.selected,
            })}>
              <span className={Styles.selectableRectangle}/>
            </div>}
          </div>
          {!this.props.ishover && !this.props.sharedDashboard && (
            <>
              {
                (
                  (this.props.sharedAccounts === undefined &&
                  (this.props.isShared && (this.props.usersSharedWith &&
                  this.props.usersSharedWith.length > 0)) ||
                  (this.props.isExternalAccount)) ||
                  this.props.invitationId
                ) ? (
                  <span className={Styles.sharedMe}>
                    {this.props.groupId === pendingInvitationGroupId() ?
                      <img src={yell} width="31" height="31"/> :
                      <img src={info1} width="31" height="31"/>
                    }
                  </span>
                  ) : (this.props.sharedAccounts && (
                  <span className={Styles.sharedMe}>
                    <svg width="31" height="31" viewBox="0 0 31 31" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                         <path
                           d="M16 26C9.92487 26 5 21.0751 5 15C5 8.92487 9.92487 4 16 4C22.0751 4 27 8.92487 27 15C27 21.0751 22.0751 26 16 26Z"
                           fill="white"/>
                         <circle cx="16" cy="15" r="10.75" stroke="#28283F"/>
                         <path d="M16.0902 9.37494L16.4743 9.05485C16.3805 8.94229 16.2421 8.87654 16.0956 8.87497C15.9491 8.8734 15.8092 8.93616
                           15.713 9.04668L16.0902 9.37494ZM15.5902 20.6249C15.5902 20.9011 15.8141 21.1249 16.0902 21.1249C16.3663 21.1249 16.5902
                           20.9011 16.5902 20.6249L15.5902 20.6249ZM11.5166 13.8681C11.3353 14.0764 11.3572 14.3922 11.5655 14.5735C11.7738 14.7548
                           12.0896 14.7329 12.2709 14.5246L11.5166 13.8681ZM19.7239 14.5165C19.9007 14.7286 20.216 14.7573 20.4281 14.5805C20.6403
                           14.4037 20.6689 14.0884 20.4922 13.8763L19.7239 14.5165ZM15.5902 9.37494L15.5902 20.6249L16.5902 20.6249L16.5902 9.37494L15.5902
                           9.37494ZM12.2709 14.5246L16.4673 9.7032L15.713 9.04668L11.5166 13.8681L12.2709 14.5246ZM20.4922 13.8763L16.4743 9.05485L15.7061
                           9.69503L19.7239 14.5165L20.4922 13.8763Z"
                           fill="#28283F"/>
                    </svg>
                  </span>
                  ))
              }
            </>
          )}

          {this.props.groupId === pendingInvitationGroupId() || this.props.externalDashboard ?
            <Avatar
              classNameIcon={Styles.iconWrapperpen}
              domain={this.props.domain}
              name={this.props.name}
              picture={this.props.picture}
              grp={true}
              isUnsorted={isUnsorted}
            /> :
            <Avatar
              classNameIcon={Styles.iconWrapper}
              domain={this.props.domain}
              name={this.props.name}
              picture={this.props.picture}
              grp={false}
              isUnsorted={isUnsorted}
            />
          }

          {/* <div className={classNames({
          [Styles.iconWrapper]: true,
          [Styles._hasIcon]: true,
          [this.props.classNameIcon]: this.props.classNameIcon,
        })}>
          <img
            alt={`${this.props.name} ${t('global.icon')}`}
            className={Styles.icon}
            onError={setDefaultIcon}
            src={this.props.picture || defaultIcon} />
          <div className={Styles.imageOverlay}></div>
        </div> */}

        <span className={Styles.label} style= {{ cursor: (this.props.groupId === pendingInvitationGroupId() || this.props.externalDashboard) ? 'default' : '' }}>
          {this.props.name ? this.props.name : this.props.domain}
        </span>
        </div>
      </DoubleClick>
    );
  }
}

IconContainer.defaultProps = {
  classNameWrapper: [],
  name: '',
  onClick: noop,
  onClickInfo: noop,
  spreadProps: {},
};

IconContainer.propTypes = {
  classNameIcon: PropTypes.string,
  classNameWrapper: PropTypes.array,
  disableAppInfo: PropTypes.bool,
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  onClick: PropTypes.func,
  onClickInfo: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  picture: PropIcon(),
  selectable: PropTypes.bool,
  selected: PropTypes.bool,
  spreadProps: PropTypes.object,
  /** i18n translate method */
  t: PropTypes.func.isRequired,
};

export default IconContainer;
