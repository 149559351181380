/*
 * Copyright (C) 2018-2019 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import credentials from 'main/vault/credentials/reducer';
import device from 'main/device/reducer';
import error from 'main/error/reducer';
import flags from 'main/flags/reducer';
import groups from 'main/vault/groups/reducer';
import groupsOrder from 'main/vault/groupsOrder/reducer';
import preferences from 'main/preferences/reducer';
import search from 'main/search/reducer';
import session from 'main/session/reducer';
import tools from 'main/tools/reducer';
import tutorial from 'main/tutorial/reducer';
import ui from 'main/ui/reducer';
import user from 'main/user/reducer';
import sharedAccounts from 'main/sharedAccounts/reducer';

/**
 * @param {object} history
 *
 * @returns {object}
 */
const getReducers = history => {
  const reducers = {
    credentials,
    device,
    error,
    flags,
    groups,
    groupsOrder,
    preferences,
    router: connectRouter(history),
    search,
    session,
    tools,
    tutorial,
    ui,
    user,
    sharedAccounts,
  };
  return reducers;
};


/**
 * @param {object} [initialState]
 * @param {object} [action={}]
 *
 * @returns {function}
 */
const rootReducer = history => {
  const reducers = getReducers(history);
  const reducer = combineReducers(reducers);
  return (initialState, action = {}) => {
    const state = reducer(initialState, action);
    return state;
  };
};

export default rootReducer;
