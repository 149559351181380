/*
 * Copyright (C) 2018-2019 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import {
  MODAL_ACCOUNT_ADD,
  MODAL_ACCOUNT_ADD_FILE,
  MODAL_EMAIL_SCAN,
  MODAL_ADVANCED_SETTINGS,
  MODAL_ACCOUNT_REMOVE,
  MODAL_TEMPLATE_TRANSFER,
} from 'constants/modal';
import { SOURCE_MANUAL_SELECTION } from 'constants/events';
import { MODAL_ADD_ACCOUNT } from 'constants/routes';
import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { List } from 'immutable';
import debounce from 'lodash.debounce';
import { arrayItemSliceOrAppend, eventTarget } from '@nettoken/helpers';
import PropTypes from 'prop-types';
import { hideModal, showModal } from 'main/modal';
import { getPredefinedAccounts } from 'main/search';
import { addCredentials, addCredentialsEfficiently } from 'main/vault/credentials';
import { getGroupIdFromGroupInputField } from 'main/vault/groups';
import { history } from 'reducers/store';
import withAsyncState from 'AsyncState';
import withOverlayAction from 'Overlay/withAction';
import addmanuallyactive from 'icons/addmanuallyactive.svg';
import addmanuallyhover from 'icons/addmanuallyhover.svg';
import importfileactive from 'icons/importfileactive.svg';
import importfilehover from 'icons/importfilehover.svg';
import mailActive from 'icons/mailActive.svg';
import mailhover from 'icons/mailhover.svg';
import removeaccountactive from 'icons/removeaccountactive.svg';
import removeaccounthover from 'icons/removeaccounthover.svg';
import selectaccountactive from 'icons/selectaccountactive.svg';
import selectaccounthover from 'icons/selectaccounthover.svg';
import transfertemplateactive from 'icons/transfertemplateactive.svg';
import transfertemplatehover from 'icons/transfertemplatehover.svg';
import Container from './container';

class ModalAddAccountNavListComponent extends React.Component {
  constructor(props) {
    super(props);
    const { t } = props;
    this.handleClick = this.handleClick.bind(this);
    this.hide = this.hide.bind(this);
    this.templateTarget = this.templateTarget.bind(this);
    this.getIcons = this.getIcons.bind(this);
    this.getHoverIcons = this.getHoverIcons.bind(this);

    this.state = {
      buttons: [
        'Select from a list',
        'Copy paste a URL',
        // 'Search email',
        'Import a file',
        'Fill in a spreadsheet',
        'Remove accounts',
      ],
    };
  }

  componentDidMount() {
    this.props.addOverlayAction('modal', this.hide);
  }

  hide() {
    history.replace({ hash: '', search: '' });
    const { dispatch } = this.props;
    dispatch(hideModal());
  }

  handleClick(event, index) {
    event.preventDefault();

    const { dispatch } = this.props;
    dispatch(showModal(this.templateTarget(index)));
  }

  templateTarget(index) {
    switch (index) {
      case 1:
        return MODAL_ADVANCED_SETTINGS;
      // case 2:
      //   return MODAL_EMAIL_SCAN;
      case 2:
        return MODAL_ACCOUNT_ADD_FILE;
      case 3:
        return MODAL_TEMPLATE_TRANSFER;
      case 4:
        return MODAL_ACCOUNT_REMOVE;
      case 0:
      default:
        return MODAL_ACCOUNT_ADD;
    }
  }

  getIcons(index) {
    switch (index) {
      case 1:
        return addmanuallyactive;

      case 2:
        return importfileactive;

      case 3:
        return transfertemplateactive;

      case 4:
        return removeaccountactive;

      case 0:
      default:
        return selectaccountactive;
    }
  }

  getHoverIcons(index) {
    switch (index) {
      case 1:
        return addmanuallyhover;

      case 2:
        return importfilehover;

      case 3:
        return transfertemplatehover;

      case 4:
        return removeaccounthover;

      case 0:
      default:
        return selectaccounthover;
    }
  }


  render() {
    return (
      <Container
        buttons={this.state.buttons}
        getIcons={this.getIcons}
        handleClick={this.handleClick}
        templateTarget={this.templateTarget}
        disabled={this.props.disabled}
        dispatch={this.props.dispatch}
        t={this.props.t}
        getHoverIcons={this.getHoverIcons}/>
    );
  }
}

ModalAddAccountNavListComponent.defaultProps = {
  disabled: false,
};

ModalAddAccountNavListComponent.propTypes = {
  disabled: PropTypes.bool,
};

const mapStateToProps = state => ({
  ui: state.ui,
});

export default translate()(connect(
  mapStateToProps,
  null,
)(withAsyncState(withOverlayAction(ModalAddAccountNavListComponent))));
