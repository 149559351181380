import React from 'react';
import classNames from 'classnames';
import { Button } from '@nettoken/components';
import Helmet from 'Helmet';
import Tooltip from 'Tooltip';
import Switch from 'Switch';
import Input from 'Input';
import Styles from 'Modal/style.css';
import LeftArrowIcon from '../../../ActionIcons/LeftArrowIcon';
import CancelIcon from '../../../ActionIcons/CancelIcon';
import QuestionMarkIcon from '../../../ActionIcons/QuestionMarkIcon';
import { getIconColorNewmethod } from '../../../../utils/misc';
import moveAccountsArrowIcon from '../../../../assets/images/moveAccountsArrowIcon.svg';
import {
  EMAIL_ACCOUNT_LABEL_ID,
  EMAIL_ACCOUNT_LABEL_TEXT,
  NAME_ACCOUNT_LABEL_ID,
  NAME_ACCOUNT_LABEL_TEXT,
  PHONE_ACCOUNT_LABEL_ID,
  PHONE_ACCOUNT_LABEL_TEXT,
  TRACKING_TOGGLE_LABEL_ID,
  TRACKING_TOGGLE_LABEL_TEXT,
} from '../../../../constants/ids';

const SidebarSettingsContainer = ({
  email,
  getError,
  name,
  onCancel,
  onChange,
  onSubmit,
  phone,
  isTracking,
  setTrackingPermission,
  t,
  unsavedChanges,
  manageDashboards,
  moveAccounts,
  dashboardLength,
}) => {
  const form = [
    {
      autoComplete: 'name',
      label: 'Name',
      name: 'name',
      placeholder: t('placeholder.name'),
      type: 'text',
      value: name,
      content:
        'Your contacts will recognise you with this name when you share accounts.',
    },
    {
      autoComplete: 'email',
      hidden: !email,
      label: t('global.email'),
      name: 'email',
      placeholder: t('placeholder.emailUser'),
      readOnly: true,
      type: 'email',
      value: email,
      content: 'this is a phone input tooltip conetent',
    },
    {
      autoComplete: 'tel-national',
      label: t('global.phone'),
      name: 'Phone',
      placeholder: t('placeholder.phone'),
      readOnly: true,
      type: 'tel',
      value: phone,
      content:
        'Your phone number is the only personal information used to verify your unique Nettoken account and is critically used to restore a lost account. To update your phone number go to settings in the mobile phone app.',
    },
  ];

  return (
    <React.Fragment>
      <h1 aria-label="My Nettoken"
          className={classNames({
            [Styles.title1]: true,
            [Styles.stickyHeaderList]: true,
          })}>
          My Nettoken
          <CancelIcon onCancel={onCancel} />
      </h1>
      <div className={`${Styles.whiteBackground2} ${Styles.minHeightBackground}`}>
        <div className={`${Styles.fixedForm} ${Styles.fixedForm2}`}>
          <div className={classNames({
            [Styles.iconHeaderWrapper]: true,
            [Styles.noBg]: true,
          })}>
            <svg width='100%' height='100%' style={{ borderRadius: '50%' }}>
              <rect x='0' y='0' fill={getIconColorNewmethod(name)} height='100%' width='100%'></rect>
              <text
                dominantBaseline='middle'
                fill='#ffffff'
                fontSize='10'
                textAnchor='middle'
                x='48%'
                y='54%'
              >{name.slice(0, 2).toUpperCase()}</text>
            </svg>
          </div>
          <div className={Styles.form}>
              {form.map(row => {
                if (row.hidden) return null;
                return (
                  <Input
                    autoComplete={row.autoComplete}
                    error={getError(row.name)}
                    key={row.name}
                    label={row.label}
                    name={row.name}
                    onChange={onChange}
                    placeholder={row.placeholder}
                    readOnly={row.readOnly}
                    t={t}
                    tooltipContent={row.content}
                    type={row.type}
                    value={row.value}
                    />
                );
              })}
          </div>
          <div className={`${Styles.toggleView}`}>
            <p>Record activity of all accounts</p>
            <QuestionMarkIcon content={t('views.settings.tracking')} position='relative' rightPosition='-31px' placement='bottom'/>
            <div style={{ right: '-60px', position: 'relative' }}><Switch checked={isTracking} onChange={setTrackingPermission}/></div>
          </div>
          {dashboardLength > 0 && (
            <div
              onClick={moveAccounts}
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
              className={`${Styles.circleParent} ${Styles.toggleView}`}
            >
              <div
                className={Styles.circle}
              >
                <img
                  src={moveAccountsArrowIcon}
                  className={Styles.iconPlus}
                />
              </div>
              <span style={{ paddingLeft: '10px' }}>Move Accounts</span>
            </div>
          )}
        </div>
        <div
          className={
            `${Styles.actions} ${Styles._squeezedAltered} ${Styles.actionsFixed} ${Styles.fixedButtons}`
          }
          style={{
            justifyContent: 'space-between',
            width: '408px',
          }}
        >
          <Button
            className={`${Styles.button}`}
            onClick={manageDashboards}
            value='Manage dashboards' />
          <Button
            className={classNames({
              [Styles.button]: true,
              [Styles.notDisabled]: unsavedChanges && name.trim() != '',
              [Styles.cancelMarginLeft]: true,
            })}
            disabled={!unsavedChanges || name.trim() == ''}
            type="submit"
            onClick={onSubmit}
            value={t('global.save')} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default SidebarSettingsContainer;
