/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { RX_MOVE_ID } from './actionTypes';

/**
 * @param {string} [id=null]
 */
export const RXMoveId = (id = null) => ({ // eslint-disable-line import/prefer-default-export
  type: RX_MOVE_ID,
  id,
});
