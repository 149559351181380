/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Avatar from 'Avatar';
import { Button } from '@nettoken/components';
import { getIconUrl } from '@nettoken/helpers';
import { setDefaultIcon } from 'utils/misc';
import { PropIcon } from 'utils/propTypes';
import defaultIcon from 'icons/default-icon.svg';
import Input from 'Input';
import Styles from 'Modal/style.css';
import sharedAccountIcon from 'icons/upwardarrow.svg';
import sharedToMeIcon from 'icons/downwardarrow.svg';
import { getIconColorNewmethod } from '../../../../utils/misc';
import cross from '../../../../assets/images/cross.svg';
import leftsvg from '../../../../assets/images/left.svg';
import LeftArrowIcon from '../../../ActionIcons/LeftArrowIcon';
import CancelIcon from '../../../ActionIcons/CancelIcon';

const ModalAccountuserContainer = ({
  name,
  email,
  userName,
  displayName,
  picture,
  onCancel,
  onSubmit,
  sharedCredentialData,
  onchnageInput,
  searchuser,
  accountShared,
  isExternalAccount,
  accepted,
  userWindow,
  loginUrl,
  loading,
  domain,
  t,
  onBack,
}) => (
  <React.Fragment>
    <h1
      aria-label={t('modal.accountShare.title')}
      className={Styles.title1}>
        <LeftArrowIcon onClickBackHandler={onBack} />
      Share {name.length > 20 ? `${name.substr(0, 20)}…` : name}
      <CancelIcon onCancel={onCancel} />
    </h1>
    <form
      action="/"
      className={`${Styles.whiteBackground2} ${Styles.minHeightBackground}`}
      method="post"
      onSubmit={onSubmit}>
      <div className={`${Styles.fixedForm} ${Styles.fixedForm2}`}>
        <div className={classNames({
          [Styles.iconHeaderWrapper]: true,
          [Styles.noBg]: true,
          [Styles.nameIconHoverEffect]: true,
        })}>
          <Avatar
            className={Styles.iconHeaderWrapper}
            loginUrl={loginUrl}
            domain={domain}
            name={name}
            picture={picture}
          />
          {(accountShared) && (
            <img
              src={sharedAccountIcon}
              alt=''
              className={Styles.sharedIcon}
            />)
          }
        </div>
        <div style={{ textAlign: 'center' }}>
          {displayName}
        </div>
      <div style= {{ padding: '14px 21px' }}>
      <div
        className={`${Styles.actions} ${Styles._squeezedAltered} ${Styles.liAccounts} ${Styles.liAccountsNoHover}`}
        style={{
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <div className={Styles.sharedCredDetails}>
          <svg
            width='100%'
            height='100%'
            style={{
              borderRadius: '50%',
              height: '32px',
              width: '32px',
              border: `1px solid ${getIconColorNewmethod(sharedCredentialData.name)}`,
            }}
          >
            <rect
              x='0'
              y='0'
              fill={getIconColorNewmethod(sharedCredentialData.name)}
              height='100%'
              width='100%'
            ></rect>
            <text
              dominantBaseline='middle'
              fill='#ffffff'
              fontSize='10'
              textAnchor='middle'
              x='48%'
              y='54%'
            >
              {sharedCredentialData.name.slice(0, 2).toUpperCase()}
            </text>
          </svg>
        </div>
        <div style={{ width: '230px' }}>
          <span
            style={{
              display: 'block',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {sharedCredentialData.name}
          </span>
          <span
            style={{
              display: 'block',
              color: '#868692',
              fontSize: '14px',
            }}
          >
            {sharedCredentialData.phone}
          </span>
          <span style={{ color: '#868692', fontSize: '14px' }}>
            They will get view only access
          </span>
        </div>
      </div>
      </div>
      </div>
      <div className={`${Styles.actions} ${Styles.actionsFixed} ${Styles._squeezedAltered} ${Styles.fixedButtons}`}>
        <Button
          className={`${Styles.button}`}
          onClick={onCancel}
          value='Cancel' />
        <Button
          className={classNames({
            [Styles.button]: true,
            [Styles.notDisabled]: true,
            [Styles.cancelMarginLeft]: true,
          })}
          type="submit"
          disabled={false}
          loading={loading}
          value="Invite to share" />
      </div>
    </form>
  </React.Fragment>
);

ModalAccountuserContainer.defaultProps = {
  name: '',
  picture: '',
};

ModalAccountuserContainer.propTypes = {
  name: PropTypes.string,
  loginUrl: PropTypes.string,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  onchnageInput: PropTypes.func,
  userWindow: PropTypes.func,
  picture: PropIcon(),
  /** i18n translate method */
  t: PropTypes.func.isRequired,
};

export default ModalAccountuserContainer;
