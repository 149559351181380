/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
export const RX_SHARED_ACCOUNTS_CREATE = 'RX_SHARED_ACCOUNTS_CREATE';
export const RX_SHARED_ACCOUNTS_DELETE = 'RX_SHARED_ACCOUNTS_DELETE';
export const RX_SHARED_ACCOUNTS_REMOVE = 'RX_SHARED_ACCOUNTS_REMOVE';
export const RX_SHARED_ACCOUNTS_REMOVE_BULK = 'RX_SHARED_ACCOUNTS_REMOVE_BULK';
