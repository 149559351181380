/*
 * Copyright (C) 2019 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { List } from 'immutable';
import { Button } from '@nettoken/components';
import AppsPreview from 'AppsPreview';
import { getIconUrl } from '@nettoken/helpers';
import { setDefaultIcon } from 'utils/misc';
import defaultIcon from 'icons/default-icon.svg';
import GStyles from 'styles/index.css';
import Styles from 'Modal/style.css';

class ModalAccountOnboardContainer extends React.Component {
  content() {
    switch (this.props.step) {
      case 'confirm':
        return this.confirm();
      case 'preview':
        return this.preview();
      default:
        return this.confirm();
    }
  }

  preview() {
    const {
      apps,
      onChange,
      selectedApps,
      onClickPasswordToggle,
      onChangeSearchInPreview,
      onSelectApp,
      passwordList,
      query,
      t,
    } = this.props;
    return (
      <React.Fragment>
        <h1
          aria-label={t('modal.accountOnboard.previewtitle')}
          className={Styles.title}>
          {t('modal.accountOnboard.previewtitle')}
        </h1>
      <form
      action="/"
      className={classNames(Styles.formEmailScanPreview)}
      id="form"
      method="post"
      onSubmit={this.props.onSubmit}>
        <input
          aria-label={t('placeholder.search')}
          className={classNames(Styles.search, Styles._preview)}
          onChange={onChangeSearchInPreview}
          placeholder={`${t('placeholder.search')}...`}
          role="search"
          type="text"
          value={query} />
        <AppsPreview
          apps={apps}
          isEmailEditable={true}
          onChange={onChange}
          onClickPasswordToggle={onClickPasswordToggle}
          onSelectApp={onSelectApp}
          selectedApps={selectedApps}
          passwordList={passwordList}
        />
        <div className={Styles.actions}>
          <span>
            {t('modal.accountRemove.total', { count: this.props.selectedApps.size })}
          </span>
          <div>
            <Button
              className={Styles.button}
              onClick={this.props.onCancel}
              value={t('global.cancel')} />

            <Button
              className={Styles.button}
              disabled={!this.props.canSubmit}
              loaderSize={20}
              loading={this.props.submitting}
              type="submit"
              value={t('global.add')} />
          </div>
        </div>
      </form>
      </React.Fragment>
    );
  }

  confirm() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <h1
          aria-label={t('modal.accountOnboard.title')}
          className={Styles.title}>
          {t('modal.accountOnboard.title')}
        </h1>

        <form
          action="/"
          className={Styles.whiteBackground}
          id="form"
          method="post"
          onSubmit={this.props.onSubmit}>
          <ul className={classNames(Styles.list, Styles._matchModal)}>
            {
              this.props.loading ?
              <li className={Styles.listLoader}>
                <div className={GStyles.loader} />
              </li> :
                this.props.accounts.map((app, index) => <li
                  className={Styles.item}
                  key={app.id}>
                  <input
                    checked={app.checked}
                    className={Styles.inputRadioElement}
                    id={app.id}
                    onChange={() => this.props.onClickAccount && this.props.onClickAccount(index)}
                    type="checkbox" />

                  <label
                    className={Styles.itemContent}
                    htmlFor={app.id}>
                    <div className={Styles.itemHeader}>
                      <div className={classNames({
                        [Styles.iconListWrapper]: true,
                        [Styles._hasIcon]: app.picture,
                      })}>
                        <img
                          alt={`${app.name} ${t('global.icon')}`}
                          className={Styles.icon}
                          onError={setDefaultIcon}
                          src={getIconUrl(app.picture, 30) || defaultIcon} />
                      </div>
                      <span className={classNames(Styles.labelList, Styles._singleLine)}>
                        {app.name}
                      </span>
                    </div>
                    <div className={Styles.inputRadioBorder} />
                  </label>
                </li>)
            }
          </ul>

          <div className={Styles.actions}>
            <span>
              {t('modal.accountRemove.total', { count: this.props.selectedApps.size })}
            </span>
            <div>
              <Button
                className={Styles.button}
                onClick={this.props.onCancel}
                value={t('global.cancel')} />

              <Button
                className={Styles.button}
                disabled={!this.props.canSubmit}
                loaderSize={20}
                loading={this.props.submitting}
                type="submit"
                value={t('global.preview')} />
            </div>
          </div>
        </form>
      </React.Fragment>
    );
  }

  render() {
    return (
      this.content()
    );
  }
}

ModalAccountOnboardContainer.defaultProps = {
  accounts: new List(),
  loading: true,
};

ModalAccountOnboardContainer.propTypes = {
  accounts: PropTypes.instanceOf(List),
  canSubmit: PropTypes.bool,
  loading: PropTypes.bool,
  onCancel: PropTypes.func,
  onClickAccount: PropTypes.func,
  onSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  /** i18n translate method */
  t: PropTypes.func.isRequired,
};

export default ModalAccountOnboardContainer;
