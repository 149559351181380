/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { RXErrorShow, RXErrorUI } from './reduxActions';

/**
 * @param {function} t i18n translate method.
 *
 * @returns {boolean} Was the operation successful?
 */
export const blockNonDesktopDevice = t => (dispatch, getState) => {
  if (isSupportedDevice(getState().device)) return false;
  const title = t('error.nosupport.title');
  const text = t('error.nosupport.text');
  dispatch(RXErrorShow(title, text));
  return true;
};

/**
 * Handle the catch clause of promise and display UI error.
 * Used when adding or editing an app for example.
 *
 * @param {array|object} errors When passing a single error, we can wrap it
 *   into array implicitly.
 */
export const catchUIError = errors => dispatch => {
  if (typeof errors === 'string') return;
  if (!Array.isArray(errors)) errors = [errors];
  errors.forEach(e => dispatch(showUIError(e.message, e.forElement)));
};

/**
 * @param {string} id
 */
export const hideUIError = id => (dispatch, getState) => {
  let errors = [];
  if (id) {
    ({ errors } = getState().ui);
    for (let i = 0; i < errors.length; i += 1) {
      if (errors[i].forElement === id) {
        errors = [
          ...errors.slice(0, i),
          ...errors.slice(i + 1),
        ];
        break;
      }
    }
  }
  dispatch(RXErrorUI(errors));
};

/**
 * @param {object} device
 *
 * @returns {boolean}
 */
export const isSupportedDevice = device => device.isDesktop || device.isPad; // || device.isTablet;

/**
 * @param {string} message
 * @param {string} [forElement] Element id.
 */
export const showUIError = (message, forElement) => (dispatch, getState) => {
  let { errors } = getState().ui;
  let overwritten = false;

  for (let i = 0; i < errors.length; i += 1) {
    if (errors[i].forElement === forElement) {
      errors[i].message = message;
      overwritten = true;
      break;
    }
  }

  if (!overwritten) {
    errors = [
      ...errors,
      {
        forElement,
        message,
      },
    ];
  }

  dispatch(RXErrorUI(errors));
};
