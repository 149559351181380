/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Styles from './style.css';

const BackgroundComponent = ({
  centerHorizontally,
  centerVertically,
  children,
  colour,
  paddingTop,
}) => (
  <div
    className={classNames({
      [Styles.wrapper]: true,
      [Styles.white]: colour === 'white',
      [Styles.yellow]: colour === 'yellow',
      [Styles.centerHorizontally]: centerHorizontally,
      [Styles.centerVertically]: centerVertically,
    })}
    style={{ paddingTop }}>
    {children}
  </div>
);

BackgroundComponent.defaultProps = {
  colour: 'grey',
};

BackgroundComponent.propTypes = {
  centerHorizontally: PropTypes.bool,
  centerVertically: PropTypes.bool,
  /** Colour scheme. */
  colour: PropTypes.string,
  paddingTop: PropTypes.number,
};

export default BackgroundComponent;
