/*
 * Copyright (C) 2018-2019 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { eventTarget } from '@nettoken/helpers';
import Routes from 'config/routes';
import { goTo } from 'main/router';
import { deleteUserAccount, exportUserData } from 'main/user';
import withOverlayAction from 'Overlay/withAction';
import { hideModal } from 'main/modal';
import Container from './container';

class SidebarDeleteAccountComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      checked: '',
      deleting: false,
      downloading: false,
    };

    this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this);
    this.handleClickDownload = this.handleClickDownload.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.props.addOverlayAction('modal', this.props.hide);
  }

  handleChangeCheckbox(event) {
    const { checked: isChecked, name } = eventTarget(event);
    const checked = isChecked ? name : '';
    this.setState({ checked });
  }

  async handleClickDownload() {
    this.setState({ downloading: true });
    switch (this.state.checked) {
      case 'decrypted':
      case 'encrypted':
        await this.props.exportData(this.state.checked === 'encrypted');
        this.setState({ downloading: false });
        break;

      // no default
    }
  }

  async handleSubmit(event) {
    event.preventDefault();
    this.setState({ deleting: true });
    try {
      await this.props.deleteAccount();
      this.setState({ deleting: false });
    }
    catch (e) {
      console.log(e);
      this.setState({ deleting: false });
    }
  }

  render() {
    return (
      <Container
        checkedOption={this.state.checked}
        deleting={this.state.deleting}
        downloading={this.state.downloading}
        onCancel={this.props.hide}
        onChangeCheckbox={this.handleChangeCheckbox}
        onClickDownload={this.handleClickDownload}
        onSubmit={this.handleSubmit}
        t={this.props.t} />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  deleteAccount: () => dispatch(deleteUserAccount()),
  exportData: encrypted => dispatch(exportUserData(encrypted)),
  goBack: () => dispatch(goTo(Routes.SETTINGS)),
  hide: () => dispatch(hideModal()),
});

export default translate()(connect(
  null,
  mapDispatchToProps,
)(withOverlayAction(SidebarDeleteAccountComponent)));
