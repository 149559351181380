/*
 * Copyright (C) 2018-2019 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';

import {
  addClass,
  addEvent,
  eventTarget,
  removeClass,
  removeEvent,
  smoothScroll,
} from '@nettoken/helpers';

import Validator from '@nettoken/validator';
import Routes from 'config/routes';
import { getError } from 'main/error/reduxState';
import { goTo } from 'main/router';
import { syncPreference, syncPreferenceInputs } from 'main/preferences';
import { RXPrefsFrequency } from 'main/preferences/reduxActions';
import { changeUserPersonalDetails } from 'main/user';
import { RXUserChangesSave } from 'main/user/reduxActions';
import CSSVars from 'runtime/variables';
import Styles from 'Sidebar/style.css';
import withOverlayAction from 'Overlay/withAction';
import Container from './container';

export const SECTIONS_TOP = ['required', 'optional'];
export const SECTIONS_BOTTOM = [];

class SidebarPrivacyComponent extends React.Component {
  constructor(props) {
    super(props);

    this.highlightedMenuItem = null;
    this.scrollPadding = 0;
    this.scrollTimer = null;
    this.sections = {};

    this.handleBack = this.handleBack.bind(this);
    this.handleChangePermission = this.handleChangePermission.bind(this);
    this.handleMenuItemClick = this.handleMenuItemClick.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.onChangeFrequency = this.onChangeFrequency.bind(this);
  }

  componentDidMount() {
    this.props.addOverlayAction('sidebar', this.handleBack);

    /*
    * These must run before `scrollToHash()` because it scrolls
    * wrapper and our coordinates would be miscalculated.
    */
    this.highlightMenuItem(true);
    this.getScrollPadding();
    this.handleResize();

    const { router } = this.props;
    const { hash } = router.location;
    this.scrollToHash(hash);

    addEvent(window, 'resize', this.handleResize);
  }

  componentDidUpdate(prevProps) {
    const { hash: oldValue } = prevProps.router.location;
    const { hash: newValue } = this.props.router.location;

    if (this.isDifferentSection(oldValue, newValue)) {
      this.handleResize();
    }

    this.highlightMenuItem(false);
  }

  componentWillUnmount() {
    removeEvent(window, 'resize', this.handleResize);
  }

  countErrors() {
    const errors = this.getErrors();
    let count = 0;

    Object.keys(errors).forEach(key => {
      if (errors[key]) {
        count += 1;
      }
    });

    return count;
  }

  getErrors() {
    return {
      age: this.props.getError('age'),
      hometown: this.props.getError('location'),
    };
  }

  getHashes(sectionsArr) {
    return sectionsArr.map(x => `#${x}`);
  }

  getScrollableWrapper() {
    const [wrapper] = document.getElementsByClassName(Styles.sectionWrapper);
    return wrapper;
  }

  getScrollPadding() {
    const [content] = document.getElementsByClassName(Styles._body);
    if (!content) return;
    const computedCSS = getComputedStyle(content);
    const padding = Validator.strToInt(computedCSS['padding-top']);
    this.scrollPadding = padding || 0;
  }

  handleBack() {
    const errors = this.countErrors();

    // Save any changes if there are no errors.
    if (errors === 0) {
      const { dispatch } = this.props;
      dispatch(RXUserChangesSave());
      dispatch(syncPreferenceInputs()).catch(e => console.log(e));
    }

    this.hide();
  }

  handleChangePermission(event) {
    const { dispatch } = this.props;
    const { checked, value } = eventTarget(event);
    dispatch(syncPreference(value, checked)).catch(e => console.log(e));
  }

  handleMenuItemClick(event) {
    const { hash: oldValue } = this.props.router.location;
    const { value: newValue } = event.currentTarget;
    const diff = this.isDifferentSection(oldValue, newValue);
    this.setHash(newValue);

    requestAnimationFrame(() => {
      const element = this.getScrollableWrapper();
      const target = this.sections[newValue].scrollTop - this.scrollPadding;
      smoothScroll(element, target, diff ? 0 : CSSVars.trMove);
    });
  }

  handleResize() {
    const { router } = this.props;
    const { hash } = router.location;

    this.saveSectionsCoordinates();
    this.scrollToHash(hash);
  }

  handleScroll(event) {
    clearTimeout(this.scrollTimer);

    const { scrollTop } = eventTarget(event);
    const currentSection = this.props.router.location.hash.substr(1);
    const sectionHashes = Object
      .keys(this.sections)
      .filter(hash => {
        if (SECTIONS_BOTTOM.includes(currentSection)) {
          return SECTIONS_BOTTOM.includes(hash.substr(1));
        }

        return !SECTIONS_BOTTOM.includes(hash.substr(1));
      });
    const limit = sectionHashes.length;

    this.scrollTimer = setTimeout(() => {
      for (let i = 1; i < limit; i += 1) {
        const sectionHash = sectionHashes[i];
        const {
          height,
          scrollTop: sectionScrollTop,
        } = this.sections[sectionHash];

        // It's the previous hash.
        if (scrollTop + this.scrollPadding < sectionScrollTop - (height / 2)) {
          this.setHash(sectionHashes[i - 1]);
          break;
        }
        // It's last hash.
        else if (i + 1 === limit) {
          this.setHash(sectionHash);
        }
      }
    }, 5);
  }

  hide() {
    this.props.goTo(Routes.SETTINGS);
  }

  highlightMenuItem(focus) {
    const { router } = this.props;
    const { hash } = router.location;
    const item = document.querySelector(hash ? `[value="${hash}"]` : '[value^="#"]');
    if (!item) return;

    if (this.highlightedMenuItem) {
      removeClass(this.highlightedMenuItem, Styles._active);
    }

    addClass(item, Styles._active);
    this.highlightedMenuItem = item;

    if (focus) item.focus();
  }

  isDifferentSection(prevHash, nextHash) {
    const s = this.getHashes(SECTIONS_BOTTOM);
    return prevHash !== nextHash && (
      (s.includes(prevHash) && !s.includes(nextHash)) ||
      (!s.includes(prevHash) && s.includes(nextHash))
    );
  }

  onChangeFrequency(event) {
    const { value } = eventTarget(event);
    this.props.RXPrefsFrequency('newsletterFrequency', value);
  }

  saveSectionsCoordinates() {
    const items = document.querySelectorAll('[value^="#"]');
    const wrapper = this.getScrollableWrapper();
    const scrolled = wrapper.scrollTop || 0;

    for (let i = 0; i < items.length; i += 1) {
      const { value: hash } = items[i];
      const section = document.getElementById(hash.substr(1));

      if (section) {
        this.sections[hash] = {
          height: section.offsetHeight,
          scrollTop: section.getBoundingClientRect().top + scrolled,
        };
      }
    }
  }

  /*
   * We need to fix the initial load as it doesn't jump
   * automatically because the child component is not
   * rendered when the URL hash is read.
   */
  scrollToHash(hash) {
    const section = document.getElementById(hash.substr(1));
    if (!section) return false;
    const wrapper = this.getScrollableWrapper();
    if (!wrapper) return false;
    const { top } = section.getBoundingClientRect();
    const scrolled = wrapper.scrollTop;
    wrapper.scrollTop = scrolled + (top - this.scrollPadding);
    return true;
  }

  setHash(newValue) {
    const { hash: oldValue } = this.props.router.location;

    if (oldValue !== newValue) {
      this.props.goTo(newValue);
    }
  }

  render() {
    const { preferences: prefs, router, user } = this.props;
    const { hash } = router.location;
    const { age, location: hometown } = user.profile;
    const errors = this.getErrors();
    return (
      <Container
        age={age}
        ageError={errors.age}
        frequencyNewsletter={prefs.newsletterFrequency}
        hometown={hometown}
        hometownError={errors.hometown}
        onCancel={this.handleBack}
        onChangeInput={this.props.changeUserPersonalDetails}
        onChangePermission={this.handleChangePermission}
        onChangeFrequency={this.onChangeFrequency}
        onClick={this.handleMenuItemClick}
        onScrollWrapper={this.handleScroll}
        permissionCookies={prefs.cookies}
        permissionDevice={prefs.device}
        permissionInfo={prefs.info}
        permissionLocation={prefs.location}
        permissionNewsletter={prefs.newsletter}
        sectionsBottom={SECTIONS_BOTTOM}
        sectionsTop={SECTIONS_TOP}
        showMainContent={!this.getHashes(SECTIONS_BOTTOM).includes(hash)}
        t={this.props.t} />
    );
  }
}

const mapStateToProps = state => ({
  preferences: state.preferences,
  // We want to listen to hash changes.
  router: state.router,
  // Update on UI errors change.
  ui: state.ui,
  user: state.user,
});

const mapDispatchToProps = dispatch => ({
  dispatch,
  changeUserPersonalDetails: e => dispatch(changeUserPersonalDetails(e)),
  getError: id => dispatch(getError(id)),
  goTo: to => dispatch(goTo(to)),
  RXPrefsFrequency: (k, v) => dispatch(RXPrefsFrequency(k, v)),
});

export default translate()(connect(
  mapStateToProps,
  mapDispatchToProps,
)(withOverlayAction(SidebarPrivacyComponent)));
