/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { PRESET_PERSIST_SESSION } from 'constants/presets';
import { List } from 'immutable';
import { isProduction } from '@nettoken/env';
import { getReduxStore } from 'utils/storage';

// This has to run first to capture the state before it gets overwritten.
export const lastBrowserSession = getReduxStore().value;

/**
 * @returns {boolean}
 */
const persistSession = () => {
  const persist = !isProduction && !!(
    lastBrowserSession &&
    lastBrowserSession.tools &&
    lastBrowserSession.tools.presets &&
    lastBrowserSession.tools.presets[PRESET_PERSIST_SESSION]
  );
  return persist;
};

/**
 * @returns {List}
 */
export const getPersistedReducers = () => {
  let persisted = new List([
    'tools',
  ]);

  if (persistSession()) {
    persisted = persisted.push(
      'credentials',
      'device',
      'flags',
      'groups',
      'groupsOrder',
      'preferences',
      'session',
      'tutorial',
      'user',
    );
  }

  return persisted;
};
