/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
const DIR_APP_BASE = '/';

/**
 * @param {string} path
 *
 * @returns {string}
 */
const ensureSlash = path => {
  if (path.endsWith('/')) return path;
  return `${path}/`;
};

export const AUTH = `${ensureSlash(DIR_APP_BASE)}login`;
export const AUTHENTICATE = `${ensureSlash(DIR_APP_BASE)}authenticate`;
export const AUTH_MANUALLY = `${ensureSlash(DIR_APP_BASE)}login-manually`;
export const DASHBOARD = `${ensureSlash(DIR_APP_BASE)}dashboard`;
export const FAQ = `${ensureSlash(DIR_APP_BASE)}faq`;
export const GET_STARTED = `${ensureSlash(DIR_APP_BASE)}getting-started`;
export const GetPricing = `${ensureSlash(DIR_APP_BASE)}pricing`;
export const About = `${ensureSlash(DIR_APP_BASE)}about`;
export const GET_STARTED_OVERVIEW = `${ensureSlash(GET_STARTED)}overview`;
export const HOMEPAGE = ensureSlash(DIR_APP_BASE);
export const INSTRUCTIONS = `${ensureSlash(GET_STARTED)}:page`;
export const LEGAL = `${ensureSlash(DIR_APP_BASE)}legal`;
export const LEGAL_CONTACT = `${ensureSlash(LEGAL)}contact`;
export const LEGAL_COOKIES = `${ensureSlash(LEGAL)}cookies`;
export const LEGAL_PRIVACY = `${ensureSlash(LEGAL)}privacy-policy`;
export const LEGAL_TOS = `${ensureSlash(LEGAL)}terms-and-conditions`;
export const OAUTH = `${ensureSlash(DIR_APP_BASE)}oauth`;
export const PRIVACY = `${ensureSlash(DASHBOARD)}privacy`;
export const SETTINGS = `${ensureSlash(DASHBOARD)}settings`;
export const SETTINGS_BACKUP = `${ensureSlash(DASHBOARD)}backup`;
export const SETTINGS_BASICS = `${ensureSlash(SETTINGS)}basics`;
export const SETTINGS_DOWNLOAD_DATA = `${ensureSlash(SETTINGS)}downloaddata`;
export const SETTINGS_DELETE_ACCOUNT = `${ensureSlash(DASHBOARD)}delete-account`;
export const SETTINGS_IMPORT = `${ensureSlash(DASHBOARD)}import`;
export const SETTINGS_MORE = `${ensureSlash(SETTINGS)}advanced`;
export const SETTINGS_SESSION = `${ensureSlash(DASHBOARD)}session`;
export const SUCCESS_LOGGED_IN = `${ensureSlash(DIR_APP_BASE)}user-authenticated`;

// Query string params.
export const MODAL_ADD_ACCOUNT = 'add-account';
export const MODAL_REMOVE_ACCOUNT = 'remove-account';
export const MODAL_MOVE_ACCOUNTS = 'move-accounts';
export const EXTENSION_REMOVED = 'remove-extension';
