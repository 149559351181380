/* eslint-disable */
/*
 * Copyright (C) 2018-2019 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { MODAL_ACCOUNT_DELETE_CONFIRM, MODAL_ACCOUNT_EDIT } from 'constants/modal';
import React from 'react';
import { List } from 'immutable';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { showModal, hideModal } from 'main/modal';
import { removeSharedByAccounts } from 'main/sharedAccounts';
import { Request } from 'utils/request';
import { deleteCredentials } from 'main/vault/credentials';
import withOverlayAction from 'Overlay/withAction';
import {
  CW_ENCRYPT_USING_MASTER_KEYPAIR,
  CW_ENCRYPT_CREDENTIALS,
  CW_DECRYPT_USING_MASTER_KEYPAIR,
  CW_GENERATE_EPHEMERAL_KEY_PAIR,
  getWorkerPromise,
  CW_EXTRACT_MASTER_KEYS,
} from '@nettoken/crypto-worker';
import KeyPair from '@nettoken/crypto-worker/src/keypair';
import { deleteGroup } from 'main/vault/groups';
import Container from './container';
import { RXDashboardDecline, RxEditAccountProccessing } from '../../../../main/modal/reduxActions';
import { DASHBOARD_SHARING, DASHBOARD_SPACES } from '../../../../constants/endpoints';
import { MODAL_EDIT_DASHBOARD } from '../../../../constants/modal';
import { RXChangeActiveDashboard, RXDashboardRemove } from '../../../../main/ui/reduxActions';
import { refreshSearch } from '../../../../main/search';
import { getUserData, encryptCredential } from '../../../../main/user';
import { authenticateUser, authenticateUserData } from '../../../../main/auth';
import { getOrCreateKeyPairForSharing, getOrCreateKeyPairForSharingDashboard } from '../../../../utils/misc';

class ModalDashboardDeleteComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteProcessing: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.hide = this.hide.bind(this);
  }

  componentDidMount() {
    this.props.addOverlayAction('modal', () => this.hide());
  }

  // async getOrCreateKeyPairForSharing(credential) {
  //   const keypair = new KeyPair();
  //   const {
  //     publicKey,
  //     secretKey,
  //   } = credential;
  //   const worker = await getWorkerPromise('crypto');
  //   if (credential.shared === true && publicKey && secretKey) {
  //     const [pKey, sKey] = await Promise.all([
  //       worker({
  //         event: CW_DECRYPT_USING_MASTER_KEYPAIR,
  //         message: publicKey,
  //       }),
  //       worker({
  //         event: CW_DECRYPT_USING_MASTER_KEYPAIR,
  //         message: secretKey,
  //       }),
  //     ])
  //       .then(values => values.map(v => Object.values(v)
  //         .join('')
  //         .replace('CW_DECRYPT_USING_MASTER_KEYPAIR', '')));

  //     keypair.set(pKey, sKey);
  //   }
  //   else {
  //     // const newKeyPair = await worker({
  //     //   event: CW_GENERATE_EPHEMERAL_KEY_PAIR,
  //     //   format: 'base64',
  //     // });
  //     // // keypair.set(newKeyPair.publicKey, newKeyPair.secretKey);

  //     const { newKeyPairPublicKey, newKeyPairSecretKey } =
  //     await worker({ event: CW_GENERATE_EPHEMERAL_KEY_PAIR, format: 'base64' });
  //     // Save the data on server.
  //     const event = CW_EXTRACT_MASTER_KEYS;
  //     const { encryptedPrivateKey } =
  //     await worker({ event, newKeyPairPublicKey, newKeyPairSecretKey });
  //     keypair.set(newKeyPairPublicKey, newKeyPairSecretKey);
  //   }
  //   return keypair;
  // }

  async handleSubmit(event) {
    this.setState({ deleteProcessing: true });
    let encryptedDashboardName;
    event.preventDefault();
    const unSharedCredentials = [];
    const unSharedCredentialsUID = [];
    if (
      this.props.dashboardData.shared
    ) {
      const { currentCredentials } = this.props;
      for (const credential of currentCredentials) {
        const objCredential = {
          id: credential.id,
          name: credential.name,
          username: credential.username,
          email: credential.email,
          password: credential.password,
          accountId: credential.accountId,
          shared: false,
          note: credential.note,
        };
        const {
          publicKey,
          secretKey,
        } = await getOrCreateKeyPairForSharingDashboard(objCredential, KeyPair);
        const worker = await getWorkerPromise('crypto');
        const [pKey, sKey] = await Promise.all([
          worker({
            event: CW_ENCRYPT_USING_MASTER_KEYPAIR,
            message: publicKey,
          }),
          worker({
            event: CW_ENCRYPT_USING_MASTER_KEYPAIR,
            message: secretKey,
          }),
        ])
          .then(values => values.map(v => Object.values(v)
            .join('')
            .replace('CW_ENCRYPT_USING_MASTER_KEYPAIR', '')));
        const { encrypted } = await worker({
          event: CW_ENCRYPT_CREDENTIALS,
          credentials: [objCredential],
        });
        unSharedCredentials.push(encrypted[0]);
      }
      if (unSharedCredentials.length > 0) {
        for (const user of this.props.dashboardData.usersSharedWith) {
          unSharedCredentialsUID.push(user.id);
        }
      }
    }
    if ('externalDashboard' in this.props.dashboardData && this.props.dashboardData.externalDashboard) {
      const worker = await getWorkerPromise('crypto');
      [encryptedDashboardName] = await Promise.all([
        worker({
          event: CW_ENCRYPT_USING_MASTER_KEYPAIR,
          message: this.props.dashboardData.name,
        }),
      ]).then(values1 => values1.map(v => Object.values(v)
        .join('')
        .replace('CW_ENCRYPT_USING_MASTER_KEYPAIR', '')));
      const { currentCredentials } = this.props;
      const promises = currentCredentials.map(async credentialData => {
        const id = credentialData.groupId;
        const params = {
          data: credentialData,
          unshareWithUserIds: [],
        };
        // const res = await this.props.removeSharedByAccounts(params);
        const resDelete = await this.props.deleteGroup({ id });
      });
      const req = new Request(DASHBOARD_SHARING);
      const dashboardData = {
        id: this.props.dashboardData.id,
        name: encryptedDashboardName,
        publicKey: this.props.dashboardData.publicKey,
        secretKey: this.props.dashboardData.secretKey,
        userId: this.props.dashboardData.userId,
        shared: false,
      };
      await req
        .authorise()
        .put({ dashboard: dashboardData, unshareWithUserIds: [], credentials: unSharedCredentials })
        .then(res => {
          this.props.refreshSearch();
          if (this.props.currentDashboard === this.props.data.id) {
            this.props.changeDashboard('');
          }
          this.props.deleteDashboard(this.props.data.id);
          this.props.hideModal();
          this.refreshUserData();
        }).catch(e => Promise.reject(e));
    }
    else {
      if (
        (
          !('externalDashboard' in this.props.dashboardData) ||
          !this.props.dashboardData.externalDashboard
        ) &&
        this.props.dashboardData.shared &&
        this.props.dashboardData.usersSharedWith
      ) {
        const worker = await getWorkerPromise('crypto');
        [encryptedDashboardName] = await Promise.all([
          worker({
            event: CW_ENCRYPT_USING_MASTER_KEYPAIR,
            message: this.props.dashboardData.name,
          }),
        ]).then(values1 => values1.map(v => Object.values(v)
          .join('')
          .replace('CW_ENCRYPT_USING_MASTER_KEYPAIR', '')));
        const { currentCredentials } = this.props;
        const promises = currentCredentials.map(async credentialData => {
          const id = credentialData.groupId;
          // const params = {
          // data: credentialData,
          // unshareWithUserIds: [],
          // };
          // const res = await this.props.removeSharedByAccounts(params);
          // const resDelete = await this.props.deleteGroup({ id });
        });
        const reqSharing = new Request(DASHBOARD_SHARING);
        const dashboardData = {
          id: this.props.dashboardData.id,
          name: encryptedDashboardName,
          publicKey: this.props.dashboardData.publicKey,
          secretKey: this.props.dashboardData.secretKey,
          userId: this.props.dashboardData.userId,
          shared: false,
        };
        await reqSharing
          .authorise()
          .put({
            dashboard: dashboardData,
            unshareWithUserIds: unSharedCredentialsUID,
            credentials: unSharedCredentials,
          });
      }

      const req = new Request(DASHBOARD_SPACES);
      await req
        .authorise()
        .delete({ id: this.props.data.id })
        .then(res => {
          this.props.refreshSearch();
          if (this.props.currentDashboard === this.props.data.id) {
            this.props.changeDashboard('');
          }
          this.props.deleteDashboard(this.props.data.id);
          this.refreshUserData();
          this.props.hideModal();
        }).catch(e => Promise.reject(e));
    }
  }

  hide() {
    this.props.showModal(MODAL_EDIT_DASHBOARD, {
      id: this.props.data.id,
      dashboardName: this.props.data.name,
    });
  }

  refreshUserData = async () => {
    const { token, authenticate } = this.props;
    const { encryptedUserCredentials, profile } = await getUserData(token, true, true);

    const {
      groups, groupsOrder, id, shared,
    } = encryptedUserCredentials;
    return authenticate(
      id, token, groups, groupsOrder, shared, { override: true, skipPrompt: true }, profile,
    );
  };

  render() {
    return (
      <Container
        name={this.props.data.name}
        onCancel={this.hide}
        onSubmit={this.handleSubmit}
        t={this.props.t}
        dashboardName={this.props.data.name}
        externalDashboard={this.props.externalDashboard}
        sharedDashboard={this.props.sharedDashboard}        
        selectedDashboardCounter={this.props.selectedDashboardCounter}
        deleteProcessing={this.state.deleteProcessing}
        currentCounter={this.props.currentCounter}
        />
    );
  }
}

const mapStateToProps = state => {
  const data = state.ui.modalData || {};
  return {
    data,
    externalDashboard: state.ui.dashboards[state.ui.modalData.id] && 'externalDashboard' in state.ui.dashboards[state.ui.modalData.id] && state.ui.dashboards[state.ui.modalData.id].externalDashboard,
    sharedDashboard: state.ui.dashboards[state.ui.modalData.id] && 'shared' in state.ui.dashboards[state.ui.modalData.id] && state.ui.dashboards[state.ui.modalData.id].shared &&
    'usersSharedWith' in state.ui.dashboards[state.ui.modalData.id] && state.ui.dashboards[state.ui.modalData.id].usersSharedWith.length > 0,
    deleteProcessing: state.ui.deleteProcessing,
    currentDashboard: state.ui.currentDashboard,
    dashboardData: state.ui.dashboards[state.ui.modalData.id],
    token: state.session.accessToken,
    currentCredentials: Object.values(state.credentials.data)
      .filter(cred => cred.dashboardSpaceId == state.ui.modalData.id),
    currentCounter: Object.keys(state.credentials.data).filter(itemKey => {
      if ('dashboardSpaceId' in state.credentials.data[itemKey]) {
        const tmpID = state.credentials.data[itemKey].dashboardSpaceId;
        const resID = data.id;
        return tmpID === resID;
      }
      return false;
    }).length,
  };
};

const mapDispatchToProps = dispatch => ({
  deleteCredentials: args => dispatch(deleteCredentials(args)),
  deleteGroup: args => dispatch(deleteGroup(args)),
  showModal: (name, data) => dispatch(showModal(name, data)),
  hideModal: () => dispatch(hideModal()),
  setEditAccountProccessing: status => dispatch(RxEditAccountProccessing(status)),
  removeSharedByAccounts: params => dispatch(removeSharedByAccounts(params)),
  dashboardRemove: id => dispatch(RXDashboardRemove(id)),
  refreshSearch: () => dispatch(refreshSearch()),
  changeDashboard: dashboardId => dispatch(RXChangeActiveDashboard(dashboardId)),
  authenticate: (...args) => dispatch(authenticateUserData(...args)),
  deleteDashboard: id => {
    dispatch(RXDashboardDecline(id));
  },
});

export default translate()(connect(
  mapStateToProps,
  mapDispatchToProps,
)(withOverlayAction(ModalDashboardDeleteComponent)));
