/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import {
  RX_SHARED_ACCOUNTS_CREATE,
  RX_SHARED_ACCOUNTS_DELETE,
  RX_SHARED_ACCOUNTS_REMOVE,
  RX_SHARED_ACCOUNTS_REMOVE_BULK,
} from './actionTypes';


/**
 * @param {array} credentials
 */
export const RXSharedAccountsCreate = (id, sharedAccounts) => ({
  type: RX_SHARED_ACCOUNTS_CREATE,
  id,
  sharedAccounts,
});


/**
 * @param {string} id
 * @param {object} credential
 */
export const RXSharedAccountsDelete = params => ({
  type: RX_SHARED_ACCOUNTS_DELETE,
  credential: params.credential,
  id: params.unshareWithUserIds,
  credentialId: params.credentialId,
});

export const RXSRemoveSharedAccounts = credentialId => ({
  type: RX_SHARED_ACCOUNTS_REMOVE,
  credentialId,
});

export const RXSharedAccountsRemoveBulk = data => ({
  type: RX_SHARED_ACCOUNTS_REMOVE_BULK,
  data,
});
