/* eslint-disable import/prefer-default-export */
export const getTrackingStatus = () => (_, getState) => getState().preferences.tracking;
export const getsharedCRedstatus = ids => (_, getState) => {
  let isSharedby = false;
  Object.keys(getState().credentials.data).forEach(key => {
    if (getState().credentials.data[key].id === ids[0]) {
      if (getState().credentials.data[key].externalAccount === true) {
        isSharedby = true;
      }
    }
  });
  return isSharedby;
};
