import React from 'react';
import Tooltip from 'Tooltip';
import questionmark from '../../assets/images/questionmark.png';

const QuestionMarkIcon = props => (
  <Tooltip delay={0} fontIncrease={true} placement={props.placement} trigger={['hover']} mouseEnterDelay={0} mouseLeaveDelay={0}
  content={props.content}>
  <img
    src={questionmark}
    style={{
      position: props.position,
      right: props.rightPosition,
      bottom: props.bottomPosition,
      paddingLeft: props.paddingLeftPosition,
      paddingRight: props.paddingRightPosition,
      paddingBottom: props.paddingBottomPosition,
      cursor: 'pointer',
      width: '24px',
      height: '24px',
    }}
    onMouseOver="this.style.borderColor='#28283F'"
  />
  </Tooltip>
);

export default QuestionMarkIcon;
