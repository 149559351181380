/*
 * Copyright (C) 2018-2019 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { MODAL_ACCOUNT_EDIT, MODAL_CHANGE_PASSWORD } from 'constants/modal';
import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { hideModal, showModal } from 'main/modal';
import { history } from 'reducers/store';
import withOverlayAction from 'Overlay/withAction';
import { deleteCredential, updateCredential } from 'main/vault/credentials';
import { getOneCredential } from 'main/vault/credentials/reduxState';
import { encryptCredential } from 'main/user';
import { stopSharingAccount } from 'main/sharedAccounts';
import Container from './container';
import { RXSharedAccountsRemoveBulk } from '../../../../main/sharedAccounts/reduxActions';
import { RXUpdateUsersShareWith } from '../../../../main/vault/credentials/reduxActions';


class ModalStopSharing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      acceptProcessing: false,
      cancelProcessing: false,
    };
    this.onClickAccept = this.onClickAccept.bind(this);
    this.onClickCancel = this.onClickCancel.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }

  componentDidMount() {
    this.props.addOverlayAction('modal', this.hideModal);
  }

  hideModal() {
    history.replace({
      hash: '',
      search: '',
    });
    this.props.hide();
  }

  async onClickAccept() {
    this.setState({
      acceptProcessing: true,
    });
    const {
      data,
      sharedAccounts,
    } = this.props;
    let shared = false;
    if (sharedAccounts[data.id].length > 1) {
      shared = true;
    }
    else {
      shared = false;
    }
    const credential = await encryptCredential({
      ...data,
      shared,
    });
    const params = {
      credential: credential.encrypted[0],
      unshareWithUserIds: [this.props.data.unshareWithUserIds],
    };
    try {
      const res = await this.props.stopSharingAccount(params);
      this.setState({
        acceptProcessing: true,
      });
      if (data.isSharedWithAccepted && data.accepted && data.ishidepopup) {
        this.props.showModal(MODAL_CHANGE_PASSWORD, {
          ...this.props.data,
          shared: false,
        });
      }
      else {
        this.props.stopSharingSharedAccounts({
          ids: [data.id],
          userId: this.props.data.unshareWithUserIds,
        });
        this.props.updateUsersSharedWith({
          ids: [data.id],
          userId: this.props.data.unshareWithUserIds,
        });
        this.props.hide();
      }
    }
    catch (error) {
      console.log(error);
    }
  }

  onClickCancel() {
    this.props.showModal(MODAL_ACCOUNT_EDIT, this.props.data);
  }

  render() {
    return (
      <Container
        domain={this.props.data.domain}
        loginUrl={this.props.data.loginUrl}
        name={this.props.data.name}
        picture={this.props.data.picture}
        sharedWithName={this.props.data.sharedWithName}
        accepted={this.props.data.accepted}
        onClickAccept={this.onClickAccept}
        onClickCancel={this.onClickCancel}
        acceptProcessing={this.state.acceptProcessing}
        cancelProcessing={this.state.cancelProcessing}
        t={this.props.t}/>
    );
  }
}

const mapStateToProps = state => ({
  credentials: state.credentials.data,
  data: state.ui.modalData || {},
  groups: state.groups.data,
  unsortedId: state.groups.unsortedGroupId,
  sharedAccounts: state.sharedAccounts,
});

const mapDispatchToProps = dispatch => ({
  updateCredential: args => dispatch(updateCredential(args)),
  deleteCredential: args => dispatch(deleteCredential(args)),
  getCredential: id => dispatch(getOneCredential(id)),
  hide: () => dispatch(hideModal()),
  showModal: (name, data) => dispatch(showModal(name, data)),
  stopSharingAccount: params => dispatch(stopSharingAccount(params)),
  stopSharingSharedAccounts: params => dispatch(RXSharedAccountsRemoveBulk(params)),
  updateUsersSharedWith: params => dispatch(RXUpdateUsersShareWith(params)),
});

export default translate()(connect(
  mapStateToProps,
  mapDispatchToProps,
)(withOverlayAction(ModalStopSharing)));
