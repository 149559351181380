/*
 * Copyright (C) 2018-2019 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import Helmet from 'react-helmet';
import { enableGoogleAnalytics, setupGoogleAnalytics } from 'main/analytics/google';
import { getCurrentLng } from 'config/i18n';
import { parseUrlQueryString } from 'main/router';

class HelmetComponent extends React.Component {
  constructor(props) {
    super(props);
    const { t, title, history: { location } } = props;
    const queryParams = parseUrlQueryString(location.search);
    this.state = {
      description: props.description || t('__manifest__.description'),
      image: props.image || t('__manifest__.icon'),
      locale: props.locale || getCurrentLng(),
      ogType: props.ogType || 'website',
      ogUrl: props.url || window.location.href,
      title: title ? `${title} - ${t('__manifest__.app')}` : t('__manifest__.title'),
      twitterSite: props.twitterSite || t('__manifest__.social.twitter'),
      twitterCard: props.twitterCard || 'summary',
      isOpen: !!queryParams.help || !!queryParams.app,
    };

    this.onConsent = this.onConsent.bind(this);

    window._iub = window._iub || [];
    window._iub.csConfiguration = {
      ccpaApplies: true,
      consentOnContinuedBrowsing: false,
      enableCcpa: true,
      invalidateConsentWithoutLog: true,
      lang: 'en',
      perPurposeConsent: true,
      siteId: 1893623,
      whitelabel: false,
      cookiePolicyId: 12612308,
      cookiePolicyUrl: 'https://nettoken.io/legal/cookies',
      privacyPolicyUrl: 'https://nettoken.io/legal/privacy-policy',
      banner: {
        acceptButtonColor: '#28283F',
        acceptButtonDisplay: 'true',
        backgroundColor: '#FFFFFF',
        closeButtonDisplay: false,
        customizeButtonColor: '#28283F',
        customizeButtonDisplay: true,
        explicitWithdrawal: true,
        listPurposes: true,
        logo: null,
        position: 'bottom',
        rejectButtonColor: '#28283F',
        rejectButtonDisplay: true,
        textColor: '#28283F',
        acceptButtonCaption: '',
        content: 'While we keep all your data private to you when you register to Nettoken, we use cookies for the functioning of the platform, to provide social media features and to analyse our website traffic. You consent to our cookies if you continue to use our website.',
        customizeButtonCaption: 'Learn More',
      },
      callback: {
        onConsentRead: this.onConsent,
      },
    };
  }

  onConsent() {
    enableGoogleAnalytics();
    setupGoogleAnalytics();
  }

  render() {
    const { t } = this.props;
    return (
      <Fragment>
        <Helmet
          title={this.state.title}
          defer={false}
          meta={[
            { name: 'description', content: this.state.description },
            { property: 'og:description', content: this.state.description },
            { property: 'og:image', content: this.state.image },
            { property: 'og:locale', content: this.state.locale },
            { property: 'og:site_name', content: t('__manifest__.app') },
            { property: 'og:title', content: this.state.title },
            { property: 'og:type', content: this.state.ogType },
            { property: 'og:url', content: this.state.ogUrl },
            { name: 'twitter:card', content: this.state.twitterCard },
            { name: 'twitter:site', content: `@${this.state.twitterSite}` },
            { name: 'twitter:title', content: this.state.title },
            { name: 'twitter:description', content: this.state.description },
            { name: 'twitter:image', content: this.state.image },
          ]}
        />
        {!this.state.isOpen &&
          <Helmet>
            <script type="text/javascript" src="//cdn.iubenda.com/cs/iubenda_cs.js" charset="UTF-8" async></script>
          </Helmet>
        }
      </Fragment>
    );
  }
}

HelmetComponent.propTypes = {
  description: PropTypes.string,
  image: PropTypes.string,
  locale: PropTypes.string,
  ogType: PropTypes.string,
  title: PropTypes.string,
  twitterSite: PropTypes.string,
  twitterCard: PropTypes.string,
  url: PropTypes.string,
};

export default translate()(withRouter(HelmetComponent));
