/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { RX_PREFS_FREQUENCY, RX_PREFS_SESSION_TIMEOUT, RX_PREFS_SET } from './actionTypes';

/**
 * @param {string} key
 * @param {integer} value
 */
export const RXPrefsFrequency = (key, value) => ({
  type: RX_PREFS_FREQUENCY,
  key,
  value,
});

/**
 * @param {string} key
 * @param {any} value
 */
export const RXPrefsSet = (key, value) => ({
  type: RX_PREFS_SET,
  key,
  value,
});

/**
 * @param {string} accessToken
 */
// TODO: Rename to something like updatedAccessToken as it can
// be reused in a more generic context, possibly move to session
export const RXPrefsSessionTimeout = accessToken => ({
  type: RX_PREFS_SESSION_TIMEOUT,
  accessToken,
});
