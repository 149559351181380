/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import {
  RX_MODAL_ANIMATION_ENTER,
  RX_MODAL_ANIMATION_LEAVE,
  RX_MODAL_DATA,
  RX_MODAL_FOCUS_POP,
  RX_MODAL_FOCUS_PUSH,
  RX_MODAL_TEMPLATE,
  RX_TOASTER_SHOW,
  RX_DASHBOARD_ADD,
  RX_EDIT_ACCOUNT_PROCCESSING,
  RX_DASHBOARDS_CREATE,
  RX_DASHBOARDS_CREATE_BULK,
  RX_DASHBOARD_ACCEPT,
  RX_DASHBOARD_DECLINE,
  RX_DASHBOARD_UPDATE,
  RX_OVRELAYLOADING,
} from './actionTypes';

export const RXModalAnimationEnter = () => ({
  type: RX_MODAL_ANIMATION_ENTER,
});

export const RXModalAnimationLeave = () => ({
  type: RX_MODAL_ANIMATION_LEAVE,
});

/**
 * @param {object} [data=null]
 */
export const RXModalData = (data = null) => ({
  type: RX_MODAL_DATA,
  data,
});

export const RXModalFocusPop = () => ({
  type: RX_MODAL_FOCUS_POP,
});

/**
 * @param {HTMLElement} [node=document.activeElement]
 */
export const RXModalFocusPush = (node = document.activeElement) => ({
  type: RX_MODAL_FOCUS_PUSH,
  node,
});

/**
 * @param {string} [template='']
 */
export const RXModalTemplate = (template = '') => ({
  type: RX_MODAL_TEMPLATE,
  template,
});

export const RXToasterShow = (toasterData = {}) => ({
  type: RX_TOASTER_SHOW,
  toasterData,
});

export const RXDashboardsCreateBulk = dashboards => ({
  type: RX_DASHBOARDS_CREATE_BULK,
  dashboards,
});

export const RXDashboardsCreate = dashboards => ({
  type: RX_DASHBOARDS_CREATE,
  dashboards,
});

export const RXDashboardAdd = dashboardName => ({
  type: RX_DASHBOARD_ADD,
  dashboardName,
});

export const RxEditAccountProccessing = status => ({
  type: RX_EDIT_ACCOUNT_PROCCESSING,
  status,
});

export const RXDashboardAccept = (id, dashboard) => ({
  type: RX_DASHBOARD_ACCEPT,
  payload: {
    id,
    dashboard,
  },
});

export const RXDashboardDecline = id => ({
  type: RX_DASHBOARD_DECLINE,
  payload: {
    id,
  },
});

export const RXDashboardUpdate = (id, dashboardName) => ({
  type: RX_DASHBOARD_UPDATE,
  dashboardName,
  id,
});

export const RXOverLoading = overlayLoading => ({
  type: RX_OVRELAYLOADING,
  overlayLoading,
});
