/*
 * Copyright (C) 2018-2019 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { i18nRenderLines, noop } from '@nettoken/helpers';
import ActionBar from 'Modal/ActionBar';
import Input from 'Input';
import AppsPreview from 'AppsPreview';
import Tabs from 'Modal/tabs';
import Styles from 'Modal/style.css';
import { Button } from '@nettoken/components';
import downloadfile from 'assets/file/Nettoken template.csv';
import sharedToMeIcon from 'icons/downwardarrow.svg';
import Avatar from 'Avatar';
import cross from '../../../../assets/images/cross.svg';
import CancelIcon from '../../../ActionIcons/CancelIcon';


class ModalChangePasswordContainer extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <h1
          aria-label={t('modal.accountAdd.title')}
          className={Styles.title}>
          Change password
          <CancelIcon onCancel={this.props.onClickDecline} />
        </h1>


        <div className={classNames({
          [Styles.SharingBywrapper]: true,
          [Styles.changePassword]: true,
        })}>
          <div className={classNames({
            [Styles.iconHeaderWrapper]: true,
            [Styles.noBg]: true,
          })}>

            {!this.props.accepted &&
              <img
                src={sharedToMeIcon}
                alt=''
                className={Styles.sharedIcon}
              />
            }
            <Avatar
              className={Styles.iconHeaderWrapper}
              domain={this.props.domain}
              loginUrl={this.props.loginUrl}
              name={this.props.name}
              picture={this.props.picture}
            />
          </div>

          <span className={classNames({
            [Styles.label]: true,
            [Styles.labelText]: true,
          })}>
            {this.props.name}
          </span>
          <p>
            {`Change your password now that you have stopped sharing with ${this.props.sharedWithName}`}
          </p>
          <p>
            Take action and keep your nettoken updated
          </p>

          <div className={classNames({
            [Styles.button]: true,
            [Styles.buttonWrapper]: true,
          })}>
            <a
              href={this.props.loginUrl}
              target='_blank'
              rel='noopener noreferrer'>
              Go to account and change password
              <i className={`${Styles.arrow} ${Styles.right}`} />
            </a>
            <i className={`${Styles.arrow} ${Styles.right}`} />
          </div>

          <div className={classNames({
            [Styles.actionsFixed]: true,
            [Styles.actions]: true,
          })}>
           <div style={{ marginLeft: '245px' }}>
            <Button
              className={classNames({
                [Styles.button]: true,
                [Styles.btnleft]: true,
              })}
              loaderSize={20}
              loading={this.props.acceptProcessing}
              onClick={this.props.onClickEdit}
              value='Edit account' />
              </div>
          </div>
        </div>

      </React.Fragment>
    );
  }
}


export default ModalChangePasswordContainer;
