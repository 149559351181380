/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { SEARCH2, SEARCH3 } from 'constants/ids';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { List } from 'immutable';
import { Button } from '@nettoken/components';
import AppsPreview from 'AppsPreview';
import Avatar from 'Avatar';
import Counter from 'Counter';

import {
  addEvent,
  eventTarget,
  removeEvent,
  scrolledToBottom,
} from '@nettoken/helpers';

import Tabs from 'Modal/tabs';
import GStyles from 'styles/index.css';
import Styles from 'Modal/style.css';
import cross from '../../../../assets/images/cross.svg';
import leftsvg from '../../../../assets/images/left.svg';
import LeftArrowIcon from '../../../ActionIcons/LeftArrowIcon';
import CancelIcon from '../../../ActionIcons/CancelIcon';
import ClearIcon from '../../../ActionIcons/ClearIcon';

class ModalAccountAddContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      btnDisable: false,
    };
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    if (this.refs.scrollable) {
      addEvent(this.refs.scrollable, 'scroll', this.handleScroll);
    }
  }

  componentWillUnmount() {
    if (this.refs.scrollable) {
      removeEvent(this.refs.scrollable, 'scroll', this.handleScroll);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.refs.scrollable) {
      removeEvent(this.refs.scrollable, 'scroll', this.handleScroll);
      addEvent(this.refs.scrollable, 'scroll', this.handleScroll);
    }
  }

  content() {
    switch (this.props.step) {
      case 'confirm':
        return this.confirm();
      case 'preview':
        return this.preview();
      default:
        return this.confirm();
    }
  }

  handleScroll(event) {
    if (this.props.onScrollToBottom && scrolledToBottom(eventTarget(event))) {
      if (this.props.query != '') {
        this.props.onScrollToBottom(event);
      }
    }
  }

  confirm() {
    const { t } = this.props;

    return (
      <form
        action="/"
        className={`${Styles.whiteBackground} ${Styles.manuallyAddBg}`}
        method="post"
        onSubmit={this.props.onSubmit}>
        <div className={Styles.searchWrapper}>
          <input
            aria-label={t('placeholder.search')}
            className={`${Styles.search} ${Styles.manuallyAddTop}`}
            onChange={this.props.onChangeSearch}
            placeholder={`${t('placeholder.search')}...`}
            role="search"
            type="text"
            id={SEARCH2}
            value={this.props.query} />
          {this.props.query.length > 0 ?
            <ClearIcon onClickClearSearch={this.props.onClickClearSearch} /> :
            null
          }
        </div>
        <div className={Styles.actions2} style={{ padding: '0px' }}></div>

        <ul
          className={`${Styles.list} ${Styles.manuallyAdd}`}
          style={{
            marginBottom: '20px',
          }}
          ref="scrollable">
          {
            this.props.loading ? // eslint-disable-line no-nested-ternary
              <li className={Styles.listLoader}>
                <div className={GStyles.loader} />
              </li> :
              !this.props.accounts.size ?
                <li className={Styles.noResultsMssg}>
                  {
                    this.props.selected === 0 ?
                      <>
                        {t('modal.accountAdd.noResults', { query: this.props.query })}
                        <span onClick={this.props.onClickAdvancedSettings}>
                          Add manually

                        </span>
                      </> :
                      <>
                        {t('modal.accountAdd.noResultsWithoutSelected', { query: this.props.query })}
                      </>
                  }
                </li> : (
                  <>
                    {this.props.accounts.map((app, index) => (
                        <li
                          className={Styles.item}
                          key={app.accountId}>
                          <input
                            checked={this.props.selectedApps.includes(app.accountId)}
                            className={Styles.inputRadioElement}
                            id={app.accountId}
                            onChange={() => this.props.onClickApp && this.props.onClickApp(index)}
                            type="checkbox" />

                          <label
                            className={Styles.itemContent}
                            htmlFor={app.accountId}>
                            <div className={Styles.itemHeader}>
                              <Avatar
                                classNameIcon={Styles.iconListWrapper}
                                domain={app.domain}
                                name={app.name}
                                picture={app.picture}
                              />
                              <span className={`${Styles.labelList} ${Styles._singleLine}`}>
                                {app.name}
                              </span>
                            </div>
                            <div className={Styles.inputRadioBorder} />
                          </label>
                        </li>
                    ))}
                  </>
                )
          }
          {this.props.accounts.size && this.props.query === '' ?
          <li><center><b>Search to find more accounts!</b>
          </center></li> : ''}
        </ul>

        <div className={Styles.addAccountBottom}>
          <div className={classNames({
            [Styles.actions]: true,
            [Styles.accountCounter]: true,
          })}>
            <span>
              <Counter
                noGreyBar={true}
                count={this.props.selected}
                font="sm"
                label={'Accounts'}
                size={32}
              />
            </span>

            <div>
              <Button
                className={classNames({
                  [Styles.button]: true,
                  [Styles.notDisabled]: this.props.canSubmit,
                })}
                disabled={!this.props.canSubmit}
                loaderSize={20}
                loading={this.props.submitting}
                type="submit"
                value={t('global.preview')} />
            </div>
          </div>
        </div>
      </form>
    );
  }

  preview() {
    const { t } = this.props;
    return (
      <form
        className={`${Styles.formEmailScanPreview} ${Styles.stickyMinHeightBackground}`}
        method="post"
        ref="formPreview"
        style={{
          paddingTop: '0px',
        }}
      >
        <div className={Styles.stickyfixedForm2} style={{ backgroundColor: '#f6f6f6' }}>
          <div className={`${Styles.searchWrapper} ${Styles.stickySearchHeader2}`}>
            <input
              aria-label={t('placeholder.search')}
              className={classNames(Styles.search, Styles._preview, Styles.search_select)}
              onChange={this.props.onChangeSearchInPreview}
              placeholder={`${t('placeholder.search')}...`}
              role="search"
              type="text"
              id={SEARCH3}
              value={this.props.query} />
            {this.props.query.length > 0 ?
              <ClearIcon onClickClearSearch={this.props.onClickClearSearchInner} /> : null}
          </div>
          <div className={`${Styles.actions2} ${Styles.stickyActionBar44}`}></div>
          <div className={`${Styles.stickyAppList4}`}>
            <AppsPreview
              apps={this.props.apps}
              isEmailEditable={true}
              onChange={this.props.onChange}
              onClickPasswordToggle={this.props.onClickPasswordToggle}
              onSelectApp={this.props.onSelectApp}
              passwordList={this.props.passwordList}
              selectedApps={this.props.selectedApps}
            />
          </div>
        </div>
        <div className={classNames({
          [Styles.actions]: true,
          [Styles.accountCounter]: true,
          [Styles.actionsFixed]: true,
        })} style={{ maxWidth: '780px', width: '92vw' }}>
          <span>
            <Counter
              noGreyBar={true}
              colour="#fff"
              count={this.props.selectedApps.size}
              font="sm"
              label={'Accounts'}
              size={32}
            />
          </span>

          <div>
            <Button
              className={classNames({
                [Styles.button]: true,
                [Styles.notDisabled]: this.props.canSubmit,
              })}
              style={{ backgroundColor: this.state.btnDisable ? '#f8f8f8 !important' : '#FFF466' }}
              disabled={!this.props.canSubmit || this.props.submitting || this.state.btnDisable}
              loaderSize={20}
              loading={this.props.editAccountProccessing}
              onClick={e => {
                this.props.onSubmit(e);
                return true;
              }}
              type="submit"
              value={t('global.add')} />
          </div>
        </div>


      </form>
    );
  }

  render() {
    const { t, step } = this.props;
    return (
      <React.Fragment>
        <h1
          aria-label={t('modal.accountAdd.title')}
          className={classNames({
            [Styles.title]: true,
            [Styles.selectAccounts]: step === 'preview' ? null : true,
            [Styles.PreviewAccounts]: step === 'preview' ? true : null,
            [Styles.marginAdjust]: step === 'preview' ? null : true,
            [Styles.stickyHeaderList]: step === 'preview' ? true : null,
          })}>
          {step == 'preview' ? '' : <LeftArrowIcon onClickBackHandler={this.props.onClickBackHandler} />}
          {step == 'preview' ? 'Add accounts' : 'Select accounts'}
          <CancelIcon onCancel={this.props.onCancel} />
        </h1>
        <div>
          {this.content()}
        </div>
      </React.Fragment>
    );
  }
}

ModalAccountAddContainer.defaultProps = {
  accounts: new List(),
  loading: true,
  query: '',
  selected: 0,
};

ModalAccountAddContainer.propTypes = {
  accounts: PropTypes.instanceOf(List),
  canSubmit: PropTypes.bool,
  loading: PropTypes.bool,
  onCancel: PropTypes.func,
  onChangeSearch: PropTypes.func,
  onChangeSearchInPreview: PropTypes.func,
  onClickClearSearch: PropTypes.func,
  onClickClearSearchInner: PropTypes.func,
  onClickAdvancedSettings: PropTypes.func,
  onClickApp: PropTypes.func,
  onScrollToBottom: PropTypes.func,
  onSubmit: PropTypes.func,
  query: PropTypes.string,
  selected: PropTypes.number,
  submitting: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

export default ModalAccountAddContainer;
