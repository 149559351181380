/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { CW_DECRYPT_CREDENTIALS, getWorkerPromise } from '@nettoken/crypto-worker';
import { isProduction } from '@nettoken/env';
import { eventTarget } from '@nettoken/helpers';
import { isImmutable, List } from 'immutable';
import XLSX from 'xlsx';
import Papa from 'papaparse';
import i18n from 'config/i18n';
import { prepareImportedAccountsForRender } from 'main/emailScan';
import { credentialsIdTreeToDomainTree, TYPE_CSV, TYPE_JSON } from 'main/import/common';
import Dashlane from 'main/import/handlers/Dashlane';
import Headers from 'main/import/handlers/Headers';
import Nettoken from 'main/import/handlers/Nettoken';
import { getOrCreateAccounts } from 'main/search';
import { SlackRequest } from 'utils/request';

// The order of sources is essential for support checks, do not change it for
// no reason. As we progress further in the checks, they become more generic
// so the probability of a match is higher the further you go. Most specific
// checks should be at lower indices.
const sources = [Headers, Nettoken, Dashlane];

/**
 * Reads the imported file data and makes the best guess about which archive
 * type the user submitted.
 *
 * @param {object} file See `openExportedArchiveFile()` for signature.
 *
 * @returns {object}
 * @property {object} data
 * @property {string|object} name Null if undefined.
 * @property {string|object} version Null if undefined.
 */
const convertImportedFileIntoArchive = async file => {
  let { data } = file;

  if (Nettoken.isSupportedFile(file, true)) {
    try {
      const worker = getWorkerPromise('crypto');
      const credentials = [{ data: data.data }];
      // Use decrypt credentials command as it uses the Master Key.
      const result = await worker({ event: CW_DECRYPT_CREDENTIALS, credentials });
      const [decrypted] = result.decrypted;
      data = JSON.parse(decrypted.data);
      file.data = data;
    }
    catch (e) {
      // If decryption or parsing fails, file might be corrupted or this is
      // not a Nettoken archive. We cannot be sure, so continue and handle
      // any errors later.
    }
  }

  for (let i = 0; i < sources.length; i += 1) {
    const source = sources[i];
    if (source.isSupportedFile(file)) {
      const { name } = source;
      const version = typeof data === 'object' && !Array.isArray(data) ? data.version : null;
      return { data, name, version };
    }
  }

  return { data, name: null, version: null };
};

/**
 * Ensure `processArchive()` returns response in the same format, regardless of
 * what each specific handler might return.
 *
 * @param {object|List} args Response from a handler.
 * @property {List} credentials Found credential objects.
 * @property {List} emptyGroups Found groups with no credentials.
 *
 * @returns {object}
 * @property {List} credentials
 * @property {List} emptyGroups
 * @property {List} loginUrls Array of objects with unique domains. We can plug
 *   this directly into API to get results from global accounts database.
 */
const formatResponse = args => {
  const res = {
    credentials: isImmutable(args) ? args : args.credentials,
    emptyGroups: isImmutable(args.emptyGroups) ? args.emptyGroups : new List(),
    loginUrls: new List(),
  };
  if (!isImmutable(res.credentials)) res.credentials = new List();
  // Filter credentials to include only unique domains.
  res.credentials.forEach(credential => {
    const domain = credential.get('domain');
    if (!res.loginUrls.includes(domain)) {
      res.loginUrls = res.loginUrls.push(domain);
    }
  });
  return res;
};

/**
 * Return a file type from the uploaded file.
 *
 * @param {ibject} file
 *
 * @returns {string|object} File type or null for unsupported files.
 */
const getFileType = file => {
  const parts = file.name.split('.');
  const ext = file.type || parts[parts.length - 1];
  switch (ext.toLowerCase()) {
    case 'application/json':
    case 'json':
      return TYPE_JSON;
    case 'application/vnd.ms-excel':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
    case 'xls':
    case 'xlsx':
    case 'text/csv':
    case 'csv':
      return TYPE_CSV;
    default:
      warnUnsupportedFileType(file.type, parts[parts.length - 1]);
      return null;
  }
};

/**
 * Transform an object of groups into an array of their names.
 *
 * @param {object} groups Object obtained from Redux state.
 *
 * @returns {array} Strings with group names.
 */
const groupsToNamesArray = groups => {
  let names = new List();
  Object.keys(groups).forEach(id => {
    names = names.push(groups[id].label);
  });
  return names.toArray();
};

/**
 * Checks if Nettoken supports importing data from the uploaded file archive.
 *
 * @param {object} archive See `convertImportedFileIntoArchive()` for signature.
 *
 * @returns {boolean}
 */
const isSupportedArchive = archive => {
  for (let i = 0; i < sources.length; i += 1) {
    if (sources[i].name === archive.name) return true;
  }
  return false;
};

/**
 * Open a data archive submitted by the user and detect its type and
 * contents. This information can be then used by parser methods to
 * extract the correct values.
 *
 * @param {object} file File submitted by the user.
 *
 * @returns {object}
 * @property {string|object} data JSON object or a string of file contents.
 * @property {string} type Detected file type.
 */
const openExportedArchiveFile = file => new Promise(resolve => {
  const reader = new FileReader();
  const rABS = !!reader.readAsBinaryString;
  const type = getFileType(file);
  let range = 0;
  reader.onload = event => {
    const rawContent = eventTarget(event).result;

    try {
      if (type === TYPE_CSV) {
        let rawContentArr = rawContent.split('\n');
        const finalData = [];
        const __Headers = [
          'note',
          'password',
          'url',
          'email',
        ];
        if (rawContentArr[0].includes('Welcome to 1Password!')) {
          rawContentArr.splice(0, 1);
          rawContentArr.map(cont => {
            const tmpCont = cont.replaceAll('^\"|\"$', '').split(',');
            tmpCont.splice(0, 1);
            if (tmpCont[3] === '"Login"') {
              const tmpData = [
                tmpCont[2],
                tmpCont[4],
                tmpCont[5],
                tmpCont[1],
              ];
              tmpData.join(',');
              finalData.push(tmpData);
            }
          });
          rawContentArr = finalData.join('\n');
        }
        else {
          rawContentArr = rawContent;
        }
        const workbook = XLSX.read(rawContentArr, { type: rABS ? 'binary' : 'array', raw: true });
        const sheetData = workbook.Sheets[workbook.SheetNames[0]];
        if ('A1' in sheetData && sheetData.A1.v.toLowerCase().includes('note')) range = 1;
        const _withHeaders = XLSX.utils.sheet_to_json(sheetData, {
          range,
          defval: '',
          blankrows: true,
        });
        const _headers = Object.keys(_withHeaders[0]).map(x => x.toLowerCase());
        if (!_withHeaders.length || !_headers.includes('password')) {
          const _noHeaders = XLSX.utils.sheet_to_json(sheetData, { range, header: 1 });
          if (_noHeaders.length) {
            return resolve({ data: _noHeaders, type });
          }
        }
        else {
          return resolve({ data: _withHeaders, headers: true, type });
        }
        // const withHeaders = Papa.parse(rawContent, { header: true });
        // console.log(withHeaders);
        // const headers = withHeaders.meta.fields.map(x => x.toLowerCase());
        // if (!withHeaders.data.length || !headers.includes('password')) {
        //   const noHeaders = Papa.parse(rawContent);
        //   if (!noHeaders.errors.length || noHeaders.data.length) {
        //     return resolve({ data: noHeaders.data, type });
        //   }
        // }
        // else {
        //   return resolve({ data: withHeaders.data, headers: true, type });
        // }
      }

      if (type === TYPE_JSON) {
        const data = JSON.parse(rawContent);
        return resolve({ data, type });
      }

      throw new Error('Unknown type');
    }
    catch (e) {
      return resolve({ data: rawContent, type });
    }
  };
  if (rABS) reader.readAsBinaryString(file); else reader.readAsArrayBuffer(file);
  // reader.readAsText(file);
});

/**
 * Read the imported archive and return found importable data. We currently
 * support importing credentials and groups.
 *
 * @param {object} archive See `convertImportedFileIntoArchive()` for signature.
 * @param {object} credentials Object with credentials from Redux state.
 * @param {object} groups Object with groups from Redux state.
 * @param {function} t i18next translate method.
 *
 * @returns {object} Null if the combination is not supported.
 * @property {array} credentials Found credentials objects.
 * @property {array} emptyGroups Found groups with no credentials.
 */
const processArchive = (archive, credentials = {}, groups = {}, t, credentialsDomainMap) => {
  // const credentialsDomainMap = credentialsIdTreeToDomainTree(credentials);
  const groupNames = groupsToNamesArray(groups);
  const args = [archive.data, credentialsDomainMap, groupNames, t, archive.version];
  for (let i = 0; i < sources.length; i += 1) {
    const source = sources[i];
    if (source.name === archive.name) {
      return formatResponse(source.processFile(...args));
    }
  }
  return formatResponse(null);
};

/**
 * @param {string} type
 * @param {string} ext
 */
const warnUnsupportedFileType = (type, ext) => {
  if (isProduction) {
    const text = i18n.t('slack.unsupportedImportFileType.text');
    const attachments = [{
      title: i18n.t('slack.unsupportedImportFileType.attachmentTitleOne'),
      text: type,
    }, {
      title: i18n.t('slack.unsupportedImportFileType.attachmentTitleTwo'),
      text: ext,
    }];
    const channel = i18n.t('slack.unsupportedImportFileType.channel');
    new SlackRequest({ attachments, text }).to(channel).silent('post');
  }
};

/**
 * @param {object} [args={}]
 * @property {object} credentials
 * @property {file} file
 * @property {object} groups
 * @property {function} t
 * @property {string} unsortedId
 *
 * @returns {object}
 * @property {object} [apps]
 * @property {string|object} error This will be the only attribute returned if something goes wrong.
 * @property {object} [groups]
 * @property {array} [groupIds]
 */
export const processFile = async (args = {}) => { // eslint-disable-line import/prefer-default-export, max-len
  const res = {
    error: null,
  };

  const file = await openExportedArchiveFile(args.file);
  if (!file.type) {
    res.error = true;
    return res;
  }

  const archive = await convertImportedFileIntoArchive(file);
  if (!isSupportedArchive(archive)) {
    res.error = true;
    return res;
  }

  try {
    const credentialsDomainMap = credentialsIdTreeToDomainTree(args.credentials);
    const { credentials, emptyGroups, loginUrls } = processArchive(
      archive,
      args.credentials,
      args.groups,
      args.t,
      credentialsDomainMap,
    );
    const credentialSizes = credentials.size;
    if (credentialSizes) {
      for (let i = 0; i < credentialSizes; i += 1) {
        credentials.toArray()[i].setState({ dashboardSpaceId: args.dashboardSpaceId ? args.dashboardSpaceId : '' });
      }
    }
    const results = await getOrCreateAccounts(loginUrls.toArray());
    const { apps, groups, groupIds } = prepareImportedAccountsForRender({
      allowMerge: args.allowMerge,
      credentials,
      emptyGroups,
      results,
      t: args.t,
      unsortedId: args.unsortedId,
      skipDuplicateCredential: true,
      credentialsDomainMap,
    });
    res.apps = apps;
    res.groups = groups;
    res.groupIds = groupIds;
    return res;
  }
  catch (e) {
    res.error = e;
    return res;
  }
};
