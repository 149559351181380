/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { MODAL_ADD_ACCOUNT } from 'constants/routes';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { UserAgentApplication } from 'msal';
import { capitalize, isWindowPopup } from '@nettoken/helpers';
import Routes from 'config/routes';


import {
  getMicrosoftClientId,
  GOOGLE,
  MICROSOFT,
  NAMESPACE,
} from 'main/emailScan';

import { parseUrlQueryString } from 'main/router';

class OAuthView extends React.Component {
  getRedirectUrl(provider) {
    const { hash } = this.props.router.location;
    const params = parseUrlQueryString(hash);
    let url = `${Routes.DASHBOARD}/?${MODAL_ADD_ACCOUNT}=email&from=${provider}`;
    Object.keys(params).forEach(key => {
      url += `&${key}=${params[key]}`;
    });
    return url;
  }

  /*
   * This is because Microsoft does not close redirect from OAuth sign in
   * so we have to close their popup manually. We do this by creating a
   * new instance of the application. 🤣 (No, really, I wish I was kidding.)
   *
   * Source: https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/174
   * Source: https://docs.microsoft.com/en-us/azure/active-directory/develop/tutorial-v2-javascript-spa#use-the-microsoft-authentication-library-msal-to-sign-in-the-user
   */
  msBugFix() {
    const userAgentApplication = new UserAgentApplication(getMicrosoftClientId(), null, null);
    if (isWindowPopup()) {
      return null;
    }
    if (userAgentApplication.isCallback(window.location.hash) && window.parent !== window) {
      return null;
    }
    return <Redirect to={this.getRedirectUrl(MICROSOFT)} />;
  }

  renderRedirect() {
    const { pathname } = this.props.router.location;
    const provider = capitalize(pathname.substr(NAMESPACE.length));
    switch (provider) {
      case GOOGLE:
        return <Redirect to={this.getRedirectUrl(provider)} />;
      case MICROSOFT:
        return this.msBugFix();
      default:
        return <Redirect to={Routes.AUTH} />;
    }
  }

  render() {
    return this.renderRedirect();
  }
}

const mapStateToProps = state => ({
  router: state.router,
});

export default connect(
  mapStateToProps,
  null,
)(OAuthView);
