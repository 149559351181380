/*
 * Copyright (C) 2024 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */

/**
 * {@link src/styles/@core/shared.scss}
 *
 * All time units converted to milliseconds.
 */
/* eslint-disable */
export default {
  groupItemsPerRow: 4,
  viewGroupsPerRow: 3,
  sGroupScrollbar: 5,
  sMoveScale: 1.25,
  swSidebar: 270,
  swSidebarWide: 680,
  trCounter: 2000,
  trMove: 400,
  trMoveFast: 50,
  trMoveDelay: 200
};
/* eslint-enable */
