/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { PRESET_LOG_NETWORK, PRESET_THROTTLE_NETWORK } from 'constants/presets';
import { URL_CREDENTIALS } from 'constants/endpoints';
import queryString from 'query-string';
import { JWTDecode } from '@nettoken/helpers';
import { WEL_REQ } from '@nettoken/web-extension-link';
import { signOutUser } from 'main/user';
import { isUserAuthenticated } from 'main/user/reduxState';
import { isExtensionConnected, postExtensionMessage } from 'utils/extension';
import fetch from './fetch';
import { hideModal, showModal } from '../../main/modal';
import { MODAL_OFFLINE_MSG } from '../../constants/modal';
import { RxEditAccountProccessing, RXToasterShow } from '../../main/modal/reduxActions';
import { RXEditApiError } from '../../main/ui/reduxActions';

let dispatch;
let getState;
let t;

const expireUserSession = () => dispatch(signOutUser({ expired: true, pushToServer: false }));

/**
 * Setup required variables for the request object, so we do not have to
 * pass them with each request instead.
 *
 * @param {object} store Redux store.
 * @param {function} i18nt
 */
export const configure = (store, i18nt) => {
  ({ dispatch, getState } = store);
  t = i18nt;
};

class Request {
  constructor(url) {
    if (typeof getState !== 'function') {
      throw new Error(this._getErrorMessage('error.fetch.args'));
    }

    this.head = {};
    this.method = null;
    this.params = null;
    this.url = url;

    this._loggerMask = ''; // Overrides method and url in logger.
    this._parser = 'json';
    this._perf = {
      end: 0,
      start: 0,
    };
    this._queryParams = {};
    this._urlParams = {};
    this._urlRaw = url;

    this._sendDefault = this._sendDefault.bind(this);
    this._sendWithExtension = this._sendWithExtension.bind(this);
  }

  // We can overwrite the default value.
  authorise(accessToken) {
    if (!accessToken) accessToken = this._getAccessToken();

    if (accessToken) {
      this.head.Authorization = `Bearer ${accessToken}`;
    }
    else {
      console.log(this._getErrorMessage('error.fetch.authorise'));
    }

    return this;
  }

  delete(params) {
    return this._sendWithParams('DELETE', params);
  }

  get(params) {
    return this.setQueryParams(params)._sendWithParams('GET');
  }

  headers(headers = {}) {
    this.head = headers;
    return this;
  }

  post(params) {
    return this._sendWithParams('POST', params);
  }

  put(params) {
    return this._sendWithParams('PUT', params);
  }

  send(internal = this._isNettokenURL()) {
    const handler = this._handler(internal);
    const { throttle } = this._presets();
    return new Promise((resolve, reject) => setTimeout(() => handler(internal)
      .then(res => {
        const { ui } = getState();
        if (ui.editAccountProccessing && ui.toaster.status === 'close') {
          if (this.url.includes('v2/credentials')) {
            dispatch(RXToasterShow({
              status: 'open',
              type: 'success',
              value: 'Changes saved!',
            }));
            if (ui.modalTemplate !== 'account-add' || ui.modalTemplate !== 'remove-account' || ui.modalTemplate === 'memo-add') {
              dispatch(RxEditAccountProccessing(false));
            }
          }
        }
        return resolve(res);
      })
      .catch(res => {
        const { ui } = getState();
        if (ui.editAccountProccessing && (ui.toaster.status === 'close' || ui.toaster.type === 'success')) {
          if (this.url.includes('v2/credentials')) {
            dispatch(RXToasterShow({
              status: 'open',
              type: 'error',
              value: 'Oops, could not save! Please try again',
            }));
            dispatch(RXEditApiError(true));
          }
        }
        return reject(res);
      }), throttle));
  }

  setQueryParams(params = {}) {
    this._queryParams = params;
    return this;
  }

  /**
   * Replace url parameters with the passed values.
   *
   * @example Calling this function on `example.com/user/:id` with values
   *
   *   values = {
   *     id: '123456'
   *   }
   *
   * Will produce `example.com/user/123456`.
   *
   * @param {object} params Map of key pair attributes to substitute.
   */
  setUrlParams(params = {}) {
    const delimiter = ':';
    const [ignored, ...parts] = this.url.split(delimiter);
    let replaced = ignored;

    parts.forEach(key => {
      const slash = key.indexOf('/');
      let tail = '';

      if (slash !== -1) {
        tail = key.substr(slash);
        key = key.substr(0, slash);
      }

      const value = params[key] !== undefined ? params[key] : (delimiter + key);
      replaced += value + tail;
    });

    this.url = replaced;
    this._urlParams = params;

    return this;
  }

  // Quietly handle any errors, discard successful response.
  silent(method, params) {
    this._sendWithParams(method.toUpperCase(), params).catch(() => {});
  }

  _addParams(params = {}) {
    const prevState = this.params;
    const nextState = params;
    this.params = prevState ? { ...prevState, ...nextState } : nextState;
  }

  _appendQuery(str) {
    const hasQueryString = this.url.includes('?');
    this.url += (hasQueryString ? '&' : '?') + str;
  }

  _errIncorrectParam(status) {
    return status >= 400 && status < 500;
  }

  _getAccessToken() {
    const session = getState().session || {};
    return session.accessToken;
  }

  _getErrorMessage(key) {
    return t ? t(key) : '';
  }

  _getPerformance() {
    return `${this._perf.end - this._perf.start}ms`;
  }

  _handler(internal) {
    return internal && isExtensionConnected() ? this._sendWithExtension : this._sendDefault;
  }

  _isExpiredAccessToken(authorizationHeader) {
    const [, accessToken] = authorizationHeader.split(' ');
    const jwt = JWTDecode(accessToken) || {};
    const infiniteExpiryDate = 0;
    const expiryDate = (jwt.exp || infiniteExpiryDate) * 1000;
    if (expiryDate === infiniteExpiryDate) return false;
    const expired = expiryDate - Date.now() < 0;
    return expired;
  }

  _isNettokenURL() {
    return !this.url.includes('://');
  }

  _isSuccess(status) {
    return status >= 200 && status < 300;
  }

  _loggerHeaders() {
    return this._loggerMask || `${this.method} ${this.url}`;
  }

  _presets() {
    const tools = getState().tools || {};
    const presets = tools.presets || {};
    const logger = presets[PRESET_LOG_NETWORK];
    const throttle = presets[PRESET_THROTTLE_NETWORK];
    return { logger, throttle };
  }

  _sendDefault(internal) {
    if (internal && this._shouldSignOut()) {
      window.location.reload();
      expireUserSession();
      return Promise.reject(this._getErrorMessage('error.fetch.sessionExpired'));
    }

    const { logger } = this._presets();

    const options = {
      headers: this.head,
      method: this.method,
    };

    if (['DELETE', 'POST', 'PUT'].includes(this.method) && this.params) {
      // Automatically attach content-type header to Nettoken API requests.
      if (internal) this._setContentType('json');

      options.body = JSON.stringify(this.params);
    }

    // Create URL query string.
    const query = queryString.stringify(this._queryParams);
    if (query) this._appendQuery(query);

    if (logger) {
      this._perf.start = Date.now();
      console.log('☁️', this._loggerHeaders(), this.params || '');
    }

    return fetch(this.url, options, getState)
      .then(res => {
        if (logger) this._perf.end = Date.now();
        return res[this._parser]();
      })
      .then(data => {
        if (internal) {
          const { meta } = data;
          if (!meta || !this._isSuccess(meta.statusCode)) {
            return Promise.reject(data);
          }
        }

        if (logger) console.log('👌', this._loggerHeaders(), data, this._getPerformance());
        return Promise.resolve(internal ? data.data : data);
      })
      .catch(e => {
        let res = e;
        if (internal) {
          const authenticated = dispatch(isUserAuthenticated());
          if (authenticated && typeof res === 'object' && res.meta) {
            const { meta } = res;

            if (typeof meta === 'object' && [401, 403].includes(meta.statusCode)) {
              expireUserSession();
              res = this._getErrorMessage('error.fetch.sessionExpired');
            }
          }
          else {
            res = this._getErrorMessage('error.fetch.unknown');
          }
        }

        if (logger) console.log('🚨', this._loggerHeaders(), res);
        return Promise.reject(res);
      });
  }

  _sendWithParams(method, params) {
    if (params) this.params = params;
    this.method = method;
    return this.send();
  }

  async _sendWithExtension() {
    const req = {
      head: this.head,
      method: this.method,
      params: this.params,
      queryParams: this._queryParams,
      url: this._urlRaw,
      urlParams: this._urlParams,
    };

    if (this._shouldSignOut()) {
      window.location.reload();
    }
    const { data, error } = await postExtensionMessage({ data: req, event: WEL_REQ });
    if (error) {
      if (error === 'session_expired') {
        expireUserSession();
        return Promise.reject(this._getErrorMessage('error.fetch.sessionExpired'));
      }
      return Promise.reject(error);
    }
    return data;
  }

  _setContentType(type) {
    switch (type) {
      case 'json':
        this.head['Content-Type'] = 'application/json';
        break;

      // no default
    }
  }

  _shouldSignOut() {
    const { Authorization } = this.head;
    return Authorization && this._isExpiredAccessToken(Authorization);
  }
}

export default Request;
