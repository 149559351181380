/*
 * Copyright (C) 2018-2019 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { RX_APP_INIT } from 'main/state/actionTypes';
import { RX_USER_LOG_OUT } from 'main/user/actionTypes';
import Reducer from 'reducers/factory';

import {
  RX_CREDENTIALS_CREATE,
  RX_CREDENTIALS_DELETE, RX_CREDENTIALS_REPLACE_ID,
  RX_CREDENTIALS_UPDATE,
  RX_CREDENTIALS_UPDATE_BATCH,
  RX_CREDENTIALS_UPDATE_FILTERED_LIST,
  RX_SET_NEW_CREDENTIALS,
  RX_SET_UPDATE_USERS_SHARED_WITH,
} from './actionTypes';

const defaultState = () => ({
  byDomain: {},
  data: {},
  filteredData: null,
  setNewCredentials: {},
});

const reducer = new Reducer('credentials', defaultState());

export default (state = reducer.defaultState(), action) => {
  switch (action.type) {
    case RX_APP_INIT:
      return reducer.merge(state);

    case RX_CREDENTIALS_CREATE: {
      const byDomain = { ...state.byDomain };
      const data = action.override ? {} : { ...state.data };
      const setNewCredentials = {};
      action.credentials.forEach(credential => {
        if (
          'dashboardSpaceId' in credential &&
          credential.dashboardSpaceId === ''
        ) {
          credential.dashboardSpaceId = null;
        }
        const { domain, id } = credential;
        if (!byDomain[domain]) byDomain[domain] = [];
        byDomain[domain] = [...byDomain[domain], id];
        data[id] = { ...credential };
        setNewCredentials[id] = { ...credential };
      });
      return {
        ...state,
        byDomain,
        data,
        setNewCredentials,
      };
    }

    case RX_CREDENTIALS_DELETE: {
      const byDomain = { ...state.byDomain };
      const { [action.id]: credential, ...data } = state.data;
      const { domain, id } = credential;
      const index = ((byDomain[domain] != null) ? byDomain[domain].indexOf(id) : -1);
      if (index !== -1) {
        byDomain[domain] = [
          ...byDomain[domain].slice(0, index),
          ...byDomain[domain].slice(index + 1),
        ];
      }
      if (byDomain[domain] != null && !byDomain[domain].length) delete byDomain[domain];
      return {
        ...state,
        byDomain,
        data,
      };
    }

    case RX_CREDENTIALS_UPDATE: {
      // Credential should always have a visible label.
      const prevState = state.data[action.id] || {};
      return {
        ...state,
        data: {
          ...state.data,
          [action.id]: {
            ...prevState,
            ...action.credential,
            dashboardSpaceId: 'dashboardSpaceId' in action.credential && action.credential.dashboardSpaceId === '' ? null : prevState.dashboardSpaceId,
          },
        },
      };
    }

    case RX_CREDENTIALS_REPLACE_ID: {
      // Credential should always have a visible label.
      const prevState = state.data[action.id] || {};
      const prevData = state.data;
      delete prevData[action.id];
      return {
        ...state,
        data: {
          ...prevData,
          [action.newId]: {
            ...prevState,
            id: action.newId,
          },
        },
      };
    }

    case RX_CREDENTIALS_UPDATE_BATCH: {
      const nextState = {};
      action.credentials.forEach(credential => {
        nextState[credential.id] = {
          ...(state.data[credential.id] || {}),
          ...credential,
        };
      });
      return {
        ...state,
        data: {
          ...state.data,
          ...nextState,
        },
      };
    }

    case RX_USER_LOG_OUT:
      return reducer.isPersisted ? state : reducer.defaultState();

    case RX_CREDENTIALS_UPDATE_FILTERED_LIST:
      return {
        ...state,
        filteredData: action.data,
      };

    case RX_SET_NEW_CREDENTIALS:
      return {
        ...state,
        setNewCredentials: action.data,
      };

    case RX_SET_UPDATE_USERS_SHARED_WITH: {
      const nextState = { ...state.data };
      action.data.ids.forEach(id => {
        const sharedUsers = nextState[id].usersSharedWith
          .filter(user => user.id != action.data.userId && user.sharedByDashboard);
        nextState[id] = {
          ...nextState[id],
          shared: sharedUsers.length > 0,
          usersSharedWith: sharedUsers,
        };
      });
      return {
        ...state,
        data: {
          ...nextState,
        },
      };
    }
    default:
      return state;
  }
};
