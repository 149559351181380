import React from 'react';
import icon from '../../assets/images/moveAccountsArrowIcon.svg';
import Styles from '../Modal/style.css';


const RightSideArrow = props => (
      <span
            style={{
              top: '10px',
              position: 'absolute',
              right: '9px',
            }}
            className={Styles.circleParent}
      >
        <span className={`${Styles.circle} ${Styles.moveAccount}`}>
          <img src={icon} className={Styles.iconPlus}
              style={{
                cursor: 'pointer',
              }}
          />
        </span>
      </span>
);

export default RightSideArrow;
