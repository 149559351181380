export const EVENT_ACCOUNT_ACCESSED = 'accountAccessed';
export const EVENT_CREDENTIAL_CREATED = 'credentialCreated';
export const EVENT_CREDENTIAL_UPDATED = 'credentialUpdated';
export const EVENT_EMAIL_UPDATED = 'emailUpdated';
export const EVENT_PASSWORD_UPDATED = 'passwordUpdated';
export const EVENT_USERNAME_UPDATED = 'usernameUpdated';
export const EVENT_GROUP_CHANGED = 'groupChanged';
export const EVENT_DASHBOARD_CHANGED = 'dashboardChanged';
export const EVENT_UPWARDARROWACCOUNT_SHARED = 'sharedByContact';
export const EVENT_DASHBOARD_SHARED = 'sharedCredentialByContact';
export const EVENT_UPWARDARROWACCOUNT_STOPSHARED = 'sharedRemovedAccess';
export const EVENT_UPWARDARROWACCOUNT_INVITEACCEPTED = 'sharedInviteAccepted';
export const EVENT_UPWARDARROWACCOUNT_INVITEREJECTED = 'sharedInviteRejected';

// Data sources
export const SOURCE_ADVANCED_SETTINGS = 'webAppAdvancedSettings';
export const SOURCE_BRAVE = 'braveExtension';
export const SOURCE_CHROME = 'chromeExtension';
export const SOURCE_EDGE = 'edgeExtension';
export const SOURCE_EMAIL_SEARCH = 'emailSearch';
export const SOURCE_FILE_UPLOAD = 'fileUpload';
export const SOURCE_MANUAL_SELECTION = 'webAppManualSelection';
export const SOURCE_WEB_APP = 'webApp';
export const SOURCE_IOS_APP = 'iOSApp';
