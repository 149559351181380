/*
 * Copyright (C) 2018-2019 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { MODAL_PROMPT_BACKUP } from 'constants/modal';
import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import Routes from 'config/routes';
import { backupMasterKey } from 'main/backup';
import { goTo } from 'main/router';
import withOverlayAction from 'Overlay/withAction';
import { showModal } from 'main/modal';
import Container from './container';

class SidebarBackupComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
    };

    this.handleClickDownload = this.handleClickDownload.bind(this);
  }

  componentDidMount() {
    this.props.addOverlayAction('sidebar', this.props.goBack);
  }

  async handleClickDownload() {
    const { t } = this.props;
    this.setState({ isLoading: true });

    try {
      await this.props.backupMasterKey(t);
      this.setState({ isLoading: false });
    }
    catch (e) {
      this.setState({ isLoading: false });
      console.log(e);
    }
  }

  render() {
    return (
      <Container
        backup={this.props.states.user.hasMasterKeyBackup}
        loading={this.state.isLoading}
        onCancel={this.props.goBack}
        onClickDownload={this.handleClickDownload}
        onClickExplain={() => this.props.showModal(MODAL_PROMPT_BACKUP)}
        t={this.props.t} />
    );
  }
}

const mapStateToProps = state => ({
  states: state,
});

const mapDispatchToProps = dispatch => ({
  backupMasterKey: t => dispatch(backupMasterKey(t)),
  goBack: () => dispatch(goTo(Routes.SETTINGS)),
  showModal: (name, data) => dispatch(showModal(name, data)),
});

export default translate()(connect(
  mapStateToProps,
  mapDispatchToProps,
)(withOverlayAction(SidebarBackupComponent)));
