/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import {
  EVENT_ACCOUNT_ACCESSED,
  EVENT_CREDENTIAL_CREATED,
  EVENT_UPWARDARROWACCOUNT_SHARED,
  EVENT_UPWARDARROWACCOUNT_STOPSHARED,
  EVENT_UPWARDARROWACCOUNT_INVITEACCEPTED,
  EVENT_UPWARDARROWACCOUNT_INVITEREJECTED,
  EVENT_EMAIL_UPDATED,
  EVENT_GROUP_CHANGED,
  EVENT_DASHBOARD_CHANGED,
  EVENT_PASSWORD_UPDATED,
  EVENT_USERNAME_UPDATED,
  EVENT_CREDENTIAL_UPDATED,
  SOURCE_ADVANCED_SETTINGS,
  SOURCE_EMAIL_SEARCH,
  SOURCE_FILE_UPLOAD,
  SOURCE_MANUAL_SELECTION,
  SOURCE_WEB_APP,
  SOURCE_CHROME,
  SOURCE_BRAVE,
  SOURCE_EDGE,
  SOURCE_IOS_APP,
} from 'constants/events';
import {
  ID_SHOW_SHARED_INFO,
  TEXT_SHOW_SHARED_INFO,
  TEXT_LOG_INFO,
} from 'constants/ids';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { List } from 'immutable';
import { Button } from '@nettoken/components';
import { ensureProtocol, noop } from '@nettoken/helpers';
import { PropIcon } from 'utils/propTypes';
import Avatar from 'Avatar';
import Input from 'Input';
import Switch from 'Switch';
import Tooltip from 'Tooltip';
import iconPlus from 'icons/plus.svg';
import sharedAccountIcon from 'icons/upwardarrow.svg';
import sharedToMeIcon from 'icons/downwardarrow.svg';
import addAccountIcon from 'icons/add-account-icon.png';
import Styles2 from 'Modal/style.css';
import Styles from 'Sidebar/style.css';
import { getIconColorNewmethod } from '../../../../utils/misc';
import cross from '../../../../assets/images/cross.svg';
import leftsvg from '../../../../assets/images/left.svg';
import HistoryIcon from '../../../ActionIcons/HistoryIcon';
import CancelIcon from '../../../ActionIcons/CancelIcon';
import QuestionMarkIcon from '../../../ActionIcons/QuestionMarkIcon';
import LeftArrowIcon from '../../../ActionIcons/LeftArrowIcon';
import SharingBtn from '../../../ActionIcons/SharingBtn';

export const Checkbox = ({
  checked,
  name,
  onChange,
  value,
}) => {
  const input = React.createRef();

  React.useEffect(() => {
    if (checked && input && input.current) {
      input.current.focus();
    }
  }, [input, checked]);

  return (
    <div>
      <input
        ref={input}
        checked={checked}
        className={Styles.radioInput}
        id={name}
        onChange={onChange}
        name={name}
        autoFocus={true}
        // type="radio" would not be focusable with keyboard
        type="checkbox"
        value={value}
      />

      <label className={Styles.radioLabel} htmlFor={name}>
        <div className={Styles.radioBorder} />
      </label>
    </div>
  );
};

const ModalMoveAccountDashboardContainer = ({
  isShared,
  isExternalAccount,
  accepted,
  domain,
  email,
  inoutchange,
  group,
  groupDropdown,
  loginUrl,
  name,
  note,
  onCancel,
  onSubmit,
  password,
  phone,
  picture,
  showLogs,
  showPassword,
  t,
  toggleView,
  username,
  deleteProcessing,
  editAccountProccessing,
  dashboard,
  dashboardDropdown,
  moveApp,
  options,
  onChangeCheckbox,
  currentDashboard,
}) => {
  const renderForm = () => (
    <form
      action="/"
      className={`${Styles2.whiteBackground2} ${Styles2.minHeightBackground}`}
      method="post"
      onSubmit={onSubmit}
    >
      <div className={`${Styles2.fixedForm} ${Styles2.fixedForm2}`}>
        <div className={classNames({
          [Styles2.iconHeaderWrapper]: true,
          [Styles2.noBg]: true,
        })}>
          {isShared &&
            <img
              src={isExternalAccount ? sharedToMeIcon : sharedAccountIcon}
              alt=''
              className={Styles2.sharedIcon}
            />
          }
          {isExternalAccount &&
            <img
              src={sharedToMeIcon}
              alt=''
              className={Styles2.sharedIcon}
            />
          }
          {!accepted &&
            <img
              src={sharedToMeIcon}
              alt=''
              className={Styles2.sharedIcon}
            />
          }
          <Avatar
            className={Styles2.iconHeaderWrapper}
            domain={domain}
            loginUrl={loginUrl}
            name={name}
            picture={picture}
          />
        </div>
        <div className={`${Styles2.form}`}>
          <div className={`${Styles2.content} ${Styles2._scrollable}`}>
            <div className={Styles2.paddingFixer}>
              <p>Select a dashboard to move this account</p>
              <ul>
                {options.map((option, index) => {
                  const color = getIconColorNewmethod(option.label.replaceAll('(home)', ''));
                  return (
                    <li key={index}
                    style={{
                      gridTemplateColumns: '0.25fr 1fr 0.1fr',
                      position: 'relative',
                      display: 'grid',
                      alignItems: 'center',
                      marginBottom: '15px',
                      cursor: 'pointer',
                    }}
                    onClick={() => onChangeCheckbox(option.value)}
                    >
                      <div
                        style={{
                          borderRadius: '50%',
                          height: '50px',
                          width: '50px',
                          textAlign: 'center',
                          verticalAlign: 'middle',
                          lineHeight: '49px',
                          backgroundColor: color,
                        }}
                      >
                        <span style={{ color: 'white' }}>{option.label.slice(0, 2).toUpperCase()}</span>
                        {option.value !== 'HomeDashboard' && option.usersSharedWith && option.usersSharedWith.length >= 1 && <img
                          src={sharedAccountIcon}
                          style ={{ position: 'absolute', top: '-9px', left: '27px' }}
                          alt=''
                          className={Styles2.sharedIcon}
                        />}
                      </div>
                      <h4
                        className={`${Styles.radioTitle11} ${Styles2.fontSizeChange}`}
                        style={{ color: option.value === currentDashboard ? '#28283f' : '#868692', marginBottom: '0px', marginTop: '0px' }}
                      >{option.label.length > 24 ? option.label.substring(0, 24).concat('...') : option.label}
                      {option.value === 'HomeDashboard' ? <div style={{ marginTop: '5px', fontSize: '12px', color: '#868692' }}> Home dashboard</div> : null}
                      {(option.value !== 'HomeDashboard' && option.usersSharedWith && option.usersSharedWith.length >= 1) ?
                      <div style={{ marginTop: '5px', fontSize: '12px', color: '#868692' }}>
                        Shared with {option.usersSharedWith[0].name}
                        {option.usersSharedWith.length > 1 ? ' and ' : null}
                        {option.usersSharedWith.length > 1 ?
                          option.usersSharedWith.length - 1 : null}
                        {option.usersSharedWith.length == 2 ? ' other' : null}
                        {option.usersSharedWith.length > 2 ? ' others' : null}
                      </div> : ''
                      }
                      </h4>
                      <Checkbox
                        checked={currentDashboard === option.value}
                        name={`option-${index}`}
                        onChange={() => onChangeCheckbox(option.value)}
                        value={option.value}
                      />
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className={`${Styles2.actions} ${Styles2.actionsFixed} ${Styles2._squeezedAltered} ${Styles2.fixedButtons}`}
        style={{ justifyContent: 'right' }}
      >
      <div>
        <Button
          className={classNames({
            [Styles2.lastChild]: true,
            [Styles2.button]: true,
            [Styles2.notDisabled]: inoutchange,
            [Styles2.cancelMarginLeft]: true,
          })}
            disabled={!inoutchange}
            type="submit"
            loaderSize={20}
            loading={editAccountProccessing}
            value="Move" />
      </div>
      </div>
    </form>
  );

  return (
    <React.Fragment>
      <h1
        aria-label={'Move Account'}
        className={Styles2.title1}>
        <LeftArrowIcon toggleView={toggleView} />
        Move Account
        <CancelIcon onCancel={onCancel} />
      </h1>
      {renderForm()}
    </React.Fragment>
  );
};

export default ModalMoveAccountDashboardContainer;
