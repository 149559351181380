/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
class FLIP {
  constructor(node, speed) {
    if (!node) throw new Error('You need to pass FLIP a node to operate on.');

    this.node = node;
    this.speed = speed || 0;

    // We don't need to calculate all 4 dimensions as the other
    // values are just inverted 'left' and 'top'.
    this.delta = {
      left: 0,
      top: 0,
    };

    this.state = {
      first: null,
      last: null,
    };

    this.first();
  }

  first() {
    this.state.first = this.getRect();
    return this;
  }

  getDelta() {
    const {
      first,
      last,
    } = this.state;

    const delta = {
      left: first.left - last.left,
      top: first.top - last.top,
    };

    this.delta = {
      ...this.delta,
      ...delta,
    };
  }

  getRect() {
    return this.node.getBoundingClientRect();
  }

  invert() {
    const {
      first,
      last,
    } = this.state;

    const {
      left,
      top,
    } = this.delta;

    const scaleX = (first.width / last.width) || 1;
    const scaleY = (first.height / last.height) || 1;

    this.node.style.transition = 'none';
    this.node.style.transformOrigin = '50% 0%';
    this.node.style.transform = `scaleX(${scaleX}) scaleY(${scaleY}) translateX(${left}px) translateY(${top}px)`;
  }

  last() {
    this.state.last = this.getRect();
    this.getDelta();
    return this;
  }

  play() {
    if (!this.state.last) {
      this.last().invert();
    }

    // Wait for the styles to be applied.
    setTimeout(() => {
      this.node.style.transition = `transform ${this.speed}ms`;
      this.node.style.transform = '';

      // Wait for the animation to finish.
      setTimeout(() => {
        this.node.style.transition = '';
        this.node.style.transformOrigin = '';
        this.delta.left = 0;
        this.delta.top = 0;
        this.state.first = null;
        this.state.last = null;

        if (!this.node.getAttribute('style')) {
          this.node.removeAttribute('style');
        }
      }, this.speed);
    }, 0);
  }
}

export default FLIP;
