/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@nettoken/components';
import classNames from 'classnames';
import { noop } from '@nettoken/helpers';
import Helmet from 'Helmet';
import Styles from 'Sidebar/style.css';

const Checkbox = ({ checked, name, onChange }) => (
  <div className={Styles.radioWrapper}>
    <input
      checked={checked}
      className={Styles.radioInput}
      id={name}
      onChange={onChange}
      name={name}
      // type="radio" would not be focusable with keyboard
      type="checkbox" />

    <label
      className={Styles.radioLabel}
      htmlFor={name}>
      <div className={Styles.radioBorder} />
    </label>
  </div>
);


let chk;
class SidebarDeleteAccountContainer extends React.Component {
  renderFragments(type) {
    const { t } = this.props;
    const isChecked = this.props.checkedOption === type;
    chk = isChecked;
    return (
      <React.Fragment>
        {isChecked && <p className={`${Styles.radioTextInExport} ${Styles._danger}`}>
          {t('views.export.warning')}
        </p>}

        <Checkbox
          checked={isChecked}
          name={type}
          onChange={this.props.onChangeCheckbox} />

        <Button
          className={Styles.radioButton}
          disabled={!isChecked}
          icon={true}
          light={true}
          loading={isChecked && this.props.downloading}
          onClick={this.props.onClickDownload}
          value='Download Data'/>
      </React.Fragment>
    );
  }

  renderFragments1(type) {
    const { t } = this.props;
    let isChecked = this.props.checkedOption === type;
    return (
      <React.Fragment>

        <Checkbox
          checked={isChecked}
          name={type}
          onChange={this.props.onChangeCheckbox} />

         <center>
         <div>
         {chk ? isChecked = true : isChecked}
         <Button
         loading={isChecked && this.props.downloading}
         disabled={!isChecked}
         style={{
           marginTop: '20px',
           color: '#FF965B',
           backgroundColor: '#F8F8F8',
           border: 'none',
         }}
          className={classNames({
            [Styles.disbtn]: !isChecked,
          })} value='Yes, Delete' /></div></center>
      </React.Fragment>
    );
  }

  render() {
    const { t } = this.props;
    return (
      <form
        action="/"
        className={Styles.sidebarFullHeight}
        method="post"
        onSubmit={this.props.onSubmit}>
        <Helmet title={t('views.export.title')} />

        <div className={`${Styles.content} ${Styles._menu} ${Styles._scrollable}`}>
          <div className={Styles.paddingFixer}>
            <h1 className={`${Styles.title} ${Styles._danger}`}>
              {t('views.export.title')}
            </h1>
               <p>
                  Deleting your Nettoken is permanent and irreversable
                  as no reference to your account is left behind.
                </p>
              <ul>
              <li>
                <h4 className={Styles.radioTitle}>
                     Download your data before deleting
                    </h4>
                <ul>
                    <p className={Styles.radioTextInExport}>
                      {t('views.export.encryptedTextDelete')}
                    </p>
                    <br/>
                  <li className={Styles.exportSectionItem}>
                      <span>
                    I agree and understand</span>
                    {this.renderFragments('encrypted')}
                  </li>
                   <h4 className={Styles.radioTitle}>
                      Delete with no data download
                    </h4>
                    <p className={Styles.radioTextInExport}>
                      You will loose all your nettoken data.
                    </p>
                    <br/>
                  <li className={Styles.exportSectionItem}>
                      <span for='html'>
                    I agree and understand</span>
                    <input type="radio" id="html" name="fav_language" value="HTML"></input>
                    {this.renderFragments1('decrypted')}
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>

        <div className={Styles.actions}>
          <Button
            className={Styles.button}
            onClick={this.props.onCancel}
            type="button"
            value={t('global.cancel')} />
        </div>
      </form>
    );
  }
}

SidebarDeleteAccountContainer.defaultProps = {
  checkedOption: '',
  deleting: false,
  downloading: false,
  onCancel: noop,
  onChangeCheckbox: noop,
  onClickDownload: noop,
  onSubmit: noop,
};

SidebarDeleteAccountContainer.propTypes = {
  checkedOption: PropTypes.string,
  deleting: PropTypes.bool,
  downloading: PropTypes.bool,
  onCancel: PropTypes.func,
  onChangeCheckbox: PropTypes.func,
  onClickDownload: PropTypes.func,
  onSubmit: PropTypes.func,
  /** i18n translate method */
  t: PropTypes.func.isRequired,
};

export default SidebarDeleteAccountContainer;
