/*
 * Copyright (C) 2019 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { TUTORIAL_ADD_CREDENTIAL, TUTORIAL_ONBOARD } from 'constants/ids';
import { MODAL_ADD_ACCOUNT } from 'constants/routes';
import React from 'react';
import { List } from 'immutable';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { findNode } from '@nettoken/helpers';
import debounce from 'lodash.debounce';
import { arrayItemSliceOrAppend, eventTarget } from '@nettoken/helpers';
import { hideModal } from 'main/modal';
import { getOrCreateAccounts } from 'main/search';
import { hideTutorial, showTutorial } from 'main/tutorial';
import { addCredentialsEfficiently } from 'main/vault/credentials';
import { history } from 'reducers/store';
import withAsyncState from 'AsyncState';
import withOverlayAction from 'Overlay/withAction';
import Container from './container';

const steps = ['confirm', 'preview'];

class ModalAccountOnboardComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      accounts: new List(),
      canSubmit: false,
      credentials: {},
      loading: true,
      passwordList: {},
      query: '',
      selected: new List(),
      step: this.nextStep(),
      submitting: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSearchInPreview = debounce(this.filterAccounts.bind(this), 200);
    this.hide = this.hide.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.searchInPreview = this.searchInPreview.bind(this);
    this.togglePassword = this.togglePassword.bind(this);
    this.toggleSelected = this.toggleSelected.bind(this);
    this.toggleSelectedApps = this.toggleSelectedApps.bind(this);
  }

  async componentDidMount() {
    this.setCorrectUrl(true);

    setTimeout(() => this.showHint(), 400);

    const urls = this.props.t('modal.accountOnboard.accounts');
    let accounts = new List(await getOrCreateAccounts(urls));
    accounts = accounts.map(account => {
      account.checked = false;
      return account;
    });
    this.setState({ accounts, loading: false });
  }

  componentWillUnmount() {
    this.cleanUp();
  }

  cleanUp() {
    this.setCorrectUrl(false);
    this.props.hideTutorial(TUTORIAL_ONBOARD);
  }

  filterAccounts(query) {
    if (query === '') {
      this.setState({
        apps: { ...this.state.credentials },
      });

      return;
    }

    let { apps } = this.state;
    apps = Object.keys(apps)
      .filter(key => apps[key].name.toLowerCase().startsWith(query.toLowerCase()))
      .reduce((obj, key) => {
        obj[key] = apps[key];
        return obj;
      }, {});

    this.setState({
      apps,
    });
  }

  handleChange(event, id) {
    const { name, value } = eventTarget(event);
    this.setState({
      credentials: {
        ...this.state.credentials,
        [id]: {
          ...this.state.credentials[id],
          [name]: value,
        },
      },
      apps: {
        ...this.state.apps,
        [id]: {
          ...this.state.credentials[id],
          [name]: value,
        },
      },
    });
  }

  searchInPreview(event) {
    const { value: query } = eventTarget(event);
    this.setState({
      query,
    }, () => this.handleSearchInPreview(query));
  }

  hide() {
    this.cleanUp();
    this.props.hide();
  }

  nextStep(current) {
    let index = steps.indexOf(current);
    index += 1;
    if (index > steps.length - 1) index = 0;
    return steps[index];
  }

  onSubmit(event) {
    event.preventDefault();

    if (!this.state.selected.size) return;

    this.setState({ submitting: true });

    const { step } = this.state;

    if (step === 'confirm') {
      const credentials = {};
      const passwordList = {};
      const credentialIds = this.state.selected;
      this.state.accounts
        .filter(x => credentialIds.includes(x.id))
        .forEach(({ checked, groupnames, ...account }) => {
          const credential = {
            ...account,
            email: '',
            password: '',
            accountId: account.id,
            groupId: groupnames,
          };
          credentials[account.id] = credential;
          passwordList[account.id] = false;
        });

      this.props.hideTutorial(TUTORIAL_ONBOARD);
      this.setState({
        apps: { ...credentials },
        credentials,
        passwordList,
        step: this.nextStep(this.state.step),
        submitting: false,
      });
    }
    else if (step === 'preview') {
      const { apps, selected: credentialIds } = this.state;
      this.props.addCredentialsEfficiently({
        credentials: apps,
        credentialIds,
      })
        .then(() => {
          this.setState({ submitting: false });
          this.props.hide();
        })
        .catch(() => this.setState({ submitting: false }));
    }
  }

  setCorrectUrl(mounted) {
    const search = mounted ? `?${MODAL_ADD_ACCOUNT}=onboard` : '';
    history.replace({ hash: '', search });
  }

  async showHint() {
    this.props.hideTutorial(TUTORIAL_ADD_CREDENTIAL);

    const id = 'form';
    // Wait for the component to render.
    await findNode(`#${id}`);
    this.props.showTutorial({
      coords: {
        left: '100%',
        top: '-28px',
      },
      direction: 'x',
      type: 'simple',
      placement: 'start',
      id,
      name: TUTORIAL_ONBOARD,
      zIndex: 999,
    });
  }

  togglePassword(id) {
    this.setState({
      passwordList: {
        ...this.state.passwordList,
        [id]: !this.state.passwordList[id],
      },
    });
  }

  toggleSelected(index) {
    let { accounts, selected } = this.state;
    const account = accounts.get(index);
    const { checked } = account;
    account.checked = !checked;
    accounts = accounts.set(index, account);
    selected = checked ? selected.filter(id => id !== account.id) : selected.push(account.id);
    this.setState({ accounts, canSubmit: selected.size > 0, selected });
  }

  toggleSelectedApps(id) {
    const selected = arrayItemSliceOrAppend(this.state.selected, id);
    this.setState({
      selected,
      canSubmit: selected.size > 0,
    });
  }

  render() {
    return (
      <Container
        accounts={this.state.accounts}
        apps={this.state.apps}
        canSubmit={this.state.canSubmit}
        loading={this.state.loading}
        onCancel={this.hide}
        onChange={this.handleChange}
        onChangeSearchInPreview={this.searchInPreview}
        onClickAccount={this.toggleSelected}
        onClickPasswordToggle={this.togglePassword}
        onSelectApp={this.toggleSelectedApps}
        onSubmit={this.onSubmit}
        passwordList={this.state.passwordList}
        query={this.state.query}
        selectedApps={this.state.selected}
        step={this.state.step}
        submitting={this.state.submitting}
        t={this.props.t} />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  addCredentialsEfficiently: args => dispatch(addCredentialsEfficiently(args)),
  hide: () => dispatch(hideModal()),
  hideTutorial: name => dispatch(hideTutorial(name, true)),
  showTutorial: args => dispatch(showTutorial(args)),
});

export default translate()(connect(
  null,
  mapDispatchToProps,
)(withAsyncState(withOverlayAction(ModalAccountOnboardComponent))));
