import React from 'react';
import Tooltip from 'Tooltip';
import activeHistoryimg from '../../assets/images/activity_history/active.svg';
import hoverHistoryimg from '../../assets/images/activity_history/hover.svg';
import clickHistoryimg from '../../assets/images/activity_history/click.svg';

const HistoryIcon = props => (
  <Tooltip content="Activity History">
    <img
      src={activeHistoryimg}
      onMouseOver={e => (e.currentTarget.src = hoverHistoryimg)}
      onMouseOut={e => (e.currentTarget.src = activeHistoryimg)}
      onMouseUp={e => (e.currentTarget.src = clickHistoryimg)}
      onClick={props.toggleView}
      style={{
        float: 'right',
        cursor: 'pointer',
        position: 'absolute',
        right: '40px',
      }}
    />
  </Tooltip>
);

export default HistoryIcon;
