/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import '@nettoken/sass-core/reset.css';
import {
  PRESET_EXT_LOG,
  PRESET_EXT_RAW,
  PRESET_LOG_NETWORK,
  PRESET_MOCK_SERVER,
} from 'constants/presets';
import React from 'react';
import ReactDOM from 'react-dom';
import { CW_DECRYPT_WITH_EXTERNAL_KEYS, CW_ENCRYPT_WITH_EXTERNAL_KEY, getWorkerPromise } from '@nettoken/crypto-worker';
import { envFromBranch, isProduction } from '@nettoken/env';
import { findNode, removeNoScriptFallbackTag } from '@nettoken/helpers';
import { configureCredential } from '@nettoken/models';
import { configureSocket } from '@nettoken/socket';
import { createWebExtensionLink } from '@nettoken/web-extension-link';
import { loadDrift } from 'config/boot';
import i18n from 'config/i18n';
import { configureCookies } from 'main/cookies';
import { appendOSSpecificClassName, bindKeyboardShortcuts, performDeviceChecks } from 'main/device';
import { canBindKeyboardShortcuts } from 'main/device/reduxState';
import { handleExtensionGlobalEvents } from 'main/extension';
import { RXAppInit } from 'main/state/reduxActions';
import { initDeveloperTools } from 'main/tools';
import { getPreset } from 'main/tools/reduxState';
import { store as rootStore } from 'reducers/store';
import { watchExtension } from 'utils/extension';
import { isTest } from 'utils/misc';
import { getHotModule } from 'utils/module';
import { configure as configureRequest } from 'utils/request';
import App from 'App';
import Provider from 'Provider';
import { registerServiceWorker, unregisterServiceWorker } from './service-worker';

/**
 * @param {object} store Redux store.
 */
const setupSocket = store => {
  const { dispatch } = store;
  const socketOptions = {
    debug: dispatch(getPreset(PRESET_LOG_NETWORK)),
    environment: envFromBranch,
    mock: dispatch(getPreset(PRESET_MOCK_SERVER)) || isTest(),
  };
  configureSocket(socketOptions);
};

const preInit = () => {
  appendOSSpecificClassName();
  configureCredential(i18n.t);
};

/**
 * @param {object} store Redux store.
 * @param {boolean} [skipDeviceCheck=false]
 */
const init = (store = rootStore, skipDeviceCheck = false) => {
  const { dispatch } = store;

  window.AppRoot = findNode('#root', true);

  configureCookies();
  configureRequest(store, i18n.t);
  setupSocket(store);
  dispatch(handleExtensionGlobalEvents());

  createWebExtensionLink({
    decryptEvent: CW_DECRYPT_WITH_EXTERNAL_KEYS,
    encryptEvent: CW_ENCRYPT_WITH_EXTERNAL_KEY,
    getter: getWorkerPromise,
    logger: {
      enabled: dispatch(getPreset(PRESET_EXT_LOG)),
      plaintext: dispatch(getPreset(PRESET_EXT_RAW)),
    },
    target: 'web',
  });

  removeNoScriptFallbackTag();
  loadDrift();

  watchExtension();
  dispatch(initDeveloperTools());
  dispatch(RXAppInit());

  if (!skipDeviceCheck) {
    dispatch(performDeviceChecks());
  }

  if (dispatch(canBindKeyboardShortcuts())) {
    dispatch(bindKeyboardShortcuts());
  }

  ReactDOM.render(<Provider store={store}><App /></Provider>, window.AppRoot);

  unregisterServiceWorker();
  registerServiceWorker();

  if (!isProduction) {
    import('utils/mock/actions')
      .then(exported => exported.default())
      .catch(e => console.log(e));
  }
};

const hot = getHotModule();
if (hot) {
  hot.accept();
}

if (!isTest()) {
  preInit();
  init();
}

export default init;
