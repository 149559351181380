/*
 * Copyright (C) 2018-2019 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import Routes from 'config/routes';
import { goTo } from 'main/router';
import { shouldShowYellowBrickRoad } from 'main/user/reduxState';
import withOverlayAction from 'Overlay/withAction';
import Container from './container';

class SidebarSettingsAdvancedComponent extends React.Component {
  componentDidMount() {
    this.props.addOverlayAction('sidebar', this.props.goBack);
  }

  render() {
    return (
      <Container
        highlight={this.props.showYellowBrickRoad()}
        onCancel={this.props.goBack}
        onClickButtonBackup={this.props.goToBackup}
        onClickButtonDeleteNettoken={this.props.goToDeleteAccount}
        onClickButtonImport={this.props.goToImport}
        onClickButtonSession={this.props.goToSession}
        t={this.props.t} />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  goBack: () => dispatch(goTo(Routes.SETTINGS)),
  goToBackup: () => dispatch(goTo(Routes.SETTINGS_BACKUP)),
  goToDeleteAccount: () => dispatch(goTo(Routes.SETTINGS_DELETE_ACCOUNT)),
  goToImport: () => dispatch(goTo(Routes.SETTINGS_IMPORT)),
  goToSession: () => dispatch(goTo(Routes.SETTINGS_SESSION)),
  showYellowBrickRoad: () => dispatch(shouldShowYellowBrickRoad()),
});

export default translate()(connect(
  null,
  mapDispatchToProps,
)(withOverlayAction(SidebarSettingsAdvancedComponent)));
