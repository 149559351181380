import React from 'react';
import envelope from '../../assets/icons/Envelope.png';

const EnvelopeIcon = () => (
  <img
    src={envelope}
    style={{ height: '20px' }}
  />
);

export default EnvelopeIcon;
