/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
export const EMAIL_INFO = 'info@nettoken.co.uk';
export const EMAIL_SUPPORT = 'support@nettoken.co.uk';

export const MASTER_KEY_BACKUP_EXT = 'pdf';
export const OWNED_DOMAINS = ['nettoken.co.uk', 'nettoken.io'];
