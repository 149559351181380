/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */

/**
 * @param {string} pathname
 *
 * @returns {object} Falsy value if does not exist.
 */
export const getPathSidebarTemplate = pathname => dispatch => {
  let template = pathname[0] === '/' ? pathname.substr(1) : pathname;
  template = template.substr(template.indexOf('/') + 1);

  // Default to the current value.
  if (!template) template = dispatch(getSidebarTemplate());

  // Template surely does not exist.
  if (!template) return false;

  try {
    // Request must include complete file name including extension to
    // avoid confusion. DO NOT REMOVE OR CHANGE THIS. Otherwise,
    // Webpack will cause trouble during the build process by looping
    // through all files in directory and include unwanted files
    // such as tests or Storybook chapters.
    const module = require(`components/Sidebar/templates/${template}/index.js`); // eslint-disable-line global-require
    return module.default ? template : null;
  }
  catch (e) {
    return null;
  }
};

/**
 * @returns {string}
 */
export const getSidebarTemplate = () => (_, getState) => {
  const state = getState();
  return state.ui.sidebarTemplate;
};
