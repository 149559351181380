/*
 * Copyright (C) 2018-2019 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { addEvent, removeEvent } from '@nettoken/helpers';
import { getHintStyle, tutorialFollowNodes, hideTutorial } from 'main/tutorial';
import NettoY from 'assets/images/Netto_yellow.png';
import Styles from './style.css';

class HintsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.timer = null;
  }

  componentDidMount() {
    addEvent(window, 'resize', tutorialFollowNodes);
  }

  componentDidUpdate() {
    clearTimeout(this.timer);
    this.timer = setTimeout(tutorialFollowNodes, 300);
  }

  componentWillUnmount() {
    removeEvent(window, 'resize', tutorialFollowNodes);
  }

  getHint(name) {
    const { hints } = this.props.tutorial;
    for (let i = 0; i < hints.length; i += 1) {
      if (hints[i].name === name) {
        return hints[i];
      }
    }
    return false;
  }

  renderX(stroke) {
    return (
      <svg
        className={`${Styles.icon} ${Styles._x}`}
        viewBox="0 0 111.71 90.556"
        xmlns="http://www.w3.org/2000/svg">
        <g fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round">
          <path d="M44.405 31.5a74.813 74.813 0 0 0-1.049-1.703"/>
          <path strokeDasharray="4.0037,4.0037" d="M41.108 26.482C28.559 9.104 11.479 2.864 3.899.855"/>
          <path d="M1.959.381C.706.101 0 0 0 0s.709.051 1.986.206"/>
          <path strokeDasharray="4.2092,4.2092" d="M6.147.822c9.584 1.646 28.566 6.375 43.797 19.295"/>
          <path d="M51.534 21.508c.492.445.982.9 1.467 1.365"/>
        </g>
        <circle fill="#FFF466" cx="74.223" cy="53.151" r="37.404"/>
        <clipPath id="x-a">
          <circle cx="74.223" cy="53.28" r="37.404"/>
        </clipPath>
        <g clipPath="url(#x-a)">
          <path fill="#FFF" stroke="#25263A" strokeLinejoin="round" strokeMiterlimit="10" d="M113.635 66.183l-10.947 25.615-25.268 3.055-1.127-4.164c.367-4.301.77-14.578 1.49-17.137.082-.02.168-.043.256-.082 1.031-.416 19.627-7.902 22.125-9.699.148-.105.236-.191.268-.254 0 0 6.711 1.467 9.34 1.678l3.863.988z"/>
          <path fill="none" stroke="#25263A" strokeMiterlimit="10" d="M79.698 84.134s4.969 9.217 5.107 9.826"/>
          <path fill="#FFF" stroke="#25263A" strokeLinejoin="round" strokeMiterlimit="10" d="M68.639 62.573c-2.336-2.678-4.773-5.773-7.004-8.887 1.629-.143 12.633-1.068 14.023.467 1.387 1.53-5.386 7.569-7.019 8.42z"/>
          <path fill="#FFF" stroke="#25263A" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M105.217 45.404c-.883-.311-11.955 2.126-13.092 3.106l-3.057-2.484c1.242-.728 4.609-7.661 4.504-8.804-.136-1.484-12.818 3.678-12.818 3.678-4.199-1.951-23.213-4.555-25.529-4.504-.02.92-.535 1.801-1.777 2.223.682 3.48 4.074 9.315 8.188 15.067 1.629-.143 12.633-1.068 14.023.467 1.387 1.529-5.387 7.568-7.02 8.42 3.393 3.895 6.572 6.928 8.562 7.896 2.236-.727 19.949-8.201 21.086-9.432.145-1.703-.521-3.256-.521-3.256 1.766-1.037 7.773-9.861 8.338-11.029.25-.521 0-1.037-.887-1.348zm-24.662 4.101c-.918-1.926 1.305-2.82 2.199-2.285 2.045 1.23.114 4.746-2.199 2.285z"/>
          <path fill="#25263A" d="M55.229 36.363c-.006.922-.51 1.811-1.746 2.248h-.008s-2.746-.479-2.982-2.77c-.121-1.184.896-2.682 2.311-2.453 1.376.231 2.425 1.639 2.425 2.975zm25.326 13.142c-.918-1.926 1.305-2.82 2.199-2.285 2.045 1.23.114 4.746-2.199 2.285zm19.883 13.994l-.273.271c-2.498 1.797-21.094 9.283-22.125 9.699a1.482 1.482 0 0 1-.256.082c-1.062.248-1.707-.758-1.707-.758-.367-1.008 0-2.02.98-2.281a4.57 4.57 0 0 1 .145-.043c2.236-.727 19.949-8.201 21.086-9.432.683-.739 2.902.771 2.15 2.462zm0 0c0 .006-.006.012-.006.018-.031.062-.119.148-.268.254l.274-.272z"/>
          <path fill="none" stroke="#25263A" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2" d="M109.772 65.194l-.02-.006"/>
        </g>
      </svg>
    );
  }

  renderY(stroke) {
    return (
      <svg
        className={`${Styles.icon} ${Styles._y}`}
        viewBox="0 0 90.705 111.846"
        xmlns="http://www.w3.org/2000/svg">
        <g fill="none" stroke={stroke} strokeMiterlimit="10">
          <path d="M59.205 44.404a71.403 71.403 0 0 0 1.703-1.049"/>
          <path strokeDasharray="4.0037,4.0037" d="M64.222 41.107C81.601 28.56 87.84 11.479 89.849 3.899"/>
          <path d="M90.324 1.959C90.604.706 90.705 0 90.705 0s-.051.708-.206 1.986"/>
          <path strokeDasharray="4.2092,4.2092" d="M89.882 6.147c-1.646 9.584-6.375 28.567-19.295 43.797"/>
          <path d="M69.197 51.533c-.445.494-.9.983-1.365 1.467"/>
        </g>
        <circle fill="#FFF466" cx="37.486" cy="74.312" r="37.404"/>
        <clipPath id="y-a">
          <circle cx="37.488" cy="74.441" r="37.404"/>
        </clipPath>
        <g clipPath="url(#y-a)">
          <path fill="#FFF" stroke="#25263A" strokeLinejoin="round" strokeMiterlimit="10" d="M-1.924 87.344l10.945 25.615 25.268 3.055 1.129-4.164c-.367-4.301-.771-14.578-1.492-17.137a1.464 1.464 0 0 1-.254-.082c-1.031-.416-19.629-7.902-22.127-9.699-.148-.105-.236-.191-.266-.254 0 0-6.711 1.467-9.34 1.678l-3.863.988z"/>
          <path fill="none" stroke="#25263A" strokeMiterlimit="10" d="M32.011 105.295s-4.967 9.217-5.107 9.826"/>
          <path fill="#FFF" stroke="#25263A" strokeLinejoin="round" strokeMiterlimit="10" d="M43.072 83.734c2.336-2.678 4.771-5.773 7.002-8.887-1.627-.143-12.631-1.068-14.023.467-1.385 1.53 5.386 7.569 7.021 8.42z"/>
          <path fill="#FFF" stroke="#25263A" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M5.605 67.914c.566 1.168 6.574 9.992 8.338 11.029 0 0-.664 1.553-.521 3.256 1.137 1.23 18.852 8.705 21.088 9.432 1.988-.969 5.17-4.002 8.562-7.896-1.635-.852-8.406-6.891-7.021-8.42 1.393-1.535 12.396-.609 14.023-.467 4.113-5.752 7.506-11.587 8.189-15.067-1.242-.422-1.758-1.303-1.777-2.223-2.316-.051-21.33 2.553-25.531 4.504 0 0-12.68-5.162-12.816-3.678-.105 1.143 3.262 8.076 4.504 8.804l-3.057 2.484c-1.137-.98-12.209-3.417-13.092-3.106-.889.31-1.137.827-.889 1.348zm23.35.467c.895-.535 3.119.359 2.199 2.285-2.311 2.461-4.242-1.055-2.199-2.285z"/>
          <path fill="#25263A" d="M56.48 57.524c.008.922.512 1.811 1.748 2.248h.006s2.748-.479 2.982-2.77c.121-1.184-.896-2.682-2.309-2.453-1.376.231-2.427 1.639-2.427 2.975zM31.154 70.666c.92-1.926-1.305-2.82-2.199-2.285-2.043 1.23-.112 4.746 2.199 2.285zM11.271 84.66l.273.271c2.498 1.797 21.096 9.283 22.127 9.699.086.039.174.062.254.082 1.062.248 1.709-.758 1.709-.758.367-1.008 0-2.02-.982-2.281a4.193 4.193 0 0 0-.143-.043c-2.236-.727-19.951-8.201-21.088-9.432-.683-.739-2.9.771-2.15 2.462zm0 0c0 .006.008.012.008.018.029.062.117.148.266.254l-.274-.272z"/>
        </g>
      </svg>
    );
  }

  renderAdvancedHint(hint, text) {
    return (
      <div className={Styles.contentWrapper}>
        <img width='75px' height='75px'
          className={Styles.dog}
          src={NettoY}
        />
        <div className={Styles.textWrapper}>
          {hint.text && <span className={classNames({
            [Styles.text]: true,
            [Styles._light]: hint.light,
          })} dangerouslySetInnerHTML={{ __html: text }} />}
          <div className={Styles.actionBar}>
            <button className={classNames({
              [Styles.confirm]: true,
              [Styles.fullWidth]: hint.fullWidth,
              [Styles.remindWidth]: true,
            })}
              onClick={hint.onConfirm}>
              {hint.buttonText || 'Remind me later'}
            </button>
            {hint.explain && <button className={classNames({
              [Styles.confirm]: true,
              [Styles.learnMoreWidth]: true,
            })}
              onClick={hint.explainHandler}
            >Learn more</button>}
          </div>
        </div>
      </div>
    );
  }

  renderDashboardTour(hint, text) {
    return (
      <div className={Styles.contentWrapper}>
        <img width='75px' height='75px'
          src={hint.image}
        />
        <div className={Styles.textWrapper}>
          {hint.text && <span className={classNames({
            [Styles.text]: true,
            [Styles._light]: hint.light,
          })} dangerouslySetInnerHTML={{ __html: text }} />}
          <div className={classNames({
            [Styles.actionBar]: true,
            [Styles.actionBarPosition]: true,
          })}>
            <span style={{
              color: 'white', fontSize: '11px', paddingTop: '5px', paddingRight: '5px',
            }}>{hint.currentStep} {'/'} {hint.totalSteps} </span>
            <button className={classNames({
              [Styles.confirm]: true,
              [Styles.fullWidth]: hint.fullWidth,
              [Styles.hintsButton]: true,
            })}
              onClick={hint.onConfirm}>
              {hint.currentStep == hint.totalSteps ? 'Finished' : 'Next'}
            </button>
            {hint.explain && <button className={Styles.confirm}
              onClick={hint.explainHandler}
            >Explain</button>}
          </div>
        </div>
      </div>
    );
  }

  renderTooltipHint(hint, text) {
    return (
      <div style={{ padding: '5px 15px', fontSize: '12px' }}>
        {hint.text && <span className={classNames({
          [Styles.text]: true,
          [Styles._light]: hint.light,
        })} style={{ marginLeft: '0px' }} dangerouslySetInnerHTML={{ __html: text }} />}
      </div>
    );
  }

  renderHints() {
    const { t } = this.props;
    const { activeHints } = this.props.tutorial;
    return activeHints.map(name => {
      const hint = this.getHint(name);
      const style = getHintStyle(hint);
      const text = t(`tutorial.${hint.text}`);
      const stroke = hint.light ? '#fff' : '#000';
      const dirX = hint.direction === 'x';
      const dirY = hint.direction === 'y';
      const top = hint.direction === 'top';
      const bottom = hint.direction === 'bottom';
      const left = hint.direction === 'left';
      const right = hint.direction === 'right';

      return (
        <div
          className={classNames({
            [Styles.wrapper]: !this.props.gridView,
            [Styles.wrapper1]: this.props.gridView,
            [Styles.advanced]: hint.type === 'advanced' || hint.type === 'tooltip' || hint.type === 'dashboardTour',
            [Styles.top]: top,
            [Styles.left]: left,
            [Styles.right]: right,
            [Styles.bottom]: bottom,
            [Styles._fixed]: hint.isFixed,
            [Styles[hint.placement]]: true,
            [Styles.forTooltip]: hint.type === 'tooltip',
          })}
          style={{ marginLeft: '200px' }}
          id={name}
          key={name}
          style={style}>
          {hint.type === 'dashboardTour' ?
            this.renderDashboardTour(hint, text) :
            null
          }
          {hint.type === 'advanced' ?
            this.renderAdvancedHint(hint, text) :
            null
          }
          {hint.type === 'tooltip' ?
            this.renderTooltipHint(hint, text) :
            null
          }
          {hint.type !== 'tooltip' && hint.type !== 'advanced' && hint.type !== 'dashboardTour' ?
            <Fragment>
              {dirX && this.renderX(stroke)}
              {dirY && this.renderY(stroke)}
              {hint.text && <span className={classNames({
                [Styles.text]: true,
                [Styles._light]: hint.light,
                [Styles._x]: dirX,
                [Styles._y]: dirY,
                [Styles.font]: hint.fontSize,
              })} dangerouslySetInnerHTML={{ __html: text }} />}
            </Fragment> :
            null
          }
        </div>
      );
    });
  }

  render() {
    const { activeHints } = this.props.tutorial;
    return activeHints.length ? this.renderHints() : null;
  }
}

HintsComponent.propTypes = {
  /** i18n translate method */
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  groups: state.groups.data,
  tutorial: state.tutorial,
  gridview: state.ui.gridView,
});

const mapDispatchToProps = dispatch => ({
  hideTutorial: name => dispatch(hideTutorial(name, false)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HintsComponent);
