/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import {
  URL_ACCOUNTS, URL_ACCOUNTS_SEARCH, URL_ACCOUNTS_SEARCH_ALL, URL_WEBSITE_SEARCH,
} from 'constants/endpoints';
import { List, Map } from 'immutable';
import { credentialSearchableAttributes, groupSearchableAttributes } from '@nettoken/models';
import { getOneCredential } from 'main/vault/credentials/reduxState';
import { Request } from 'utils/request';
import { RXSearchQuery } from './reduxActions';
import { getQuery } from './reduxState';
import { getCurrentDashboard } from '../ui/reduxState';

/**
 * We implement a separate method for getting accounts by ids because
 * we add pagination to the request.
 *
 * @param {array} ids
 * @param {string} token
 *
 * @returns {array}
 */
export const getPredefinedAccountsFromIds = (ids = [], token) => {
  const resultsPerPage = 100;
  const requestsToMake = Math.ceil(ids.length / resultsPerPage);
  let promises = new List();

  for (let i = 0; i < requestsToMake; i += 1) {
    const start = i * resultsPerPage;
    const end = start + resultsPerPage;
    const pageIds = ids.slice(start, end);
    const promise = new Request(URL_ACCOUNTS)
      .authorise(token)
      .setQueryParams({ limit: resultsPerPage })
      .post({ ids: pageIds })
      .then(res => res.accounts)
      .catch(e => Promise.reject(e));
    promises = promises.push(promise);
  }
  return Promise.all(promises.toArray())
    .then(res => Promise.resolve([].concat(...res), console.log('')))
    .catch(e => {
      console.log(e);
      return Promise.reject(e);
    });
};

/**
 * Returns an object with group ids as keys and arrays of matching app ids
 * as values. Contains a special key __groups__ that contains array of matching
 * group ids. This overrides the matched apps and everything inside the group
 * will be displayed.
 *
 * @param {string} query
 *
 * @returns {object}
 */
const getResults = (query, dashboardId, specialSearch = '') => (dispatch, getState) => {
  let results = Map({
    __groups__: List(),
  });

  if (query) {
    query = query.toUpperCase();
    let credentialSearchableAttrs = [];
    credentialSearchableAttrs = credentialSearchableAttributes;
    credentialSearchableAttrs.push('sharedByUserName');
    credentialSearchableAttrs.push('dashboardSpaceId');
    credentialSearchableAttrs.push('loginUrl');
    const groups = getState().groups.data;
    const sharedAccounts = { ...getState().sharedAccounts };
    Object.keys(groups)
      .forEach(groupId => {
        const group = groups[groupId];

        // Create empty results row for this group.
        results = results.set(groupId, List());
        if (isQueryFoundInObject(query, groupSearchableAttributes, group)) {
          if (group.dashboardSpaceId == dashboardId) {
            results = results.set('__groups__', results.get('__groups__')
              .push(groupId));
            group.apps.forEach(appId => {
              results = results.set(groupId, results.get(groupId)
                .push(appId));
            });
          }
        }
        else {
          group.apps.forEach(appId => {
            const app = dispatch(getOneCredential(appId));
            if (app) {
              if (isTextFoundInObject(query, credentialSearchableAttrs, app)) {
                if ('dashboardSpaceId' in app) {
                  if (dashboardId === app.dashboardSpaceId) {
                    results = results.set(groupId, results.get(groupId)
                      .push(appId));
                  }
                }
                else {
                  results = results.set(groupId, results.get(groupId)
                    .push(appId));
                }
              }
              else {
                if (sharedAccounts[appId]) {
                  sharedAccounts[appId].forEach(userData => {
                    if (isQueryFoundInObject(query, ['name'], userData)) {
                      if ('dashboardSpaceId' in app) {
                        if (dashboardId === app.dashboardSpaceId) {
                          results = results.set(groupId, results.get(groupId)
                            .push(appId));
                        }
                      }
                      else {
                        results = results.set(groupId, results.get(groupId)
                          .push(appId));
                      }
                    }
                  });
                }
                console.log('');
              }
            }
          });
        }
      });
  }

  return results.toJS();
};

/**
 * Check if searched query can be found inside the passed object. This can be
 * for example an app or a group object. We return `true` only if the value
 * matches query from the start, i.e. random matches inside the value do not
 * count as matches.
 *
 * @param {string} query
 * @param {array} attrs List of entity attributes to search for the keyword.
 * @param {object} entity Searched object.
 *
 * @returns {boolean}
 */
const isQueryFoundInObject = (query, attrs, entity) => {
  for (let i = 0; i < attrs.length; i += 1) {
    const attr = attrs[i];
    const value = entity[attr] ? entity[attr].toUpperCase() : null;
    if (value && value.includes(query)) return true;
  }
  return false;
};

const isTextFoundInObject = (query, attrs, entity) => {
  for (let i = 0; i < attrs.length; i += 1) {
    const attr = attrs[i];
    let value = entity[attr] ? entity[attr].toUpperCase() : null;
    if (attr === 'loginUrl') {
      value = value.replace('HTTP://', '');
      value = value.replace('HTTPS://', '');
      value = value.replace('WWW.', '');
    }
    if (value && value.includes(query.toUpperCase())) return true;
  }
  return false;
};

/**
 * Turn an array of accounts into an object where we can query entries quickly
 * by their key.
 *
 * @param {array} predefined Array of predefined accounts to be transformed.
 * @param {string} [key] Entity attribute we want to use as a key for the tree.
 *
 * @returns {object} transformed
 */
export const convertGlobalAccountsIntoObject = (predefined = [], key = 'id') => {
  const obj = {};
  predefined.forEach(account => {
    obj[account[key]] = account;
  });
  return obj;
};

/**
 * @param {string} query
 */
export const doSearch = (query, specialSearch = '') => (dispatch, getState) => {
  const queryValue = specialSearch === 'dashboardSpaceId' ? getState().search.query : query;
  const dashboardId = getState().ui.currentDashboard;
  const results = dispatch(getResults(queryValue, dashboardId, specialSearch));
  dispatch(RXSearchQuery(queryValue, dashboardId, results));
};

/**
 * Gets an account associated with the specified login URLs. These accounts
 * are defined in our global predefined database which includes information
 * like URL, picture, cookies URL, etc. If the account does not exist, it
 * gets created on the server, so we always get back a result.
 *
 * @param {array} loginUrls Urls of the accounts to search.
 * @param {boolean} [skipNoLoginUrl=false]
 *
 * @returns {array} Found (or created) accounts.
 */
export const getOrCreateAccounts = (loginUrls = [], skipNoLoginUrl = false) => {
  const req = new Request(URL_ACCOUNTS);
  const accounts = loginUrls.map(loginUrl => ({ loginUrl }));
  return req.authorise()
    .post({ accounts })
    .then(res => {
      const result = skipNoLoginUrl ? res.accounts.filter(x => !!x.loginUrl) : res.accounts;
      return Promise.resolve(result);
    })
    .catch(e => Promise.reject(e));
};

/**
 * Return predefined accounts from the global accounts pool. We can either
 * ask for random entries or specify which accounts we want.
 *
 * @param {object} params Query parameters.
 * @param {string} [token]
 *
 * @returns {array} Accounts.
 */
export const getPredefinedAccounts = (params = {}, token) => {
  if (params.ids) {
    return getPredefinedAccountsFromIds(params.ids, token);
  }

  const url = params.q ? URL_ACCOUNTS_SEARCH : URL_ACCOUNTS;
  const req = new Request(url);
  return req.authorise(token)
    .get(params)
    .then(res => Promise.resolve(res.accounts, console.log('')))
    .catch(e => {
      console.log(e);
      return Promise.reject(e);
    });
};

export const getAllPredefinedAccounts = (params = {}, token) => {
  const url = (params.scroll || params.q != '') ? URL_ACCOUNTS_SEARCH : URL_ACCOUNTS_SEARCH_ALL;
  const req = new Request(url);
  return req.authorise(token)
    .get(params)
    .then(res => Promise.resolve(res.accounts, console.log('')))
    .catch(e => {
      console.log(e);
      return Promise.reject(e);
    });
};

/**
 * Recalculates the current search state.
 */
export const refreshSearch = () => dispatch => {
  const query = dispatch(getQuery());
  const currentDashboard = dispatch(getCurrentDashboard());
  // if (query && currentDashboard !== query) ;
  dispatch(doSearch(query, currentDashboard ? 'dashboardSpaceId' : ''));
};

/**
 * Chech isReachable website
 */
export const isReachable = (params = {}, token) => {
  const req = new Request(URL_WEBSITE_SEARCH);
  return req.authorise(token)
    .get(params)
    .then(res => res.isReachable)
    .catch(e => Promise.reject(e));
};
