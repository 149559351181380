/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import env from 'runtime/env';

export const getMicrosoftClientId = () => env.microsoftSignInClientId;

// Do not change case for these as the handlers file names must match values.
export const GOOGLE = 'Google';
export const MICROSOFT = 'Microsoft';

export const NAMESPACE = '/oauth/';
export const LOGOUT = '/dashboard?add-account=email';
