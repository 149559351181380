/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { RX_PREFS_SESSION_TIMEOUT } from 'main/preferences/actionTypes';
import { RX_APP_INIT } from 'main/state/actionTypes';
import { RX_USER_LOG_IN, RX_USER_LOG_OUT } from 'main/user/actionTypes';
import Reducer from 'reducers/factory';

const defaultState = () => ({
  accessToken: null,
  createdAt: null,
});

const reducer = new Reducer('session', defaultState());

export default (state = reducer.defaultState(), action) => {
  switch (action.type) {
    case RX_APP_INIT:
      return reducer.merge(state);

    case RX_PREFS_SESSION_TIMEOUT:
    case RX_USER_LOG_IN:
      return {
        ...state,
        accessToken: action.accessToken,
        createdAt: Date.now(),
      };

    case RX_USER_LOG_OUT:
      return reducer.isPersisted ? state : reducer.defaultState();

    default:
      return state;
  }
};
