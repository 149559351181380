/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import {
  RX_DEVICE_BROWSER,
  RX_DEVICE_DESKTOP,
  RX_DEVICE_HOTKEYS_ADD,
  RX_DEVICE_HOTKEYS_REMOVE,
  RX_DEVICE_IPAD,
  RX_DEVICE_MOBILE,
  RX_DEVICE_PHONE,
  RX_DEVICE_TABLET,
} from './actionTypes';

/**
 * @param {boolean} value
 */
export const RXDeviceDesktop = value => ({
  type: RX_DEVICE_DESKTOP,
  value,
});

/**
 * @param {array} shortcuts
 */
export const RXDeviceHotkeysAdd = shortcuts => ({
  type: RX_DEVICE_HOTKEYS_ADD,
  shortcuts,
});

/**
 * @param {array} shortcuts
 */
export const RXDeviceHotkeysRemove = shortcuts => ({
  type: RX_DEVICE_HOTKEYS_REMOVE,
  shortcuts,
});

/**
 * @param {boolean} value
 */
export const RXDeviceIpad = value => ({
  type: RX_DEVICE_IPAD,
  value,
});

/**
 * @param {boolean} value
 */
export const RXDeviceMobile = value => ({
  type: RX_DEVICE_MOBILE,
  value,
});

/**
 * @param {boolean} value
 */
export const RXDevicePhone = value => ({
  type: RX_DEVICE_PHONE,
  value,
});

/**
 * @param {boolean} value
 */
export const RXDeviceTablet = value => ({
  type: RX_DEVICE_TABLET,
  value,
});

export const RXDeviceBrowser = value => ({
  type: RX_DEVICE_BROWSER,
  value,
});
