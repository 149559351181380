/*
 * Copyright (C) 2018-2019 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */

// Definitions for global DOM ids. We often query document for nodes with these ids.
export const ADD_ACCOUNT = 'add-account';
export const BACKUP_KEY = 'backup-key';
export const ACCOUNT_BACKUP_KEY = 'account-backup-key';
export const LOGO = 'logo';
export const LOGIN = 'login';
export const MODAL = 'modal';
export const MANAGE_ACCOUNT = 'manage-account';
export const OVERLAY = 'overlay';
export const PAUSE_SCAN = 'pause-scan';
export const SEARCH = 'search';
export const SEARCH1 = 'search1';
export const SEARCH2 = 'search2';
export const SEARCH3 = 'search3';
export const SIDEBAR = 'sidebar';
export const VALID_URL = 'valid-url';
export const TUTORIAL_ADD_CREDENTIAL = 'addCredential';
export const TUTORIAL_ALLOW_POPUPS = 'allowPopups';
export const TUTORIAL_BACKUP_KEY = 'backupKey';
export const ACCOUNT_TUTORIAL_BACKUP_KEY = 'backupKey1';
export const TUTORIAL_INSTRUCTIONS = 'instructions';
export const TUTORIAL_MANAGE_CREDENTIAL = 'manageCredential';
export const TUTORIAL_ONBOARD = 'onboard';
export const TUTORIAL_PAUSE_SCAN = 'pauseScan';
export const TUTORIAL_SORT_CREDENTIALS = 'sortCredentials';
export const TUTORIAL_VALID_URL = 'validUrl';
export const CREDENTIAL_NOTE = 'credentialNote';
export const CREDENTIAL_NOTE_PARENT = 'credentialNoteParent';
export const ACCOUNTBACKUPKEY = 'addaccount';
export const ID_SHOW_SHARED_INFO = 'ID-SHOW-SHARED-INFO';
export const TEXT_SHOW_SHARED_INFO = 'sharedText';
export const TEXT_LOG_INFO = 'logText';
export const ID_SHOW_HOME_TITLE = 'ID_SHOW_HOME_TITLE';
export const NETTOKEN_HEAD = 'nettokenHead';
export const ID_ADD_DASHBOARD = 'ID_ADD_DASHBOARD';
export const ADD_DASHBOARD_LABEL = 'addDashboardLabel';
export const SESSION_TIMEOUT_INACTIVITY = 'inactivity-timeout';
export const SESSION_TIMEOUT_INACTIVITY_TEXT = 'inactivityText';
export const SESSION_TIMEOUT_CONNECTION = 'connection-timeout';
export const SESSION_TIMEOUT_CONNECTION_TEXT = 'connectionText';
export const NAME_ACCOUNT_LABEL_ID = 'name-account-label';
export const NAME_ACCOUNT_LABEL_TEXT = 'nettokenAppName';
export const EMAIL_ACCOUNT_LABEL_ID = 'email-account-label';
export const EMAIL_ACCOUNT_LABEL_TEXT = 'emailUserLabel';
export const PHONE_ACCOUNT_LABEL_ID = 'phone-account-label';
export const PHONE_ACCOUNT_LABEL_TEXT = 'phoneLabel';
export const TRACKING_ACCOUNT_LABEL_ID = 'tracking-account-label';
export const TRACKING_ACCOUNT_LABEL_TEXT = 'accountTrackingLabel';
export const TRACKING_TOGGLE_LABEL_TEXT = 'trackingToggleLabel';
export const TRACKING_TOGGLE_LABEL_ID = 'tracking-toggle-label';
export const ID_CONTACT_SHARED_WITH = 'ID-CONTACT-SHARED-WITH';
export const TEXT_CONTACT_SHARED_WITH = 'contactSharedWith';
export const ID_CONTACT_SHARED_BY = 'ID-CONTACT-SHARED-BY';
export const TEXT_CONTACT_SHARED_BY = 'contactSharedBy';
export const DASHBOARD_TUTORIAL_STEPS = 'DASHBOARD_TUTORIAL_STEPS';
export const HOME_DASHBOARD_TUTORIAL_STEPS = 'home-dashboard-tour-steps';
export const HOME_DASHBOARD_TUTORIAL_STEPS_TEXT1 = 'homeDashboardTourStep1';
export const HOME_DASHBOARD_TUTORIAL_STEPS_TEXT2 = 'homeDashboardTourStep2';
export const HOME_DASHBOARD_TUTORIAL_STEPS_TEXT3 = 'homeDashboardTourStep3';
export const HOME_DASHBOARD_TUTORIAL_STEPS_TEXT4 = 'homeDashboardTourStep4';
export const HOME_DASHBOARD_TUTORIAL_STEPS_TEXT5 = 'homeDashboardTourStep5';
export const HOME_DASHBOARD_TUTORIAL_STEPS_TEXT6 = 'homeDashboardTourStep6';
export const HOME_DASHBOARD_TUTORIAL_STEPS_1 = 'homeDashboardTour1';
export const HOME_DASHBOARD_TUTORIAL_STEPS_2 = 'homeDashboardTour2';
export const HOME_DASHBOARD_TUTORIAL_STEPS_3 = 'homeDashboardTour3';
export const HOME_DASHBOARD_TUTORIAL_STEPS_4 = 'homeDashboardTour4';
export const HOME_DASHBOARD_TUTORIAL_STEPS_5 = 'homeDashboardTour5';
export const HOME_DASHBOARD_TUTORIAL_STEPS_6 = 'drift-frame-controller';

export const tutorials = [
  TUTORIAL_ADD_CREDENTIAL,
  TUTORIAL_ALLOW_POPUPS,
  TUTORIAL_ONBOARD,
  TUTORIAL_SORT_CREDENTIALS,
  TUTORIAL_BACKUP_KEY,
];
