/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import React from 'react';

/*
 * This HOC helps us avoid errors resulting from changing state on a mounting
 * component. A typical example would be calling an asynchronous function to
 * retrieve something from the server. While this request is being fulfilled,
 * the user performs another action, resulting in our component unmounting, i.e.
 * changing its state. Our asynchronous action would complete only after the
 * component has been removed from the view. It would then try to update the
 * state with the fresh data, but oops, too late buddy. And error is born.
 *
 * But it does not have to be that way. Introducing... AsyncState()! All you
 * have to do is import it, like so.
 *
 * import withAsyncState from 'AsyncState';
 *
 * And then use it on your component.
 *
 * export default withAsyncState(MyWokeComponent);
 *
 * Lastly, wrap your asynchronous setState() methods (or all methods if you
 * want to be 100% safe) in this.props.AsyncSetState(). See below.
 *
 * this.props.AsyncSetState(() => this.setState({ data: fromAsyncAction }))
 *
 * And that's it!
 *
 * Note: This HOC also exposes its internal state if you need it for whatever
 * reason, although that should not be the case.
 */
const withAsyncState = WrappedComponent => class AsyncState extends React.Component {
  constructor(props) {
    super(props);
    this.state = { mounted: false };
  }

  componentDidMount() {
    this.setState({ mounted: true });
  }

  componentWillUnmount() {
    this.setState({ mounted: false });
  }

  AsyncSetState(callback) {
    if (this.state.mounted) callback();
  }

  render() {
    return (
      <WrappedComponent
        _isComponentMounted={this.state.mounted}
        AsyncSetState={cb => this.AsyncSetState(cb)}
        {...this.props} />
    );
  }
};

export default withAsyncState;
