/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { RX_START_TOUR, RX_STOP_TOUR, RX_TUTORIAL } from './actionTypes';

/**
 * @param {string} key
 * @param {any} value
 */
export const RXTutorial = (key, value) => ({ // eslint-disable-line import/prefer-default-export
  type: RX_TUTORIAL,
  key,
  value,
});

export const RXStopTour = () => ({ // eslint-disable-line import/prefer-default-export
  type: RX_STOP_TOUR,
});

export const RXStartTour = () => ({ // eslint-disable-line import/prefer-default-export
  type: RX_START_TOUR,
});
