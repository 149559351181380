/*
 * Copyright (C) 2018-2019 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { SEARCH2 } from 'constants/ids';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { i18nRenderLines, noop } from '@nettoken/helpers';
import ActionBar from 'Modal/ActionBar';
import Input from 'Input';
import AppsPreview from 'AppsPreview';
import Counter from 'Counter';
import Tabs from 'Modal/tabs';
import Styles from 'Modal/style.css';
import { Button } from '@nettoken/components';
import downloadfile from 'assets/file/Nettoken template.csv';
import SupportedImportPlatformsList from './platforms';
import cross from '../../../../assets/images/cross.svg';
import leftsvg from '../../../../assets/images/left.svg';
import LeftArrowIcon from '../../../ActionIcons/LeftArrowIcon';
import CancelIcon from '../../../ActionIcons/CancelIcon';
import ClearIcon from '../../../ActionIcons/ClearIcon';

class ModalAccountAddFileContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      btnDisable: false,
    };
  }

  renderUpload() {
    const { t } = this.props;
    return (
      <div className={Styles.uploadWrapper}>

        <p className={Styles.emailScanDisclaimer} >
        Import your usernames and passwords from these password managers to Nettoken:
        </p>

        <SupportedImportPlatformsList t={t} />

        <ol className={classNames({
          [Styles.emailScanDisclaimer]: true,
          [Styles.numList]: true,
        })}>
          <li>Export the file using the helpful links above</li>
          <li>Upload the file here</li>
          <li>Preview and add the accounts</li>
          <li>Delete the file!</li>
        </ol>

        <Input
          className={classNames(Styles.fromFileInput, Styles.templateMarginBottom)}
          label="Upload file"
          name="file"
          onChange={this.props.onChange}
          placeholder={t('views.import.inputFile')}
          t={t}
          type="file"
          value={this.props.filename} />
          {this.props.error && <p className={Styles.fromFileError} style={{ marginTop: '-13px' }}>
            {this.props.error}
          </p>}
      </div>
    );
  }

  renderPreview() {
    const { t } = this.props;
    return (
      this.props.hasNewAccounts ?
        <div className={Styles.stickyfixedForm2} style={{ backgroundColor: '#f6f6f6' }}>
          <div className={`${Styles.searchWrapper} ${Styles.stickySearchHeader2}`}>
            <input
              aria-label={t('placeholder.search')}
              className={classNames(Styles.search, Styles._preview, Styles.search_select)}
              onChange={this.props.onChangeSearch}
              placeholder={`${t('placeholder.search')}...`}
              role="search"
              type="text"
              id={SEARCH2}
              value={this.props.query} />
            {this.props.query.length > 0 ?
              <ClearIcon onClickClearSearch={this.props.onClickClearSearch} /> : null}
          </div>
          <div className={`${Styles.actions2} ${Styles.stickyActionBar44}`}></div>
          <div className={`${Styles.stickyAppList44}`}>
            <AppsPreview
              apps={this.props.apps}
              isEmailEditable={true}
              onChange={this.props.onFieldChange}
              onClickPasswordToggle={this.props.onClickPasswordToggle}
              onSelectApp={this.props.onSelectApp}
              passwordList={this.props.passwordList}
              selectedApps={this.props.selectedApps}
              showUsername={true}
              unsortedId={this.props.unsortedId}
            />
          </div>
        </div> : <p className={Styles.emailScanDisclaimer} style={{
          marginTop: '15px',
        }}>
        {i18nRenderLines(t('modal.importPreview.noNewAccounts'))}
      </p>
    );
  }

  render() {
    const { hasNewAccounts, t } = this.props;
    const isUpload = this.props.step === 'upload';
    return (
      <React.Fragment>
        <h1
          aria-label={t('modal.accountAdd.title')}
          className={classNames({
            [Styles.title]: true,
            [Styles.stickyHeaderList]: true,
          })}>
          <LeftArrowIcon onClickBackHandler={this.props.onClickBackHandler} />
          { isUpload ? 'Import file' : 'Add accounts' }
          <CancelIcon onCancel={this.props.onCancel} />
        </h1>

        <form
          action="/"
          className={classNames({
            [Styles.formEmailScan]: !hasNewAccounts,
            [Styles.formEmailScanPreview]: hasNewAccounts,
            [Styles._fixedActions]: !isUpload,
            [Styles.stickyMinHeightBackground]: !isUpload,
            [Styles.stickyPaddingTop]: !isUpload,
          })}
          style={{
            maxWidth: '980px',
            height: this.props.hasNewAccounts ? '80vh' : 'auto',
          }}
          method="post"
          onSubmit={this.props.onSubmit}>
          {
            isUpload ?
              this.renderUpload() :
              this.renderPreview()
          }
          <ActionBar
            disableSubmit={isUpload ? !this.props.filename : (
              this.props.selectedApps.size == 0 && this.props.hasNewAccounts
            ) }
            isFromFile={true}
            isFixed={isUpload}
            onCancel={this.props.onCancel}
            selected={this.props.selectedApps.size}
            showSelected={!isUpload}
            submitLabel={t(isUpload ? 'global.preview' : 'global.add')}
            submitting={this.props.loading}
            onClickBackHandler={this.props.onClickBackHandler}
            showDoneBtn={!isUpload && !this.props.hasNewAccounts}
            disableInPreview={this.props.disableInPreview}
            hideBackButton={!isUpload}
            t={t}
            isFixedActionBar={this.props.hasNewAccounts}
            styles={{ width: this.props.hasNewAccounts ? '92vw' : 'auto', maxWidth: '980px' }}/>
        </form>
      </React.Fragment>
    );
  }
}

ModalAccountAddFileContainer.defaultProps = {
  apps: {},
  error: '',
  filename: '',
  groups: {},
  groupIds: [],
  hasNewAccounts: false,
  loading: false,
  onCancel: noop,
  onChange: noop,
  onSubmit: noop,
  unsortedId: '',
};

ModalAccountAddFileContainer.propTypes = {
  apps: PropTypes.object,
  disabledTabs: PropTypes.bool,
  error: PropTypes.string,
  filename: PropTypes.string,
  groups: PropTypes.object,
  groupIds: PropTypes.array,
  onClickClearSearch: PropTypes.func,
  hasNewAccounts: PropTypes.bool,
  loading: PropTypes.bool,
  onCancel: PropTypes.func,
  onChange: PropTypes.func,
  onSelectApp: PropTypes.func,
  onSubmit: PropTypes.func,
  selectedApps: PropTypes.array,
  step: PropTypes.string.isRequired,
  /** i18n translate method */
  t: PropTypes.func.isRequired,
  unsortedId: PropTypes.string,
};

export default ModalAccountAddFileContainer;
