/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { reloadBrowser } from '@nettoken/helpers';
import { parseUrlQueryString, stringifyUrlQueryParams } from 'main/router';
import { signOutUser } from 'main/user';
import { isUserAuthenticated } from 'main/user/reduxState';
import { history } from 'reducers/store';
import { canExecuteDeveloperCommand } from 'utils/module';

import {
  RXToolsHide,
  RXToolsLarge,
  RXToolsPresets,
  RXToolsShow,
  RXToolsSmall,
} from './reduxActions';

const CMD_OPEN_DEV_TOOLS = 'developer-tools';

let presetTimer = null;

/**
 * @param {string} key
 * @param {boolean} [refresh=true]
 */
const handlePresetChanged = (key, refresh = true) => dispatch => {
  clearTimeout(presetTimer);
  presetTimer = setTimeout(() => {
    const shouldSignOut = key.startsWith('mock-');
    if (shouldSignOut && dispatch(isUserAuthenticated())) {
      dispatch(signOutUser());
    }
    else if (refresh) {
      reloadBrowser();
    }
  }, 500);
};

/**
 * Developer tools are available only in development.
 */
export const initDeveloperTools = () => dispatch => {
  if (!canExecuteDeveloperCommand) return;
  if (dispatch(shouldActivateTools())) {
    dispatch(RXToolsShow());
  }
};

/**
 * @param {string} key
 * @param {any} value
 * @param {boolean} [refresh]
 */
export const setPreset = (key, value, refresh) => (dispatch, getState) => {
  if (!canExecuteDeveloperCommand) return;
  const { presets } = getState().tools;
  presets[key] = value;
  dispatch(RXToolsPresets(presets));
  dispatch(handlePresetChanged(key, refresh));
};

/**
 * Checks if we should display developer tools panel. To do that, we need to
 * append the correct command to the url.
 *
 * Example:
 * https://localhost:3000/?developer-tools
 *
 * @returns {boolean}
 */
export const shouldActivateTools = () => dispatch => {
  const queryParams = parseUrlQueryString(history.location.search);
  const cmd = queryParams[CMD_OPEN_DEV_TOOLS];

  if (cmd !== undefined) {
    // Remove the command and update the state.
    delete queryParams[CMD_OPEN_DEV_TOOLS];
    const restURLParams = stringifyUrlQueryParams(queryParams);
    history.replace({ search: restURLParams });

    if (cmd !== 'false') {
      return true;
    }

    dispatch(RXToolsHide());
  }

  return false;
};

/**
 * @param {boolean} value
 */
export const toggleToolsMaximise = value => dispatch => {
  if (value) {
    dispatch(RXToolsLarge());
  }
  else {
    dispatch(RXToolsSmall());
  }
};
