import React, { Component, Fragment } from 'react';
import { Button } from '@nettoken/components';
import Tooltip from 'Tooltip';
import netto from 'images/netto-keeping-track.png';
import Styles from 'Modal/style.css';

class ModalPromptTrackingContainer extends Component {
  render() {
    const { t } = this.props;
    return (
      <Fragment>
        <h1 className={Styles.title}>
          Activity history
        </h1>
        <div className={`${Styles.formEmailScanPreview} ${Styles._boxPadding} ${Styles.tracker}`}>
          <img src={netto} width={1996} height={534} />
          <p>
            You can keep track of events and changes in your Nettoken activity history.
          </p>
          <p>
            We value your privacy. Only YOU can see this data to help you be in control.
          </p>
          <div className={Styles.backupPromptButton}>
            <Button
              onClick={this.props.onConfirm}
              value={'Record my activity'}
            />
            <Tooltip content={t('views.settings.tracking')} delay={0}
              align={{
                points: ['tr', 'bc'],
              }}
            >
              <p className={Styles.explain}>?</p>
            </Tooltip>
          </div>
          <Button
            className={Styles.button}
            onClick={this.props.onCancel}
            value={'I prefer no activity history'}
          />
        </div>
      </Fragment>
    );
  }
}

export default ModalPromptTrackingContainer;
