/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'Helmet';
import Tooltip from 'Tooltip';
import classNames from 'classnames';
import Styles2 from 'Modal/style.css';
import Styles from 'Sidebar/style.css';
import { Button } from '@nettoken/components';
import CancelIcon from '../../../ActionIcons/CancelIcon';
import QuestionMarkIcon from '../../../ActionIcons/QuestionMarkIcon';

import {
  SESSION_TIMEOUT_INACTIVITY,
  SESSION_TIMEOUT_INACTIVITY_TEXT,
  SESSION_TIMEOUT_CONNECTION_TEXT,
  SESSION_TIMEOUT_CONNECTION,
} from '../../../../constants/ids';

export const Checkbox = ({
  checked,
  name,
  onChange,
  value,
}) => {
  const input = React.createRef();

  React.useEffect(() => {
    if (checked && input && input.current) {
      input.current.focus();
    }
  }, [input, checked]);

  return (
    <div className={Styles.radioWrapper}>
      <input
        ref={input}
        checked={checked}
        className={Styles.radioInput}
        id={name}
        onChange={onChange}
        name={name}
        autoFocus={true}
        // type="radio" would not be focusable with keyboard
        type="checkbox"
        value={value}
      />

      <label className={Styles.radioLabel} htmlFor={name}>
        <div className={Styles.radioBorder} />
      </label>
    </div>
  );
};

const SidebarSessionContainer = ({
  onCancel,
  onChangeCheckbox,
  onSubmit,
  options,
  lostConnectionOptions,
  handlerForConnectionLost,
  connectionLostTimeout,
  t,
  timeout,
  lostConnectionTimeoutInitial,
  initialTimeout,
}) => (
  <React.Fragment>
    <h1 aria-label={t('views.session.title')} className={Styles2.title1}>
      {t('views.session.title')}
      <CancelIcon onCancel={onCancel} />
    </h1>
    <form
      action="/"
      className={`${Styles2.whiteBackground2} ${Styles2.minHeightBackground}`}
      method="post"
      onSubmit={onSubmit}
    >
      <div className={`${Styles2.fixedForm} ${Styles2.fixedForm2}`}>
        <div className={`${Styles2.form}`}>
          <div className={`${Styles.content} ${Styles._scrollable}`}>
            <div className={Styles.paddingFixer}>
              <p>
                Nettoken will automatically log you out after this time as an added
                layer of security.
              </p>

              <ul>
                <li className={Styles.dashedSection}>
                  <div className={Styles.switchWrapper}>
                    <h2 className={`${Styles.sectionTitle} ${Styles2.fontSizeChange}`}>
                      {t('views.session.sectionTitle')}
                    </h2>
                    {/* ToDo Need to move this text in translate file. */}
                    <QuestionMarkIcon content='When Nettoken detects no activity it will count down before automatically logging you out of the browser session as an added layer of security.' rightPosition='100px' placement='right'/>
                  </div>

                  <ul>
                    {options.map((option, index) => (
                      <li className={Styles.exportSectionItem} key={index}>
                        <h4 className={`${Styles.radioTitle11} ${Styles2.fontSizeChange}`}>{option.label}</h4>
                        <Checkbox
                          checked={timeout === option.value}
                          name={`option-${index}`}
                          onChange={onChangeCheckbox}
                          value={option.value}
                        />
                      </li>
                    ))}
                  </ul>
                </li>
              </ul>

              <div className={Styles.switchWrapper}>
                {/* ToDo Need to move this text in translate file. */}
                <h2 className={`${Styles.sectionTitle} ${Styles2.fontSizeChange}`}>Lost Connection</h2>
                {/* ToDo Need to move this text in translate file. */}
                <QuestionMarkIcon content='When the network connection is lost, Nettoken will count down before automatically logging you out of the browser session as an added layer of security.' rightPosition='100px' placement='right'/>
              </div>

              <ul>
                {lostConnectionOptions.map((option, index) => (
                  <li className={Styles.exportSectionItem} key={index}>
                    <h4 className={`${Styles.radioTitle11} ${Styles2.fontSizeChange}`}>{option.label}</h4>
                    <Checkbox
                      checked={connectionLostTimeout === option.value}
                      name={`lostConnectionOptions-${index}`}
                      onChange={handlerForConnectionLost}
                      value={option.value}
                    />
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className={`${Styles2.actions} ${Styles2.actionsFixed} ${Styles2._squeezedAltered} ${Styles2.fixedButtons}`}
        style={{ justifyContent: 'right' }}
      >

        <div>
          <Button className={classNames({
            [Styles2.button]: true,
            [Styles2.notDisabled]:
              timeout !== initialTimeout || connectionLostTimeout !== lostConnectionTimeoutInitial,
            [Styles2.cancelMarginLeft]: true,
          })}
            type="submit"
            disabled={
              timeout === initialTimeout &&
              connectionLostTimeout === lostConnectionTimeoutInitial
            }
            value={t('global.save')} />
        </div>
      </div>
    </form>
  </React.Fragment>
);

SidebarSessionContainer.defaultProps = {
  options: [],
  timeout: 0,
};

SidebarSessionContainer.propTypes = {
  onCancel: PropTypes.func,
  onChangeCheckbox: PropTypes.func,
  onSubmit: PropTypes.func,
  options: PropTypes.array,
  /** i18n translate method */
  t: PropTypes.func.isRequired,
  timeout: PropTypes.number,
};

export default SidebarSessionContainer;
