/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from '@nettoken/components';
import { noop, reloadBrowser } from '@nettoken/helpers';
import Styles from 'Modal/style.css';
import Input from 'Input';
import Tooltip from 'Tooltip';
import LeftArrowIcon from '../../../ActionIcons/LeftArrowIcon';
import CancelIcon from '../../../ActionIcons/CancelIcon';
import moveAccountsArrowIcon from '../../../../assets/images/moveAccountsArrowIcon.svg';
import sharingIcon from '../../../../assets/icons/sharingDashboard.png';
import upwardArrowicon from '../../../../assets/images/info11.png';
import chevronRight from '../../../../assets/images/chevronRight.png';
import iconPlus from '../../../../assets/icons/plus.svg';
import customStyles from './style.css';
import EnvelopeIcon from '../../../ActionIcons/Envelope';
import { getIconColorNewmethod } from '../../../../utils/misc';
import SharingBtn from '../../../ActionIcons/SharingBtn';
import QuestionMarkIcon from '../../../ActionIcons/QuestionMarkIcon';
import AccountSharedWithInLine from '../../../AccountSharedWithInLine';


const addDashboard = ({
  onCancel,
  t,
  onChange,
  dashboardName,
  loading,
  onEdit,
  onClickShare,
  onClickUser,
  deleteProcessing,
  onClickButtonDelete,
  dataName,
  onBack,
  moveAccounts,
  error,
  maxLengthError,
  helperText,
  maxLengthErrorText,
  externalDashboard,
  sharedDashboard,
  usersSharedWithWithoutLoggedInUser,
  sharedCredentialData,
  onMouseEnterSharingStatus,
  onMouseLeaveSharingStatus,
  sharingStatus,
  disableSharing,
  handleSharingAccount,
  credentialData,
  getUserAccount,
  sharedByUserName,
}) => (
  <React.Fragment>
    <h1 aria-label='Edit Dashboard'
        className={classNames({
          [Styles.title]: true,
          [Styles.stickyHeaderList]: true,
        })}>
      <LeftArrowIcon onClickBackHandler={onBack} />
      Edit Dashboard
      <CancelIcon onCancel={onCancel} />
    </h1>
    <div className={`${Styles.whiteBackground2} ${Styles.minHeightBackground}`}
    style={{ overflow: 'unset' }}>
        <div className={`${Styles.form} ${Styles.fixedForm} ${Styles.fixedForm2}`}>
            <div className={classNames({
              [Styles.iconHeaderWrapper]: true,
              [Styles.noBg]: true,
            })}>
              {externalDashboard ? (
                  <img
                    src={upwardArrowicon}
                    style={{
                      top: '-3px',
                      right: '-7px',
                      height: '28.5px',
                      width: '28.5px',
                      position: 'absolute',
                    }}/>
              ) : null}
              {!externalDashboard && sharedDashboard ? (
                <span className={Styles.sharedMe} style={{
                  position: 'absolute',
                  top: '-7px',
                  right: '-7px',
                }}>
                  <svg width="28.5" height="28.5" viewBox="0 0 31 31" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M16 26C9.92487 26 5 21.0751 5 15C5 8.92487 9.92487 4 16
                         4C22.0751 4 27 8.92487 27 15C27 21.0751 22.0751 26 16 26Z"
                        fill="white"/>
                      <circle cx="16" cy="15" r="10.75" stroke="#28283F"/>
                      <path d="M16.0902 9.37494L16.4743 9.05485C16.3805 8.94229
                      16.2421 8.87654
                       16.0956 8.87497C15.9491 8.8734 15.8092 8.93616
                        15.713 9.04668L16.0902 9.37494ZM15.5902 20.6249C15.5902
                         20.9011
                        15.8141 21.1249 16.0902 21.1249C16.3663 21.1249 16.5902
                        20.9011 16.5902 20.6249L15.5902 20.6249ZM11.5166 13.8681C11.3353
                        14.0764 11.3572 14.3922 11.5655 14.5735C11.7738 14.7548
                        12.0896 14.7329 12.2709 14.5246L11.5166 13.8681ZM19.7239
                         14.5165C19.9007
                         14.7286 20.216 14.7573 20.4281 14.5805C20.6403
                        14.4037 20.6689 14.0884 20.4922 13.8763L19.7239 14.5165ZM15.5902
                         9.37494L15.5902
                        20.6249L16.5902 20.6249L16.5902 9.37494L15.5902
                        9.37494ZM12.2709 14.5246L16.4673 9.7032L15.713 9.04668L11.5166
                         13.8681L12.2709
                        14.5246ZM20.4922 13.8763L16.4743 9.05485L15.7061
                        9.69503L19.7239 14.5165L20.4922 13.8763Z"
                        fill="#28283F"/>
                  </svg>
                </span>
              ) : null}
                <svg width='100%' height='100%' style={{ borderRadius: '50%' }}>
                <rect x='0' y='0' fill={getIconColorNewmethod(dashboardName)} height='100%' width='100%'></rect>
                <text
                    dominantBaseline='middle'
                    fill='#ffffff'
                    fontSize='10'
                    textAnchor='middle'
                    x='48%'
                    y='54%'
                >{dashboardName.slice(0, 2).toUpperCase()}</text>
                </svg>
            </div>
            <div style={{
              color: '#868692',
              fontSize: '14px',
              padding: '16px 12px 8px',
            }}>DASHBOARD NAME</div>
            <Input readOnly={externalDashboard} className={`${(externalDashboard) ? Styles.externalAcc : ''}`} bgColorOnHover={true} style={{ marginTop: '10px' }} onChange={onChange} value={dashboardName} type="text"/>
            {externalDashboard ? <span style={{ color: '#868692', fontSize: '14px' }}>You have permission to view this dashboard without editing. </span> : ''}
            <div style={{ color: '#F8581C', fontSize: '12px', margin: '14px' }}>{error ? helperText : ''}</div>
            <div style={{ color: '#F8581C', fontSize: '12px', margin: '14px' }}>{maxLengthError ? maxLengthErrorText : ''}</div>
            <div style={{
              paddingLeft: '44px', marginLeft: '-33px', backgroundColor: '#dcdbde', width: '420px',
            }}>
            {!externalDashboard ?
            <div
              onClick={() => moveAccounts()}
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
              className={Styles.circleParent}
            >
              <div
                className={`${Styles.circle} ${Styles.moveAccount}`}
              >
                <img
                  src={moveAccountsArrowIcon}
                  className={Styles.iconPlus}
                />
              </div>
              <span style={{ paddingLeft: '5px', cursor: 'pointer' }}>Move Accounts</span>
            </div> : ''}
          <div className={Styles.sharedRow} style={{ margin: 'auto' }}>
            {sharedDashboard ? (
              <h2 style={{ marginTop: '20px' }}>SHARED CONTACTS
                 <QuestionMarkIcon content='When you share an account with a contact they can view your username, email, and password and use it to log in to your account. Your contact cannot edit the information and can only view it.' position='relative' rightPosition='-193px' placement='right'/>
              </h2>
            ) : null}
            {
              !externalDashboard && <div className={classNames({
                [Styles.subHeader]: true,
              })} style={{ width: '100%' }}>
                <div style={
                  {
                    display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '10px', marginBottom: '10px',
                  }
                }>
                  <div style={{ display: 'flex', alignItems: 'center', columnGap: '10px' }}>
                    <div style={{ cursor: 'pointer' }} className={Styles.circle} onClick={onClickShare}>
                        {!sharedDashboard && <img
                          className={Styles.iconPlus}
                          style={{ width: '26px', height: '26px' }}
                          src={sharingIcon}
                        />}
                       {sharedDashboard && <img
                        alt={t('alt.addCredential')}
                        className={Styles.iconPlus}
                        src={iconPlus} />}
                    </div>
                    <span
                      className={Styles.addADashboard}
                      style={{ cursor: 'pointer' }}
                      onClick={onClickShare}
                    >Share dashboard</span>
                    {!sharedDashboard && <QuestionMarkIcon content='When you share an account with a contact they can view your username, email, and password and use it to log in to your account. Your contact cannot edit the information and can only view it.' position='relative' rightPosition='-160px' placement='right'/>}
                  </div>
                </div>
              </div>
            }
           <React.Fragment>
            {(sharedDashboard && (sharedByUserName != null)) &&
             <div className={Styles.outerSharedDetails} style={{ marginLeft: '-4px', width: '354px', height: '41px' }}>
                <div
                  className={Styles.sharedDetails}
                  style={{ marginTop: '3px', marginLeft: '6px' }}
                  onClick={() => {
                    const userAccount = getUserAccount();
                    onClickUser(userAccount);
                  }}>
                  <div className={Styles.sharedCredDetails} style={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    marginTop: '4px',
                  }}>
                  <svg width='100%' height='100%' style={{ border: `1px solid ${getIconColorNewmethod(sharedByUserName)}` }}>
                    <rect x='0' y='0' fill={getIconColorNewmethod(sharedByUserName)} height='100%' width='100%'></rect>
                    <text
                      dominantBaseline='middle'
                      fill='#ffffff'
                      fontSize='10'
                      textAnchor='middle'
                      x='48%'
                      y='54%'
                    >{sharedByUserName.slice(0, 2).toUpperCase()}</text>
                  </svg>
                    <span style={{
                      flex: 1,
                      minWidth: 0,
                      height: '1.5rem',
                      lineHeight: '1.5rem',
                    }}>{sharedByUserName.length > 18 ? sharedByUserName.substring(0, 18).concat('...') : sharedByUserName}</span>
                  </div>

                  <div className={Styles.rightSide} style={{ color: '#868692', fontSize: '14px' }}>
                    Owner <img style={{ width: '24px', height: '24px' }} src={chevronRight} />
                  </div>
                </div>
              </div>
            }
              <AccountSharedWithInLine
                sharedCredentialData={usersSharedWithWithoutLoggedInUser}
                onClickUser={onClickUser}
                onMouseEnterSharingStatus={onMouseEnterSharingStatus}
                onMouseLeaveSharingStatus={onMouseLeaveSharingStatus}
                sharingStatus={sharingStatus}
                disableSharing={disableSharing}
                handleSharingAccount={handleSharingAccount}
                externalDashboard={externalDashboard}
                chevronRightStyle={{ marginRight: '0px' }}
              />
            {/* {sharedCredentialData.map((account, index) => (
              <div
                className={Styles.sharedDetails}
                key={index}
                onClick={() => {
                  onClickUser(account);
                }}
              >
                <div className={Styles.sharedCredDetails} style={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}>
                <svg width='100%'
                  height='100%'
                  style={{ border: `1px solid ${getIconColor(account.name)}` }}>
                  <rect
                    x='0'
                    y='0'
                    fill={getIconColor(account.name)} height='100%' width='100%'></rect>
                  <text
                    dominantBaseline='middle'
                    fill='#ffffff'
                    fontSize='10'
                    textAnchor='middle'
                    x='48%'
                    y='54%'
                  >{account.name.slice(0, 2).toUpperCase()}</text>
                </svg>
                  <span style={{
                    flex: 1,
                    minWidth: 0,
                    height: '1.5rem',
                    lineHeight: '1.5rem',
                  }}>{
                    account.name.length > 18 ?
                    account.name.substring(0, 18).concat('...') : account.name}</span>
                </div>
                  <SharingBtn
                    account={account}
                    onMouseEnterSharingStatus={onMouseEnterSharingStatus}
                    onMouseLeaveSharingStatus={onMouseLeaveSharingStatus}
                    sharingStatus={sharingStatus}
                    externalDashboard={externalDashboard}
                    disableSharing={disableSharing}
                    handleSharingAccount={handleSharingAccount}
                  />
              </div>
            ))} */}
            </React.Fragment>
          </div>
        </div>
        </div>
    </div>
    <div className={`${Styles.actions} ${Styles.actionsFixed} ${Styles._squeezedAltered} ${Styles.fixedButtons}`}>
        <Button
          className={`${Styles.button} ${Styles.danger}`}
          onClick={onClickButtonDelete}
          loaderSize={20}
          loading={deleteProcessing}
          value={'Remove dashboard'} />
        <Button
          className={classNames({
            [Styles.button]: true,
            [Styles.notDisabled]: dataName !== dashboardName &&
              dashboardName.length > 0 &&
              !error &&
              !maxLengthError,
            [Styles.cancelMarginLeft]: true,
          })}
          style={{ paddingLeft: '10px', paddingRight: '10px', paddingTop: '5px' }}
          loading={loading}
          onClick={onEdit}
          disabled={dataName === dashboardName || !dashboardName || error || maxLengthError}
          type="submit"
          value='Save' />
    </div>
  </React.Fragment>
);

addDashboard.propTypes = {
  onCancel: PropTypes.func,
  /** i18n translate method */
  t: PropTypes.func.isRequired,
};

export default addDashboard;
