/*
 * Copyright (C) 2018-2019 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { PRESET_REDUX } from 'constants/presets';
import { applyMiddleware, createStore as newStore } from 'redux';
import { List } from 'immutable';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import throttle from 'lodash.throttle';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory, createMemoryHistory } from 'history';
import { isProduction } from '@nettoken/env';
import { noop } from '@nettoken/helpers';
import { trackPageGoogleAnalytics } from 'main/analytics/google';
import { isTest } from 'utils/misc';
import { passStoreMethods, setReduxStore } from 'utils/storage';
import { getPersistedReducers, lastBrowserSession } from './persisted';
import rootReducer from './root';

export const history = isTest() ? createMemoryHistory() : createBrowserHistory();
export const reduxStorageThrottle = 300;

history.listen(trackPageGoogleAnalytics);

/**
 * @returns {boolean}
 */
const debugRedux = () => {
  const debug = !isProduction && !!(
    lastBrowserSession &&
    lastBrowserSession.tools &&
    lastBrowserSession.tools.presets &&
    lastBrowserSession.tools.presets[PRESET_REDUX]
  );
  return debug;
};

/**
 * @returns {function}
 */
const enhancer = () => {
  let middleware = new List([
    routerMiddleware(history),
    thunk,
    storageMiddleware,
  ]);

  if (debugRedux()) {
    const ReduxLogger = require('redux-logger'); // eslint-disable-line global-require
    middleware = middleware.push(ReduxLogger.createLogger());
  }

  const composeEnhancers = composeWithDevTools(applyMiddleware(...middleware.toArray()));
  return composeEnhancers;
};

/**
 * @param {object} store Redux store.
 *
 * @returns {object}
 */
const pruneReduxState = store => {
  const persisted = getPersistedReducers();
  const copy = { ...store.getState() };
  Object.keys(copy).forEach(key => {
    if (!persisted.includes(key)) delete copy[key];
  });
  return copy;
};

/**
 * Persists store state in localStorage.
 *
 * @param {object} store Redux store.
 *
 * @returns {function}
 */
const storageMiddleware = store => next => {
  const persistStateThrottled = throttle(setReduxStore, reduxStorageThrottle);
  return action => {
    next(action);
    const reduxState = pruneReduxState(store);
    persistStateThrottled(reduxState);
  };
};

/**
 * Used in tests to generate different store scenarios we can use
 * for fully mounted components.
 *
 * @param {any} state
 * @param {function} [reducer]
 *
 * @returns {object}
 */
export const createStore = (state, reducer) => {
  if (!reducer) reducer = rootReducer || noop;
  const middleware = enhancer();
  reducer = reducer(history);
  if (typeof state === 'function') state = undefined;
  const reduxStore = newStore(reducer, state, middleware);
  return reduxStore;
};

export const store = createStore(lastBrowserSession);

store.dispatch(passStoreMethods());
