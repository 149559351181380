import { MODAL_ACCOUNT_EDIT, MODAL_ACCOUNT_SHARE } from 'constants/modal';
import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import withOverlayAction from 'Overlay/withAction';
import { showModal, hideModal } from 'main/modal';
import Container from './container';

class ModalAccountShareRuleComponent extends React.Component {
  constructor(props) {
    super(props);
    this.hide = this.hide.bind(this);
    this.onGotItClick = this.onGotItClick.bind(this);
  }

  componentDidMount() {
    this.props.addOverlayAction('modal', this.hide, false);
  }

  hide() {
    this.props.showModal(MODAL_ACCOUNT_EDIT, this.props.data);
  }

  onGotItClick() {
    const { dispatch } = this.props;
    dispatch(showModal(MODAL_ACCOUNT_SHARE, this.props.data));
  }

  render() {
    return (
      <Container hide={this.hide} onGotItClick={this.onGotItClick}
      onClose={() => this.props.hideModal()}/>
    );
  }
}

const mapStateToProps = state => ({
  data: state.ui.modalData || {},
});
const mapDispatchToProps = dispatch => ({
  dispatch,
  showModal: (name, data) => dispatch(showModal(name, data)),
  hideModal: () => dispatch(hideModal()),
});

export default translate()(connect(
  mapStateToProps,
  mapDispatchToProps,
)(withOverlayAction(ModalAccountShareRuleComponent)));
