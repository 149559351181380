/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@nettoken/components';
import classNames from 'classnames';
import { noop } from '@nettoken/helpers';
import Helmet from 'Helmet';
import Styles from 'Sidebar/style.css';
import Styles2 from 'Modal/style.css';
import CancelIcon from '../../../ActionIcons/CancelIcon';
import LeftArrowIcon from '../../../ActionIcons/LeftArrowIcon';

const Checkbox = ({ checked, name, onChange }) => (
  <div className={Styles.radioWrapper}>
    <input
      checked={checked}
      className={Styles.radioInput}
      id={name}
      onChange={onChange}
      name={name}
      type="checkbox" />

    <label
      className={Styles.radioLabel}
      htmlFor={name}>
      <div className={Styles.radioBorder} />
    </label>
  </div>
);


let chk;
class SidebarDeleteAccountConfirmationContainer extends React.Component {
  renderFragments(type) {
    const { t } = this.props;
    const isChecked = this.props.checkedOption === type;
    chk = isChecked;
    return (
      <React.Fragment>
        {isChecked && <p className={`${Styles.radioTextInExport} ${Styles._danger}`}>
          {t('views.export.warning')}
        </p>}

        <Checkbox
          checked={isChecked}
          name={type}
          onChange={this.props.onChangeCheckbox} />

        <Button
          className={Styles.radioButton}
          disabled={!isChecked}
          icon={true}
          light={true}
          loading={isChecked && this.props.downloading}
          onClick={this.props.onClickDownload}
          value='Download Data'/>
      </React.Fragment>
    );
  }

  renderFragments1(type) {
    const { t } = this.props;
    const isChecked = this.props.checkedOption === type;
    return (
      <React.Fragment>
        <Checkbox
          checked={isChecked}
          name={type}
          onChange={this.props.onChangeCheckbox} />
      </React.Fragment>
    );
  }

  render() {
    const { t } = this.props;
    return (
        <React.Fragment>
            <h1
              aria-label={t('views.export.title')}
              className={`${Styles2.title1} ${Styles._danger}`}
              style={{ color: 'rgb(248, 88, 28)' }}
            >
                <LeftArrowIcon onClickBackHandler={this.props.clickBack} />
                {t('views.export.title')}
                <CancelIcon onCancel={this.props.onCancel} />
            </h1>
            <form
                action="/"
                className={`${Styles2.whiteBackground2} ${Styles2.minHeightBackground}`}
                method="post"
                onSubmit={this.props.onSubmit}>
                <div className={`${Styles2.fixedForm} ${Styles2.fixedForm2}`}>
                  <div className={`${Styles.content} ${Styles._scrollable}`}>
                      <div className={Styles.paddingFixer}>
                          <p style={{ color: 'rgb(248, 88, 28)' }}>
                              Deleting your Nettoken is permanent and irreversable
                              as no reference to your account is left behind.
                              </p>
                          <ul>
                            <li className={Styles.exportSectionItem}>
                                <span for='html'>I agree and understand</span>
                                <input type="radio" id="html" name="fav_language" value="HTML" />
                                {this.renderFragments1('decrypted')}
                            </li>
                          </ul>
                      </div>
                  </div>
                </div>
                <div
                  className={`${Styles2.actions} ${Styles2.actionsFixed} ${Styles2._squeezedAltered} ${Styles2.fixedButtons}`}
                  style={{ justifyContent: 'right' }}
                >
                  <Button
                    className={classNames({
                      [Styles2.button]: true,
                      [Styles2.cancelMarginLeft]: true,
                      [Styles2.danger]: true,
                    })}
                    loaderSize={20}
                    loading={this.props.deleting}
                    disabled={!this.props.checkedOption}
                    type="submit"
                    value="Permanently delete your account" />
                </div>
            </form>
        </React.Fragment>
    );
  }
}

SidebarDeleteAccountConfirmationContainer.defaultProps = {
  checkedOption: '',
  deleting: false,
  downloading: false,
  onCancel: noop,
  onChangeCheckbox: noop,
  onClickDownload: noop,
  onSubmit: noop,
};

SidebarDeleteAccountConfirmationContainer.propTypes = {
  checkedOption: PropTypes.string,
  deleting: PropTypes.bool,
  downloading: PropTypes.bool,
  onCancel: PropTypes.func,
  onChangeCheckbox: PropTypes.func,
  onClickDownload: PropTypes.func,
  onSubmit: PropTypes.func,
  /** i18n translate method */
  t: PropTypes.func.isRequired,
};

export default SidebarDeleteAccountConfirmationContainer;
