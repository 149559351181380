/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from '@nettoken/components';
import { getIconUrl } from '@nettoken/helpers';
import { setDefaultIcon } from 'utils/misc';
import { PropIcon } from 'utils/propTypes';
import defaultIcon from 'icons/default-icon.svg';
import Styles from 'Modal/style.css';
import Avatar from '../../../Avatar';

const ModalAccountDeleteConfirmContainer = ({
  name,
  onSubmit,
  picture,
  t,
  domain,
  loginUrl,
}) => (
  <React.Fragment>
    <h1
      aria-label={t('modal.accountDeleteConfirm.title')}
      className={classNames({
        [Styles.title]: true,
        [Styles.displayBlock]: true,
      })}>
      {t('modal.accountDeleteConfirm.title')}
    </h1>

    <form
      action="/"
      className={Styles.whiteBackground}
      method="post"
      onSubmit={onSubmit}>
      <div className={classNames({
        [Styles.iconHeaderWrapper]: true,
        [Styles.noBg]: true,
      })}>
        <Avatar
          className={Styles.iconHeaderWrapper}
          domain={domain}
          loginUrl={loginUrl}
          name={name}
          picture={picture}
        />
      </div>

      <p className={Styles.promptText}>
        {t('modal.accountDeleteConfirm.text', { name })}
      </p>

      <div className={`${Styles.actions} ${Styles._single}`}>
        <Button
          className={Styles.button}
          type="submit"
          value={t('global.OK')} />
      </div>
    </form>
  </React.Fragment>
);

ModalAccountDeleteConfirmContainer.defaultProps = {
  name: '',
  picture: '',
};

ModalAccountDeleteConfirmContainer.propTypes = {
  name: PropTypes.string,
  onSubmit: PropTypes.func,
  picture: PropIcon(),
  /** i18n translate method */
  t: PropTypes.func.isRequired,
};

export default ModalAccountDeleteConfirmContainer;
