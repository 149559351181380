/*
 * Copyright (C) 2018-2019 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { hideModal, showModal } from 'main/modal';
import { Request } from 'utils/request';
import withOverlayAction from 'Overlay/withAction';
import { removeSharedByAccounts } from 'main/sharedAccounts';
import {
  CW_ENCRYPT_USING_MASTER_KEYPAIR,
  CW_ENCRYPT_WITH_EXTERNAL_KEY,
  getWorkerPromise,
  CW_DECRYPT_USING_MASTER_KEYPAIR,
  CW_GENERATE_EPHEMERAL_KEY_PAIR,
  CW_EXTRACT_MASTER_KEYS,
} from '@nettoken/crypto-worker';
import KeyPair from '@nettoken/crypto-worker/src/keypair';
import Container from './container';
import {
  MODAL_DASHBOARDS,
  MODAL_DELETE_DASHBOARD,
  MODAL_EDIT_DASHBOARD,
  MODAL_SHARE_DASHBOARD,
  MODAL_SHARE_DASHBOARD_RULES,
  MODAL_STOP_SHARING,
  MODAL_ADD_ACCOUNT_NAVLIST, MODAL_MOVE_ACCOUNTS, MODAL_USER_DETAIL, MODAL_STOP_SHARING_DASHBOARD,
} from '../../../../constants/modal';
import { RXDashboardAdd, RXDashboardUpdate } from '../../../../main/modal/reduxActions';
import addDashboard from './container';
import { DASHBOARD_SPACE_ID } from '../../../../constants/endpoints';
import { getOrCreateKeyPairForSharingDashboard } from '../../../../utils/misc';

class ModalAddDashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dashboardName: this.props.dataName,
      previousName: this.props.dataName,
      loading: false,
      deleteProcessing: false,
      error: false,
      maxLengthError: false,
      helperText: 'This dashboard name already exists',
      maxLengthErrorText: 'This name exceeds the 30 characters limit',
      sharingStatus: {},
      deleteProcessing: false,
      disableSharing: {},
    };

    this.hide = this.hide.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.onBack = this.onBack.bind(this);
    this.moveAccounts = this.moveAccounts.bind(this);
    this.onMouseEnterSharingStatus = this.onMouseEnterSharingStatus.bind(this);
    this.onMouseLeaveSharingStatus = this.onMouseLeaveSharingStatus.bind(this);
    this.handleSharingAccount = this.handleSharingAccount.bind(this);
    this.onClickShare = this.onClickShare.bind(this);
    this.onClickUser = this.onClickUser.bind(this);
    this.getUserAccount = this.getUserAccount.bind(this);
  }

  componentDidMount() {
    this.props.addOverlayAction('modal', () => this.props.hide());
  }

  onChange(e) {
    this.setState({
      ...this.state,
      error:
        Object.keys(this.props.dashboardList)
          .filter(id => this.props.dashboardList[id].name === e.target.value &&
            this.props.data.id != id)
          .length > 0,
      maxLengthError: e.target.value.length > 30,
      dashboardName: e.target.value,
    });
  }

  // async getOrCreateKeyPairForSharing(dashboard) {
  //   const keypair = new KeyPair();
  //   const {
  //     publicKey,
  //     secretKey,
  //   } = dashboard;
  //   const worker = await getWorkerPromise('crypto');

  //   if (dashboard.shared === true && publicKey && secretKey) {
  //     const [pKey, sKey] = await Promise.all([
  //       worker({
  //         event: CW_DECRYPT_USING_MASTER_KEYPAIR,
  //         message: publicKey,
  //       }),
  //       worker({
  //         event: CW_DECRYPT_USING_MASTER_KEYPAIR,
  //         message: secretKey,
  //       }),
  //     ])
  //       .then(values => values.map(v => Object.values(v)
  //         .join('')
  //         .replace('CW_DECRYPT_USING_MASTER_KEYPAIR', '')));

  //     keypair.set(pKey, sKey);
  //   }
  //   else {
  //     const { newKeyPairPublicKey, newKeyPairSecretKey } =
  //     await worker({ event: CW_GENERATE_EPHEMERAL_KEY_PAIR });
  //     // Save the data on server.
  //     const event = CW_EXTRACT_MASTER_KEYS;
  //     const { encryptedPrivateKey } =
  //     await worker({ event, newKeyPairPublicKey, newKeyPairSecretKey });
  //     keypair.set(newKeyPairPublicKey, newKeyPairSecretKey);
  //   }
  //   return keypair;
  // }

  onClickUser(account) {
    const userAccountDetails = [];
    const sharedAccountDetail = {};
    const sharedByAccounts = [];
    Object.keys(this.props.sharedAccounts).forEach(accId => {
      const tmpAccountDetails = this.props.sharedAccounts[accId].find(acc => acc.id === account.id);
      if (tmpAccountDetails) {
        Object.keys(this.props.credentials.data).forEach(credId => {
          let sharedDashboard = false;
          if (
            'dashboardSpaceId' in this.props.credentials.data[credId] &&
            this.props.credentials.data[credId].dashboardSpaceId &&
            'shared' in this.props.dashboardList[this.props.credentials.data[credId].dashboardSpaceId] &&
            this.props.dashboardList[this.props.credentials.data[credId].dashboardSpaceId].shared &&
            'usersSharedWith' in this.props.dashboardList[this.props.credentials.data[credId].dashboardSpaceId] &&
            this.props.dashboardList[this.props.credentials.data[credId].dashboardSpaceId].
              usersSharedWith.length > 0
          ) {
            sharedDashboard = true;
          }
          if (credId === accId && !sharedDashboard) {
            userAccountDetails.push(this.props.credentials.data[credId]);
            sharedAccountDetail[credId] = tmpAccountDetails;
          }
        });
      }
    });
    Object.keys(this.props.credentials.data).forEach(key => {
      if (this.props.credentials.data[key].sharedByUserId === account.id) {
        let sharedDashboard = false;
        if (
          'dashboardSpaceId' in this.props.credentials.data[key] &&
          this.props.credentials.data[key].dashboardSpaceId &&
          'shared' in this.props.dashboardList[this.props.credentials.data[key].dashboardSpaceId] &&
          this.props.dashboardList[this.props.credentials.data[key].dashboardSpaceId].shared &&
            'usersSharedWith' in this.props.dashboardList[this.props.credentials.data[key].dashboardSpaceId] &&
            this.props.dashboardList[this.props.credentials.data[key].dashboardSpaceId].
              usersSharedWith.length > 0
        ) {
          sharedDashboard = true;
        }
        if (!sharedDashboard) {
          sharedByAccounts.push(this.props.credentials.data[key]);
          if (
            (('phone' in account && account.phone === '') || !('phone' in account)) &&
            'sharedByUserPhone' in this.props.credentials.data[key]
          ) {
            account.phone = this.props.credentials.data[key].sharedByUserPhone;
          }
        }
      }
    });
    this.props.dispatch(showModal(MODAL_USER_DETAIL, {
      userAccountDetails,
      account,
      oldData: this.props.data,
      oldModal: MODAL_EDIT_DASHBOARD,
      sharedAccountDetail,
      sharedByAccounts,
    }));
  }


  async onEdit() {
    this.setState({ loading: true });

    let sharedDashboard = false;
    let encryptedDashboardName;
    if (this.props.usersSharedWith.length > 0) {
      sharedDashboard = true;
    }
    else {
      sharedDashboard = false;
    }
    const {
      publicKey: publicKeyDashboard,
      secretKey: secretKeyDashboard,
    } = await getOrCreateKeyPairForSharingDashboard(this.props.dashboardSpace, KeyPair);
    const worker = await getWorkerPromise('crypto');
    if (sharedDashboard) {
      [{
        encrypted: encryptedDashboardName,
      }] = await Promise.all([
        worker({
          event: CW_ENCRYPT_WITH_EXTERNAL_KEY,
          message: this.state.dashboardName,
          inputFormat: 'plain',
          outputFormat: 'base64',
          publicKey: publicKeyDashboard,
        }),
      ]);
    }
    else {
      [encryptedDashboardName] = await Promise.all([
        worker({
          event: CW_ENCRYPT_USING_MASTER_KEYPAIR,
          message: this.state.dashboardName,
        }),
      ]).then(resData => resData.map(v => Object.values(v)
        .join('')
        .replace('CW_ENCRYPT_USING_MASTER_KEYPAIR', '')));
    }
    const req = new Request(DASHBOARD_SPACE_ID);
    req
      .setUrlParams({ id: this.props.dashboardSpace.id })
      .authorise()
      .put({
        id: this.props.dashboardSpace.id,
        name: encryptedDashboardName,
        publicKey: this.props.dashboardSpace.publicKey,
        secretKey: this.props.dashboardSpace.secretKey,
        userId: this.props.dashboardSpace.userId,
        shared: sharedDashboard,
      })
      .then(res => {
        this.props.updateDashboard(this.props.dataId, this.state.dashboardName);
        this.setState({ loading: false, previousName: this.state.dashboardName });
      })
      .catch(e => Promise.reject(e));
  }

  hide() {
    this.props.hide();
  }

  handleDelete() {
    this.props.showModal(MODAL_DELETE_DASHBOARD, {
      id: this.props.dataId,
      name: this.state.dashboardName,
    });
  }

  onBack() {
    if ('prevModalData' in this.props.data) {
      this.props.showModal(this.props.data.prevModalData.id, this.props.data.prevModalData.data);
    }
    else {
      this.props.showModal(MODAL_DASHBOARDS);
    }
  }

  onClickShare() {
    if (this.props.sharedDashboard) {
      this.props.showModal(MODAL_SHARE_DASHBOARD, {
        id: this.props.dataId,
        name: this.props.dataName,
      });
    }
    else {
      this.props.showModal(MODAL_SHARE_DASHBOARD_RULES, {
        id: this.props.dataId,
        name: this.props.dataName,
      });
    }
  }

  getUserAccount() {
    // const credentialId = this.props.dataId;
    // let userId = '';
    // let userName = '';
    // Object.keys(this.props.credentials.data).forEach(key => {
    //   if (key === credentialId) {
    //     userId = this.props.credentials.data[credentialId].sharedByUserId;
    //     userName = this.props.credentials.data[credentialId].sharedByUserName;
    //   }
    // });
    // let userAccount = [];
    // Object.keys(this.props.sharedAccounts).forEach(accId => {
    //   const tmpAccountDetails = this.props.sharedAccounts[accId].find(acc => acc.id === userId);
    //   if (tmpAccountDetails) {
    //     userAccount = tmpAccountDetails;
    //   }
    // });
    // if (userAccount.length === 0) {
    const userAccount = {
      id: this.props.dashboardData.sharedByUserId,
      name: this.props.dashboardData.sharedByUserName,
      phone: this.props.dashboardData.sharedByUserPhone,
    };
    // }
    return userAccount;
  }

  moveAccounts() {
    this.props.showModal(MODAL_MOVE_ACCOUNTS, {
      id: this.props.dataId,
      backBtn: () => this.props.showModal(MODAL_EDIT_DASHBOARD, {
        id: this.props.dataId,
        dashboardName: this.props.dataName,
      }),
    });
  }

  onMouseEnterSharingStatus(user) {
    this.setState({
      sharingStatus: {
        [user]: true,
      },
    });
  }

  onMouseLeaveSharingStatus(user) {
    this.setState({
      sharingStatus: {
        [user]: false,
      },
    });
  }

  async handleSharingAccount(id, phone, name, isAccepted) {
    this.props.showModal(MODAL_STOP_SHARING_DASHBOARD, {
      ...this.props.dashboardData,
      sharedWithName: name,
      accepted: isAccepted,
      phone,
      sharedWith: id,
      ...this.props.data,
      currentPopup: MODAL_EDIT_DASHBOARD,
      currentPopupData: this.props.data,
    });
  }

  render() {
    return (
      <Container
        onCancel={this.hide}
        t={this.props.t}
        onChange={this.onChange}
        dashboardName={this.state.dashboardName}
        dataName={this.state.previousName}
        loading={this.state.loading}
        onEdit={this.onEdit}
        deleteProcessing={this.state.deleteProcessing}
        onClickButtonDelete={this.handleDelete}
        onBack={this.onBack}
        moveAccounts={this.moveAccounts}
        error={this.state.error}
        maxLengthError={this.state.maxLengthError}
        maxLengthErrorText={this.state.maxLengthErrorText}
        helperText={this.state.helperText}
        externalDashboard={this.props.externalDashboard}
        sharedDashboard={this.props.sharedDashboard}
        onClickShare={this.onClickShare}
        onClickUser={this.onClickUser}
        onMouseEnterSharingStatus={this.onMouseEnterSharingStatus}
        onMouseLeaveSharingStatus={this.onMouseLeaveSharingStatus}
        sharingStatus={this.state.sharingStatus}
        disableSharing={this.state.disableSharing}
        handleSharingAccount={this.handleSharingAccount}
        sharedCredentialData={this.props.usersSharedWith}
        sharedByUserName={this.props.sharedByUserName}
        getUserAccount={this.getUserAccount}
        usersSharedWithWithoutLoggedInUser={this.props.usersSharedWithWithoutLoggedInUser}
        />
    );
  }
}

const mapDispatchToState = state => ({
  data: state.ui.modalData,
  dataName: state.ui.modalData.dashboardName,
  dataId: state.ui.modalData.id,
  sharedAccounts: state.sharedAccounts,
  credentials: state.credentials,
  dashboardList: state.ui.dashboards,
  dashboardSpace: state.ui.dashboards[state.ui.modalData.id],
  sharedByUserName: 'sharedByUserName' in state.ui.dashboards[state.ui.modalData.id] ? state.ui.dashboards[state.ui.modalData.id].sharedByUserName : null,
  externalDashboard: 'externalDashboard' in state.ui.dashboards[state.ui.modalData.id] && state.ui.dashboards[state.ui.modalData.id].externalDashboard,
  sharedDashboard: 'shared' in state.ui.dashboards[state.ui.modalData.id] && state.ui.dashboards[state.ui.modalData.id].shared &&
  'usersSharedWith' in state.ui.dashboards[state.ui.modalData.id] && state.ui.dashboards[state.ui.modalData.id].usersSharedWith.length > 0,
  usersSharedWith: 'usersSharedWith' in state.ui.dashboards[state.ui.modalData.id] ?
    state.ui.dashboards[state.ui.modalData.id].usersSharedWith :
    [],
  usersSharedWithWithoutLoggedInUser: 'usersSharedWith' in state.ui.dashboards[state.ui.modalData.id] ?
    state.ui.dashboards[state.ui.modalData.id].usersSharedWith.filter(x => (
      x.name != state.user.profile.name)) :
    [],
  dashboardData: state.ui.dashboards[state.ui.modalData.id],
});

const mapDispatchToProps = dispatch => ({
  hide: () => dispatch(hideModal()),
  dispatch,
  addDashboard: dashboardName => dispatch(RXDashboardAdd(dashboardName)),
  updateDashboard: (id, dashboardName) => dispatch(RXDashboardUpdate(id, dashboardName)),
  showModal: (name, data) => dispatch(showModal(name, data)),
});

export default translate()(connect(
  mapDispatchToState,
  mapDispatchToProps,
)(withOverlayAction(ModalAddDashboard)));
