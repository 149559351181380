import { MODAL_UNSAVED_CHANGES } from 'constants/modal';
import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import withOverlayAction from 'Overlay/withAction';
import Routes from 'config/routes';
import { getError } from 'main/error/reduxState';
import { showModal } from 'main/modal';
import { goTo } from 'main/router';
import { setConnectionLostTimeout, setTrackingPermission } from 'main/preferences';
import { changeUserPersonalDetails, syncUserPersonalDetails } from 'main/user';
import { RXUserChangesSave, RXUserChangesUndo } from 'main/user/reduxActions';
import { getUserProfile } from 'main/user/reduxState';
import Container from './container';

class SidebarSettingsBasicsComponent extends React.Component {
  componentDidMount() {
    this.props.addOverlayAction('sidebar', this.props.goBack);
  }

  hasChanged() {
    const { changed } = this.props.user;
    return !!Object.keys(changed).length;
  }

  handleAction(callback) {
    if (this.hasChanged()) {
      this.props.showModal(MODAL_UNSAVED_CHANGES, {
        onCancel: () => {
          this.props.RXUserChangesUndo();
          callback();
        },
        onConfirm: e => this.save(e, callback),
      });
      return;
    }

    callback();
  }

  hide() {
    this.props.goToDashboard();
  }

  save(event, callback) {
    if (event) event.preventDefault();
    this.props.RXUserChangesSave();
    this.props.pushSettingsToServer().catch(e => console.log(e));
    callback();
  }

  render() {
    const user = this.props.getUserProfile();

    return (
      <Container
        email={user.email}
        isTracking={this.props.isTracking}
        getError={this.props.getError}
        name={user.name}
        onCancel={() => this.handleAction(() => this.props.goBack())}
        onChange={this.props.changeUserPersonalDetails}
        onSubmit={e => this.save(e, () => this.props.goBack())}
        phone={user.phone}
        setTrackingPermission={this.props.setTrackingPermission}
        t={this.props.t}
        unsavedChanges={this.hasChanged()} />
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  isTracking: state.preferences.tracking,
});

const mapDispatchToProps = dispatch => ({
  changeUserPersonalDetails: e => dispatch(changeUserPersonalDetails(e)),
  getError: id => dispatch(getError(id)),
  getUserProfile: () => dispatch(getUserProfile()),
  goBack: () => dispatch(goTo(Routes.SETTINGS)),
  goToDashboard: () => dispatch(goTo(Routes.DASHBOARD)),
  pushSettingsToServer: () => dispatch(syncUserPersonalDetails()),
  RXUserChangesSave: () => dispatch(RXUserChangesSave()),
  RXUserChangesUndo: () => dispatch(RXUserChangesUndo()),
  showModal: (name, data) => dispatch(showModal(name, data)),
  setTrackingPermission: permission => dispatch(setTrackingPermission(permission)),
});

export default translate()(connect(
  mapStateToProps,
  mapDispatchToProps,
)(withOverlayAction(SidebarSettingsBasicsComponent)));
