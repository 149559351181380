import React from 'react';
import Styles from 'Modal/style.css';
import classNames from 'classnames';
import { getIconColorNewmethod } from '../../utils/misc';

const UsersSharedWith = props => {
  const { usersSharedWith } = props;
  let styles = {
    display: 'grid', gridTemplateColumns: 'auto 1fr', padding: '5px', paddingLeft: '0px', alignItems: 'center',
  };
  if ('customStyles' in props) {
    styles = {
      ...styles,
      ...props.customStyles,
    };
  }
  return (
    <div style={styles}>
      <div style={{ display: 'flex' }}>
                    {usersSharedWith.length > 0 ?
                      usersSharedWith.slice(0,
                        usersSharedWith.length > 2 ? 1 :
                          usersSharedWith.length).map(
                        (x, index) => (
                          <div
                            className={classNames({
                              [Styles.circleCopyWithoutHover]: true,
                              [Styles.circleCopyMarginLeft]: index == 1,
                            })}
                            style={{ backgroundColor: getIconColorNewmethod(x.name), color: 'white' }}
                          >{x.name.toString().toUpperCase().slice(0, 2)}</div>
                        ),
                      ) : null}
                    {usersSharedWith.length > 2 ? (
                      <div
                        className={classNames({
                          [Styles.circleUsersSharedWith]: true,
                          [Styles.label]: true,
                          [Styles.hideLabel]: true,
                        })}
                        style={{ backgroundColor: '#FFFFFF', color: 'black' }}
                      >{'+'}{usersSharedWith.length - 1}</div>
                    ) : null}
      </div>
      {usersSharedWith.length > 0 && (
          <div style ={
            {
              marginLeft: '6px', fontSize: '14px',
            }
          }>
            In shared dashboard with {usersSharedWith[0].name.toString()}
            {usersSharedWith.length > 1 && ` and ${usersSharedWith.length - 1}`}
            {usersSharedWith.length == 2 ? ' other' : null}
            {usersSharedWith.length > 2 ? ' others' : null}
          </div>
      )}
    </div>
  );
};

export default UsersSharedWith;
